import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Protected } from '@timed/auth';
import {
  addServerErrors,
  Block,
  IconButtonMulti,
  Textarea,
} from '@timed/common';
import { useUpdateMembersNotesMutation } from '@timed/gql';
import { MemberContext } from '@timed/member/context';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  patch: {
    confidentialNotes: string;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      flexGrow: 1,
      display: 'flex',
      flexFlow: 'column',
    },
    paper: {
      flexGrow: 1,
      gap: theme.spacing(0),
    },
    input: {
      padding: theme.spacing(4),
      overflowY: 'auto',
      outline: 'none',
      resize: 'none',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: '1px solid ' + theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      fontFamily: 'inherit',
      WebkitTextFillColor: theme.palette.text.primary, // required for iOS
      opacity: 1, // required for iOS
      width: '100%',
      height: 'calc(100% - 3px)',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    disabled: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  }),
);

const MemberConfidentialNotesForm = () => {
  const classes = useStyles();

  const members = useContext(MemberContext);

  const [editing, setEditing] = useState<boolean>(false);

  const [updateMemberNotes, response] = useUpdateMembersNotesMutation();

  const defaultValues: FormData = {
    patch: { confidentialNotes: members.confidentialNotes || '' },
  };

  const { handleSubmit, watch, control, setError } = useForm<FormData>({
    defaultValues,
  });

  const currentValues = watch();

  useEffect(
    () => response.error && addServerErrors(response.error, setError),
    [response.error, setError],
  );

  const onSubmit = (values: FormData) =>
    updateMemberNotes({
      variables: { input: { ids: [members.id], ...values } },
    }).catch((e) => {});

  return (
    <Protected tester>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Block
          paperProps={{ className: classes.paper }}
          title="Confidential Notes"
          topRight={
            <IconButtonMulti
              enabled={editing}
              changed={!isEqual(currentValues, defaultValues)}
              setEditing={setEditing}
              loading={response.loading}
              success={!!response.data}
            />
          }
        >
          <Textarea
            name="patch.confidentialNotes"
            control={control}
            disabled={!editing}
            className={
              editing ? classes.input : clsx(classes.input, classes.disabled)
            }
            style={{ height: '', overflow: '' }}
          />
        </Block>
      </form>
    </Protected>
  );
};

export default MemberConfidentialNotesForm;
