import {
  Box,
  Divider,
  Checkbox as MuiCheckbox,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { useAlert } from '@timed/alert';
import { _schedule } from '@timed/app';
import { useAuth } from '@timed/auth';
import {
  Button,
  Checkbox,
  DateInput,
  Link,
  Select,
  SelectMenuItem,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  formatPersonName,
  intersperse,
  jsDateToLocalISO8601DateString,
} from '@timed/common';
import { pluralise } from '@timed/common/utils/pluralise';
import {
  EventsWhereInput,
  Member,
  OrderBy,
  PersonNamesFragment,
  SetMemberPayrollCheckDocument,
  SetMemberPayrollCheckInput,
  useGetActivitySlipsLazyQuery,
  useGetEventsMembersReadyToExportLazyQuery,
  useGetPublicHolidaysLazyQuery,
  useReportActivitySlipsGetMemberPayrollChecksLazyQuery,
  useSetMemberPayrollCheckMutation,
} from '@timed/gql';
import { useLoadingEffect } from '@timed/loading';
import {
  Activity,
  PayrollCategory,
  generateActivitySlips,
} from '@timed/report';
import {
  differenceInMonths,
  format,
  isBefore,
  isEqual,
  startOfToday,
  startOfWeek,
  subMilliseconds,
  subWeeks,
} from 'date-fns';
import addDays from 'date-fns/addDays';
import { isMonday, subDays } from 'date-fns/esm';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';

export type ActivitySlip = {
  memberExternalId?: string | null;
  memberLastName: string;
  memberFirstName: string;
  date: string;
  activityId: Activity;
  planManagerName: string;
  units: string;
  ndisId: string;
  notes: string;
  payrollCategory: PayrollCategory;
};

type FormData = {
  members: string[];
  period: 'previous' | 'current' | 'custom' | undefined;
  from?: Date;
  to?: Date;
  capitaliseLastNames?: boolean;
  exportOnlyVerified?: boolean;
  exportOnlyNonPaid?: boolean;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      '& .MuiSvgIcon-root': {
        fontSize: 14,
      },
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    info: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    warning: {
      backgroundColor: yellow[50],
      border: '1px solid ' + yellow[500],
    },
    error: {
      backgroundColor: red[50],
      border: '1px solid ' + red[100],
    },
    success: {
      backgroundColor: green[50],
      border: '1px solid ' + green[100],
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    checker: {
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper2,
      border: '1px solid ' + theme.palette.divider,
      display: 'grid',
      gridTemplateColumns: 'repeat(6, max-content)',
      columnGap: theme.spacing(4),
      rowGap: theme.spacing(0),
      alignItems: 'center',
    },
    paidHeader: {
      '& #pay-all': {
        display: 'none',
      },
      '&:hover': {
        '& #paid': {
          display: 'none',
        },
        '& #pay-all': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          color: theme.palette.primary.main,
        },
      },
    },
    infoBox: {
      maxWidth: 'max-content',
      backgroundColor: yellow[100],
      border: `1px solid ${yellow[500]}`,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      '& .MuiTypography-root': {
        fontSize: 13,
      },
    },
  }),
);

// type PayrollPeriods = "previous" | "current" | "custom" | undefined;

const ReportActivitySlips = () => {
  const classes = useStyles();
  const theme = useTheme();

  const WEEK_STARTS_ON = 1; // MONDAY

  const { control, watch, setValue } = useForm<FormData>({
    defaultValues: { period: 'previous' },
  });

  const { branch } = useAuth();

  const alert = useAlert();

  const period = watch('period');
  const from = watch('from');
  const to = watch('to');
  const members = watch('members');
  const capitaliseLastNames = watch('capitaliseLastNames');
  const exportOnlyVerified = watch('exportOnlyVerified');
  const exportOnlyNonPaid = watch('exportOnlyNonPaid');

  const headers = [
    { label: 'Emp. First Name', key: 'memberFirstName' },
    { label: 'Emp. Co./Last Name', key: 'memberLastName' },
    { label: 'Emp. Record ID', key: 'memberExternalId' },
    { label: 'Date', key: 'date' },
    { label: 'Activity ID', key: 'activityId' },
    { label: 'Cust. Co./Last Name', key: 'planManagerName' },
    { label: 'Units', key: 'units' },
    { label: 'Job', key: 'ndisId' },
    { label: 'Notes', key: 'notes' },
    { label: 'Payroll Category', key: 'payrollCategory' },
  ];

  const periods = useMemo<SelectMenuItem[]>(
    () => [
      { label: 'Most recently ended payroll period', value: 'previous' },
      { label: 'Current payroll period', value: 'current' },
      { label: 'Custom date range', value: 'custom' },
    ],
    [],
  );

  const [reportData, setReportData] = useState<Array<ActivitySlip> | undefined>(
    undefined,
  );

  const [getMembers, membersResponse] =
    useGetEventsMembersReadyToExportLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [getMemberPayrollChecks, memberPayrollChecksResponse] =
    useReportActivitySlipsGetMemberPayrollChecksLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [setMemberPayrollCheck, setMemberPayrollChecksResponse] =
    useSetMemberPayrollCheckMutation({
      onCompleted: (result) => {
        const cache = setMemberPayrollChecksResponse.client.cache;

        cache.modify({
          fields: {
            memberPayrollChecks(existing = []) {
              return [
                ...existing,
                cache.writeQuery({
                  data: result.setMemberPayrollCheck,
                  query: SetMemberPayrollCheckDocument,
                }),
              ];
            },
          },
        });
      },
    });

  const [getEvents, eventsResponse] = useGetActivitySlipsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getPublicHolidays, publicHolidaysResponse] =
    useGetPublicHolidaysLazyQuery({
      fetchPolicy: 'network-only',
    });

  const handleSetMemberPayrollCheck = useCallback(
    (input: Pick<SetMemberPayrollCheckInput, 'paid' | 'member'>) => {
      if (from)
        setMemberPayrollCheck({
          variables: {
            input: {
              payrollPeriodStartAt: format(from, 'yyyy-MM-dd'),
              events: membersResponse.data?.events.length
                ? {
                    ids: membersResponse.data.events
                      .filter((event) => event.member?.id === input.member.id)
                      .map(({ id }) => id),
                  }
                : undefined,
              ...input,
            },
          },
        });
    },
    [from, setMemberPayrollCheck, membersResponse.data?.events],
  );

  const loading =
    membersResponse.loading ||
    memberPayrollChecksResponse.loading ||
    eventsResponse.loading ||
    publicHolidaysResponse.loading;

  useEffect(() => {
    eventsResponse.error &&
      alert.push({
        message: eventsResponse.error.graphQLErrors[0].message,
        severity: 'error',
      });
  }, [eventsResponse.error, alert]);

  useLoadingEffect(loading);

  useEffect(() => {
    if (period) {
      switch (period) {
        case 'current':
          setValue(
            'from',
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
          );
          setValue(
            'to',
            addDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              6,
            ),
          );
          break;
        case 'previous':
          setValue(
            'from',
            subWeeks(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          setValue(
            'to',
            subDays(
              startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
              1,
            ),
          );
          break;
      }
    }
  }, [period, setValue]);

  useEffect(() => {
    if (!!from && !!to) {
      if (
        isEqual(
          from,
          startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
        ) &&
        isEqual(
          to,
          addDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 6),
        )
      ) {
        setValue('period', 'current');
      } else if (
        isEqual(
          from,
          subWeeks(
            startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }),
            1,
          ),
        ) &&
        isEqual(
          to,
          subDays(startOfWeek(new Date(), { weekStartsOn: WEEK_STARTS_ON }), 1),
        )
      ) {
        setValue('period', 'previous');
      } else {
        setValue('period', 'custom');
      }
    }
  }, [from, to, setValue]);

  /**
   * Fetch members
   */
  useEffect(() => {
    if (!!from && !!to)
      getMembers({
        variables: {
          input: {
            where: {
              startAt: { _lte: addDays(to, 1) },
              endAt: { _gte: from },
              payable: { _eq: true },
              client: !!branch
                ? { branch: { id: { _eq: branch.id } } }
                : undefined,
            },
            orderBy: [
              { member: { lastName: OrderBy.ASC_NULLS_FIRST } },
              { member: { firstName: OrderBy.ASC } },
            ],
          },
        },
      });
  }, [branch, getMembers, from, to]);

  /**
   * Fetch members payroll checks
   */
  useEffect(() => {
    // Only fetch if from date is set and is a monday.
    if (!!members && !!from && isMonday(from))
      getMemberPayrollChecks({
        variables: {
          input: {
            where: {
              payrollPeriodStartAt: { _eq: format(from, 'yyyy-MM-dd') },
              member: { id: { _in: members } },
            },
            orderBy: [
              { member: { lastName: OrderBy.ASC_NULLS_FIRST } },
              { member: { firstName: OrderBy.ASC } },
            ],
          },
        },
      });
  }, [getMemberPayrollChecks, members, from]);

  /**
   * Unique selectable members.
   */
  const selectableMembers = useMemo<
    [
      string,
      Pick<
        Member,
        'id' | 'bonusEligible' | 'externalId' | 'employmentStartDate'
      > &
        PersonNamesFragment,
    ][]
  >(() => {
    return !!membersResponse.data?.events.length
      ? [
          ...new Map<string, any>(
            membersResponse.data.events
              .filter(({ member }) => !!member)
              .map(({ member }) => member)
              .map((arr) => [arr!['id'], arr]),
          ),
        ]
      : [];
  }, [membersResponse.data]);

  /**
   * Selectable members with errors.
   */
  const [memberIdsMissingNotes, memberIdsNotClocked] = useMemo(() => {
    if (!!selectableMembers.length) {
      const list: string[][] = [[], []];

      selectableMembers.forEach(([id, m]) => {
        if (!!m) {
          if (
            !!membersResponse
              // Get this member's shifts
              .data!.events.filter(({ member }) => member?.id === id)
              .some(({ hasNotes }) => !hasNotes)
          )
            list[0].push(m.id);
        }
      });

      selectableMembers.forEach(([id, m]) => {
        if (!!m) {
          if (
            !!membersResponse
              // Get this member's shifts
              .data!.events.filter(({ member }) => member?.id === id)
              .some(
                ({ clockedOffAt, clockedOnAt }) =>
                  !clockedOnAt || !clockedOffAt,
              )
          )
            list[1].push(m.id);
        }
      });

      return [list[0], list[1]];
    }

    return [[], []];
  }, [selectableMembers, membersResponse.data]);

  const canCheckMultiple = useMemo(
    () =>
      !!selectableMembers
        .filter(([id]) => members.includes(id))
        .filter(
          ([id]) =>
            !memberPayrollChecksResponse.data?.memberPayrollChecks.find(
              ({ member }) => id === member.id,
            )?.paid,
        ).length,
    [
      memberPayrollChecksResponse.data?.memberPayrollChecks,
      members,
      selectableMembers,
    ],
  );

  const memberPayrollCheckAll = useCallback(() => {
    if (canCheckMultiple)
      selectableMembers
        .filter(([id]) => members.includes(id))
        .filter(
          ([id]) =>
            !memberPayrollChecksResponse.data?.memberPayrollChecks.find(
              ({ member }) => id === member.id,
            )?.paid,
        )
        .forEach(([id]) => {
          handleSetMemberPayrollCheck({
            member: { id },
            paid: true,
          });
        });
  }, [
    canCheckMultiple,
    handleSetMemberPayrollCheck,
    members,
    selectableMembers,
    memberPayrollChecksResponse.data,
  ]);

  /**
   * Set select field default value
   */
  useEffect(() => {
    if (!!selectableMembers?.length)
      setValue(
        'members',
        selectableMembers.map(([id]) => id),
      );
  }, [selectableMembers, setValue]);

  /**
   * Fetch events and public holidays
   */
  const fetchData = () => {
    if (
      !eventsResponse.loading &&
      !publicHolidaysResponse.loading &&
      from &&
      to &&
      !!members.length &&
      (isBefore(from, to) || period === 'custom')
    ) {
      const where: EventsWhereInput = {
        endAt: { _gte: from },
        startAt: { _lte: addDays(to, 1) },
        payable: { _eq: true },
        member: { id: { _in: members } },
        client: { firstName: { _ne: 'Reminders' } },
        // notes: hasNotes ? { _ne: null } : undefined,
        // clockedOnAt: isClocked ? { _ne: null } : undefined,
        // clockedOffAt: isClocked ? { _ne: null } : undefined,
      };

      getEvents({
        variables: {
          input: {
            where,
            orderBy: [{ startAt: OrderBy.ASC }],
          },
        },
      });

      getPublicHolidays({
        variables: {
          input: {
            orderBy: [{ date: OrderBy.ASC }],
            where: {
              date: {
                _gte: jsDateToLocalISO8601DateString(from),
                _lte: jsDateToLocalISO8601DateString(subMilliseconds(to, 1)),
              },
            },
          },
        },
      });
    }
  };

  /**
   * Generate report from fetched data
   */
  useEffect(() => {
    if (!!eventsResponse.data && !!publicHolidaysResponse.data && from && to) {
      // Valiate existance of externIds for each discovered member
      if (eventsResponse.data.events.some((e) => !e.member?.externalId)) {
        alert.push({
          message:
            'Set MYOB id(s) for: ' +
            intersperse({
              array: _.union(
                eventsResponse.data.events
                  .filter((e) => !e.member?.externalId)
                  .map((e) =>
                    formatPersonName(e.member!, { capitaliseLastName: true }),
                  ),
              ),
            }).join(''),
          severity: 'error',
        });
      }

      // Valiate existance of ndisIds for each discovered client
      if (eventsResponse.data.events.some((e) => !e.client?.ndisId)) {
        alert.push({
          message:
            'Set NDIS id(s) for: ' +
            intersperse({
              array: _.union(
                eventsResponse.data.events
                  .filter((e) => !e.client?.ndisId)
                  .map((e) =>
                    formatPersonName(e.client!, { capitaliseLastName: true }),
                  ),
              ),
            }).join(''),
          severity: 'error',
        });
      }

      // Valiate existance of plan managers for each discovered client
      if (eventsResponse.data.events.some((e) => !e.client?.planManager)) {
        alert.push({
          message:
            'Set plan manager(s) for: ' +
            intersperse({
              array: _.union(
                eventsResponse.data.events
                  .filter((e) => !e.client?.planManager)
                  .map((e) =>
                    formatPersonName(e.client!, { capitaliseLastName: true }),
                  ),
              ),
            }).join(''),
          severity: 'error',
        });
      }

      let filteredEvents = eventsResponse.data.events;

      // Filter out non-verified activities.
      if (exportOnlyVerified) {
        const verifiedMembers = members.filter((id) =>
          membersResponse.data?.events
            .filter(({ member }) => member?.id === id)
            .every(({ verified }) => verified),
        );

        filteredEvents = filteredEvents.filter(
          ({ member }) => !!member && verifiedMembers.includes(member.id),
        );
      }

      // Filter out already-paid activities.
      if (exportOnlyNonPaid) {
        const nonPaidMembers = members.filter(
          (id) =>
            !memberPayrollChecksResponse.data?.memberPayrollChecks.find(
              ({ member }) => member?.id === id,
            )?.paid,
        );

        filteredEvents = filteredEvents.filter(
          ({ member }) => !!member && nonPaidMembers.includes(member.id),
        );
      }

      setReportData(
        generateActivitySlips({
          capitaliseLastNames,
          after: from,
          before: addDays(to, 1),
          events: filteredEvents,
          publicHolidays: publicHolidaysResponse.data?.publicHolidays,
        }),
      );
    }
  }, [
    eventsResponse.data,
    publicHolidaysResponse.data,
    from,
    to,
    capitaliseLastNames,
    exportOnlyNonPaid,
    exportOnlyVerified,
  ]);

  return (
    <div className={classes.grid}>
      <Box className={classes.row}>
        <Select
          control={control}
          name="period"
          label="Reporting period"
          items={periods}
          formControlProps={{ variant: 'outlined', size: 'small' }}
        />
        {period && (
          <Box className={classes.row}>
            <DateInput
              disableToolbar
              autoOk
              control={control}
              name="from"
              label="From start of day"
              inputVariant="outlined"
              size="small"
            />
            <DateInput
              disableToolbar
              autoOk
              control={control}
              name="to"
              label="To end of day"
              inputVariant="outlined"
              size="small"
            />
          </Box>
        )}
      </Box>
      {!!period && !!selectableMembers && (
        <>
          <Box className={classes.row}>
            <Select
              multiple
              control={control}
              defaultValue={[]}
              name="members"
              label="Employees"
              items={selectableMembers.map(([_, member]) => ({
                label: formatPersonName(member, { lastNameFirst: true }),
                value: member.id,
              }))}
              formControlProps={{
                variant: 'outlined',
                size: 'small',
                style: { width: 200 },
              }}
              renderValue={() => members.length + ' selected'}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setValue('members', selectableMembers.map(([id]) => id) || []);
              }}
            >
              Select All
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setValue('members', []);
              }}
            >
              Clear Selected
            </Button>
          </Box>
          <Box className={classes.row}>
            <Checkbox
              defaultChecked
              control={control}
              name="capitaliseLastNames"
              label="Capitalise employee last names in report"
            />
          </Box>
          <Box className={classes.row}>
            <Checkbox
              defaultChecked
              control={control}
              name="exportOnlyVerified"
              label="Export only fully-verified employees"
            />
          </Box>
          <Box className={classes.row}>
            <Checkbox
              defaultChecked
              control={control}
              name="exportOnlyNonPaid"
              label="Export only not-yet-paid employees"
            />
          </Box>
        </>
      )}
      {!!members?.length && (
        <>
          <Box className={classes.row}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading || !from || !to || !members || !members.length}
              onClick={() => {
                fetchData();
              }}
            >
              Generate Report
            </Button>
            {members && !!members.length && reportData && !loading && (
              <CSVLink
                style={{ justifySelf: 'center' }}
                data={reportData}
                headers={headers}
                filename={
                  format(from ? from : startOfToday(), 'yyyyMMdd') +
                  '-' +
                  format(to ? to : startOfToday(), 'yyyyMMdd') +
                  '_activity-slips-myob.txt'
                }
                target="_blank"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  Download CSV
                </Button>
              </CSVLink>
            )}
          </Box>
          <Divider />
          <Box className={classes.infoBox}>
            <Typography>
              {membersResponse.data?.events.length}{' '}
              {pluralise({
                singular: 'shift',
                plural: 'shifts',
                quantity: membersResponse.data?.events.length ?? 0,
              })}{' '}
              occured during this period of time
            </Typography>
            {reportData && (
              <Typography>
                {reportData.length}{' '}
                {pluralise({
                  singular: 'record',
                  plural: 'records',
                  quantity: reportData.length,
                })}{' '}
                generated
              </Typography>
            )}
          </Box>
          <Table
            inline
            showIndexColumn
            enableRowHighlighting
            persistentRowHighlighting
            loading={loading}
            backgroundColor={theme.palette.background.paper}
            wrapperStyle={{ flex: '1 1 0' }}
          >
            <TableHeader sticky order="ASC" title="Name of the employee">
              Employee
            </TableHeader>
            <TableHeader align="center" title="External ID of the employee.">
              External ID
            </TableHeader>
            <TableHeader
              align="center"
              title="Quantity of months the employee has been actively working."
            >
              Active Months
            </TableHeader>
            <TableHeader
              align="center"
              title="Is this employee eligible for the bonus rate?"
            >
              Bonus Eligible
            </TableHeader>
            <TableHeader
              align="center"
              title="Has this employee completed all their case notes?'"
            >
              No Notes
            </TableHeader>
            <TableHeader
              align="center"
              title="Has this employee clocked on and off correctly?"
            >
              No Clocks
            </TableHeader>
            <TableHeader
              align="center"
              title="Total shifts this person has attended in the given time period."
            >
              Shifts
            </TableHeader>
            <TableHeader
              align="center"
              title="Do shifts have unresolved issues?"
            >
              Issues
            </TableHeader>

            <TableHeader
              align="center"
              title="Have all shifts attended to by this employee been verified?"
            >
              Verified
            </TableHeader>
            <TableHeader
              align="center"
              className={classes.paidHeader}
              title="Has this employee been paid?"
              onClick={memberPayrollCheckAll}
            >
              <div id="paid">Paid</div>
              <div id="pay-all">
                <div>Check All</div>
                <CheckIcon />
              </div>
            </TableHeader>
            {selectableMembers
              .filter(([id]) => members.includes(id))
              .map(([_, member], i) => {
                const eventsCount = membersResponse.data?.events.filter(
                  (event) => event.member?.id === member.id,
                ).length;

                const verifiedEventsCount = membersResponse.data?.events.filter(
                  (event) => event.verified && event.member?.id === member.id,
                ).length;

                const issueCount = membersResponse.data?.events
                  .filter(
                    (event) =>
                      event.issues.length && event.member?.id === member.id,
                  )
                  .flatMap(({ issues }) => issues)
                  .filter(({ closedAt }) => !closedAt).length;

                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Link
                        to={
                          _schedule.path +
                          `?${
                            !!from ? 'f=' + format(from, 'ddMMyyyy') + '&' : ''
                          }m=${member.id}`
                        }
                      >
                        {formatPersonName(member, {
                          capitaliseLastName: true,
                          lastNameFirst: true,
                        })}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography style={{ justifySelf: 'center' }}>
                        {member.externalId ? (
                          member.externalId
                        ) : (
                          <PriorityHighRoundedIcon color="error" />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography style={{ justifySelf: 'center' }}>
                        {!!to &&
                          !!member.employmentStartDate &&
                          Math.max(
                            0,
                            differenceInMonths(
                              to,
                              new Date(member.employmentStartDate),
                            ),
                          )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography style={{ justifySelf: 'center' }}>
                        {member.bonusEligible && <CheckIcon />}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {memberIdsMissingNotes.includes(member.id) && (
                        <PriorityHighRoundedIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      {memberIdsNotClocked.includes(member.id) && (
                        <PriorityHighRoundedIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      <div style={{ justifySelf: 'center' }}>{eventsCount}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{ justifySelf: 'center' }}>
                        {issueCount === 0 ? (
                          <CheckIcon />
                        ) : (
                          <span
                            style={{
                              color: 'red',
                              fontWeight: theme.typography.fontWeightMedium,
                            }}
                          >
                            {issueCount}
                          </span>
                        )}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div style={{ justifySelf: 'center' }}>
                        {verifiedEventsCount === eventsCount ? (
                          <CheckIcon />
                        ) : (
                          verifiedEventsCount + ' / ' + eventsCount
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <MuiCheckbox
                        size="small"
                        color="default"
                        checked={
                          !!memberPayrollChecksResponse.data?.memberPayrollChecks.find(
                            ({ member: { id } }) => id === member.id,
                          )?.paid
                        }
                        onChange={(event) =>
                          handleSetMemberPayrollCheck({
                            member: { id: member.id },
                            paid: event.target.checked,
                          })
                        }
                        onClick={(event) => event.stopPropagation()}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </Table>
        </>
      )}
    </div>
  );
};

export default ReportActivitySlips;
