import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ABN: any;
  ClientContactRelationScaler: any;
  ClientFileTypeScaler: any;
  Date: any;
  DateTime: any;
  EmailAddress: any;
  HexColorCode: any;
  InfiniteInt: any;
  JWT: any;
  MemberFileTypeScaler: any;
  NdisId: any;
  NdisSupportItemTypeScaler: any;
  NdisSupportItemUnitScaler: any;
  PayrollCategoryValueTypeScaler: any;
  PostalCode: any;
  UUID: any;
  Upload: any;
  UserIdentifier: any;
  UserPlainTextPassword: any;
};

/** Australian Business Number conditional expression input. */
export type AbnConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['ABN']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['ABN']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['ABN']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['ABN']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['ABN']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['ABN']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['ABN']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['ABN']>>;
};

export type AccessLog = {
  __typename?: 'AccessLog';
  /** Actor. */
  actor: Member;
  /** Entity accessed. */
  client?: Maybe<Client>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Date of access. */
  date: Scalars['DateTime'];
  /** Entity accessed. */
  event?: Maybe<Event>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** IP address of client during successful login */
  ip: Scalars['String'];
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for entities. */
export type AccessLogsAggregate = {
  __typename?: 'AccessLogsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<AccessLog>;
};

/** Ordering options for entities. */
export type AccessLogsOrderByInput = {
  /** Order by actor. */
  actor?: InputMaybe<MembersOrderByInput>;
  /** Order by date. */
  date?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by IP address. */
  ip?: InputMaybe<OrderBy>;
};

/** Filtering conditions for entities. */
export type AccessLogsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<AccessLogsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<AccessLogsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<AccessLogsWhereInput>>;
  /** Actor. */
  actor?: InputMaybe<MembersWhereInput>;
  /** Accessed entity. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Date. */
  date?: InputMaybe<DateTimeConditionalInput>;
  /** Accessed entity. */
  event?: InputMaybe<EventsWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** IP address. */
  ip?: InputMaybe<StringConditionalInput>;
};

/** An address */
export type Address = {
  /** Building name. */
  buildingName?: Maybe<Scalars['String']>;
  /** Country. */
  country?: Maybe<AddressCountryCode>;
  /** Latitude coordinate. */
  latitude?: Maybe<Scalars['String']>;
  /** Suburb or town. */
  locality?: Maybe<Scalars['String']>;
  /** Longitude coordinate. */
  longitude?: Maybe<Scalars['String']>;
  /** Postcode. */
  postcode?: Maybe<Scalars['PostalCode']>;
  /** State or territory. */
  region?: Maybe<AddressRegionCode>;
  /** Street number, name and type. */
  street?: Maybe<Scalars['String']>;
  /** Unit number. */
  unit?: Maybe<Scalars['String']>;
};

/** Countries. */
export enum AddressCountryCode {
  Afghanistan = 'Afghanistan',
  AlandIslands = 'AlandIslands',
  Albania = 'Albania',
  Algeria = 'Algeria',
  AmericanSamoa = 'AmericanSamoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  Antarctica = 'Antarctica',
  AntiguaAndBarbuda = 'AntiguaAndBarbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  /** Australia */
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  BonaireSintEustatiusSaba = 'BonaireSintEustatiusSaba',
  BosniaAndHerzegovina = 'BosniaAndHerzegovina',
  Botswana = 'Botswana',
  BouvetIsland = 'BouvetIsland',
  Brazil = 'Brazil',
  BritishIndianOceanTerritory = 'BritishIndianOceanTerritory',
  BruneiDarussalam = 'BruneiDarussalam',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'BurkinaFaso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'CapeVerde',
  CaymanIslands = 'CaymanIslands',
  CentralAfricanRepublic = 'CentralAfricanRepublic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  ChristmasIsland = 'ChristmasIsland',
  CocosKeelingIslands = 'CocosKeelingIslands',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CongoDemocraticRepublic = 'CongoDemocraticRepublic',
  CookIslands = 'CookIslands',
  CostaRica = 'CostaRica',
  CoteDIvoire = 'CoteDIvoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Curacao = 'Curacao',
  Cyprus = 'Cyprus',
  CzechRepublic = 'CzechRepublic',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  DominicanRepublic = 'DominicanRepublic',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  ElSalvador = 'ElSalvador',
  EquatorialGuinea = 'EquatorialGuinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FalklandIslands = 'FalklandIslands',
  FaroeIslands = 'FaroeIslands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'FrenchGuiana',
  FrenchPolynesia = 'FrenchPolynesia',
  FrenchSouthernTerritories = 'FrenchSouthernTerritories',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadeloupe = 'Guadeloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guernsey = 'Guernsey',
  Guinea = 'Guinea',
  GuineaBissau = 'GuineaBissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  HeardIslandMcdonaldIslands = 'HeardIslandMcdonaldIslands',
  HolySeeVaticanCityState = 'HolySeeVaticanCityState',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  IsleOfMan = 'IsleOfMan',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jersey = 'Jersey',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  Korea = 'Korea',
  KoreaDemocraticPeoplesRepublic = 'KoreaDemocraticPeoplesRepublic',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  LaoPeoplesDemocraticRepublic = 'LaoPeoplesDemocraticRepublic',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  LibyanArabJamahiriya = 'LibyanArabJamahiriya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macao = 'Macao',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'MarshallIslands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Micronesia = 'Micronesia',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NewCaledonia = 'NewCaledonia',
  NewZealand = 'NewZealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  Niue = 'Niue',
  NorfolkIsland = 'NorfolkIsland',
  NorthernMarianaIslands = 'NorthernMarianaIslands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  PalestinianTerritory = 'PalestinianTerritory',
  Panama = 'Panama',
  PapuaNewGuinea = 'PapuaNewGuinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Pitcairn = 'Pitcairn',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'PuertoRico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  RussianFederation = 'RussianFederation',
  Rwanda = 'Rwanda',
  SaintBarthelemy = 'SaintBarthelemy',
  SaintHelena = 'SaintHelena',
  SaintKittsAndNevis = 'SaintKittsAndNevis',
  SaintLucia = 'SaintLucia',
  SaintMartin = 'SaintMartin',
  SaintPierreAndMiquelon = 'SaintPierreAndMiquelon',
  SaintVincentAndGrenadines = 'SaintVincentAndGrenadines',
  Samoa = 'Samoa',
  SanMarino = 'SanMarino',
  SaoTomeAndPrincipe = 'SaoTomeAndPrincipe',
  SaudiArabia = 'SaudiArabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  SierraLeone = 'SierraLeone',
  Singapore = 'Singapore',
  SintMaarten = 'SintMaarten',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SolomonIslands = 'SolomonIslands',
  Somalia = 'Somalia',
  SouthAfrica = 'SouthAfrica',
  SouthGeorgiaAndSandwichIsl = 'SouthGeorgiaAndSandwichIsl',
  SouthSudan = 'SouthSudan',
  Spain = 'Spain',
  SriLanka = 'SriLanka',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  SvalbardAndJanMayen = 'SvalbardAndJanMayen',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  SyrianArabRepublic = 'SyrianArabRepublic',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  TimorLeste = 'TimorLeste',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'TrinidadAndTobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'TurksAndCaicosIslands',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'UnitedArabEmirates',
  UnitedKingdom = 'UnitedKingdom',
  UnitedStates = 'UnitedStates',
  UnitedStatesOutlyingIslands = 'UnitedStatesOutlyingIslands',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  VirginIslandsBritish = 'VirginIslandsBritish',
  VirginIslandsUS = 'VirginIslandsUS',
  WallisAndFutuna = 'WallisAndFutuna',
  WesternSahara = 'WesternSahara',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
}

/** Australian states and territories. */
export enum AddressRegionCode {
  ACT = 'ACT',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
}

/** Aggregation data */
export type AggregateData = {
  __typename?: 'AggregateData';
  /** Total nodes discovered. */
  totalCount: Scalars['Int'];
};

/** Input data for archiving entities. */
export type ArchiveInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Reason for archiving. */
  reason?: InputMaybe<Scalars['String']>;
};

/** BaseHistory interface. */
export type BaseHistory = {
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Boolean conditional expression input. */
export type BooleanConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['Boolean']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean response */
export type BooleanResponse = {
  __typename?: 'BooleanResponse';
  /** Value. */
  value: Scalars['Boolean'];
};

export type Branch = {
  __typename?: 'Branch';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: Member;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  name: Scalars['String'];
  org: Org;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Filtering conditions for "BranchMember" entities. */
export type BranchMembersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<BranchMembersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<BranchMembersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<BranchMembersWhereInput>>;
  /** Linked Branch entity. */
  branch?: InputMaybe<BranchesWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Eraser of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Linked Member entity. */
  member?: InputMaybe<MembersWhereInput>;
};

/** Aggregated response object for "Branch" entities. */
export type BranchesAggregate = {
  __typename?: 'BranchesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Branch>;
};

/** Creatable fields for "Branch" entities */
export type BranchesCreatableFieldsInput = {
  name: Scalars['String'];
};

/** Ordering options for "Branch" entities. */
export type BranchesOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Branch" entities. */
export type BranchesUpdatableFieldsInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "Branch" entities. */
export type BranchesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<BranchesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<BranchesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<BranchesWhereInput>>;
  /** BranchMember associative entities. */
  branchMembers?: InputMaybe<BranchMembersWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
};

/** A business */
export type Business = {
  /** Australian Business Number of the organisation */
  abn?: Maybe<Scalars['ABN']>;
  /** Legal name */
  legalName: Scalars['String'];
  /** Preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** Website of the organisation */
  website?: Maybe<Scalars['String']>;
};

/** A client of an organisation. */
export type Client = Address &
  Person & {
    __typename?: 'Client';
    /** Active status. */
    active: Scalars['Boolean'];
    /** Age in years. */
    age?: Maybe<Scalars['Int']>;
    /** Allow automatic population of data for unattended future events. */
    allowAutomaticEventPopulation: Scalars['Boolean'];
    /** Allow recording of medication. */
    allowMedication: Scalars['Boolean'];
    /** Allow recording of observations. */
    allowObservations: Scalars['Boolean'];
    /** Allow recording of zeisures. */
    allowRecordSeizures: Scalars['Boolean'];
    /** Archive data, if entity is archived. */
    archive?: Maybe<HistoryRestorable>;
    /** Biography. */
    bio?: Maybe<Scalars['String']>;
    /** Diastolic blood pressure lower-limit warning threshold (level 1). */
    bloodDiastolicLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure lower-limit warning threshold (level 2). */
    bloodDiastolicLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure upper-limit warning threshold (level 1). */
    bloodDiastolicUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure upper-limit warning threshold (level 2). */
    bloodDiastolicUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure lower-limit warning threshold (level 1). */
    bloodSystolicLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure lower-limit warning threshold (level 2). */
    bloodSystolicLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure upper-limit warning threshold (level 1). */
    bloodSystolicUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure upper-limit warning threshold (level 2). */
    bloodSystolicUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Branch to which this client belongs. */
    branch: Branch;
    /** Building name. */
    buildingName?: Maybe<Scalars['String']>;
    /** Hexidecimal color. */
    color?: Maybe<Scalars['HexColorCode']>;
    /** Confidential notes. */
    confidentialNotes?: Maybe<Scalars['String']>;
    contacts?: Maybe<Array<ClientContact>>;
    /** Contacts Email address. */
    contactsEmail?: Maybe<Scalars['String']>;
    /** Contacts name. */
    contactsName?: Maybe<Scalars['String']>;
    /** Contacts Phone number. */
    contactsPhone?: Maybe<Scalars['String']>;
    /** Address country. */
    country?: Maybe<AddressCountryCode>;
    /** Datetime of the entity's creation. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Creator of the entity. */
    createdBy?: Maybe<Member>;
    currentNdisPlan?: Maybe<ClientNdisPlan>;
    /** Default duration (in hours) to use when creating events. */
    defaultEventDuration?: Maybe<Scalars['Float']>;
    /** Deletion data, if entity is deleted. */
    delete?: Maybe<HistoryRestorable>;
    /** Date of birth. */
    dob?: Maybe<Scalars['Date']>;
    /** Email address. */
    email?: Maybe<Scalars['String']>;
    events?: Maybe<Array<Event>>;
    /** External ID. */
    externalId?: Maybe<Scalars['String']>;
    firstBillableEvent?: Maybe<Event>;
    /** First name. */
    firstName: Scalars['String'];
    /** Gender. */
    gender?: Maybe<Gender>;
    hasFile: Scalars['Boolean'];
    /** Heart rate lower-limit warning threshold (level 1). */
    heartLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Heart rate lower-limit warning threshold. (level 2) */
    heartLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Heart rate upper-limit warning threshold (level 1). */
    heartUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Heart rate upper-limit warning threshold (level 2). */
    heartUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Unique identifier of the entity. */
    id: Scalars['ID'];
    lastBillableEvent?: Maybe<Event>;
    /** Last name. */
    lastName?: Maybe<Scalars['String']>;
    /** Address latitude coordinate. */
    latitude?: Maybe<Scalars['String']>;
    /** Address suburb or town. */
    locality?: Maybe<Scalars['String']>;
    /** Address longitude coordinate. */
    longitude?: Maybe<Scalars['String']>;
    medications?: Maybe<Array<ClientMedication>>;
    /** Middle name. */
    middleName?: Maybe<Scalars['String']>;
    /** Minutes between first and last billable events. */
    minutesBetweenFirstAndLastBillableEvents?: Maybe<Scalars['Int']>;
    /** Module: Core Support. */
    moduleCS: Scalars['Boolean'];
    /** Module: Support Coordination. */
    moduleSC: Scalars['Boolean'];
    /** NDIS participant ID. */
    ndisId?: Maybe<Scalars['NdisId']>;
    /** End datetime of the NDIS plan. */
    ndisPlanEndDate?: Maybe<Scalars['Date']>;
    /** NDIS plan initial funds capacity building. */
    ndisPlanInitialFundsCb?: Maybe<Scalars['Int']>;
    /** NDIS plan initial funds core support. */
    ndisPlanInitialFundsCore?: Maybe<Scalars['Int']>;
    /** NDIS plan initial funds community service. */
    ndisPlanInitialFundsCs?: Maybe<Scalars['Int']>;
    /** Start date of the NDIS plan. */
    ndisPlanStartDate?: Maybe<Scalars['Date']>;
    /** Administrator-only notes. */
    notes?: Maybe<Scalars['String']>;
    /** Organisation to which this client belongs. */
    org: Org;
    /** Phone number. */
    phone?: Maybe<Scalars['String']>;
    /** Plan manager for the client. */
    planManager?: Maybe<PlanManager>;
    /** Address postcode. */
    postcode?: Maybe<Scalars['PostalCode']>;
    /** Preferred contact. */
    preferredContact?: Maybe<ClientContact>;
    /** Preferred name. */
    preferredName?: Maybe<Scalars['String']>;
    /** Pronouns. */
    pronouns?: Maybe<Pronouns>;
    /** Address state or territory. */
    region?: Maybe<AddressRegionCode>;
    /** Event requirement: Handover. */
    requireHandover: Scalars['Boolean'];
    /** Respiration lower-limit warning threshold. (level 1) */
    respirationLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Respiration lower-limit warning threshold. (level 2) */
    respirationLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Respiration upper-limit warning threshold. (level 1) */
    respirationUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Respiration upper-limit warning threshold. (level 2) */
    respirationUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Salutation. */
    salutation?: Maybe<Salutation>;
    /** Shortened name. */
    shortName: Scalars['String'];
    /** Spo2 lower-limit warning threshold. (level 1) */
    spo2LowerThreshold1?: Maybe<Scalars['Int']>;
    /** Spo2 lower-limit warning threshold. (level 2) */
    spo2LowerThreshold2?: Maybe<Scalars['Int']>;
    /** Spo2 upper-limit warning threshold. (level 1) */
    spo2UpperThreshold1?: Maybe<Scalars['Int']>;
    /** Spo2 upper-limit warning threshold. (level 2) */
    spo2UpperThreshold2?: Maybe<Scalars['Int']>;
    /** Address street number, name and type. */
    street?: Maybe<Scalars['String']>;
    /** Support coordinator for the client. */
    supportCoordinator?: Maybe<SupportCoordinator>;
    teams?: Maybe<Array<ClientTeam>>;
    /** Timezone. */
    timezone: Timezone;
    /** Accumulated billable minutes. */
    totalBillableMinutes?: Maybe<Scalars['Int']>;
    /** File-tracking status. */
    trackFiles: Scalars['Boolean'];
    /** Address unit number. */
    unit?: Maybe<Scalars['String']>;
    /** Datetime of the entity record's most recent update. */
    updatedAt?: Maybe<Scalars['DateTime']>;
  };

/** A client of an organisation. */
export type ClientContactsArgs = {
  input?: InputMaybe<QueryClientContactsInput>;
};

/** A client of an organisation. */
export type ClientEventsArgs = {
  input?: InputMaybe<QueryEventsInput>;
};

/** A client of an organisation. */
export type ClientHasFileArgs = {
  input: QueryClientHasFileInput;
};

/** A client of an organisation. */
export type ClientMedicationsArgs = {
  input?: InputMaybe<QueryClientMedicationsInput>;
};

/** A client of an organisation. */
export type ClientTeamsArgs = {
  input?: InputMaybe<QueryClientTeamsInput>;
};

export type ClientContact = {
  __typename?: 'ClientContact';
  /** Linked client. */
  client: Client;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Custom type. */
  customRelation?: Maybe<Scalars['String']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Email address. */
  email?: Maybe<Scalars['String']>;
  /** Contact is an emergency contact. */
  emergencyContact: Scalars['Boolean'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name. */
  name: Scalars['String'];
  /** Phone number. */
  phone?: Maybe<Scalars['String']>;
  /** Contact type. */
  relations?: Maybe<Array<ClientContactRelation>>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** A contact's relation to their client */
export enum ClientContactRelation {
  BROTHER = 'BROTHER',
  CARER = 'CARER',
  DAUGHTER = 'DAUGHTER',
  FATHER = 'FATHER',
  GUARDIAN = 'GUARDIAN',
  HUSBAND = 'HUSBAND',
  MOTHER = 'MOTHER',
  NDIS_NOMINEE = 'NDIS_NOMINEE',
  NEIGHBOUR = 'NEIGHBOUR',
  PARENT = 'PARENT',
  PARTNER = 'PARTNER',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  SIBLING = 'SIBLING',
  SISTER = 'SISTER',
  SON = 'SON',
  SUPPORT_COORDINATOR = 'SUPPORT_COORDINATOR',
  WIFE = 'WIFE',
}

/** String conditional expression input. */
export type ClientContactRelationConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['ClientContactRelationScaler']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['ClientContactRelationScaler']>>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['ClientContactRelationScaler']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['ClientContactRelationScaler']>>;
};

/** Aggregated response object for "ClientContact" entities. */
export type ClientContactsAggregate = {
  __typename?: 'ClientContactsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientContact>;
};

/** Ordering options for "ClientContact" entities. */
export type ClientContactsOrderByInput = {
  /** Order by client. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by custom relation. */
  customRelation?: InputMaybe<OrderBy>;
  /** Order by emergency contact. */
  emergencyContact?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
  /** Order by relations. */
  relations?: InputMaybe<OrderBy>;
};

/** Updatable fields for "ClientContact" entities. */
export type ClientContactsUpdatableFieldsInput = {
  /** Custom type. */
  customRelation?: InputMaybe<Scalars['String']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** Contact is an emergency contact. */
  emergencyContact?: InputMaybe<Scalars['Boolean']>;
  /** Name. */
  name?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred contact. */
  preferred?: InputMaybe<Scalars['Boolean']>;
  /** Contact type. */
  relations?: InputMaybe<Array<ClientContactRelation>>;
};

/** Filtering conditions for "ClientContact" entities. */
export type ClientContactsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientContactsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientContactsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientContactsWhereInput>>;
  /** Client. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Custom relation. */
  customRelation?: InputMaybe<StringConditionalInput>;
  /** Is emergency contact. */
  emergencyContact?: InputMaybe<BooleanConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
  /** Relations. */
  relations?: InputMaybe<ClientContactRelationConditionalInput>;
};

export type ClientFile = {
  __typename?: 'ClientFile';
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: Maybe<Scalars['Date']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Expiry date of file. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  file: File;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Date file was issued. */
  issuedAt?: Maybe<Scalars['DateTime']>;
  /** Owner of the file. */
  owner: Client;
  type: ClientFileType;
  value?: Maybe<Scalars['String']>;
};

/** Type of file belonging to a "Client" entity. */
export enum ClientFileType {
  AC_HANDBOOK = 'AC_HANDBOOK',
  CAREPLAN = 'CAREPLAN',
  CHANGE_OF_CIRCUMSTANCES = 'CHANGE_OF_CIRCUMSTANCES',
  CLIENT_NEEDS = 'CLIENT_NEEDS',
  CONFLICT_OF_INTEREST = 'CONFLICT_OF_INTEREST',
  CONSENT_TO_ACT = 'CONSENT_TO_ACT',
  CONSENT_TO_ACT_NDIS = 'CONSENT_TO_ACT_NDIS',
  CONSENT_TO_SHARE = 'CONSENT_TO_SHARE',
  CONSENT_TO_SHARE_NDIS = 'CONSENT_TO_SHARE_NDIS',
  CURRENT_MEDICATION = 'CURRENT_MEDICATION',
  EMERGENCY_RESPONSE_PLAN = 'EMERGENCY_RESPONSE_PLAN',
  FUNCTIONAL_ASSESMENT = 'FUNCTIONAL_ASSESMENT',
  GP = 'GP',
  GUARDIANSHIP_ORDERS = 'GUARDIANSHIP_ORDERS',
  INCIDENT_REPORT = 'INCIDENT_REPORT',
  INITIAL_CONSULT = 'INITIAL_CONSULT',
  LETTER_TO_PARTICIPANT_NDIS = 'LETTER_TO_PARTICIPANT_NDIS',
  MANUAL_HANDLING_PLAN = 'MANUAL_HANDLING_PLAN',
  MISC = 'MISC',
  NDIS_NOMINEE = 'NDIS_NOMINEE',
  NDIS_PLAN = 'NDIS_PLAN',
  NDIS_SERVICE_BOOKING = 'NDIS_SERVICE_BOOKING',
  OCCUPATIONAL_THERAPIST = 'OCCUPATIONAL_THERAPIST',
  OTHER_SPECIALISTS = 'OTHER_SPECIALISTS',
  PARTICIPANT_CHARTER = 'PARTICIPANT_CHARTER',
  PHYSIOTHERAPIST = 'PHYSIOTHERAPIST',
  PLAN_MANAGER = 'PLAN_MANAGER',
  POSITIVE_SUPPORT_PLAN = 'POSITIVE_SUPPORT_PLAN',
  PRIVACY_CONSENT = 'PRIVACY_CONSENT',
  PRIVACY_STATEMENT = 'PRIVACY_STATEMENT',
  PROGRESS_REPORT = 'PROGRESS_REPORT',
  PSYCHOLOGIST = 'PSYCHOLOGIST',
  QUOTE = 'QUOTE',
  REFERRALS = 'REFERRALS',
  RISK_ASSESSMENT = 'RISK_ASSESSMENT',
  SC_PLAN = 'SC_PLAN',
  SERVICE_AGREEMENT = 'SERVICE_AGREEMENT',
  TEST_RESULTS = 'TEST_RESULTS',
}

/** String conditional expression input. */
export type ClientFileTypeConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['ClientFileTypeScaler']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['ClientFileTypeScaler']>>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['ClientFileTypeScaler']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['ClientFileTypeScaler']>>;
};

/** Aggregated response object for "ClientFile" entities. */
export type ClientFilesAggregate = {
  __typename?: 'ClientFilesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientFile>;
};

/** Ordering options for "ClientFile" entities. */
export type ClientFilesOrderByInput = {
  /** Order by date-of-file. */
  dateOfFile?: InputMaybe<OrderBy>;
  /** Order by expiry date. */
  expiresAt?: InputMaybe<OrderBy>;
  /** Order by file. */
  file?: InputMaybe<FilesOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by issue date. */
  issuedAt?: InputMaybe<OrderBy>;
  /** Order by value. */
  value?: InputMaybe<OrderBy>;
};

/** Updatable fields for "ClientFile" entities. */
export type ClientFilesUpdatableFieldsInput = {
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Update to apply to linked file entity. */
  file?: InputMaybe<UpdateFileInput>;
  /** Date file was issued. */
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  /** Owner of the file. */
  owner?: InputMaybe<QueryByIdInput>;
  type?: InputMaybe<ClientFileType>;
  value?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "ClientFile" entities. */
export type ClientFilesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientFilesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientFilesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientFilesWhereInput>>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<DateTimeConditionalInput>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<DateTimeConditionalInput>;
  /** File. */
  file?: InputMaybe<FilesWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Issue date of file. */
  issuedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Owner. */
  owner?: InputMaybe<ClientsWhereInput>;
  /** File type. */
  type?: InputMaybe<ClientFileTypeConditionalInput>;
  /** Value. */
  value?: InputMaybe<StringConditionalInput>;
};

/** Associative entity joining Client and Medication entities. */
export type ClientMedication = {
  __typename?: 'ClientMedication';
  /** Linked Client entity. */
  client: Client;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Datetime of entity's deletion. */
  deletedAt: Scalars['DateTime'];
  /** Eraser of the entity. */
  deletedBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked Medication entity. */
  medication: Medication;
};

/** Aggregated response object for "ClientMedication" entities. */
export type ClientMedicationsAggregate = {
  __typename?: 'ClientMedicationsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientMedication>;
};

/** Ordering options for "ClientMedication" entities. */
export type ClientMedicationsOrderByInput = {
  /** Order by linked client. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by linked medication. */
  medication?: InputMaybe<MedicationsOrderByInput>;
};

/** Filtering conditions for "ClientMedication" entities. */
export type ClientMedicationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientMedicationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientMedicationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientMedicationsWhereInput>>;
  /** Linked client entity. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Eraser of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked medication entity. */
  medication?: InputMaybe<MedicationsWhereInput>;
};

/** An NDIS Plan associated with a Participant of the NDIS. */
export type ClientNdisPlan = {
  __typename?: 'ClientNdisPlan';
  /** Linked "Client" entity. */
  client: Client;
  /** Linked entity for Core Support billing. */
  coreSupportItem?: Maybe<ClientNdisPlanItem>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** End date. */
  endAt?: Maybe<Scalars['Date']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  initialFunds: Scalars['Int'];
  items?: Maybe<Array<ClientNdisPlanItem>>;
  /** Start date. */
  startAt: Scalars['Date'];
  /** Linked entity for Support Coordination billing. */
  supportCoordinationItem?: Maybe<ClientNdisPlanItem>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** An NDIS Plan associated with a Participant of the NDIS. */
export type ClientNdisPlanItemsArgs = {
  input?: InputMaybe<QueryClientNdisPlanItemInput>;
};

/** An item of an NDIS Plan. */
export type ClientNdisPlanItem = {
  __typename?: 'ClientNdisPlanItem';
  /** Category. */
  category?: Maybe<ClientNdisPlanItemCategory>;
  /** Linked "ClientNdisPlan" entity. */
  clientNdisPlan: ClientNdisPlan;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  currentFunds: Scalars['Int'];
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Initial funds. */
  initialFunds: Scalars['Int'];
  /** Managed by. */
  managedBy?: Maybe<ClientNdisPlanItemManagedBy>;
  /** Linked "ClientNdisPlanItemName" entity. */
  name: ClientNdisPlanItemName;
  spentFunds: Scalars['Int'];
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** An item of an NDIS Plan. */
export type ClientNdisPlanItemSpentFundsArgs = {
  input?: InputMaybe<QueryClientNdisPlanItemSpentFundsInput>;
};

/** Category of an NDIS Plan Item. */
export enum ClientNdisPlanItemCategory {
  /** Capacity Building supports. */
  CAPACITY_BUILDING = 'CAPACITY_BUILDING',
  /** Capital supports. */
  CAPITAL = 'CAPITAL',
  /** Core supports. */
  CORE = 'CORE',
}

export type ClientNdisPlanItemCreatableFieldsInput = {
  /** Category. */
  category?: InputMaybe<ClientNdisPlanItemCategory>;
  coreSupportItem?: InputMaybe<Scalars['Boolean']>;
  /** Initial funds. */
  initialFunds: Scalars['Int'];
  /** Managed by. */
  managedBy?: InputMaybe<ClientNdisPlanItemManagedBy>;
  name: QueryByIdInput;
  supportCoordinationItem?: InputMaybe<Scalars['Boolean']>;
};

/** Manager of an NDIS Plan Item. */
export enum ClientNdisPlanItemManagedBy {
  /** NDIA. */
  NDIA = 'NDIA',
  /** Plan manager. */
  PLAN_MANAGER = 'PLAN_MANAGER',
  /** Self. */
  SELF = 'SELF',
}

/** A name of an NDIS Plan Item. */
export type ClientNdisPlanItemName = {
  __typename?: 'ClientNdisPlanItemName';
  /** Category. */
  category: ClientNdisPlanItemCategory;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name. */
  name: Scalars['String'];
};

/** Aggregated response object. */
export type ClientNdisPlanItemNameAggregate = {
  __typename?: 'ClientNdisPlanItemNameAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientNdisPlanItemName>;
};

/** Ordering options */
export type ClientNdisPlanItemNameOrderByInput = {
  category?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** Filtering conditions. */
export type ClientNdisPlanItemNameWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientNdisPlanItemNameWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientNdisPlanItemNameWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientNdisPlanItemNameWhereInput>>;
  category?: InputMaybe<IntConditionalInput>;
  id?: InputMaybe<IdConditionalInput>;
  name?: InputMaybe<StringConditionalInput>;
};

/** Ordering options */
export type ClientNdisPlanItemOrderByInput = {
  category?: InputMaybe<OrderBy>;
  clientNdisPlan?: InputMaybe<ClientNdisPlanOrderByInput>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  managedBy?: InputMaybe<OrderBy>;
  name?: InputMaybe<ClientNdisPlanItemNameOrderByInput>;
  transactions?: InputMaybe<ClientNdisPlanItemTransactionOrderByInput>;
};

/** An transaction of an NDIS Plan Item. */
export type ClientNdisPlanItemTransaction = {
  __typename?: 'ClientNdisPlanItemTransaction';
  /** Actor. */
  actor?: Maybe<Member>;
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Billing date. */
  billingDate?: Maybe<Scalars['Date']>;
  /** Linked "ClientNdisPlanItem" entity. */
  clientNdisPlanItem: ClientNdisPlanItem;
  /** Linked "ClientNdisPlanItemTransactionSupplier" entity. */
  clientNdisPlanItemTransactionSupplier?: Maybe<ClientNdisPlanItemTransactionSupplier>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Linked "Event" entity. */
  event?: Maybe<Event>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Invoice number. */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Memo. */
  memo?: Maybe<Scalars['String']>;
  /** Service date end. */
  serviceDateEnd?: Maybe<Scalars['Date']>;
  /** Service date start. */
  serviceDateStart?: Maybe<Scalars['Date']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Initial funds. */
  value: Scalars['Int'];
};

/** Aggregated response object. */
export type ClientNdisPlanItemTransactionAggregate = {
  __typename?: 'ClientNdisPlanItemTransactionAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientNdisPlanItemTransaction>;
};

export type ClientNdisPlanItemTransactionInputCreatableFieldsInput = {
  /** Billing date. */
  billingDate?: InputMaybe<Scalars['Date']>;
  clientNdisPlanItem: QueryByIdInput;
  clientNdisPlanItemTransactionSupplier?: InputMaybe<QueryByIdInput>;
  /** Invoice number. */
  invoiceNumber?: InputMaybe<Scalars['String']>;
  /** Memo. */
  memo?: InputMaybe<Scalars['String']>;
  /** Service date end. */
  serviceDateEnd?: InputMaybe<Scalars['Date']>;
  /** Service date start. */
  serviceDateStart?: InputMaybe<Scalars['Date']>;
  /** Initial funds. */
  value: Scalars['Int'];
};

/** Ordering options */
export type ClientNdisPlanItemTransactionOrderByInput = {
  actor?: InputMaybe<MembersOrderByInput>;
  billingDate?: InputMaybe<OrderBy>;
  clientNdisPlanItem?: InputMaybe<ClientNdisPlanItemOrderByInput>;
  clientNdisPlanItemTransactionSupplier?: InputMaybe<ClientNdisPlanItemTransactionSuppliersOrderByInput>;
  createdAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  invoiceNumber?: InputMaybe<OrderBy>;
  memo?: InputMaybe<OrderBy>;
  serviceDateEnd?: InputMaybe<OrderBy>;
  serviceDateStart?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** A supplier of a goods or service sold to an NDIS participant. */
export type ClientNdisPlanItemTransactionSupplier = {
  __typename?: 'ClientNdisPlanItemTransactionSupplier';
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name of supplier. */
  name: Scalars['String'];
  /** Linked organisation. */
  org: Org;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object. */
export type ClientNdisPlanItemTransactionSuppliersAggregate = {
  __typename?: 'ClientNdisPlanItemTransactionSuppliersAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientNdisPlanItemTransactionSupplier>;
};

/** Ordering options. */
export type ClientNdisPlanItemTransactionSuppliersOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Updatable fields. */
export type ClientNdisPlanItemTransactionSuppliersUpdatableFieldsInput = {
  /** Name of supplier. */
  name?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions. */
export type ClientNdisPlanItemTransactionSuppliersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientNdisPlanItemTransactionSuppliersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientNdisPlanItemTransactionSuppliersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientNdisPlanItemTransactionSuppliersWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
};

export type ClientNdisPlanItemTransactionUpdatableFieldsInput = {
  /** Billing date. */
  billingDate?: InputMaybe<Scalars['Date']>;
  clientNdisPlanItem?: InputMaybe<QueryByIdInput>;
  clientNdisPlanItemTransactionSupplier?: InputMaybe<QueryByIdInput>;
  /** Invoice number. */
  invoiceNumber?: InputMaybe<Scalars['String']>;
  /** Memo. */
  memo?: InputMaybe<Scalars['String']>;
  /** Service date end. */
  serviceDateEnd?: InputMaybe<Scalars['Date']>;
  /** Service date start. */
  serviceDateStart?: InputMaybe<Scalars['Date']>;
  /** Initial funds. */
  value?: InputMaybe<Scalars['Int']>;
};

/** Filtering conditions. */
export type ClientNdisPlanItemTransactionWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientNdisPlanItemTransactionWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientNdisPlanItemTransactionWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientNdisPlanItemTransactionWhereInput>>;
  actor?: InputMaybe<MembersWhereInput>;
  billingDate?: InputMaybe<DateConditionalInput>;
  clientNdisPlanItem?: InputMaybe<ClientNdisPlanItemWhereInput>;
  clientNdisPlanItemTransactionSupplier?: InputMaybe<ClientNdisPlanItemTransactionSuppliersWhereInput>;
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  id?: InputMaybe<IdConditionalInput>;
  invoiceNumber?: InputMaybe<StringConditionalInput>;
  memo?: InputMaybe<StringConditionalInput>;
  serviceDateEnd?: InputMaybe<DateConditionalInput>;
  serviceDateStart?: InputMaybe<DateConditionalInput>;
  value?: InputMaybe<IntConditionalInput>;
};

export type ClientNdisPlanItemUpdatableFieldsInput = {
  coreSupportItem?: InputMaybe<Scalars['Boolean']>;
  /** Initial funds. */
  initialFunds?: InputMaybe<Scalars['Int']>;
  /** Managed by. */
  managedBy?: InputMaybe<ClientNdisPlanItemManagedBy>;
  supportCoordinationItem?: InputMaybe<Scalars['Boolean']>;
};

/** Filtering conditions. */
export type ClientNdisPlanItemWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientNdisPlanItemWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientNdisPlanItemWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientNdisPlanItemWhereInput>>;
  category?: InputMaybe<IntConditionalInput>;
  clientNdisPlan?: InputMaybe<ClientNdisPlanWhereInput>;
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  createdBy?: InputMaybe<MembersWhereInput>;
  id?: InputMaybe<IdConditionalInput>;
  managedBy?: InputMaybe<IntConditionalInput>;
  name?: InputMaybe<ClientNdisPlanItemNameWhereInput>;
  transactions?: InputMaybe<ClientNdisPlanItemTransactionWhereInput>;
};

/** Ordering options */
export type ClientNdisPlanOrderByInput = {
  client?: InputMaybe<ClientsOrderByInput>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<MembersOrderByInput>;
  endAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  items?: InputMaybe<ClientNdisPlanItemOrderByInput>;
  startAt?: InputMaybe<OrderBy>;
};

export type ClientNdisPlanUpdatableFieldsInput = {
  /** End date. */
  endAt?: InputMaybe<Scalars['Date']>;
  items?: InputMaybe<Array<ClientNdisPlanItemCreatableFieldsInput>>;
  /** Start date. */
  startAt?: InputMaybe<Scalars['Date']>;
};

/** Filtering conditions. */
export type ClientNdisPlanWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientNdisPlanWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientNdisPlanWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientNdisPlanWhereInput>>;
  client?: InputMaybe<ClientsWhereInput>;
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  createdBy?: InputMaybe<MembersWhereInput>;
  endAt?: InputMaybe<DateConditionalInput>;
  id?: InputMaybe<IdConditionalInput>;
  items?: InputMaybe<ClientNdisPlanItemWhereInput>;
  startAt?: InputMaybe<DateConditionalInput>;
};

export type ClientNote = {
  __typename?: 'ClientNote';
  /** Linked client. */
  client: Client;
  /** Detailed information concering the client. */
  comment?: Maybe<Scalars['String']>;
  /** Date the comment was written. */
  commentedAt: Scalars['DateTime'];
  /** Member who wrote the comment. */
  commentedBy?: Maybe<Member>;
  /** Commented by: text version. */
  commentedByText?: Maybe<Scalars['String']>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Linked event. */
  event?: Maybe<Event>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Resolver for "lastUpdatedBy" field. */
  lastUpdatedBy?: Maybe<Member>;
  type: ClientNoteType;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientNoteFile = {
  __typename?: 'ClientNoteFile';
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: Maybe<Scalars['Date']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  file: File;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Owner of the file. */
  owner: ClientNote;
  type: ClientNoteFileType;
  value?: Maybe<Scalars['String']>;
};

/** Type of file belonging to a "ClientNote" entity. */
export enum ClientNoteFileType {
  MISC = 'MISC',
  TRAVEL_RECEIPT = 'TRAVEL_RECEIPT',
}

/** Aggregated response object for "ClientNoteFile" entities. */
export type ClientNoteFilesAggregate = {
  __typename?: 'ClientNoteFilesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientNoteFile>;
};

/** Ordering options for "ClientNoteFile" entities. */
export type ClientNoteFilesOrderByInput = {
  /** Order by date-of-file. */
  dateOfFile?: InputMaybe<OrderBy>;
  /** Order by file. */
  file?: InputMaybe<FilesOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by value. */
  value?: InputMaybe<OrderBy>;
};

/** Updatable fields for "ClientNoteFile" entities. */
export type ClientNoteFilesUpdatableFieldsInput = {
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Owner of the file. */
  owner?: InputMaybe<QueryByIdInput>;
  type?: InputMaybe<ClientNoteFileType>;
  value?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "ClientNoteFile" entities. */
export type ClientNoteFilesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientNoteFilesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientNoteFilesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientNoteFilesWhereInput>>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<DateTimeConditionalInput>;
  /** File. */
  file?: InputMaybe<FilesWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Owner. */
  owner?: InputMaybe<ClientNotesWhereInput>;
  /** File type. */
  type?: InputMaybe<StringConditionalInput>;
  /** Value. */
  value?: InputMaybe<StringConditionalInput>;
};

export type ClientNoteKeyword = {
  __typename?: 'ClientNoteKeyword';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked org. */
  org: Org;
  /** Text. */
  text: Scalars['String'];
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientNoteKeywordMatch = {
  __typename?: 'ClientNoteKeywordMatch';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Date of dismissal. */
  dismissedAt?: Maybe<Scalars['Date']>;
  /** Dismisser. */
  dismissedBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked keyword. */
  keyword: ClientNoteKeyword;
  /** Linked note. */
  note: ClientNote;
};

/** Aggregated response object for entities. */
export type ClientNoteKeywordMatchesAggregate = {
  __typename?: 'ClientNoteKeywordMatchesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientNoteKeywordMatch>;
};

/** Ordering options for entities. */
export type ClientNoteKeywordMatchesOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by datetime of dismissal. */
  dismissedAt?: InputMaybe<OrderBy>;
  /** Order by dismisser. */
  dismissedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by keyword. */
  keyword?: InputMaybe<ClientNoteKeywordsOrderByInput>;
  /** Order by note. */
  note?: InputMaybe<ClientNotesOrderByInput>;
};

/** Filtering conditions for entities. */
export type ClientNoteKeywordMatchesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientNoteKeywordMatchesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientNoteKeywordMatchesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientNoteKeywordMatchesWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Datetime of entitys dismissal. */
  dismissedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Dismisser. */
  dismissedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked keyword. */
  keyword?: InputMaybe<ClientNoteKeywordsWhereInput>;
  /** Linked note. */
  note?: InputMaybe<ClientNotesWhereInput>;
};

/** Aggregated response object for entities. */
export type ClientNoteKeywordsAggregate = {
  __typename?: 'ClientNoteKeywordsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientNoteKeyword>;
};

/** Creatable fields for entities */
export type ClientNoteKeywordsCreatableFieldsInput = {
  /** Text. */
  text: Scalars['String'];
};

/** Ordering options for entities. */
export type ClientNoteKeywordsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by keyword matches. */
  matches?: InputMaybe<ClientNoteKeywordMatchesOrderByInput>;
  /** Order by text. */
  text?: InputMaybe<OrderBy>;
};

/** Updatable fields for entities. */
export type ClientNoteKeywordsUpdatableFieldsInput = {
  /** Text. */
  text?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for entities. */
export type ClientNoteKeywordsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientNoteKeywordsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientNoteKeywordsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientNoteKeywordsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Matches. */
  matches?: InputMaybe<ClientNoteKeywordMatchesWhereInput>;
  /** Text. */
  text?: InputMaybe<StringConditionalInput>;
};

/** Type of client note. */
export enum ClientNoteType {
  /** Ad hoc notes. */
  AD_HOC = 'AD_HOC',
  /** Shift notes. */
  SHIFT = 'SHIFT',
}

/** Aggregated response object for entities. */
export type ClientNotesAggregate = {
  __typename?: 'ClientNotesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientNote>;
};

/** Creatable fields for entities */
export type ClientNotesCreatableFieldsInput = {
  /** Client input data. */
  client: QueryByIdInput;
  /** Detailed information concering the client. */
  comment?: InputMaybe<Scalars['String']>;
  /** Date the comment was written. */
  commentedAt: Scalars['DateTime'];
  /** Member input data. */
  commentedBy?: InputMaybe<QueryByIdInput>;
  /** Commented by: text version. */
  commentedByText?: InputMaybe<Scalars['String']>;
  /** Existing event input data. */
  existingEvent?: InputMaybe<QueryByIdInput>;
  /** New event input data. */
  newEvent?: InputMaybe<EventsCreatableFieldsInput>;
};

/** Ordering options for entities. */
export type ClientNotesOrderByInput = {
  /** Order by client. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by comment. */
  comment?: InputMaybe<OrderBy>;
  /** Order by date the comment was written. */
  commentedAt?: InputMaybe<OrderBy>;
  /** Order by member who wrote the comment. */
  commentedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by commented by: text version. */
  commentedByText?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by event. */
  event?: InputMaybe<EventsOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by keyword matches. */
  keywordMatches?: InputMaybe<ClientNoteKeywordMatchesOrderByInput>;
  /** Order by type. */
  type?: InputMaybe<OrderBy>;
};

/** Updatable fields for entities. */
export type ClientNotesUpdatableFieldsInput = {
  /** Detailed information concering the client. */
  comment?: InputMaybe<Scalars['String']>;
  /** Date the comment was written. */
  commentedAt?: InputMaybe<Scalars['DateTime']>;
  /** Member input data. */
  commentedBy?: InputMaybe<QueryByIdInput>;
  /** Commented by: text version. */
  commentedByText?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for entities. */
export type ClientNotesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientNotesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientNotesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientNotesWhereInput>>;
  /** Linked client. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Detailed information concering the client. */
  comment?: InputMaybe<StringConditionalInput>;
  /** Date the comment was written. */
  commentedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Member who wrote the comment. */
  commentedBy?: InputMaybe<MembersWhereInput>;
  /** Commented by: text version. */
  commentedByText?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Linked event. */
  event?: InputMaybe<EventsWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked keyword matches. */
  keywordMatches?: InputMaybe<ClientNoteKeywordMatchesWhereInput>;
  /** Searchable comment. */
  searchableComment?: InputMaybe<FullTextSearchConditionalInput>;
  /** Type of note. */
  type?: InputMaybe<IntConditionalInput>;
};

/** Recorded observation of a client. */
export type ClientObservation = {
  __typename?: 'ClientObservation';
  /** Diastolic blood pressure. */
  bloodDiastolic?: Maybe<Scalars['Int']>;
  /** Systolic blood pressure. */
  bloodSystolic?: Maybe<Scalars['Int']>;
  /** Owner of the data. */
  client: Client;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Date. */
  date?: Maybe<Scalars['DateTime']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Event during which this observation was recorded. */
  event?: Maybe<Event>;
  givenFood?: Maybe<Array<ClientObservationFood>>;
  givenMedications?: Maybe<Array<ClientObservationMedication>>;
  /** Had seizure. */
  hadSeizure?: Maybe<Scalars['Boolean']>;
  /** Heart rate. */
  heart?: Maybe<Scalars['Int']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Incontinence type. */
  incontinenceType?: Maybe<IncontinenceType>;
  /** Member linked with the observation. */
  member?: Maybe<Member>;
  /** Observational notes. */
  notes?: Maybe<Scalars['String']>;
  /** Nurse's name. */
  nursesName?: Maybe<Scalars['String']>;
  /** Post-seizure walk duration in minutes. */
  postSeizureWalkDuration?: Maybe<Scalars['Int']>;
  /** Respiration. */
  respiration?: Maybe<Scalars['Int']>;
  /** Seizure duration in seconds. */
  seizureDuration?: Maybe<Scalars['Int']>;
  /** Seizure lost consciousness. */
  seizureLostConsciousness?: Maybe<SeizureLostConsciousness>;
  /** Seizure type. */
  seizureType?: Maybe<SeizureType>;
  /** SpO2. */
  spo2?: Maybe<Scalars['Int']>;
  /** Bristol stool chart type. */
  stoolType?: Maybe<StoolType>;
  /** Temperature. */
  temp?: Maybe<Scalars['Float']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Urine color. */
  urineColor?: Maybe<UrineColor>;
  /** Weight in kilograms. */
  weight?: Maybe<Scalars['Float']>;
};

/** Recorded observation of a client. */
export type ClientObservationGivenFoodArgs = {
  input?: InputMaybe<QueryClientObservationFoodsInput>;
};

/** Recorded observation of a client. */
export type ClientObservationGivenMedicationsArgs = {
  input?: InputMaybe<QueryClientGivenMedicationsInput>;
};

/** Food given to a client. */
export type ClientObservationFood = {
  __typename?: 'ClientObservationFood';
  /** Description. */
  description?: Maybe<Scalars['String']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Kilojoules. */
  kilojoules?: Maybe<Scalars['Int']>;
  /** Linked ClientObservation entity. */
  observation: ClientObservation;
};

/** Creatable fields for "ClientObservationFood" entities */
export type ClientObservationFoodCreatableFieldsInput = {
  /** Description. */
  description?: InputMaybe<Scalars['String']>;
  /** Kilojoules. */
  kilojoules?: InputMaybe<Scalars['Int']>;
};

/** Ordering options for "ClientObservationFood" entities. */
export type ClientObservationFoodsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by description. */
  description?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "ClientObservationFood" entities. */
export type ClientObservationFoodsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientObservationFoodsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientObservationFoodsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientObservationFoodsWhereInput>>;
  /** Description. */
  description?: InputMaybe<StringConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
};

/** Medications given to a client. */
export type ClientObservationMedication = {
  __typename?: 'ClientObservationMedication';
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked Medication entity. */
  medication: Medication;
  /** Linked ClientObservation entity. */
  observation: ClientObservation;
  /** Quantity. */
  quantity?: Maybe<Scalars['String']>;
};

/** Creatable fields for "ClientObservationMedication" entities */
export type ClientObservationMedicationCreatableFieldsInput = {
  /** The medication which was given. */
  medication: QueryByIdInput;
  /** Quantity. */
  quantity?: InputMaybe<Scalars['String']>;
};

/** Ordering options for "ClientObservationMedication" entities. */
export type ClientObservationMedicationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by medication given. */
  medication?: InputMaybe<MedicationsOrderByInput>;
};

/** Filtering conditions for "ClientObservationMedication" entities. */
export type ClientObservationMedicationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientObservationMedicationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientObservationMedicationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientObservationMedicationsWhereInput>>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** The medication given. */
  medication?: InputMaybe<MedicationsWhereInput>;
};

/** Aggregated response object for "ClientObservation" entities. */
export type ClientObservationsAggregate = {
  __typename?: 'ClientObservationsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientObservation>;
};

/** Ordering options for "ClientObservation" entities. */
export type ClientObservationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by date. */
  date?: InputMaybe<OrderBy>;
  /** Order by event. */
  event?: InputMaybe<EventsOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Updatable fields for "ClientObservation" entities. */
export type ClientObservationsUpdatableFieldsInput = {
  /** Diastolic blood pressure. */
  bloodDiastolic?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure. */
  bloodSystolic?: InputMaybe<Scalars['Int']>;
  /** Date. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** Food input data. */
  givenFood?: InputMaybe<CreateClientObservationFoodsInput>;
  /** Medication input data. */
  givenMedications?: InputMaybe<CreateClientObservationMedicationsInput>;
  /** Had seizure. */
  hadSeizure?: InputMaybe<Scalars['Boolean']>;
  /** Heart rate. */
  heart?: InputMaybe<Scalars['Int']>;
  /** Incontinence type. */
  incontinenceType?: InputMaybe<IncontinenceType>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Observational notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Nurse's name. */
  nursesName?: InputMaybe<Scalars['String']>;
  /** Post-seizure walk duration in minutes. */
  postSeizureWalkDuration?: InputMaybe<Scalars['Int']>;
  /** Respiration. */
  respiration?: InputMaybe<Scalars['Int']>;
  /** Seizure duration in seconds. */
  seizureDuration?: InputMaybe<Scalars['Int']>;
  /** Seizure lost consciousness. */
  seizureLostConsciousness?: InputMaybe<SeizureLostConsciousness>;
  /** Seizure type. */
  seizureType?: InputMaybe<SeizureType>;
  /** SpO2. */
  spo2?: InputMaybe<Scalars['Int']>;
  /** Bristol stool chart type. */
  stoolType?: InputMaybe<StoolType>;
  /** Temperature. */
  temp?: InputMaybe<Scalars['Float']>;
  /** Urine color. */
  urineColor?: InputMaybe<UrineColor>;
  /** Weight in kilograms. */
  weight?: InputMaybe<Scalars['Float']>;
};

/** Filtering conditions for "ClientObservation" entities. */
export type ClientObservationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientObservationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientObservationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientObservationsWhereInput>>;
  /** Diastolic blood pressure. */
  bloodDiastolic?: InputMaybe<IntConditionalInput>;
  /** Systolic blood pressure. */
  bloodSystolic?: InputMaybe<IntConditionalInput>;
  /** Owner of the data. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Date. */
  date?: InputMaybe<DateTimeConditionalInput>;
  /** Event during which this observation was recorded. */
  event?: InputMaybe<EventsWhereInput>;
  /** Given medications. */
  givenMedications?: InputMaybe<ClientObservationMedicationsWhereInput>;
  /** Given food. */
  givenfood?: InputMaybe<ClientObservationFoodsWhereInput>;
  /** Had seizure. */
  hadSeizure?: InputMaybe<BooleanConditionalInput>;
  /** Heart rate. */
  heart?: InputMaybe<IntConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Incontinence type. */
  incontinenceType?: InputMaybe<IntConditionalInput>;
  /** Observational notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Nurse's name. */
  nursesName?: InputMaybe<StringConditionalInput>;
  /** Post-seizure walk duration in seconds. */
  postSeizureWalkDuration?: InputMaybe<IntConditionalInput>;
  /** Respiration. */
  respiration?: InputMaybe<IntConditionalInput>;
  /** Seizure duration. */
  seizureDuration?: InputMaybe<IntConditionalInput>;
  /** Lost consciousness. */
  seizureLostConsciousness?: InputMaybe<IntConditionalInput>;
  /** Seizure type. */
  seizureType?: InputMaybe<IntConditionalInput>;
  /** SpO2. */
  spo2?: InputMaybe<IntConditionalInput>;
  /** Stool type. */
  stoolType?: InputMaybe<IntConditionalInput>;
  /** Temperature. */
  temp?: InputMaybe<FloatConditionalInput>;
  /** Weight. */
  weight?: InputMaybe<IntConditionalInput>;
};

/** Associative entity joining Client and Member entities as a team. */
export type ClientTeam = {
  __typename?: 'ClientTeam';
  /** Linked "Client" entity. */
  client: Client;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Datetime of entity's deletion. */
  deletedAt: Scalars['DateTime'];
  /** Eraser of the entity. */
  deletedBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked "Member" entity. */
  member: Member;
  /** Preferential-status of the member */
  preferred: Scalars['Boolean'];
};

/** Aggregated response object for "ClientTeam" entities. */
export type ClientTeamsAggregate = {
  __typename?: 'ClientTeamsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<ClientTeam>;
};

/** Ordering options for "ClientTeam" entities. */
export type ClientTeamsOrderByInput = {
  /** Order by clients. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by datetime of deletion. */
  deletedAt?: InputMaybe<OrderBy>;
  /** Order by eraser. */
  deletedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by members. */
  member?: InputMaybe<MembersOrderByInput>;
  /** Order by preferential-status of the member. */
  preferred?: InputMaybe<OrderBy>;
};

/** Settable fields for "ClientTeam" entities. */
export type ClientTeamsSettableFieldsInput = {
  /** Member input data. */
  member: QueryByIdInput;
  /** Preferential-status of the member */
  preferred: Scalars['Boolean'];
};

/** Filtering conditions for "ClientTeam" entities. */
export type ClientTeamsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientTeamsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientTeamsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientTeamsWhereInput>>;
  /** Linked Client entity. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Eraser of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked Member entity. */
  member?: InputMaybe<MembersWhereInput>;
  /** Preferential-status of the member. */
  preferred?: InputMaybe<BooleanConditionalInput>;
};

/** Aggregated response object for "Client" entities. */
export type ClientsAggregate = {
  __typename?: 'ClientsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Client>;
};

/** Creatable fields for "Client" entities. */
export type ClientsCreatableFieldsInput = {
  /** Active status. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Allow automatic population of data for unattended future events. */
  allowAutomaticEventPopulation?: InputMaybe<Scalars['Boolean']>;
  /** Allow recording of medication. */
  allowMedication?: InputMaybe<Scalars['Boolean']>;
  /** Allow recording of observations. */
  allowObservations?: InputMaybe<Scalars['Boolean']>;
  /** Allow recording of zeisures. */
  allowRecordSeizures?: InputMaybe<Scalars['Boolean']>;
  avatarData?: InputMaybe<Scalars['Upload']>;
  /** Biography. */
  bio?: InputMaybe<Scalars['String']>;
  /** Diastolic blood pressure lower-limit warning threshold (level 1). */
  bloodDiastolicLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure lower-limit warning threshold (level 2). */
  bloodDiastolicLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure upper-limit warning threshold (level 1). */
  bloodDiastolicUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure upper-limit warning threshold (level 2). */
  bloodDiastolicUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure lower-limit warning threshold (level 1). */
  bloodSystolicLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure lower-limit warning threshold (level 2). */
  bloodSystolicLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure upper-limit warning threshold (level 1). */
  bloodSystolicUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure upper-limit warning threshold (level 2). */
  bloodSystolicUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Branch input data. */
  branch: QueryByIdInput;
  /** Building name. */
  buildingName?: InputMaybe<Scalars['String']>;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Confidential notes. */
  confidentialNotes?: InputMaybe<Scalars['String']>;
  /** Contacts Email address. */
  contactsEmail?: InputMaybe<Scalars['String']>;
  /** Contacts name. */
  contactsName?: InputMaybe<Scalars['String']>;
  /** Contacts Phone number. */
  contactsPhone?: InputMaybe<Scalars['String']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Default duration (in hours) to use when creating events. */
  defaultEventDuration?: InputMaybe<Scalars['Float']>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** External ID. */
  externalId?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Heart rate lower-limit warning threshold (level 1). */
  heartLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Heart rate lower-limit warning threshold. (level 2) */
  heartLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Heart rate upper-limit warning threshold (level 1). */
  heartUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Heart rate upper-limit warning threshold (level 2). */
  heartUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Module: Core Support. */
  moduleCS?: InputMaybe<Scalars['Boolean']>;
  /** Module: Support Coordination. */
  moduleSC?: InputMaybe<Scalars['Boolean']>;
  /** NDIS participant ID. */
  ndisId?: InputMaybe<Scalars['NdisId']>;
  /** End datetime of the NDIS plan. */
  ndisPlanEndDate?: InputMaybe<Scalars['Date']>;
  /** NDIS plan initial funds capacity building. */
  ndisPlanInitialFundsCb?: InputMaybe<Scalars['Int']>;
  /** NDIS plan initial funds core support. */
  ndisPlanInitialFundsCore?: InputMaybe<Scalars['Int']>;
  /** NDIS plan initial funds community service. */
  ndisPlanInitialFundsCs?: InputMaybe<Scalars['Int']>;
  /** Start date of the NDIS plan. */
  ndisPlanStartDate?: InputMaybe<Scalars['Date']>;
  /** Administrator-only notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** PlanManager input data. */
  planManager?: InputMaybe<QueryByIdInput>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Event requirement: Handover. */
  requireHandover?: InputMaybe<Scalars['Boolean']>;
  /** Respiration lower-limit warning threshold. (level 1) */
  respirationLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Respiration lower-limit warning threshold. (level 2) */
  respirationLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Respiration upper-limit warning threshold. (level 1) */
  respirationUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Respiration upper-limit warning threshold. (level 2) */
  respirationUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Spo2 lower-limit warning threshold. (level 1) */
  spo2LowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Spo2 lower-limit warning threshold. (level 2) */
  spo2LowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Spo2 upper-limit warning threshold. (level 1) */
  spo2UpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Spo2 upper-limit warning threshold. (level 2) */
  spo2UpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** SupportCoordinator input data. */
  supportCoordinator?: InputMaybe<QueryByIdInput>;
  /** Timezone. */
  timezone?: InputMaybe<Timezone>;
  /** File-tracking status. */
  trackFiles?: InputMaybe<Scalars['Boolean']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Ordering options for "Client" entities. */
export type ClientsOrderByInput = {
  /** Order by active status. */
  active?: InputMaybe<OrderBy>;
  /** Order by branch. */
  branch?: InputMaybe<BranchesOrderByInput>;
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by preferred name. */
  dob?: InputMaybe<OrderBy>;
  /** Order by events. */
  events?: InputMaybe<EventsOrderByInput>;
  /** Order by first billable event start date. */
  firstBillableEventStartAt?: InputMaybe<OrderBy>;
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by gender. */
  gender?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last billable event start date. */
  lastBillableEventStartAt?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by minutes between first and last billable events. */
  minutesBetweenFirstAndLastBillableEvents?: InputMaybe<OrderBy>;
  /** Order by observations. */
  observations?: InputMaybe<ClientObservationsOrderByInput>;
  /** Order by plan manager. */
  planManager?: InputMaybe<PlanManagersOrderByInput>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by support coordinator. */
  supportCoordinator?: InputMaybe<SupportCoordinatorsOrderByInput>;
  /** Order by teams. */
  teams?: InputMaybe<ClientTeamsOrderByInput>;
  /** Order by billable minutes. */
  totalBillableMinutes?: InputMaybe<OrderBy>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Client" entities. */
export type ClientsUpdatableFieldsInput = {
  /** Active status. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Allow automatic population of data for unattended future events. */
  allowAutomaticEventPopulation?: InputMaybe<Scalars['Boolean']>;
  /** Allow recording of medication. */
  allowMedication?: InputMaybe<Scalars['Boolean']>;
  /** Allow recording of observations. */
  allowObservations?: InputMaybe<Scalars['Boolean']>;
  /** Allow recording of zeisures. */
  allowRecordSeizures?: InputMaybe<Scalars['Boolean']>;
  avatarData?: InputMaybe<Scalars['Upload']>;
  /** Biography. */
  bio?: InputMaybe<Scalars['String']>;
  /** Diastolic blood pressure lower-limit warning threshold (level 1). */
  bloodDiastolicLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure lower-limit warning threshold (level 2). */
  bloodDiastolicLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure upper-limit warning threshold (level 1). */
  bloodDiastolicUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Diastolic blood pressure upper-limit warning threshold (level 2). */
  bloodDiastolicUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure lower-limit warning threshold (level 1). */
  bloodSystolicLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure lower-limit warning threshold (level 2). */
  bloodSystolicLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure upper-limit warning threshold (level 1). */
  bloodSystolicUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure upper-limit warning threshold (level 2). */
  bloodSystolicUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Branch input data. */
  branch?: InputMaybe<QueryByIdInput>;
  /** Building name. */
  buildingName?: InputMaybe<Scalars['String']>;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Confidential notes. */
  confidentialNotes?: InputMaybe<Scalars['String']>;
  /** Contacts Email address. */
  contactsEmail?: InputMaybe<Scalars['String']>;
  /** Contacts name. */
  contactsName?: InputMaybe<Scalars['String']>;
  /** Contacts Phone number. */
  contactsPhone?: InputMaybe<Scalars['String']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Default duration (in hours) to use when creating events. */
  defaultEventDuration?: InputMaybe<Scalars['Float']>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** External ID. */
  externalId?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Heart rate lower-limit warning threshold (level 1). */
  heartLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Heart rate lower-limit warning threshold. (level 2) */
  heartLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Heart rate upper-limit warning threshold (level 1). */
  heartUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Heart rate upper-limit warning threshold (level 2). */
  heartUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Module: Core Support. */
  moduleCS?: InputMaybe<Scalars['Boolean']>;
  /** Module: Support Coordination. */
  moduleSC?: InputMaybe<Scalars['Boolean']>;
  /** NDIS participant ID. */
  ndisId?: InputMaybe<Scalars['NdisId']>;
  /** End datetime of the NDIS plan. */
  ndisPlanEndDate?: InputMaybe<Scalars['Date']>;
  /** NDIS plan initial funds capacity building. */
  ndisPlanInitialFundsCb?: InputMaybe<Scalars['Int']>;
  /** NDIS plan initial funds core support. */
  ndisPlanInitialFundsCore?: InputMaybe<Scalars['Int']>;
  /** NDIS plan initial funds community service. */
  ndisPlanInitialFundsCs?: InputMaybe<Scalars['Int']>;
  /** Start date of the NDIS plan. */
  ndisPlanStartDate?: InputMaybe<Scalars['Date']>;
  /** Administrator-only notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** PlanManager input data. */
  planManager?: InputMaybe<QueryByIdInput>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Event requirement: Handover. */
  requireHandover?: InputMaybe<Scalars['Boolean']>;
  /** Respiration lower-limit warning threshold. (level 1) */
  respirationLowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Respiration lower-limit warning threshold. (level 2) */
  respirationLowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Respiration upper-limit warning threshold. (level 1) */
  respirationUpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Respiration upper-limit warning threshold. (level 2) */
  respirationUpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Spo2 lower-limit warning threshold. (level 1) */
  spo2LowerThreshold1?: InputMaybe<Scalars['Int']>;
  /** Spo2 lower-limit warning threshold. (level 2) */
  spo2LowerThreshold2?: InputMaybe<Scalars['Int']>;
  /** Spo2 upper-limit warning threshold. (level 1) */
  spo2UpperThreshold1?: InputMaybe<Scalars['Int']>;
  /** Spo2 upper-limit warning threshold. (level 2) */
  spo2UpperThreshold2?: InputMaybe<Scalars['Int']>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** SupportCoordinator input data. */
  supportCoordinator?: InputMaybe<QueryByIdInput>;
  /** Timezone. */
  timezone?: InputMaybe<Timezone>;
  /** File-tracking status. */
  trackFiles?: InputMaybe<Scalars['Boolean']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Input data for validating client external ids. */
export type ClientsValidateExternalldsInput = {
  /** Check whether ids exists or not */
  exists: Scalars['Boolean'];
  /** External IDs. */
  ids: Array<Scalars['String']>;
};

/** Validated client external ids response object. */
export type ClientsValidatedExternalIdsResponse = {
  __typename?: 'ClientsValidatedExternalIdsResponse';
  /** External IDs. */
  ids: Array<Scalars['String']>;
};

/** Filtering conditions for "Client" entities. */
export type ClientsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ClientsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ClientsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ClientsWhereInput>>;
  /** Allow recording of observations. */
  allowObservations?: InputMaybe<BooleanConditionalInput>;
  /** Branch. */
  branch?: InputMaybe<BranchesWhereInput>;
  /** Color. */
  color?: InputMaybe<StringConditionalInput>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Default event duration. */
  defaultEventDuration?: InputMaybe<IntConditionalInput>;
  /** Date of birth. */
  dob?: InputMaybe<DateConditionalInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** Events for this client. */
  events?: InputMaybe<EventsWhereInput>;
  /** External ID. */
  externalId?: InputMaybe<StringConditionalInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Gender. */
  gender?: InputMaybe<IntConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  moduleCS?: InputMaybe<BooleanConditionalInput>;
  moduleSC?: InputMaybe<BooleanConditionalInput>;
  /** NDIS participant ID. */
  ndisId?: InputMaybe<NdisIdConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Observations for this client. */
  observations?: InputMaybe<ClientObservationsWhereInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Plan manager. */
  planManager?: InputMaybe<PlanManagersWhereInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Pronouns. */
  pronouns?: InputMaybe<IntConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Support Coordinator. */
  supportCoordinator?: InputMaybe<SupportCoordinatorsWhereInput>;
  /** Teams. */
  teams?: InputMaybe<ClientTeamsWhereInput>;
  /** File tracking status. */
  trackFiles?: InputMaybe<BooleanConditionalInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
};

/** Input data for clocking on/off "Event" entities. */
export type ClockEventInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
};

export type Conflict = {
  __typename?: 'Conflict';
  /** Latest starting datetime of a linked event. */
  endAt: Scalars['DateTime'];
  /** Resolver for "eventConflicts" field. */
  eventConflicts: Array<EventConflict>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Member linked with the conflict. */
  member: Member;
  /** Earliest starting datetime of a linked event. */
  startAt: Scalars['DateTime'];
  /** Resolver for "unavailableConflicts" field. */
  unavailableConflicts: Array<MemberUnavailableConflict>;
};

export type ConflictEventConflictsArgs = {
  input?: InputMaybe<QueryEventConflictsInput>;
};

export type ConflictUnavailableConflictsArgs = {
  input?: InputMaybe<QueryMemberUnavailableConflictsInput>;
};

/** Aggregated response object for "Conflict" entities. */
export type ConflictsAggregate = {
  __typename?: 'ConflictsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Conflict>;
};

/** Ordering options entities. */
export type ConflictsOrderByInput = {
  /** Order by end datetime. */
  endAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by member. */
  member?: InputMaybe<MembersOrderByInput>;
  /** Order by start datetime. */
  startAt?: InputMaybe<OrderBy>;
};

/** Filtering conditions for entities. */
export type ConflictsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<ConflictsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<ConflictsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<ConflictsWhereInput>>;
  /** End datetime. */
  endAt?: InputMaybe<DateTimeConditionalInput>;
  /** EventConflict associative entities. */
  eventConflicts?: InputMaybe<EventConflictsWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Attending member. */
  member?: InputMaybe<MembersWhereInput>;
  /** MemberUnavailableConflict associative entities. */
  memberUnavailableConflicts?: InputMaybe<MemberUnavailableConflictsWhereInput>;
  /** Start datetime. */
  startAt?: InputMaybe<DateTimeConditionalInput>;
};

/** Creation input data for "Branch" entities. */
export type CreateBranchesInput = {
  objects: Array<BranchesCreatableFieldsInput>;
};

/** Creation input data for singular "ClientContact" entities. */
export type CreateClientContactInput = {
  /** Associated client entity. */
  client: QueryByIdInput;
  /** Custom type. */
  customRelation?: InputMaybe<Scalars['String']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** Contact is an emergency contact. */
  emergencyContact: Scalars['Boolean'];
  /** Name. */
  name: Scalars['String'];
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred contact. */
  preferred?: InputMaybe<Scalars['Boolean']>;
  /** Contact type. */
  relations?: InputMaybe<Array<ClientContactRelation>>;
};

/** Creation input data for singular "ClientFile" entities. */
export type CreateClientFileInput = {
  /** File upload buffer. */
  attachment: Scalars['Upload'];
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Date file was issued. */
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  /** Owner of the file. */
  owner: QueryByIdInput;
  type: ClientFileType;
  value?: InputMaybe<Scalars['String']>;
};

export type CreateClientNdisPlanInput = {
  client: QueryByIdInput;
  /** End date. */
  endAt?: InputMaybe<Scalars['Date']>;
  items?: InputMaybe<Array<ClientNdisPlanItemCreatableFieldsInput>>;
  /** Start date. */
  startAt: Scalars['Date'];
};

export type CreateClientNdisPlanItemInput = {
  /** Category. */
  category?: InputMaybe<ClientNdisPlanItemCategory>;
  clientNdisPlan: QueryByIdInput;
  coreSupportItem?: InputMaybe<Scalars['Boolean']>;
  /** Initial funds. */
  initialFunds: Scalars['Int'];
  /** Managed by. */
  managedBy?: InputMaybe<ClientNdisPlanItemManagedBy>;
  name: QueryByIdInput;
  supportCoordinationItem?: InputMaybe<Scalars['Boolean']>;
};

export type CreateClientNdisPlanItemTransactionInput = {
  /** Billing date. */
  billingDate?: InputMaybe<Scalars['Date']>;
  clientNdisPlanItem: QueryByIdInput;
  clientNdisPlanItemTransactionSupplier?: InputMaybe<QueryByIdInput>;
  /** Invoice number. */
  invoiceNumber?: InputMaybe<Scalars['String']>;
  /** Memo. */
  memo?: InputMaybe<Scalars['String']>;
  /** Service date end. */
  serviceDateEnd?: InputMaybe<Scalars['Date']>;
  /** Service date start. */
  serviceDateStart?: InputMaybe<Scalars['Date']>;
  /** Initial funds. */
  value: Scalars['Int'];
};

/** Creation input data. */
export type CreateClientNdisPlanItemTransactionSupplierInput = {
  /** Name of supplier. */
  name: Scalars['String'];
};

export type CreateClientNdisPlanItemTransactionsInput = {
  objects: Array<ClientNdisPlanItemTransactionInputCreatableFieldsInput>;
};

/** Creation input data for singular "ClientNoteFile" entities. */
export type CreateClientNoteFileInput = {
  /** File upload buffer. */
  attachment: Scalars['Upload'];
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Owner of the file. */
  owner: QueryByIdInput;
  type: ClientNoteFileType;
  value?: InputMaybe<Scalars['String']>;
};

/** Input data for creating a singular entity. */
export type CreateClientNoteInput = {
  /** Client input data. */
  client: QueryByIdInput;
  /** Detailed information concering the client. */
  comment?: InputMaybe<Scalars['String']>;
  /** Date the comment was written. */
  commentedAt: Scalars['DateTime'];
  /** Member input data. */
  commentedBy?: InputMaybe<QueryByIdInput>;
  /** Commented by: text version. */
  commentedByText?: InputMaybe<Scalars['String']>;
  /** Existing event input data. */
  existingEvent?: InputMaybe<QueryByIdInput>;
  /** New event input data. */
  newEvent?: InputMaybe<EventsCreatableFieldsInput>;
};

/** Input data for creating a singular entity. */
export type CreateClientNoteKeywordInput = {
  /** Text. */
  text: Scalars['String'];
};

/** Input data for creating entities. */
export type CreateClientNoteKeywordsInput = {
  objects: Array<ClientNoteKeywordsCreatableFieldsInput>;
};

/** Input data for creating entities. */
export type CreateClientNotesInput = {
  objects: Array<ClientNotesCreatableFieldsInput>;
  /** Timezone. */
  timezone: Timezone;
};

/** Creation input data for "ClientObservationFood" entities. */
export type CreateClientObservationFoodsInput = {
  objects: Array<ClientObservationFoodCreatableFieldsInput>;
};

/** Creation input data for "ClientObservation" entities. */
export type CreateClientObservationInput = {
  /** Diastolic blood pressure. */
  bloodDiastolic?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure. */
  bloodSystolic?: InputMaybe<Scalars['Int']>;
  /** Client input data. */
  client: QueryByIdInput;
  /** Date. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** Food input data. */
  givenFood?: InputMaybe<CreateClientObservationFoodsInput>;
  /** Medication input data. */
  givenMedications?: InputMaybe<CreateClientObservationMedicationsInput>;
  /** Had seizure. */
  hadSeizure?: InputMaybe<Scalars['Boolean']>;
  /** Heart rate. */
  heart?: InputMaybe<Scalars['Int']>;
  /** Incontinence type. */
  incontinenceType?: InputMaybe<IncontinenceType>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Observational notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Nurse's name. */
  nursesName?: InputMaybe<Scalars['String']>;
  /** Post-seizure walk duration in minutes. */
  postSeizureWalkDuration?: InputMaybe<Scalars['Int']>;
  /** Respiration. */
  respiration?: InputMaybe<Scalars['Int']>;
  /** Seizure duration in seconds. */
  seizureDuration?: InputMaybe<Scalars['Int']>;
  /** Seizure lost consciousness. */
  seizureLostConsciousness?: InputMaybe<SeizureLostConsciousness>;
  /** Seizure type. */
  seizureType?: InputMaybe<SeizureType>;
  /** SpO2. */
  spo2?: InputMaybe<Scalars['Int']>;
  /** Bristol stool chart type. */
  stoolType?: InputMaybe<StoolType>;
  /** Temperature. */
  temp?: InputMaybe<Scalars['Float']>;
  /** Urine color. */
  urineColor?: InputMaybe<UrineColor>;
  /** Weight in kilograms. */
  weight?: InputMaybe<Scalars['Float']>;
};

/** Creation input data for "ClientObservationMedication" entities. */
export type CreateClientObservationMedicationsInput = {
  objects: Array<ClientObservationMedicationCreatableFieldsInput>;
};

/** Creation input data for "ClientObservation" entities with an Event relation. */
export type CreateClientObservationWithEventInput = {
  /** Diastolic blood pressure. */
  bloodDiastolic?: InputMaybe<Scalars['Int']>;
  /** Systolic blood pressure. */
  bloodSystolic?: InputMaybe<Scalars['Int']>;
  /** Date. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** Event input data. */
  event: QueryByIdInput;
  /** Food input data. */
  givenFood?: InputMaybe<CreateClientObservationFoodsInput>;
  /** Medication input data. */
  givenMedications?: InputMaybe<CreateClientObservationMedicationsInput>;
  /** Had seizure. */
  hadSeizure?: InputMaybe<Scalars['Boolean']>;
  /** Heart rate. */
  heart?: InputMaybe<Scalars['Int']>;
  /** Incontinence type. */
  incontinenceType?: InputMaybe<IncontinenceType>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Observational notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Nurse's name. */
  nursesName?: InputMaybe<Scalars['String']>;
  /** Post-seizure walk duration in minutes. */
  postSeizureWalkDuration?: InputMaybe<Scalars['Int']>;
  /** Respiration. */
  respiration?: InputMaybe<Scalars['Int']>;
  /** Seizure duration in seconds. */
  seizureDuration?: InputMaybe<Scalars['Int']>;
  /** Seizure lost consciousness. */
  seizureLostConsciousness?: InputMaybe<SeizureLostConsciousness>;
  /** Seizure type. */
  seizureType?: InputMaybe<SeizureType>;
  /** SpO2. */
  spo2?: InputMaybe<Scalars['Int']>;
  /** Bristol stool chart type. */
  stoolType?: InputMaybe<StoolType>;
  /** Temperature. */
  temp?: InputMaybe<Scalars['Float']>;
  /** Urine color. */
  urineColor?: InputMaybe<UrineColor>;
  /** Weight in kilograms. */
  weight?: InputMaybe<Scalars['Float']>;
};

/** Creation input data for "Client" entities. */
export type CreateClientsInput = {
  objects: Array<ClientsCreatableFieldsInput>;
};

/** Creation input data for singular "EventFile" entities. */
export type CreateEventFileInput = {
  /** File upload buffer. */
  attachment: Scalars['Upload'];
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Owner of the file. */
  owner: QueryByIdInput;
  type: EventFileType;
  value?: InputMaybe<Scalars['String']>;
};

/** Creation input data for an "Event" entity. */
export type CreateEventInput = {
  /** Passive active-assist state. */
  activeAssist?: InputMaybe<Scalars['Boolean']>;
  /** Client-billable status. */
  billable?: InputMaybe<Scalars['Boolean']>;
  /** Billing registration group. */
  billingRegistrationGroup?: InputMaybe<EventBillingRegistrationGroup>;
  /** Member-bonus-pay ammount. */
  bonusPay?: InputMaybe<Scalars['Int']>;
  /** Building name. */
  buildingName?: InputMaybe<Scalars['String']>;
  /** Client input data. */
  client: QueryByIdInput;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Duration of the event in minutes. */
  duration: Scalars['Int'];
  /** Enable duplication. */
  enableDuplication?: InputMaybe<Scalars['Boolean']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Locked status. */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Event is passive. */
  passive?: InputMaybe<Scalars['Boolean']>;
  /** Start datetime of the passive period. Can not exceed 4 hours past event startAt. */
  passiveStartAt?: InputMaybe<Scalars['DateTime']>;
  /** Member-payable status. */
  payable?: InputMaybe<Scalars['Boolean']>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Does event occurs on a public holiday? */
  publicHoliday?: InputMaybe<Scalars['Boolean']>;
  /** Publish status. */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Requirement: Car. */
  requireCar?: InputMaybe<Scalars['Boolean']>;
  /** Scheduling information concering the event. */
  scheduleNotes?: InputMaybe<Scalars['String']>;
  /** Start datetime of the event. */
  startAt: Scalars['DateTime'];
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** A short summary of the event. */
  summary?: InputMaybe<Scalars['String']>;
  /** Travel distance in meters. */
  travelDistance?: InputMaybe<Scalars['Int']>;
  travelDistanceNotes?: InputMaybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<Scalars['Int']>;
  travelTimeNotes?: InputMaybe<Scalars['String']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

export type CreateEventIssueInput = {
  event: QueryByIdInput;
  /** Issue type. */
  type: EventIssueType;
};

/** Creation input data for "Medication" entities. */
export type CreateMedicationInput = {
  /** Name of medication. */
  name: Scalars['String'];
  /** Pronunciation of medication. */
  pronunciation?: InputMaybe<Scalars['String']>;
  /** URL. */
  url?: InputMaybe<Scalars['String']>;
};

/** Creation input data for singular "MemberBasePayRate" entities. */
export type CreateMemberBasePayRateInput = {
  /** Effective-from date. */
  date: Scalars['Date'];
  /** Associated member entity. */
  member: QueryByIdInput;
  /** Pay rate type. */
  type: MemberBasePayRateType;
  /** Value. */
  value: Scalars['Int'];
};

/** Creation input data for singular "MemberBranchAllocation" entities. */
export type CreateMemberBranchAllocationInput = {
  /** Effective-from date. */
  date: Scalars['Date'];
  /** Associated member entity. */
  member: QueryByIdInput;
  /** Values. */
  values: Array<CreateMemberBranchAllocationValueInput>;
};

/** Creation input data for singular "MemberBranchAllocation" entities. */
export type CreateMemberBranchAllocationValueInput = {
  /** Branch ID. */
  branchId: Scalars['UUID'];
  /** Value. */
  value: Scalars['Int'];
};

/** Creation input data for singular "MemberFile" entities. */
export type CreateMemberFileInput = {
  /** File upload buffer. */
  attachment: Scalars['Upload'];
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Date file was issued. */
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  /** Owner of the file. */
  owner: QueryByIdInput;
  type: MemberFileType;
  value?: InputMaybe<Scalars['String']>;
};

export type CreateMemberPostNominalInput = {
  /** Effective-from date. */
  date: Scalars['Date'];
  /** Associated member entity. */
  member: QueryByIdInput;
  /** Value. */
  value: Scalars['String'];
};

/** Creation input data for "MemberScheduleTimeLimit" entities. */
export type CreateMemberScheduleTimeLimitsInput = {
  objects: Array<MemberScheduleTimeLimitsCreatableFieldsInput>;
};

/** Creation input data for "MemberUnavailable" entities. */
export type CreateMemberUnavailablesInput = {
  objects: Array<MemberUnavailablesCreatableFieldsInput>;
  /** Owner. */
  owner?: InputMaybe<QueryByIdInput>;
};

/** Creation input data for singular "MemberUpload" entities. */
export type CreateMemberUploadInput = {
  /** File upload buffer. */
  attachment: Scalars['Upload'];
  /** File type. */
  type: MemberFileType;
  /** Owner of the file. */
  uploadedFor?: InputMaybe<QueryByIdInput>;
};

/** Creation input data for "Member" entities. */
export type CreateMembersInput = {
  /** Immediately send invitations to all newly created members. */
  invite?: InputMaybe<Scalars['Boolean']>;
  objects: Array<MembersCreatableFieldsInput>;
};

/** Creation input data. */
export type CreateNdisCoordinationClientActivityInput = {
  /** Client input data. */
  client: QueryByIdInput;
  /** Start datetime of the event. */
  date: Scalars['DateTime'];
  /** Duration of the event in minutes. */
  duration: Scalars['Int'];
  /** Member input data. */
  member: QueryByIdInput;
  /** Detailed information concering the event. */
  notes?: InputMaybe<Scalars['String']>;
};

/** Creation input data. */
export type CreateNdisCoordinationClientInput = {
  /** Biography. */
  bio?: InputMaybe<Scalars['String']>;
  /** Branch input data. */
  branch: QueryByIdInput;
  /** Building name. */
  buildingName?: InputMaybe<Scalars['String']>;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** External ID. */
  externalId?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** NDIS participant ID. */
  ndisId?: InputMaybe<Scalars['NdisId']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** PlanManager input data. */
  planManager?: InputMaybe<QueryByIdInput>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** Timezone. */
  timezone?: InputMaybe<Timezone>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Creation input data for singular "Org" entity. */
export type CreateOrgInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Afternoon pay multiplier. */
  afternoonPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Allowed duration between conflicts in minutes. */
  allowedDurationBetweenConflicts?: InputMaybe<Scalars['Int']>;
  /** Automatically lock clocked-off events after a number of hours */
  autoArchiveClockedOffEventsAfterNumberOfHours?: InputMaybe<Scalars['Int']>;
  /** Base pay rate. */
  basePayRate?: InputMaybe<Scalars['Int']>;
  /** Entity input data. */
  billing?: InputMaybe<SetOrgBillingInput>;
  /** Bonus pay rate 2. */
  bonus2PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 3. */
  bonus3PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 4. */
  bonus4PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 5. */
  bonus5PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 6. */
  bonus6PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 7. */
  bonus7PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 8. */
  bonus8PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 9. */
  bonus9PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 10. */
  bonus10PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 11. */
  bonus11PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 12. */
  bonus12PayRate?: InputMaybe<Scalars['Int']>;
  /** "Branch" entity input data. */
  branches: CreateBranchesInput;
  /** Days in advance a member can claim an event. */
  claimEventsDayRange?: InputMaybe<Scalars['Int']>;
  clientDocumentTrackerCollapsedFiles?: InputMaybe<Array<ClientFileType>>;
  clientDocumentTrackerVisibleFiles?: InputMaybe<Array<ClientFileType>>;
  /** Warning threshold for early/late clock-off times. */
  clockOffWarnMinutes?: InputMaybe<Scalars['Int']>;
  /** Warning threshold for early/late clock-on times. */
  clockOnWarnMinutes?: InputMaybe<Scalars['Int']>;
  /** Email address */
  email?: InputMaybe<Scalars['EmailAddress']>;
  /** Fixed expenses */
  fixedExpenses?: InputMaybe<Scalars['String']>;
  /** Ideas. */
  ideas?: InputMaybe<Scalars['String']>;
  /** Kilometre allowance. */
  kmAllowance?: InputMaybe<Scalars['Int']>;
  /** Legal name */
  legalName: Scalars['String'];
  /** Maximum member lateness percentage for automatic handover event allocation. */
  maxMemberLatenessForAutomaticHandoverEvents?: InputMaybe<Scalars['Int']>;
  /** Maximum member lateness percentage for automatic public-holiday event allocation. */
  maxMemberLatenessForAutomaticPublicHolidayEvents?: InputMaybe<Scalars['Int']>;
  /** Maximum member lateness percentage for automatic Saturday event allocation. */
  maxMemberLatenessForAutomaticSaturdayEvents?: InputMaybe<Scalars['Int']>;
  /** Maximum member lateness percentage for automatic Sunday event allocation. */
  maxMemberLatenessForAutomaticSundayEvents?: InputMaybe<Scalars['Int']>;
  memberDocumentTrackerCollapsedFiles?: InputMaybe<Array<MemberFileType>>;
  memberDocumentTrackerVisibleFiles?: InputMaybe<Array<MemberFileType>>;
  /** Members can see clocked times. */
  membersCanSeeClockedTimes?: InputMaybe<Scalars['Boolean']>;
  /** NdisRegistrationGroups input data. */
  ndisRegistrationGroups?: InputMaybe<QueryByIdsNumericNullableInput>;
  /** Night pay multiplier. */
  nightPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Passive-overnight allowance. */
  passiveAllowance?: InputMaybe<Scalars['Int']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Public holiday pay multiplier. */
  pubPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely after thier own shift. */
  quantityOfFutureShiftsMembersCanSee?: InputMaybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely before thier own shift. */
  quantityOfPastShiftsMembersCanSee?: InputMaybe<Scalars['Int']>;
  /** Saturday pay multiplier. */
  satPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Default maximum schedulable minutes per day. */
  scheduleMaxDayTimeLimitDefault?: InputMaybe<Scalars['Int']>;
  /** Default maximum schedulable minutes per week. */
  scheduleMaxWeekTimeLimitDefault?: InputMaybe<Scalars['Int']>;
  /** Sunday pay multiplier. */
  sunPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Enable task: Assign Attendee. */
  taskAssignAttendeeEnable?: InputMaybe<Scalars['Boolean']>;
  /** Days in the future to assign attendees. */
  taskAssignAttendeeFutureDays?: InputMaybe<Scalars['Int']>;
  /** Weeks in the past to find attendees. */
  taskAssignAttendeePastWeeks?: InputMaybe<Scalars['Int']>;
  /** Allow travel time to be assigned to subsequent events for the same client. */
  travelTimeAllowSameClient?: InputMaybe<Scalars['Boolean']>;
  /** Allowed duration between events for travel time eligibility. */
  travelTimeAllowedDurationBetweenEvents?: InputMaybe<Scalars['Int']>;
  /** Days in advance to check for unfilled shifts. */
  unfilledEventsDayRange?: InputMaybe<Scalars['Int']>;
  /** Website of the organisation */
  website?: InputMaybe<Scalars['String']>;
};

/** Creation input data for singular "PayrollCategoriesCreatableFieldsInput" entity. */
export type CreatePayrollCategoryInput = {
  /** Name */
  name: Scalars['String'];
  /** "PayrollCategoryValue" entity input data. */
  value: PayrollCategoryValuesSettableFieldsInput;
};

/** Creation input data for "PlanManager" entities. */
export type CreatePlanManagersInput = {
  objects: Array<PlanManagersCreatableFieldsInput>;
};

/** Creation input data for "SupportCoordinator" entities. */
export type CreateSupportCoordinatorsInput = {
  objects: Array<SupportCoordinatorsCreatableFieldsInput>;
};

/** Creation input data for singular "User" entities. */
export type CreateUserInput = {
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address */
  email?: InputMaybe<Scalars['String']>;
  /** First name */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** A unique identifier. Either an email address or phone number. */
  identifier: Scalars['UserIdentifier'];
  /** Last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's mailing list preferrence. */
  mailingList?: Scalars['Boolean'];
  /** Middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /** Passwords must be at least 8 characters in length and contain a combination of lowercase, uppercase and special characters */
  password: Scalars['UserPlainTextPassword'];
  /** Phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** User's chosen application theme. */
  theme?: InputMaybe<UserTheme>;
};

/** Creation input data for singular "VisaType" entities. */
export type CreateVisaTypeInput = {
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<Scalars['Int']>;
  /** Allowed worked hours is waived. */
  allowedWorkHoursWaived: Scalars['Boolean'];
  /** Description. */
  description?: InputMaybe<Scalars['String']>;
  /** Name of VISA. */
  name: Scalars['String'];
};

/** Date conditional expression input. */
export type DateConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['Date']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['Date']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['Date']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['Date']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['Date']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['Date']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['Date']>>;
};

/** DateTime conditional expression input. */
export type DateTimeConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['DateTime']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['DateTime']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['DateTime']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['DateTime']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['DateTime']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['DateTime']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['DateTime']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['DateTime']>>;
};

/** Input data for deleting entities. */
export type DeleteInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Reason for deletion. */
  reason?: InputMaybe<Scalars['String']>;
};

/** Dismissal input data for "ClientNoteKeywordMatch" entities. */
export type DismissClientNoteKeywordMatchesInput = {
  dismissAll?: InputMaybe<Scalars['Boolean']>;
  /** Entity IDs. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

/** Drivers license. */
export enum DriversLicense {
  C = 'C',
  HC = 'HC',
  HR = 'HR',
  L = 'L',
  MC = 'MC',
  MR = 'MR',
  NO = 'NO',
  P1 = 'P1',
  P2 = 'P2',
  UNKNOWN = 'UNKNOWN',
  YES = 'YES',
}

/** Quering input data for potential event attendees. */
export type DuplicateEventsInput = {
  /** Automatically assign duplicated events to attendees. */
  assignAttendees?: InputMaybe<Scalars['Boolean']>;
  /** Attempt to assign duplicated events to the same attendees. */
  attemptSameAttendees?: InputMaybe<Scalars['Boolean']>;
  /** Optional Branches input data. */
  branches?: InputMaybe<QueryByIdInput>;
  /** Optional Client input data. */
  client?: InputMaybe<QueryByIdInput>;
  /** Duplicate events even if conflicts would arise. */
  duplicateConflictingEvents?: InputMaybe<Scalars['Boolean']>;
  /** Source week. */
  sourceWeek: Scalars['String'];
  /** Target week. */
  targetWeek: Scalars['String'];
  /** Timezone. */
  timezone: Scalars['String'];
};

export enum EntityRestorableState {
  ARCHIVED = 'ARCHIVED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export enum EntityState {
  ARCHIVED = 'ARCHIVED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  NORMAL = 'NORMAL',
}

export type Event = Address & {
  __typename?: 'Event';
  /** Passive active-assist state. */
  activeAssist: Scalars['Boolean'];
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Client-billable status. */
  billable: Scalars['Boolean'];
  /** Billing registration group. */
  billingRegistrationGroup: EventBillingRegistrationGroup;
  /** Member-bonus-pay ammount. */
  bonusPay: Scalars['Int'];
  /** Building name. */
  buildingName?: Maybe<Scalars['String']>;
  /** Cancellation data, if entity is cancelled. */
  cancel?: Maybe<HistoryRestorable>;
  /** Resolver for "claims" field. */
  claims: Array<EventClaim>;
  /** Client linked with the event. */
  client: Client;
  /** Clock-off datetime. */
  clockedOffAt?: Maybe<Scalars['DateTime']>;
  /** Member who clocked-off. */
  clockedOffBy?: Maybe<Member>;
  /** Clocked-off latitude coordinate. */
  clockedOffLatitude?: Maybe<Scalars['String']>;
  /** Clocked-off longitude coordinate. */
  clockedOffLongitude?: Maybe<Scalars['String']>;
  /** Clock-on datetime. */
  clockedOnAt?: Maybe<Scalars['DateTime']>;
  /** Member who clocked-on. */
  clockedOnBy?: Maybe<Member>;
  /** Was event clocked on late?. */
  clockedOnLate: Scalars['Boolean'];
  /** Clocked-on latitude coordinate. */
  clockedOnLatitude?: Maybe<Scalars['String']>;
  /** Clocked-on longitude coordinate. */
  clockedOnLongitude?: Maybe<Scalars['String']>;
  /** Address country. */
  country?: Maybe<AddressCountryCode>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Parent Event entity. */
  duplicateOf?: Maybe<Event>;
  /** Duration of the event in minutes. */
  duration: Scalars['Int'];
  /** Enable duplication. */
  enableDuplication: Scalars['Boolean'];
  /** End datetime of the final instance of the event. */
  endAt: Scalars['DateTime'];
  hasFiles: Scalars['Boolean'];
  hasIssue: Scalars['Boolean'];
  hasNotes: Scalars['Boolean'];
  hasSeizureObservation: Scalars['Boolean'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Resolver for "issues" field. */
  issues: Array<EventIssue>;
  /** Address latitude coordinate. */
  latitude?: Maybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: Maybe<Scalars['String']>;
  /** Locked status. */
  locked?: Maybe<Scalars['Boolean']>;
  /** Address longitude coordinate. */
  longitude?: Maybe<Scalars['String']>;
  /** Member linked with the event. */
  member?: Maybe<Member>;
  /** Member was assigned automatically by the server. */
  memberAssignedAutomatically: Scalars['Boolean'];
  /** Detailed information concering the event. */
  notes?: Maybe<Scalars['String']>;
  /** Event is passive. */
  passive: Scalars['Boolean'];
  /** Start datetime of the passive period. Can not exceed 4 hours past event startAt. */
  passiveStartAt?: Maybe<Scalars['DateTime']>;
  /** Member-payable status. */
  payable: Scalars['Boolean'];
  /** Address postcode. */
  postcode?: Maybe<Scalars['PostalCode']>;
  /** Does event occurs on a public holiday? */
  publicHoliday?: Maybe<Scalars['Boolean']>;
  /** Publish status. */
  published?: Maybe<Scalars['Boolean']>;
  /** Address state or territory. */
  region?: Maybe<AddressRegionCode>;
  /** Requirement: Car. */
  requireCar: Scalars['Boolean'];
  /** Revenue. */
  revenue: Scalars['Int'];
  /** Scheduling information concering the event. */
  scheduleNotes?: Maybe<Scalars['String']>;
  /** Start datetime of the event. */
  startAt: Scalars['DateTime'];
  /** Address street number, name and type. */
  street?: Maybe<Scalars['String']>;
  /** A short summary of the event. */
  summary?: Maybe<Scalars['String']>;
  /** Timezone */
  timezone?: Maybe<Scalars['String']>;
  /** Datetime of when travel data was approved. */
  travelApprovedAt?: Maybe<Scalars['DateTime']>;
  /** Member who approved the travel data. */
  travelApprovedBy?: Maybe<Member>;
  /** Travel distance in meters. */
  travelDistance?: Maybe<Scalars['Int']>;
  travelDistanceNotes?: Maybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime?: Maybe<Scalars['Int']>;
  travelTimeNotes?: Maybe<Scalars['String']>;
  /** Address unit number. */
  unit?: Maybe<Scalars['String']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Verification status. */
  verified?: Maybe<Scalars['Boolean']>;
  /** Start datetime of the event. */
  verifiedAt?: Maybe<Scalars['DateTime']>;
  /** Verifier. */
  verifiedBy?: Maybe<Member>;
};

export type EventClaimsArgs = {
  input?: InputMaybe<QueryEventClaimsInput>;
};

export type EventIssuesArgs = {
  input?: InputMaybe<QueryEventIssuesInput>;
};

export type EventAttendeeSuitability = {
  __typename?: 'EventAttendeeSuitability';
  attendeeId: Scalars['String'];
  unsuitabilityReasons?: Maybe<Array<Scalars['String']>>;
};

/** Event NDIS billing registration group. */
export enum EventBillingRegistrationGroup {
  /** Assistance with Social, Economic and Community Participation. */
  COMMUNITY_PARTICIPATION = 'COMMUNITY_PARTICIPATION',
  DAILY_LIFE_SKILLS = 'DAILY_LIFE_SKILLS',
  /** Daily Personal Activities. */
  DAILY_PERSONAL_ACTIVITIES = 'DAILY_PERSONAL_ACTIVITIES',
}

export type EventClaim = {
  __typename?: 'EventClaim';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Datetime of claim's dismissal. */
  dismissedAt?: Maybe<Scalars['DateTime']>;
  /** Member who dismissed the claim. */
  dismissedBy?: Maybe<Member>;
  /** Linked Event entity */
  event: Event;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Resolver for "issues" field. */
  issues: EventClaimIssues;
  /** Linked Member entity */
  member: Member;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventClaimIssues = {
  __typename?: 'EventClaimIssues';
  /** Claim. */
  claim: Array<EventClaim>;
  /** Reasons */
  reasons?: Maybe<Array<Scalars['String']>>;
};

/** Aggregated response object for "EventClaim" entities. */
export type EventClaimsAggregate = {
  __typename?: 'EventClaimsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<EventClaim>;
};

/** Ordering options. */
export type EventClaimsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by dismissal datetime. */
  dismissedAt?: InputMaybe<OrderBy>;
  /** Order by dismisser. */
  dismissedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by event. */
  event?: InputMaybe<EventsOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by member. */
  member?: InputMaybe<MembersOrderByInput>;
};

/** Filtering conditions. */
export type EventClaimsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<EventClaimsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<EventClaimsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<EventClaimsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Datetime of entitys dismissal. */
  dismissedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Dismisser. */
  dismissedBy?: InputMaybe<MembersWhereInput>;
  /** Linked Event entity. */
  event?: InputMaybe<EventsWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked Member entity. */
  member?: InputMaybe<MembersWhereInput>;
};

export type EventConflict = {
  __typename?: 'EventConflict';
  conflict: Conflict;
  event: Event;
};

/** Ordering options. */
export type EventConflictsOrderByInput = {
  /** Order by conflicts. */
  conflict?: InputMaybe<ConflictsOrderByInput>;
  /** Order by events. */
  event?: InputMaybe<EventsOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Filtering conditions. */
export type EventConflictsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<EventConflictsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<EventConflictsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<EventConflictsWhereInput>>;
  /** Linked Conflict entity. */
  conflict?: InputMaybe<ConflictsWhereInput>;
  /** Linked Event entity. */
  event?: InputMaybe<EventsWhereInput>;
};

export type EventFile = {
  __typename?: 'EventFile';
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: Maybe<Scalars['Date']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  file: File;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Owner of the file. */
  owner: Event;
  type: EventFileType;
  value?: Maybe<Scalars['String']>;
};

/** Type of file belonging to an "Event" entity. */
export enum EventFileType {
  MISC = 'MISC',
  TRAVEL_RECEIPT = 'TRAVEL_RECEIPT',
}

/** Aggregated response object for "EventFile" entities. */
export type EventFilesAggregate = {
  __typename?: 'EventFilesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<EventFile>;
};

/** Ordering options for "EventFile" entities. */
export type EventFilesOrderByInput = {
  /** Order by date-of-file. */
  dateOfFile?: InputMaybe<OrderBy>;
  /** Order by file. */
  file?: InputMaybe<FilesOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by value. */
  value?: InputMaybe<OrderBy>;
};

/** Updatable fields for "EventFile" entities. */
export type EventFilesUpdatableFieldsInput = {
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Owner of the file. */
  owner?: InputMaybe<QueryByIdInput>;
  type?: InputMaybe<EventFileType>;
  value?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "EventFile" entities. */
export type EventFilesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<EventFilesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<EventFilesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<EventFilesWhereInput>>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<DateTimeConditionalInput>;
  /** File. */
  file?: InputMaybe<FilesWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Owner. */
  owner?: InputMaybe<EventsWhereInput>;
  /** File type. */
  type?: InputMaybe<StringConditionalInput>;
  /** Value. */
  value?: InputMaybe<StringConditionalInput>;
};

/** Event filter. */
export enum EventFilter {
  /** Event does not have travel time but should. */
  SHOULD_HAVE_TRAVEL_TIME = 'SHOULD_HAVE_TRAVEL_TIME',
  /** Event does has travel time but should not. */
  SHOULD_NOT_HAVE_TRAVEL_TIME = 'SHOULD_NOT_HAVE_TRAVEL_TIME',
}

export type EventIssue = {
  __typename?: 'EventIssue';
  /** Closure date. */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Member who closed the issue. */
  closedBy?: Maybe<Member>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Linked Event entity. */
  event: Event;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Issue type. */
  type: EventIssueType;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Type of event issue. */
export enum EventIssueType {
  /** Add notes. */
  ADD_NOTES = 'ADD_NOTES',
  /** Clocked wrong location. */
  CLOCKED_WRONG_LOCATION = 'CLOCKED_WRONG_LOCATION',
  /** Clocked wrong time. */
  CLOCKED_WRONG_TIME = 'CLOCKED_WRONG_TIME',
  /** Did not clock. */
  DID_NOT_CLOCK = 'DID_NOT_CLOCK',
  /** Did not clock off. */
  DID_NOT_CLOCK_OFF = 'DID_NOT_CLOCK_OFF',
  /** Did not clock on. */
  DID_NOT_CLOCK_ON = 'DID_NOT_CLOCK_ON',
  /** Notes. */
  FIX_NOTES = 'FIX_NOTES',
  /** Missing handover. */
  MISSING_HANDOVER = 'MISSING_HANDOVER',
  /** Missing kilometres. */
  MISSING_KILOMETRES = 'MISSING_KILOMETRES',
}

/** Ordering options. */
export type EventIssuesOrderByInput = {
  closedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Filtering conditions. */
export type EventIssuesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<EventIssuesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<EventIssuesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<EventIssuesWhereInput>>;
  /** Datetime of entitys dismissal. */
  closedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Linked Event entity. */
  event?: InputMaybe<EventsWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
};

export type EventOwnAndRelated = Address & {
  __typename?: 'EventOwnAndRelated';
  /** Passive active-assist state. */
  activeAssist: Scalars['Boolean'];
  /** Address building name. */
  buildingName?: Maybe<Scalars['String']>;
  /** Cancellation data, if entity is cancelled. */
  cancel?: Maybe<HistoryRestorable>;
  /** Redacted client linked with the event. */
  client: RedactedClient;
  /** Clock-off datetime. */
  clockedOffAt?: Maybe<Scalars['DateTime']>;
  /** Clock-on datetime. */
  clockedOnAt?: Maybe<Scalars['DateTime']>;
  /** Address country. */
  country?: Maybe<AddressCountryCode>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Duration of the event in minutes. */
  duration: Scalars['Int'];
  /** End datetime of the final instance of the event. */
  endAt: Scalars['DateTime'];
  hasFiles: Scalars['Boolean'];
  hasIssue: Scalars['Boolean'];
  hasNotes: Scalars['Boolean'];
  hasSeizureObservation: Scalars['Boolean'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Resolver for "issues" field. */
  issues: Array<EventIssue>;
  /** Address latitude coordinate. */
  latitude?: Maybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: Maybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: Maybe<Scalars['String']>;
  /** Redacted member linked with the event. */
  member?: Maybe<RedactedMember>;
  /** Member was assigned automatically by the server. */
  memberAssignedAutomatically: Scalars['Boolean'];
  /** Detailed information concering the event. */
  notes?: Maybe<Scalars['String']>;
  observations: Array<ClientObservation>;
  /** Event is passive. */
  passive: Scalars['Boolean'];
  /** Start datetime of the passive period. */
  passiveStartAt?: Maybe<Scalars['DateTime']>;
  /** Address postcode. */
  postcode?: Maybe<Scalars['PostalCode']>;
  /** Does event occurs on a public holiday? */
  publicHoliday: Scalars['Boolean'];
  /** Address state or territory. */
  region?: Maybe<AddressRegionCode>;
  /** Requirement: Car. */
  requireCar: Scalars['Boolean'];
  /** Start datetime of the event. */
  startAt: Scalars['DateTime'];
  /** Address street number, name and type. */
  street?: Maybe<Scalars['String']>;
  /** A short summary of the event. */
  summary?: Maybe<Scalars['String']>;
  /** Timezone */
  timezone?: Maybe<Scalars['String']>;
  /** Travel distance in meters. */
  travelDistance: Scalars['Int'];
  travelDistanceNotes?: Maybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime: Scalars['Int'];
  travelTimeNotes?: Maybe<Scalars['String']>;
  /** Address unit number. */
  unit?: Maybe<Scalars['String']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Verification status. */
  verified?: Maybe<Scalars['Boolean']>;
};

export type EventOwnAndRelatedIssuesArgs = {
  input?: InputMaybe<QueryEventIssuesInput>;
};

/** Aggregated response object for "Event" entities. */
export type EventsAggregate = {
  __typename?: 'EventsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Event>;
};

/** Cancellation input data for "Event" entities. */
export type EventsCancelInput = {
  objects: Array<EventsCancellableFieldsInput>;
};

/** Input data for cancelling "Event" entities. */
export type EventsCancellableFieldsInput = {
  /** Client-billable status. */
  billable: Scalars['Boolean'];
  /** Entity ID. */
  id: Scalars['ID'];
  /** Member-payable status. */
  payable: Scalars['Boolean'];
  /** Reason for cancellation. */
  reason?: InputMaybe<Scalars['String']>;
};

/** Creatable fields for "Event" entities */
export type EventsCreatableFieldsInput = {
  /** Passive active-assist state. */
  activeAssist?: InputMaybe<Scalars['Boolean']>;
  /** Client-billable status. */
  billable?: InputMaybe<Scalars['Boolean']>;
  /** Billing registration group. */
  billingRegistrationGroup?: InputMaybe<EventBillingRegistrationGroup>;
  /** Member-bonus-pay ammount. */
  bonusPay?: InputMaybe<Scalars['Int']>;
  /** Building name. */
  buildingName?: InputMaybe<Scalars['String']>;
  /** Client input data. */
  client: QueryByIdInput;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Duration of the event in minutes. */
  duration: Scalars['Int'];
  /** Enable duplication. */
  enableDuplication?: InputMaybe<Scalars['Boolean']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Locked status. */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Event is passive. */
  passive?: InputMaybe<Scalars['Boolean']>;
  /** Start datetime of the passive period. Can not exceed 4 hours past event startAt. */
  passiveStartAt?: InputMaybe<Scalars['DateTime']>;
  /** Member-payable status. */
  payable?: InputMaybe<Scalars['Boolean']>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Does event occurs on a public holiday? */
  publicHoliday?: InputMaybe<Scalars['Boolean']>;
  /** Publish status. */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Requirement: Car. */
  requireCar?: InputMaybe<Scalars['Boolean']>;
  /** Scheduling information concering the event. */
  scheduleNotes?: InputMaybe<Scalars['String']>;
  /** Start datetime of the event. */
  startAt: Scalars['DateTime'];
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** A short summary of the event. */
  summary?: InputMaybe<Scalars['String']>;
  /** Travel distance in meters. */
  travelDistance?: InputMaybe<Scalars['Int']>;
  travelDistanceNotes?: InputMaybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<Scalars['Int']>;
  travelTimeNotes?: InputMaybe<Scalars['String']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Ordering options for "Event" entities. */
export type EventsOrderByInput = {
  /** Order by active-assist state. */
  activeAssist?: InputMaybe<OrderBy>;
  /** Order by billable status. */
  billable?: InputMaybe<OrderBy>;
  /** Order by client. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by client notes. */
  clientNotes?: InputMaybe<ClientNotesOrderByInput>;
  /** Order by clock-off datetime. */
  clockedOffAt?: InputMaybe<OrderBy>;
  /** Order by clock-on datetime. */
  clockedOnAt?: InputMaybe<OrderBy>;
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by duration. */
  duration?: InputMaybe<OrderBy>;
  /** Order by end datetime. */
  endAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by locked status. */
  locked?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by member. */
  member?: InputMaybe<MembersOrderByInput>;
  /** Order by notes. */
  notes?: InputMaybe<OrderBy>;
  /** Order by passive state. */
  passive?: InputMaybe<OrderBy>;
  /** Order by passive start datetime. */
  passiveStartAt?: InputMaybe<OrderBy>;
  /** Order by member payable status. */
  payable?: InputMaybe<OrderBy>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by public holiday status. */
  publicHoliday?: InputMaybe<OrderBy>;
  /** Order by published status. */
  published?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by start datetime. */
  startAt?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by summary. */
  summary?: InputMaybe<OrderBy>;
  /** Order by travel approved at datetime. */
  travelApproveAt?: InputMaybe<OrderBy>;
  /** Order by the member who approved the travel data. */
  travelApprovedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by travel distance. */
  travelDistance?: InputMaybe<OrderBy>;
  /** Order by travel distance notes. */
  travelDistanceNotes?: InputMaybe<OrderBy>;
  /** Order by travel set at datetime. */
  travelSetAt?: InputMaybe<OrderBy>;
  /** Order by the member who set the travel data. */
  travelSetBy?: InputMaybe<MembersOrderByInput>;
  /** Order by travel time. */
  travelTime?: InputMaybe<OrderBy>;
  /** Order by travel time notes. */
  travelTimeNotes?: InputMaybe<OrderBy>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
};

/** Ordering options for "EventOwnAndRelated" entities. */
export type EventsOwnAndRelatedOrderByInput = {
  /** Order by client. */
  client?: InputMaybe<ClientsOrderByInput>;
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by duration. */
  duration?: InputMaybe<OrderBy>;
  /** Order by end datetime. */
  endAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by member. */
  member?: InputMaybe<MembersOrderByInput>;
  /** Order by notes. */
  notes?: InputMaybe<OrderBy>;
  /** Order by passive state. */
  passive?: InputMaybe<OrderBy>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by public holiday status. */
  publicHoliday?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by start datetime. */
  startAt?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by summary. */
  summary?: InputMaybe<OrderBy>;
  /** Order by travel distance. */
  travelDistance?: InputMaybe<OrderBy>;
  /** Order by travel distance notes. */
  travelDistanceNotes?: InputMaybe<OrderBy>;
  /** Order by travel time. */
  travelTime?: InputMaybe<OrderBy>;
  /** Order by travel time notes. */
  travelTimeNotes?: InputMaybe<OrderBy>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "EventOwnAndRelated" entities. */
export type EventsOwnAndRelatedWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<EventsOwnAndRelatedWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<EventsOwnAndRelatedWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<EventsOwnAndRelatedWhereInput>>;
  /** Client. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Duration in minutes. */
  duration?: InputMaybe<IntConditionalInput>;
  /** End datetime. */
  endAt?: InputMaybe<DateTimeConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Passive-state. */
  passive?: InputMaybe<BooleanConditionalInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Public-holiday-status. */
  publicHoliday?: InputMaybe<BooleanConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Start datetime. */
  startAt?: InputMaybe<DateTimeConditionalInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Summary. */
  summary?: InputMaybe<StringConditionalInput>;
  /** Travel distance. */
  travelDistance?: InputMaybe<IntConditionalInput>;
  /** Travel distance notes. */
  travelDistanceNotes?: InputMaybe<StringConditionalInput>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<IntConditionalInput>;
  /** Travel time notes. */
  travelTimeNotes?: InputMaybe<StringConditionalInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
};

/** Updatable basic fields for "Event" entities. */
export type EventsUpdatableBasicFieldsInput = {
  /** Travel distance in meters. */
  travelDistance?: InputMaybe<Scalars['Int']>;
  travelDistanceNotes?: InputMaybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<Scalars['Int']>;
  travelTimeNotes?: InputMaybe<Scalars['String']>;
};

/** Updatable fields for "Event" entities. */
export type EventsUpdatableFieldsInput = {
  /** Passive active-assist state. */
  activeAssist?: InputMaybe<Scalars['Boolean']>;
  /** Client-billable status. */
  billable?: InputMaybe<Scalars['Boolean']>;
  /** Billing registration group. */
  billingRegistrationGroup?: InputMaybe<EventBillingRegistrationGroup>;
  /** Member-bonus-pay ammount. */
  bonusPay?: InputMaybe<Scalars['Int']>;
  /** Building name. */
  buildingName?: InputMaybe<Scalars['String']>;
  /** Client input data. */
  client?: InputMaybe<QueryByIdInput>;
  /** Clock-off datetime. */
  clockedOffAt?: InputMaybe<Scalars['DateTime']>;
  /** Clock-on datetime. */
  clockedOnAt?: InputMaybe<Scalars['DateTime']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Duration of the event in minutes. */
  duration?: InputMaybe<Scalars['Int']>;
  /** Enable duplication. */
  enableDuplication?: InputMaybe<Scalars['Boolean']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Locked status. */
  locked?: InputMaybe<Scalars['Boolean']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Event is passive. */
  passive?: InputMaybe<Scalars['Boolean']>;
  /** Start datetime of the passive period. Can not exceed 4 hours past event startAt. */
  passiveStartAt?: InputMaybe<Scalars['DateTime']>;
  /** Member-payable status. */
  payable?: InputMaybe<Scalars['Boolean']>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Does event occurs on a public holiday? */
  publicHoliday?: InputMaybe<Scalars['Boolean']>;
  /** Publish status. */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Requirement: Car. */
  requireCar?: InputMaybe<Scalars['Boolean']>;
  /** Scheduling information concering the event. */
  scheduleNotes?: InputMaybe<Scalars['String']>;
  /** Start datetime of the event. */
  startAt?: InputMaybe<Scalars['DateTime']>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** A short summary of the event. */
  summary?: InputMaybe<Scalars['String']>;
  /** Travel distance in meters. */
  travelDistance?: InputMaybe<Scalars['Int']>;
  travelDistanceNotes?: InputMaybe<Scalars['String']>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<Scalars['Int']>;
  travelTimeNotes?: InputMaybe<Scalars['String']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "Event" entities. */
export type EventsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<EventsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<EventsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<EventsWhereInput>>;
  /** Active-state state. */
  activeAssist?: InputMaybe<BooleanConditionalInput>;
  /** Primary identifier key. */
  archive?: InputMaybe<HistoriesWhereInput>;
  /** Billable. */
  billable?: InputMaybe<BooleanConditionalInput>;
  /** Primary identifier key. */
  cancel?: InputMaybe<HistoriesWhereInput>;
  /** Claims. */
  claims?: InputMaybe<EventClaimsWhereInput>;
  /** Client. */
  client?: InputMaybe<ClientsWhereInput>;
  /** Linked ClientNote entities. */
  clientNotes?: InputMaybe<ClientNotesWhereInput>;
  /** End datetime. */
  clockedOffAt?: InputMaybe<DateTimeConditionalInput>;
  /** Start datetime. */
  clockedOnAt?: InputMaybe<DateTimeConditionalInput>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  delete?: InputMaybe<HistoriesWhereInput>;
  /** Duration in minutes. */
  duration?: InputMaybe<IntConditionalInput>;
  /** End datetime. */
  endAt?: InputMaybe<DateTimeConditionalInput>;
  /** EventConflict associative entities. */
  eventConflicts?: InputMaybe<EventConflictsWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Locked. */
  locked?: InputMaybe<BooleanConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Attending member. */
  member?: InputMaybe<MembersWhereInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Passive state. */
  passive?: InputMaybe<BooleanConditionalInput>;
  /** Passive start datetime. */
  passiveStartAt?: InputMaybe<DateTimeConditionalInput>;
  /** Member payable status. */
  payable?: InputMaybe<BooleanConditionalInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Public-holiday-status. */
  publicHoliday?: InputMaybe<BooleanConditionalInput>;
  /** Published. */
  published?: InputMaybe<BooleanConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Start datetime. */
  startAt?: InputMaybe<DateTimeConditionalInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Summary. */
  summary?: InputMaybe<StringConditionalInput>;
  /** Travel approved at datetime. */
  travelApprovedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Member who last approved the travel data. */
  travelApprovedBy?: InputMaybe<MembersWhereInput>;
  /** Travel distance. */
  travelDistance?: InputMaybe<IntConditionalInput>;
  /** Travel distance notes. */
  travelDistanceNotes?: InputMaybe<StringConditionalInput>;
  /** Travel set at datetime. */
  travelSetAt?: InputMaybe<DateTimeConditionalInput>;
  /** Member who last set the travel data. */
  travelSetBy?: InputMaybe<MembersWhereInput>;
  /** Travel time in minutes. */
  travelTime?: InputMaybe<IntConditionalInput>;
  /** Travel time notes. */
  travelTimeNotes?: InputMaybe<StringConditionalInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime'];
  createdBy: Member;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Content MIME type. */
  mime: Scalars['String'];
  /** Name of file. */
  name: Scalars['String'];
  /** Path to file. */
  path: Scalars['String'];
  /** Size in bytes. */
  size: Scalars['Int'];
};

/** File storage statistics. */
export type FileStorageStatistics = {
  __typename?: 'FileStorageStatistics';
  /** Allocated space in bytes. */
  allocatedSpace: Scalars['Int'];
  /** Number of stored files. */
  fileCount: Scalars['Int'];
  /** Used space in bytes. */
  usedSpace: Scalars['Int'];
};

/** Ordering options for "File" entities. */
export type FilesOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by file size. */
  size?: InputMaybe<OrderBy>;
};

/** Updatable fields for entities. */
export type FilesUpdatableFieldsInput = {
  /** Name of file. */
  name?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "File" entities. */
export type FilesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<FilesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<FilesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<FilesWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** File size. */
  size?: InputMaybe<IntConditionalInput>;
};

/** Float conditional expression input. */
export type FloatConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['Float']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['Float']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['Float']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['Float']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['Float']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['Float']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['Float']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

/** Full text search conditional expression input. */
export type FullTextSearchConditionalInput = {
  /** Full text. */
  _fulltext?: InputMaybe<Scalars['String']>;
};

/** Gender */
export enum Gender {
  /** Female */
  FEMALE = 'FEMALE',
  /** Intersex */
  INTERSEX = 'INTERSEX',
  /** Male */
  MALE = 'MALE',
  /** Not specified */
  NOTSPECIFIED = 'NOTSPECIFIED',
}

/** Generation input data for "MemberHour" entities. */
export type GenerateMemberHoursInput = {
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
};

/** Group entity. */
export type Group = {
  __typename?: 'Group';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name. */
  name: Scalars['String'];
  /** Organisation to which group belongs. */
  org: Org;
  /** Permissions within the organisation. */
  permissions?: Maybe<Array<Permission>>;
};

/** Ordering options when selecting data from "History" sub-entities. */
export type HistoriesOrderByInput = {
  /** Order by actor. */
  actor?: InputMaybe<MembersOrderByInput>;
  /** Order by creation date. */
  date?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Union of historical entity records. */
export type HistoriesUnion =
  | HistoryBranchName
  | HistoryClientAllowAutomaticEventPopulation
  | HistoryClientColor
  | HistoryClientConfidentialNotes
  | HistoryClientContactCustomRelation
  | HistoryClientContactEmail
  | HistoryClientContactEmergencyContact
  | HistoryClientContactName
  | HistoryClientContactPhone
  | HistoryClientContactRelations
  | HistoryClientCountry
  | HistoryClientDefaultEventDuration
  | HistoryClientDob
  | HistoryClientEmail
  | HistoryClientExternalId
  | HistoryClientFileDateOfFile
  | HistoryClientFileExpiresAt
  | HistoryClientFileOwner
  | HistoryClientFileType
  | HistoryClientFileValue
  | HistoryClientFirstName
  | HistoryClientGender
  | HistoryClientLastName
  | HistoryClientLocality
  | HistoryClientMiddleName
  | HistoryClientNdisId
  | HistoryClientNdisPlanCoreSupportItem
  | HistoryClientNdisPlanEndAt
  | HistoryClientNdisPlanEndDate
  | HistoryClientNdisPlanInitialFundsCb
  | HistoryClientNdisPlanInitialFundsCore
  | HistoryClientNdisPlanInitialFundsCs
  | HistoryClientNdisPlanItemCategory
  | HistoryClientNdisPlanItemInitialFunds
  | HistoryClientNdisPlanItemManagedBy
  | HistoryClientNdisPlanItemName
  | HistoryClientNdisPlanItemTransactionBillingDate
  | HistoryClientNdisPlanItemTransactionInvoiceNumber
  | HistoryClientNdisPlanItemTransactionMemo
  | HistoryClientNdisPlanItemTransactionServiceDateEnd
  | HistoryClientNdisPlanItemTransactionServiceDateStart
  | HistoryClientNdisPlanItemTransactionSupplier
  | HistoryClientNdisPlanItemTransactionSupplierName
  | HistoryClientNdisPlanItemTransactionValue
  | HistoryClientNdisPlanStartAt
  | HistoryClientNdisPlanStartDate
  | HistoryClientNdisPlanSupportCoordinationItem
  | HistoryClientNoteComment
  | HistoryClientNoteCommentedAt
  | HistoryClientNoteCommentedBy
  | HistoryClientNoteCommentedByText
  | HistoryClientNoteKeywordText
  | HistoryClientNoteType
  | HistoryClientNotes
  | HistoryClientObservationBloodDiastolic
  | HistoryClientObservationBloodSystolic
  | HistoryClientObservationDate
  | HistoryClientObservationHeart
  | HistoryClientObservationMember
  | HistoryClientObservationNotes
  | HistoryClientObservationNursesName
  | HistoryClientObservationRespiration
  | HistoryClientObservationSpo2
  | HistoryClientObservationStoolType
  | HistoryClientObservationTemp
  | HistoryClientPhone
  | HistoryClientPlanManager
  | HistoryClientPostcode
  | HistoryClientPreferredName
  | HistoryClientPronouns
  | HistoryClientRegion
  | HistoryClientSalutation
  | HistoryClientStreet
  | HistoryClientSupportCoordinator
  | HistoryClientUnit
  | HistoryEventActiveAssist
  | HistoryEventBillable
  | HistoryEventBillingRegistrationGroup
  | HistoryEventClient
  | HistoryEventClockedOffAt
  | HistoryEventClockedOnAt
  | HistoryEventCountry
  | HistoryEventDuration
  | HistoryEventLocality
  | HistoryEventMember
  | HistoryEventNotes
  | HistoryEventPassive
  | HistoryEventPassiveStartAt
  | HistoryEventPayable
  | HistoryEventPostcode
  | HistoryEventPublicHoliday
  | HistoryEventRegion
  | HistoryEventRequireCar
  | HistoryEventScheduleNotes
  | HistoryEventStartAt
  | HistoryEventStreet
  | HistoryEventSummary
  | HistoryEventTravelDistance
  | HistoryEventTravelDistanceNotes
  | HistoryEventTravelTime
  | HistoryEventTravelTimeNotes
  | HistoryEventUnit
  | HistoryMemberAdmin
  | HistoryMemberBasePayRateDate
  | HistoryMemberBasePayRateType
  | HistoryMemberBasePayRateValue
  | HistoryMemberColor
  | HistoryMemberConfidentialNotes
  | HistoryMemberCountry
  | HistoryMemberDob
  | HistoryMemberEmail
  | HistoryMemberEmpathyRating
  | HistoryMemberExternalId
  | HistoryMemberFileDateOfFile
  | HistoryMemberFileExpiresAt
  | HistoryMemberFileOwner
  | HistoryMemberFileType
  | HistoryMemberFileValue
  | HistoryMemberFirstName
  | HistoryMemberGender
  | HistoryMemberLastName
  | HistoryMemberLocality
  | HistoryMemberMiddleName
  | HistoryMemberNotes
  | HistoryMemberPhone
  | HistoryMemberPostcode
  | HistoryMemberPreferredName
  | HistoryMemberPronouns
  | HistoryMemberRegion
  | HistoryMemberReliabilityRating
  | HistoryMemberSalutation
  | HistoryMemberSchedulable
  | HistoryMemberStreet
  | HistoryMemberUnit
  | HistoryOrgAbn
  | HistoryOrgEmail
  | HistoryOrgLegalName
  | HistoryOrgPhone
  | HistoryOrgPreferredName
  | HistoryOrgWebsite
  | HistoryPlanManagerAbn
  | HistoryPlanManagerBillingEmail
  | HistoryPlanManagerBusinessName
  | HistoryPlanManagerContactEmail
  | HistoryPlanManagerName
  | HistoryPlanManagerNotes
  | HistoryPlanManagerPhone
  | HistoryPlanManagerSalutation
  | HistoryRestorable
  | HistorySupportCoordinatorAbn
  | HistorySupportCoordinatorBusinessEmail
  | HistorySupportCoordinatorBusinessName
  | HistorySupportCoordinatorBusinessPhone
  | HistorySupportCoordinatorEmail
  | HistorySupportCoordinatorFirstName
  | HistorySupportCoordinatorLastName
  | HistorySupportCoordinatorMiddleName
  | HistorySupportCoordinatorNotes
  | HistorySupportCoordinatorPhone
  | HistorySupportCoordinatorPreferredName
  | HistorySupportCoordinatorSalutation
  | HistoryVisaTypeDescription
  | HistoryVisaTypeName;

/** OrgHistory query conditions. */
export type HistoriesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<HistoriesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<HistoriesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<HistoriesWhereInput>>;
  /** Creator of the entity. */
  actor?: InputMaybe<MembersWhereInput>;
  /** "Branch" entity to which this historical record belongs. */
  branch?: InputMaybe<BranchesWhereInput>;
  /** "Client" entity to which this historical record belongs. */
  client?: InputMaybe<ClientsWhereInput>;
  /** "ClientContact" entity to which this historical record belongs. */
  clientContact?: InputMaybe<ClientContactsWhereInput>;
  /** "ClientFile" entity to which this historical record belongs. */
  clientFile?: InputMaybe<ClientFilesWhereInput>;
  /** "ClientNdisPlan" entity to which this historical record belongs. */
  clientNdisPlan?: InputMaybe<ClientNdisPlanWhereInput>;
  /** "ClientNdisPlanItem" entity to which this historical record belongs. */
  clientNdisPlanItem?: InputMaybe<ClientNdisPlanItemWhereInput>;
  /** "ClientNdisPlanItemTransaction" entity to which this historical record belongs. */
  clientNdisPlanItemTransaction?: InputMaybe<ClientNdisPlanItemTransactionWhereInput>;
  /** "ClientNdisPlanItemTransactionSupplier" entity to which this historical record belongs. */
  clientNdisPlanItemTransactionSupplier?: InputMaybe<ClientNdisPlanItemTransactionSuppliersWhereInput>;
  /** "ClientNote" entity to which this historical record belongs. */
  clientNote?: InputMaybe<ClientNotesWhereInput>;
  /** "ClientNoteFile" entity to which this historical record belongs. */
  clientNoteFile?: InputMaybe<ClientNoteFilesWhereInput>;
  /** "ClientNoteKeyword" entity to which this historical record belongs. */
  clientNoteKeyword?: InputMaybe<ClientNoteKeywordsWhereInput>;
  /** "ClientObservation" entity to which this historical record belongs. */
  clientObservation?: InputMaybe<ClientObservationsWhereInput>;
  /** Date of record creation. */
  date?: InputMaybe<DateTimeConditionalInput>;
  /** "Event" entity to which this historical record belongs. */
  event?: InputMaybe<EventsWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** "Member" entity to which this historical record belongs. */
  member?: InputMaybe<MembersWhereInput>;
  /** "MemberBasePayRate" entity to which this historical record belongs. */
  memberBasePayRate?: InputMaybe<MemberBasePayRatesWhereInput>;
  /** "MemberFile" entity to which this historical record belongs. */
  memberFile?: InputMaybe<MemberFilesWhereInput>;
  /** "NdisCoordinationClient" entity to which this historical record belongs. */
  ndisCoordinationClient?: InputMaybe<NdisCoordinationClientWhereInput>;
  /** "NdisCoordinationClientActivity" entity to which this historical record belongs. */
  ndisCoordinationClientActivity?: InputMaybe<NdisCoordinationClientActivityWhereInput>;
  /** "Org" entity to which this historical record belongs. */
  org?: InputMaybe<OrgsWhereInput>;
  /** "PlanManager" entity to which this historical record belongs. */
  planManager?: InputMaybe<PlanManagersWhereInput>;
  /** "SupportCoordinator" entity to which this historical record belongs. */
  supportCoordinator?: InputMaybe<SupportCoordinatorsWhereInput>;
  /** Type of record. */
  type?: InputMaybe<IntConditionalInput>;
  /** "VisaType" entity to which this historical record belongs. */
  visaType?: InputMaybe<VisaTypesWhereInput>;
};

/** Historical "name" update record for "Branch" entities. */
export type HistoryBranchName = BaseHistory & {
  __typename?: 'HistoryBranchName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "allowAutomaticEventPopulation" update record for "Client" entities. */
export type HistoryClientAllowAutomaticEventPopulation = BaseHistory & {
  __typename?: 'HistoryClientAllowAutomaticEventPopulation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  old?: Maybe<Scalars['Boolean']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "color" update record for "Client" entities. */
export type HistoryClientColor = BaseHistory & {
  __typename?: 'HistoryClientColor';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['HexColorCode']>;
  /** Old value. */
  old?: Maybe<Scalars['HexColorCode']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "confidentialNotes" update record for "Client" entities. */
export type HistoryClientConfidentialNotes = BaseHistory & {
  __typename?: 'HistoryClientConfidentialNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "customRelation" update record for "ClientContact" entities. */
export type HistoryClientContactCustomRelation = BaseHistory & {
  __typename?: 'HistoryClientContactCustomRelation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "email" update record for "ClientContact" entities. */
export type HistoryClientContactEmail = BaseHistory & {
  __typename?: 'HistoryClientContactEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['EmailAddress']>;
  /** Old value. */
  old?: Maybe<Scalars['EmailAddress']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "emergencyContact" update record for "ClientContact" entities. */
export type HistoryClientContactEmergencyContact = BaseHistory & {
  __typename?: 'HistoryClientContactEmergencyContact';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  old?: Maybe<Scalars['Boolean']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "name" update record for "ClientContact" entities. */
export type HistoryClientContactName = BaseHistory & {
  __typename?: 'HistoryClientContactName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "ClientContact" entities. */
export type HistoryClientContactPhone = BaseHistory & {
  __typename?: 'HistoryClientContactPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "relations" update record for "ClientContact" entities. */
export type HistoryClientContactRelations = BaseHistory & {
  __typename?: 'HistoryClientContactRelations';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['ClientContactRelationScaler']>;
  /** Old value. */
  old?: Maybe<Scalars['ClientContactRelationScaler']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "country" update record for "Client" entities. */
export type HistoryClientCountry = BaseHistory & {
  __typename?: 'HistoryClientCountry';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<AddressCountryCode>;
  /** Old value. */
  old?: Maybe<AddressCountryCode>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "defaultEventDuration" update record for "Client" entities. */
export type HistoryClientDefaultEventDuration = BaseHistory & {
  __typename?: 'HistoryClientDefaultEventDuration';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "dob" update record for "Client" entities. */
export type HistoryClientDob = BaseHistory & {
  __typename?: 'HistoryClientDob';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "email" update record for "Client" entities. */
export type HistoryClientEmail = BaseHistory & {
  __typename?: 'HistoryClientEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['EmailAddress']>;
  /** Old value. */
  old?: Maybe<Scalars['EmailAddress']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "externalId" update record for "Client" entities. */
export type HistoryClientExternalId = BaseHistory & {
  __typename?: 'HistoryClientExternalId';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "dateOfFile" update record for "ClientFile" entities. */
export type HistoryClientFileDateOfFile = BaseHistory & {
  __typename?: 'HistoryClientFileDateOfFile';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "expiresAt" update record for "ClientFile" entities. */
export type HistoryClientFileExpiresAt = BaseHistory & {
  __typename?: 'HistoryClientFileExpiresAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  old?: Maybe<Scalars['DateTime']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "owner" update record for "ClientFile" entities. */
export type HistoryClientFileOwner = BaseHistory & {
  __typename?: 'HistoryClientFileOwner';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Client>;
  /** Old value. */
  old?: Maybe<Client>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "type" update record for "ClientFile" entities. */
export type HistoryClientFileType = BaseHistory & {
  __typename?: 'HistoryClientFileType';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<ClientFileType>;
  /** Old value. */
  old?: Maybe<ClientFileType>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "value" update record for "ClientFile" entities. */
export type HistoryClientFileValue = BaseHistory & {
  __typename?: 'HistoryClientFileValue';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "firstName" update record for "Client" entities. */
export type HistoryClientFirstName = BaseHistory & {
  __typename?: 'HistoryClientFirstName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "gender" update record for "Client" entities. */
export type HistoryClientGender = BaseHistory & {
  __typename?: 'HistoryClientGender';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Gender>;
  /** Old value. */
  old?: Maybe<Gender>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "lastName" update record for "Client" entities. */
export type HistoryClientLastName = BaseHistory & {
  __typename?: 'HistoryClientLastName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "locality" update record for "Client" entities. */
export type HistoryClientLocality = BaseHistory & {
  __typename?: 'HistoryClientLocality';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "middleName" update record for "Client" entities. */
export type HistoryClientMiddleName = BaseHistory & {
  __typename?: 'HistoryClientMiddleName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "color" update record for "Client" entities. */
export type HistoryClientNdisId = BaseHistory & {
  __typename?: 'HistoryClientNdisId';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['NdisId']>;
  /** Old value. */
  old?: Maybe<Scalars['NdisId']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "coreSupportItem" update record for "ClientNdisPlan" entities. */
export type HistoryClientNdisPlanCoreSupportItem = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanCoreSupportItem';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlan: ClientNdisPlan;
  /** New value. */
  clientNdisPlanCoreSupportItemNew?: Maybe<ClientNdisPlanItem>;
  /** Old value. */
  clientNdisPlanCoreSupportItemOld?: Maybe<ClientNdisPlanItem>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "endAt" update record for "ClientNdisPlan" entities. */
export type HistoryClientNdisPlanEndAt = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanEndAt';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlan: ClientNdisPlan;
  /** New value. */
  clientNdisPlanEndAtNew?: Maybe<Scalars['Date']>;
  /** Old value. */
  clientNdisPlanEndAtOld?: Maybe<Scalars['Date']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanEndDate" update record for "Client" entities. */
export type HistoryClientNdisPlanEndDate = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanEndDate';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanInitialFundsCb" update record for "Client" entities. */
export type HistoryClientNdisPlanInitialFundsCb = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanInitialFundsCb';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanInitialFundsCore" update record for "Client" entities. */
export type HistoryClientNdisPlanInitialFundsCore = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanInitialFundsCore';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanInitialFundsCs" update record for "Client" entities. */
export type HistoryClientNdisPlanInitialFundsCs = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanInitialFundsCs';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "category" update record for "ClientNdisPlanItem" entities. */
export type HistoryClientNdisPlanItemCategory = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemCategory';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItem: ClientNdisPlanItem;
  /** New value. */
  clientNdisPlanItemCategoryNew?: Maybe<ClientNdisPlanItemCategory>;
  /** Old value. */
  clientNdisPlanItemCategoryOld?: Maybe<ClientNdisPlanItemCategory>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "initialFunds" update record for "ClientNdisPlanItem" entities. */
export type HistoryClientNdisPlanItemInitialFunds = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemInitialFunds';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItem: ClientNdisPlanItem;
  /** New value. */
  clientNdisPlanItemInitialFundsNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  clientNdisPlanItemInitialFundsOld?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "managedBy" update record for "ClientNdisPlanItem" entities. */
export type HistoryClientNdisPlanItemManagedBy = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemManagedBy';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItem: ClientNdisPlanItem;
  /** New value. */
  clientNdisPlanItemManagedByNew?: Maybe<ClientNdisPlanItemManagedBy>;
  /** Old value. */
  clientNdisPlanItemManagedByOld?: Maybe<ClientNdisPlanItemManagedBy>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "name" update record for "ClientNdisPlanItem" entities. */
export type HistoryClientNdisPlanItemName = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemName';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItem: ClientNdisPlanItem;
  /** New value. */
  clientNdisPlanItemNameNew?: Maybe<ClientNdisPlanItemName>;
  /** Old value. */
  clientNdisPlanItemNameOld?: Maybe<ClientNdisPlanItemName>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "billingDate" update record for "ClientNdisPlanItemTransaction" entities. */
export type HistoryClientNdisPlanItemTransactionBillingDate = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemTransactionBillingDate';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItemTransaction: ClientNdisPlanItemTransaction;
  /** New value. */
  clientNdisPlanItemTransactionBillingDateNew?: Maybe<Scalars['Date']>;
  /** Old value. */
  clientNdisPlanItemTransactionBillingDateOld?: Maybe<Scalars['Date']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "invoiceNumber" update record for "ClientNdisPlanItemTransaction" entities. */
export type HistoryClientNdisPlanItemTransactionInvoiceNumber = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemTransactionInvoiceNumber';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItemTransaction: ClientNdisPlanItemTransaction;
  /** New value. */
  clientNdisPlanItemTransactionInvoiceNumberNew?: Maybe<Scalars['String']>;
  /** Old value. */
  clientNdisPlanItemTransactionInvoiceNumberOld?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "memo" update record for "ClientNdisPlanItemTransaction" entities. */
export type HistoryClientNdisPlanItemTransactionMemo = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemTransactionMemo';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItemTransaction: ClientNdisPlanItemTransaction;
  /** New value. */
  clientNdisPlanItemTransactionMemoNew?: Maybe<Scalars['String']>;
  /** Old value. */
  clientNdisPlanItemTransactionMemoOld?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "serviceDateEnd" update record for "ClientNdisPlanItemTransaction" entities. */
export type HistoryClientNdisPlanItemTransactionServiceDateEnd = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateEnd';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItemTransaction: ClientNdisPlanItemTransaction;
  /** New value. */
  clientNdisPlanItemTransactionServiceDateEndNew?: Maybe<Scalars['Date']>;
  /** Old value. */
  clientNdisPlanItemTransactionServiceDateEndOld?: Maybe<Scalars['Date']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "serviceDateStart" update record for "ClientNdisPlanItemTransaction" entities. */
export type HistoryClientNdisPlanItemTransactionServiceDateStart =
  BaseHistory & {
    __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateStart';
    actor?: Maybe<Member>;
    /** Entity to which the historical record belongs. */
    clientNdisPlanItemTransaction: ClientNdisPlanItemTransaction;
    /** New value. */
    clientNdisPlanItemTransactionServiceDateStartNew?: Maybe<Scalars['Date']>;
    /** Old value. */
    clientNdisPlanItemTransactionServiceDateStartOld?: Maybe<Scalars['Date']>;
    date: Scalars['DateTime'];
    id: Scalars['ID'];
    owner: Org;
    /** Type of this historical record (archive, delete, update etc.) */
    type: HistoryType;
  };

/** Historical "supplier" update record for "ClientNdisPlanItemTransaction" entities. */
export type HistoryClientNdisPlanItemTransactionSupplier = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemTransactionSupplier';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItemTransaction: ClientNdisPlanItemTransaction;
  /** New value. */
  clientNdisPlanItemTransactionSupplierNew?: Maybe<ClientNdisPlanItemTransactionSupplier>;
  /** Old value. */
  clientNdisPlanItemTransactionSupplierOld?: Maybe<ClientNdisPlanItemTransactionSupplier>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "name" update record for "ClientNdisPlanItemTransactionSupplier" entities. */
export type HistoryClientNdisPlanItemTransactionSupplierName = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemTransactionSupplierName';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItemTransactionSupplier: ClientNdisPlanItemTransactionSupplier;
  /** New value. */
  clientNdisPlanItemTransactionSupplierNameNew?: Maybe<Scalars['String']>;
  /** Old value. */
  clientNdisPlanItemTransactionSupplierNameOld?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "value" update record for "ClientNdisPlanItemTransaction" entities. */
export type HistoryClientNdisPlanItemTransactionValue = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanItemTransactionValue';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlanItemTransaction: ClientNdisPlanItemTransaction;
  /** New value. */
  clientNdisPlanItemTransactionValueNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  clientNdisPlanItemTransactionValueOld?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "startAt" update record for "ClientNdisPlan" entities. */
export type HistoryClientNdisPlanStartAt = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanStartAt';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlan: ClientNdisPlan;
  /** New value. */
  clientNdisPlanStartAtNew?: Maybe<Scalars['Date']>;
  /** Old value. */
  clientNdisPlanStartAtOld?: Maybe<Scalars['Date']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "ndisPlanStartDate" update record for "Client" entities. */
export type HistoryClientNdisPlanStartDate = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanStartDate';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "supportCoordinationItem" update record for "ClientNdisPlan" entities. */
export type HistoryClientNdisPlanSupportCoordinationItem = BaseHistory & {
  __typename?: 'HistoryClientNdisPlanSupportCoordinationItem';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNdisPlan: ClientNdisPlan;
  /** New value. */
  clientNdisPlanSupportCoordinationItemNew?: Maybe<ClientNdisPlanItem>;
  /** Old value. */
  clientNdisPlanSupportCoordinationItemOld?: Maybe<ClientNdisPlanItem>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "comment" update record for "ClientNote" entities. */
export type HistoryClientNoteComment = BaseHistory & {
  __typename?: 'HistoryClientNoteComment';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNote: ClientNote;
  /** New value. */
  clientNoteCommentNew?: Maybe<Scalars['String']>;
  /** Old value. */
  clientNoteCommentOld?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "commentedAt" update record for "ClientNote" entities. */
export type HistoryClientNoteCommentedAt = BaseHistory & {
  __typename?: 'HistoryClientNoteCommentedAt';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNote: ClientNote;
  /** New value. */
  clientNoteCommentedAtNew?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  clientNoteCommentedAtOld?: Maybe<Scalars['DateTime']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "commentedBy" update record for "ClientNote" entities. */
export type HistoryClientNoteCommentedBy = BaseHistory & {
  __typename?: 'HistoryClientNoteCommentedBy';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNote: ClientNote;
  /** New value. */
  clientNoteCommentedByNew?: Maybe<Member>;
  /** Old value. */
  clientNoteCommentedByOld?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "commentedByText" update record for "ClientNote" entities. */
export type HistoryClientNoteCommentedByText = BaseHistory & {
  __typename?: 'HistoryClientNoteCommentedByText';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNote: ClientNote;
  /** New value. */
  clientNoteCommentedByTextNew?: Maybe<Scalars['String']>;
  /** Old value. */
  clientNoteCommentedByTextOld?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "text" update record for "ClientNoteKeyword" entities. */
export type HistoryClientNoteKeywordText = BaseHistory & {
  __typename?: 'HistoryClientNoteKeywordText';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNoteKeyword: ClientNoteKeyword;
  /** New value. */
  clientNoteKeywordTextNew?: Maybe<Scalars['String']>;
  /** Old value. */
  clientNoteKeywordTextOld?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "type" update record for "ClientNote" entities. */
export type HistoryClientNoteType = BaseHistory & {
  __typename?: 'HistoryClientNoteType';
  actor?: Maybe<Member>;
  /** Entity to which the historical record belongs. */
  clientNote: ClientNote;
  /** New value. */
  clientNoteTypeNew?: Maybe<ClientNoteType>;
  /** Old value. */
  clientNoteTypeOld?: Maybe<ClientNoteType>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "Client" entities. */
export type HistoryClientNotes = BaseHistory & {
  __typename?: 'HistoryClientNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "bloodDiastolic" update record for "ClientObservation" entities. */
export type HistoryClientObservationBloodDiastolic = BaseHistory & {
  __typename?: 'HistoryClientObservationBloodDiastolic';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "bloodSystolic" update record for "ClientObservation" entities. */
export type HistoryClientObservationBloodSystolic = BaseHistory & {
  __typename?: 'HistoryClientObservationBloodSystolic';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "date" update record for "ClientObservation" entities. */
export type HistoryClientObservationDate = BaseHistory & {
  __typename?: 'HistoryClientObservationDate';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  old?: Maybe<Scalars['DateTime']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "heart" update record for "ClientObservation" entities. */
export type HistoryClientObservationHeart = BaseHistory & {
  __typename?: 'HistoryClientObservationHeart';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "member" update record for "ClientObservation" entities. */
export type HistoryClientObservationMember = BaseHistory & {
  __typename?: 'HistoryClientObservationMember';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Member>;
  /** Old value. */
  old?: Maybe<Member>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "ClientObservation" entities. */
export type HistoryClientObservationNotes = BaseHistory & {
  __typename?: 'HistoryClientObservationNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "nursesName" update record for "ClientObservation" entities. */
export type HistoryClientObservationNursesName = BaseHistory & {
  __typename?: 'HistoryClientObservationNursesName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "respiration" update record for "ClientObservation" entities. */
export type HistoryClientObservationRespiration = BaseHistory & {
  __typename?: 'HistoryClientObservationRespiration';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "spo2" update record for "ClientObservation" entities. */
export type HistoryClientObservationSpo2 = BaseHistory & {
  __typename?: 'HistoryClientObservationSpo2';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "stoolType" update record for "ClientObservation" entities. */
export type HistoryClientObservationStoolType = BaseHistory & {
  __typename?: 'HistoryClientObservationStoolType';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<StoolType>;
  /** Old value. */
  old?: Maybe<StoolType>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "temp" update record for "ClientObservation" entities. */
export type HistoryClientObservationTemp = BaseHistory & {
  __typename?: 'HistoryClientObservationTemp';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Float']>;
  /** Old value. */
  old?: Maybe<Scalars['Float']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "Client" entities. */
export type HistoryClientPhone = BaseHistory & {
  __typename?: 'HistoryClientPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "planManager" update record for "Client" entities. */
export type HistoryClientPlanManager = BaseHistory & {
  __typename?: 'HistoryClientPlanManager';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<PlanManager>;
  /** Old value. */
  old?: Maybe<PlanManager>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "postcode" update record for "Client" entities. */
export type HistoryClientPostcode = BaseHistory & {
  __typename?: 'HistoryClientPostcode';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "preferredName" update record for "Client" entities. */
export type HistoryClientPreferredName = BaseHistory & {
  __typename?: 'HistoryClientPreferredName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "pronouns" update record for "Client" entities. */
export type HistoryClientPronouns = BaseHistory & {
  __typename?: 'HistoryClientPronouns';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Pronouns>;
  /** Old value. */
  old?: Maybe<Pronouns>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "region" update record for "Client" entities. */
export type HistoryClientRegion = BaseHistory & {
  __typename?: 'HistoryClientRegion';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<AddressRegionCode>;
  /** Old value. */
  old?: Maybe<AddressRegionCode>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "salutation" update record for "Client" entities. */
export type HistoryClientSalutation = BaseHistory & {
  __typename?: 'HistoryClientSalutation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Salutation>;
  /** Old value. */
  old?: Maybe<Salutation>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "street" update record for "Client" entities. */
export type HistoryClientStreet = BaseHistory & {
  __typename?: 'HistoryClientStreet';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "supportCoordinator" update record for "Client" entities. */
export type HistoryClientSupportCoordinator = BaseHistory & {
  __typename?: 'HistoryClientSupportCoordinator';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<SupportCoordinator>;
  /** Old value. */
  old?: Maybe<SupportCoordinator>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "unit" update record for "Client" entities. */
export type HistoryClientUnit = BaseHistory & {
  __typename?: 'HistoryClientUnit';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "activeAssist" update record for "Event" entities. */
export type HistoryEventActiveAssist = BaseHistory & {
  __typename?: 'HistoryEventActiveAssist';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventActiveAssistNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  eventActiveAssistOld?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "billable" update record for "Event" entities. */
export type HistoryEventBillable = BaseHistory & {
  __typename?: 'HistoryEventBillable';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** Old value. */
  eventBillableOld?: Maybe<Scalars['Boolean']>;
  /** New value. */
  eventBillablenew?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "billingRegistrationGroup" update record for "Event" entities. */
export type HistoryEventBillingRegistrationGroup = BaseHistory & {
  __typename?: 'HistoryEventBillingRegistrationGroup';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventBillingRegistrationGroupNew?: Maybe<EventBillingRegistrationGroup>;
  /** Old value. */
  eventBillingRegistrationGroupOld?: Maybe<EventBillingRegistrationGroup>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "client" update record for "Event" entities. */
export type HistoryEventClient = BaseHistory & {
  __typename?: 'HistoryEventClient';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventClientNew?: Maybe<Client>;
  /** Old value. */
  eventClientOld?: Maybe<Client>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "clockedOffAt" update record for "Event" entities. */
export type HistoryEventClockedOffAt = BaseHistory & {
  __typename?: 'HistoryEventClockedOffAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventClockedOffAtNew?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  eventClockedOffAtOld?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "clockedOnAt" update record for "Event" entities. */
export type HistoryEventClockedOnAt = BaseHistory & {
  __typename?: 'HistoryEventClockedOnAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventClockedOnAtNew?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  eventClockedOnAtOld?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "country" update record for "Event" entities. */
export type HistoryEventCountry = BaseHistory & {
  __typename?: 'HistoryEventCountry';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventCountryNew?: Maybe<AddressCountryCode>;
  /** Old value. */
  eventCountryOld?: Maybe<AddressCountryCode>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "duration" update record for "Event" entities. */
export type HistoryEventDuration = BaseHistory & {
  __typename?: 'HistoryEventDuration';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventDurationNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  eventDurationOld?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "locality" update record for "Event" entities. */
export type HistoryEventLocality = BaseHistory & {
  __typename?: 'HistoryEventLocality';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventLocalityNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventLocalityOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "member" update record for "Event" entities. */
export type HistoryEventMember = BaseHistory & {
  __typename?: 'HistoryEventMember';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventMemberNew?: Maybe<Member>;
  /** Old value. */
  eventMemberOld?: Maybe<Member>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "Event" entities. */
export type HistoryEventNotes = BaseHistory & {
  __typename?: 'HistoryEventNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventNotesNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventNotesOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "passive" update record for "Event" entities. */
export type HistoryEventPassive = BaseHistory & {
  __typename?: 'HistoryEventPassive';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPassiveNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  eventPassiveOld?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "passiveStartAt" update record for "Event" entities. */
export type HistoryEventPassiveStartAt = BaseHistory & {
  __typename?: 'HistoryEventPassiveStartAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPassiveStartAtNew?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  eventPassiveStartAtOld?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "payable" update record for "Event" entities. */
export type HistoryEventPayable = BaseHistory & {
  __typename?: 'HistoryEventPayable';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPayableNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  eventPayableOld?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "postcode" update record for "Event" entities. */
export type HistoryEventPostcode = BaseHistory & {
  __typename?: 'HistoryEventPostcode';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPostcodeNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventPostcodeOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "publicHoliday" update record for "Event" entities. */
export type HistoryEventPublicHoliday = BaseHistory & {
  __typename?: 'HistoryEventPublicHoliday';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventPublicHolidayNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  eventPublicHolidayOld?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "region" update record for "Event" entities. */
export type HistoryEventRegion = BaseHistory & {
  __typename?: 'HistoryEventRegion';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventRegionNew?: Maybe<AddressRegionCode>;
  /** Old value. */
  eventRegionOld?: Maybe<AddressRegionCode>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "requireCar" update record for "Event" entities. */
export type HistoryEventRequireCar = BaseHistory & {
  __typename?: 'HistoryEventRequireCar';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventRequireCarNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  eventRequireCarOld?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "scheduleNotes" update record for "Event" entities. */
export type HistoryEventScheduleNotes = BaseHistory & {
  __typename?: 'HistoryEventScheduleNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventScheduleNotesNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventSheduleNotesOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "startAt" update record for "Event" entities. */
export type HistoryEventStartAt = BaseHistory & {
  __typename?: 'HistoryEventStartAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventStartAtNew?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  eventStartAtOld?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "street" update record for "Event" entities. */
export type HistoryEventStreet = BaseHistory & {
  __typename?: 'HistoryEventStreet';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventStreetNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventStreetOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "summary" update record for "Event" entities. */
export type HistoryEventSummary = BaseHistory & {
  __typename?: 'HistoryEventSummary';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventSummaryNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventSummaryOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "travelDistance" update record for "Event" entities. */
export type HistoryEventTravelDistance = BaseHistory & {
  __typename?: 'HistoryEventTravelDistance';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventTravelDistanceNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  eventTravelDistanceOld?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "travelDistanceNotes" update record for "Event" entities. */
export type HistoryEventTravelDistanceNotes = BaseHistory & {
  __typename?: 'HistoryEventTravelDistanceNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventTravelDistanceNotesNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventTravelDistanceNotesOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "travelTime" update record for "Event" entities. */
export type HistoryEventTravelTime = BaseHistory & {
  __typename?: 'HistoryEventTravelTime';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventTravelTimeNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  eventTravelTimeOld?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "travelTimeNotes" update record for "Event" entities. */
export type HistoryEventTravelTimeNotes = BaseHistory & {
  __typename?: 'HistoryEventTravelTimeNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventTravelTimeNotesNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventTravelTimeNotesOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "unit" update record for "Event" entities. */
export type HistoryEventUnit = BaseHistory & {
  __typename?: 'HistoryEventUnit';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  /** Entity to which the historical record belongs. */
  event: Event;
  /** New value. */
  eventUnitNew?: Maybe<Scalars['String']>;
  /** Old value. */
  eventUnitOld?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "admin" update record for "Member" entities. */
export type HistoryMemberAdmin = BaseHistory & {
  __typename?: 'HistoryMemberAdmin';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Entity to which the historical record belongs. */
  member: Member;
  /** New value. */
  memberAdminNew?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  memberAdminOld?: Maybe<Scalars['Boolean']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "date" update record for "MemberBasePayRate" entities. */
export type HistoryMemberBasePayRateDate = BaseHistory & {
  __typename?: 'HistoryMemberBasePayRateDate';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "type" update record for "MemberBasePayRate" entities. */
export type HistoryMemberBasePayRateType = BaseHistory & {
  __typename?: 'HistoryMemberBasePayRateType';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<MemberBasePayRateType>;
  /** Old value. */
  old?: Maybe<MemberBasePayRateType>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "value" update record for "MemberBasePayRate" entities. */
export type HistoryMemberBasePayRateValue = BaseHistory & {
  __typename?: 'HistoryMemberBasePayRateValue';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Int']>;
  /** Old value. */
  old?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "color" update record for "Member" entities. */
export type HistoryMemberColor = BaseHistory & {
  __typename?: 'HistoryMemberColor';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['HexColorCode']>;
  /** Old value. */
  old?: Maybe<Scalars['HexColorCode']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "confidentialNotes" update record for "Member" entities. */
export type HistoryMemberConfidentialNotes = BaseHistory & {
  __typename?: 'HistoryMemberConfidentialNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "country" update record for "Member" entities. */
export type HistoryMemberCountry = BaseHistory & {
  __typename?: 'HistoryMemberCountry';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<AddressCountryCode>;
  /** Old value. */
  old?: Maybe<AddressCountryCode>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "dob" update record for "Member" entities. */
export type HistoryMemberDob = BaseHistory & {
  __typename?: 'HistoryMemberDob';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "email" update record for "Member" entities. */
export type HistoryMemberEmail = BaseHistory & {
  __typename?: 'HistoryMemberEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['EmailAddress']>;
  /** Old value. */
  old?: Maybe<Scalars['EmailAddress']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "empathyRating" update record for "Member" entities. */
export type HistoryMemberEmpathyRating = BaseHistory & {
  __typename?: 'HistoryMemberEmpathyRating';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Entity to which the historical record belongs. */
  member: Member;
  /** New value. */
  memberEmpathyRatingNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  memberEmpathyRatingOld?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "externalId" update record for "Member" entities. */
export type HistoryMemberExternalId = BaseHistory & {
  __typename?: 'HistoryMemberExternalId';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "dateOfFile" update record for "MemberFile" entities. */
export type HistoryMemberFileDateOfFile = BaseHistory & {
  __typename?: 'HistoryMemberFileDateOfFile';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Date']>;
  /** Old value. */
  old?: Maybe<Scalars['Date']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "expiresAt" update record for "MemberFile" entities. */
export type HistoryMemberFileExpiresAt = BaseHistory & {
  __typename?: 'HistoryMemberFileExpiresAt';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['DateTime']>;
  /** Old value. */
  old?: Maybe<Scalars['DateTime']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "owner" update record for "MemberFile" entities. */
export type HistoryMemberFileOwner = BaseHistory & {
  __typename?: 'HistoryMemberFileOwner';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Member>;
  /** Old value. */
  old?: Maybe<Member>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "type" update record for "MemberFile" entities. */
export type HistoryMemberFileType = BaseHistory & {
  __typename?: 'HistoryMemberFileType';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<MemberFileType>;
  /** Old value. */
  old?: Maybe<MemberFileType>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "value" update record for "MemberFile" entities. */
export type HistoryMemberFileValue = BaseHistory & {
  __typename?: 'HistoryMemberFileValue';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "firstName" update record for "Member" entities. */
export type HistoryMemberFirstName = BaseHistory & {
  __typename?: 'HistoryMemberFirstName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "gender" update record for "Member" entities. */
export type HistoryMemberGender = BaseHistory & {
  __typename?: 'HistoryMemberGender';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Gender>;
  /** Old value. */
  old?: Maybe<Gender>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "lastName" update record for "Member" entities. */
export type HistoryMemberLastName = BaseHistory & {
  __typename?: 'HistoryMemberLastName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "locality" update record for "Member" entities. */
export type HistoryMemberLocality = BaseHistory & {
  __typename?: 'HistoryMemberLocality';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "middleName" update record for "Member" entities. */
export type HistoryMemberMiddleName = BaseHistory & {
  __typename?: 'HistoryMemberMiddleName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "Member" entities. */
export type HistoryMemberNotes = BaseHistory & {
  __typename?: 'HistoryMemberNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "Member" entities. */
export type HistoryMemberPhone = BaseHistory & {
  __typename?: 'HistoryMemberPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "postcode" update record for "Member" entities. */
export type HistoryMemberPostcode = BaseHistory & {
  __typename?: 'HistoryMemberPostcode';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "preferredName" update record for "Member" entities. */
export type HistoryMemberPreferredName = BaseHistory & {
  __typename?: 'HistoryMemberPreferredName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "pronouns" update record for "Member" entities. */
export type HistoryMemberPronouns = BaseHistory & {
  __typename?: 'HistoryMemberPronouns';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Pronouns>;
  /** Old value. */
  old?: Maybe<Pronouns>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "region" update record for "Member" entities. */
export type HistoryMemberRegion = BaseHistory & {
  __typename?: 'HistoryMemberRegion';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<AddressRegionCode>;
  /** Old value. */
  old?: Maybe<AddressRegionCode>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "reliabilityRating" update record for "Member" entities. */
export type HistoryMemberReliabilityRating = BaseHistory & {
  __typename?: 'HistoryMemberReliabilityRating';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Entity to which the historical record belongs. */
  member: Member;
  /** New value. */
  memberReliabilityRatingNew?: Maybe<Scalars['Int']>;
  /** Old value. */
  memberReliabilityRatingOld?: Maybe<Scalars['Int']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "salutation" update record for "Member" entities. */
export type HistoryMemberSalutation = BaseHistory & {
  __typename?: 'HistoryMemberSalutation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Salutation>;
  /** Old value. */
  old?: Maybe<Salutation>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "schedulable" update record for "Member" entities. */
export type HistoryMemberSchedulable = BaseHistory & {
  __typename?: 'HistoryMemberSchedulable';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['Boolean']>;
  /** Old value. */
  old?: Maybe<Scalars['Boolean']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "street" update record for "Member" entities. */
export type HistoryMemberStreet = BaseHistory & {
  __typename?: 'HistoryMemberStreet';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "unit" update record for "Member" entities. */
export type HistoryMemberUnit = BaseHistory & {
  __typename?: 'HistoryMemberUnit';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "abn" update record for "Org" entities. */
export type HistoryOrgAbn = BaseHistory & {
  __typename?: 'HistoryOrgAbn';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "email" update record for "Org" entities. */
export type HistoryOrgEmail = BaseHistory & {
  __typename?: 'HistoryOrgEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "legalName" update record for "Org" entities. */
export type HistoryOrgLegalName = BaseHistory & {
  __typename?: 'HistoryOrgLegalName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "Org" entities. */
export type HistoryOrgPhone = BaseHistory & {
  __typename?: 'HistoryOrgPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "preferredName" update record for "Org" entities. */
export type HistoryOrgPreferredName = BaseHistory & {
  __typename?: 'HistoryOrgPreferredName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "website" update record for "Org" entities. */
export type HistoryOrgWebsite = BaseHistory & {
  __typename?: 'HistoryOrgWebsite';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "abn" update record for "PlanManager" entities. */
export type HistoryPlanManagerAbn = BaseHistory & {
  __typename?: 'HistoryPlanManagerAbn';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "billingEmail" update record for "PlanManager" entities. */
export type HistoryPlanManagerBillingEmail = BaseHistory & {
  __typename?: 'HistoryPlanManagerBillingEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "businessName" update record for "PlanManager" entities. */
export type HistoryPlanManagerBusinessName = BaseHistory & {
  __typename?: 'HistoryPlanManagerBusinessName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "contactEmail" update record for "PlanManager" entities. */
export type HistoryPlanManagerContactEmail = BaseHistory & {
  __typename?: 'HistoryPlanManagerContactEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "name" update record for "PlanManager" entities. */
export type HistoryPlanManagerName = BaseHistory & {
  __typename?: 'HistoryPlanManagerName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "PlanManager" entities. */
export type HistoryPlanManagerNotes = BaseHistory & {
  __typename?: 'HistoryPlanManagerNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "PlanManager" entities. */
export type HistoryPlanManagerPhone = BaseHistory & {
  __typename?: 'HistoryPlanManagerPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "salutation" update record for "PlanManager" entities. */
export type HistoryPlanManagerSalutation = BaseHistory & {
  __typename?: 'HistoryPlanManagerSalutation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

export type HistoryRestorable = BaseHistory & {
  __typename?: 'HistoryRestorable';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Org;
  reason?: Maybe<Scalars['String']>;
  restoredAt?: Maybe<Scalars['DateTime']>;
  restoredBy?: Maybe<Member>;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "abn" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorAbn = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorAbn';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "businessEmail" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorBusinessEmail = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorBusinessEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "businessName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorBusinessName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorBusinessName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "businessPhone" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorBusinessPhone = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorBusinessPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "email" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorEmail = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorEmail';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "firstName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorFirstName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorFirstName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "lastName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorLastName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorLastName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "middleName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorMiddleName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorMiddleName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "notes" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorNotes = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorNotes';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "phone" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorPhone = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorPhone';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "preferredName" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorPreferredName = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorPreferredName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "salutation" update record for "SupportCoordinator" entities. */
export type HistorySupportCoordinatorSalutation = BaseHistory & {
  __typename?: 'HistorySupportCoordinatorSalutation';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

export enum HistoryType {
  ARCHIVE = 'ARCHIVE',
  CANCELLATION = 'CANCELLATION',
  DELETION = 'DELETION',
  UPDATE = 'UPDATE',
}

/** Historical "description" update record for "VisaType" entities. */
export type HistoryVisaTypeDescription = BaseHistory & {
  __typename?: 'HistoryVisaTypeDescription';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** Historical "name" update record for "VisaType" entities. */
export type HistoryVisaTypeName = BaseHistory & {
  __typename?: 'HistoryVisaTypeName';
  actor?: Maybe<Member>;
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  /** New value. */
  new?: Maybe<Scalars['String']>;
  /** Old value. */
  old?: Maybe<Scalars['String']>;
  owner: Org;
  /** Type of this historical record (archive, delete, update etc.) */
  type: HistoryType;
};

/** ID conditional expression input. */
export type IdConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['ID']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['ID']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['ID']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['ID']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['ID']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['ID']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['ID']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['ID']>>;
};

/** Incontinence type. */
export enum IncontinenceType {
  Both = 'Both',
  Bowel = 'Bowel',
  Nil = 'Nil',
  NotRecorded = 'NotRecorded',
  Urine = 'Urine',
}

/** Int conditional expression input. */
export type IntConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['Int']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['Int']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['Int']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['Int']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['Int']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['Int']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LinkMemberInput = {
  /** Invitation token. */
  token: Scalars['UUID'];
};

/** User account login input data */
export type LoginUserInput = {
  /** A unique identifier. Either an email address or phone number. */
  identifier: Scalars['UserIdentifier'];
  /** Module type. */
  module: ModuleType;
  /** Passwords must be at least 8 characters in length and contain a combination of lowercase, uppercase and special characters */
  password: Scalars['UserPlainTextPassword'];
  /** Keep session active for an extended period of time */
  rememberMe?: InputMaybe<Scalars['Boolean']>;
};

/** Filtering conditions for "MemberBranchAllocation" entities. */
export type MeMemberBranchAllocationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MeMemberBranchAllocationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MeMemberBranchAllocationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MeMemberBranchAllocationsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Effective-from date. */
  date?: InputMaybe<DateConditionalInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Deletor of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked member. */
  member?: InputMaybe<MembersWhereInput>;
  /** Values. */
  values?: InputMaybe<IntConditionalInput>;
};

/** An medication linked to an organisation. */
export type Medication = {
  __typename?: 'Medication';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name of medication. */
  name: Scalars['String'];
  /** Linked organisation. */
  org: Org;
  /** Pronunciation of medication. */
  pronunciation?: Maybe<Scalars['String']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** URL. */
  url?: Maybe<Scalars['String']>;
};

/** Aggregated response object for "Medication" entities. */
export type MedicationsAggregate = {
  __typename?: 'MedicationsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Medication>;
};

/** Ordering options for "Medication" entities. */
export type MedicationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name of medication. */
  name?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Medication" entities. */
export type MedicationsUpdatableFieldsInput = {
  /** Name of medication. */
  name?: InputMaybe<Scalars['String']>;
  /** Pronunciation of medication. */
  pronunciation?: InputMaybe<Scalars['String']>;
  /** URL. */
  url?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "Medication" entities. */
export type MedicationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MedicationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MedicationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MedicationsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked organisation. */
  org?: InputMaybe<OrgsWhereInput>;
};

/** A member profile linking a user with an organisation. */
export type Member = Address &
  Person & {
    __typename?: 'Member';
    /** Admin status. */
    admin: Scalars['Boolean'];
    /** Age in years. */
    age?: Maybe<Scalars['Int']>;
    /** Allowed worked hours per fortnight. */
    allowedFortnightlyWorkHours?: Maybe<Scalars['Int']>;
    /** Archive data, if entity is archived. */
    archive?: Maybe<HistoryRestorable>;
    /** Member is an Australian Citizen. */
    australianCitizen?: Maybe<Scalars['Boolean']>;
    avatar?: Maybe<File>;
    /** Bank details. */
    bank?: Maybe<MemberBank>;
    /** Resolver for "basePayRates" field. */
    basePayRates: Array<MemberBasePayRate>;
    /** Biography. */
    bio?: Maybe<Scalars['String']>;
    /** Resolver for "bonusEligible" field. */
    bonusEligible: Scalars['Boolean'];
    /** Resolver for "bonusEligibleStartAt" field. */
    bonusEligibleStartAt?: Maybe<Scalars['DateTime']>;
    branches: Array<Branch>;
    /** Building name. */
    buildingName?: Maybe<Scalars['String']>;
    /** Member can use a hoist. */
    canHoist?: Maybe<Scalars['Boolean']>;
    /** Resolver for "canPromptMedication" field. */
    canPromptMedication: Scalars['Boolean'];
    /** Description of car. */
    carDescription?: Maybe<Scalars['String']>;
    /** Resolver for "claims" field. */
    claims: Array<EventClaim>;
    /** Hexidecimal color. */
    color?: Maybe<Scalars['HexColorCode']>;
    /** Confidential notes. */
    confidentialNotes?: Maybe<Scalars['String']>;
    /** Address country. */
    country?: Maybe<AddressCountryCode>;
    /** Datetime of the entity's creation. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Creator of the entity. */
    createdBy?: Maybe<Member>;
    /** Resolver for "currentAnnualPayRate" field. */
    currentAnnualPayRate: Scalars['Int'];
    currentEvent?: Maybe<Event>;
    /** Resolver for "currentHourlyBasePayRate" field. */
    currentHourlyBasePayRate: Scalars['Int'];
    /** Resolver for "currentPostNominal" field. */
    currentPostNominal?: Maybe<Scalars['String']>;
    /** Member's default branch. */
    defaultBranch?: Maybe<Branch>;
    /** Deletion data, if entity is deleted. */
    delete?: Maybe<HistoryRestorable>;
    /** Date of birth. */
    dob?: Maybe<Scalars['Date']>;
    /** Drivers license. */
    driversLicense?: Maybe<DriversLicense>;
    /** Drivers license country of issue. */
    driversLicenseCountry?: Maybe<AddressCountryCode>;
    /** Drivers license expiry date. */
    driversLicenseExpiryDate?: Maybe<Scalars['Date']>;
    /** Email address. */
    email?: Maybe<Scalars['String']>;
    /** Empathy rating. */
    empathyRating?: Maybe<Scalars['Int']>;
    /** Employment contract externally saved? */
    employementContractExternallySaved?: Maybe<Scalars['Int']>;
    /** Employment end date */
    employmentEndDate?: Maybe<Scalars['Date']>;
    /** Employment start date */
    employmentStartDate?: Maybe<Scalars['Date']>;
    /** Resolver for "events" field. */
    events: Array<Event>;
    /** External reference ID. */
    externalId?: Maybe<Scalars['String']>;
    /** Resolver for "files" field. */
    files: Array<MemberFile>;
    firstBillableEvent?: Maybe<Event>;
    /** First name. */
    firstName: Scalars['String'];
    /** Gender. */
    gender?: Maybe<Gender>;
    /** Members ability to access their organisation. */
    hasAccess?: Maybe<Scalars['Boolean']>;
    /** Member has a car. */
    hasCar?: Maybe<Scalars['Boolean']>;
    /** Resolver for "hasCertEffectiveCommunication" field. */
    hasCertEffectiveCommunication: Scalars['Boolean'];
    /** Resolver for "hasCertManualHandling" field. */
    hasCertManualHandling: Scalars['Boolean'];
    /** Resolver for "hasCertPreventingDementia" field. */
    hasCertPreventingDementia: Scalars['Boolean'];
    /** Resolver for "hasCertSeizureRecognition" field. */
    hasCertSeizureRecognition: Scalars['Boolean'];
    /** Resolver for "hasCertSupportingSafeMeals" field. */
    hasCertSupportingSafeMeals: Scalars['Boolean'];
    /** Resolver for "hasCertUnderstandingDementia" field. */
    hasCertUnderstandingDementia: Scalars['Boolean'];
    /** Member has a drivers license. */
    hasDriversLicense?: Maybe<Scalars['Boolean']>;
    /** Resolver for "hours" field. */
    hours: Array<MemberHour>;
    /** Unique identifier of the entity. */
    id: Scalars['ID'];
    /** Fetches the most recently created invitation. */
    invitationMostRecentlyReceived?: Maybe<MemberInvitation>;
    invitationsReceived?: Maybe<Array<MemberInvitation>>;
    invitationsSent?: Maybe<Array<MemberInvitationSender>>;
    lastActiveAt?: Maybe<Scalars['DateTime']>;
    lastBillableEvent?: Maybe<Event>;
    /** Last name. */
    lastName?: Maybe<Scalars['String']>;
    lateCount: Array<Scalars['Int']>;
    /** Late percentage. */
    lateness?: Maybe<Scalars['Int']>;
    /** Address latitude coordinate. */
    latitude?: Maybe<Scalars['String']>;
    /** Address suburb or town. */
    locality?: Maybe<Scalars['String']>;
    /** Address longitude coordinate. */
    longitude?: Maybe<Scalars['String']>;
    /** Middle name. */
    middleName?: Maybe<Scalars['String']>;
    /** Minutes between first and last billable events. */
    minutesBetweenFirstAndLastBillableEvents?: Maybe<Scalars['Int']>;
    /** Resolver for "missingBankDetails" field. */
    missingBankDetails: Scalars['Boolean'];
    /** Resolver for "missingFiles" field. */
    missingFiles: Array<MemberFileType>;
    /** Module access: Core Support. */
    moduleAccessCS?: Maybe<Scalars['Boolean']>;
    /** Module access: Support Coordination. */
    moduleAccessSC?: Maybe<Scalars['Boolean']>;
    nextEvent?: Maybe<Event>;
    /** Administrator-only notes. */
    notes?: Maybe<Scalars['String']>;
    /** Organisation to which member belongs. */
    org: Org;
    /** Passport country. */
    passportCountry?: Maybe<AddressCountryCode>;
    /** Passport number. */
    passportNumber?: Maybe<Scalars['String']>;
    /** Permissions within the organisation. */
    permissions?: Maybe<Array<Permission>>;
    /** Phone number. */
    phone?: Maybe<Scalars['String']>;
    /** Address postcode. */
    postcode?: Maybe<Scalars['PostalCode']>;
    /** Preferred name. */
    preferredName?: Maybe<Scalars['String']>;
    /** Pronouns. */
    pronouns?: Maybe<Pronouns>;
    /** Address state or territory. */
    region?: Maybe<AddressRegionCode>;
    /** Reliability rating. */
    reliabilityRating?: Maybe<Scalars['Int']>;
    /** Communication preference: Clock on/off reminder. */
    remindClock?: Maybe<Scalars['Boolean']>;
    /** Communication preference: Upcoming events reminder. */
    remindEvents?: Maybe<Scalars['Boolean']>;
    /** Communication preference: Incomplete notes reminder. */
    remindNotes?: Maybe<Scalars['Boolean']>;
    /** Salutation. */
    salutation?: Maybe<Salutation>;
    /** Member-schedulable status. */
    schedulable: Scalars['Boolean'];
    /** Resolver for "schedule" field. */
    schedule: MemberSchedule;
    /** Default maximum schedulable minutes per day. */
    scheduleMaxDayTimeLimitDefault?: Maybe<Scalars['Int']>;
    /** Resolver for "scheduleMaxWeekTimeLimitCurrent" field. */
    scheduleMaxWeekTimeLimitCurrent: Scalars['Int'];
    /** Default maximum schedulable minutes per week. */
    scheduleMaxWeekTimeLimitDefault?: Maybe<Scalars['Int']>;
    /** Resolver for "scheduleTimeLimits" field. */
    scheduleTimeLimits: Array<MemberScheduleTimeLimit>;
    /** Resolver for "schedules" field. */
    schedules: Array<MemberSchedule>;
    /** Shortened name. */
    shortName: Scalars['String'];
    /** Address street number, name and type. */
    street?: Maybe<Scalars['String']>;
    /** Field of study. */
    study?: Maybe<Scalars['String']>;
    /** Superannuation details. */
    super?: Maybe<MemberSuper>;
    /** Super-admin status. */
    superAdmin: Scalars['Boolean'];
    /** Tax file number declaration details. */
    taxFileNumberDeclaration?: Maybe<MemberTaxFileNumberDeclaration>;
    teams?: Maybe<Array<ClientTeam>>;
    /** Timezone. */
    timezone: Timezone;
    /** Accumulated billable minutes. */
    totalBillableMinutes?: Maybe<Scalars['Int']>;
    /** File-tracking status. */
    trackFiles: Scalars['Boolean'];
    /** Member is typically unavailable on Friday daytime. */
    unavailableFrDay: Scalars['Boolean'];
    /** Member is typically unavailable on Friday morning. */
    unavailableFrMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Friday night. */
    unavailableFrNight: Scalars['Boolean'];
    /** Member is typically unavailable on Monday daytime. */
    unavailableMoDay: Scalars['Boolean'];
    /** Member is typically unavailable on Monday morning. */
    unavailableMoMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Monday night. */
    unavailableMoNight: Scalars['Boolean'];
    /** General unavailability notes. */
    unavailableNotes?: Maybe<Scalars['String']>;
    /** Member is typically unavailable on Saturday daytime. */
    unavailableSaDay: Scalars['Boolean'];
    /** Member is typically unavailable on Saturday morning. */
    unavailableSaMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Saturday night. */
    unavailableSaNight: Scalars['Boolean'];
    /** Member is typically unavailable on Saturday daytime. */
    unavailableSuDay: Scalars['Boolean'];
    /** Member is typically unavailable on Saturday morning. */
    unavailableSuMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Sunday night. */
    unavailableSuNight: Scalars['Boolean'];
    /** Member is typically unavailable on Thurday daytime. */
    unavailableThDay: Scalars['Boolean'];
    /** Member is typically unavailable on Thurday morning. */
    unavailableThMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Thurday night. */
    unavailableThNight: Scalars['Boolean'];
    /** Member is typically unavailable on Tuesday daytime. */
    unavailableTuDay: Scalars['Boolean'];
    /** Member is typically unavailable on Tuesday morning. */
    unavailableTuMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Tuesday night. */
    unavailableTuNight: Scalars['Boolean'];
    /** Member is typically unavailable on Wednesday daytime. */
    unavailableWeDay: Scalars['Boolean'];
    /** Member is typically unavailable on Wednesday morning. */
    unavailableWeMorn: Scalars['Boolean'];
    /** Member is typically unavailable on Wednesday night. */
    unavailableWeNight: Scalars['Boolean'];
    unavailables?: Maybe<Array<MemberUnavailable>>;
    /** Address unit number. */
    unit?: Maybe<Scalars['String']>;
    /** Datetime of the entity record's most recent update. */
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** Type of VISA. */
    visaType?: Maybe<VisaType>;
    /** Work rights. */
    workRights?: Maybe<MemberWorkRights>;
    /** Work rights last check date. */
    workRightsLastCheckedAt?: Maybe<Scalars['Date']>;
  };

/** A member profile linking a user with an organisation. */
export type MemberBasePayRatesArgs = {
  input: QueryMemberBasePayRatesInput;
};

/** A member profile linking a user with an organisation. */
export type MemberBranchesArgs = {
  input?: InputMaybe<QueryBranchesInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberClaimsArgs = {
  input?: InputMaybe<QueryEventClaimsInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberEventsArgs = {
  input?: InputMaybe<QueryEventsInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberFilesArgs = {
  input?: InputMaybe<QueryMemberFilesInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberHoursArgs = {
  input?: InputMaybe<QueryMemberHoursInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberInvitationsReceivedArgs = {
  input?: InputMaybe<QueryMemberInvitationsInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberInvitationsSentArgs = {
  input?: InputMaybe<QueryMemberInvitationSendersInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberScheduleArgs = {
  input: QueryMemberScheduleInput;
};

/** A member profile linking a user with an organisation. */
export type MemberScheduleTimeLimitsArgs = {
  input?: InputMaybe<QueryMemberScheduleTimeLimitsInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberSchedulesArgs = {
  input: QueryMemberSchedulesInput;
};

/** A member profile linking a user with an organisation. */
export type MemberTeamsArgs = {
  input?: InputMaybe<QueryClientTeamsInput>;
};

/** A member profile linking a user with an organisation. */
export type MemberUnavailablesArgs = {
  input?: InputMaybe<QueryMemberUnavailablesInput>;
};

/** Bank details. */
export type MemberBank = {
  __typename?: 'MemberBank';
  /** Bank details - Account Name. */
  accountName?: Maybe<Scalars['String']>;
  /** Bank details - Account Number. */
  accountNumber?: Maybe<Scalars['String']>;
  /** Bank details - BSB. */
  bsb?: Maybe<Scalars['String']>;
};

/** A base pay rate for a member. */
export type MemberBasePayRate = {
  __typename?: 'MemberBasePayRate';
  /** Is the currently active pay rate for the linked Member. */
  active: Scalars['Boolean'];
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Effective-from date. */
  date: Scalars['Date'];
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Creator of the entity. */
  member: Member;
  /** Pay rate type. */
  type: MemberBasePayRateType;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Value. */
  value: Scalars['Int'];
};

/** Pay rate type. */
export enum MemberBasePayRateType {
  /** Annual salary. */
  ANNUAL = 'ANNUAL',
  /** Hourly rate. */
  HOURLY = 'HOURLY',
}

/** Aggregated response object for "MemberBasePayRate" entities. */
export type MemberBasePayRatesAggregate = {
  __typename?: 'MemberBasePayRatesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberBasePayRate>;
};

/** Ordering options for "MemberBasePayRate" entities. */
export type MemberBasePayRatesOrderByInput = {
  /** Order by active-state. */
  active?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by effective-from date. */
  date?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by type. */
  type?: InputMaybe<OrderBy>;
  /** Order by value */
  value?: InputMaybe<OrderBy>;
};

/** Updatable fields for "MemberBasePayRate" entities. */
export type MemberBasePayRatesUpdatableFieldsInput = {
  /** Effective-from date. */
  date?: InputMaybe<Scalars['Date']>;
  /** Pay rate type. */
  type?: InputMaybe<MemberBasePayRateType>;
  /** Value. */
  value?: InputMaybe<Scalars['Int']>;
};

/** Filtering conditions for "MemberBasePayRate" entities. */
export type MemberBasePayRatesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberBasePayRatesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberBasePayRatesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberBasePayRatesWhereInput>>;
  /** Active-state. */
  active?: InputMaybe<BooleanConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Effective-from date. */
  date?: InputMaybe<DateConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked member. */
  member?: InputMaybe<MembersWhereInput>;
  /** Type. */
  type?: InputMaybe<IntConditionalInput>;
  /** Value. */
  value?: InputMaybe<IntConditionalInput>;
};

/** Bonus eligibility state of a member. */
export type MemberBonusEligible = {
  __typename?: 'MemberBonusEligible';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Creator of the entity. */
  member: Member;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Value. */
  value: Scalars['Boolean'];
};

/** Aggregated response object for "MemberBonusEligible" entities. */
export type MemberBonusEligiblesAggregate = {
  __typename?: 'MemberBonusEligiblesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberBonusEligible>;
};

/** Ordering options for "MemberBonusEligible" entities. */
export type MemberBonusEligiblesOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Settable fields for "MemberBonusEligible" entities. */
export type MemberBonusEligiblesSettableFieldsInput = {
  /** Value. */
  value: Scalars['Boolean'];
};

/** Filtering conditions for "MemberBonusEligible" entities. */
export type MemberBonusEligiblesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberBonusEligiblesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberBonusEligiblesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberBonusEligiblesWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked member. */
  member?: InputMaybe<MembersWhereInput>;
};

/** Branch allocations of a member. */
export type MemberBranchAllocation = {
  __typename?: 'MemberBranchAllocation';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Effective-from date. */
  date: Scalars['Date'];
  /** Date of deletion. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Deletor of the entity. */
  deletedBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked Member entity. */
  member: Member;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Value. */
  values: Array<MemberBranchAllocationValue>;
};

/** Branch allocation value. */
export type MemberBranchAllocationValue = {
  __typename?: 'MemberBranchAllocationValue';
  /** Branch ID. */
  branchId: Scalars['UUID'];
  /** Value. */
  value: Scalars['Int'];
};

/** Aggregated response object for "MemberBranchAllocation" entities. */
export type MemberBranchAllocationsAggregate = {
  __typename?: 'MemberBranchAllocationsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberBranchAllocation>;
};

/** Ordering options for "MemberBranchAllocation" entities. */
export type MemberBranchAllocationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by effective-from date. */
  date?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by values */
  values?: InputMaybe<OrderBy>;
};

/** Updatable fields for "MemberBranchAllocation" entities. */
export type MemberBranchAllocationsUpdatableFieldsInput = {
  /** Effective-from date. */
  date?: InputMaybe<Scalars['Date']>;
  /** Values. */
  values?: InputMaybe<Array<CreateMemberBranchAllocationValueInput>>;
};

export type MemberFile = {
  __typename?: 'MemberFile';
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: Maybe<Scalars['Date']>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Expiry date of file. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  file: File;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Date file was issued. */
  issuedAt?: Maybe<Scalars['DateTime']>;
  /** Linked memberUpload. */
  memberUpload?: Maybe<MemberUpload>;
  /** Owner of the file. */
  owner: Member;
  type: MemberFileType;
  value?: Maybe<Scalars['String']>;
};

/** Type of file belonging to a "Member" entity. */
export enum MemberFileType {
  ASSISTING_WITH_SELF_MEDICATION = 'ASSISTING_WITH_SELF_MEDICATION',
  BANK_DETAILS = 'BANK_DETAILS',
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  CAR_INSURANCE = 'CAR_INSURANCE',
  CAR_REGISTRATION = 'CAR_REGISTRATION',
  CONFIRMATION_OF_ENROLLMENT = 'CONFIRMATION_OF_ENROLLMENT',
  COVID_19 = 'COVID_19',
  COVID_19_TRAVEL_EXEMPTION = 'COVID_19_TRAVEL_EXEMPTION',
  COVID_TEST_RESULTS = 'COVID_TEST_RESULTS',
  CPR = 'CPR',
  CTP_INSURANCE = 'CTP_INSURANCE',
  DRIVERS_LICENCE = 'DRIVERS_LICENCE',
  EFFECTIVE_COMMUNICATION = 'EFFECTIVE_COMMUNICATION',
  EMPLOYMENT_CONTRACT = 'EMPLOYMENT_CONTRACT',
  FIRST_AID = 'FIRST_AID',
  INDIVIDUAL_SUPPORT = 'INDIVIDUAL_SUPPORT',
  INTERVIEW = 'INTERVIEW',
  MANUAL_HANDLING = 'MANUAL_HANDLING',
  MEDICARE_CARD = 'MEDICARE_CARD',
  MISC = 'MISC',
  NDIS_MODULE = 'NDIS_MODULE',
  NEW_NDIS_CLEARANCE = 'NEW_NDIS_CLEARANCE',
  NEW_WORKER_NDIS_INDUCTION_MODULE = 'NEW_WORKER_NDIS_INDUCTION_MODULE',
  NON_DISCLOSURE_AGREEMENT = 'NON_DISCLOSURE_AGREEMENT',
  PASSPORT = 'PASSPORT',
  PHOTO_ID_CARD = 'PHOTO_ID_CARD',
  POLICE_CHECK = 'POLICE_CHECK',
  PPE = 'PPE',
  PREVENTING_DEMENTIA = 'PREVENTING_DEMENTIA',
  PRIVACY_AND_CONFIDENTIAILITY_DECLARATION = 'PRIVACY_AND_CONFIDENTIAILITY_DECLARATION',
  PROOF_OF_COVID_VACCINATION = 'PROOF_OF_COVID_VACCINATION',
  PROOF_OF_OVERSEAS_HEALTH_COVER = 'PROOF_OF_OVERSEAS_HEALTH_COVER',
  RESUME = 'RESUME',
  SEIZURE_RECOGITION = 'SEIZURE_RECOGITION',
  STAFF_APPLICATION = 'STAFF_APPLICATION',
  STAFF_CARD_PHOTO_ID = 'STAFF_CARD_PHOTO_ID',
  SUPERANNUATION = 'SUPERANNUATION',
  SUPPORTING_SAFE_MEALS = 'SUPPORTING_SAFE_MEALS',
  TAX_FILE_DECLARATION = 'TAX_FILE_DECLARATION',
  TAX_FILE_NUMBER = 'TAX_FILE_NUMBER',
  TRAVEL_RECEIPT = 'TRAVEL_RECEIPT',
  UNDERSTANDING_DEMENTIA = 'UNDERSTANDING_DEMENTIA',
  UNDERSTANDING_TRAUMATIC_BRAIN_INJURY = 'UNDERSTANDING_TRAUMATIC_BRAIN_INJURY',
  VACCINATION_RECORD = 'VACCINATION_RECORD',
  VEVO = 'VEVO',
  VISA = 'VISA',
  WORKING_WITH_CHILDREN_CHECK = 'WORKING_WITH_CHILDREN_CHECK',
  WORK_RIGHTS = 'WORK_RIGHTS',
}

/** String conditional expression input. */
export type MemberFileTypeConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['MemberFileTypeScaler']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['MemberFileTypeScaler']>>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['MemberFileTypeScaler']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['MemberFileTypeScaler']>>;
};

/** Aggregated response object for "MemberFile" entities. */
export type MemberFilesAggregate = {
  __typename?: 'MemberFilesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberFile>;
};

/** Ordering options for "MemberFile" entities. */
export type MemberFilesOrderByInput = {
  /** Order by date-of-file. */
  dateOfFile?: InputMaybe<OrderBy>;
  /** Order by expiry date. */
  expiresAt?: InputMaybe<OrderBy>;
  /** Order by file. */
  file?: InputMaybe<FilesOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by issue date. */
  issuedAt?: InputMaybe<OrderBy>;
  /** Order by value. */
  value?: InputMaybe<OrderBy>;
};

/** Updatable fields for "MemberFile" entities. */
export type MemberFilesUpdatableFieldsInput = {
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Update to apply to linked file entity. */
  file?: InputMaybe<UpdateFileInput>;
  /** Date file was issued. */
  issuedAt?: InputMaybe<Scalars['DateTime']>;
  /** Owner of the file. */
  owner?: InputMaybe<QueryByIdInput>;
  type?: InputMaybe<MemberFileType>;
  value?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "MemberFile" entities. */
export type MemberFilesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberFilesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberFilesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberFilesWhereInput>>;
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<DateTimeConditionalInput>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<DateTimeConditionalInput>;
  /** File. */
  file?: InputMaybe<FilesWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Issue date of file. */
  issuedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Owner. */
  owner?: InputMaybe<MembersWhereInput>;
  /** File type. */
  type?: InputMaybe<MemberFileTypeConditionalInput>;
  /** Value. */
  value?: InputMaybe<StringConditionalInput>;
};

/** Calculated Member hours. */
export type MemberHour = {
  __typename?: 'MemberHour';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Date of occurrence. */
  date: Scalars['Date'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked Member entity. */
  member: Member;
  /** Type. */
  type: MemberHoursType;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Quantity of hours. */
  value?: Maybe<Scalars['InfiniteInt']>;
};

/** Ordering options for "Member" entities. */
export type MemberHoursOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by date. */
  date?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by linked Member. */
  member?: InputMaybe<MembersOrderByInput>;
  /** Order by value. */
  value?: InputMaybe<OrderBy>;
};

/** Type of MemberHour. */
export enum MemberHoursType {
  MAX_LIMIT = 'MAX_LIMIT',
  SCHEDULED = 'SCHEDULED',
}

/** Filtering conditions for "MemberHour" entities. */
export type MemberHoursWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberHoursWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberHoursWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberHoursWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Date of occurrence. */
  date?: InputMaybe<DateConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked Member entity. */
  member?: InputMaybe<MembersWhereInput>;
  /** Type. */
  type?: InputMaybe<IntConditionalInput>;
  /** Value. */
  value?: InputMaybe<IntConditionalInput>;
};

/** An invitation to be sent to a user to grant them access to an organisation. */
export type MemberInvitation = {
  __typename?: 'MemberInvitation';
  /** Datetime of the entity's creation. */
  createdAt: Scalars['DateTime'];
  /** Creator of the entity. */
  createdBy: Member;
  /** Datetime of the entity's deletion. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Actor who performed the deletion. */
  deletedBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Member whom the invitation was created for. */
  invitee: Member;
  lastSender: MemberInvitationSender;
  /** The number of times the invitation has been sent. */
  sentCount: Scalars['Int'];
  /** Date token was successfully used. */
  usedAt?: Maybe<Scalars['DateTime']>;
};

/** A record of a sent member-invitation. */
export type MemberInvitationSender = {
  __typename?: 'MemberInvitationSender';
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** The invitation that was sent. */
  invitation: MemberInvitation;
  /** Date invitation was sent. */
  sentAt: Scalars['DateTime'];
  /** The sender of the invitation. */
  sentBy: Member;
};

/** Ordering options for "MemberInvitationSender" entities. */
export type MemberInvitationSendersOrderByInput = {
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by invitation. */
  invitation?: InputMaybe<MemberInvitationsOrderByInput>;
  /** Order by the datetime of when the invitation was sent. */
  sentAt?: InputMaybe<OrderBy>;
  /** Order by sender. */
  sentBy?: InputMaybe<MembersOrderByInput>;
};

/** Filtering conditions for "MemberInvitationSender" entities. */
export type MemberInvitationSendersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberInvitationSendersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberInvitationSendersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberInvitationSendersWhereInput>>;
  /** Invitation that was sent. */
  invitation?: InputMaybe<MemberInvitationsWhereInput>;
  /** Datetime when invitation was sent. */
  sentAt?: InputMaybe<DateTimeConditionalInput>;
  /** Sender of the invitation. */
  sentBy?: InputMaybe<MembersWhereInput>;
};

/** Ordering options for "MemberInvitation" entities. */
export type MemberInvitationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by datetime of deletion. */
  deletedAt?: InputMaybe<OrderBy>;
  /** Order by eraser. */
  deletedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by invitee. */
  invitee?: InputMaybe<MembersOrderByInput>;
  /** Order by senders. */
  senders?: InputMaybe<MemberInvitationSendersOrderByInput>;
  /** Order by datetime of successful use. */
  usedAt?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "MemberInvitation" entities. */
export type MemberInvitationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberInvitationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberInvitationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberInvitationsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Eraser of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Member whom the invitation was created for. */
  invitee?: InputMaybe<MembersWhereInput>;
  /** Senders of the invitation. */
  senders?: InputMaybe<MemberInvitationSendersWhereInput>;
  /** Datetime of successful use. */
  usedAt?: InputMaybe<DateTimeConditionalInput>;
};

/** A payroll-assistance check for a Member entity. */
export type MemberPayrollCheck = {
  __typename?: 'MemberPayrollCheck';
  /** Clocks-checked status. */
  clocksChecked: Scalars['Boolean'];
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Events-checked status. */
  eventsChecked: Scalars['Boolean'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked Member entity. */
  member: Member;
  /** Paid status. */
  paid: Scalars['Boolean'];
  /** Start date of payroll period. */
  payrollPeriodStartAt: Scalars['Date'];
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for "MemberPayrollCheck" entities. */
export type MemberPayrollChecksAggregate = {
  __typename?: 'MemberPayrollChecksAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberPayrollCheck>;
};

/** Ordering options for "MemberPayrollCheck" entities. */
export type MemberPayrollChecksOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by member. */
  member?: InputMaybe<MembersOrderByInput>;
  /** Order by start date of payroll period. */
  payrollPeriodStartAt?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "Member" entities. */
export type MemberPayrollChecksWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberPayrollChecksWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberPayrollChecksWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberPayrollChecksWhereInput>>;
  /** Clocks-checked status. */
  clocksChecked?: InputMaybe<BooleanConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Events-checked status. */
  eventsChecked?: InputMaybe<BooleanConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked Member entity. */
  member?: InputMaybe<MembersWhereInput>;
  /** Paid-checked status. */
  paid?: InputMaybe<BooleanConditionalInput>;
  /** Start date of payroll period. */
  payrollPeriodStartAt?: InputMaybe<DateConditionalInput>;
};

/** Post-nominals of a member. */
export type MemberPostNominal = {
  __typename?: 'MemberPostNominal';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Effective-from date. */
  date: Scalars['Date'];
  /** Date of deletion. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Deletor of the entity. */
  deletedBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked Member entity. */
  member: Member;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Value. */
  value: Scalars['String'];
};

export type MemberPostNominalsAggregate = {
  __typename?: 'MemberPostNominalsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberPostNominal>;
};

export type MemberPostNominalsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by effective-from date. */
  date?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by value. */
  value?: InputMaybe<OrderBy>;
};

export type MemberPostNominalsUpdatableFieldsInput = {
  /** Effective-from date. */
  date?: InputMaybe<Scalars['Date']>;
  /** Value. */
  value?: InputMaybe<Scalars['String']>;
};

export type MemberPostNominalsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberPostNominalsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberPostNominalsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberPostNominalsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Effective-from date. */
  date?: InputMaybe<DateConditionalInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Deletor of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Linked member. */
  member?: InputMaybe<MembersWhereInput>;
  /** Value. */
  value?: InputMaybe<StringConditionalInput>;
};

/** A schedule for a member. */
export type MemberSchedule = {
  __typename?: 'MemberSchedule';
  /** Allowed weekly schedulable minutes within specified period. */
  allowedMinutes: Scalars['Int'];
  /** Time scheduled within specified period. */
  totalMinutes: Scalars['Int'];
  /** Time worked within specified period. */
  totalPay: Scalars['Int'];
};

/** Scheduling time limits for a member of an organisation. */
export type MemberScheduleTimeLimit = {
  __typename?: 'MemberScheduleTimeLimit';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Datetime of the entity's deletion. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Deletor of the entity. */
  deletedBy?: Maybe<Member>;
  /** Description. */
  description?: Maybe<Scalars['String']>;
  /** End datetime of the limit. */
  endAt: Scalars['DateTime'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Time limit in minutes. */
  limit: Scalars['Int'];
  /** Owning entity. */
  member: Member;
  /** Start datetime of the limit. */
  startAt: Scalars['DateTime'];
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Creatable fields for "MemberScheduleTimeLimit" entities. */
export type MemberScheduleTimeLimitsCreatableFieldsInput = {
  /** Description. */
  description?: InputMaybe<Scalars['String']>;
  /** End datetime of the limit. */
  endAt: Scalars['DateTime'];
  /** Time limit in minutes. */
  limit: Scalars['Int'];
  /** Member input data. */
  member: QueryByIdInput;
  /** Start datetime of the limit. */
  startAt: Scalars['DateTime'];
};

/** Ordering options for "MemberScheduleTimeLimit" entities. */
export type MemberScheduleTimeLimitsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by description. */
  description?: InputMaybe<OrderBy>;
  /** Order by endAt. */
  endAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by startAt. */
  startAt?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "MemberScheduleTimeLimit" entities. */
export type MemberScheduleTimeLimitsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberScheduleTimeLimitsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberScheduleTimeLimitsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberScheduleTimeLimitsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Datetime of entitys deletion. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Deletor of the entity. */
  deletedBy?: InputMaybe<MembersWhereInput>;
  /** Description. */
  description?: InputMaybe<StringConditionalInput>;
  /** End datetime of limit. */
  endAt?: InputMaybe<DateConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Time limit in minutes. */
  limit?: InputMaybe<IntConditionalInput>;
  /** Linked Member entity. */
  member?: InputMaybe<MembersWhereInput>;
  /** Start datetime of limit. */
  startAt?: InputMaybe<DateConditionalInput>;
};

/** Superannuation details. */
export type MemberSuper = {
  __typename?: 'MemberSuper';
  /** Superannutation fund account name. */
  accountName?: Maybe<Scalars['String']>;
  /** Superannutation fund account number. */
  accountNumber?: Maybe<Scalars['String']>;
  /** Australian Business Number of the superannutation fund. */
  fundAbn?: Maybe<Scalars['ABN']>;
  /** Superannutation fund name. */
  fundName?: Maybe<Scalars['String']>;
  /** Unique superannuation identifier (USI). */
  usi?: Maybe<Scalars['String']>;
};

/** TFN delcaration details. */
export type MemberTaxFileNumberDeclaration = {
  __typename?: 'MemberTaxFileNumberDeclaration';
  /** Made a separate application/enquiry to the ATO for a new or existing TFN. */
  appliedForTfn?: Maybe<Scalars['Boolean']>;
  /** Basis of pay. */
  areYou?: Maybe<MemberTaxFileNumberDeclarationAreYou>;
  /** Basis of pay. */
  basisOfPay?: Maybe<MemberTaxFileNumberDeclarationBasisOfPay>;
  /** Do you want to claim the tax-free threshold from this payer? */
  claimTaxFreeThreshold?: Maybe<Scalars['Boolean']>;
  /** Claiming an exemption because I am under 18 years of age and do not earn enough to pay tax. */
  claimingExemptionMinor?: Maybe<Scalars['Boolean']>;
  /** Claiming an exemption because I am in receipt of a pension, benefit or allowance. */
  claimingExemptionPension?: Maybe<Scalars['Boolean']>;
  /** Date of birth. */
  dob?: Maybe<Scalars['Date']>;
  /** Email address. */
  email?: Maybe<Scalars['String']>;
  /** First name. */
  firstName?: Maybe<Scalars['String']>;
  /** Last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: Maybe<Scalars['String']>;
  /** Middle name. */
  middleName?: Maybe<Scalars['String']>;
  /** Address postcode. */
  postcode?: Maybe<Scalars['PostalCode']>;
  /** Previous last name. */
  prevLastName?: Maybe<Scalars['String']>;
  /** Address state or territory. */
  region?: Maybe<AddressRegionCode>;
  /** Salutation. */
  salutation?: Maybe<Salutation>;
  /** Address street number, name and type. */
  street?: Maybe<Scalars['String']>;
  /** Do you have a Higher Education Loan Program (HELP), VET Student Loan (VSL), Financial Supplement (FS), Student Start-up Loan (SSL) or Trade Support Loan (TSL) debt? */
  studentLoanDebt?: Maybe<Scalars['Boolean']>;
  /** Tax File Number (TFN). */
  tfn?: Maybe<Scalars['String']>;
};

/** TFN declaration - Are you. */
export enum MemberTaxFileNumberDeclarationAreYou {
  AUSTRALIAN_RESIDENT = 'AUSTRALIAN_RESIDENT',
  FOREIGN_RESIDENT = 'FOREIGN_RESIDENT',
  HOLIDAY_MAKER = 'HOLIDAY_MAKER',
}

/** TFN declaration - Basis of pay. */
export enum MemberTaxFileNumberDeclarationBasisOfPay {
  ANNUITY_INCOME_STREAM = 'ANNUITY_INCOME_STREAM',
  CASUAL = 'CASUAL',
  FULL_TIME = 'FULL_TIME',
  LABOUR_HIRE = 'LABOUR_HIRE',
  PART_TIME = 'PART_TIME',
}

/** A period of time during which an employee is unavailable. */
export type MemberUnavailable = {
  __typename?: 'MemberUnavailable';
  /** Hexidecimal color. */
  color?: Maybe<Scalars['HexColorCode']>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  dates: Array<Array<Scalars['DateTime']>>;
  /** Date of deletion. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Actor who performed the deletion. */
  deletedBy?: Maybe<Member>;
  /** End datetime of the unavailable period. */
  endAt: Scalars['DateTime'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Notes. */
  notes?: Maybe<Scalars['String']>;
  /** Member to which this entity is linked. */
  owner: Member;
  /** Recurrence rule as documented in the iCalendar RFC. */
  rrule?: Maybe<Scalars['String']>;
  /** Start datetime of the unavailable period. */
  startAt: Scalars['DateTime'];
  startDates: Array<Scalars['DateTime']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MemberUnavailableConflict = {
  __typename?: 'MemberUnavailableConflict';
  conflict: Conflict;
  memberUnavailable: MemberUnavailable;
};

/** Ordering options. */
export type MemberUnavailableConflictsOrderByInput = {
  /** Order by conflicts. */
  conflict?: InputMaybe<ConflictsOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by memberUnavailables. */
  memberUnavailable?: InputMaybe<MemberUnavailablesOrderByInput>;
};

/** Filtering conditions. */
export type MemberUnavailableConflictsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberUnavailableConflictsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberUnavailableConflictsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberUnavailableConflictsWhereInput>>;
  /** Linked Conflict entity. */
  conflict?: InputMaybe<ConflictsWhereInput>;
  /** Linked MemberUnavailable entity. */
  memberUnavailable?: InputMaybe<MemberUnavailablesWhereInput>;
};

/** Aggregated response object for "MemberUnavailable" entities. */
export type MemberUnavailablesAggregate = {
  __typename?: 'MemberUnavailablesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberUnavailable>;
};

/** Creatable fields for "MemberUnavailable" entities */
export type MemberUnavailablesCreatableFieldsInput = {
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** End datetime of the unavailable period. */
  endAt: Scalars['DateTime'];
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Recurrence rule as documented in the iCalendar RFC. */
  rrule?: InputMaybe<Scalars['String']>;
  /** Start datetime of the unavailable period. */
  startAt: Scalars['DateTime'];
};

/** Ordering options for "MemberUnavailable" entities. */
export type MemberUnavailablesOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by deletion date. */
  deletedAt?: InputMaybe<OrderBy>;
  /** Order by eraser. */
  deletedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by end date. */
  endAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by notes. */
  notes?: InputMaybe<OrderBy>;
  /** Order by owner. */
  owner?: InputMaybe<MembersOrderByInput>;
  /** Order by start date. */
  startAt?: InputMaybe<OrderBy>;
};

/** Updatable fields for "MemberUnavailable" entities. */
export type MemberUnavailablesUpdatableFieldsInput = {
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** End datetime of the unavailable period. */
  endAt?: InputMaybe<Scalars['DateTime']>;
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Start datetime of the unavailable period. */
  startAt?: InputMaybe<Scalars['DateTime']>;
};

/** Filtering conditions for "MemberUnavailable" entities. */
export type MemberUnavailablesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberUnavailablesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberUnavailablesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberUnavailablesWhereInput>>;
  /** Deletion date. */
  deletedAt?: InputMaybe<DateTimeConditionalInput>;
  /** End date. */
  endAt?: InputMaybe<DateTimeConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Owner. */
  owner?: InputMaybe<MembersWhereInput>;
  /** Start date. */
  startAt?: InputMaybe<DateTimeConditionalInput>;
};

export type MemberUpload = {
  __typename?: 'MemberUpload';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Uploaded file. */
  file: File;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Linked memberFile. */
  memberFile?: Maybe<MemberFile>;
  /** Datetime of processing. */
  processedAt?: Maybe<Scalars['DateTime']>;
  /** Member who processed the upload. */
  processedBy?: Maybe<Member>;
  /** Processing note. */
  processingNote?: Maybe<Scalars['String']>;
  /** Processing state. */
  processingState: ProcessingState;
  /** File type. */
  type: MemberFileType;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Uploader of the file. */
  uploadedBy: Member;
  /** Owner of the file. */
  uploadedFor: Member;
};

/** Aggregated response object for "MemberUpload" entities. */
export type MemberUploadsAggregate = {
  __typename?: 'MemberUploadsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<MemberUpload>;
};

/** Ordering options for "MemberUpload" entities. */
export type MemberUploadsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by file. */
  file?: InputMaybe<FilesOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by datetime of processing. */
  processedAt?: InputMaybe<OrderBy>;
  /** Order by member who processed the upload. */
  processedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by processing note. */
  processingNote?: InputMaybe<OrderBy>;
  /** Order by processing state. */
  processingState?: InputMaybe<OrderBy>;
  /** Order by lfile type. */
  type?: InputMaybe<OrderBy>;
  /** Order by uploader. */
  uploadedBy?: InputMaybe<MembersOrderByInput>;
  /** Order by owner of the file. */
  uploadedFor?: InputMaybe<MembersOrderByInput>;
};

/** Filtering conditions for "MemberUpload" entities. */
export type MemberUploadsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MemberUploadsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MemberUploadsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MemberUploadsWhereInput>>;
  /** File. */
  file?: InputMaybe<FilesWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Datetime of entitys processing. */
  processedAt?: InputMaybe<DateTimeConditionalInput>;
  /** Member who processed the upload. */
  processedBy?: InputMaybe<MembersWhereInput>;
  /** Processing note. */
  processingNote?: InputMaybe<StringConditionalInput>;
  /** Processing state. */
  processingState?: InputMaybe<ProcessingStateConditionalInput>;
  /** File type. */
  type?: InputMaybe<StringConditionalInput>;
  /** Uploader of the file. */
  uploadedBy?: InputMaybe<MembersWhereInput>;
  /** Owner of the file. */
  uploadedFor?: InputMaybe<MembersWhereInput>;
};

/** Work rights of a "Member" entity. */
export enum MemberWorkRights {
  NIL = 'NIL',
  RESTRICTED = 'RESTRICTED',
  UNRESTRICTED = 'UNRESTRICTED',
}

/** Aggregated response object for "Member" entities. */
export type MembersAggregate = {
  __typename?: 'MembersAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Member>;
};

/** Creatable fields for "Member" entities. */
export type MembersCreatableFieldsInput = {
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<Scalars['Int']>;
  /** Member is an Australian Citizen. */
  australianCitizen?: InputMaybe<Scalars['Boolean']>;
  /** Avatar image upload. */
  avatarData?: InputMaybe<Scalars['Upload']>;
  /** Entity input data. */
  bank?: InputMaybe<SetMemberBankInput>;
  /** Biography. */
  bio?: InputMaybe<Scalars['String']>;
  /** Bonus eligible. */
  bonusEligible?: InputMaybe<MemberBonusEligiblesSettableFieldsInput>;
  /** Branches input data. */
  branches?: InputMaybe<QueryByIdsNullableInput>;
  /** Building name. */
  buildingName?: InputMaybe<Scalars['String']>;
  /** Member can use a hoist. */
  canHoist?: InputMaybe<Scalars['Boolean']>;
  /** Description of car. */
  carDescription?: InputMaybe<Scalars['String']>;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Confidential notes. */
  confidentialNotes?: InputMaybe<Scalars['String']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Drivers license. */
  driversLicense?: InputMaybe<DriversLicense>;
  /** Drivers license country of issue. */
  driversLicenseCountry?: InputMaybe<AddressCountryCode>;
  /** Drivers license expiry date. */
  driversLicenseExpiryDate?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** Empathy rating. */
  empathyRating?: InputMaybe<Scalars['Int']>;
  /** Employment contract externally saved? */
  employementContractExternallySaved?: InputMaybe<Scalars['Int']>;
  /** Employment end date */
  employmentEndDate?: InputMaybe<Scalars['Date']>;
  /** Employment start date */
  employmentStartDate?: InputMaybe<Scalars['Date']>;
  /** External reference ID. */
  externalId?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Member has a car. */
  hasCar?: InputMaybe<Scalars['Boolean']>;
  /** Member has a drivers license. */
  hasDriversLicense?: InputMaybe<Scalars['Boolean']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Module access: Core Support. */
  moduleAccessCS?: InputMaybe<Scalars['Boolean']>;
  /** Module access: Support Coordination. */
  moduleAccessSC?: InputMaybe<Scalars['Boolean']>;
  /** Administrator-only notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Passport country. */
  passportCountry?: InputMaybe<AddressCountryCode>;
  /** Passport number. */
  passportNumber?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Reliability rating. */
  reliabilityRating?: InputMaybe<Scalars['Int']>;
  /** Communication preference: Clock on/off reminder. */
  remindClock?: InputMaybe<Scalars['Boolean']>;
  /** Communication preference: Upcoming events reminder. */
  remindEvents?: InputMaybe<Scalars['Boolean']>;
  /** Communication preference: Incomplete notes reminder. */
  remindNotes?: InputMaybe<Scalars['Boolean']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Member-schedulable status. */
  schedulable?: InputMaybe<Scalars['Boolean']>;
  /** Default maximum schedulable minutes per day. */
  scheduleMaxDayTimeLimitDefault?: InputMaybe<Scalars['Int']>;
  /** Default maximum schedulable minutes per week. */
  scheduleMaxWeekTimeLimitDefault?: InputMaybe<Scalars['Int']>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** Field of study. */
  study?: InputMaybe<Scalars['String']>;
  /** Entity input data. */
  super?: InputMaybe<SetMemberSuperInput>;
  /** Entity input data. */
  taxFileNumberDeclaration?: InputMaybe<SetMemberTaxFileNumberDeclarationInput>;
  /** Timezone. */
  timezone?: InputMaybe<Timezone>;
  /** File-tracking status. */
  trackFiles?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday daytime. */
  unavailableFrDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday morning. */
  unavailableFrMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday night. */
  unavailableFrNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday daytime. */
  unavailableMoDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday morning. */
  unavailableMoMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday night. */
  unavailableMoNight?: InputMaybe<Scalars['Boolean']>;
  /** General unavailability notes. */
  unavailableNotes?: InputMaybe<Scalars['String']>;
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSaDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday morning. */
  unavailableSaMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday night. */
  unavailableSaNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSuDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday morning. */
  unavailableSuMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Sunday night. */
  unavailableSuNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday daytime. */
  unavailableThDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday morning. */
  unavailableThMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday night. */
  unavailableThNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday daytime. */
  unavailableTuDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday morning. */
  unavailableTuMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday night. */
  unavailableTuNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday daytime. */
  unavailableWeDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday morning. */
  unavailableWeMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday night. */
  unavailableWeNight?: InputMaybe<Scalars['Boolean']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
  /** Visa Type input data. */
  visaType?: InputMaybe<QueryByIdInput>;
  /** Work rights. */
  workRights?: InputMaybe<MemberWorkRights>;
  /** Work rights last check date. */
  workRightsLastCheckedAt?: InputMaybe<Scalars['Date']>;
};

/** Updatable fields for "Member" entities. */
export type MembersCurrentUpdatableFieldsInput = {
  /** Entity input data. */
  bank?: InputMaybe<SetMemberBankInput>;
  /** Default branch input data. */
  defaultBranch?: InputMaybe<QueryByIdInput>;
  /** Entity input data. */
  super?: InputMaybe<SetMemberSuperInput>;
  /** Entity input data. */
  taxFileNumberDeclaration?: InputMaybe<SetMemberTaxFileNumberDeclarationInput>;
  /** Member is typically unavailable on Friday daytime. */
  unavailableFrDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday morning. */
  unavailableFrMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday night. */
  unavailableFrNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday daytime. */
  unavailableMoDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday morning. */
  unavailableMoMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday night. */
  unavailableMoNight?: InputMaybe<Scalars['Boolean']>;
  /** General unavailability notes. */
  unavailableNotes?: InputMaybe<Scalars['String']>;
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSaDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday morning. */
  unavailableSaMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday night. */
  unavailableSaNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSuDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday morning. */
  unavailableSuMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Sunday night. */
  unavailableSuNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday daytime. */
  unavailableThDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday morning. */
  unavailableThMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday night. */
  unavailableThNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday daytime. */
  unavailableTuDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday morning. */
  unavailableTuMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday night. */
  unavailableTuNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday daytime. */
  unavailableWeDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday morning. */
  unavailableWeMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday night. */
  unavailableWeNight?: InputMaybe<Scalars['Boolean']>;
};

/** Ordering options for "Member" entities. */
export type MembersOrderByInput = {
  /** Order by allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<OrderBy>;
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by date of birth. */
  dob?: InputMaybe<OrderBy>;
  /** Order by drivers license country of issue. */
  driversLicenseCountry?: InputMaybe<OrderBy>;
  /** Order by drivers license expiry date. */
  driversLicenseExpiryDate?: InputMaybe<OrderBy>;
  /** Order by employementContractExternallySaved. */
  employementContractExternallySaved?: InputMaybe<OrderBy>;
  /** Order by employment end date. */
  employmentEndDate?: InputMaybe<OrderBy>;
  /** Order by employment start date. */
  employmentStartDate?: InputMaybe<OrderBy>;
  /** Order by attending events. */
  events?: InputMaybe<EventsOrderByInput>;
  /** Order by external reference ID. */
  externalId?: InputMaybe<OrderBy>;
  /** Order by first billable event start date. */
  firstBillableEventStartAt?: InputMaybe<OrderBy>;
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by having a car. */
  hasCar?: InputMaybe<OrderBy>;
  /** Order by having drivers license. */
  hasDriversLicense?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last-active-at datatime. */
  lastActiveAt?: InputMaybe<OrderBy>;
  /** Order by last billable event start date. */
  lastBillableEventStartAt?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by lateness. */
  lateness?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by minutes between first and last billable events. */
  minutesBetweenFirstAndLastBillableEvents?: InputMaybe<OrderBy>;
  /** Order by org. */
  org?: InputMaybe<OrgsOrderByInput>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by schedulable status. */
  schedulable?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by teams. */
  teams?: InputMaybe<ClientTeamsOrderByInput>;
  /** Order by billable minutes. */
  totalBillableMinutes?: InputMaybe<OrderBy>;
  /** Order by unavailable periods. */
  unavailables?: InputMaybe<MemberUnavailablesOrderByInput>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
  /** Order by work rights. */
  workRights?: InputMaybe<OrderBy>;
  /** Order by work rights last check date. */
  workRightsLastCheckedAt?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Member" entities. */
export type MembersUpdatableFieldsInput = {
  /** Admin status. */
  admin?: InputMaybe<Scalars['Boolean']>;
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<Scalars['Int']>;
  /** Member is an Australian Citizen. */
  australianCitizen?: InputMaybe<Scalars['Boolean']>;
  /** Avatar image upload. */
  avatarData?: InputMaybe<Scalars['Upload']>;
  /** Entity input data. */
  bank?: InputMaybe<SetMemberBankInput>;
  /** Biography. */
  bio?: InputMaybe<Scalars['String']>;
  /** Bonus eligible. */
  bonusEligible?: InputMaybe<MemberBonusEligiblesSettableFieldsInput>;
  /** Branches input data. */
  branches?: InputMaybe<QueryByIdsNullableInput>;
  /** Building name. */
  buildingName?: InputMaybe<Scalars['String']>;
  /** Member can use a hoist. */
  canHoist?: InputMaybe<Scalars['Boolean']>;
  /** Description of car. */
  carDescription?: InputMaybe<Scalars['String']>;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Confidential notes. */
  confidentialNotes?: InputMaybe<Scalars['String']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Default branch input data. */
  defaultBranch?: InputMaybe<QueryByIdInput>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Drivers license. */
  driversLicense?: InputMaybe<DriversLicense>;
  /** Drivers license country of issue. */
  driversLicenseCountry?: InputMaybe<AddressCountryCode>;
  /** Drivers license expiry date. */
  driversLicenseExpiryDate?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** Empathy rating. */
  empathyRating?: InputMaybe<Scalars['Int']>;
  /** Employment contract externally saved? */
  employementContractExternallySaved?: InputMaybe<Scalars['Int']>;
  /** Employment end date */
  employmentEndDate?: InputMaybe<Scalars['Date']>;
  /** Employment start date */
  employmentStartDate?: InputMaybe<Scalars['Date']>;
  /** External reference ID. */
  externalId?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Member has a car. */
  hasCar?: InputMaybe<Scalars['Boolean']>;
  /** Member has a drivers license. */
  hasDriversLicense?: InputMaybe<Scalars['Boolean']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Module access: Core Support. */
  moduleAccessCS?: InputMaybe<Scalars['Boolean']>;
  /** Module access: Support Coordination. */
  moduleAccessSC?: InputMaybe<Scalars['Boolean']>;
  /** Administrator-only notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Passport country. */
  passportCountry?: InputMaybe<AddressCountryCode>;
  /** Passport number. */
  passportNumber?: InputMaybe<Scalars['String']>;
  /** Permissions within the organisation. */
  permissions?: InputMaybe<Array<Permission>>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Reliability rating. */
  reliabilityRating?: InputMaybe<Scalars['Int']>;
  /** Communication preference: Clock on/off reminder. */
  remindClock?: InputMaybe<Scalars['Boolean']>;
  /** Communication preference: Upcoming events reminder. */
  remindEvents?: InputMaybe<Scalars['Boolean']>;
  /** Communication preference: Incomplete notes reminder. */
  remindNotes?: InputMaybe<Scalars['Boolean']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Member-schedulable status. */
  schedulable?: InputMaybe<Scalars['Boolean']>;
  /** Default maximum schedulable minutes per day. */
  scheduleMaxDayTimeLimitDefault?: InputMaybe<Scalars['Int']>;
  /** Default maximum schedulable minutes per week. */
  scheduleMaxWeekTimeLimitDefault?: InputMaybe<Scalars['Int']>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** Field of study. */
  study?: InputMaybe<Scalars['String']>;
  /** Entity input data. */
  super?: InputMaybe<SetMemberSuperInput>;
  /** Super-admin status. */
  superAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Entity input data. */
  taxFileNumberDeclaration?: InputMaybe<SetMemberTaxFileNumberDeclarationInput>;
  /** Timezone. */
  timezone?: InputMaybe<Timezone>;
  /** File-tracking status. */
  trackFiles?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday daytime. */
  unavailableFrDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday morning. */
  unavailableFrMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Friday night. */
  unavailableFrNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday daytime. */
  unavailableMoDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday morning. */
  unavailableMoMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Monday night. */
  unavailableMoNight?: InputMaybe<Scalars['Boolean']>;
  /** General unavailability notes. */
  unavailableNotes?: InputMaybe<Scalars['String']>;
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSaDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday morning. */
  unavailableSaMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday night. */
  unavailableSaNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday daytime. */
  unavailableSuDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Saturday morning. */
  unavailableSuMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Sunday night. */
  unavailableSuNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday daytime. */
  unavailableThDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday morning. */
  unavailableThMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Thurday night. */
  unavailableThNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday daytime. */
  unavailableTuDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday morning. */
  unavailableTuMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Tuesday night. */
  unavailableTuNight?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday daytime. */
  unavailableWeDay?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday morning. */
  unavailableWeMorn?: InputMaybe<Scalars['Boolean']>;
  /** Member is typically unavailable on Wednesday night. */
  unavailableWeNight?: InputMaybe<Scalars['Boolean']>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
  /** Visa Type input data. */
  visaType?: InputMaybe<QueryByIdInput>;
  /** Work rights. */
  workRights?: InputMaybe<MemberWorkRights>;
  /** Work rights last check date. */
  workRightsLastCheckedAt?: InputMaybe<Scalars['Date']>;
};

/** Input data for validating member external ids. */
export type MembersValidateExternalldsInput = {
  /** Check whether ids exists or not */
  exists: Scalars['Boolean'];
  /** External IDs. */
  ids: Array<Scalars['String']>;
};

/** Validated member external ids response object. */
export type MembersValidatedExternalIdsResponse = {
  __typename?: 'MembersValidatedExternalIdsResponse';
  /** External IDs. */
  ids: Array<Scalars['String']>;
};

/** Filtering conditions for "Member" entities. */
export type MembersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<MembersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<MembersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<MembersWhereInput>>;
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<IntConditionalInput>;
  /** Archived entity state. */
  archive?: InputMaybe<BooleanConditionalInput>;
  /** BranchMember associative entities. */
  branchMembers?: InputMaybe<BranchMembersWhereInput>;
  /** Car description. */
  carDescription?: InputMaybe<StringConditionalInput>;
  /** ClientObservation associative entities. */
  clientObservationsCreated?: InputMaybe<ClientObservationsWhereInput>;
  /** Color. */
  color?: InputMaybe<StringConditionalInput>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Deleted entity state. */
  delete?: InputMaybe<BooleanConditionalInput>;
  /** Date of birth. */
  dob?: InputMaybe<DateConditionalInput>;
  /** Drivers license country of issue. */
  driversLicenseCountry?: InputMaybe<StringConditionalInput>;
  /** Drivers license expiry date. */
  driversLicenseExpiryDate?: InputMaybe<DateConditionalInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** Events this member is an attendee of. */
  events?: InputMaybe<EventsWhereInput>;
  /** External reference ID. */
  externalId?: InputMaybe<StringConditionalInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Gender. */
  gender?: InputMaybe<IntConditionalInput>;
  /** Has car. */
  hasCar?: InputMaybe<BooleanConditionalInput>;
  /** Has drivers license. */
  hasDriversLicense?: InputMaybe<BooleanConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Datetime of when member was last active in the organisation. */
  lastActiveAt?: InputMaybe<DateTimeConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  moduleAccessCS?: InputMaybe<BooleanConditionalInput>;
  moduleAccessSC?: InputMaybe<BooleanConditionalInput>;
  /** Administrator-only notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Pronouns. */
  pronouns?: InputMaybe<IntConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
  /** Member schedulable status. */
  schedulable?: InputMaybe<BooleanConditionalInput>;
  /** Member schedule time limits. */
  scheduleTimeLimits?: InputMaybe<MemberScheduleTimeLimitsWhereInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Teams. */
  teams?: InputMaybe<ClientTeamsWhereInput>;
  /** File tracking status. */
  trackFiles?: InputMaybe<BooleanConditionalInput>;
  /** General unavailability notes. */
  unavailabileNotes?: InputMaybe<StringConditionalInput>;
  /** Member unavailable periods. */
  unavailables?: InputMaybe<MemberUnavailablesWhereInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
  /** Work rights. */
  workRights?: InputMaybe<IntConditionalInput>;
  /** Work rights last check date. */
  workRightsLastCheckedAt?: InputMaybe<DateConditionalInput>;
};

/** Module type. */
export enum ModuleType {
  CS = 'CS',
  SC = 'SC',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Approve travel data for "Event" entities. */
  approveEventsTravel: Array<Event>;
  /** Archive "Branch" entities. */
  archiveBranches: Array<Branch>;
  /** Archive entities. */
  archiveClientNdisPlanItemTransactionSuppliers: Array<ClientNdisPlanItemTransactionSupplier>;
  /** Archive entities. */
  archiveClientNdisPlanItemTransactions: Array<ClientNdisPlanItemTransaction>;
  /** Archive "Client" entities. */
  archiveClients: Array<Client>;
  /** Archive "Event" entities. */
  archiveEvents: Array<Event>;
  /** Archive "Member" entities. */
  archiveMembers: Array<Member>;
  /** Archive entities. */
  archiveNdisCoordinationClients: Array<NdisCoordinationClient>;
  /** Archive "Org" entities of which the current user is a member. */
  archiveOrgs: Array<Org>;
  /** Archive "PlanManager" entities. */
  archivePlanManagers: Array<PlanManager>;
  /** Archive "SupportCoordinator" entities. */
  archiveSupportCoordinators: Array<SupportCoordinator>;
  /** Cancel "Event" entities. */
  cancelEvents: Array<Event>;
  /** Claim a single event. */
  claimEvent: EventClaim;
  /** Clock on/off. */
  clockEvent: Event;
  /** Clock on/off. */
  clockEventAttended: EventOwnAndRelated;
  closeEventIssue: EventIssue;
  /** Create "Branch" entities. */
  createBranches: Array<Branch>;
  /** Create "ClientContact" entities. */
  createClientContact: ClientContact;
  /** Upload a single File for a "Client" entity. */
  createClientFile: ClientFile;
  /** Create an entity. */
  createClientNdisPlan: ClientNdisPlan;
  /** Create an entity. */
  createClientNdisPlanItem: ClientNdisPlanItem;
  /** Create an entity. */
  createClientNdisPlanItemTransaction: ClientNdisPlanItemTransaction;
  /** Create entity. */
  createClientNdisPlanItemTransactionSupplier: ClientNdisPlanItemTransactionSupplier;
  /** Create an entity. */
  createClientNdisPlanItemTransactions: Array<ClientNdisPlanItemTransaction>;
  /** Create a singular entity. */
  createClientNote: ClientNote;
  /** Upload a single File for a "ClientNote" entity. */
  createClientNoteFile: ClientNoteFile;
  /** Create a singular entity. */
  createClientNoteKeyword: ClientNoteKeyword;
  /** Create entities. */
  createClientNoteKeywords: Array<ClientNoteKeyword>;
  /** Create entities. */
  createClientNotes: Array<ClientNote>;
  /** Create "ClientObservation" entities. */
  createClientObservation: ClientObservation;
  /** Create a "ClientObservation" entity with an Event relation. */
  createClientObservationWithEvent: ClientObservation;
  /** Create "Client" entities. */
  createClients: Array<Client>;
  /** Create "Event" entity. */
  createEvent: Event;
  /** Upload a single File for an "Event" entity. */
  createEventFile: EventFile;
  createEventIssue: EventIssue;
  /** Create "Medication" entities. */
  createMedication: Medication;
  /** Create "MemberBasePayRate" entity */
  createMemberBasePayRate: MemberBasePayRate;
  /** Create "MemberBranchAllocation" entity */
  createMemberBranchAllocation: MemberBranchAllocation;
  /** Upload a single File for a "Member" entity. */
  createMemberFile: MemberFile;
  /** Create entity */
  createMemberPostNominal: MemberPostNominal;
  /** Create "MemberScheduleTimeLimit" entities. */
  createMemberScheduleTimeLimits: Array<MemberScheduleTimeLimit>;
  /** Create "MemberUnavailable" entities. */
  createMemberUnavailables: Array<MemberUnavailable>;
  /** Upload a single File. */
  createMemberUpload: MemberUpload;
  /** Create "Member" entities. */
  createMembers: Array<Member>;
  /** Create an entity. */
  createNdisCoordinationClient: NdisCoordinationClient;
  /** Create an entity. */
  createNdisCoordinationClientActivity: NdisCoordinationClientActivity;
  /** Create an "Org" entity, "Branch" entities and a "Member" profile entity for the current user. */
  createOrg: Org;
  /** Create a "PayrollCategory" entity. */
  createPayrollCategory: PayrollCategory;
  /** Create "PlanManager" entities. */
  createPlanManagers: Array<PlanManager>;
  /** Create "SupportCoordinator" entities. */
  createSupportCoordinators: Array<SupportCoordinator>;
  /** Create a user account. */
  createUser: UserLoginResponse;
  /** Create a "VisaType" entity. */
  createVisaType: VisaType;
  /** Delete "Branch" entities. */
  deleteBranches: Array<Branch>;
  /** Delete "ClientContact" entities. */
  deleteClientContacts: Array<ClientContact>;
  /** Delete "ClientFile" entities. */
  deleteClientFiles: Array<ClientFile>;
  /** Delete entities. */
  deleteClientNdisPlanItemTransactions: Array<ClientNdisPlanItemTransaction>;
  /** Delete entities. */
  deleteClientNdisPlanItems: Array<ClientNdisPlanItem>;
  /** Delete entities. */
  deleteClientNdisPlans: Array<ClientNdisPlan>;
  /** Delete "ClientNoteFile" entities. */
  deleteClientNoteFiles: Array<ClientNoteFile>;
  /** Delete entities. */
  deleteClientNoteKeywords: Array<ClientNoteKeyword>;
  /** Delete entities. */
  deleteClientNotes: Array<ClientNote>;
  /** Delete "ClientObservation" entities. */
  deleteClientObservations: Array<ClientObservation>;
  /** Delete "Client" entities. */
  deleteClients: Array<Client>;
  /** Delete "EventFile" entities. */
  deleteEventFiles: Array<EventFile>;
  /** Delete "Event" entities. */
  deleteEvents: Array<Event>;
  /** Delete "Medication" entities. */
  deleteMedications: Array<Medication>;
  /** Delete "MemberBasePayRate" entities. */
  deleteMemberBasePayRates: Array<MemberBasePayRate>;
  /** Delete an entity. */
  deleteMemberBranchAllocation: MemberBranchAllocation;
  /** Delete "MemberFile" entities. */
  deleteMemberFiles: Array<MemberFile>;
  /** Delete an entity. */
  deleteMemberPostNominal: MemberPostNominal;
  /** Delete "MemberUnavailable" entities. */
  deleteMemberUnavailables: Array<MemberUnavailable>;
  /** Delete "Member" entities. */
  deleteMembers: Array<Member>;
  /** Delete entities. */
  deleteNdisCoordinationClientActivities: Array<NdisCoordinationClientActivity>;
  /** Delete "Org" entities of which the current user is a member. */
  deleteOrgs: Array<Org>;
  /** Delete "PlanManager" entities. */
  deletePlanManagers: Array<PlanManager>;
  /** Delete "SupportCoordinator" entities. */
  deleteSupportCoordinators: Array<SupportCoordinator>;
  /** Delete "VisaType" entities. */
  deleteVisaTypes: Array<VisaType>;
  /** Dismiss entities. */
  dismissClientNoteKeywordMatches: Array<ClientNoteKeywordMatch>;
  /** Dismiss a single claim. */
  dismissEventClaim: EventClaim;
  /** Duplicate "Event" entities. */
  duplicateEvents: Array<Event>;
  /** Email worked periods. */
  emailMemberClockedPeriods: BooleanResponse;
  /** Generate "MemberHour" entities. */
  generateMemberHours: Array<MemberHour>;
  /** Send invitations to join an organisation. */
  inviteMembers: Array<Member>;
  /** Link a member with the current user. */
  linkMember: Member;
  /** Log into an existing user account. */
  loginUser: UserLoginResponse;
  /** Process a single file. */
  processMemberUpload: MemberUpload;
  /** Resend user identifier verification token. */
  resendUserIdentifierVerification: Scalars['Boolean'];
  /** Initiate password-reset process for a user account. */
  resetUserPassword: Scalars['Boolean'];
  /** Restore deleted or archived "Branch" entities. */
  restoreBranches: Array<Branch>;
  /** Restore deleted "ClientContact" entities. */
  restoreClientContacts: Array<ClientContact>;
  /** Restore deleted or archived "ClientFile" entities. */
  restoreClientFiles: Array<ClientFile>;
  /** Restore deleted or archived entities. */
  restoreClientNdisPlanItemTransactionSuppliers: Array<ClientNdisPlanItemTransactionSupplier>;
  /** Restore entities. */
  restoreClientNdisPlanItemTransactions: Array<ClientNdisPlanItemTransaction>;
  /** Restore entities. */
  restoreClientNdisPlanItems: Array<ClientNdisPlanItem>;
  /** Restore entities. */
  restoreClientNdisPlans: Array<ClientNdisPlan>;
  /** Restore deleted or archived "ClientNoteFile" entities. */
  restoreClientNoteFiles: Array<ClientNoteFile>;
  /** Restore entities. */
  restoreClientNoteKeywords: Array<ClientNoteKeyword>;
  /** Restore entities. */
  restoreClientNotes: Array<ClientNote>;
  /** Restore deleted or archived "ClientObservation" entities. */
  restoreClientObservations: Array<ClientObservation>;
  /** Restore deleted or archived "Client" entities. */
  restoreClients: Array<Client>;
  /** Restore deleted or archived "EventFile" entities. */
  restoreEventFiles: Array<EventFile>;
  /** Restore "Event" entities. */
  restoreEvents: Array<Event>;
  /** Restore deleted or archived "Medication" entities. */
  restoreMedications: Array<Medication>;
  /** Restore deleted "MemberBasePayRate" entities. */
  restoreMemberBasePayRates: Array<MemberBasePayRate>;
  /** Restore deleted or archived "MemberFile" entities. */
  restoreMemberFiles: Array<MemberFile>;
  /** Restore deleted or archived "Member" entities. */
  restoreMembers: Array<Member>;
  /** Restore entities. */
  restoreNdisCoordinationClientActivities: Array<NdisCoordinationClientActivity>;
  /** Restore entities. */
  restoreNdisCoordinationClients: Array<NdisCoordinationClient>;
  /** Restore deleted or archived "Org" entities of which the current user is a member. */
  restoreOrgs: Array<Org>;
  /** Restore deleted or archived "PlanManager" entities. */
  restorePlanManagers: Array<PlanManager>;
  /** Restore deleted or archived "SupportCoordinator" entities. */
  restoreSupportCoordinators: Array<SupportCoordinator>;
  /** Restore deleted or archived "VisaType" entities. */
  restoreVisaTypes: Array<VisaType>;
  /** Revert updates to a "Branch" entity, providing the IDs of correlating "History" entities. */
  revertBranchUpdates: Array<Branch>;
  /** Revert updates to a "ClientContact" entity, providing the IDs of correlating "History" entities. */
  revertClientContactUpdates: Array<ClientContact>;
  /** Revert updates to a "Client" entity, providing the IDs of correlating "History" entities. */
  revertClientUpdates: Array<Client>;
  /** Revert updates to a "MemberBasePayRate" entity, providing the IDs of correlating "History" entities. */
  revertMemberBasePayRateUpdates: Array<MemberBasePayRate>;
  /** Revert updates to a "Member" entity, providing the IDs of correlating "History" entities. */
  revertMemberUpdates: Array<Member>;
  /** Revert updates to an "Org" entity of which the current user is a member, providing the IDs of correlating "History" entities. */
  revertOrgUpdates: Array<Org>;
  /** Revert updates to a "PlanManager" entity, providing the IDs of correlating "History" entities. */
  revertPlanManagerUpdates: Array<PlanManager>;
  /** Revert updates to a "SupportCoordinator" entity, providing the IDs of correlating "History" entities. */
  revertSupportCoordinatorUpdates: Array<SupportCoordinator>;
  /** Revert updates to an entity. */
  revertUpdateClientNdisPlanItems: Array<ClientNdisPlanItem>;
  /** Revert updates to an entity. */
  revertUpdateClientNdisPlans: Array<ClientNdisPlan>;
  /** Revert updates to an entity. */
  revertUpdateNdisCoordinationClient: Array<NdisCoordinationClient>;
  /** Set a client's medication. */
  setClientMedications: Array<ClientMedication>;
  /** Set which "Member" entities belong to a given "Client" entity's team. */
  setClientTeamMembers: Array<ClientTeam>;
  /** Set an entity. */
  setMemberPayrollCheck: MemberPayrollCheck;
  /** Update the currently logged-in user's active member profile. */
  setUserActiveMember: User;
  /** Using a password-reset token, set a new password for a user account. */
  setUserPassword: UserLoginResponse;
  /** Delete existing and unused invitations. */
  uninviteMember: MemberInvitation;
  /** Unlink members from their user entities. */
  unlinkMembers: Array<Member>;
  /** Update "Branch" entities. */
  updateBranches: Array<Branch>;
  /** Update a single "ClientContact" entity. */
  updateClientContact: ClientContact;
  /** Update "ClientFile" entities. */
  updateClientFiles: Array<ClientFile>;
  /** Update an entity. */
  updateClientNdisPlan: ClientNdisPlan;
  /** Update an entity. */
  updateClientNdisPlanItem: ClientNdisPlanItem;
  /** Update an entity. */
  updateClientNdisPlanItemTransaction: ClientNdisPlanItemTransaction;
  /** Update entity. */
  updateClientNdisPlanItemTransactionSupplier: ClientNdisPlanItemTransactionSupplier;
  /** Update entities. */
  updateClientNote: ClientNote;
  /** Update "ClientNoteFile" entities. */
  updateClientNoteFiles: Array<ClientNoteFile>;
  /** Update a singular entity. */
  updateClientNoteKeyword: ClientNoteKeyword;
  /** Update "ClientObservation" entities. */
  updateClientObservation: ClientObservation;
  /** Update "Client" entities. */
  updateClients: Array<Client>;
  /** Update "Event" entity. */
  updateEvent: Event;
  /** Update basic fields of an "Event" entity of which the current user is attending. */
  updateEventAttended: EventOwnAndRelated;
  /** Automatically assign an attendee to an "Event" entity. */
  updateEventAutomaticAttendee: Event;
  /** Update "EventFile" entities. */
  updateEventFiles: Array<EventFile>;
  /** Update "Medication" entities. */
  updateMedication: Medication;
  /** Update a single "MemberBasePayRate" entity. */
  updateMemberBasePayRate: MemberBasePayRate;
  /** Update a single "MemberBranchAllocation" entity. */
  updateMemberBranchAllocation: MemberBranchAllocation;
  /** Update the current users own "Member" entity. */
  updateMemberCurrent: Member;
  /** Update "MemberFile" entities. */
  updateMemberFiles: Array<MemberFile>;
  /** Update a single entity. */
  updateMemberPostNominal: MemberPostNominal;
  /** Update a "MemberUnavailable" entity. */
  updateMemberUnavailable: MemberUnavailable;
  /** Update "Member" entities. */
  updateMembers: Array<Member>;
  /** Update an entity. */
  updateNdisCoordinationClient: NdisCoordinationClient;
  /** Update an entity. */
  updateNdisCoordinationClientActivity: NdisCoordinationClientActivity;
  /** Update the users current "Org" entity. */
  updateOrg: Org;
  /** Update a "PayrollCategory" entity. */
  updatePayrollCategory: PayrollCategory;
  /** Update "PlanManager" entities. */
  updatePlanManagers: Array<PlanManager>;
  /** Update "SupportCoordinator" entities. */
  updateSupportCoordinators: Array<SupportCoordinator>;
  /** Update the currently logged-in user account. */
  updateUser: User;
  /** Update "VisaType" entities. */
  updateVisaType: VisaType;
  /** Verify event. */
  verifyEvents: Array<Event>;
  /** Verify user identififer. */
  verifyUserIdentifier: User;
};

export type MutationApproveEventsTravelArgs = {
  input: QueryByIdsInput;
};

export type MutationArchiveBranchesArgs = {
  input: ArchiveInput;
};

export type MutationArchiveClientNdisPlanItemTransactionSuppliersArgs = {
  input: ArchiveInput;
};

export type MutationArchiveClientNdisPlanItemTransactionsArgs = {
  input: ArchiveInput;
};

export type MutationArchiveClientsArgs = {
  input: ArchiveInput;
};

export type MutationArchiveEventsArgs = {
  input: ArchiveInput;
};

export type MutationArchiveMembersArgs = {
  input: ArchiveInput;
};

export type MutationArchiveNdisCoordinationClientsArgs = {
  input: ArchiveInput;
};

export type MutationArchiveOrgsArgs = {
  input: ArchiveInput;
};

export type MutationArchivePlanManagersArgs = {
  input: ArchiveInput;
};

export type MutationArchiveSupportCoordinatorsArgs = {
  input: ArchiveInput;
};

export type MutationCancelEventsArgs = {
  input: EventsCancelInput;
};

export type MutationClaimEventArgs = {
  id: Scalars['ID'];
};

export type MutationClockEventArgs = {
  input: ClockEventInput;
};

export type MutationClockEventAttendedArgs = {
  input: ClockEventInput;
};

export type MutationCloseEventIssueArgs = {
  id: Scalars['ID'];
};

export type MutationCreateBranchesArgs = {
  input: CreateBranchesInput;
};

export type MutationCreateClientContactArgs = {
  input: CreateClientContactInput;
};

export type MutationCreateClientFileArgs = {
  input: CreateClientFileInput;
};

export type MutationCreateClientNdisPlanArgs = {
  input: CreateClientNdisPlanInput;
};

export type MutationCreateClientNdisPlanItemArgs = {
  input: CreateClientNdisPlanItemInput;
};

export type MutationCreateClientNdisPlanItemTransactionArgs = {
  input: CreateClientNdisPlanItemTransactionInput;
};

export type MutationCreateClientNdisPlanItemTransactionSupplierArgs = {
  input: CreateClientNdisPlanItemTransactionSupplierInput;
};

export type MutationCreateClientNdisPlanItemTransactionsArgs = {
  input: CreateClientNdisPlanItemTransactionsInput;
};

export type MutationCreateClientNoteArgs = {
  input: CreateClientNoteInput;
};

export type MutationCreateClientNoteFileArgs = {
  input: CreateClientNoteFileInput;
};

export type MutationCreateClientNoteKeywordArgs = {
  input: CreateClientNoteKeywordInput;
};

export type MutationCreateClientNoteKeywordsArgs = {
  input: CreateClientNoteKeywordsInput;
};

export type MutationCreateClientNotesArgs = {
  input: CreateClientNotesInput;
};

export type MutationCreateClientObservationArgs = {
  input: CreateClientObservationInput;
};

export type MutationCreateClientObservationWithEventArgs = {
  input: CreateClientObservationWithEventInput;
};

export type MutationCreateClientsArgs = {
  input: CreateClientsInput;
};

export type MutationCreateEventArgs = {
  input: CreateEventInput;
};

export type MutationCreateEventFileArgs = {
  input: CreateEventFileInput;
};

export type MutationCreateEventIssueArgs = {
  input: CreateEventIssueInput;
};

export type MutationCreateMedicationArgs = {
  input: CreateMedicationInput;
};

export type MutationCreateMemberBasePayRateArgs = {
  input: CreateMemberBasePayRateInput;
};

export type MutationCreateMemberBranchAllocationArgs = {
  input: CreateMemberBranchAllocationInput;
};

export type MutationCreateMemberFileArgs = {
  input: CreateMemberFileInput;
};

export type MutationCreateMemberPostNominalArgs = {
  input: CreateMemberPostNominalInput;
};

export type MutationCreateMemberScheduleTimeLimitsArgs = {
  input: CreateMemberScheduleTimeLimitsInput;
};

export type MutationCreateMemberUnavailablesArgs = {
  input: CreateMemberUnavailablesInput;
};

export type MutationCreateMemberUploadArgs = {
  input: CreateMemberUploadInput;
};

export type MutationCreateMembersArgs = {
  input: CreateMembersInput;
};

export type MutationCreateNdisCoordinationClientArgs = {
  input: CreateNdisCoordinationClientInput;
};

export type MutationCreateNdisCoordinationClientActivityArgs = {
  input: CreateNdisCoordinationClientActivityInput;
};

export type MutationCreateOrgArgs = {
  input: CreateOrgInput;
};

export type MutationCreatePayrollCategoryArgs = {
  input: CreatePayrollCategoryInput;
};

export type MutationCreatePlanManagersArgs = {
  input: CreatePlanManagersInput;
};

export type MutationCreateSupportCoordinatorsArgs = {
  input: CreateSupportCoordinatorsInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateVisaTypeArgs = {
  input: CreateVisaTypeInput;
};

export type MutationDeleteBranchesArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientContactsArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientFilesArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientNdisPlanItemTransactionsArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientNdisPlanItemsArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientNdisPlansArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientNoteFilesArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientNoteKeywordsArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientNotesArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientObservationsArgs = {
  input: DeleteInput;
};

export type MutationDeleteClientsArgs = {
  input: DeleteInput;
};

export type MutationDeleteEventFilesArgs = {
  input: DeleteInput;
};

export type MutationDeleteEventsArgs = {
  input: DeleteInput;
};

export type MutationDeleteMedicationsArgs = {
  input: DeleteInput;
};

export type MutationDeleteMemberBasePayRatesArgs = {
  input: DeleteInput;
};

export type MutationDeleteMemberBranchAllocationArgs = {
  input: QueryByIdInput;
};

export type MutationDeleteMemberFilesArgs = {
  input: DeleteInput;
};

export type MutationDeleteMemberPostNominalArgs = {
  input: QueryByIdInput;
};

export type MutationDeleteMemberUnavailablesArgs = {
  input: QueryByIdsInput;
};

export type MutationDeleteMembersArgs = {
  input: DeleteInput;
};

export type MutationDeleteNdisCoordinationClientActivitiesArgs = {
  input: DeleteInput;
};

export type MutationDeleteOrgsArgs = {
  input: DeleteInput;
};

export type MutationDeletePlanManagersArgs = {
  input: DeleteInput;
};

export type MutationDeleteSupportCoordinatorsArgs = {
  input: DeleteInput;
};

export type MutationDeleteVisaTypesArgs = {
  input: DeleteInput;
};

export type MutationDismissClientNoteKeywordMatchesArgs = {
  input: DismissClientNoteKeywordMatchesInput;
};

export type MutationDismissEventClaimArgs = {
  id: Scalars['ID'];
};

export type MutationDuplicateEventsArgs = {
  input: DuplicateEventsInput;
};

export type MutationEmailMemberClockedPeriodsArgs = {
  input: QueryMemberClockedPeriodsInput;
};

export type MutationGenerateMemberHoursArgs = {
  input: GenerateMemberHoursInput;
};

export type MutationInviteMembersArgs = {
  input: QueryByIdsInput;
};

export type MutationLinkMemberArgs = {
  input: LinkMemberInput;
};

export type MutationLoginUserArgs = {
  input: LoginUserInput;
};

export type MutationProcessMemberUploadArgs = {
  input: ProcessMemberUploadInput;
};

export type MutationResendUserIdentifierVerificationArgs = {
  input: ResendUserIdentifierVerificationInput;
};

export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type MutationRestoreBranchesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientContactsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientFilesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientNdisPlanItemTransactionSuppliersArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientNdisPlanItemTransactionsArgs = {
  input: RestoreInput;
};

export type MutationRestoreClientNdisPlanItemsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientNdisPlansArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientNoteFilesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientNoteKeywordsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientNotesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientObservationsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreClientsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreEventFilesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreEventsArgs = {
  input: RestoreInput;
};

export type MutationRestoreMedicationsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreMemberBasePayRatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreMemberFilesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreMembersArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreNdisCoordinationClientActivitiesArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreNdisCoordinationClientsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreOrgsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestorePlanManagersArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreSupportCoordinatorsArgs = {
  input: QueryByIdsInput;
};

export type MutationRestoreVisaTypesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertBranchUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertClientContactUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertClientUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertMemberBasePayRateUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertMemberUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertOrgUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertPlanManagerUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertSupportCoordinatorUpdatesArgs = {
  input: QueryByIdsInput;
};

export type MutationRevertUpdateClientNdisPlanItemsArgs = {
  input: QueryByIdInput;
};

export type MutationRevertUpdateClientNdisPlansArgs = {
  input: QueryByIdInput;
};

export type MutationRevertUpdateNdisCoordinationClientArgs = {
  input: QueryByIdsInput;
};

export type MutationSetClientMedicationsArgs = {
  input: SetClientMedicationsInput;
};

export type MutationSetClientTeamMembersArgs = {
  input: SetClientTeamMembersInput;
};

export type MutationSetMemberPayrollCheckArgs = {
  input: SetMemberPayrollCheckInput;
};

export type MutationSetUserActiveMemberArgs = {
  id: Scalars['ID'];
};

export type MutationSetUserPasswordArgs = {
  input: SetUserPasswordInput;
};

export type MutationUninviteMemberArgs = {
  input: QueryByIdInput;
};

export type MutationUnlinkMembersArgs = {
  input: QueryByIdsInput;
};

export type MutationUpdateBranchesArgs = {
  input: UpdateBranchesInput;
};

export type MutationUpdateClientContactArgs = {
  input: UpdateClientContactInput;
};

export type MutationUpdateClientFilesArgs = {
  input: UpdateClientFilesInput;
};

export type MutationUpdateClientNdisPlanArgs = {
  input: UpdateClientNdisPlanInput;
};

export type MutationUpdateClientNdisPlanItemArgs = {
  input: UpdateClientNdisPlanItemInput;
};

export type MutationUpdateClientNdisPlanItemTransactionArgs = {
  input: UpdateClientNdisPlanItemTransactionInput;
};

export type MutationUpdateClientNdisPlanItemTransactionSupplierArgs = {
  input: UpdateClientNdisPlanItemTransactionSupplierInput;
};

export type MutationUpdateClientNoteArgs = {
  input: UpdateClientNoteInput;
};

export type MutationUpdateClientNoteFilesArgs = {
  input: UpdateClientNoteFilesInput;
};

export type MutationUpdateClientNoteKeywordArgs = {
  input: UpdateClientNoteKeywordInput;
};

export type MutationUpdateClientObservationArgs = {
  input: UpdateClientObservationInput;
};

export type MutationUpdateClientsArgs = {
  input: UpdateClientsInput;
};

export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};

export type MutationUpdateEventAttendedArgs = {
  input: UpdateEventBasicFieldsInput;
};

export type MutationUpdateEventAutomaticAttendeeArgs = {
  input: QueryByIdInput;
};

export type MutationUpdateEventFilesArgs = {
  input: UpdateEventFilesInput;
};

export type MutationUpdateMedicationArgs = {
  input: UpdateMedicationInput;
};

export type MutationUpdateMemberBasePayRateArgs = {
  input: UpdateMemberBasePayRateInput;
};

export type MutationUpdateMemberBranchAllocationArgs = {
  input: UpdateMemberBranchAllocationInput;
};

export type MutationUpdateMemberCurrentArgs = {
  input: UpdateMemberCurrentInput;
};

export type MutationUpdateMemberFilesArgs = {
  input: UpdateMemberFilesInput;
};

export type MutationUpdateMemberPostNominalArgs = {
  input: UpdateMemberPostNominalInput;
};

export type MutationUpdateMemberUnavailableArgs = {
  input: UpdateMemberUnavailableInput;
};

export type MutationUpdateMembersArgs = {
  input: UpdateMembersInput;
};

export type MutationUpdateNdisCoordinationClientArgs = {
  input: UpdateNdisCoordinationClientInput;
};

export type MutationUpdateNdisCoordinationClientActivityArgs = {
  input: UpdateNdisCoordinationClientActivityInput;
};

export type MutationUpdateOrgArgs = {
  input: UpdateOrgInput;
};

export type MutationUpdatePayrollCategoryArgs = {
  input: UpdatePayrollCategoryInput;
};

export type MutationUpdatePlanManagersArgs = {
  input: UpdatePlanManagersInput;
};

export type MutationUpdateSupportCoordinatorsArgs = {
  input: UpdateSupportCoordinatorsInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateVisaTypeArgs = {
  input: UpdateVisaTypeInput;
};

export type MutationVerifyEventsArgs = {
  input: VerifyEventsInput;
};

export type MutationVerifyUserIdentifierArgs = {
  input: VerifyUserIdentifierInput;
};

/** Aggregated response object for "NdisActivity" entities. */
export type NdisActivitiesAggregate = {
  __typename?: 'NdisActivitiesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<NdisActivity>;
};

/** Ordering options for "NdisActivity" entities. */
export type NdisActivitiesOrderByInput = {
  /** Order by code. */
  code?: InputMaybe<OrderBy>;
  /** Order by description. */
  description?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by national rate. */
  rateNational?: InputMaybe<OrderBy>;
  /** Order by remote rate. */
  rateRemote?: InputMaybe<OrderBy>;
  /** Order by very remote rate. */
  rateVeryRemote?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "NdisActivity" entities. */
export type NdisActivitiesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<NdisActivitiesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<NdisActivitiesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<NdisActivitiesWhereInput>>;
  /** Code. */
  code?: InputMaybe<StringConditionalInput>;
  /** Description. */
  description?: InputMaybe<StringConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** National rate. */
  rateNational?: InputMaybe<IntConditionalInput>;
  /** Remote rate. */
  rateRemote?: InputMaybe<IntConditionalInput>;
  /** Very remote rate. */
  rateVeryRemote?: InputMaybe<IntConditionalInput>;
};

/** A NDIS activity. */
export type NdisActivity = {
  __typename?: 'NdisActivity';
  /** Activity ID. */
  code: Scalars['String'];
  /** Description. */
  description: Scalars['String'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** National rate. */
  rateNational: Scalars['Int'];
  /** Remote rate. */
  rateRemote: Scalars['Int'];
  /** Very remote rate. */
  rateVeryRemote: Scalars['Int'];
};

/** An NDIS support coordination client. */
export type NdisCoordinationClient = Address &
  Person & {
    __typename?: 'NdisCoordinationClient';
    /** Archive data, if entity is archived. */
    archive?: Maybe<HistoryRestorable>;
    /** Biography. */
    bio?: Maybe<Scalars['String']>;
    /** Branch to which this client belongs. */
    branch: Branch;
    /** Building name. */
    buildingName?: Maybe<Scalars['String']>;
    /** Hexidecimal color. */
    color?: Maybe<Scalars['HexColorCode']>;
    /** Address country. */
    country?: Maybe<AddressCountryCode>;
    /** Datetime of the entity's creation. */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Creator of the entity. */
    createdBy?: Maybe<Member>;
    /** Date of birth. */
    dob?: Maybe<Scalars['Date']>;
    /** Email address. */
    email?: Maybe<Scalars['String']>;
    /** External ID. */
    externalId?: Maybe<Scalars['String']>;
    /** Date of first activity */
    firstActivityDate?: Maybe<Scalars['DateTime']>;
    /** First name. */
    firstName: Scalars['String'];
    /** Gender. */
    gender?: Maybe<Gender>;
    /** Unique identifier of the entity. */
    id: Scalars['ID'];
    /** Date of most-recently started activity */
    lastActivityDate?: Maybe<Scalars['DateTime']>;
    /** Last name. */
    lastName?: Maybe<Scalars['String']>;
    /** Address latitude coordinate. */
    latitude?: Maybe<Scalars['String']>;
    /** Address suburb or town. */
    locality?: Maybe<Scalars['String']>;
    /** Address longitude coordinate. */
    longitude?: Maybe<Scalars['String']>;
    /** Middle name. */
    middleName?: Maybe<Scalars['String']>;
    /** Minutes between first and last activities. */
    minutesBetweenFirstAndLastActivities?: Maybe<Scalars['Int']>;
    /** NDIS participant ID. */
    ndisId?: Maybe<Scalars['NdisId']>;
    /** Organisation to which this client belongs. */
    org: Org;
    /** Phone number. */
    phone?: Maybe<Scalars['String']>;
    /** Plan manager for the client. */
    planManager?: Maybe<PlanManager>;
    /** Address postcode. */
    postcode?: Maybe<Scalars['PostalCode']>;
    /** Preferred contact. */
    preferredContact?: Maybe<ClientContact>;
    /** Preferred name. */
    preferredName?: Maybe<Scalars['String']>;
    /** Pronouns. */
    pronouns?: Maybe<Pronouns>;
    /** Address state or territory. */
    region?: Maybe<AddressRegionCode>;
    /** Salutation. */
    salutation?: Maybe<Salutation>;
    /** Address street number, name and type. */
    street?: Maybe<Scalars['String']>;
    /** Timezone. */
    timezone: Timezone;
    /** Accumulated activity minutes. */
    totalActivityMinutes?: Maybe<Scalars['Int']>;
    /** Address unit number. */
    unit?: Maybe<Scalars['String']>;
    /** Datetime of the entity record's most recent update. */
    updatedAt?: Maybe<Scalars['DateTime']>;
  };

export type NdisCoordinationClientActivity = Address & {
  __typename?: 'NdisCoordinationClientActivity';
  /** Building name. */
  buildingName?: Maybe<Scalars['String']>;
  /** Client linked with the event. */
  client: NdisCoordinationClient;
  /** Country. */
  country?: Maybe<AddressCountryCode>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Start datetime of the event. */
  date: Scalars['DateTime'];
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Duration of the event in minutes. */
  duration: Scalars['Int'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Latitude coordinate. */
  latitude?: Maybe<Scalars['String']>;
  /** Suburb or town. */
  locality?: Maybe<Scalars['String']>;
  /** Longitude coordinate. */
  longitude?: Maybe<Scalars['String']>;
  /** Member linked with the event. */
  member: Member;
  /** Detailed information concering the event. */
  notes?: Maybe<Scalars['String']>;
  /** Postcode. */
  postcode?: Maybe<Scalars['PostalCode']>;
  /** State or territory. */
  region?: Maybe<AddressRegionCode>;
  /** Street number, name and type. */
  street?: Maybe<Scalars['String']>;
  /** Unit number. */
  unit?: Maybe<Scalars['String']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object. */
export type NdisCoordinationClientActivityAggregate = {
  __typename?: 'NdisCoordinationClientActivityAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<NdisCoordinationClientActivity>;
};

/** Ordering options. */
export type NdisCoordinationClientActivityOrderByInput = {
  client?: InputMaybe<ClientsOrderByInput>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<MembersOrderByInput>;
  duration?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  member?: InputMaybe<MembersOrderByInput>;
  notes?: InputMaybe<OrderBy>;
  startAt?: InputMaybe<OrderBy>;
};

/** Updatable fields. */
export type NdisCoordinationClientActivityUpdatableFieldsInput = {
  /** Client input data. */
  client?: InputMaybe<QueryByIdInput>;
  /** Start datetime of the event. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** Duration of the event in minutes. */
  duration?: InputMaybe<Scalars['Int']>;
  /** Member input data. */
  member?: InputMaybe<QueryByIdInput>;
  /** Detailed information concering the event. */
  notes?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions. */
export type NdisCoordinationClientActivityWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<NdisCoordinationClientActivityWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<NdisCoordinationClientActivityWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<NdisCoordinationClientActivityWhereInput>>;
  client?: InputMaybe<ClientsWhereInput>;
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  createdBy?: InputMaybe<MembersWhereInput>;
  date?: InputMaybe<DateTimeConditionalInput>;
  delete?: InputMaybe<HistoriesWhereInput>;
  duration?: InputMaybe<IntConditionalInput>;
  id?: InputMaybe<IdConditionalInput>;
  member?: InputMaybe<MembersWhereInput>;
  notes?: InputMaybe<StringConditionalInput>;
};

/** Aggregated response object. */
export type NdisCoordinationClientAggregate = {
  __typename?: 'NdisCoordinationClientAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<NdisCoordinationClient>;
};

/** Ordering options. */
export type NdisCoordinationClientOrderByInput = {
  activities?: InputMaybe<NdisCoordinationClientActivityOrderByInput>;
  branch?: InputMaybe<BranchesOrderByInput>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<MembersOrderByInput>;
  dob?: InputMaybe<OrderBy>;
  firstActivityDate?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  lastActivityDate?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  locality?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  middleName?: InputMaybe<OrderBy>;
  minutesBetweenFirstAndLastActivities?: InputMaybe<OrderBy>;
  planManager?: InputMaybe<PlanManagersOrderByInput>;
  postcode?: InputMaybe<OrderBy>;
  preferredName?: InputMaybe<OrderBy>;
  region?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
  totalActivityMinutes?: InputMaybe<OrderBy>;
  unit?: InputMaybe<OrderBy>;
};

/** Updatable fields. */
export type NdisCoordinationClientUpdatableFieldsInput = {
  /** Biography. */
  bio?: InputMaybe<Scalars['String']>;
  /** Branch input data. */
  branch?: InputMaybe<QueryByIdInput>;
  /** Building name. */
  buildingName?: InputMaybe<Scalars['String']>;
  /** Hexidecimal color. */
  color?: InputMaybe<Scalars['HexColorCode']>;
  /** Address country. */
  country?: InputMaybe<AddressCountryCode>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** External ID. */
  externalId?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address latitude coordinate. */
  latitude?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Address longitude coordinate. */
  longitude?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** NDIS participant ID. */
  ndisId?: InputMaybe<Scalars['NdisId']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** PlanManager input data. */
  planManager?: InputMaybe<QueryByIdInput>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** Timezone. */
  timezone?: InputMaybe<Timezone>;
  /** Address unit number. */
  unit?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions. */
export type NdisCoordinationClientWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<NdisCoordinationClientWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<NdisCoordinationClientWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<NdisCoordinationClientWhereInput>>;
  activities?: InputMaybe<NdisCoordinationClientActivityWhereInput>;
  branch?: InputMaybe<BranchesWhereInput>;
  color?: InputMaybe<StringConditionalInput>;
  country?: InputMaybe<StringConditionalInput>;
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  createdBy?: InputMaybe<MembersWhereInput>;
  dob?: InputMaybe<DateConditionalInput>;
  email?: InputMaybe<StringConditionalInput>;
  firstName?: InputMaybe<StringConditionalInput>;
  gender?: InputMaybe<IntConditionalInput>;
  id?: InputMaybe<IdConditionalInput>;
  lastName?: InputMaybe<StringConditionalInput>;
  latitude?: InputMaybe<StringConditionalInput>;
  locality?: InputMaybe<StringConditionalInput>;
  longitude?: InputMaybe<StringConditionalInput>;
  middleName?: InputMaybe<StringConditionalInput>;
  ndisId?: InputMaybe<NdisIdConditionalInput>;
  phone?: InputMaybe<PhoneConditionalInput>;
  planManager?: InputMaybe<PlanManagersWhereInput>;
  postcode?: InputMaybe<StringConditionalInput>;
  preferredName?: InputMaybe<StringConditionalInput>;
  pronouns?: InputMaybe<IntConditionalInput>;
  region?: InputMaybe<StringConditionalInput>;
  salutation?: InputMaybe<IntConditionalInput>;
  street?: InputMaybe<StringConditionalInput>;
  unit?: InputMaybe<StringConditionalInput>;
};

/** NDIS participant ID conditional expression input. */
export type NdisIdConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['NdisId']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['NdisId']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['NdisId']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['NdisId']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['NdisId']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['NdisId']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['NdisId']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['NdisId']>>;
};

/** A NDIS registration group. */
export type NdisRegistrationGroup = {
  __typename?: 'NdisRegistrationGroup';
  /** ID. */
  id: Scalars['ID'];
  /** Name. */
  name: Scalars['String'];
  supportItems: Array<NdisSupportItem>;
};

/** A NDIS registration group. */
export type NdisRegistrationGroupSupportItemsArgs = {
  input?: InputMaybe<QueryNdisSupportItemsInput>;
};

/** Aggregated response object for "NdisRegistrationGroup" entities. */
export type NdisRegistrationGroupsAggregate = {
  __typename?: 'NdisRegistrationGroupsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<NdisRegistrationGroup>;
};

/** Ordering options for "NdisRegistrationGroup" entities. */
export type NdisRegistrationGroupsOrderByInput = {
  /** Order by ID. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "NdisRegistrationGroup" entities. */
export type NdisRegistrationGroupsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<NdisRegistrationGroupsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<NdisRegistrationGroupsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<NdisRegistrationGroupsWhereInput>>;
  /** Primary identifier key. */
  id?: InputMaybe<IntConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
};

/** Aggregated response object for "NdisSupportCategory" entities. */
export type NdisSupportCategoriesAggregate = {
  __typename?: 'NdisSupportCategoriesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<NdisSupportCategory>;
};

/** Ordering options for "NdisSupportCategory" entities. */
export type NdisSupportCategoriesOrderByInput = {
  /** Order by ID. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "NdisSupportCategory" entities. */
export type NdisSupportCategoriesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<NdisSupportCategoriesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<NdisSupportCategoriesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<NdisSupportCategoriesWhereInput>>;
  /** Primary identifier key. */
  id?: InputMaybe<IntConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
};

/** A NDIS support category. */
export type NdisSupportCategory = {
  __typename?: 'NdisSupportCategory';
  /** ID. */
  id: Scalars['ID'];
  /** Name. */
  name: Scalars['String'];
};

/** A NDIS support item. */
export type NdisSupportItem = {
  __typename?: 'NdisSupportItem';
  /** ACT. */
  act?: Maybe<Scalars['Int']>;
  /** End date. */
  endDate: Scalars['Date'];
  /** ID. */
  id: Scalars['ID'];
  /** Irregular SIL supports. */
  irregularSilSupports?: Maybe<Scalars['Boolean']>;
  /** Name. */
  name: Scalars['String'];
  /** NDIA requested reports. */
  ndiaRequestedReports?: Maybe<Scalars['Boolean']>;
  /** Non-face-to-face support provision. */
  nonFaceToFaceSupportProvision?: Maybe<Scalars['Boolean']>;
  /** NSW. */
  nsw?: Maybe<Scalars['Int']>;
  /** NT. */
  nt?: Maybe<Scalars['Int']>;
  /** P01. */
  p01?: Maybe<Scalars['Int']>;
  /** P02. */
  p02?: Maybe<Scalars['Int']>;
  /** Provider travel. */
  providerTravel?: Maybe<Scalars['Boolean']>;
  /** QLD. */
  qld?: Maybe<Scalars['Int']>;
  /** Quote. */
  quote: Scalars['Boolean'];
  /** Registration group. */
  registrationGroup: NdisRegistrationGroup;
  /** SA. */
  sa?: Maybe<Scalars['Int']>;
  /** Short notice cancellations. */
  shortNoticeCancellations?: Maybe<Scalars['Boolean']>;
  /** Start date. */
  startDate: Scalars['Date'];
  /** Support category. */
  supportCategory: NdisSupportCategory;
  /** TAS. */
  tas?: Maybe<Scalars['Int']>;
  /** Type. */
  type?: Maybe<Scalars['NdisSupportItemTypeScaler']>;
  /** Unit. */
  unit: Scalars['NdisSupportItemUnitScaler'];
  /** VIC. */
  vic?: Maybe<Scalars['Int']>;
  /** WA. */
  wa?: Maybe<Scalars['Int']>;
};

/** Aggregated response object for "NdisSupportItem" entities. */
export type NdisSupportItemsAggregate = {
  __typename?: 'NdisSupportItemsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<NdisSupportItem>;
};

/** Ordering options for "NdisSupportItem" entities. */
export type NdisSupportItemsOrderByInput = {
  /** Order by ID. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "NdisSupportItem" entities. */
export type NdisSupportItemsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<NdisSupportItemsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<NdisSupportItemsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<NdisSupportItemsWhereInput>>;
  /** Primary identifier key. */
  id?: InputMaybe<StringConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
};

export type Notification = {
  __typename?: 'Notification';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Datetime when notification was dismissed. */
  dismissedAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  recipient: Member;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for "Notification" entities. */
export type NotificationsAggregate = {
  __typename?: 'NotificationsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Notification>;
};

/** Ordering options for "Notification" entities. */
export type NotificationsOrderByInput = {
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "Notification" entities. */
export type NotificationsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<NotificationsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<NotificationsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<NotificationsWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
};

export enum OrderBy {
  ASC = 'ASC',
  ASC_NULLS_FIRST = 'ASC_NULLS_FIRST',
  ASC_NULLS_LAST = 'ASC_NULLS_LAST',
  DESC = 'DESC',
  DESC_NULLS_FIRST = 'DESC_NULLS_FIRST',
  DESC_NULLS_LAST = 'DESC_NULLS_LAST',
}

/** An organisation. */
export type Org = Business & {
  __typename?: 'Org';
  /** Australian Business Number of the organisation */
  abn?: Maybe<Scalars['ABN']>;
  /** Administrators of the organisation. */
  admins?: Maybe<Array<Member>>;
  /** Afternoon pay multiplier. */
  afternoonPayMultiplier?: Maybe<Scalars['Int']>;
  /** Allowed duration between conflicts in minutes. */
  allowedDurationBetweenConflicts?: Maybe<Scalars['Int']>;
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Automatically lock clocked-off events after a number of hours */
  autoArchiveClockedOffEventsAfterNumberOfHours?: Maybe<Scalars['Int']>;
  /** Base pay rate. */
  basePayRate?: Maybe<Scalars['Int']>;
  /** Billing rates. */
  billing: OrgBilling;
  /** Bonus pay rate 2. */
  bonus2PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 3. */
  bonus3PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 4. */
  bonus4PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 5. */
  bonus5PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 6. */
  bonus6PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 7. */
  bonus7PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 8. */
  bonus8PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 9. */
  bonus9PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 10. */
  bonus10PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 11. */
  bonus11PayRate?: Maybe<Scalars['Int']>;
  /** Bonus pay rate 12. */
  bonus12PayRate?: Maybe<Scalars['Int']>;
  /** Branches belonging to the organisation. */
  branches: Array<Branch>;
  /** Days in advance a member can claim an event. */
  claimEventsDayRange?: Maybe<Scalars['Int']>;
  clientDocumentTrackerCollapsedFiles?: Maybe<Array<ClientFileType>>;
  clientDocumentTrackerVisibleFiles?: Maybe<Array<ClientFileType>>;
  /** Warning threshold for early/late clock-off times. */
  clockOffWarnMinutes?: Maybe<Scalars['Int']>;
  /** Warning threshold for early/late clock-on times. */
  clockOnWarnMinutes?: Maybe<Scalars['Int']>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Email address */
  email?: Maybe<Scalars['EmailAddress']>;
  fileStorageStatistics: FileStorageStatistics;
  /** Fixed expenses */
  fixedExpenses?: Maybe<Scalars['String']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Ideas. */
  ideas?: Maybe<Scalars['String']>;
  /** Kilometre allowance. */
  kmAllowance?: Maybe<Scalars['Int']>;
  /** Legal name */
  legalName: Scalars['String'];
  /** Maximum member lateness percentage for automatic handover event allocation. */
  maxMemberLatenessForAutomaticHandoverEvents?: Maybe<Scalars['Int']>;
  /** Maximum member lateness percentage for automatic public-holiday event allocation. */
  maxMemberLatenessForAutomaticPublicHolidayEvents?: Maybe<Scalars['Int']>;
  /** Maximum member lateness percentage for automatic Saturday event allocation. */
  maxMemberLatenessForAutomaticSaturdayEvents?: Maybe<Scalars['Int']>;
  /** Maximum member lateness percentage for automatic Sunday event allocation. */
  maxMemberLatenessForAutomaticSundayEvents?: Maybe<Scalars['Int']>;
  memberDocumentTrackerCollapsedFiles?: Maybe<Array<MemberFileType>>;
  memberDocumentTrackerVisibleFiles?: Maybe<Array<MemberFileType>>;
  memberUploads: Array<MemberUpload>;
  /** Members can see clocked times. */
  membersCanSeeClockedTimes?: Maybe<Scalars['Boolean']>;
  ndisRegistrationGroups: Array<NdisRegistrationGroup>;
  /** Night pay multiplier. */
  nightPayMultiplier?: Maybe<Scalars['Int']>;
  /** Passive-overnight allowance. */
  passiveAllowance?: Maybe<Scalars['Int']>;
  /** Payroll categories belonging to the organisation. */
  payrollCategories: Array<PayrollCategory>;
  /** Phone number */
  phone?: Maybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** Public holiday pay multiplier. */
  pubPayMultiplier?: Maybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely after thier own shift. */
  quantityOfFutureShiftsMembersCanSee?: Maybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely before thier own shift. */
  quantityOfPastShiftsMembersCanSee?: Maybe<Scalars['Int']>;
  /** Saturday pay multiplier. */
  satPayMultiplier?: Maybe<Scalars['Int']>;
  /** Default maximum schedulable minutes per day. */
  scheduleMaxDayTimeLimitDefault?: Maybe<Scalars['Int']>;
  /** Maximum schedulable minutes per week. */
  scheduleMaxWeekTimeLimit?: Maybe<Scalars['Int']>;
  /** Default maximum schedulable minutes per week. */
  scheduleMaxWeekTimeLimitDefault?: Maybe<Scalars['Int']>;
  /** Scheduling contact person. */
  schedulingContactPerson?: Maybe<Member>;
  /** Second scheduling contact person. */
  schedulingContactPerson2?: Maybe<Member>;
  /** Sunday pay multiplier. */
  sunPayMultiplier?: Maybe<Scalars['Int']>;
  /** Enable task: Assign Attendee. */
  taskAssignAttendeeEnable?: Maybe<Scalars['Boolean']>;
  /** Days in the future to assign attendees. */
  taskAssignAttendeeFutureDays?: Maybe<Scalars['Int']>;
  /** Weeks in the past to find attendees. */
  taskAssignAttendeePastWeeks?: Maybe<Scalars['Int']>;
  /** Allow travel time to be assigned to subsequent events for the same client. */
  travelTimeAllowSameClient?: Maybe<Scalars['Boolean']>;
  /** Allowed duration between events for travel time eligibility. */
  travelTimeAllowedDurationBetweenEvents?: Maybe<Scalars['Int']>;
  /** Days in advance to check for unfilled shifts. */
  unfilledEventsDayRange?: Maybe<Scalars['Int']>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Website of the organisation */
  website?: Maybe<Scalars['String']>;
};

/** An organisation. */
export type OrgAdminsArgs = {
  input?: InputMaybe<QueryMembersInput>;
};

/** An organisation. */
export type OrgBranchesArgs = {
  input?: InputMaybe<QueryBranchesInput>;
};

/** An organisation. */
export type OrgMemberUploadsArgs = {
  input: QueryMemberUploadsInput;
};

/** An organisation. */
export type OrgNdisRegistrationGroupsArgs = {
  input?: InputMaybe<QueryNdisRegistrationGroupsInput>;
};

/** An organisation. */
export type OrgPayrollCategoriesArgs = {
  input?: InputMaybe<QueryPayrollCategoriesInput>;
};

/** Billing rates for an organisation. */
export type OrgBilling = {
  __typename?: 'OrgBilling';
  /** Afternoon rate */
  afternoon?: Maybe<Scalars['Int']>;
  /** Day rate */
  day?: Maybe<Scalars['Int']>;
  /** KM rate */
  kms?: Maybe<Scalars['Int']>;
  /** Night rate */
  night?: Maybe<Scalars['Int']>;
  /** Overnight rate */
  overnight?: Maybe<Scalars['Int']>;
  /** Public holiday rate */
  publicHoliday?: Maybe<Scalars['Int']>;
  /** Saturday rate */
  saturday?: Maybe<Scalars['Int']>;
  /** Sunday rate */
  sunday?: Maybe<Scalars['Int']>;
};

/** Aggregated response object for "Org" entities. */
export type OrgsAggregate = {
  __typename?: 'OrgsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<Org>;
};

/** Ordering options for "Org" entities. */
export type OrgsOrderByInput = {
  /** Order by Australian Business Number. */
  abn?: InputMaybe<OrderBy>;
  /** Order by datetime of entity creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by email address. */
  email?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by legal name. */
  legalName?: InputMaybe<OrderBy>;
  /** Order by phone number. */
  phone?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
  /** Order by website. */
  website?: InputMaybe<OrderBy>;
};

/** Updatable fields for "Org" entities. */
export type OrgsUpdatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Afternoon pay multiplier. */
  afternoonPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Allowed duration between conflicts in minutes. */
  allowedDurationBetweenConflicts?: InputMaybe<Scalars['Int']>;
  /** Automatically lock clocked-off events after a number of hours */
  autoArchiveClockedOffEventsAfterNumberOfHours?: InputMaybe<Scalars['Int']>;
  /** Base pay rate. */
  basePayRate?: InputMaybe<Scalars['Int']>;
  /** Entity input data. */
  billing?: InputMaybe<SetOrgBillingInput>;
  /** Bonus pay rate 2. */
  bonus2PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 3. */
  bonus3PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 4. */
  bonus4PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 5. */
  bonus5PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 6. */
  bonus6PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 7. */
  bonus7PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 8. */
  bonus8PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 9. */
  bonus9PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 10. */
  bonus10PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 11. */
  bonus11PayRate?: InputMaybe<Scalars['Int']>;
  /** Bonus pay rate 12. */
  bonus12PayRate?: InputMaybe<Scalars['Int']>;
  /** Days in advance a member can claim an event. */
  claimEventsDayRange?: InputMaybe<Scalars['Int']>;
  clientDocumentTrackerCollapsedFiles?: InputMaybe<Array<ClientFileType>>;
  clientDocumentTrackerVisibleFiles?: InputMaybe<Array<ClientFileType>>;
  /** Warning threshold for early/late clock-off times. */
  clockOffWarnMinutes?: InputMaybe<Scalars['Int']>;
  /** Warning threshold for early/late clock-on times. */
  clockOnWarnMinutes?: InputMaybe<Scalars['Int']>;
  /** Email address */
  email?: InputMaybe<Scalars['EmailAddress']>;
  /** Fixed expenses */
  fixedExpenses?: InputMaybe<Scalars['String']>;
  /** Ideas. */
  ideas?: InputMaybe<Scalars['String']>;
  /** Kilometre allowance. */
  kmAllowance?: InputMaybe<Scalars['Int']>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']>;
  /** Maximum member lateness percentage for automatic handover event allocation. */
  maxMemberLatenessForAutomaticHandoverEvents?: InputMaybe<Scalars['Int']>;
  /** Maximum member lateness percentage for automatic public-holiday event allocation. */
  maxMemberLatenessForAutomaticPublicHolidayEvents?: InputMaybe<Scalars['Int']>;
  /** Maximum member lateness percentage for automatic Saturday event allocation. */
  maxMemberLatenessForAutomaticSaturdayEvents?: InputMaybe<Scalars['Int']>;
  /** Maximum member lateness percentage for automatic Sunday event allocation. */
  maxMemberLatenessForAutomaticSundayEvents?: InputMaybe<Scalars['Int']>;
  memberDocumentTrackerCollapsedFiles?: InputMaybe<Array<MemberFileType>>;
  memberDocumentTrackerVisibleFiles?: InputMaybe<Array<MemberFileType>>;
  /** Members can see clocked times. */
  membersCanSeeClockedTimes?: InputMaybe<Scalars['Boolean']>;
  /** NdisRegistrationGroups input data. */
  ndisRegistrationGroups?: InputMaybe<QueryByIdsNumericNullableInput>;
  /** Night pay multiplier. */
  nightPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Passive-overnight allowance. */
  passiveAllowance?: InputMaybe<Scalars['Int']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Public holiday pay multiplier. */
  pubPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely after thier own shift. */
  quantityOfFutureShiftsMembersCanSee?: InputMaybe<Scalars['Int']>;
  /** Quantity of shifts a member can see occuring immedaitely before thier own shift. */
  quantityOfPastShiftsMembersCanSee?: InputMaybe<Scalars['Int']>;
  /** Saturday pay multiplier. */
  satPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Default maximum schedulable minutes per day. */
  scheduleMaxDayTimeLimitDefault?: InputMaybe<Scalars['Int']>;
  /** Default maximum schedulable minutes per week. */
  scheduleMaxWeekTimeLimitDefault?: InputMaybe<Scalars['Int']>;
  /** Scheduling contact person input data. */
  schedulingContactPerson?: InputMaybe<QueryByIdInput>;
  /** Second scheduling contact person input data. */
  schedulingContactPerson2?: InputMaybe<QueryByIdInput>;
  /** Sunday pay multiplier. */
  sunPayMultiplier?: InputMaybe<Scalars['Int']>;
  /** Enable task: Assign Attendee. */
  taskAssignAttendeeEnable?: InputMaybe<Scalars['Boolean']>;
  /** Days in the future to assign attendees. */
  taskAssignAttendeeFutureDays?: InputMaybe<Scalars['Int']>;
  /** Weeks in the past to find attendees. */
  taskAssignAttendeePastWeeks?: InputMaybe<Scalars['Int']>;
  /** Allow travel time to be assigned to subsequent events for the same client. */
  travelTimeAllowSameClient?: InputMaybe<Scalars['Boolean']>;
  /** Allowed duration between events for travel time eligibility. */
  travelTimeAllowedDurationBetweenEvents?: InputMaybe<Scalars['Int']>;
  /** Days in advance to check for unfilled shifts. */
  unfilledEventsDayRange?: InputMaybe<Scalars['Int']>;
  /** Website of the organisation */
  website?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "Org" entities. */
export type OrgsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<OrgsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<OrgsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<OrgsWhereInput>>;
  /** Australian Business Number. */
  abn?: InputMaybe<AbnConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Legal Name. */
  legalName?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Preferred Name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Website. */
  website?: InputMaybe<StringConditionalInput>;
};

/** Aggregated response object for "PayrollCategory" entities. */
export type PayrollCategoriesAggregate = {
  __typename?: 'PayrollCategoriesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<PayrollCategory>;
};

/** Ordering options for "PayrollCategory" entities. */
export type PayrollCategoriesOrderByInput = {
  /** Order by datetime of entity creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by values. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
  /** Order by values. */
  values?: InputMaybe<PayrollCategoryValuesOrderByInput>;
};

/** Updatable fields for "PayrollCategory" entities. */
export type PayrollCategoriesUpdatableFieldsInput = {
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** "PayrollCategoryValue" entity input data. */
  value?: InputMaybe<PayrollCategoryValuesSettableFieldsInput>;
};

/** Filtering conditions for "PayrollCategory" entities. */
export type PayrollCategoriesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<PayrollCategoriesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<PayrollCategoriesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<PayrollCategoriesWhereInput>>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
  /** Values. */
  values?: InputMaybe<PayrollCategoryValuesWhereInput>;
};

/** A payroll category. */
export type PayrollCategory = {
  __typename?: 'PayrollCategory';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name */
  name: Scalars['String'];
  /** Linked organisation. */
  org: Member;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
  values: Array<PayrollCategoryValue>;
};

/** A payroll category. */
export type PayrollCategoryValuesArgs = {
  input?: InputMaybe<QueryPayrollCategoryValuesInput>;
};

/** A payroll category value. */
export type PayrollCategoryValue = {
  __typename?: 'PayrollCategoryValue';
  /** Linked payroll category. */
  category: Member;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Member who set the value. */
  createdBy?: Maybe<Member>;
  /** Fixed value */
  fixedValue?: Maybe<Scalars['Int']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Multiplier */
  multiplier?: Maybe<Scalars['Float']>;
  type: PayrollCategoryValueType;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Type of payroll category value. */
export enum PayrollCategoryValueType {
  /** Fixed-value-based */
  FIXED = 'FIXED',
  /** Multiplier-based */
  MULTIPLIER = 'MULTIPLIER',
}

/** Enum conditional expression input. */
export type PayrollCategoryValueTypeConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['PayrollCategoryValueTypeScaler']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['PayrollCategoryValueTypeScaler']>>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['PayrollCategoryValueTypeScaler']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['PayrollCategoryValueTypeScaler']>>;
};

/** Aggregated response object for "PayrollCategoryValue" entities. */
export type PayrollCategoryValuesAggregate = {
  __typename?: 'PayrollCategoryValuesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<PayrollCategoryValue>;
};

/** Ordering options for "PayrollCategoryValue" entities. */
export type PayrollCategoryValuesOrderByInput = {
  /** Order by payroll category. */
  category?: InputMaybe<PayrollCategoriesOrderByInput>;
  /** Order by datetime of entity creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by fixed value. */
  fixedValue?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by fixed value. */
  multiplier?: InputMaybe<OrderBy>;
  /** Order by type. */
  type?: InputMaybe<OrderBy>;
};

/** Settable fields for "PayrollCategoryValue" entities */
export type PayrollCategoryValuesSettableFieldsInput = {
  /** Fixed value */
  fixedValue?: InputMaybe<Scalars['Int']>;
  /** Multiplier */
  multiplier?: InputMaybe<Scalars['Float']>;
  type: PayrollCategoryValueType;
};

/** Filtering conditions for "PayrollCategoryValue" entities. */
export type PayrollCategoryValuesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<PayrollCategoryValuesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<PayrollCategoryValuesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<PayrollCategoryValuesWhereInput>>;
  /** Payroll category. */
  category?: InputMaybe<PayrollCategoriesWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Fixed value. */
  fixedValue?: InputMaybe<IntConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Multiplier */
  multiplier?: InputMaybe<FloatConditionalInput>;
  /** Type. */
  type?: InputMaybe<PayrollCategoryValueTypeConditionalInput>;
};

export enum Permission {
  CLIENT_BUDGET_READ = 'CLIENT_BUDGET_READ',
  CLIENT_BUDGET_WRITE = 'CLIENT_BUDGET_WRITE',
  CLIENT_FILE_READ = 'CLIENT_FILE_READ',
  CLIENT_FILE_WRITE = 'CLIENT_FILE_WRITE',
  CLIENT_READ = 'CLIENT_READ',
  CLIENT_WRITE = 'CLIENT_WRITE',
  EVENT_READ = 'EVENT_READ',
  EVENT_WRITE = 'EVENT_WRITE',
  MEMBER_FILE_READ = 'MEMBER_FILE_READ',
  MEMBER_FILE_WRITE = 'MEMBER_FILE_WRITE',
  MEMBER_READ = 'MEMBER_READ',
  MEMBER_WRITE = 'MEMBER_WRITE',
  PLANMANAGER_READ = 'PLANMANAGER_READ',
  PLANMANAGER_WRITE = 'PLANMANAGER_WRITE',
  SUPPORTCOORDINATOR_READ = 'SUPPORTCOORDINATOR_READ',
  SUPPORTCOORDINATOR_WRITE = 'SUPPORTCOORDINATOR_WRITE',
}

/** A person */
export type Person = {
  /** First name */
  firstName: Scalars['String'];
  /** Gender */
  gender?: Maybe<Gender>;
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** Middle name */
  middleName?: Maybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** Pronouns */
  pronouns?: Maybe<Pronouns>;
  /** Salutation */
  salutation?: Maybe<Salutation>;
};

/** Phone number conditional expression input. */
export type PhoneConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['String']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['String']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['String']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['String']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['String']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['String']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['String']>>;
};

/** A plan manager for NDIS participants. */
export type PlanManager = {
  __typename?: 'PlanManager';
  /** Australian Business Number of the organisation */
  abn?: Maybe<Scalars['ABN']>;
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Billing email address. */
  billingEmail?: Maybe<Scalars['String']>;
  /** Business name. */
  businessName?: Maybe<Scalars['String']>;
  clients: Array<Client>;
  /** Contact email address. */
  contactEmail?: Maybe<Scalars['String']>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name. */
  name: Scalars['String'];
  /** Notes. */
  notes?: Maybe<Scalars['String']>;
  /** Organisation to which this plan manager belongs. */
  org: Org;
  /** Phone number. */
  phone?: Maybe<Scalars['String']>;
  /** Salutation. */
  salutation?: Maybe<Salutation>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** A plan manager for NDIS participants. */
export type PlanManagerClientsArgs = {
  input?: InputMaybe<QueryClientsInput>;
};

/** Aggregated response object for "PlanManager" entities. */
export type PlanManagersAggregate = {
  __typename?: 'PlanManagersAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<PlanManager>;
};

/** Creatable fields for "PlanManager" entities. */
export type PlanManagersCreatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Billing email address. */
  billingEmail?: InputMaybe<Scalars['String']>;
  /** Business name. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Contact email address. */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Name. */
  name: Scalars['String'];
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
};

/** Ordering options for "PlanManager" entities. */
export type PlanManagersOrderByInput = {
  /** Order by Australian Business Number. */
  abn?: InputMaybe<OrderBy>;
  /** Order by business name. */
  businessName?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Updatable fields for "PlanManager" entities. */
export type PlanManagersUpdatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Billing email address. */
  billingEmail?: InputMaybe<Scalars['String']>;
  /** Business name. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Contact email address. */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Name. */
  name?: InputMaybe<Scalars['String']>;
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
};

/** Filtering conditions for "PlanManager" entities. */
export type PlanManagersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<PlanManagersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<PlanManagersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<PlanManagersWhereInput>>;
  /** Australian Business Number. */
  abn?: InputMaybe<AbnConditionalInput>;
  /** Billing email address. */
  billingEmail?: InputMaybe<StringConditionalInput>;
  /** Business name. */
  businessName?: InputMaybe<StringConditionalInput>;
  /** Contact email address. */
  contactEmail?: InputMaybe<StringConditionalInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
};

export type PotentialAttendeeConflict = {
  __typename?: 'PotentialAttendeeConflict';
  /** Client. */
  client: Client;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
};

export type PotentialAttendeeHours = {
  __typename?: 'PotentialAttendeeHours';
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Next week. */
  nextWeek: Scalars['Int'];
  /** Previous week. */
  prevWeek: Scalars['Int'];
  /** This week. */
  thisWeek: Scalars['Int'];
};

export type PotentialAttendeeTeams = {
  __typename?: 'PotentialAttendeeTeams';
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Preferred. */
  preferred: Scalars['Boolean'];
};

export type PotentialAttendeeUnavailables = {
  __typename?: 'PotentialAttendeeUnavailables';
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
};

export type PotentialAttendees = {
  __typename?: 'PotentialAttendees';
  /** Conflicts. */
  conflicts: Array<PotentialAttendeeConflict>;
  /** Unavailables. */
  hours: Array<PotentialAttendeeHours>;
  /** Members. */
  members: Array<Member>;
  /** Teams. */
  teams: Array<PotentialAttendeeTeams>;
  /** Unavailables. */
  unavailables: Array<PotentialAttendeeUnavailables>;
};

/** Input data for processing singular MemberUpload entities. */
export type ProcessMemberUploadInput = {
  /** Processing state. */
  accept: Scalars['Boolean'];
  /** Ambiguous date of file. Could be the original creation date or the date the document was signed etc. */
  dateOfFile?: InputMaybe<Scalars['Date']>;
  /** Expiry date of file. */
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** Uploaded file to process. */
  file: QueryByIdInput;
  /** Processing note. */
  processingNote?: InputMaybe<Scalars['String']>;
  type: MemberFileType;
  value?: InputMaybe<Scalars['String']>;
};

export enum ProcessingState {
  /** Accepted */
  ACCEPTED = 'ACCEPTED',
  /** Pending */
  PENDING = 'PENDING',
  /** Rejected */
  REJECTED = 'REJECTED',
}

/** Processing state conditional expression input. */
export type ProcessingStateConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<ProcessingState>;
  /** In array. */
  _in?: InputMaybe<Array<ProcessingState>>;
  /** Not equal. */
  _ne?: InputMaybe<ProcessingState>;
  /** Not in array. */
  _nin?: InputMaybe<Array<ProcessingState>>;
};

/** Pronouns */
export enum Pronouns {
  /** He */
  HE = 'HE',
  /** Not specified */
  NOTSPECIFIED = 'NOTSPECIFIED',
  /** She */
  SHE = 'SHE',
  /** They */
  THEY = 'THEY',
}

/** A public holiday. */
export type PublicHoliday = {
  __typename?: 'PublicHoliday';
  /** Date of the public holiday. */
  date: Scalars['Date'];
  /** Description. */
  description: Scalars['String'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Address state or territory. */
  region?: Maybe<AddressRegionCode>;
};

/** Aggregated response object for "PublicHoliday" entities. */
export type PublicHolidaysAggregate = {
  __typename?: 'PublicHolidaysAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<PublicHoliday>;
};

/** Ordering options for "PublicHoliday" entities. */
export type PublicHolidaysOrderByInput = {
  /** Order by date. */
  date?: InputMaybe<OrderBy>;
  /** Order by description. */
  description?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "PublicHoliday" entities. */
export type PublicHolidaysWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<PublicHolidaysWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<PublicHolidaysWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<PublicHolidaysWhereInput>>;
  /** Date. */
  date?: InputMaybe<DateConditionalInput>;
  /** Description. */
  description?: InputMaybe<StringConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetch a single entity by its unique ID. */
  accessLogById: AccessLog;
  /** Fetch paginated entities. */
  accessLogs: Array<AccessLog>;
  /** Fetch aggregated entities. */
  accessLogsAggregate: AccessLogsAggregate;
  /** Fetch a single "Branch" entity by its unique ID. */
  branchById: Branch;
  /** Fetch paginated "Branch" entities. */
  branches: Array<Branch>;
  /** Fetch aggregated "Branch" entities. */
  branchesAggregate: BranchesAggregate;
  /** Fetch a single "Client" entity by its unique ID. */
  clientById: Client;
  /** Fetch a single "ClientContact" entity by its unique ID. */
  clientContactById: ClientContact;
  /** Fetch paginated "ClientContact" entities. */
  clientContacts: Array<ClientContact>;
  /** Fetch aggregated "ClientContact" entities. */
  clientContactsAggregate: ClientContactsAggregate;
  /** Fetch paginated "ClientFile" entities. */
  clientFiles: Array<ClientFile>;
  /** Fetch aggregated "ClientFile" entities. */
  clientFilesAggregate: ClientFilesAggregate;
  /** Fetch a single "ClientMedication" entity by its unique ID. */
  clientMedicationById: ClientMedication;
  /** Fetch paginated "ClientMedication" entities. */
  clientMedications: Array<ClientMedication>;
  /** Fetch aggregated "ClientMedication" entities. */
  clientMedicationsAggregate: ClientMedicationsAggregate;
  /** Fetch aggregated entities. */
  clientNdisPlanAggregate: ClientNdisPlan;
  /** Fetch an entity. */
  clientNdisPlanById: ClientNdisPlan;
  /** Fetch aggregated entities. */
  clientNdisPlanItemAggregate: ClientNdisPlanItem;
  /** Fetch an entity. */
  clientNdisPlanItemById: ClientNdisPlanItem;
  /** Fetch aggregated entities. */
  clientNdisPlanItemNameAggregate: ClientNdisPlanItemNameAggregate;
  /** Fetch an entity. */
  clientNdisPlanItemNameById: ClientNdisPlanItemName;
  /** Fetch entities. */
  clientNdisPlanItemNames: Array<ClientNdisPlanItemName>;
  /** Fetch aggregated entities. */
  clientNdisPlanItemTransactionAggregate: ClientNdisPlanItemTransactionAggregate;
  /** Fetch an entity. */
  clientNdisPlanItemTransactionById: ClientNdisPlanItemTransaction;
  /** Fetch aggregated entities. */
  clientNdisPlanItemTransactionSupplierAggregate: ClientNdisPlanItemTransactionSuppliersAggregate;
  /** Fetch a single entity by its unique ID. */
  clientNdisPlanItemTransactionSupplierById: ClientNdisPlanItemTransactionSupplier;
  /** Fetch paginated entities. */
  clientNdisPlanItemTransactionSuppliers: Array<ClientNdisPlanItemTransactionSupplier>;
  /** Fetch entities. */
  clientNdisPlanItemTransactions: Array<ClientNdisPlanItemTransaction>;
  /** Fetch entities. */
  clientNdisPlanItems: Array<ClientNdisPlanItem>;
  /** Fetch entities. */
  clientNdisPlans: Array<ClientNdisPlan>;
  /** Fetch a single entity by its unique ID. */
  clientNoteById: ClientNote;
  /** Fetch paginated "ClientNoteFile" entities. */
  clientNoteFiles: Array<ClientNoteFile>;
  /** Fetch aggregated "ClientNoteFile" entities. */
  clientNoteFilesAggregate: ClientNoteFilesAggregate;
  /** Fetch a single entity by its unique ID. */
  clientNoteKeywordById: ClientNoteKeyword;
  /** Fetch a single entity by its unique ID. */
  clientNoteKeywordMatchById: ClientNoteKeywordMatch;
  /** Fetch paginated entities. */
  clientNoteKeywordMatches: Array<ClientNoteKeywordMatch>;
  /** Fetch aggregated entities. */
  clientNoteKeywordMatchesAggregate: ClientNoteKeywordMatchesAggregate;
  /** Fetch paginated entities. */
  clientNoteKeywords: Array<ClientNoteKeyword>;
  /** Fetch aggregated entities. */
  clientNoteKeywordsAggregate: ClientNoteKeywordsAggregate;
  /** Fetch paginated entities. */
  clientNotes: Array<ClientNote>;
  /** Fetch aggregated entities. */
  clientNotesAggregate: ClientNotesAggregate;
  /** Fetch a single "ClientObservation" entity by its unique ID. */
  clientObservationById: ClientObservation;
  /** Fetch paginated "ClientObservation" entities. */
  clientObservations: Array<ClientObservation>;
  /** Fetch aggregated "ClientObservation" entities. */
  clientObservationsAggregate: ClientObservationsAggregate;
  /** Fetch a single "ClientTeam" entity by its unique ID. */
  clientTeamById: ClientTeam;
  /** Fetch paginated "ClientTeam" entities. */
  clientTeams: Array<ClientTeam>;
  /** Fetch aggregated "ClientTeam" entities. */
  clientTeamsAggregate: ClientTeamsAggregate;
  /** Fetch paginated "Client" entities. */
  clients: Array<Client>;
  /** Fetch aggregated "Client" entities. */
  clientsAggregate: ClientsAggregate;
  /** Fetch a single entity by its unique ID. */
  conflictById: Conflict;
  /** Fetch paginated entities. */
  conflicts: Array<Conflict>;
  /** Fetch aggregated entities. */
  conflictsAggregate: ConflictsAggregate;
  /** Download a care plan belonging to a "Client" entity. */
  downloadClientCarePlan: File;
  /** Download a file by its unique ID, belonging to a "Client" entity. */
  downloadClientFileById: File;
  /** Download a file by its unique ID, belonging to a "ClientNote" entity. */
  downloadClientNoteFileById: File;
  /** Download a file by its unique ID, belonging to an "Event" entity. */
  downloadEventFileById: File;
  /** Download a file by its unique ID, belonging to a "Member" entity. */
  downloadMemberFileById: File;
  /** Download a file by its unique ID. */
  downloadMemberUploadById: File;
  /** Fetch a single "Event" entity by its unique ID. */
  eventById: Event;
  /** Check suitability of event attendees. */
  eventCheckAttendeeSuitability: Array<EventAttendeeSuitability>;
  /** Fetch a single entity by its unique ID. */
  eventClaimById: EventClaim;
  /** Fetch paginated entities. */
  eventClaims: Array<EventClaim>;
  /** Fetch aggregated entities. */
  eventClaimsAggregate: EventClaimsAggregate;
  /** Fetch paginated "EventFile" entities. */
  eventFiles: Array<EventFile>;
  /** Fetch aggregated "EventFile" entities. */
  eventFilesAggregate: EventFilesAggregate;
  /** Fetch a single "EventOwnAndRelated" entity by its unique ID. */
  eventOwnAndRelatedById: EventOwnAndRelated;
  /** Fetch potential attendees for a new or existing event. */
  eventPotentialAttendees: PotentialAttendees;
  /** Fetch paginated "Event" entities. */
  events: Array<Event>;
  /** Fetch aggregated "Event" entities. */
  eventsAggregate: EventsAggregate;
  /** Fetch unattended "EventOwnAndRelated" entities which the current user can claim. */
  eventsClaimable: Array<EventOwnAndRelated>;
  /** Fetch paginated "Event" entities. */
  eventsNoCache: Array<Event>;
  /** Fetch "EventOwnAndRelated" entities. */
  eventsOwnAndRelated: Array<EventOwnAndRelated>;
  /** Historical records. */
  histories?: Maybe<Array<HistoriesUnion>>;
  /** Get data on the currently logged-in user. */
  me: User;
  /** Fetch a single "Medication" entity by its unique ID. */
  medicationById: Medication;
  /** Fetch paginated "Medication" entities. */
  medications: Array<Medication>;
  /** Fetch aggregated "Medication" entities. */
  medicationsAggregate: MedicationsAggregate;
  /** Fetch a single "MemberBasePayRate" entity by its unique ID. */
  memberBasePayRateById: MemberBasePayRate;
  /** Fetch paginated "MemberBasePayRate" entities. */
  memberBasePayRates: Array<MemberBasePayRate>;
  /** Fetch aggregated "MemberBasePayRate" entities. */
  memberBasePayRatesAggregate: MemberBasePayRatesAggregate;
  /** Fetch a single "MemberBonusEligible" entity by its unique ID. */
  memberBonusEligibleById: MemberBonusEligible;
  /** Fetch paginated "MemberBonusEligible" entities. */
  memberBonusEligibles: Array<MemberBonusEligible>;
  /** Fetch aggregated "MemberBonusEligible" entities. */
  memberBonusEligiblesAggregate: MemberBonusEligiblesAggregate;
  /** Fetch a single "MemberBranchAllocation" entity by its unique ID. */
  memberBranchAllocationById: MemberBranchAllocation;
  /** Fetch paginated "MemberBranchAllocation" entities. */
  memberBranchAllocations: Array<MemberBranchAllocation>;
  /** Fetch aggregated "MemberBranchAllocation" entities. */
  memberBranchAllocationsAggregate: MemberBranchAllocationsAggregate;
  /** Fetch a single "Member" entity by its unique ID. */
  memberById: Member;
  /** Fetch paginated "MemberFile" entities. */
  memberFiles: Array<MemberFile>;
  /** Fetch aggregated "MemberFile" entities. */
  memberFilesAggregate: MemberFilesAggregate;
  /** Fetch a single "MemberPayrollCheck" entity by its unique ID. */
  memberPayrollCheckById: MemberPayrollCheck;
  /** Fetch paginated "MemberPayrollCheck" entities. */
  memberPayrollChecks: Array<MemberPayrollCheck>;
  /** Fetch aggregated "MemberPayrollCheck" entities. */
  memberPayrollChecksAggregate: MemberPayrollChecksAggregate;
  /** Fetch a single entity by its unique ID. */
  memberPostNominalById: MemberPostNominal;
  /** Fetch paginated entities. */
  memberPostNominals: Array<MemberPostNominal>;
  /** Fetch aggregated entities. */
  memberPostNominalsAggregate: MemberPostNominalsAggregate;
  /** Fetch a single "MemberUnavailable" entity by its unique ID. */
  memberUnavailableById: MemberUnavailable;
  /** Fetch paginated "MemberUnavailable" entities. */
  memberUnavailables: Array<MemberUnavailable>;
  /** Fetch aggregated "MemberUnavailable" entities. */
  memberUnavailablesAggregate: MemberUnavailablesAggregate;
  /** Fetch paginated entities. */
  memberUploads: Array<MemberUpload>;
  /** Fetch aggregated entities. */
  memberUploadsAggregate: MemberUploadsAggregate;
  /** Fetch paginated "Member" entities. */
  members: Array<Member>;
  /** Fetch aggregated "Member" entities. */
  membersAggregate: MembersAggregate;
  /** Fetch paginated entities associated with the current user. */
  myEventClaims: Array<EventClaim>;
  /** Fetch paginated "MemberUnavailable" entities for the current user. */
  myUnavailables: Array<MemberUnavailable>;
  /** Fetch paginated "NdisActivity" entities. */
  ndisActivities: Array<NdisActivity>;
  /** Fetch aggregated "NdisActivity" entities. */
  ndisActivitiesAggregate: NdisActivitiesAggregate;
  /** Fetch a single "NdisActivity" entity by its unique ID. */
  ndisActivityById: NdisActivity;
  /** Fetch entities. */
  ndisCoordinationClientActivities: Array<NdisCoordinationClientActivity>;
  /** Fetch aggregated entities. */
  ndisCoordinationClientActivitiesAggregate: NdisCoordinationClientActivityAggregate;
  /** Fetch an entity. */
  ndisCoordinationClientActivityById: NdisCoordinationClientActivity;
  /** Fetch an entity. */
  ndisCoordinationClientById: NdisCoordinationClient;
  /** Fetch entities. */
  ndisCoordinationClients: Array<NdisCoordinationClient>;
  /** Fetch aggregated entities. */
  ndisCoordinationClientsAggregate: NdisCoordinationClientAggregate;
  /** Fetch a single "NdisRegistrationGroup" entity by its unique number. */
  ndisRegistrationGroupById: NdisRegistrationGroup;
  /** Fetch paginated "NdisRegistrationGroup" entities. */
  ndisRegistrationGroups: Array<NdisRegistrationGroup>;
  /** Fetch aggregated "NdisRegistrationGroup" entities. */
  ndisRegistrationGroupsAggregate: NdisRegistrationGroupsAggregate;
  /** Fetch paginated "NdisSupportCategory" entities. */
  ndisSupportCategories: Array<NdisSupportCategory>;
  /** Fetch aggregated "NdisSupportCategory" entities. */
  ndisSupportCategoriesAggregate: NdisSupportCategoriesAggregate;
  /** Fetch a single "NdisSupportCategory" entity by its unique number. */
  ndisSupportCategoryById: NdisSupportCategory;
  /** Fetch a single "NdisSupportItem" entity by its unique number. */
  ndisSupportItemById: NdisSupportItem;
  /** Fetch paginated "NdisSupportItem" entities. */
  ndisSupportItems: Array<NdisSupportItem>;
  /** Fetch aggregated "NdisSupportItem" entities. */
  ndisSupportItemsAggregate: NdisSupportItemsAggregate;
  /** Fetch a single "Notification" entity by its unique ID belonging to the current user. */
  notificationById: Notification;
  /** Fetch paginated "Notification" entities. */
  notifications: Array<Notification>;
  /** Fetch aggregated "Notification" entities. */
  notificationsAggregate: NotificationsAggregate;
  /** Fetch a single "Org" entity by its unique ID. */
  orgById: Org;
  /** Fetch paginated "Org" entities of which the current user is a member. */
  orgs: Array<Org>;
  /** Fetch aggregated "Org" entities of which the current user is a member. */
  orgsAggregate: OrgsAggregate;
  /** Fetch paginated "PayrollCategory" entities. */
  payrollCategories: Array<PayrollCategory>;
  /** Fetch aggregated "PayrollCategory" entities. */
  payrollCategoriesAggregate: PayrollCategoriesAggregate;
  /** Fetch a single "PayrollCategory" entity by its unique ID. */
  payrollCategoryById: PayrollCategory;
  /** Fetch a single "PayrollCategoryValue" entity by its unique ID. */
  payrollCategoryValueById: PayrollCategoryValue;
  /** Fetch paginated "PayrollCategoryValue" entities of which the current user is a member. */
  payrollCategoryValues: Array<PayrollCategoryValue>;
  /** Fetch aggregated "PayrollCategoryValue" entities of which the current user is a member. */
  payrollCategoryValuesAggregate: PayrollCategoryValuesAggregate;
  /** Fetch a single "PlanManager" entity by its unique ID. */
  planManagerById: PlanManager;
  /** Fetch paginated "PlanManager" entities. */
  planManagers: Array<PlanManager>;
  /** Fetch aggregated "PlanManager" entities. */
  planManagersAggregate: PlanManagersAggregate;
  /** Fetch a single "PublicHoliday" entity by its unique ID. */
  publicHolidayById: PublicHoliday;
  /** Fetch paginated "PublicHoliday" entities. */
  publicHolidays: Array<PublicHoliday>;
  /** Fetch aggregated "PublicHoliday" entities. */
  publicHolidaysAggregate: PublicHolidaysAggregate;
  /** Fetch a single "RedactedClient" entity by its unique ID. */
  redactedClientById: RedactedClient;
  /** Fetch paginated "RedactedClient" entities. */
  redactedClients: Array<RedactedClient>;
  /** Fetch aggregated "RedactedClient" entities. */
  redactedClientsAggregate: RedactedClientsAggregate;
  /** Fetch a single "RedactedMember" entity by its unique ID. */
  redactedMemberById: RedactedMember;
  /** Fetch paginated "RedactedMember" entities. */
  redactedMembers: Array<RedactedMember>;
  /** Fetch aggregated "RedactedMember" entities. */
  redactedMembersAggregate: RedactedMembersAggregate;
  /** Fetch the "Schedule" entity. */
  schedule: Schedule;
  /** Fetch a single "SupportCoordinator" entity by its unique ID. */
  supportCoordinatorById: SupportCoordinator;
  /** Fetch paginated "SupportCoordinator" entities. */
  supportCoordinators: Array<SupportCoordinator>;
  /** Fetch aggregated "SupportCoordinator" entities. */
  supportCoordinatorsAggregate: SupportCoordinatorsAggregate;
  /** Validate external IDs. */
  validateClientExternalIds: ClientsValidatedExternalIdsResponse;
  /** Validate external IDs. */
  validateMemberExternalIds: MembersValidatedExternalIdsResponse;
  /** Fetch a single "VisaType" entity by its unique ID. */
  visaTypeById: VisaType;
  /** Fetch paginated "VisaType" entities. */
  visaTypes: Array<VisaType>;
  /** Fetch aggregated "VisaType" entities. */
  visaTypesAggregate: VisaTypesAggregate;
};

export type QueryAccessLogByIdArgs = {
  id: Scalars['ID'];
};

export type QueryAccessLogsArgs = {
  input: QueryAccessLogsInput;
};

export type QueryAccessLogsAggregateArgs = {
  input: QueryAccessLogsInput;
};

export type QueryBranchByIdArgs = {
  id: Scalars['ID'];
};

export type QueryBranchesArgs = {
  input: QueryBranchesInput;
};

export type QueryBranchesAggregateArgs = {
  input: QueryBranchesInput;
};

export type QueryClientByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientContactByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientContactsArgs = {
  input: QueryClientContactsInput;
};

export type QueryClientContactsAggregateArgs = {
  input: QueryClientContactsInput;
};

export type QueryClientFilesArgs = {
  input: QueryClientFilesInput;
};

export type QueryClientFilesAggregateArgs = {
  input: QueryClientFilesInput;
};

export type QueryClientMedicationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientMedicationsArgs = {
  input: QueryClientMedicationsInput;
};

export type QueryClientMedicationsAggregateArgs = {
  input: QueryClientMedicationsInput;
};

export type QueryClientNdisPlanAggregateArgs = {
  input: QueryClientNdisPlanInput;
};

export type QueryClientNdisPlanByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientNdisPlanItemAggregateArgs = {
  input: QueryClientNdisPlanItemInput;
};

export type QueryClientNdisPlanItemByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientNdisPlanItemNameAggregateArgs = {
  input: QueryClientNdisPlanItemNameInput;
};

export type QueryClientNdisPlanItemNameByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientNdisPlanItemNamesArgs = {
  input: QueryClientNdisPlanItemNameInput;
};

export type QueryClientNdisPlanItemTransactionAggregateArgs = {
  input: QueryClientNdisPlanItemTransactionInput;
};

export type QueryClientNdisPlanItemTransactionByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientNdisPlanItemTransactionSupplierAggregateArgs = {
  input: QueryClientNdisPlanItemTransactionSuppliersInput;
};

export type QueryClientNdisPlanItemTransactionSupplierByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientNdisPlanItemTransactionSuppliersArgs = {
  input: QueryClientNdisPlanItemTransactionSuppliersInput;
};

export type QueryClientNdisPlanItemTransactionsArgs = {
  input: QueryClientNdisPlanItemTransactionInput;
};

export type QueryClientNdisPlanItemsArgs = {
  input: QueryClientNdisPlanItemInput;
};

export type QueryClientNdisPlansArgs = {
  input: QueryClientNdisPlanInput;
};

export type QueryClientNoteByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientNoteFilesArgs = {
  input: QueryClientNoteFilesInput;
};

export type QueryClientNoteFilesAggregateArgs = {
  input: QueryClientNoteFilesInput;
};

export type QueryClientNoteKeywordByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientNoteKeywordMatchByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientNoteKeywordMatchesArgs = {
  input: QueryClientNoteKeywordMatchesInput;
};

export type QueryClientNoteKeywordMatchesAggregateArgs = {
  input: QueryClientNoteKeywordMatchesInput;
};

export type QueryClientNoteKeywordsArgs = {
  input: QueryClientNoteKeywordsInput;
};

export type QueryClientNoteKeywordsAggregateArgs = {
  input: QueryClientNoteKeywordsInput;
};

export type QueryClientNotesArgs = {
  input: QueryClientNotesInput;
};

export type QueryClientNotesAggregateArgs = {
  input: QueryClientNotesInput;
};

export type QueryClientObservationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientObservationsArgs = {
  input: QueryClientObservationsInput;
};

export type QueryClientObservationsAggregateArgs = {
  input: QueryClientObservationsInput;
};

export type QueryClientTeamByIdArgs = {
  id: Scalars['ID'];
};

export type QueryClientTeamsArgs = {
  input: QueryClientTeamsInput;
};

export type QueryClientTeamsAggregateArgs = {
  input: QueryClientTeamsInput;
};

export type QueryClientsArgs = {
  input: QueryClientsInput;
};

export type QueryClientsAggregateArgs = {
  input: QueryClientsInput;
};

export type QueryConflictByIdArgs = {
  id: Scalars['ID'];
};

export type QueryConflictsArgs = {
  input: QueryConflictsInput;
};

export type QueryConflictsAggregateArgs = {
  input: QueryConflictsInput;
};

export type QueryDownloadClientCarePlanArgs = {
  id: Scalars['ID'];
};

export type QueryDownloadClientFileByIdArgs = {
  id: Scalars['ID'];
};

export type QueryDownloadClientNoteFileByIdArgs = {
  id: Scalars['ID'];
};

export type QueryDownloadEventFileByIdArgs = {
  id: Scalars['ID'];
};

export type QueryDownloadMemberFileByIdArgs = {
  id: Scalars['ID'];
};

export type QueryDownloadMemberUploadByIdArgs = {
  id: Scalars['ID'];
};

export type QueryEventByIdArgs = {
  id: Scalars['ID'];
};

export type QueryEventCheckAttendeeSuitabilityArgs = {
  input: QueryEventsPotentialAttendeesInput;
};

export type QueryEventClaimByIdArgs = {
  id: Scalars['ID'];
};

export type QueryEventClaimsArgs = {
  input: QueryEventClaimsInput;
};

export type QueryEventClaimsAggregateArgs = {
  input: QueryEventClaimsInput;
};

export type QueryEventFilesArgs = {
  input: QueryEventFilesInput;
};

export type QueryEventFilesAggregateArgs = {
  input: QueryEventFilesInput;
};

export type QueryEventOwnAndRelatedByIdArgs = {
  id: Scalars['ID'];
};

export type QueryEventPotentialAttendeesArgs = {
  input: QueryEventsPotentialAttendeesInput;
};

export type QueryEventsArgs = {
  input: QueryEventsInput;
};

export type QueryEventsAggregateArgs = {
  input: QueryEventsInput;
};

export type QueryEventsClaimableArgs = {
  input?: InputMaybe<QueryEventsOwnAndRelatedInput>;
};

export type QueryEventsNoCacheArgs = {
  input: QueryEventsInput;
};

export type QueryEventsOwnAndRelatedArgs = {
  input: QueryEventsOwnAndRelatedInput;
};

export type QueryHistoriesArgs = {
  input: QueryHistoriesInput;
};

export type QueryMedicationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMedicationsArgs = {
  input: QueryMedicationsInput;
};

export type QueryMedicationsAggregateArgs = {
  input: QueryMedicationsInput;
};

export type QueryMemberBasePayRateByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMemberBasePayRatesArgs = {
  input: QueryMemberBasePayRatesInput;
};

export type QueryMemberBasePayRatesAggregateArgs = {
  input: QueryMemberBasePayRatesInput;
};

export type QueryMemberBonusEligibleByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMemberBonusEligiblesArgs = {
  input: QueryMemberBonusEligiblesInput;
};

export type QueryMemberBonusEligiblesAggregateArgs = {
  input: QueryMemberBonusEligiblesInput;
};

export type QueryMemberBranchAllocationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMemberBranchAllocationsArgs = {
  input: QueryMemberBranchAllocationsInput;
};

export type QueryMemberBranchAllocationsAggregateArgs = {
  input: QueryMemberBranchAllocationsInput;
};

export type QueryMemberByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMemberFilesArgs = {
  input: QueryMemberFilesInput;
};

export type QueryMemberFilesAggregateArgs = {
  input: QueryMemberFilesInput;
};

export type QueryMemberPayrollCheckByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMemberPayrollChecksArgs = {
  input: QueryMemberPayrollChecksInput;
};

export type QueryMemberPayrollChecksAggregateArgs = {
  input: QueryMemberPayrollChecksInput;
};

export type QueryMemberPostNominalByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMemberPostNominalsArgs = {
  input: QueryMemberPostNominalsInput;
};

export type QueryMemberPostNominalsAggregateArgs = {
  input: QueryMemberPostNominalsInput;
};

export type QueryMemberUnavailableByIdArgs = {
  id: Scalars['ID'];
};

export type QueryMemberUnavailablesArgs = {
  input: QueryMemberUnavailablesInput;
};

export type QueryMemberUnavailablesAggregateArgs = {
  input: QueryMemberUnavailablesInput;
};

export type QueryMemberUploadsArgs = {
  input: QueryMemberUploadsInput;
};

export type QueryMemberUploadsAggregateArgs = {
  input: QueryMemberUploadsInput;
};

export type QueryMembersArgs = {
  input: QueryMembersInput;
};

export type QueryMembersAggregateArgs = {
  input: QueryMembersInput;
};

export type QueryMyEventClaimsArgs = {
  input: QueryEventClaimsInput;
};

export type QueryMyUnavailablesArgs = {
  input: QueryMyMemberUnavailablesInput;
};

export type QueryNdisActivitiesArgs = {
  input: QueryNdisActivitiesInput;
};

export type QueryNdisActivitiesAggregateArgs = {
  input: QueryNdisActivitiesInput;
};

export type QueryNdisActivityByIdArgs = {
  id: Scalars['ID'];
};

export type QueryNdisCoordinationClientActivitiesArgs = {
  input: QueryNdisCoordinationClientActivityInput;
};

export type QueryNdisCoordinationClientActivitiesAggregateArgs = {
  input: QueryNdisCoordinationClientActivityInput;
};

export type QueryNdisCoordinationClientActivityByIdArgs = {
  id: Scalars['ID'];
};

export type QueryNdisCoordinationClientByIdArgs = {
  id: Scalars['ID'];
};

export type QueryNdisCoordinationClientsArgs = {
  input: QueryNdisCoordinationClientInput;
};

export type QueryNdisCoordinationClientsAggregateArgs = {
  input: QueryNdisCoordinationClientInput;
};

export type QueryNdisRegistrationGroupByIdArgs = {
  id: Scalars['ID'];
};

export type QueryNdisRegistrationGroupsArgs = {
  input: QueryNdisRegistrationGroupsInput;
};

export type QueryNdisRegistrationGroupsAggregateArgs = {
  input: QueryNdisRegistrationGroupsInput;
};

export type QueryNdisSupportCategoriesArgs = {
  input: QueryNdisSupportCategoriesInput;
};

export type QueryNdisSupportCategoriesAggregateArgs = {
  input: QueryNdisSupportCategoriesInput;
};

export type QueryNdisSupportCategoryByIdArgs = {
  id: Scalars['ID'];
};

export type QueryNdisSupportItemByIdArgs = {
  id: Scalars['ID'];
};

export type QueryNdisSupportItemsArgs = {
  input: QueryNdisSupportItemsInput;
};

export type QueryNdisSupportItemsAggregateArgs = {
  input: QueryNdisSupportItemsInput;
};

export type QueryNotificationByIdArgs = {
  id: Scalars['ID'];
};

export type QueryNotificationsArgs = {
  input: QueryNotificationsInput;
};

export type QueryNotificationsAggregateArgs = {
  input: QueryNotificationsInput;
};

export type QueryOrgByIdArgs = {
  id: Scalars['ID'];
};

export type QueryOrgsArgs = {
  input: QueryOrgsInput;
};

export type QueryOrgsAggregateArgs = {
  input: QueryOrgsInput;
};

export type QueryPayrollCategoriesArgs = {
  input: QueryPayrollCategoriesInput;
};

export type QueryPayrollCategoriesAggregateArgs = {
  input: QueryPayrollCategoriesInput;
};

export type QueryPayrollCategoryByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPayrollCategoryValueByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPayrollCategoryValuesArgs = {
  input: QueryPayrollCategoryValuesInput;
};

export type QueryPayrollCategoryValuesAggregateArgs = {
  input: QueryPayrollCategoryValuesInput;
};

export type QueryPlanManagerByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPlanManagersArgs = {
  input: QueryPlanManagersInput;
};

export type QueryPlanManagersAggregateArgs = {
  input: QueryPlanManagersInput;
};

export type QueryPublicHolidayByIdArgs = {
  id: Scalars['ID'];
};

export type QueryPublicHolidaysArgs = {
  input: QueryPublicHolidaysInput;
};

export type QueryPublicHolidaysAggregateArgs = {
  input: QueryPublicHolidaysInput;
};

export type QueryRedactedClientByIdArgs = {
  id: Scalars['ID'];
};

export type QueryRedactedClientsArgs = {
  input: QueryRedactedClientsInput;
};

export type QueryRedactedClientsAggregateArgs = {
  input: QueryRedactedClientsInput;
};

export type QueryRedactedMemberByIdArgs = {
  id: Scalars['ID'];
};

export type QueryRedactedMembersArgs = {
  input: QueryRedactedMembersInput;
};

export type QueryRedactedMembersAggregateArgs = {
  input: QueryRedactedMembersInput;
};

export type QuerySupportCoordinatorByIdArgs = {
  id: Scalars['ID'];
};

export type QuerySupportCoordinatorsArgs = {
  input: QuerySupportCoordinatorsInput;
};

export type QuerySupportCoordinatorsAggregateArgs = {
  input: QuerySupportCoordinatorsInput;
};

export type QueryValidateClientExternalIdsArgs = {
  input: ClientsValidateExternalldsInput;
};

export type QueryValidateMemberExternalIdsArgs = {
  input: MembersValidateExternalldsInput;
};

export type QueryVisaTypeByIdArgs = {
  id: Scalars['ID'];
};

export type QueryVisaTypesArgs = {
  input: QueryVisaTypesInput;
};

export type QueryVisaTypesAggregateArgs = {
  input: QueryVisaTypesInput;
};

/** Quering input data for entities. */
export type QueryAccessLogsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<AccessLogsOrderByInput>>;
  /** Query conditions. */
  where?: AccessLogsWhereInput;
};

/** Quering input data for "Branch" entities. */
export type QueryBranchesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<BranchesOrderByInput>>;
  /** Query conditions. */
  where?: BranchesWhereInput;
};

/** Query by ID input data for any entity. */
export type QueryByIdInput = {
  /** Entity ID. */
  id: Scalars['ID'];
};

/** Query by ID input data for any entity. */
export type QueryByIdsInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
};

/** Query by ID input data for any entity. Nullable. */
export type QueryByIdsNullableInput = {
  /** Entity IDs. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

/** Query by ID input data for any entity with a numerical id. Nullable. */
export type QueryByIdsNumericNullableInput = {
  /** Entity IDs. */
  ids?: InputMaybe<Array<Scalars['Int']>>;
};

/** Quering input data for "ClientContact" entities. */
export type QueryClientContactsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientContactsOrderByInput>>;
  /** Query conditions. */
  where?: ClientContactsWhereInput;
};

/** Quering input data for "ClientFile" entities. */
export type QueryClientFilesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientFilesOrderByInput>>;
  /** Query conditions. */
  where?: ClientFilesWhereInput;
};

/** Quering input data for "ClientGivenMedication" entities. */
export type QueryClientGivenMedicationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientObservationMedicationsOrderByInput>>;
  /** Query conditions. */
  where?: ClientObservationMedicationsWhereInput;
};

/** Input data for validating existance of a client's file. */
export type QueryClientHasFileInput = {
  /** Client file type */
  type: Scalars['ClientFileTypeScaler'];
};

/** Quering input data for "ClientMedication" entities. */
export type QueryClientMedicationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientMedicationsOrderByInput>>;
  /** Query conditions. */
  where?: ClientMedicationsWhereInput;
};

/** Quering input data. */
export type QueryClientNdisPlanInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientNdisPlanOrderByInput>>;
  /** Query conditions. */
  where?: ClientNdisPlanWhereInput;
};

/** Quering input data. */
export type QueryClientNdisPlanItemInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientNdisPlanItemOrderByInput>>;
  /** Query conditions. */
  where?: ClientNdisPlanItemWhereInput;
};

/** Quering input data. */
export type QueryClientNdisPlanItemNameInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientNdisPlanItemNameOrderByInput>>;
  /** Query conditions. */
  where?: ClientNdisPlanItemNameWhereInput;
};

export type QueryClientNdisPlanItemSpentFundsInput = {
  /** Date. */
  from: Scalars['Date'];
  /** Date. */
  to: Scalars['Date'];
};

/** Quering input data. */
export type QueryClientNdisPlanItemTransactionInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientNdisPlanItemTransactionOrderByInput>>;
  /** Query conditions. */
  where?: ClientNdisPlanItemTransactionWhereInput;
};

/** Quering input data. */
export type QueryClientNdisPlanItemTransactionSuppliersInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<
    Array<ClientNdisPlanItemTransactionSuppliersOrderByInput>
  >;
  /** Query conditions. */
  where?: ClientNdisPlanItemTransactionSuppliersWhereInput;
};

/** Quering input data for "ClientNoteFile" entities. */
export type QueryClientNoteFilesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientNoteFilesOrderByInput>>;
  /** Query conditions. */
  where?: ClientNoteFilesWhereInput;
};

/** Quering input data for entities. */
export type QueryClientNoteKeywordMatchesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientNoteKeywordMatchesOrderByInput>>;
  /** Query conditions. */
  where?: ClientNoteKeywordMatchesWhereInput;
};

/** Quering input data for entities. */
export type QueryClientNoteKeywordsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientNoteKeywordsOrderByInput>>;
  /** Query conditions. */
  where?: ClientNoteKeywordsWhereInput;
};

/** Quering input data for entities. */
export type QueryClientNotesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientNotesOrderByInput>>;
  /** Query conditions. */
  where?: ClientNotesWhereInput;
};

/** Quering input data for "ClientGivenMedication" entities. */
export type QueryClientObservationFoodsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientObservationFoodsOrderByInput>>;
  /** Query conditions. */
  where?: ClientObservationFoodsWhereInput;
};

/** Quering input data for "ClientObservation" entities. */
export type QueryClientObservationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientObservationsOrderByInput>>;
  /** Query conditions. */
  where?: ClientObservationsWhereInput;
};

/** Quering input data for "ClientTeam" entities. */
export type QueryClientTeamsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientTeamsOrderByInput>>;
  /** Query conditions. */
  where?: ClientTeamsWhereInput;
};

/** Quering input data for "Client" entities. */
export type QueryClientsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ClientsOrderByInput>>;
  /** Query conditions. */
  where?: ClientsWhereInput;
};

/** Quering input data. */
export type QueryConflictsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<ConflictsOrderByInput>>;
  /** Query conditions. */
  where?: ConflictsWhereInput;
};

/** Quering input data. */
export type QueryEventClaimsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<EventClaimsOrderByInput>>;
  /** Query conditions. */
  where?: EventClaimsWhereInput;
};

/** Quering input data. */
export type QueryEventConflictsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<EventConflictsOrderByInput>>;
  /** Query conditions. */
  where?: EventConflictsWhereInput;
};

/** Quering input data for "EventFile" entities. */
export type QueryEventFilesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<EventFilesOrderByInput>>;
  /** Query conditions. */
  where?: EventFilesWhereInput;
};

/** Quering input data. */
export type QueryEventIssuesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<EventIssuesOrderByInput>>;
  /** Query conditions. */
  where?: EventIssuesWhereInput;
};

/** Quering input data for "Event" entities. */
export type QueryEventsInput = {
  /** Apply filter. */
  entityFilters?: InputMaybe<Array<EventFilter>>;
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<EventsOrderByInput>>;
  /** Query conditions. */
  where?: EventsWhereInput;
};

/** Quering input data for "EventOwnAndRelated" entities. */
export type QueryEventsOwnAndRelatedInput = {
  /** Filter by client. */
  client?: InputMaybe<QueryByIdInput>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<EventsOwnAndRelatedOrderByInput>>;
  /** Query conditions. */
  where?: EventsOwnAndRelatedWhereInput;
};

/** Quering input data for potential event attendees. */
export type QueryEventsPotentialAttendeesInput = {
  /** Client ID. */
  clientId: Scalars['String'];
  /** Period starting datetime. */
  endAt: Scalars['DateTime'];
  /** Period starting datetime. */
  startAt: Scalars['DateTime'];
};

/** Quering input data for "History" sub-entities. */
export type QueryHistoriesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<HistoriesOrderByInput>>;
  /** Query conditions. */
  where?: HistoriesWhereInput;
};

/** Quering input data for "Medication" entities. */
export type QueryMedicationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MedicationsOrderByInput>>;
  /** Query conditions. */
  where?: MedicationsWhereInput;
};

/** Quering input data for "MemberBasePayRate" entities. */
export type QueryMemberBasePayRatesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberBasePayRatesOrderByInput>>;
  /** Query conditions. */
  where?: MemberBasePayRatesWhereInput;
};

/** Quering input data for "MemberBonusEligible" entities. */
export type QueryMemberBonusEligiblesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberBonusEligiblesOrderByInput>>;
  /** Query conditions. */
  where?: MemberBonusEligiblesWhereInput;
};

/** Quering input data for "MemberBranchAllocation" entities. */
export type QueryMemberBranchAllocationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberBranchAllocationsOrderByInput>>;
  /** Query conditions. */
  where?: MeMemberBranchAllocationsWhereInput;
};

/**  Quering input data for emailing worked periods to "Member" entities. */
export type QueryMemberClockedPeriodsInput = {
  /** Period starting datetime. */
  from: Scalars['DateTime'];
  /** Entity ID. */
  id: Scalars['ID'];
  /** Period ending datetime. */
  to: Scalars['DateTime'];
};

/** Quering input data for "MemberFile" entities. */
export type QueryMemberFilesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberFilesOrderByInput>>;
  /** Query conditions. */
  where?: MemberFilesWhereInput;
};

/** Quering input data for "MemberHour" entities. */
export type QueryMemberHoursInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberHoursOrderByInput>>;
  /** Query conditions. */
  where?: MemberHoursWhereInput;
};

/** Quering input data for "MemberInvitationSender" entities. */
export type QueryMemberInvitationSendersInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberInvitationSendersOrderByInput>>;
  /** Query conditions. */
  where?: MemberInvitationSendersWhereInput;
};

/** Quering input data for "MemberInvitation" entities. */
export type QueryMemberInvitationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberInvitationsOrderByInput>>;
  /** Query conditions. */
  where?: MemberInvitationsWhereInput;
};

/** Quering input data for "MemberPayrollCheck" entities. */
export type QueryMemberPayrollChecksInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberPayrollChecksOrderByInput>>;
  /** Query conditions. */
  where?: MemberPayrollChecksWhereInput;
};

export type QueryMemberPostNominalsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberPostNominalsOrderByInput>>;
  /** Query conditions. */
  where?: MemberPostNominalsWhereInput;
};

/** Quering input data for "MemberSchedule" objects. */
export type QueryMemberScheduleInput = {
  /** Period ending datetime. */
  endAt: Scalars['Date'];
  /** Period starting datetime. */
  startAt: Scalars['Date'];
};

/** Quering input data for "MemberScheduleTimeLimit" entities. */
export type QueryMemberScheduleTimeLimitsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberScheduleTimeLimitsOrderByInput>>;
  /** Query conditions. */
  where?: MemberScheduleTimeLimitsWhereInput;
};

/** Quering input data for "MemberSchedule" objects. */
export type QueryMemberSchedulesInput = {
  dates: Array<QueryMemberScheduleInput>;
};

/** Quering input data. */
export type QueryMemberUnavailableConflictsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberUnavailableConflictsOrderByInput>>;
  /** Query conditions. */
  where?: MemberUnavailableConflictsWhereInput;
};

/** Quering input data for "MemberUnavailable" entities. */
export type QueryMemberUnavailablesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberUnavailablesOrderByInput>>;
  /** Query conditions. */
  where?: MemberUnavailablesWhereInput;
};

/** Quering input data for "MemberUpload" entities. */
export type QueryMemberUploadsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberUploadsOrderByInput>>;
  /** Query conditions. */
  where?: MemberUploadsWhereInput;
};

/** Quering input data for "Member" entities. */
export type QueryMembersInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MembersOrderByInput>>;
  /** Query conditions. */
  where?: MembersWhereInput;
};

/** Quering input data for "MemberUnavailable" entities. */
export type QueryMyMemberUnavailablesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<MemberUnavailablesOrderByInput>>;
};

/** Quering input data for "NdisActivity" entities. */
export type QueryNdisActivitiesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<NdisActivitiesOrderByInput>>;
  /** Query conditions. */
  where?: NdisActivitiesWhereInput;
};

export type QueryNdisCoordinationClientActivityInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<NdisCoordinationClientActivityOrderByInput>>;
  /** Query conditions. */
  where?: NdisCoordinationClientActivityWhereInput;
};

export type QueryNdisCoordinationClientInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<NdisCoordinationClientOrderByInput>>;
  /** Query conditions. */
  where?: NdisCoordinationClientWhereInput;
};

/** Quering input data for "NdisRegistrationGroup" entities. */
export type QueryNdisRegistrationGroupsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<NdisRegistrationGroupsOrderByInput>>;
  /** Query conditions. */
  where?: NdisRegistrationGroupsWhereInput;
};

/** Quering input data for "NdisSupportCategory" entities. */
export type QueryNdisSupportCategoriesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<NdisSupportCategoriesOrderByInput>>;
  /** Query conditions. */
  where?: NdisSupportCategoriesWhereInput;
};

/** Quering input data for "NdisSupportItem" entities. */
export type QueryNdisSupportItemsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<NdisSupportItemsOrderByInput>>;
  /** Query conditions. */
  where?: NdisSupportItemsWhereInput;
};

/** Quering input data for "Notification" entities. */
export type QueryNotificationsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<NotificationsOrderByInput>>;
  /** Query conditions. */
  where?: NotificationsWhereInput;
};

/** Quering input data for "Org" entities. */
export type QueryOrgsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<OrgsOrderByInput>>;
  /** Query conditions. */
  where?: OrgsWhereInput;
};

/** Quering input data for "PayrollCategory" entities. */
export type QueryPayrollCategoriesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<PayrollCategoriesOrderByInput>>;
  /** Query conditions. */
  where?: PayrollCategoriesWhereInput;
};

/** Quering input data for "PayrollCategoryValue" entities. */
export type QueryPayrollCategoryValuesInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<PayrollCategoryValuesOrderByInput>>;
  /** Query conditions. */
  where?: PayrollCategoryValuesWhereInput;
};

/** Quering input data for "PlanManager" entities. */
export type QueryPlanManagersInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<PlanManagersOrderByInput>>;
  /** Query conditions. */
  where?: PlanManagersWhereInput;
};

/** Quering input data for "PublicHoliday" entities. */
export type QueryPublicHolidaysInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<PublicHolidaysOrderByInput>>;
  /** Query conditions. */
  where?: PublicHolidaysWhereInput;
};

/** Quering input data for "RedactedClient" entities. */
export type QueryRedactedClientsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<RedactedClientsOrderByInput>>;
  /** Query conditions. */
  where?: RedactedClientsWhereInput;
};

/** Quering input data for "RedactedMember" entities. */
export type QueryRedactedMembersInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<RedactedMembersOrderByInput>>;
  /** Query conditions. */
  where?: RedactedMembersWhereInput;
};

/** Quering input data for the "Schedule" entity. */
export type QueryScheuleMembersInput = {
  /** Period starting datetime. */
  date: Scalars['DateTime'];
  /** Members. */
  members: QueryMembersInput;
};

/** Quering input data for "SupportCoordinator" entities. */
export type QuerySupportCoordinatorsInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<SupportCoordinatorsOrderByInput>>;
  /** Query conditions. */
  where?: SupportCoordinatorsWhereInput;
};

/** Quering input data for "UserLogin" entities. */
export type QueryUserLoginsInput = {
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<UserLoginsOrderByInput>>;
  /** Query conditions. */
  where?: UserLoginsWhereInput;
};

/** Quering input data for "VisaType" entities. */
export type QueryVisaTypesInput = {
  /** Filter by entity state(s). */
  entityStates?: InputMaybe<Array<EntityState>>;
  /** Limit value. */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset value. */
  offset?: InputMaybe<Scalars['Int']>;
  /** Ordering options. */
  orderBy?: InputMaybe<Array<VisaTypesOrderByInput>>;
  /** Query conditions. */
  where?: VisaTypesWhereInput;
};

/** A redacted client of an organisation. */
export type RedactedClient = Address &
  Person & {
    __typename?: 'RedactedClient';
    /** Age in years. */
    age?: Maybe<Scalars['Int']>;
    /** Allow automatic population of data for unattended future events. */
    allowAutomaticEventPopulation: Scalars['Boolean'];
    /** Allow recording of medication. */
    allowMedication: Scalars['Boolean'];
    /** Allow recording of observations. */
    allowObservations: Scalars['Boolean'];
    /** Allow recording of seizures. */
    allowRecordSeizures: Scalars['Boolean'];
    /** Diastolic blood pressure lower-limit warning threshold (level 1). */
    bloodDiastolicLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure lower-limit warning threshold (level 2). */
    bloodDiastolicLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure upper-limit warning threshold (level 1). */
    bloodDiastolicUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Diastolic blood pressure upper-limit warning threshold (level 2). */
    bloodDiastolicUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure lower-limit warning threshold (level 1). */
    bloodSystolicLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure lower-limit warning threshold (level 2). */
    bloodSystolicLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure upper-limit warning threshold (level 1). */
    bloodSystolicUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Systolic blood pressure upper-limit warning threshold (level 2). */
    bloodSystolicUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Address building name. */
    buildingName?: Maybe<Scalars['String']>;
    /** Hexidecimal color. */
    color?: Maybe<Scalars['HexColorCode']>;
    /** Address country. */
    country?: Maybe<AddressCountryCode>;
    /** First name. */
    firstName: Scalars['String'];
    /** Gender. */
    gender?: Maybe<Gender>;
    hasFile: Scalars['Boolean'];
    /** Heart rate lower-limit warning threshold (level 1). */
    heartLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Heart rate lower-limit warning threshold. (level 2) */
    heartLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Heart rate upper-limit warning threshold (level 1). */
    heartUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Heart rate upper-limit warning threshold (level 2). */
    heartUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Unique identifier of the entity. */
    id: Scalars['ID'];
    /** Last name. */
    lastName?: Maybe<Scalars['String']>;
    /** Address latitude coordinate. */
    latitude?: Maybe<Scalars['String']>;
    /** Address suburb or town. */
    locality?: Maybe<Scalars['String']>;
    /** Address longitude coordinate. */
    longitude?: Maybe<Scalars['String']>;
    medications?: Maybe<Array<ClientMedication>>;
    /** Middle name. */
    middleName?: Maybe<Scalars['String']>;
    /** Address postcode. */
    postcode?: Maybe<Scalars['PostalCode']>;
    /** Preferred name. */
    preferredName?: Maybe<Scalars['String']>;
    /** Pronouns. */
    pronouns?: Maybe<Pronouns>;
    /** Address state or territory. */
    region?: Maybe<AddressRegionCode>;
    /** Event requirement: Handover. */
    requireHandover: Scalars['Boolean'];
    /** Respiration lower-limit warning threshold. (level 1) */
    respirationLowerThreshold1?: Maybe<Scalars['Int']>;
    /** Respiration lower-limit warning threshold. (level 2) */
    respirationLowerThreshold2?: Maybe<Scalars['Int']>;
    /** Respiration upper-limit warning threshold. (level 1) */
    respirationUpperThreshold1?: Maybe<Scalars['Int']>;
    /** Respiration upper-limit warning threshold. (level 2) */
    respirationUpperThreshold2?: Maybe<Scalars['Int']>;
    /** Salutation. */
    salutation?: Maybe<Salutation>;
    /** Shortened name. */
    shortName: Scalars['String'];
    /** Spo2 lower-limit warning threshold. (level 1) */
    spo2LowerThreshold1?: Maybe<Scalars['Int']>;
    /** Spo2 lower-limit warning threshold. (level 2) */
    spo2LowerThreshold2?: Maybe<Scalars['Int']>;
    /** Spo2 upper-limit warning threshold. (level 1) */
    spo2UpperThreshold1?: Maybe<Scalars['Int']>;
    /** Spo2 upper-limit warning threshold. (level 2) */
    spo2UpperThreshold2?: Maybe<Scalars['Int']>;
    /** Address street number, name and type. */
    street?: Maybe<Scalars['String']>;
    /** Timezone. */
    timezone: Timezone;
    /** Address unit number. */
    unit?: Maybe<Scalars['String']>;
  };

/** A redacted client of an organisation. */
export type RedactedClientHasFileArgs = {
  input: QueryClientHasFileInput;
};

/** A redacted client of an organisation. */
export type RedactedClientMedicationsArgs = {
  input?: InputMaybe<QueryClientMedicationsInput>;
};

/** Aggregated response object for "RedactedClient" entities. */
export type RedactedClientsAggregate = {
  __typename?: 'RedactedClientsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<RedactedClient>;
};

/** Ordering options for "RedactedClient" entities. */
export type RedactedClientsOrderByInput = {
  /** Order by branch. */
  branch?: InputMaybe<BranchesOrderByInput>;
  /** Order by address country. */
  country?: InputMaybe<OrderBy>;
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by gender. */
  gender?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by address latitude. */
  latitude?: InputMaybe<OrderBy>;
  /** Order by address locality. */
  locality?: InputMaybe<OrderBy>;
  /** Order by address longitude. */
  longitude?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by address postcode. */
  postcode?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
  /** Order by address region. */
  region?: InputMaybe<OrderBy>;
  /** Order by address street. */
  street?: InputMaybe<OrderBy>;
  /** Order by address unit. */
  unit?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "RedactedClient" entities. */
export type RedactedClientsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<RedactedClientsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<RedactedClientsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<RedactedClientsWhereInput>>;
  /** Branch. */
  branch?: InputMaybe<BranchesWhereInput>;
  /** Color. */
  color?: InputMaybe<StringConditionalInput>;
  /** Address country. */
  country?: InputMaybe<StringConditionalInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Gender. */
  gender?: InputMaybe<IntConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Address latitude. */
  latitude?: InputMaybe<StringConditionalInput>;
  /** Address locality. */
  locality?: InputMaybe<StringConditionalInput>;
  /** Address longitude. */
  longitude?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Address postcode. */
  postcode?: InputMaybe<StringConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Pronouns. */
  pronouns?: InputMaybe<IntConditionalInput>;
  /** Address region. */
  region?: InputMaybe<StringConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
  /** Address street. */
  street?: InputMaybe<StringConditionalInput>;
  /** Address unit. */
  unit?: InputMaybe<StringConditionalInput>;
};

/** A redacted member of an organisation. */
export type RedactedMember = Person & {
  __typename?: 'RedactedMember';
  /** Hexidecimal color. */
  color?: Maybe<Scalars['HexColorCode']>;
  /** Resolver for "currentPostNominal" field. */
  currentPostNominal?: Maybe<Scalars['String']>;
  /** Email address. */
  email?: Maybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: Maybe<Gender>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Last name. */
  lastName?: Maybe<Scalars['String']>;
  lateCount: Array<Scalars['Int']>;
  /** Middle name. */
  middleName?: Maybe<Scalars['String']>;
  /** Phone number. */
  phone?: Maybe<Scalars['String']>;
  /** Preferred name. */
  preferredName?: Maybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: Maybe<Pronouns>;
  /** Salutation. */
  salutation?: Maybe<Salutation>;
  /** Shortened name. */
  shortName: Scalars['String'];
  /** Timezone. */
  timezone: Timezone;
};

/** Aggregated response object for "RedactedMember" entities. */
export type RedactedMembersAggregate = {
  __typename?: 'RedactedMembersAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<RedactedMember>;
};

/** Ordering options for "RedactedMember" entities. */
export type RedactedMembersOrderByInput = {
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by gender. */
  gender?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "RedactedMember" entities. */
export type RedactedMembersWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<RedactedMembersWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<RedactedMembersWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<RedactedMembersWhereInput>>;
  /** BranchMember associative entities. */
  branchMembers?: InputMaybe<BranchMembersWhereInput>;
  /** Color. */
  color?: InputMaybe<StringConditionalInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Gender. */
  gender?: InputMaybe<IntConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Pronouns. */
  pronouns?: InputMaybe<IntConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
};

/** User identifier verification resend input */
export type ResendUserIdentifierVerificationInput = {
  /** Type of identifier whose verification token is to be resent */
  type: UserIdentifierType;
};

/** User password-reset input data. */
export type ResetUserPasswordInput = {
  /** Email address or phone number. */
  identifier: Scalars['UserIdentifier'];
};

/** Input data for restoring entities. */
export type RestoreInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  type: EntityRestorableState;
};

/** Salutation */
export enum Salutation {
  /** Dr */
  DR = 'DR',
  /** Master */
  MASTER = 'MASTER',
  /** Miss */
  MISS = 'MISS',
  /** Mr */
  MR = 'MR',
  /** Mrs */
  MRS = 'MRS',
  /** Ms */
  MS = 'MS',
  /** Mx */
  MX = 'MX',
  /** Not specified */
  NOTSPECIFIED = 'NOTSPECIFIED',
}

export type Schedule = {
  __typename?: 'Schedule';
  /** Events. */
  events?: Maybe<Array<ScheduleEvent>>;
  /** Members. */
  members?: Maybe<Array<ScheduleMember>>;
};

export type ScheduleEventsArgs = {
  input?: InputMaybe<QueryEventsInput>;
};

export type ScheduleMembersArgs = {
  input?: InputMaybe<QueryScheuleMembersInput>;
};

export type ScheduleEvent = {
  __typename?: 'ScheduleEvent';
  /** Conflict reason. */
  conflictReason?: Maybe<Scalars['String']>;
  /** Event. */
  event: Event;
};

export type ScheduleMember = {
  __typename?: 'ScheduleMember';
  /** Current shift. */
  currentEvent?: Maybe<Event>;
  /** Fortnight scheduled minutes. */
  fortnightTime: Scalars['Int'];
  /** Member. */
  member: Member;
  /** Next shift. */
  nextEvent?: Maybe<Event>;
  /** Week scheduled minutes. */
  weekTime: Scalars['Int'];
};

/** Seizure lost consciousness. */
export enum SeizureLostConsciousness {
  NO = 'NO',
  NOT_RECORDED = 'NOT_RECORDED',
  UNKNOWN = 'UNKNOWN',
  YES = 'YES',
}

/** Seizure type. */
export enum SeizureType {
  /** Absence seizures, sometimes called petit mal seizures, can cause rapid blinking or a few seconds of staring into space. */
  Absence = 'Absence',
  Atonic = 'Atonic',
  AtonicTonic = 'AtonicTonic',
  AtypicalAbsense = 'AtypicalAbsense',
  Clonic = 'Clonic',
  Myoclonic = 'Myoclonic',
  Tonic = 'Tonic',
  /**  Tonic-clonic seizures, also called grand mal seizures, can make a person: cry out, lose consciousness, fall to the ground, have muscle jerks or spasms. */
  TonicClonic = 'TonicClonic',
  Unknown = 'Unknown',
}

/** Input data for setting a clients medication. */
export type SetClientMedicationsInput = {
  /** Client input data. */
  client: QueryByIdInput;
  /** Medication ids. */
  medication: QueryByIdsInput;
};

/** Input data for setting which "Member" entities belong to a given "Client" entity's team. */
export type SetClientTeamMembersInput = {
  /** Client input data. */
  client: QueryByIdInput;
  objects: Array<ClientTeamsSettableFieldsInput>;
};

/** Creation input data. */
export type SetMemberBankInput = {
  /** Bank details - Account Name. */
  accountName?: InputMaybe<Scalars['String']>;
  /** Bank details - Account Number. */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** Bank details - BSB. */
  bsb?: InputMaybe<Scalars['String']>;
};

/** Settable input data for an entity. */
export type SetMemberPayrollCheckInput = {
  /** Clocks-checked status. */
  clocksChecked?: InputMaybe<Scalars['Boolean']>;
  /** Member input data. */
  events?: InputMaybe<QueryByIdsInput>;
  /** Events-checked status. */
  eventsChecked?: InputMaybe<Scalars['Boolean']>;
  /** Member input data. */
  member: QueryByIdInput;
  /** Paid status. */
  paid?: InputMaybe<Scalars['Boolean']>;
  /** Start date of payroll period. */
  payrollPeriodStartAt: Scalars['Date'];
};

/** Creation input data. */
export type SetMemberSuperInput = {
  /** Superannutation fund account name. */
  accountName?: InputMaybe<Scalars['String']>;
  /** Superannutation fund account number. */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** Australian Business Number of the superannutation fund. */
  fundAbn?: InputMaybe<Scalars['ABN']>;
  /** Superannutation fund name. */
  fundName?: InputMaybe<Scalars['String']>;
  /** Unique superannuation identifier (USI). */
  usi?: InputMaybe<Scalars['String']>;
};

/** Creation input data. */
export type SetMemberTaxFileNumberDeclarationInput = {
  /** Made a separate application/enquiry to the ATO for a new or existing TFN. */
  appliedForTfn?: InputMaybe<Scalars['Boolean']>;
  /** Basis of pay. */
  areYou?: InputMaybe<MemberTaxFileNumberDeclarationAreYou>;
  /** Basis of pay. */
  basisOfPay?: InputMaybe<MemberTaxFileNumberDeclarationBasisOfPay>;
  /** Do you want to claim the tax-free threshold from this payer? */
  claimTaxFreeThreshold?: InputMaybe<Scalars['Boolean']>;
  /** Claiming an exemption because I am under 18 years of age and do not earn enough to pay tax. */
  claimingExemptionMinor?: InputMaybe<Scalars['Boolean']>;
  /** Claiming an exemption because I am in receipt of a pension, benefit or allowance. */
  claimingExemptionPension?: InputMaybe<Scalars['Boolean']>;
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address. */
  email?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Address suburb or town. */
  locality?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Address postcode. */
  postcode?: InputMaybe<Scalars['PostalCode']>;
  /** Previous last name. */
  prevLastName?: InputMaybe<Scalars['String']>;
  /** Address state or territory. */
  region?: InputMaybe<AddressRegionCode>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** Address street number, name and type. */
  street?: InputMaybe<Scalars['String']>;
  /** Do you have a Higher Education Loan Program (HELP), VET Student Loan (VSL), Financial Supplement (FS), Student Start-up Loan (SSL) or Trade Support Loan (TSL) debt? */
  studentLoanDebt?: InputMaybe<Scalars['Boolean']>;
  /** Tax File Number (TFN). */
  tfn?: InputMaybe<Scalars['String']>;
};

/** Creation input data. */
export type SetOrgBillingInput = {
  /** Afternoon rate */
  afternoon?: InputMaybe<Scalars['Int']>;
  /** Day rate */
  day?: InputMaybe<Scalars['Int']>;
  /** KM rate */
  kms?: InputMaybe<Scalars['Int']>;
  /** Night rate */
  night?: InputMaybe<Scalars['Int']>;
  /** Overnight rate */
  overnight?: InputMaybe<Scalars['Int']>;
  /** Public holiday rate */
  publicHoliday?: InputMaybe<Scalars['Int']>;
  /** Saturday rate */
  saturday?: InputMaybe<Scalars['Int']>;
  /** Sunday rate */
  sunday?: InputMaybe<Scalars['Int']>;
};

export type SetUserPasswordInput = {
  /** New password. */
  password: Scalars['UserPlainTextPassword'];
  /** Verification token. */
  token: Scalars['UUID'];
};

/** Bristol Stool Chart Type. */
export enum StoolType {
  NIL = 'NIL',
  /** Type 1 */
  TYPE1 = 'TYPE1',
  /** Type 2 */
  TYPE2 = 'TYPE2',
  /** Type 3 */
  TYPE3 = 'TYPE3',
  /** Type 4 */
  TYPE4 = 'TYPE4',
  /** Type 5 */
  TYPE5 = 'TYPE5',
  /** Type 6 */
  TYPE6 = 'TYPE6',
  /** Type 7 */
  TYPE7 = 'TYPE7',
}

/** String conditional expression input. */
export type StringConditionalInput = {
  /** Equal. */
  _eq?: InputMaybe<Scalars['String']>;
  /** Greater than. */
  _gt?: InputMaybe<Scalars['String']>;
  /** Greater than or equal. */
  _gte?: InputMaybe<Scalars['String']>;
  /** Case-insensitive pattern matching. */
  _ilike?: InputMaybe<Scalars['String']>;
  /** In array. */
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** Pattern matching. */
  _like?: InputMaybe<Scalars['String']>;
  /** Less than. */
  _lt?: InputMaybe<Scalars['String']>;
  /** Less than or equal. */
  _lte?: InputMaybe<Scalars['String']>;
  /** Not equal. */
  _ne?: InputMaybe<Scalars['String']>;
  /** Not in array. */
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** Regular expression pattern matching. */
  _re?: InputMaybe<Scalars['String']>;
};

/** A support coordinator for NDIS participants. */
export type SupportCoordinator = {
  __typename?: 'SupportCoordinator';
  /** Australian Business Number of the organisation */
  abn?: Maybe<Scalars['ABN']>;
  /** Archive data, if entity is archived. */
  archive?: Maybe<HistoryRestorable>;
  /** Billing email address. */
  businessEmail?: Maybe<Scalars['String']>;
  /** Business name. */
  businessName?: Maybe<Scalars['String']>;
  /** Phone number. */
  businessPhone?: Maybe<Scalars['String']>;
  clients?: Maybe<Array<Client>>;
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy?: Maybe<Member>;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Contact email address. */
  email?: Maybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Middle name. */
  middleName?: Maybe<Scalars['String']>;
  /** Notes. */
  notes?: Maybe<Scalars['String']>;
  /** Organisation to which this support coordinator belongs. */
  org: Org;
  /** Phone number. */
  phone?: Maybe<Scalars['String']>;
  /** Preferred name. */
  preferredName?: Maybe<Scalars['String']>;
  /** Salutation. */
  salutation?: Maybe<Salutation>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** A support coordinator for NDIS participants. */
export type SupportCoordinatorClientsArgs = {
  input?: InputMaybe<QueryClientsInput>;
};

/** Aggregated response object for "SupportCoordinator" entities. */
export type SupportCoordinatorsAggregate = {
  __typename?: 'SupportCoordinatorsAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<SupportCoordinator>;
};

/** Creatable fields for "SupportCoordinator" entities. */
export type SupportCoordinatorsCreatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Billing email address. */
  businessEmail?: InputMaybe<Scalars['String']>;
  /** Business name. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  businessPhone?: InputMaybe<Scalars['String']>;
  /** Clients input data. */
  clients?: InputMaybe<QueryByIdsInput>;
  /** Contact email address. */
  email?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName: Scalars['String'];
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
};

/** Ordering options for "SupportCoordinator" entities. */
export type SupportCoordinatorsOrderByInput = {
  /** Order by Australian Business Number. */
  abn?: InputMaybe<OrderBy>;
  /** Order by business name. */
  businessName?: InputMaybe<OrderBy>;
  /** Order by datetime of creation. */
  createdAt?: InputMaybe<OrderBy>;
  /** Order by creator. */
  createdBy?: InputMaybe<MembersOrderByInput>;
  /** Order by first name. */
  firstName?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by last name. */
  lastName?: InputMaybe<OrderBy>;
  /** Order by middle name. */
  middleName?: InputMaybe<OrderBy>;
  /** Order by preferred name. */
  preferredName?: InputMaybe<OrderBy>;
};

/** Updatable fields for "SupportCoordinator" entities. */
export type SupportCoordinatorsUpdatableFieldsInput = {
  /** Australian Business Number of the organisation */
  abn?: InputMaybe<Scalars['ABN']>;
  /** Billing email address. */
  businessEmail?: InputMaybe<Scalars['String']>;
  /** Business name. */
  businessName?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  businessPhone?: InputMaybe<Scalars['String']>;
  /** Clients input data. */
  clients?: InputMaybe<QueryByIdsInput>;
  /** Contact email address. */
  email?: InputMaybe<Scalars['String']>;
  /** First name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middleName?: InputMaybe<Scalars['String']>;
  /** Notes. */
  notes?: InputMaybe<Scalars['String']>;
  /** Phone number. */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name. */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
};

/** Filtering conditions for "SupportCoordinator" entities. */
export type SupportCoordinatorsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<SupportCoordinatorsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<SupportCoordinatorsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<SupportCoordinatorsWhereInput>>;
  /** Australian Business Number. */
  abn?: InputMaybe<AbnConditionalInput>;
  /** Business email address. */
  businessEmail?: InputMaybe<StringConditionalInput>;
  /** Business name. */
  businessName?: InputMaybe<StringConditionalInput>;
  /** Business phone number. */
  businessPhone?: InputMaybe<PhoneConditionalInput>;
  /** Clients. */
  clients?: InputMaybe<ClientsWhereInput>;
  /** Datetime of entitys creation. */
  createdAt?: InputMaybe<DateTimeConditionalInput>;
  /** Creator of the entity. */
  createdBy?: InputMaybe<MembersWhereInput>;
  /** Email address. */
  email?: InputMaybe<StringConditionalInput>;
  /** First name. */
  firstName?: InputMaybe<StringConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Last name. */
  lastName?: InputMaybe<StringConditionalInput>;
  /** Middle name. */
  middleName?: InputMaybe<StringConditionalInput>;
  /** Notes. */
  notes?: InputMaybe<StringConditionalInput>;
  /** Phone number. */
  phone?: InputMaybe<PhoneConditionalInput>;
  /** Preferred name. */
  preferredName?: InputMaybe<StringConditionalInput>;
  /** Salutation. */
  salutation?: InputMaybe<IntConditionalInput>;
};

/** Australian timezone. */
export enum Timezone {
  AU_ADELAIDE = 'AU_ADELAIDE',
  AU_BRISBANE = 'AU_BRISBANE',
  AU_BROKEN_HILL = 'AU_BROKEN_HILL',
  AU_DARWIN = 'AU_DARWIN',
  AU_EUCLA = 'AU_EUCLA',
  AU_HOBART = 'AU_HOBART',
  AU_LINDEMAN = 'AU_LINDEMAN',
  AU_LORD_HOWE = 'AU_LORD_HOWE',
  AU_MELBOURNE = 'AU_MELBOURNE',
  AU_PERTH = 'AU_PERTH',
  AU_SYDNEY = 'AU_SYDNEY',
}

/** Input data for updating "Branch" entities. */
export type UpdateBranchesInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: BranchesUpdatableFieldsInput;
};

/** Input data for updating a single entity. */
export type UpdateClientContactInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: ClientContactsUpdatableFieldsInput;
};

/** Input data for updating "ClientFile" entities. */
export type UpdateClientFilesInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: ClientFilesUpdatableFieldsInput;
};

export type UpdateClientNdisPlanInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  patch: ClientNdisPlanUpdatableFieldsInput;
};

export type UpdateClientNdisPlanItemInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  patch: ClientNdisPlanItemUpdatableFieldsInput;
};

export type UpdateClientNdisPlanItemTransactionInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  patch: ClientNdisPlanItemTransactionUpdatableFieldsInput;
};

/** Input data for updating a "ClientNdisPlanItemTransactionSupplier" entity. */
export type UpdateClientNdisPlanItemTransactionSupplierInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to the discovered entity. */
  patch: ClientNdisPlanItemTransactionSuppliersUpdatableFieldsInput;
};

/** Input data for updating "ClientNoteFile" entities. */
export type UpdateClientNoteFilesInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: ClientNoteFilesUpdatableFieldsInput;
};

/** Input data for updating a singular entity. */
export type UpdateClientNoteInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: ClientNotesUpdatableFieldsInput;
};

/** Input data for updating a singular entity. */
export type UpdateClientNoteKeywordInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: ClientNoteKeywordsUpdatableFieldsInput;
};

/** Input data for updating a "ClientObservation" entity. */
export type UpdateClientObservationInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to the discovered entity. */
  patch: ClientObservationsUpdatableFieldsInput;
};

/** Input data for updating "Client" entities. */
export type UpdateClientsInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: ClientsUpdatableFieldsInput;
};

/** Input data for updating the basic fields of an "Event" entity. */
export type UpdateEventBasicFieldsInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: EventsUpdatableBasicFieldsInput;
};

/** Input data for updating "EventFile" entities. */
export type UpdateEventFilesInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: EventFilesUpdatableFieldsInput;
};

/** Input data for updating an "Event" entity. */
export type UpdateEventInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: EventsUpdatableFieldsInput;
  /** Unset clocked off data. */
  unsetClockedOff?: InputMaybe<Scalars['Boolean']>;
  /** Unset clocked on data. */
  unsetClockedOn?: InputMaybe<Scalars['Boolean']>;
};

/** Input data for updating entities. */
export type UpdateFileInput = {
  /** Update to apply to discovered entity. */
  patch: FilesUpdatableFieldsInput;
};

/** Input data for updating a "Medication" entity. */
export type UpdateMedicationInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to the discovered entity. */
  patch: MedicationsUpdatableFieldsInput;
};

/** Input data for updating a single entity. */
export type UpdateMemberBasePayRateInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: MemberBasePayRatesUpdatableFieldsInput;
};

/** Input data for updating a single entity. */
export type UpdateMemberBranchAllocationInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: MemberBranchAllocationsUpdatableFieldsInput;
};

/** Input data for updating the current users current "Member" entity. */
export type UpdateMemberCurrentInput = {
  /** Update to apply to the discovered entity. */
  patch: MembersCurrentUpdatableFieldsInput;
};

/** Input data for updating "MemberFile" entities. */
export type UpdateMemberFilesInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: MemberFilesUpdatableFieldsInput;
};

/** Input data for updating a single entity. */
export type UpdateMemberPostNominalInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: MemberPostNominalsUpdatableFieldsInput;
};

/** Input data for updating a "MemberUnavailable" entity. */
export type UpdateMemberUnavailableInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: MemberUnavailablesUpdatableFieldsInput;
};

/** Input data for updating "Member" entities. */
export type UpdateMembersInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: MembersUpdatableFieldsInput;
};

/** Update input data. */
export type UpdateNdisCoordinationClientActivityInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update patch to apply. */
  patch: NdisCoordinationClientActivityUpdatableFieldsInput;
};

/** Update input data. */
export type UpdateNdisCoordinationClientInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update patch to apply. */
  patch: NdisCoordinationClientUpdatableFieldsInput;
};

/** Input data for updating an "Org" entity. */
export type UpdateOrgInput = {
  /** Update to apply to discovered entity. */
  patch: OrgsUpdatableFieldsInput;
};

/** Input data for updating a "PayrollCategory" entity. */
export type UpdatePayrollCategoryInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: PayrollCategoriesUpdatableFieldsInput;
};

/** Input data for updating "PlanManager" entities. */
export type UpdatePlanManagersInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: PlanManagersUpdatableFieldsInput;
};

/** Input data for updating "SupportCoordinator" entities. */
export type UpdateSupportCoordinatorsInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Update to apply to each discovered entity. */
  patch: SupportCoordinatorsUpdatableFieldsInput;
};

/** Input data for updating the current user. */
export type UpdateUserInput = {
  /** Updated data to apply to the current user. */
  patch: UserUpdatableFieldsInput;
};

/** Input data for updating singular "VisaType" entities. */
export type UpdateVisaTypeInput = {
  /** Entity ID. */
  id: Scalars['ID'];
  /** Update to apply to discovered entity. */
  patch: VisaTypesUpdatableFieldsInput;
};

/** Urine color. */
export enum UrineColor {
  /** Brown */
  BROWN = 'BROWN',
  /** Clear */
  CLEAR = 'CLEAR',
  /** Dark yellow */
  DARKYELLOW = 'DARKYELLOW',
  /** Light yellow */
  LIGHTYELLOW = 'LIGHTYELLOW',
  /** Yellow */
  YELLOW = 'YELLOW',
}

/** A user account */
export type User = Person & {
  __typename?: 'User';
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Current password data. */
  currentPassword: UserPassword;
  /** Date of birth. */
  dob?: Maybe<Scalars['Date']>;
  /** Email address */
  email?: Maybe<Scalars['String']>;
  /** Datetime of when email address was last set. */
  emailSetAt?: Maybe<Scalars['DateTime']>;
  /** Datetime of successful email address verification. */
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  /** First name */
  firstName: Scalars['String'];
  /** Gender. */
  gender?: Maybe<Gender>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** Recent successful user account logins. */
  logins: Array<UserLogin>;
  /** User's mailing list preferrence. */
  mailingList: Scalars['Boolean'];
  /** User's currently-active member profile. */
  member?: Maybe<Member>;
  /** Member profiles belonging to this user. */
  members: Array<Member>;
  /** Middle name */
  middleName?: Maybe<Scalars['String']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']>;
  /** Datetime of when phone number was last set. */
  phoneSetAt?: Maybe<Scalars['DateTime']>;
  /** Datetime of successful phone number verification. */
  phoneVerifiedAt?: Maybe<Scalars['DateTime']>;
  /** Preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** Most recent successful log in. */
  previousLogin: UserLogin;
  /** Pronouns. */
  pronouns?: Maybe<Pronouns>;
  /** Salutation. */
  salutation?: Maybe<Salutation>;
  /** User's chosen application theme. */
  theme?: Maybe<UserTheme>;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** A user account */
export type UserLoginsArgs = {
  input?: InputMaybe<QueryUserLoginsInput>;
};

/** A user account */
export type UserMembersArgs = {
  input?: InputMaybe<QueryMembersInput>;
};

/** Type of unique user identifier */
export enum UserIdentifierType {
  /** Email address */
  EMAIL = 'EMAIL',
  /** Phone number */
  PHONE = 'PHONE',
}

/** Record of successful user login */
export type UserLogin = {
  __typename?: 'UserLogin';
  /** Datetime of successful login */
  date: Scalars['DateTime'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** IP address of client during successful login */
  ip: Scalars['String'];
};

/** "User" entity login response object. */
export type UserLoginResponse = {
  __typename?: 'UserLoginResponse';
  /** User authentication JWT. */
  token: Scalars['JWT'];
};

/** Ordering options for "UserLogin" entities. */
export type UserLoginsOrderByInput = {
  /** Order by datetime of entity creation. */
  date?: InputMaybe<OrderBy>;
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by identifier used. */
  identifierUsed?: InputMaybe<OrderBy>;
  /** Order by ip address. */
  ip?: InputMaybe<OrderBy>;
};

/** Filtering conditions for "UserLogin" entities. */
export type UserLoginsWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<UserLoginsWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<UserLoginsWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<UserLoginsWhereInput>>;
  /** Datetime of entitys creation. */
  date?: InputMaybe<DateTimeConditionalInput>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Identifier used. */
  identifierUsed?: InputMaybe<StringConditionalInput>;
  /** IP address used. */
  ip?: InputMaybe<StringConditionalInput>;
};

/** Current password data for "User" entities. */
export type UserPassword = {
  __typename?: 'UserPassword';
  /** Date password was set. */
  date: Scalars['DateTime'];
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** IP address of the user who set the password. */
  ip: Scalars['String'];
};

/** User's chosen application theme. */
export enum UserTheme {
  /** Dark theme. */
  DARK = 'DARK',
  /** Light theme. */
  LIGHT = 'LIGHT',
}

/** Update user patch data. */
export type UserUpdatableFieldsInput = {
  /** Date of birth. */
  dob?: InputMaybe<Scalars['Date']>;
  /** Email address */
  email?: InputMaybe<Scalars['String']>;
  /** First name */
  firstName?: InputMaybe<Scalars['String']>;
  /** Gender. */
  gender?: InputMaybe<Gender>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's mailing list preferrence. */
  mailingList?: InputMaybe<Scalars['Boolean']>;
  /** Middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /** Passwords must be at least 8 characters in length and contain a combination of lowercase, uppercase and special characters */
  password?: InputMaybe<Scalars['UserPlainTextPassword']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']>;
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']>;
  /** Pronouns. */
  pronouns?: InputMaybe<Pronouns>;
  /** Salutation. */
  salutation?: InputMaybe<Salutation>;
  /** User's chosen application theme. */
  theme?: InputMaybe<UserTheme>;
};

/** Input data for clocking on/off "Event" entities. */
export type VerifyEventsInput = {
  /** Entity IDs. */
  ids: Array<Scalars['ID']>;
  /** Verification status. */
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** User email address or phone number verification input */
export type VerifyUserIdentifierInput = {
  /** Verification token */
  token: Scalars['UUID'];
};

export type VisaType = {
  __typename?: 'VisaType';
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: Maybe<Scalars['Int']>;
  /** Allowed worked hours is waived. */
  allowedWorkHoursWaived: Scalars['Boolean'];
  /** Datetime of the entity's creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Creator of the entity. */
  createdBy: Member;
  /** Deletion data, if entity is deleted. */
  delete?: Maybe<HistoryRestorable>;
  /** Description. */
  description?: Maybe<Scalars['String']>;
  /** Unique identifier of the entity. */
  id: Scalars['ID'];
  /** Name of VISA. */
  name: Scalars['String'];
  /** Organisation. */
  org: Org;
  /** Datetime of the entity record's most recent update. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Aggregated response object for "VisaType" entities. */
export type VisaTypesAggregate = {
  __typename?: 'VisaTypesAggregate';
  /** Aggregation data. */
  aggregate: AggregateData;
  /** Discovered entities. */
  nodes: Array<VisaType>;
};

/** Ordering options for "VisaType" entities. */
export type VisaTypesOrderByInput = {
  /** Order by primary identifier key. */
  id?: InputMaybe<OrderBy>;
  /** Order by name. */
  name?: InputMaybe<OrderBy>;
};

/** Updatable fields for "VisaType" entities. */
export type VisaTypesUpdatableFieldsInput = {
  /** Allowed worked hours per fortnight. */
  allowedFortnightlyWorkHours?: InputMaybe<Scalars['Int']>;
  /** Allowed worked hours is waived. */
  allowedWorkHoursWaived?: InputMaybe<Scalars['Boolean']>;
  /** Description. */
  description?: InputMaybe<Scalars['String']>;
  /** Name of VISA. */
  name?: InputMaybe<Scalars['String']>;
};

/** Filtering conditions for "VisaType" entities. */
export type VisaTypesWhereInput = {
  /** Logical operator "and". */
  _and?: InputMaybe<Array<VisaTypesWhereInput>>;
  /** Logical operator "not". */
  _not?: InputMaybe<VisaTypesWhereInput>;
  /** Logical operator "or". */
  _or?: InputMaybe<Array<VisaTypesWhereInput>>;
  /** Primary identifier key. */
  id?: InputMaybe<IdConditionalInput>;
  /** Name. */
  name?: InputMaybe<StringConditionalInput>;
};

type AddressFull_Client_Fragment = {
  __typename?: 'Client';
  unit?: string | null;
  buildingName?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_Event_Fragment = {
  __typename?: 'Event';
  unit?: string | null;
  buildingName?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_EventOwnAndRelated_Fragment = {
  __typename?: 'EventOwnAndRelated';
  unit?: string | null;
  buildingName?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_Member_Fragment = {
  __typename?: 'Member';
  unit?: string | null;
  buildingName?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_NdisCoordinationClient_Fragment = {
  __typename?: 'NdisCoordinationClient';
  unit?: string | null;
  buildingName?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_NdisCoordinationClientActivity_Fragment = {
  __typename?: 'NdisCoordinationClientActivity';
  unit?: string | null;
  buildingName?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

type AddressFull_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  unit?: string | null;
  buildingName?: string | null;
  street?: string | null;
  locality?: string | null;
  region?: AddressRegionCode | null;
  postcode?: any | null;
  country?: AddressCountryCode | null;
};

export type AddressFullFragment =
  | AddressFull_Client_Fragment
  | AddressFull_Event_Fragment
  | AddressFull_EventOwnAndRelated_Fragment
  | AddressFull_Member_Fragment
  | AddressFull_NdisCoordinationClient_Fragment
  | AddressFull_NdisCoordinationClientActivity_Fragment
  | AddressFull_RedactedClient_Fragment;

type AddressGeolocation_Client_Fragment = {
  __typename?: 'Client';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_Event_Fragment = {
  __typename?: 'Event';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_EventOwnAndRelated_Fragment = {
  __typename?: 'EventOwnAndRelated';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_Member_Fragment = {
  __typename?: 'Member';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_NdisCoordinationClient_Fragment = {
  __typename?: 'NdisCoordinationClient';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_NdisCoordinationClientActivity_Fragment = {
  __typename?: 'NdisCoordinationClientActivity';
  latitude?: string | null;
  longitude?: string | null;
};

type AddressGeolocation_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  latitude?: string | null;
  longitude?: string | null;
};

export type AddressGeolocationFragment =
  | AddressGeolocation_Client_Fragment
  | AddressGeolocation_Event_Fragment
  | AddressGeolocation_EventOwnAndRelated_Fragment
  | AddressGeolocation_Member_Fragment
  | AddressGeolocation_NdisCoordinationClient_Fragment
  | AddressGeolocation_NdisCoordinationClientActivity_Fragment
  | AddressGeolocation_RedactedClient_Fragment;

export type BusinessNamesFragment = {
  __typename?: 'Org';
  legalName: string;
  preferredName?: string | null;
};

type PersonGenderIdentifiers_Client_Fragment = {
  __typename?: 'Client';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

type PersonGenderIdentifiers_Member_Fragment = {
  __typename?: 'Member';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

type PersonGenderIdentifiers_NdisCoordinationClient_Fragment = {
  __typename?: 'NdisCoordinationClient';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

type PersonGenderIdentifiers_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

type PersonGenderIdentifiers_RedactedMember_Fragment = {
  __typename?: 'RedactedMember';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

type PersonGenderIdentifiers_User_Fragment = {
  __typename?: 'User';
  gender?: Gender | null;
  salutation?: Salutation | null;
  pronouns?: Pronouns | null;
};

export type PersonGenderIdentifiersFragment =
  | PersonGenderIdentifiers_Client_Fragment
  | PersonGenderIdentifiers_Member_Fragment
  | PersonGenderIdentifiers_NdisCoordinationClient_Fragment
  | PersonGenderIdentifiers_RedactedClient_Fragment
  | PersonGenderIdentifiers_RedactedMember_Fragment
  | PersonGenderIdentifiers_User_Fragment;

type PersonNames_Client_Fragment = {
  __typename?: 'Client';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

type PersonNames_Member_Fragment = {
  __typename?: 'Member';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

type PersonNames_NdisCoordinationClient_Fragment = {
  __typename?: 'NdisCoordinationClient';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

type PersonNames_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

type PersonNames_RedactedMember_Fragment = {
  __typename?: 'RedactedMember';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

type PersonNames_User_Fragment = {
  __typename?: 'User';
  middleName?: string | null;
  preferredName?: string | null;
  firstName: string;
  lastName?: string | null;
};

export type PersonNamesFragment =
  | PersonNames_Client_Fragment
  | PersonNames_Member_Fragment
  | PersonNames_NdisCoordinationClient_Fragment
  | PersonNames_RedactedClient_Fragment
  | PersonNames_RedactedMember_Fragment
  | PersonNames_User_Fragment;

type PersonNamesPartial_Client_Fragment = {
  __typename?: 'Client';
  firstName: string;
  lastName?: string | null;
};

type PersonNamesPartial_Member_Fragment = {
  __typename?: 'Member';
  firstName: string;
  lastName?: string | null;
};

type PersonNamesPartial_NdisCoordinationClient_Fragment = {
  __typename?: 'NdisCoordinationClient';
  firstName: string;
  lastName?: string | null;
};

type PersonNamesPartial_RedactedClient_Fragment = {
  __typename?: 'RedactedClient';
  firstName: string;
  lastName?: string | null;
};

type PersonNamesPartial_RedactedMember_Fragment = {
  __typename?: 'RedactedMember';
  firstName: string;
  lastName?: string | null;
};

type PersonNamesPartial_User_Fragment = {
  __typename?: 'User';
  firstName: string;
  lastName?: string | null;
};

export type PersonNamesPartialFragment =
  | PersonNamesPartial_Client_Fragment
  | PersonNamesPartial_Member_Fragment
  | PersonNamesPartial_NdisCoordinationClient_Fragment
  | PersonNamesPartial_RedactedClient_Fragment
  | PersonNamesPartial_RedactedMember_Fragment
  | PersonNamesPartial_User_Fragment;

export type CreateBranchesMutationVariables = Exact<{
  input: CreateBranchesInput;
}>;

export type CreateBranchesMutation = {
  __typename?: 'Mutation';
  createBranches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
};

export type UpdateBranchesMutationVariables = Exact<{
  input: UpdateBranchesInput;
}>;

export type UpdateBranchesMutation = {
  __typename?: 'Mutation';
  updateBranches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
};

export type CreateClientContactMutationVariables = Exact<{
  input: CreateClientContactInput;
}>;

export type CreateClientContactMutation = {
  __typename?: 'Mutation';
  createClientContact: {
    __typename?: 'ClientContact';
    id: string;
    relations?: Array<ClientContactRelation> | null;
    customRelation?: string | null;
    name: string;
    email?: string | null;
    phone?: string | null;
    emergencyContact: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      preferredContact?: { __typename?: 'ClientContact'; id: string } | null;
    };
  };
};

export type DeleteClientContactsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientContactsMutation = {
  __typename?: 'Mutation';
  deleteClientContacts: Array<{
    __typename?: 'ClientContact';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientContactsMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientContactsMutation = {
  __typename?: 'Mutation';
  restoreClientContacts: Array<{
    __typename?: 'ClientContact';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateClientContactMutationVariables = Exact<{
  input: UpdateClientContactInput;
}>;

export type UpdateClientContactMutation = {
  __typename?: 'Mutation';
  updateClientContact: {
    __typename?: 'ClientContact';
    id: string;
    relations?: Array<ClientContactRelation> | null;
    customRelation?: string | null;
    name: string;
    email?: string | null;
    phone?: string | null;
    emergencyContact: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      preferredContact?: { __typename?: 'ClientContact'; id: string } | null;
    };
  };
};

export type CreateClientFileMutationVariables = Exact<{
  input: CreateClientFileInput;
}>;

export type CreateClientFileMutation = {
  __typename?: 'Mutation';
  createClientFile: {
    __typename?: 'ClientFile';
    id: string;
    type: ClientFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Client'; id: string };
    file: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      path: string;
      createdBy: { __typename?: 'Member'; id: string };
    };
  };
};

export type DeleteClientFilesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientFilesMutation = {
  __typename?: 'Mutation';
  deleteClientFiles: Array<{
    __typename?: 'ClientFile';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientFilesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientFilesMutation = {
  __typename?: 'Mutation';
  restoreClientFiles: Array<{
    __typename?: 'ClientFile';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateClientFilesMutationVariables = Exact<{
  input: UpdateClientFilesInput;
}>;

export type UpdateClientFilesMutation = {
  __typename?: 'Mutation';
  updateClientFiles: Array<{
    __typename?: 'ClientFile';
    id: string;
    type: ClientFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Client'; id: string };
    file: { __typename?: 'File'; id: string; name: string };
  }>;
};

export type SetClientMedicationsMutationVariables = Exact<{
  input: SetClientMedicationsInput;
}>;

export type SetClientMedicationsMutation = {
  __typename?: 'Mutation';
  setClientMedications: Array<{
    __typename?: 'ClientMedication';
    id: string;
    client: { __typename?: 'Client'; id: string };
    medication: { __typename?: 'Medication'; id: string };
  }>;
};

export type ArchiveClientNdisPlanItemTransactionSuppliersMutationVariables =
  Exact<{
    input: ArchiveInput;
  }>;

export type ArchiveClientNdisPlanItemTransactionSuppliersMutation = {
  __typename?: 'Mutation';
  archiveClientNdisPlanItemTransactionSuppliers: Array<{
    __typename?: 'ClientNdisPlanItemTransactionSupplier';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreateClientNdisPlanItemTransactionSupplierMutationVariables =
  Exact<{
    input: CreateClientNdisPlanItemTransactionSupplierInput;
  }>;

export type CreateClientNdisPlanItemTransactionSupplierMutation = {
  __typename?: 'Mutation';
  createClientNdisPlanItemTransactionSupplier: {
    __typename?: 'ClientNdisPlanItemTransactionSupplier';
    id: string;
    name: string;
  };
};

export type RestoreClientNdisPlanItemTransactionSuppliersMutationVariables =
  Exact<{
    input: QueryByIdsInput;
  }>;

export type RestoreClientNdisPlanItemTransactionSuppliersMutation = {
  __typename?: 'Mutation';
  restoreClientNdisPlanItemTransactionSuppliers: Array<{
    __typename?: 'ClientNdisPlanItemTransactionSupplier';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateClientNdisPlanItemTransactionSupplierMutationVariables =
  Exact<{
    input: UpdateClientNdisPlanItemTransactionSupplierInput;
  }>;

export type UpdateClientNdisPlanItemTransactionSupplierMutation = {
  __typename?: 'Mutation';
  updateClientNdisPlanItemTransactionSupplier: {
    __typename?: 'ClientNdisPlanItemTransactionSupplier';
    id: string;
    name: string;
  };
};

export type ArchiveClientNdisPlanItemTransactionsMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveClientNdisPlanItemTransactionsMutation = {
  __typename?: 'Mutation';
  archiveClientNdisPlanItemTransactions: Array<{
    __typename?: 'ClientNdisPlanItemTransaction';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreateClientNdisPlanItemTransactionMutationVariables = Exact<{
  input: CreateClientNdisPlanItemTransactionInput;
}>;

export type CreateClientNdisPlanItemTransactionMutation = {
  __typename?: 'Mutation';
  createClientNdisPlanItemTransaction: {
    __typename?: 'ClientNdisPlanItemTransaction';
    id: string;
    billingDate?: any | null;
    serviceDateStart?: any | null;
    serviceDateEnd?: any | null;
    invoiceNumber?: string | null;
    value: number;
    memo?: string | null;
    clientNdisPlanItem: { __typename?: 'ClientNdisPlanItem'; id: string };
  };
};

export type CreateClientNdisPlanItemTransactionsMutationVariables = Exact<{
  input: CreateClientNdisPlanItemTransactionsInput;
}>;

export type CreateClientNdisPlanItemTransactionsMutation = {
  __typename?: 'Mutation';
  createClientNdisPlanItemTransactions: Array<{
    __typename?: 'ClientNdisPlanItemTransaction';
    id: string;
    billingDate?: any | null;
    serviceDateStart?: any | null;
    serviceDateEnd?: any | null;
    invoiceNumber?: string | null;
    value: number;
    memo?: string | null;
    clientNdisPlanItem: { __typename?: 'ClientNdisPlanItem'; id: string };
  }>;
};

export type DeleteClientNdisPlanItemTransactionsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientNdisPlanItemTransactionsMutation = {
  __typename?: 'Mutation';
  deleteClientNdisPlanItemTransactions: Array<{
    __typename?: 'ClientNdisPlanItemTransaction';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientNdisPlanItemTransactionsMutationVariables = Exact<{
  input: RestoreInput;
}>;

export type RestoreClientNdisPlanItemTransactionsMutation = {
  __typename?: 'Mutation';
  restoreClientNdisPlanItemTransactions: Array<{
    __typename?: 'ClientNdisPlanItemTransaction';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateClientNdisPlanItemTransactionMutationVariables = Exact<{
  input: UpdateClientNdisPlanItemTransactionInput;
}>;

export type UpdateClientNdisPlanItemTransactionMutation = {
  __typename?: 'Mutation';
  updateClientNdisPlanItemTransaction: {
    __typename?: 'ClientNdisPlanItemTransaction';
    id: string;
    billingDate?: any | null;
    serviceDateStart?: any | null;
    serviceDateEnd?: any | null;
    invoiceNumber?: string | null;
    value: number;
    memo?: string | null;
    clientNdisPlanItem: { __typename?: 'ClientNdisPlanItem'; id: string };
  };
};

export type CreateClientNdisPlanItemMutationVariables = Exact<{
  input: CreateClientNdisPlanItemInput;
}>;

export type CreateClientNdisPlanItemMutation = {
  __typename?: 'Mutation';
  createClientNdisPlanItem: {
    __typename?: 'ClientNdisPlanItem';
    id: string;
    category?: ClientNdisPlanItemCategory | null;
    initialFunds: number;
    currentFunds: number;
    name: { __typename?: 'ClientNdisPlanItemName'; id: string; name: string };
    clientNdisPlan: {
      __typename?: 'ClientNdisPlan';
      id: string;
      coreSupportItem?: {
        __typename?: 'ClientNdisPlanItem';
        id: string;
      } | null;
      supportCoordinationItem?: {
        __typename?: 'ClientNdisPlanItem';
        id: string;
      } | null;
    };
  };
};

export type DeleteClientNdisPlanItemsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientNdisPlanItemsMutation = {
  __typename?: 'Mutation';
  deleteClientNdisPlanItems: Array<{
    __typename?: 'ClientNdisPlanItem';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientNdisPlanItemsMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientNdisPlanItemsMutation = {
  __typename?: 'Mutation';
  restoreClientNdisPlanItems: Array<{
    __typename?: 'ClientNdisPlanItem';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateClientNdisPlanItemMutationVariables = Exact<{
  input: UpdateClientNdisPlanItemInput;
}>;

export type UpdateClientNdisPlanItemMutation = {
  __typename?: 'Mutation';
  updateClientNdisPlanItem: {
    __typename?: 'ClientNdisPlanItem';
    id: string;
    initialFunds: number;
    currentFunds: number;
    clientNdisPlan: {
      __typename?: 'ClientNdisPlan';
      id: string;
      initialFunds: number;
      coreSupportItem?: {
        __typename?: 'ClientNdisPlanItem';
        id: string;
      } | null;
      supportCoordinationItem?: {
        __typename?: 'ClientNdisPlanItem';
        id: string;
      } | null;
    };
  };
};

export type CreateClientNdisPlanMutationVariables = Exact<{
  input: CreateClientNdisPlanInput;
}>;

export type CreateClientNdisPlanMutation = {
  __typename?: 'Mutation';
  createClientNdisPlan: {
    __typename?: 'ClientNdisPlan';
    id: string;
    startAt: any;
    endAt?: any | null;
    coreSupportItem?: { __typename?: 'ClientNdisPlanItem'; id: string } | null;
    supportCoordinationItem?: {
      __typename?: 'ClientNdisPlanItem';
      id: string;
    } | null;
  };
};

export type DeleteClientNdisPlansMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientNdisPlansMutation = {
  __typename?: 'Mutation';
  deleteClientNdisPlans: Array<{
    __typename?: 'ClientNdisPlan';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientNdisPlansMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientNdisPlansMutation = {
  __typename?: 'Mutation';
  restoreClientNdisPlans: Array<{
    __typename?: 'ClientNdisPlan';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateClientNdisPlanMutationVariables = Exact<{
  input: UpdateClientNdisPlanInput;
}>;

export type UpdateClientNdisPlanMutation = {
  __typename?: 'Mutation';
  updateClientNdisPlan: {
    __typename?: 'ClientNdisPlan';
    id: string;
    startAt: any;
    endAt?: any | null;
    coreSupportItem?: { __typename?: 'ClientNdisPlanItem'; id: string } | null;
    supportCoordinationItem?: {
      __typename?: 'ClientNdisPlanItem';
      id: string;
    } | null;
  };
};

export type CreateClientNoteFileMutationVariables = Exact<{
  input: CreateClientNoteFileInput;
}>;

export type CreateClientNoteFileMutation = {
  __typename?: 'Mutation';
  createClientNoteFile: {
    __typename?: 'ClientNoteFile';
    id: string;
    type: ClientNoteFileType;
    dateOfFile?: any | null;
    value?: string | null;
    owner: { __typename?: 'ClientNote'; id: string };
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      path: string;
      createdBy: { __typename?: 'Member'; id: string };
    };
  };
};

export type DeleteClientNoteFilesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientNoteFilesMutation = {
  __typename?: 'Mutation';
  deleteClientNoteFiles: Array<{
    __typename?: 'ClientNoteFile';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientNoteFilesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientNoteFilesMutation = {
  __typename?: 'Mutation';
  restoreClientNoteFiles: Array<{
    __typename?: 'ClientNoteFile';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateClientNoteFilesMutationVariables = Exact<{
  input: UpdateClientNoteFilesInput;
}>;

export type UpdateClientNoteFilesMutation = {
  __typename?: 'Mutation';
  updateClientNoteFiles: Array<{
    __typename?: 'ClientNoteFile';
    id: string;
    type: ClientNoteFileType;
    dateOfFile?: any | null;
    value?: string | null;
    owner: { __typename?: 'ClientNote'; id: string };
  }>;
};

export type CreateClientNoteKeywordMutationVariables = Exact<{
  input: CreateClientNoteKeywordInput;
}>;

export type CreateClientNoteKeywordMutation = {
  __typename?: 'Mutation';
  createClientNoteKeyword: {
    __typename?: 'ClientNoteKeyword';
    id: string;
    createdAt?: any | null;
    text: string;
    createdBy: { __typename?: 'Member'; id: string };
  };
};

export type DeleteClientNoteKeywordsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientNoteKeywordsMutation = {
  __typename?: 'Mutation';
  deleteClientNoteKeywords: Array<{
    __typename?: 'ClientNoteKeyword';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientNoteKeywordsMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientNoteKeywordsMutation = {
  __typename?: 'Mutation';
  restoreClientNoteKeywords: Array<{
    __typename?: 'ClientNoteKeyword';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type CreateClientNoteMutationVariables = Exact<{
  input: CreateClientNoteInput;
}>;

export type CreateClientNoteMutation = {
  __typename?: 'Mutation';
  createClientNote: {
    __typename?: 'ClientNote';
    id: string;
    createdAt?: any | null;
    type: ClientNoteType;
    comment?: string | null;
    commentedAt: any;
    commentedByText?: string | null;
    createdBy: { __typename?: 'Member'; id: string };
    client: { __typename?: 'Client'; id: string };
    event?: {
      __typename?: 'Event';
      id: string;
      startAt: any;
      endAt: any;
      travelDistance?: number | null;
      hasNotes: boolean;
    } | null;
    commentedBy?: { __typename?: 'Member'; id: string } | null;
  };
};

export type CreateClientNotesMutationVariables = Exact<{
  input: CreateClientNotesInput;
}>;

export type CreateClientNotesMutation = {
  __typename?: 'Mutation';
  createClientNotes: Array<{
    __typename?: 'ClientNote';
    id: string;
    createdAt?: any | null;
    type: ClientNoteType;
    comment?: string | null;
    commentedAt: any;
    commentedByText?: string | null;
    createdBy: { __typename?: 'Member'; id: string };
    client: { __typename?: 'Client'; id: string };
    event?: {
      __typename?: 'Event';
      id: string;
      startAt: any;
      endAt: any;
      travelDistance?: number | null;
    } | null;
    commentedBy?: { __typename?: 'Member'; id: string } | null;
  }>;
};

export type DeleteClientNotesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientNotesMutation = {
  __typename?: 'Mutation';
  deleteClientNotes: Array<{
    __typename?: 'ClientNote';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientNotesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientNotesMutation = {
  __typename?: 'Mutation';
  restoreClientNotes: Array<{
    __typename?: 'ClientNote';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateClientNoteMutationVariables = Exact<{
  input: UpdateClientNoteInput;
}>;

export type UpdateClientNoteMutation = {
  __typename?: 'Mutation';
  updateClientNote: {
    __typename?: 'ClientNote';
    id: string;
    type: ClientNoteType;
    comment?: string | null;
    commentedAt: any;
    commentedByText?: string | null;
    updatedAt?: any | null;
    commentedBy?: { __typename?: 'Member'; id: string } | null;
    lastUpdatedBy?: { __typename?: 'Member'; id: string } | null;
  };
};

export type CreateClientObservationMutationVariables = Exact<{
  input: CreateClientObservationInput;
}>;

export type CreateClientObservationMutation = {
  __typename?: 'Mutation';
  createClientObservation: {
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    weight?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    hadSeizure?: boolean | null;
    seizureDuration?: number | null;
    seizureType?: SeizureType | null;
    seizureLostConsciousness?: SeizureLostConsciousness | null;
    incontinenceType?: IncontinenceType | null;
    postSeizureWalkDuration?: number | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    client: { __typename?: 'Client'; id: string };
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type CreateClientObservationWithEventMutationVariables = Exact<{
  input: CreateClientObservationWithEventInput;
}>;

export type CreateClientObservationWithEventMutation = {
  __typename?: 'Mutation';
  createClientObservationWithEvent: {
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    weight?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    hadSeizure?: boolean | null;
    seizureDuration?: number | null;
    seizureType?: SeizureType | null;
    seizureLostConsciousness?: SeizureLostConsciousness | null;
    incontinenceType?: IncontinenceType | null;
    postSeizureWalkDuration?: number | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    event?: { __typename?: 'Event'; id: string } | null;
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type DeleteClientObservationsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientObservationsMutation = {
  __typename?: 'Mutation';
  deleteClientObservations: Array<{
    __typename?: 'ClientObservation';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateClientObservationMutationVariables = Exact<{
  input: UpdateClientObservationInput;
}>;

export type UpdateClientObservationMutation = {
  __typename?: 'Mutation';
  updateClientObservation: {
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    weight?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    hadSeizure?: boolean | null;
    seizureDuration?: number | null;
    seizureType?: SeizureType | null;
    seizureLostConsciousness?: SeizureLostConsciousness | null;
    incontinenceType?: IncontinenceType | null;
    postSeizureWalkDuration?: number | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type SetClientTeamMembersMutationVariables = Exact<{
  input: SetClientTeamMembersInput;
}>;

export type SetClientTeamMembersMutation = {
  __typename?: 'Mutation';
  setClientTeamMembers: Array<{
    __typename?: 'ClientTeam';
    id: string;
    preferred: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type ArchiveClientsMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveClientsMutation = {
  __typename?: 'Mutation';
  archiveClients: Array<{
    __typename?: 'Client';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreateClientsMutationVariables = Exact<{
  input: CreateClientsInput;
}>;

export type CreateClientsMutation = {
  __typename?: 'Mutation';
  createClients: Array<{
    __typename?: 'Client';
    id: string;
    moduleCS: boolean;
    moduleSC: boolean;
    email?: string | null;
    phone?: string | null;
    dob?: any | null;
    color?: any | null;
    ndisId?: any | null;
    timezone: Timezone;
    ndisPlanStartDate?: any | null;
    ndisPlanEndDate?: any | null;
    ndisPlanInitialFundsCore?: number | null;
    ndisPlanInitialFundsCs?: number | null;
    ndisPlanInitialFundsCb?: number | null;
    defaultEventDuration?: number | null;
    notes?: string | null;
    active: boolean;
    allowMedication: boolean;
    allowObservations: boolean;
    allowRecordSeizures: boolean;
    allowAutomaticEventPopulation: boolean;
    bloodSystolicUpperThreshold1?: number | null;
    bloodSystolicLowerThreshold1?: number | null;
    bloodDiastolicUpperThreshold1?: number | null;
    bloodDiastolicLowerThreshold1?: number | null;
    heartUpperThreshold1?: number | null;
    heartLowerThreshold1?: number | null;
    spo2UpperThreshold1?: number | null;
    spo2LowerThreshold1?: number | null;
    respirationUpperThreshold1?: number | null;
    respirationLowerThreshold1?: number | null;
    bloodSystolicUpperThreshold2?: number | null;
    bloodSystolicLowerThreshold2?: number | null;
    bloodDiastolicUpperThreshold2?: number | null;
    bloodDiastolicLowerThreshold2?: number | null;
    heartUpperThreshold2?: number | null;
    heartLowerThreshold2?: number | null;
    spo2UpperThreshold2?: number | null;
    spo2LowerThreshold2?: number | null;
    respirationUpperThreshold2?: number | null;
    respirationLowerThreshold2?: number | null;
    requireHandover: boolean;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    planManager?: {
      __typename?: 'PlanManager';
      id: string;
      name: string;
    } | null;
    supportCoordinator?: {
      __typename?: 'SupportCoordinator';
      id: string;
    } | null;
  }>;
};

export type DeleteClientsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteClientsMutation = {
  __typename?: 'Mutation';
  deleteClients: Array<{
    __typename?: 'Client';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreClientsMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreClientsMutation = {
  __typename?: 'Mutation';
  restoreClients: Array<{
    __typename?: 'Client';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateClientsModulesMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsModulesMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{
    __typename?: 'Client';
    id: string;
    moduleCS: boolean;
    moduleSC: boolean;
  }>;
};

export type UpdateClientsMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{
    __typename?: 'Client';
    id: string;
    moduleCS: boolean;
    moduleSC: boolean;
    email?: string | null;
    phone?: string | null;
    bio?: string | null;
    dob?: any | null;
    color?: any | null;
    timezone: Timezone;
    ndisId?: any | null;
    externalId?: string | null;
    ndisPlanStartDate?: any | null;
    ndisPlanEndDate?: any | null;
    ndisPlanInitialFundsCore?: number | null;
    ndisPlanInitialFundsCs?: number | null;
    ndisPlanInitialFundsCb?: number | null;
    defaultEventDuration?: number | null;
    notes?: string | null;
    confidentialNotes?: string | null;
    active: boolean;
    allowMedication: boolean;
    allowObservations: boolean;
    allowRecordSeizures: boolean;
    allowAutomaticEventPopulation: boolean;
    requireHandover: boolean;
    contactsName?: string | null;
    contactsEmail?: string | null;
    contactsPhone?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    planManager?: {
      __typename?: 'PlanManager';
      id: string;
      name: string;
    } | null;
    supportCoordinator?: {
      __typename?: 'SupportCoordinator';
      id: string;
    } | null;
  }>;
};

export type UpdateClientsColorsMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsColorsMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{
    __typename?: 'Client';
    id: string;
    color?: any | null;
  }>;
};

export type UpdateClientsNotesMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsNotesMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{
    __typename?: 'Client';
    id: string;
    notes?: string | null;
  }>;
};

export type UpdateClientsObservationThresholdsMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsObservationThresholdsMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{
    __typename?: 'Client';
    id: string;
    bloodSystolicUpperThreshold1?: number | null;
    bloodSystolicLowerThreshold1?: number | null;
    bloodDiastolicUpperThreshold1?: number | null;
    bloodDiastolicLowerThreshold1?: number | null;
    heartUpperThreshold1?: number | null;
    heartLowerThreshold1?: number | null;
    spo2UpperThreshold1?: number | null;
    spo2LowerThreshold1?: number | null;
    respirationUpperThreshold1?: number | null;
    respirationLowerThreshold1?: number | null;
    bloodSystolicUpperThreshold2?: number | null;
    bloodSystolicLowerThreshold2?: number | null;
    bloodDiastolicUpperThreshold2?: number | null;
    bloodDiastolicLowerThreshold2?: number | null;
    heartUpperThreshold2?: number | null;
    heartLowerThreshold2?: number | null;
    spo2UpperThreshold2?: number | null;
    spo2LowerThreshold2?: number | null;
    respirationUpperThreshold2?: number | null;
    respirationLowerThreshold2?: number | null;
  }>;
};

export type UpdateClientsPersonalDetailsMutationVariables = Exact<{
  input: UpdateClientsInput;
}>;

export type UpdateClientsPersonalDetailsMutation = {
  __typename?: 'Mutation';
  updateClients: Array<{
    __typename?: 'Client';
    id: string;
    email?: string | null;
    phone?: string | null;
    bio?: string | null;
    dob?: any | null;
    color?: any | null;
    externalId?: string | null;
    contactsName?: string | null;
    contactsEmail?: string | null;
    contactsPhone?: string | null;
    timezone: Timezone;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type ClaimEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ClaimEventMutation = {
  __typename?: 'Mutation';
  claimEvent: {
    __typename?: 'EventClaim';
    id: string;
    event: { __typename?: 'Event'; id: string };
  };
};

export type DismissEventClaimMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DismissEventClaimMutation = {
  __typename?: 'Mutation';
  dismissEventClaim: {
    __typename?: 'EventClaim';
    id: string;
    dismissedAt?: any | null;
    event: { __typename?: 'Event'; id: string };
  };
};

export type CreateEventFileMutationVariables = Exact<{
  input: CreateEventFileInput;
}>;

export type CreateEventFileMutation = {
  __typename?: 'Mutation';
  createEventFile: {
    __typename?: 'EventFile';
    id: string;
    type: EventFileType;
    dateOfFile?: any | null;
    value?: string | null;
    owner: { __typename?: 'Event'; id: string };
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      path: string;
      createdBy: { __typename?: 'Member'; id: string };
    };
  };
};

export type DeleteEventFilesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteEventFilesMutation = {
  __typename?: 'Mutation';
  deleteEventFiles: Array<{
    __typename?: 'EventFile';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreEventFilesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreEventFilesMutation = {
  __typename?: 'Mutation';
  restoreEventFiles: Array<{
    __typename?: 'EventFile';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateEventFilesMutationVariables = Exact<{
  input: UpdateEventFilesInput;
}>;

export type UpdateEventFilesMutation = {
  __typename?: 'Mutation';
  updateEventFiles: Array<{
    __typename?: 'EventFile';
    id: string;
    type: EventFileType;
    dateOfFile?: any | null;
    value?: string | null;
    owner: { __typename?: 'Event'; id: string };
  }>;
};

export type CloseEventIssueMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CloseEventIssueMutation = {
  __typename?: 'Mutation';
  closeEventIssue: {
    __typename?: 'EventIssue';
    id: string;
    closedAt?: any | null;
    closedBy?: { __typename?: 'Member'; id: string } | null;
    event: { __typename?: 'Event'; id: string; hasIssue: boolean };
  };
};

export type CreateEventIssueMutationVariables = Exact<{
  input: CreateEventIssueInput;
}>;

export type CreateEventIssueMutation = {
  __typename?: 'Mutation';
  createEventIssue: {
    __typename?: 'EventIssue';
    id: string;
    type: EventIssueType;
    createdAt?: any | null;
    createdBy: { __typename?: 'Member'; id: string };
    event: { __typename?: 'Event'; id: string; hasIssue: boolean };
  };
};

export type ApproveEventsTravelMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type ApproveEventsTravelMutation = {
  __typename?: 'Mutation';
  approveEventsTravel: Array<{
    __typename?: 'Event';
    id: string;
    travelApprovedAt?: any | null;
    travelApprovedBy?: { __typename?: 'Member'; id: string } | null;
  }>;
};

export type ArchiveEventsMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveEventsMutation = {
  __typename?: 'Mutation';
  archiveEvents: Array<{
    __typename?: 'Event';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type AutomaticallyAssignAttendeeMutationVariables = Exact<{
  input: QueryByIdInput;
}>;

export type AutomaticallyAssignAttendeeMutation = {
  __typename?: 'Mutation';
  updateEventAutomaticAttendee: {
    __typename?: 'Event';
    id: string;
    memberAssignedAutomatically: boolean;
    scheduleNotes?: string | null;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type CancelEventsMutationVariables = Exact<{
  input: EventsCancelInput;
}>;

export type CancelEventsMutation = {
  __typename?: 'Mutation';
  cancelEvents: Array<{
    __typename?: 'Event';
    id: string;
    billable: boolean;
    payable: boolean;
    cancel?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type ClockOffEventMutationVariables = Exact<{
  input: ClockEventInput;
}>;

export type ClockOffEventMutation = {
  __typename?: 'Mutation';
  clockEvent: {
    __typename?: 'Event';
    id: string;
    clockedOffAt?: any | null;
    clockedOffLatitude?: string | null;
    clockedOffLongitude?: string | null;
    clockedOffBy?: { __typename?: 'Member'; id: string } | null;
  };
};

export type ClockOffEventAttendedMutationVariables = Exact<{
  input: ClockEventInput;
}>;

export type ClockOffEventAttendedMutation = {
  __typename?: 'Mutation';
  clockEventAttended: {
    __typename?: 'EventOwnAndRelated';
    id: string;
    clockedOffAt?: any | null;
  };
};

export type ClockOnEventMutationVariables = Exact<{
  input: ClockEventInput;
}>;

export type ClockOnEventMutation = {
  __typename?: 'Mutation';
  clockEvent: {
    __typename?: 'Event';
    id: string;
    clockedOnAt?: any | null;
    clockedOnLatitude?: string | null;
    clockedOnLongitude?: string | null;
    clockedOnBy?: { __typename?: 'Member'; id: string } | null;
  };
};

export type ClockOnEventAttendedMutationVariables = Exact<{
  input: ClockEventInput;
}>;

export type ClockOnEventAttendedMutation = {
  __typename?: 'Mutation';
  clockEventAttended: {
    __typename?: 'EventOwnAndRelated';
    id: string;
    clockedOnAt?: any | null;
  };
};

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;

export type CreateEventMutation = {
  __typename?: 'Mutation';
  createEvent: {
    __typename?: 'Event';
    id: string;
    locked?: boolean | null;
    published?: boolean | null;
    billable: boolean;
    billingRegistrationGroup: EventBillingRegistrationGroup;
    payable: boolean;
    bonusPay: number;
    startAt: any;
    duration: number;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    summary?: string | null;
    publicHoliday?: boolean | null;
    requireCar: boolean;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    client: { __typename?: 'Client'; id: string; timezone: Timezone };
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type DeleteEventsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteEventsMutation = {
  __typename?: 'Mutation';
  deleteEvents: Array<{
    __typename?: 'Event';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreEventsMutationVariables = Exact<{
  input: RestoreInput;
}>;

export type RestoreEventsMutation = {
  __typename?: 'Mutation';
  restoreEvents: Array<{
    __typename?: 'Event';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    cancel?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateEventAttendeeMutationVariables = Exact<{
  input: UpdateEventInput;
}>;

export type UpdateEventAttendeeMutation = {
  __typename?: 'Mutation';
  updateEvent: {
    __typename?: 'Event';
    id: string;
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;

export type UpdateEventMutation = {
  __typename?: 'Mutation';
  updateEvent: {
    __typename?: 'Event';
    id: string;
    hasNotes: boolean;
    locked?: boolean | null;
    published?: boolean | null;
    billable: boolean;
    billingRegistrationGroup: EventBillingRegistrationGroup;
    payable: boolean;
    bonusPay: number;
    startAt: any;
    endAt: any;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    duration: number;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    summary?: string | null;
    scheduleNotes?: string | null;
    travelDistance?: number | null;
    travelDistanceNotes?: string | null;
    travelTime?: number | null;
    travelTimeNotes?: string | null;
    publicHoliday?: boolean | null;
    memberAssignedAutomatically: boolean;
    requireCar: boolean;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    clockedOnBy?: { __typename?: 'Member'; id: string } | null;
    clockedOffBy?: { __typename?: 'Member'; id: string } | null;
    client: { __typename?: 'Client'; id: string };
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type UpdateEventAttendedMutationVariables = Exact<{
  input: UpdateEventBasicFieldsInput;
}>;

export type UpdateEventAttendedMutation = {
  __typename?: 'Mutation';
  updateEventAttended: {
    __typename?: 'EventOwnAndRelated';
    id: string;
    hasNotes: boolean;
    travelDistance: number;
    travelDistanceNotes?: string | null;
    travelTime: number;
    travelTimeNotes?: string | null;
  };
};

export type VerifyEventsMutationVariables = Exact<{
  input: VerifyEventsInput;
}>;

export type VerifyEventsMutation = {
  __typename?: 'Mutation';
  verifyEvents: Array<{
    __typename?: 'Event';
    id: string;
    verified?: boolean | null;
    verifiedAt?: any | null;
  }>;
};

export type CreateMedicationMutationVariables = Exact<{
  input: CreateMedicationInput;
}>;

export type CreateMedicationMutation = {
  __typename?: 'Mutation';
  createMedication: { __typename?: 'Medication'; id: string; name: string };
};

export type DeleteMedicationsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteMedicationsMutation = {
  __typename?: 'Mutation';
  deleteMedications: Array<{
    __typename?: 'Medication';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateMedicationMutationVariables = Exact<{
  input: UpdateMedicationInput;
}>;

export type UpdateMedicationMutation = {
  __typename?: 'Mutation';
  updateMedication: { __typename?: 'Medication'; id: string; name: string };
};

export type CreateMemberBasePayRateMutationVariables = Exact<{
  input: CreateMemberBasePayRateInput;
}>;

export type CreateMemberBasePayRateMutation = {
  __typename?: 'Mutation';
  createMemberBasePayRate: {
    __typename?: 'MemberBasePayRate';
    id: string;
    date: any;
    value: number;
    type: MemberBasePayRateType;
    member: { __typename?: 'Member'; id: string };
  };
};

export type DeleteMemberBasePayRatesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteMemberBasePayRatesMutation = {
  __typename?: 'Mutation';
  deleteMemberBasePayRates: Array<{
    __typename?: 'MemberBasePayRate';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreMemberBasePayRatesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreMemberBasePayRatesMutation = {
  __typename?: 'Mutation';
  restoreMemberBasePayRates: Array<{
    __typename?: 'MemberBasePayRate';
    id: string;
    active: boolean;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateMemberBasePayRateMutationVariables = Exact<{
  input: UpdateMemberBasePayRateInput;
}>;

export type UpdateMemberBasePayRateMutation = {
  __typename?: 'Mutation';
  updateMemberBasePayRate: {
    __typename?: 'MemberBasePayRate';
    id: string;
    date: any;
    value: number;
    type: MemberBasePayRateType;
    active: boolean;
  };
};

export type CreateMemberBranchAllocationMutationVariables = Exact<{
  input: CreateMemberBranchAllocationInput;
}>;

export type CreateMemberBranchAllocationMutation = {
  __typename?: 'Mutation';
  createMemberBranchAllocation: {
    __typename?: 'MemberBranchAllocation';
    id: string;
    date: any;
    deletedAt?: any | null;
    member: { __typename?: 'Member'; id: string };
    values: Array<{
      __typename?: 'MemberBranchAllocationValue';
      value: number;
      branchId: any;
    }>;
  };
};

export type DeleteMemberBranchAllocationMutationVariables = Exact<{
  input: QueryByIdInput;
}>;

export type DeleteMemberBranchAllocationMutation = {
  __typename?: 'Mutation';
  deleteMemberBranchAllocation: {
    __typename?: 'MemberBranchAllocation';
    id: string;
    deletedAt?: any | null;
  };
};

export type UpdateMemberBranchAllocationMutationVariables = Exact<{
  input: UpdateMemberBranchAllocationInput;
}>;

export type UpdateMemberBranchAllocationMutation = {
  __typename?: 'Mutation';
  updateMemberBranchAllocation: {
    __typename?: 'MemberBranchAllocation';
    id: string;
    date: any;
    values: Array<{
      __typename?: 'MemberBranchAllocationValue';
      value: number;
      branchId: any;
    }>;
  };
};

export type CreateMemberFileMutationVariables = Exact<{
  input: CreateMemberFileInput;
}>;

export type CreateMemberFileMutation = {
  __typename?: 'Mutation';
  createMemberFile: {
    __typename?: 'MemberFile';
    id: string;
    type: MemberFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    issuedAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Member'; id: string };
    file: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      path: string;
      createdBy: { __typename?: 'Member'; id: string };
    };
  };
};

export type DeleteMemberFilesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteMemberFilesMutation = {
  __typename?: 'Mutation';
  deleteMemberFiles: Array<{
    __typename?: 'MemberFile';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreMemberFilesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreMemberFilesMutation = {
  __typename?: 'Mutation';
  restoreMemberFiles: Array<{
    __typename?: 'MemberFile';
    id: string;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type UpdateMemberFilesMutationVariables = Exact<{
  input: UpdateMemberFilesInput;
}>;

export type UpdateMemberFilesMutation = {
  __typename?: 'Mutation';
  updateMemberFiles: Array<{
    __typename?: 'MemberFile';
    id: string;
    type: MemberFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    issuedAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Member'; id: string };
    file: { __typename?: 'File'; id: string; name: string };
  }>;
};

export type GenerateMemberHoursMutationVariables = Exact<{
  input: GenerateMemberHoursInput;
}>;

export type GenerateMemberHoursMutation = {
  __typename?: 'Mutation';
  generateMemberHours: Array<{
    __typename?: 'MemberHour';
    id: string;
    date: any;
    type: MemberHoursType;
    value?: any | null;
  }>;
};

export type InviteMembersMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type InviteMembersMutation = {
  __typename?: 'Mutation';
  inviteMembers: Array<{
    __typename?: 'Member';
    id: string;
    invitationMostRecentlyReceived?: {
      __typename?: 'MemberInvitation';
      id: string;
      lastSender: {
        __typename?: 'MemberInvitationSender';
        id: string;
        sentAt: any;
        sentBy: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        };
      };
    } | null;
  }>;
};

export type ProcessMemberInvitationMutationVariables = Exact<{
  input: LinkMemberInput;
}>;

export type ProcessMemberInvitationMutation = {
  __typename?: 'Mutation';
  linkMember: {
    __typename?: 'Member';
    id: string;
    org: { __typename?: 'Org'; id: string };
  };
};

export type UninviteMemberMutationVariables = Exact<{
  input: QueryByIdInput;
}>;

export type UninviteMemberMutation = {
  __typename?: 'Mutation';
  uninviteMember: {
    __typename?: 'MemberInvitation';
    id: string;
    deletedAt?: any | null;
  };
};

export type SetMemberPayrollCheckMutationVariables = Exact<{
  input: SetMemberPayrollCheckInput;
}>;

export type SetMemberPayrollCheckMutation = {
  __typename?: 'Mutation';
  setMemberPayrollCheck: {
    __typename?: 'MemberPayrollCheck';
    id: string;
    eventsChecked: boolean;
    clocksChecked: boolean;
    paid: boolean;
    member: { __typename?: 'Member'; id: string };
  };
};

export type CreateMemberPostNominalMutationVariables = Exact<{
  input: CreateMemberPostNominalInput;
}>;

export type CreateMemberPostNominalMutation = {
  __typename?: 'Mutation';
  createMemberPostNominal: {
    __typename?: 'MemberPostNominal';
    id: string;
    date: any;
    value: string;
    member: {
      __typename?: 'Member';
      id: string;
      currentPostNominal?: string | null;
    };
  };
};

export type DeleteMemberPostNominalMutationVariables = Exact<{
  input: QueryByIdInput;
}>;

export type DeleteMemberPostNominalMutation = {
  __typename?: 'Mutation';
  deleteMemberPostNominal: {
    __typename?: 'MemberPostNominal';
    id: string;
    deletedAt?: any | null;
    member: {
      __typename?: 'Member';
      id: string;
      currentPostNominal?: string | null;
    };
  };
};

export type UpdateMemberPostNominalMutationVariables = Exact<{
  input: UpdateMemberPostNominalInput;
}>;

export type UpdateMemberPostNominalMutation = {
  __typename?: 'Mutation';
  updateMemberPostNominal: {
    __typename?: 'MemberPostNominal';
    id: string;
    date: any;
    value: string;
    member: {
      __typename?: 'Member';
      id: string;
      currentPostNominal?: string | null;
    };
  };
};

export type CreateMemberScheduleTimeLimitMutationVariables = Exact<{
  input: CreateMemberScheduleTimeLimitsInput;
}>;

export type CreateMemberScheduleTimeLimitMutation = {
  __typename?: 'Mutation';
  createMemberScheduleTimeLimits: Array<{
    __typename?: 'MemberScheduleTimeLimit';
    id: string;
    createdAt?: any | null;
    description?: string | null;
    limit: number;
    startAt: any;
    endAt: any;
    member: {
      __typename?: 'Member';
      id: string;
      scheduleMaxWeekTimeLimitCurrent: number;
    };
    createdBy: { __typename?: 'Member'; id: string };
  }>;
};

export type CreateMemberUnavailablesMutationVariables = Exact<{
  input: CreateMemberUnavailablesInput;
}>;

export type CreateMemberUnavailablesMutation = {
  __typename?: 'Mutation';
  createMemberUnavailables: Array<{
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    dates: Array<Array<any>>;
    color?: any | null;
    rrule?: string | null;
    owner: { __typename?: 'Member'; id: string };
  }>;
};

export type DeleteMemberUnavailablesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type DeleteMemberUnavailablesMutation = {
  __typename?: 'Mutation';
  deleteMemberUnavailables: Array<{
    __typename?: 'MemberUnavailable';
    id: string;
    deletedAt?: any | null;
  }>;
};

export type UpdateMemberUnavailableMutationVariables = Exact<{
  input: UpdateMemberUnavailableInput;
}>;

export type UpdateMemberUnavailableMutation = {
  __typename?: 'Mutation';
  updateMemberUnavailable: {
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    dates: Array<Array<any>>;
    color?: any | null;
    rrule?: string | null;
  };
};

export type CreateMemberUploadMutationVariables = Exact<{
  input: CreateMemberUploadInput;
}>;

export type CreateMemberUploadMutation = {
  __typename?: 'Mutation';
  createMemberUpload: {
    __typename?: 'MemberUpload';
    id: string;
    type: MemberFileType;
    processingState: ProcessingState;
    uploadedFor: {
      __typename?: 'Member';
      id: string;
      missingFiles: Array<MemberFileType>;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      path: string;
      createdBy: { __typename?: 'Member'; id: string };
    };
  };
};

export type ProcessMemberUploadMutationVariables = Exact<{
  input: ProcessMemberUploadInput;
}>;

export type ProcessMemberUploadMutation = {
  __typename?: 'Mutation';
  processMemberUpload: {
    __typename?: 'MemberUpload';
    id: string;
    processingState: ProcessingState;
    processingNote?: string | null;
    processedBy?: { __typename?: 'Member'; id: string } | null;
    memberFile?: { __typename?: 'MemberFile'; id: string } | null;
  };
};

export type UpdateMemberBankDetailsSelfMutationVariables = Exact<{
  input: UpdateMemberCurrentInput;
}>;

export type UpdateMemberBankDetailsSelfMutation = {
  __typename?: 'Mutation';
  updateMemberCurrent: {
    __typename?: 'Member';
    id: string;
    bank?: {
      __typename?: 'MemberBank';
      bsb?: string | null;
      accountNumber?: string | null;
      accountName?: string | null;
    } | null;
  };
};

export type UpdateMemberSuperDetailsSelfMutationVariables = Exact<{
  input: UpdateMemberCurrentInput;
}>;

export type UpdateMemberSuperDetailsSelfMutation = {
  __typename?: 'Mutation';
  updateMemberCurrent: {
    __typename?: 'Member';
    id: string;
    super?: {
      __typename?: 'MemberSuper';
      usi?: string | null;
      fundName?: string | null;
      fundAbn?: any | null;
      accountName?: string | null;
      accountNumber?: string | null;
    } | null;
  };
};

export type UpdateMemberTaxFileNumberDeclarationDetailsSelfMutationVariables =
  Exact<{
    input: UpdateMemberCurrentInput;
  }>;

export type UpdateMemberTaxFileNumberDeclarationDetailsSelfMutation = {
  __typename?: 'Mutation';
  updateMemberCurrent: {
    __typename?: 'Member';
    id: string;
    taxFileNumberDeclaration?: {
      __typename?: 'MemberTaxFileNumberDeclaration';
      tfn?: string | null;
      appliedForTfn?: boolean | null;
      claimingExemptionMinor?: boolean | null;
      claimingExemptionPension?: boolean | null;
      salutation?: Salutation | null;
      lastName?: string | null;
      firstName?: string | null;
      middleName?: string | null;
      street?: string | null;
      locality?: string | null;
      region?: AddressRegionCode | null;
      postcode?: any | null;
      prevLastName?: string | null;
      email?: string | null;
      dob?: any | null;
      basisOfPay?: MemberTaxFileNumberDeclarationBasisOfPay | null;
      areYou?: MemberTaxFileNumberDeclarationAreYou | null;
      claimTaxFreeThreshold?: boolean | null;
      studentLoanDebt?: boolean | null;
    } | null;
  };
};

export type ArchiveMembersMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveMembersMutation = {
  __typename?: 'Mutation';
  archiveMembers: Array<{
    __typename?: 'Member';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreateMembersMutationVariables = Exact<{
  input: CreateMembersInput;
}>;

export type CreateMembersMutation = {
  __typename?: 'Mutation';
  createMembers: Array<{
    __typename?: 'Member';
    id: string;
    moduleAccessCS?: boolean | null;
    moduleAccessSC?: boolean | null;
    externalId?: string | null;
    color?: any | null;
    email?: string | null;
    phone?: string | null;
    salutation?: Salutation | null;
    gender?: Gender | null;
    pronouns?: Pronouns | null;
    dob?: any | null;
    timezone: Timezone;
    schedulable: boolean;
    trackFiles: boolean;
    bonusEligible: boolean;
    workRights?: MemberWorkRights | null;
    workRightsLastCheckedAt?: any | null;
    passportCountry?: AddressCountryCode | null;
    passportNumber?: string | null;
    allowedFortnightlyWorkHours?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    visaType?: { __typename?: 'VisaType'; id: string } | null;
  }>;
};

export type DeleteMembersMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteMembersMutation = {
  __typename?: 'Mutation';
  deleteMembers: Array<{
    __typename?: 'Member';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type EmailMemberClockedPeriodsMutationVariables = Exact<{
  input: QueryMemberClockedPeriodsInput;
}>;

export type EmailMemberClockedPeriodsMutation = {
  __typename?: 'Mutation';
  emailMemberClockedPeriods: { __typename?: 'BooleanResponse'; value: boolean };
};

export type RestoreMembersMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreMembersMutation = {
  __typename?: 'Mutation';
  restoreMembers: Array<{
    __typename?: 'Member';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UnlinkMembersFromUsersMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type UnlinkMembersFromUsersMutation = {
  __typename?: 'Mutation';
  unlinkMembers: Array<{
    __typename?: 'Member';
    id: string;
    hasAccess?: boolean | null;
  }>;
};

export type UpdateCurrentMemberTypicalUnavailabilityMutationVariables = Exact<{
  input: UpdateMemberCurrentInput;
}>;

export type UpdateCurrentMemberTypicalUnavailabilityMutation = {
  __typename?: 'Mutation';
  updateMemberCurrent: {
    __typename?: 'Member';
    id: string;
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
  };
};

export type UpdateMemberDefaultBranchMutationVariables = Exact<{
  input: UpdateMemberCurrentInput;
}>;

export type UpdateMemberDefaultBranchMutation = {
  __typename?: 'Mutation';
  updateMemberCurrent: {
    __typename?: 'Member';
    id: string;
    defaultBranch?: { __typename?: 'Branch'; id: string } | null;
  };
};

export type UpdateMembersMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    moduleAccessCS?: boolean | null;
    moduleAccessSC?: boolean | null;
    currentPostNominal?: string | null;
    dob?: any | null;
    bio?: string | null;
    email?: string | null;
    phone?: string | null;
    study?: string | null;
    externalId?: string | null;
    timezone: Timezone;
    schedulable: boolean;
    trackFiles: boolean;
    australianCitizen?: boolean | null;
    workRights?: MemberWorkRights | null;
    workRightsLastCheckedAt?: any | null;
    passportCountry?: AddressCountryCode | null;
    passportNumber?: string | null;
    allowedFortnightlyWorkHours?: number | null;
    driversLicense?: DriversLicense | null;
    driversLicenseCountry?: AddressCountryCode | null;
    driversLicenseExpiryDate?: any | null;
    hasCar?: boolean | null;
    carDescription?: string | null;
    canHoist?: boolean | null;
    employmentStartDate?: any | null;
    employmentEndDate?: any | null;
    bonusEligible: boolean;
    remindClock?: boolean | null;
    remindEvents?: boolean | null;
    remindNotes?: boolean | null;
    employementContractExternallySaved?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    visaType?: { __typename?: 'VisaType'; id: string } | null;
    branches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
  }>;
};

export type UpdateMembersAttributesMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersAttributesMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    empathyRating?: number | null;
    reliabilityRating?: number | null;
  }>;
};

export type UpdateMembersAvatarMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersAvatarMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    avatar?: {
      __typename?: 'File';
      id: string;
      path: string;
      size: number;
    } | null;
  }>;
};

export type UpdateMembersBankDetailsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersBankDetailsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    bank?: {
      __typename?: 'MemberBank';
      bsb?: string | null;
      accountNumber?: string | null;
      accountName?: string | null;
    } | null;
  }>;
};

export type UpdateMembersBranchesMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersBranchesMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    branches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
  }>;
};

export type UpdateMembersColorsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersColorsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    color?: any | null;
  }>;
};

export type UpdateMembersExternalIdsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersExternalIdsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    externalId?: string | null;
  }>;
};

export type UpdateMembersFinancialDetailsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersFinancialDetailsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    bank?: {
      __typename?: 'MemberBank';
      bsb?: string | null;
      accountNumber?: string | null;
      accountName?: string | null;
    } | null;
    super?: {
      __typename?: 'MemberSuper';
      usi?: string | null;
      fundName?: string | null;
      fundAbn?: any | null;
      accountName?: string | null;
      accountNumber?: string | null;
    } | null;
    taxFileNumberDeclaration?: {
      __typename?: 'MemberTaxFileNumberDeclaration';
      tfn?: string | null;
      appliedForTfn?: boolean | null;
      claimingExemptionMinor?: boolean | null;
      claimingExemptionPension?: boolean | null;
      salutation?: Salutation | null;
      lastName?: string | null;
      firstName?: string | null;
      middleName?: string | null;
      street?: string | null;
      locality?: string | null;
      region?: AddressRegionCode | null;
      postcode?: any | null;
      prevLastName?: string | null;
      email?: string | null;
      dob?: any | null;
      basisOfPay?: MemberTaxFileNumberDeclarationBasisOfPay | null;
      areYou?: MemberTaxFileNumberDeclarationAreYou | null;
      claimTaxFreeThreshold?: boolean | null;
      studentLoanDebt?: boolean | null;
    } | null;
  }>;
};

export type UpdateMembersNotesMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersNotesMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    notes?: string | null;
    confidentialNotes?: string | null;
  }>;
};

export type UpdateMembersPermissionsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersPermissionsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    admin: boolean;
    superAdmin: boolean;
    permissions?: Array<Permission> | null;
  }>;
};

export type UpdateMembersScheduleMaxTimeLimitDefaultsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersScheduleMaxTimeLimitDefaultsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    scheduleMaxDayTimeLimitDefault?: number | null;
    scheduleMaxWeekTimeLimitDefault?: number | null;
    scheduleMaxWeekTimeLimitCurrent: number;
  }>;
};

export type UpdateMembersSuperDetailsMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersSuperDetailsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    super?: {
      __typename?: 'MemberSuper';
      usi?: string | null;
      fundName?: string | null;
      fundAbn?: any | null;
      accountName?: string | null;
      accountNumber?: string | null;
    } | null;
  }>;
};

export type UpdateMembersTaxFileNumberDeclarationDetailsMutationVariables =
  Exact<{
    input: UpdateMembersInput;
  }>;

export type UpdateMembersTaxFileNumberDeclarationDetailsMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    taxFileNumberDeclaration?: {
      __typename?: 'MemberTaxFileNumberDeclaration';
      tfn?: string | null;
      appliedForTfn?: boolean | null;
      claimingExemptionMinor?: boolean | null;
      claimingExemptionPension?: boolean | null;
      salutation?: Salutation | null;
      lastName?: string | null;
      firstName?: string | null;
      middleName?: string | null;
      street?: string | null;
      locality?: string | null;
      region?: AddressRegionCode | null;
      postcode?: any | null;
      prevLastName?: string | null;
      email?: string | null;
      dob?: any | null;
      basisOfPay?: MemberTaxFileNumberDeclarationBasisOfPay | null;
      areYou?: MemberTaxFileNumberDeclarationAreYou | null;
      claimTaxFreeThreshold?: boolean | null;
      studentLoanDebt?: boolean | null;
    } | null;
  }>;
};

export type UpdateMembersTypicalUnavailabilityMutationVariables = Exact<{
  input: UpdateMembersInput;
}>;

export type UpdateMembersTypicalUnavailabilityMutation = {
  __typename?: 'Mutation';
  updateMembers: Array<{
    __typename?: 'Member';
    id: string;
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
  }>;
};

export type ArchiveNdisCoordinationClientsMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveNdisCoordinationClientsMutation = {
  __typename?: 'Mutation';
  archiveNdisCoordinationClients: Array<{
    __typename?: 'NdisCoordinationClient';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreateNdisCoordinationClientMutationVariables = Exact<{
  input: CreateNdisCoordinationClientInput;
}>;

export type CreateNdisCoordinationClientMutation = {
  __typename?: 'Mutation';
  createNdisCoordinationClient: {
    __typename?: 'NdisCoordinationClient';
    id: string;
    email?: string | null;
    phone?: string | null;
    dob?: any | null;
    color?: any | null;
    ndisId?: any | null;
    timezone: Timezone;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    planManager?: {
      __typename?: 'PlanManager';
      id: string;
      name: string;
    } | null;
  };
};

export type RestoreNdisCoordinationClientsMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreNdisCoordinationClientsMutation = {
  __typename?: 'Mutation';
  restoreNdisCoordinationClients: Array<{
    __typename?: 'NdisCoordinationClient';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateNdisCoordinationClientMutationVariables = Exact<{
  input: UpdateNdisCoordinationClientInput;
}>;

export type UpdateNdisCoordinationClientMutation = {
  __typename?: 'Mutation';
  updateNdisCoordinationClient: {
    __typename?: 'NdisCoordinationClient';
    id: string;
    email?: string | null;
    phone?: string | null;
    bio?: string | null;
    dob?: any | null;
    color?: any | null;
    timezone: Timezone;
    ndisId?: any | null;
    externalId?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    planManager?: {
      __typename?: 'PlanManager';
      id: string;
      name: string;
    } | null;
  };
};

export type UpdateNdisCoordinationClientColorMutationVariables = Exact<{
  input: UpdateNdisCoordinationClientInput;
}>;

export type UpdateNdisCoordinationClientColorMutation = {
  __typename?: 'Mutation';
  updateNdisCoordinationClient: {
    __typename?: 'NdisCoordinationClient';
    id: string;
    color?: any | null;
  };
};

export type UpdateNdisCoordinationClientPersonalDetailsMutationVariables =
  Exact<{
    input: UpdateNdisCoordinationClientInput;
  }>;

export type UpdateNdisCoordinationClientPersonalDetailsMutation = {
  __typename?: 'Mutation';
  updateNdisCoordinationClient: {
    __typename?: 'NdisCoordinationClient';
    id: string;
    email?: string | null;
    phone?: string | null;
    bio?: string | null;
    dob?: any | null;
    color?: any | null;
    externalId?: string | null;
    timezone: Timezone;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
  };
};

export type CreateOrgMutationVariables = Exact<{
  input: CreateOrgInput;
}>;

export type CreateOrgMutation = {
  __typename?: 'Mutation';
  createOrg: {
    __typename?: 'Org';
    id: string;
    abn?: any | null;
    email?: any | null;
    phone?: string | null;
    website?: string | null;
    legalName: string;
    preferredName?: string | null;
    branches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
  };
};

export type UpdateOrgBillingMutationVariables = Exact<{
  input: UpdateOrgInput;
}>;

export type UpdateOrgBillingMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    id: string;
    billing: {
      __typename?: 'OrgBilling';
      day?: number | null;
      afternoon?: number | null;
      night?: number | null;
      saturday?: number | null;
      sunday?: number | null;
      publicHoliday?: number | null;
      overnight?: number | null;
      kms?: number | null;
    };
  };
};

export type UpdateOrgClientDocumentTrackerCollapsedFilesMutationVariables =
  Exact<{
    input: UpdateOrgInput;
  }>;

export type UpdateOrgClientDocumentTrackerCollapsedFilesMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    id: string;
    clientDocumentTrackerCollapsedFiles?: Array<ClientFileType> | null;
  };
};

export type UpdateOrgClientDocumentTrackerVisibleFilesMutationVariables =
  Exact<{
    input: UpdateOrgInput;
  }>;

export type UpdateOrgClientDocumentTrackerVisibleFilesMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    id: string;
    clientDocumentTrackerVisibleFiles?: Array<ClientFileType> | null;
  };
};

export type UpdateOrgFixedExpensesMutationVariables = Exact<{
  input: UpdateOrgInput;
}>;

export type UpdateOrgFixedExpensesMutation = {
  __typename?: 'Mutation';
  updateOrg: { __typename?: 'Org'; id: string; fixedExpenses?: string | null };
};

export type UpdateOrgIdeasMutationVariables = Exact<{
  input: UpdateOrgInput;
}>;

export type UpdateOrgIdeasMutation = {
  __typename?: 'Mutation';
  updateOrg: { __typename?: 'Org'; id: string; ideas?: string | null };
};

export type UpdateOrgMemberDocumentTrackerCollapsedFilesMutationVariables =
  Exact<{
    input: UpdateOrgInput;
  }>;

export type UpdateOrgMemberDocumentTrackerCollapsedFilesMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    id: string;
    memberDocumentTrackerCollapsedFiles?: Array<MemberFileType> | null;
  };
};

export type UpdateOrgMemberDocumentTrackerVisibleFilesMutationVariables =
  Exact<{
    input: UpdateOrgInput;
  }>;

export type UpdateOrgMemberDocumentTrackerVisibleFilesMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    id: string;
    memberDocumentTrackerVisibleFiles?: Array<MemberFileType> | null;
  };
};

export type UpdateOrgPayrollMutationVariables = Exact<{
  input: UpdateOrgInput;
}>;

export type UpdateOrgPayrollMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    id: string;
    basePayRate?: number | null;
    afternoonPayMultiplier?: number | null;
    nightPayMultiplier?: number | null;
    satPayMultiplier?: number | null;
    sunPayMultiplier?: number | null;
    pubPayMultiplier?: number | null;
    passiveAllowance?: number | null;
    kmAllowance?: number | null;
    bonus2PayRate?: number | null;
    bonus3PayRate?: number | null;
    bonus4PayRate?: number | null;
    bonus5PayRate?: number | null;
    bonus6PayRate?: number | null;
    bonus7PayRate?: number | null;
    bonus8PayRate?: number | null;
    bonus9PayRate?: number | null;
    bonus10PayRate?: number | null;
    bonus11PayRate?: number | null;
    bonus12PayRate?: number | null;
  };
};

export type UpdateOrgSchedulingMutationVariables = Exact<{
  input: UpdateOrgInput;
}>;

export type UpdateOrgSchedulingMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    id: string;
    taskAssignAttendeeEnable?: boolean | null;
    taskAssignAttendeeFutureDays?: number | null;
    taskAssignAttendeePastWeeks?: number | null;
    membersCanSeeClockedTimes?: boolean | null;
    quantityOfFutureShiftsMembersCanSee?: number | null;
    quantityOfPastShiftsMembersCanSee?: number | null;
    unfilledEventsDayRange?: number | null;
    clockOnWarnMinutes?: number | null;
    clockOffWarnMinutes?: number | null;
    allowedDurationBetweenConflicts?: number | null;
    travelTimeAllowSameClient?: boolean | null;
    travelTimeAllowedDurationBetweenEvents?: number | null;
    claimEventsDayRange?: number | null;
    scheduleMaxDayTimeLimitDefault?: number | null;
    scheduleMaxWeekTimeLimitDefault?: number | null;
    maxMemberLatenessForAutomaticSundayEvents?: number | null;
    maxMemberLatenessForAutomaticSaturdayEvents?: number | null;
    maxMemberLatenessForAutomaticPublicHolidayEvents?: number | null;
    maxMemberLatenessForAutomaticHandoverEvents?: number | null;
    autoArchiveClockedOffEventsAfterNumberOfHours?: number | null;
    schedulingContactPerson?: { __typename?: 'Member'; id: string } | null;
    schedulingContactPerson2?: { __typename?: 'Member'; id: string } | null;
  };
};

export type ArchivePlanManagersMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchivePlanManagersMutation = {
  __typename?: 'Mutation';
  archivePlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreatePlanManagersMutationVariables = Exact<{
  input: CreatePlanManagersInput;
}>;

export type CreatePlanManagersMutation = {
  __typename?: 'Mutation';
  createPlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    abn?: any | null;
    name: string;
    salutation?: Salutation | null;
    phone?: string | null;
    businessName?: string | null;
    contactEmail?: string | null;
    billingEmail?: string | null;
    notes?: string | null;
  }>;
};

export type DeletePlanManagersMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeletePlanManagersMutation = {
  __typename?: 'Mutation';
  deletePlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestorePlanManagersMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestorePlanManagersMutation = {
  __typename?: 'Mutation';
  restorePlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdatePlanManagersMutationVariables = Exact<{
  input: UpdatePlanManagersInput;
}>;

export type UpdatePlanManagersMutation = {
  __typename?: 'Mutation';
  updatePlanManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    abn?: any | null;
    name: string;
    salutation?: Salutation | null;
    phone?: string | null;
    businessName?: string | null;
    contactEmail?: string | null;
    billingEmail?: string | null;
    notes?: string | null;
  }>;
};

export type ArchiveSupportCoordinatorsMutationVariables = Exact<{
  input: ArchiveInput;
}>;

export type ArchiveSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  archiveSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type CreateSupportCoordinatorsMutationVariables = Exact<{
  input: CreateSupportCoordinatorsInput;
}>;

export type CreateSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  createSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    firstName: string;
    middleName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    email?: string | null;
    phone?: string | null;
    businessName?: string | null;
    businessEmail?: string | null;
    businessPhone?: string | null;
    abn?: any | null;
    salutation?: Salutation | null;
    notes?: string | null;
    clients?: Array<{ __typename?: 'Client'; id: string }> | null;
  }>;
};

export type DeleteSupportCoordinatorsMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  deleteSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreSupportCoordinatorsMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  restoreSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateSupportCoordinatorsMutationVariables = Exact<{
  input: UpdateSupportCoordinatorsInput;
}>;

export type UpdateSupportCoordinatorsMutation = {
  __typename?: 'Mutation';
  updateSupportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    firstName: string;
    middleName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    email?: string | null;
    phone?: string | null;
    businessName?: string | null;
    businessEmail?: string | null;
    businessPhone?: string | null;
    abn?: any | null;
    salutation?: Salutation | null;
    notes?: string | null;
    clients?: Array<{ __typename?: 'Client'; id: string }> | null;
  }>;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'UserLoginResponse'; token: any };
};

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginUserMutation = {
  __typename?: 'Mutation';
  loginUser: { __typename?: 'UserLoginResponse'; token: any };
};

export type SetUserActiveMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SetUserActiveMemberMutation = {
  __typename?: 'Mutation';
  setUserActiveMember: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        legalName: string;
        preferredName?: string | null;
      };
    } | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    email?: string | null;
    phone?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    firstName: string;
    lastName?: string | null;
    member?: { __typename?: 'Member'; id: string } | null;
  };
};

export type CreateVisaTypeMutationVariables = Exact<{
  input: CreateVisaTypeInput;
}>;

export type CreateVisaTypeMutation = {
  __typename?: 'Mutation';
  createVisaType: {
    __typename?: 'VisaType';
    id: string;
    name: string;
    description?: string | null;
    allowedFortnightlyWorkHours?: number | null;
    allowedWorkHoursWaived: boolean;
  };
};

export type DeleteVisaTypesMutationVariables = Exact<{
  input: DeleteInput;
}>;

export type DeleteVisaTypesMutation = {
  __typename?: 'Mutation';
  deleteVisaTypes: Array<{
    __typename?: 'VisaType';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type RestoreVisaTypesMutationVariables = Exact<{
  input: QueryByIdsInput;
}>;

export type RestoreVisaTypesMutation = {
  __typename?: 'Mutation';
  restoreVisaTypes: Array<{
    __typename?: 'VisaType';
    id: string;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      restoredAt?: any | null;
      restoredBy?: { __typename?: 'Member'; id: string } | null;
    } | null;
  }>;
};

export type UpdateVisaTypeMutationVariables = Exact<{
  input: UpdateVisaTypeInput;
}>;

export type UpdateVisaTypeMutation = {
  __typename?: 'Mutation';
  updateVisaType: {
    __typename?: 'VisaType';
    id: string;
    name: string;
    description?: string | null;
    allowedFortnightlyWorkHours?: number | null;
    allowedWorkHoursWaived: boolean;
  };
};

export type GetBranchByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBranchByIdQuery = {
  __typename?: 'Query';
  branchById: { __typename?: 'Branch'; id: string; name: string };
};

export type GetBranchesQueryVariables = Exact<{
  input: QueryBranchesInput;
}>;

export type GetBranchesQuery = {
  __typename?: 'Query';
  branches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
};

export type GetClientContactByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClientContactByIdQuery = {
  __typename?: 'Query';
  clientContactById: {
    __typename?: 'ClientContact';
    id: string;
    name: string;
    email?: string | null;
    phone?: string | null;
    relations?: Array<ClientContactRelation> | null;
    customRelation?: string | null;
    emergencyContact: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      preferredContact?: { __typename?: 'ClientContact'; id: string } | null;
    };
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  };
};

export type GetClientContactsQueryVariables = Exact<{
  input: QueryClientContactsInput;
}>;

export type GetClientContactsQuery = {
  __typename?: 'Query';
  clientContacts: Array<{
    __typename?: 'ClientContact';
    id: string;
    name: string;
    email?: string | null;
    phone?: string | null;
    relations?: Array<ClientContactRelation> | null;
    customRelation?: string | null;
    emergencyContact: boolean;
    client: { __typename?: 'Client'; id: string };
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type DownloadClientCarePlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadClientCarePlanQuery = {
  __typename?: 'Query';
  downloadClientCarePlan: { __typename?: 'File'; id: string; path: string };
};

export type DownloadClientFileByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadClientFileByIdQuery = {
  __typename?: 'Query';
  downloadClientFileById: { __typename?: 'File'; id: string; path: string };
};

export type GetClientFilesQueryVariables = Exact<{
  input: QueryClientFilesInput;
}>;

export type GetClientFilesQuery = {
  __typename?: 'Query';
  clientFiles: Array<{
    __typename?: 'ClientFile';
    id: string;
    type: ClientFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    file: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      createdAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    };
  }>;
};

export type GetClientNdisPlanItemNamesQueryVariables = Exact<{
  input: QueryClientNdisPlanItemNameInput;
}>;

export type GetClientNdisPlanItemNamesQuery = {
  __typename?: 'Query';
  clientNdisPlanItemNames: Array<{
    __typename?: 'ClientNdisPlanItemName';
    id: string;
    category: ClientNdisPlanItemCategory;
    name: string;
  }>;
};

export type ClientNdisPlanItemTransactionContextGetTransactionsQueryVariables =
  Exact<{
    input: QueryClientNdisPlanItemTransactionInput;
  }>;

export type ClientNdisPlanItemTransactionContextGetTransactionsQuery = {
  __typename?: 'Query';
  clientNdisPlanItemTransactionAggregate: {
    __typename?: 'ClientNdisPlanItemTransactionAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{
      __typename?: 'ClientNdisPlanItemTransaction';
      id: string;
      createdAt?: any | null;
      billingDate?: any | null;
      serviceDateStart?: any | null;
      serviceDateEnd?: any | null;
      invoiceNumber?: string | null;
      value: number;
      memo?: string | null;
      clientNdisPlanItem: {
        __typename?: 'ClientNdisPlanItem';
        id: string;
        name: {
          __typename?: 'ClientNdisPlanItemName';
          id: string;
          name: string;
        };
      };
      actor?: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      } | null;
      clientNdisPlanItemTransactionSupplier?: {
        __typename?: 'ClientNdisPlanItemTransactionSupplier';
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type GetClientNdisPlanItemTransactionHistoriesQueryVariables = Exact<{
  input: QueryHistoriesInput;
}>;

export type GetClientNdisPlanItemTransactionHistoriesQuery = {
  __typename?: 'Query';
  histories?: Array<
    | {
        __typename?: 'HistoryBranchName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientAllowAutomaticEventPopulation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactCustomRelation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmergencyContact';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactRelations';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDefaultEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanCoreSupportItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCb';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCore';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCs';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemCategory';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemInitialFunds';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemManagedBy';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionBillingDate';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemTransactionBillingDateNew?: any | null;
        clientNdisPlanItemTransactionBillingDateOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItemTransaction: {
          __typename?: 'ClientNdisPlanItemTransaction';
          id: string;
        };
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionInvoiceNumber';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemTransactionInvoiceNumberNew?: string | null;
        clientNdisPlanItemTransactionInvoiceNumberOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItemTransaction: {
          __typename?: 'ClientNdisPlanItemTransaction';
          id: string;
        };
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionMemo';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemTransactionMemoNew?: string | null;
        clientNdisPlanItemTransactionMemoOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItemTransaction: {
          __typename?: 'ClientNdisPlanItemTransaction';
          id: string;
        };
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateEnd';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemTransactionServiceDateEndNew?: any | null;
        clientNdisPlanItemTransactionServiceDateEndOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItemTransaction: {
          __typename?: 'ClientNdisPlanItemTransaction';
          id: string;
        };
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateStart';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemTransactionServiceDateStartNew?: any | null;
        clientNdisPlanItemTransactionServiceDateStartOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItemTransaction: {
          __typename?: 'ClientNdisPlanItemTransaction';
          id: string;
        };
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplier';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItemTransaction: {
          __typename?: 'ClientNdisPlanItemTransaction';
          id: string;
        };
        clientNdisPlanItemTransactionSupplierNew?: {
          __typename?: 'ClientNdisPlanItemTransactionSupplier';
          id: string;
        } | null;
        clientNdisPlanItemTransactionSupplierOld?: {
          __typename?: 'ClientNdisPlanItemTransactionSupplier';
          id: string;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplierName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionValue';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemTransactionValueNew?: number | null;
        clientNdisPlanItemTransactionValueOld?: number | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItemTransaction: {
          __typename?: 'ClientNdisPlanItemTransaction';
          id: string;
        };
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanSupportCoordinationItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteComment';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedBy';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedByText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteKeywordText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodDiastolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodSystolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationHeart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNursesName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationRespiration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationSpo2';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationStoolType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationTemp';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPlanManager';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSupportCoordinator';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventActiveAssist';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventBillable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventBillingRegistrationGroup';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClient';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOffAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOnAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPassive';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPassiveStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPayable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPublicHoliday';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventRequireCar';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventScheduleNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventSummary';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelDistance';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelDistanceNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelTime';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelTimeNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberAdmin';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmpathyRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberReliabilityRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSchedulable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgLegalName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgWebsite';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBillingEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryRestorable';
        id: string;
        date: any;
        type: HistoryType;
        reason?: string | null;
        restoredAt?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        restoredBy?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          middleName?: string | null;
          lastName?: string | null;
          preferredName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeDescription';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
  > | null;
};

export type GetClientNdisPlanItemTransactionsQueryVariables = Exact<{
  input: QueryClientNdisPlanItemTransactionInput;
}>;

export type GetClientNdisPlanItemTransactionsQuery = {
  __typename?: 'Query';
  clientNdisPlanItemTransactions: Array<{
    __typename?: 'ClientNdisPlanItemTransaction';
    id: string;
    createdAt?: any | null;
    billingDate?: any | null;
    serviceDateStart?: any | null;
    serviceDateEnd?: any | null;
    invoiceNumber?: string | null;
    value: number;
    memo?: string | null;
    clientNdisPlanItem: { __typename?: 'ClientNdisPlanItem'; id: string };
    actor?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    clientNdisPlanItemTransactionSupplier?: {
      __typename?: 'ClientNdisPlanItemTransactionSupplier';
      id: string;
      name: string;
    } | null;
  }>;
};

export type GetClientNdisPlanItemTransationSupplierHistoriesQueryVariables =
  Exact<{
    input: QueryHistoriesInput;
  }>;

export type GetClientNdisPlanItemTransationSupplierHistoriesQuery = {
  __typename?: 'Query';
  histories?: Array<
    | {
        __typename?: 'HistoryBranchName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientAllowAutomaticEventPopulation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactCustomRelation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmergencyContact';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactRelations';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDefaultEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanCoreSupportItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCb';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCore';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCs';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemCategory';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemInitialFunds';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemManagedBy';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionBillingDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionInvoiceNumber';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionMemo';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateEnd';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateStart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplier';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplierName';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemTransactionSupplierNameNew?: string | null;
        clientNdisPlanItemTransactionSupplierNameOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItemTransactionSupplier: {
          __typename?: 'ClientNdisPlanItemTransactionSupplier';
          id: string;
        };
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanSupportCoordinationItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteComment';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedBy';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedByText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteKeywordText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodDiastolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodSystolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationHeart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNursesName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationRespiration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationSpo2';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationStoolType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationTemp';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPlanManager';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSupportCoordinator';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventActiveAssist';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventBillable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventBillingRegistrationGroup';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClient';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOffAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOnAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPassive';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPassiveStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPayable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPublicHoliday';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventRequireCar';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventScheduleNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventSummary';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelDistance';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelDistanceNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelTime';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelTimeNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberAdmin';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmpathyRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberReliabilityRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSchedulable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgLegalName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgWebsite';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBillingEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryRestorable';
        id: string;
        date: any;
        type: HistoryType;
        reason?: string | null;
        restoredAt?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        restoredBy?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          middleName?: string | null;
          lastName?: string | null;
          preferredName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeDescription';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
  > | null;
};

export type GetClientNdisPlanItemTransationSuppliersQueryVariables = Exact<{
  input: QueryClientNdisPlanItemTransactionSuppliersInput;
}>;

export type GetClientNdisPlanItemTransationSuppliersQuery = {
  __typename?: 'Query';
  clientNdisPlanItemTransactionSuppliers: Array<{
    __typename?: 'ClientNdisPlanItemTransactionSupplier';
    id: string;
    name: string;
  }>;
};

export type GetClientNdisPlanItemHistoriesQueryVariables = Exact<{
  input: QueryHistoriesInput;
}>;

export type GetClientNdisPlanItemHistoriesQuery = {
  __typename?: 'Query';
  histories?: Array<
    | {
        __typename?: 'HistoryBranchName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientAllowAutomaticEventPopulation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactCustomRelation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmergencyContact';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactRelations';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDefaultEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanCoreSupportItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCb';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCore';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCs';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemCategory';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemCategoryNew?: ClientNdisPlanItemCategory | null;
        clientNdisPlanItemCategoryOld?: ClientNdisPlanItemCategory | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItem: { __typename?: 'ClientNdisPlanItem'; id: string };
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemInitialFunds';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemInitialFundsNew?: number | null;
        clientNdisPlanItemInitialFundsOld?: number | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItem: { __typename?: 'ClientNdisPlanItem'; id: string };
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemManagedBy';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanItemManagedByNew?: ClientNdisPlanItemManagedBy | null;
        clientNdisPlanItemManagedByOld?: ClientNdisPlanItemManagedBy | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItem: { __typename?: 'ClientNdisPlanItem'; id: string };
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlanItem: { __typename?: 'ClientNdisPlanItem'; id: string };
        clientNdisPlanItemNameNew?: {
          __typename?: 'ClientNdisPlanItemName';
          id: string;
          name: string;
        } | null;
        clientNdisPlanItemNameOld?: {
          __typename?: 'ClientNdisPlanItemName';
          id: string;
          name: string;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionBillingDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionInvoiceNumber';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionMemo';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateEnd';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateStart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplier';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplierName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanSupportCoordinationItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteComment';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedBy';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedByText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteKeywordText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodDiastolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodSystolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationHeart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNursesName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationRespiration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationSpo2';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationStoolType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationTemp';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPlanManager';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSupportCoordinator';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventActiveAssist';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventBillable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventBillingRegistrationGroup';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClient';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOffAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOnAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPassive';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPassiveStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPayable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPublicHoliday';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventRequireCar';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventScheduleNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventSummary';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelDistance';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelDistanceNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelTime';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelTimeNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberAdmin';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmpathyRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberReliabilityRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSchedulable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgLegalName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgWebsite';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBillingEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryRestorable';
        id: string;
        date: any;
        type: HistoryType;
        reason?: string | null;
        restoredAt?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        restoredBy?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          middleName?: string | null;
          lastName?: string | null;
          preferredName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeDescription';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
  > | null;
};

export type GetClientNdisPlanItemsQueryVariables = Exact<{
  input: QueryClientNdisPlanItemInput;
}>;

export type GetClientNdisPlanItemsQuery = {
  __typename?: 'Query';
  clientNdisPlanItems: Array<{
    __typename?: 'ClientNdisPlanItem';
    id: string;
    category?: ClientNdisPlanItemCategory | null;
    initialFunds: number;
    currentFunds: number;
    name: { __typename?: 'ClientNdisPlanItemName'; id: string; name: string };
  }>;
};

export type GetClientNdisPlanByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClientNdisPlanByIdQuery = {
  __typename?: 'Query';
  clientNdisPlanById: {
    __typename?: 'ClientNdisPlan';
    id: string;
    startAt: any;
    endAt?: any | null;
    initialFunds: number;
  };
};

export type GetClientNdisPlanHistoriesQueryVariables = Exact<{
  input: QueryHistoriesInput;
}>;

export type GetClientNdisPlanHistoriesQuery = {
  __typename?: 'Query';
  histories?: Array<
    | {
        __typename?: 'HistoryBranchName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientAllowAutomaticEventPopulation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactCustomRelation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmergencyContact';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactRelations';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDefaultEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanCoreSupportItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlan: { __typename?: 'ClientNdisPlan'; id: string };
        clientNdisPlanCoreSupportItemNew?: {
          __typename?: 'ClientNdisPlanItem';
          id: string;
          name: {
            __typename?: 'ClientNdisPlanItemName';
            id: string;
            name: string;
          };
        } | null;
        clientNdisPlanCoreSupportItemOld?: {
          __typename?: 'ClientNdisPlanItem';
          id: string;
          name: {
            __typename?: 'ClientNdisPlanItemName';
            id: string;
            name: string;
          };
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndAt';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanEndAtNew?: any | null;
        clientNdisPlanEndAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlan: { __typename?: 'ClientNdisPlan'; id: string };
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCb';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCore';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCs';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemCategory';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemInitialFunds';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemManagedBy';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionBillingDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionInvoiceNumber';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionMemo';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateEnd';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateStart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplier';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplierName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartAt';
        id: string;
        date: any;
        type: HistoryType;
        clientNdisPlanStartAtNew?: any | null;
        clientNdisPlanStartAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlan: { __typename?: 'ClientNdisPlan'; id: string };
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanSupportCoordinationItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        clientNdisPlan: { __typename?: 'ClientNdisPlan'; id: string };
        clientNdisPlanSupportCoordinationItemNew?: {
          __typename?: 'ClientNdisPlanItem';
          id: string;
          name: {
            __typename?: 'ClientNdisPlanItemName';
            id: string;
            name: string;
          };
        } | null;
        clientNdisPlanSupportCoordinationItemOld?: {
          __typename?: 'ClientNdisPlanItem';
          id: string;
          name: {
            __typename?: 'ClientNdisPlanItemName';
            id: string;
            name: string;
          };
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteComment';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedBy';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedByText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteKeywordText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodDiastolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodSystolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationHeart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNursesName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationRespiration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationSpo2';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationStoolType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationTemp';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPlanManager';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSupportCoordinator';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventActiveAssist';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventBillable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventBillingRegistrationGroup';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClient';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOffAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOnAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPassive';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPassiveStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPayable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventPublicHoliday';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventRequireCar';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventScheduleNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventSummary';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelDistance';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelDistanceNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelTime';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventTravelTimeNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberAdmin';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmpathyRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberReliabilityRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSchedulable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgLegalName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgWebsite';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBillingEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryRestorable';
        id: string;
        date: any;
        type: HistoryType;
        reason?: string | null;
        restoredAt?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        restoredBy?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          middleName?: string | null;
          lastName?: string | null;
          preferredName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeDescription';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
  > | null;
};

export type GetClientNdisPlansQueryVariables = Exact<{
  input: QueryClientNdisPlanInput;
  itemsInput: QueryClientNdisPlanItemInput;
}>;

export type GetClientNdisPlansQuery = {
  __typename?: 'Query';
  clientNdisPlans: Array<{
    __typename?: 'ClientNdisPlan';
    id: string;
    startAt: any;
    endAt?: any | null;
    initialFunds: number;
    items?: Array<{
      __typename?: 'ClientNdisPlanItem';
      id: string;
      category?: ClientNdisPlanItemCategory | null;
      managedBy?: ClientNdisPlanItemManagedBy | null;
      initialFunds: number;
      currentFunds: number;
      name: {
        __typename?: 'ClientNdisPlanItemName';
        id: string;
        category: ClientNdisPlanItemCategory;
        name: string;
      };
    }> | null;
    coreSupportItem?: { __typename?: 'ClientNdisPlanItem'; id: string } | null;
    supportCoordinationItem?: {
      __typename?: 'ClientNdisPlanItem';
      id: string;
    } | null;
  }>;
};

export type DownloadClientNoteFileByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadClientNoteFileByIdQuery = {
  __typename?: 'Query';
  downloadClientNoteFileById: { __typename?: 'File'; id: string; path: string };
};

export type GetClientNoteFilesQueryVariables = Exact<{
  input: QueryClientNoteFilesInput;
}>;

export type GetClientNoteFilesQuery = {
  __typename?: 'Query';
  clientNoteFiles: Array<{
    __typename?: 'ClientNoteFile';
    id: string;
    type: ClientNoteFileType;
    dateOfFile?: any | null;
    value?: string | null;
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      createdAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    };
  }>;
};

export type GetClientNoteKeywordsQueryVariables = Exact<{
  input: QueryClientNoteKeywordsInput;
}>;

export type GetClientNoteKeywordsQuery = {
  __typename?: 'Query';
  clientNoteKeywords: Array<{
    __typename?: 'ClientNoteKeyword';
    id: string;
    createdAt?: any | null;
    text: string;
    createdBy: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type NotesContextGetClientNoteKeywordsQueryVariables = Exact<{
  input: QueryClientNoteKeywordsInput;
}>;

export type NotesContextGetClientNoteKeywordsQuery = {
  __typename?: 'Query';
  clientNoteKeywords: Array<{
    __typename?: 'ClientNoteKeyword';
    id: string;
    text: string;
  }>;
};

export type ExportClientNotesQueryVariables = Exact<{
  input: QueryClientNotesInput;
}>;

export type ExportClientNotesQuery = {
  __typename?: 'Query';
  clientNotes: Array<{
    __typename?: 'ClientNote';
    id: string;
    type: ClientNoteType;
    comment?: string | null;
    commentedAt: any;
    commentedByText?: string | null;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    commentedBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetClientNotesQueryVariables = Exact<{
  input: QueryClientNotesInput;
}>;

export type GetClientNotesQuery = {
  __typename?: 'Query';
  clientNotes: Array<{
    __typename?: 'ClientNote';
    id: string;
    type: ClientNoteType;
    comment?: string | null;
    commentedAt: any;
    commentedByText?: string | null;
    createdBy: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    event?: {
      __typename?: 'Event';
      id: string;
      startAt: any;
      endAt: any;
      travelDistance?: number | null;
    } | null;
    commentedBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type NotesContextGetAggregatedClientNotesQueryVariables = Exact<{
  input: QueryClientNotesInput;
}>;

export type NotesContextGetAggregatedClientNotesQuery = {
  __typename?: 'Query';
  clientNotesAggregate: {
    __typename?: 'ClientNotesAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{
      __typename?: 'ClientNote';
      id: string;
      type: ClientNoteType;
      comment?: string | null;
      commentedAt: any;
      commentedByText?: string | null;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
      client: {
        __typename?: 'Client';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
      event?: {
        __typename?: 'Event';
        id: string;
        startAt: any;
        endAt: any;
        travelDistance?: number | null;
      } | null;
      commentedBy?: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      } | null;
    }>;
  };
};

export type NotesContextGetFilesQueryVariables = Exact<{
  clientNoteFilesInput: QueryClientNoteFilesInput;
  eventFilesInput: QueryEventFilesInput;
}>;

export type NotesContextGetFilesQuery = {
  __typename?: 'Query';
  clientNoteFiles: Array<{
    __typename?: 'ClientNoteFile';
    id: string;
    type: ClientNoteFileType;
    dateOfFile?: any | null;
    value?: string | null;
    owner: { __typename?: 'ClientNote'; id: string };
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      createdAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    };
  }>;
  eventFiles: Array<{
    __typename?: 'EventFile';
    id: string;
    type: EventFileType;
    dateOfFile?: any | null;
    value?: string | null;
    owner: { __typename?: 'Event'; id: string };
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      createdAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    };
  }>;
};

export type NotesImportFormGetClientIdsQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type NotesImportFormGetClientIdsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    externalId?: string | null;
  }>;
};

export type NotesImportFormGetMemberIdsQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type NotesImportFormGetMemberIdsQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    externalId?: string | null;
  }>;
};

export type GetClientEnergyIntakeQueryVariables = Exact<{
  input: QueryClientObservationsInput;
}>;

export type GetClientEnergyIntakeQuery = {
  __typename?: 'Query';
  clientObservations: Array<{
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    client: { __typename?: 'Client'; id: string };
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      kilojoules?: number | null;
    }> | null;
  }>;
};

export type GetClientObservationByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClientObservationByIdQuery = {
  __typename?: 'Query';
  clientObservationById: {
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    weight?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    hadSeizure?: boolean | null;
    seizureDuration?: number | null;
    seizureType?: SeizureType | null;
    seizureLostConsciousness?: SeizureLostConsciousness | null;
    incontinenceType?: IncontinenceType | null;
    postSeizureWalkDuration?: number | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    client: { __typename?: 'Client'; id: string };
    event?: { __typename?: 'Event'; id: string } | null;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type GetClientObservationsQueryVariables = Exact<{
  input: QueryClientObservationsInput;
}>;

export type GetClientObservationsQuery = {
  __typename?: 'Query';
  clientObservations: Array<{
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    nursesName?: string | null;
    bloodSystolic?: number | null;
    bloodDiastolic?: number | null;
    heart?: number | null;
    temp?: number | null;
    spo2?: number | null;
    respiration?: number | null;
    weight?: number | null;
    stoolType?: StoolType | null;
    urineColor?: UrineColor | null;
    hadSeizure?: boolean | null;
    seizureDuration?: number | null;
    seizureType?: SeizureType | null;
    seizureLostConsciousness?: SeizureLostConsciousness | null;
    incontinenceType?: IncontinenceType | null;
    postSeizureWalkDuration?: number | null;
    givenFood?: Array<{
      __typename?: 'ClientObservationFood';
      id: string;
      description?: string | null;
      kilojoules?: number | null;
    }> | null;
    givenMedications?: Array<{
      __typename?: 'ClientObservationMedication';
      id: string;
      quantity?: string | null;
      medication: { __typename?: 'Medication'; id: string; name: string };
    }> | null;
    client: { __typename?: 'Client'; id: string };
    event?: {
      __typename?: 'Event';
      id: string;
      member?: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetClientTeamsQueryVariables = Exact<{
  input: QueryClientTeamsInput;
}>;

export type GetClientTeamsQuery = {
  __typename?: 'Query';
  clientTeams: Array<{
    __typename?: 'ClientTeam';
    id: string;
    preferred: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type ClientListContextGetAggregatedClientsQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type ClientListContextGetAggregatedClientsQuery = {
  __typename?: 'Query';
  clientsAggregate: {
    __typename?: 'ClientsAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{
      __typename?: 'Client';
      id: string;
      createdAt?: any | null;
      color?: any | null;
      totalBillableMinutes?: number | null;
      trackFiles: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
      firstBillableEvent?: {
        __typename?: 'Event';
        id: string;
        startAt: any;
      } | null;
      lastBillableEvent?: {
        __typename?: 'Event';
        id: string;
        startAt: any;
      } | null;
    }>;
  };
};

export type ClientListContextGetAggregatedRedactedClientsQueryVariables =
  Exact<{
    input: QueryRedactedClientsInput;
  }>;

export type ClientListContextGetAggregatedRedactedClientsQuery = {
  __typename?: 'Query';
  redactedClientsAggregate: {
    __typename?: 'RedactedClientsAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{
      __typename?: 'RedactedClient';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    }>;
  };
};

export type GetClientByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetClientByIdQuery = {
  __typename?: 'Query';
  clientById: {
    __typename?: 'Client';
    id: string;
    createdAt?: any | null;
    moduleCS: boolean;
    moduleSC: boolean;
    color?: any | null;
    dob?: any | null;
    email?: string | null;
    phone?: string | null;
    bio?: string | null;
    ndisId?: any | null;
    externalId?: string | null;
    ndisPlanStartDate?: any | null;
    ndisPlanEndDate?: any | null;
    ndisPlanInitialFundsCore?: number | null;
    ndisPlanInitialFundsCs?: number | null;
    ndisPlanInitialFundsCb?: number | null;
    defaultEventDuration?: number | null;
    active: boolean;
    allowMedication: boolean;
    allowObservations: boolean;
    allowRecordSeizures: boolean;
    allowAutomaticEventPopulation: boolean;
    bloodSystolicUpperThreshold1?: number | null;
    bloodSystolicLowerThreshold1?: number | null;
    bloodDiastolicUpperThreshold1?: number | null;
    bloodDiastolicLowerThreshold1?: number | null;
    heartUpperThreshold1?: number | null;
    heartLowerThreshold1?: number | null;
    spo2UpperThreshold1?: number | null;
    spo2LowerThreshold1?: number | null;
    respirationUpperThreshold1?: number | null;
    respirationLowerThreshold1?: number | null;
    bloodSystolicUpperThreshold2?: number | null;
    bloodSystolicLowerThreshold2?: number | null;
    bloodDiastolicUpperThreshold2?: number | null;
    bloodDiastolicLowerThreshold2?: number | null;
    heartUpperThreshold2?: number | null;
    heartLowerThreshold2?: number | null;
    spo2UpperThreshold2?: number | null;
    spo2LowerThreshold2?: number | null;
    respirationUpperThreshold2?: number | null;
    respirationLowerThreshold2?: number | null;
    notes?: string | null;
    confidentialNotes?: string | null;
    requireHandover: boolean;
    contactsName?: string | null;
    contactsEmail?: string | null;
    contactsPhone?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    currentNdisPlan?: {
      __typename?: 'ClientNdisPlan';
      id: string;
      coreSupportItem?: {
        __typename?: 'ClientNdisPlanItem';
        id: string;
        currentFunds: number;
      } | null;
      supportCoordinationItem?: {
        __typename?: 'ClientNdisPlanItem';
        id: string;
        currentFunds: number;
      } | null;
    } | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    createdBy?: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    preferredContact?: { __typename?: 'ClientContact'; id: string } | null;
    planManager?: {
      __typename?: 'PlanManager';
      id: string;
      name: string;
    } | null;
    supportCoordinator?: {
      __typename?: 'SupportCoordinator';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  };
};

export type GetClientIdsQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type GetClientIdsQuery = {
  __typename?: 'Query';
  clients: Array<{ __typename?: 'Client'; id: string }>;
};

export type GetClientMedicationsQueryVariables = Exact<{
  input: QueryClientMedicationsInput;
}>;

export type GetClientMedicationsQuery = {
  __typename?: 'Query';
  clientMedications: Array<{
    __typename?: 'ClientMedication';
    id: string;
    medication: { __typename?: 'Medication'; id: string; name: string };
    client: { __typename?: 'Client'; id: string };
  }>;
};

export type GetClientsQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type GetClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    createdAt?: any | null;
    color?: any | null;
    dob?: any | null;
    email?: string | null;
    phone?: string | null;
    ndisId?: any | null;
    timezone: Timezone;
    defaultEventDuration?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetClientsAutocompleteQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type GetClientsAutocompleteQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    color?: any | null;
    ndisId?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetClientsNdisIdQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type GetClientsNdisIdQuery = {
  __typename?: 'Query';
  clients: Array<{ __typename?: 'Client'; id: string; ndisId?: any | null }>;
};

export type GetClientsSelectQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type GetClientsSelectQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    timezone: Timezone;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetClientsSelectRedactedQueryVariables = Exact<{
  input: QueryRedactedClientsInput;
}>;

export type GetClientsSelectRedactedQuery = {
  __typename?: 'Query';
  redactedClients: Array<{
    __typename?: 'RedactedClient';
    id: string;
    timezone: Timezone;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetRedactedClientByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetRedactedClientByIdQuery = {
  __typename?: 'Query';
  redactedClientById: {
    __typename?: 'RedactedClient';
    id: string;
    color?: any | null;
    age?: number | null;
    allowMedication: boolean;
    allowObservations: boolean;
    allowRecordSeizures: boolean;
    allowAutomaticEventPopulation: boolean;
    bloodSystolicUpperThreshold1?: number | null;
    bloodSystolicLowerThreshold1?: number | null;
    bloodDiastolicUpperThreshold1?: number | null;
    bloodDiastolicLowerThreshold1?: number | null;
    heartUpperThreshold1?: number | null;
    heartLowerThreshold1?: number | null;
    spo2UpperThreshold1?: number | null;
    spo2LowerThreshold1?: number | null;
    respirationUpperThreshold1?: number | null;
    respirationLowerThreshold1?: number | null;
    bloodSystolicUpperThreshold2?: number | null;
    bloodSystolicLowerThreshold2?: number | null;
    bloodDiastolicUpperThreshold2?: number | null;
    bloodDiastolicLowerThreshold2?: number | null;
    heartUpperThreshold2?: number | null;
    heartLowerThreshold2?: number | null;
    spo2UpperThreshold2?: number | null;
    spo2LowerThreshold2?: number | null;
    respirationUpperThreshold2?: number | null;
    respirationLowerThreshold2?: number | null;
    requireHandover: boolean;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
  };
};

export type GetRedactedClientMedicationsQueryVariables = Exact<{
  input: QueryClientMedicationsInput;
}>;

export type GetRedactedClientMedicationsQuery = {
  __typename?: 'Query';
  clientMedications: Array<{
    __typename?: 'ClientMedication';
    id: string;
    medication: { __typename?: 'Medication'; id: string; name: string };
    client: { __typename?: 'Client'; id: string };
  }>;
};

export type GetRedactedClientTimezoneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetRedactedClientTimezoneQuery = {
  __typename?: 'Query';
  redactedClientById: {
    __typename?: 'RedactedClient';
    id: string;
    timezone: Timezone;
  };
};

export type GetRedactedClientsQueryVariables = Exact<{
  input: QueryRedactedClientsInput;
}>;

export type GetRedactedClientsQuery = {
  __typename?: 'Query';
  redactedClients: Array<{
    __typename?: 'RedactedClient';
    id: string;
    color?: any | null;
    age?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type ValidateClientExternalIdsQueryVariables = Exact<{
  input: ClientsValidateExternalldsInput;
}>;

export type ValidateClientExternalIdsQuery = {
  __typename?: 'Query';
  validateClientExternalIds: {
    __typename?: 'ClientsValidatedExternalIdsResponse';
    ids: Array<string>;
  };
};

export type GetConflictyIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetConflictyIdQuery = {
  __typename?: 'Query';
  conflictById: {
    __typename?: 'Conflict';
    id: string;
    startAt: any;
    endAt: any;
    member: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  };
};

export type GetConflictsQueryVariables = Exact<{
  input: QueryConflictsInput;
}>;

export type GetConflictsQuery = {
  __typename?: 'Query';
  conflicts: Array<{
    __typename?: 'Conflict';
    id: string;
    startAt: any;
    endAt: any;
    member: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    eventConflicts: Array<{
      __typename?: 'EventConflict';
      event: { __typename?: 'Event'; id: string };
      conflict: { __typename?: 'Conflict'; id: string };
    }>;
    unavailableConflicts: Array<{
      __typename?: 'MemberUnavailableConflict';
      memberUnavailable: { __typename?: 'MemberUnavailable'; id: string };
      conflict: { __typename?: 'Conflict'; id: string };
    }>;
  }>;
};

export type GetClaimedEventsQueryVariables = Exact<{
  input: QueryEventClaimsInput;
}>;

export type GetClaimedEventsQuery = {
  __typename?: 'Query';
  eventClaims: Array<{
    __typename?: 'EventClaim';
    id: string;
    createdAt?: any | null;
    dismissedAt?: any | null;
    member: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    event: {
      __typename?: 'Event';
      id: string;
      startAt: any;
      endAt: any;
      locality?: string | null;
      region?: AddressRegionCode | null;
      requireCar: boolean;
      client: {
        __typename?: 'Client';
        id: string;
        color?: any | null;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
      archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
      delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
      cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
    };
  }>;
};

export type GetEventClaimsQueryVariables = Exact<{
  eventClaimsInput: QueryEventClaimsInput;
  hoursInput: QueryMemberHoursInput;
}>;

export type GetEventClaimsQuery = {
  __typename?: 'Query';
  eventClaims: Array<{
    __typename?: 'EventClaim';
    id: string;
    createdAt?: any | null;
    member: {
      __typename?: 'Member';
      id: string;
      schedulable: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
      hours: Array<{
        __typename?: 'MemberHour';
        id: string;
        date: any;
        type: MemberHoursType;
        value?: any | null;
      }>;
    };
    event: { __typename?: 'Event'; id: string };
    issues: { __typename?: 'EventClaimIssues'; reasons?: Array<string> | null };
  }>;
};

export type GetEventClaimsNotificationBadgeQueryVariables = Exact<{
  input: QueryEventClaimsInput;
}>;

export type GetEventClaimsNotificationBadgeQuery = {
  __typename?: 'Query';
  eventClaims: Array<{
    __typename?: 'EventClaim';
    id: string;
    dismissedAt?: any | null;
  }>;
};

export type GetEventsWithClaimsQueryVariables = Exact<{
  eventsInput: QueryEventsInput;
  claimsInput: QueryEventClaimsInput;
}>;

export type GetEventsWithClaimsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    locality?: string | null;
    region?: AddressRegionCode | null;
    requireCar: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    claims: Array<{
      __typename?: 'EventClaim';
      id: string;
      createdAt?: any | null;
      member: {
        __typename?: 'Member';
        id: string;
        color?: any | null;
        hasCar?: boolean | null;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    }>;
  }>;
};

export type GetOldClaimedEventsQueryVariables = Exact<{
  input: QueryEventClaimsInput;
}>;

export type GetOldClaimedEventsQuery = {
  __typename?: 'Query';
  eventClaims: Array<{
    __typename?: 'EventClaim';
    id: string;
    createdAt?: any | null;
    dismissedAt?: any | null;
    member: {
      __typename?: 'Member';
      id: string;
      schedulable: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    dismissedBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    event: {
      __typename?: 'Event';
      id: string;
      startAt: any;
      endAt: any;
      locality?: string | null;
      region?: AddressRegionCode | null;
      requireCar: boolean;
      client: {
        __typename?: 'Client';
        id: string;
        color?: any | null;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
      member?: { __typename?: 'Member'; id: string } | null;
      archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
      delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
      cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
    };
  }>;
};

export type GetOrgClaimRangeSettingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrgClaimRangeSettingQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        claimEventsDayRange?: number | null;
      };
    } | null;
  };
};

export type DownloadEventFileByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadEventFileByIdQuery = {
  __typename?: 'Query';
  downloadEventFileById: { __typename?: 'File'; id: string; path: string };
};

export type GetEventFilesQueryVariables = Exact<{
  input: QueryEventFilesInput;
}>;

export type GetEventFilesQuery = {
  __typename?: 'Query';
  eventFiles: Array<{
    __typename?: 'EventFile';
    id: string;
    type: EventFileType;
    dateOfFile?: any | null;
    value?: string | null;
    file: {
      __typename?: 'File';
      id: string;
      size: number;
      createdAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    };
  }>;
};

export type GetEventAccessLogsQueryVariables = Exact<{
  input: QueryAccessLogsInput;
}>;

export type GetEventAccessLogsQuery = {
  __typename?: 'Query';
  accessLogs: Array<{
    __typename?: 'AccessLog';
    id: string;
    date: any;
    ip: string;
    actor: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    event?: { __typename?: 'Event'; id: string } | null;
  }>;
};

export type GetEventByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  eventIssuesInput: QueryEventIssuesInput;
  fileTypeInput: QueryClientHasFileInput;
}>;

export type GetEventByIdQuery = {
  __typename?: 'Query';
  eventById: {
    __typename?: 'Event';
    id: string;
    createdAt?: any | null;
    verified?: boolean | null;
    verifiedAt?: any | null;
    locked?: boolean | null;
    startAt: any;
    endAt: any;
    duration: number;
    summary?: string | null;
    scheduleNotes?: string | null;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    billable: boolean;
    billingRegistrationGroup: EventBillingRegistrationGroup;
    payable: boolean;
    bonusPay: number;
    publicHoliday?: boolean | null;
    memberAssignedAutomatically: boolean;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    clockedOnLatitude?: string | null;
    clockedOnLongitude?: string | null;
    clockedOffLatitude?: string | null;
    clockedOffLongitude?: string | null;
    revenue: number;
    travelDistance?: number | null;
    travelDistanceNotes?: string | null;
    travelTime?: number | null;
    travelTimeNotes?: string | null;
    requireCar: boolean;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    clockedOnBy?: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    clockedOffBy?: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    travelApprovedBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    client: {
      __typename?: 'Client';
      id: string;
      color?: any | null;
      timezone: Timezone;
      defaultEventDuration?: number | null;
      allowMedication: boolean;
      allowObservations: boolean;
      allowRecordSeizures: boolean;
      requireHandover: boolean;
      bloodSystolicUpperThreshold1?: number | null;
      bloodSystolicLowerThreshold1?: number | null;
      bloodDiastolicUpperThreshold1?: number | null;
      bloodDiastolicLowerThreshold1?: number | null;
      heartUpperThreshold1?: number | null;
      heartLowerThreshold1?: number | null;
      spo2UpperThreshold1?: number | null;
      spo2LowerThreshold1?: number | null;
      respirationUpperThreshold1?: number | null;
      respirationLowerThreshold1?: number | null;
      bloodSystolicUpperThreshold2?: number | null;
      bloodSystolicLowerThreshold2?: number | null;
      bloodDiastolicUpperThreshold2?: number | null;
      bloodDiastolicLowerThreshold2?: number | null;
      heartUpperThreshold2?: number | null;
      heartLowerThreshold2?: number | null;
      spo2UpperThreshold2?: number | null;
      spo2LowerThreshold2?: number | null;
      respirationUpperThreshold2?: number | null;
      respirationLowerThreshold2?: number | null;
      hasFile: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      timezone: Timezone;
      lateCount: Array<number>;
      canPromptMedication: boolean;
      hasCertSupportingSafeMeals: boolean;
      hasCertEffectiveCommunication: boolean;
      hasCertPreventingDementia: boolean;
      hasCertUnderstandingDementia: boolean;
      hasCertManualHandling: boolean;
      hasCertSeizureRecognition: boolean;
      hasCar?: boolean | null;
      canHoist?: boolean | null;
      bonusEligible: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    issues: Array<{
      __typename?: 'EventIssue';
      id: string;
      type: EventIssueType;
      closedAt?: any | null;
    }>;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    cancel?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  };
};

export type GetEventCreatorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetEventCreatorQuery = {
  __typename?: 'Query';
  eventById: {
    __typename?: 'Event';
    id: string;
    createdAt?: any | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type GetEventHistoriesQueryVariables = Exact<{
  input: QueryHistoriesInput;
}>;

export type GetEventHistoriesQuery = {
  __typename?: 'Query';
  histories?: Array<
    | {
        __typename?: 'HistoryBranchName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientAllowAutomaticEventPopulation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactCustomRelation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactEmergencyContact';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientContactRelations';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDefaultEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanCoreSupportItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanEndDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCb';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCore';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanInitialFundsCs';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemCategory';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemInitialFunds';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemManagedBy';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionBillingDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionInvoiceNumber';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionMemo';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateEnd';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionServiceDateStart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplier';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionSupplierName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanItemTransactionValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanStartDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNdisPlanSupportCoordinationItem';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteComment';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedBy';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteCommentedByText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteKeywordText';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNoteType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodDiastolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationBloodSystolic';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationHeart';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationNursesName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationRespiration';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationSpo2';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationStoolType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientObservationTemp';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPlanManager';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientSupportCoordinator';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryClientUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventActiveAssist';
        id: string;
        date: any;
        type: HistoryType;
        eventActiveAssistNew?: boolean | null;
        eventActiveAssistOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventBillable';
        id: string;
        date: any;
        type: HistoryType;
        eventBillablenew?: boolean | null;
        eventBillableOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventBillingRegistrationGroup';
        id: string;
        date: any;
        type: HistoryType;
        eventBillingRegistrationGroupNew?: EventBillingRegistrationGroup | null;
        eventBillingRegistrationGroupOld?: EventBillingRegistrationGroup | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventClient';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
        eventClientNew?: {
          __typename?: 'Client';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        eventClientOld?: {
          __typename?: 'Client';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventClockedOffAt';
        id: string;
        date: any;
        type: HistoryType;
        eventClockedOffAtNew?: any | null;
        eventClockedOffAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventClockedOnAt';
        id: string;
        date: any;
        type: HistoryType;
        eventClockedOnAtNew?: any | null;
        eventClockedOnAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventCountry';
        id: string;
        date: any;
        type: HistoryType;
        eventCountryNew?: AddressCountryCode | null;
        eventCountryOld?: AddressCountryCode | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventDuration';
        id: string;
        date: any;
        type: HistoryType;
        eventDurationNew?: number | null;
        eventDurationOld?: number | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventLocality';
        id: string;
        date: any;
        type: HistoryType;
        eventLocalityNew?: string | null;
        eventLocalityOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventMember';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
        eventMemberNew?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        eventMemberOld?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryEventNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPassive';
        id: string;
        date: any;
        type: HistoryType;
        eventPassiveNew?: boolean | null;
        eventPassiveOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPassiveStartAt';
        id: string;
        date: any;
        type: HistoryType;
        eventPassiveStartAtNew?: any | null;
        eventPassiveStartAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPayable';
        id: string;
        date: any;
        type: HistoryType;
        eventPayableNew?: boolean | null;
        eventPayableOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPostcode';
        id: string;
        date: any;
        type: HistoryType;
        eventPostcodeNew?: string | null;
        eventPostcodeOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventPublicHoliday';
        id: string;
        date: any;
        type: HistoryType;
        eventPublicHolidayNew?: boolean | null;
        eventPublicHolidayOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventRegion';
        id: string;
        date: any;
        type: HistoryType;
        eventRegionNew?: AddressRegionCode | null;
        eventRegionOld?: AddressRegionCode | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventRequireCar';
        id: string;
        date: any;
        type: HistoryType;
        eventRequireCarNew?: boolean | null;
        eventRequireCarOld?: boolean | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventScheduleNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventStartAt';
        id: string;
        date: any;
        type: HistoryType;
        eventStartAtNew?: any | null;
        eventStartAtOld?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventStreet';
        id: string;
        date: any;
        type: HistoryType;
        eventStreetNew?: string | null;
        eventStreetOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventSummary';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventTravelDistance';
        id: string;
        date: any;
        type: HistoryType;
        eventTravelDistanceNew?: number | null;
        eventTravelDistanceOld?: number | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventTravelDistanceNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventTravelTime';
        id: string;
        date: any;
        type: HistoryType;
        eventTravelTimeNew?: number | null;
        eventTravelTimeOld?: number | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventTravelTimeNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryEventUnit';
        id: string;
        date: any;
        type: HistoryType;
        eventUnitNew?: string | null;
        eventUnitOld?: string | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        event: { __typename?: 'Event'; id: string };
      }
    | {
        __typename?: 'HistoryMemberAdmin';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateDate';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberBasePayRateValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberColor';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberConfidentialNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberCountry';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberDob';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberEmpathyRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberExternalId';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileDateOfFile';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileExpiresAt';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileOwner';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileType';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFileValue';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberGender';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberLocality';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPostcode';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberPronouns';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberRegion';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberReliabilityRating';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberSchedulable';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberStreet';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryMemberUnit';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgLegalName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryOrgWebsite';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBillingEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerContactEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryPlanManagerSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryRestorable';
        id: string;
        date: any;
        type: HistoryType;
        reason?: string | null;
        restoredAt?: any | null;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
        restoredBy?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          middleName?: string | null;
          lastName?: string | null;
          preferredName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorAbn';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorBusinessPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorEmail';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorFirstName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorLastName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorMiddleName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorNotes';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPhone';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorPreferredName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistorySupportCoordinatorSalutation';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeDescription';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'HistoryVisaTypeName';
        id: string;
        date: any;
        type: HistoryType;
        actor?: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        } | null;
      }
  > | null;
};

export type GetEventOwnAndRelatedByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  fileTypeInput: QueryClientHasFileInput;
  eventIssuesInput: QueryEventIssuesInput;
}>;

export type GetEventOwnAndRelatedByIdQuery = {
  __typename?: 'Query';
  eventOwnAndRelatedById: {
    __typename?: 'EventOwnAndRelated';
    id: string;
    startAt: any;
    endAt: any;
    duration: number;
    notes?: string | null;
    summary?: string | null;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    publicHoliday: boolean;
    memberAssignedAutomatically: boolean;
    unit?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    travelDistance: number;
    travelDistanceNotes?: string | null;
    travelTime: number;
    travelTimeNotes?: string | null;
    requireCar: boolean;
    client: {
      __typename?: 'RedactedClient';
      id: string;
      color?: any | null;
      timezone: Timezone;
      allowMedication: boolean;
      allowObservations: boolean;
      allowRecordSeizures: boolean;
      bloodSystolicUpperThreshold1?: number | null;
      bloodSystolicLowerThreshold1?: number | null;
      bloodDiastolicUpperThreshold1?: number | null;
      bloodDiastolicLowerThreshold1?: number | null;
      heartUpperThreshold1?: number | null;
      heartLowerThreshold1?: number | null;
      spo2UpperThreshold1?: number | null;
      spo2LowerThreshold1?: number | null;
      respirationUpperThreshold1?: number | null;
      respirationLowerThreshold1?: number | null;
      bloodSystolicUpperThreshold2?: number | null;
      bloodSystolicLowerThreshold2?: number | null;
      bloodDiastolicUpperThreshold2?: number | null;
      bloodDiastolicLowerThreshold2?: number | null;
      heartUpperThreshold2?: number | null;
      heartLowerThreshold2?: number | null;
      spo2UpperThreshold2?: number | null;
      spo2LowerThreshold2?: number | null;
      respirationUpperThreshold2?: number | null;
      respirationLowerThreshold2?: number | null;
      requireHandover: boolean;
      hasFile: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'RedactedMember';
      id: string;
      color?: any | null;
      timezone: Timezone;
      lateCount: Array<number>;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    issues: Array<{
      __typename?: 'EventIssue';
      id: string;
      type: EventIssueType;
      closedAt?: any | null;
    }>;
    cancel?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
    } | null;
  };
};

export type GetEventsPotentialAttendeesQueryVariables = Exact<{
  input: QueryEventsPotentialAttendeesInput;
  scheduleInput: QueryMemberScheduleInput;
}>;

export type GetEventsPotentialAttendeesQuery = {
  __typename?: 'Query';
  eventPotentialAttendees: {
    __typename?: 'PotentialAttendees';
    members: Array<{
      __typename?: 'Member';
      id: string;
      color?: any | null;
      hasCar?: boolean | null;
      lateCount: Array<number>;
      workRights?: MemberWorkRights | null;
      bonusEligible: boolean;
      locality?: string | null;
      region?: AddressRegionCode | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
      schedule: {
        __typename?: 'MemberSchedule';
        totalMinutes: number;
        allowedMinutes: number;
      };
    }>;
    hours: Array<{
      __typename?: 'PotentialAttendeeHours';
      id: string;
      thisWeek: number;
      prevWeek: number;
      nextWeek: number;
    }>;
    conflicts: Array<{
      __typename?: 'PotentialAttendeeConflict';
      id: string;
      client: {
        __typename?: 'Client';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    }>;
    unavailables: Array<{
      __typename?: 'PotentialAttendeeUnavailables';
      id: string;
    }>;
    teams: Array<{
      __typename?: 'PotentialAttendeeTeams';
      id: string;
      preferred: boolean;
    }>;
  };
};

export type GetEventsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetEventsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    locked?: boolean | null;
    createdAt?: any | null;
    startAt: any;
    endAt: any;
    duration: number;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    hasNotes: boolean;
    publicHoliday?: boolean | null;
    travelDistance?: number | null;
    travelTime?: number | null;
    memberAssignedAutomatically: boolean;
    payable: boolean;
    bonusPay: number;
    verified?: boolean | null;
    hasIssue: boolean;
    hasSeizureObservation: boolean;
    region?: AddressRegionCode | null;
    client: {
      __typename?: 'Client';
      id: string;
      ndisId?: any | null;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
    cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetEventsMembersReadyToExportQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetEventsMembersReadyToExportQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    verified?: boolean | null;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    hasNotes: boolean;
    issues: Array<{
      __typename?: 'EventIssue';
      id: string;
      closedAt?: any | null;
    }>;
    member?: {
      __typename?: 'Member';
      id: string;
      bonusEligible: boolean;
      externalId?: string | null;
      employmentStartDate?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetEventsMembersPaySlipsDataQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetEventsMembersPaySlipsDataQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    hasNotes: boolean;
    member?: {
      __typename?: 'Member';
      id: string;
      currentHourlyBasePayRate: number;
      bonusEligible: boolean;
      externalId?: string | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetEventsMembersWorkedHoursQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetEventsMembersWorkedHoursQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type GetEventsOwnAndRelatedQueryVariables = Exact<{
  input: QueryEventsOwnAndRelatedInput;
}>;

export type GetEventsOwnAndRelatedQuery = {
  __typename?: 'Query';
  eventsOwnAndRelated: Array<{
    __typename?: 'EventOwnAndRelated';
    id: string;
    createdAt?: any | null;
    startAt: any;
    endAt: any;
    duration: number;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    hasNotes: boolean;
    publicHoliday: boolean;
    travelDistance: number;
    travelTime: number;
    memberAssignedAutomatically: boolean;
    verified?: boolean | null;
    hasIssue: boolean;
    hasSeizureObservation: boolean;
    region?: AddressRegionCode | null;
    client: {
      __typename?: 'RedactedClient';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'RedactedMember';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetNonClockedEventsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetNonClockedEventsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetUnfilledEventsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetUnfilledEventsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type GetMedicationByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMedicationByIdQuery = {
  __typename?: 'Query';
  medicationById: { __typename?: 'Medication'; id: string; name: string };
};

export type GetMedicationsQueryVariables = Exact<{
  input: QueryMedicationsInput;
}>;

export type GetMedicationsQuery = {
  __typename?: 'Query';
  medications: Array<{
    __typename?: 'Medication';
    id: string;
    name: string;
    pronunciation?: string | null;
    url?: string | null;
  }>;
};

export type DownloadMemberFileByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadMemberFileByIdQuery = {
  __typename?: 'Query';
  downloadMemberFileById: { __typename?: 'File'; id: string; path: string };
};

export type GetMemberFilesQueryVariables = Exact<{
  input: QueryMemberFilesInput;
}>;

export type GetMemberFilesQuery = {
  __typename?: 'Query';
  memberFiles: Array<{
    __typename?: 'MemberFile';
    id: string;
    type: MemberFileType;
    dateOfFile?: any | null;
    expiresAt?: any | null;
    issuedAt?: any | null;
    value?: string | null;
    file: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      createdAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    };
    memberUpload?: {
      __typename?: 'MemberUpload';
      id: string;
      processedAt?: any | null;
      processingNote?: string | null;
      processedBy?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  }>;
};

export type GetMemberScheduleTimeLimitsQueryVariables = Exact<{
  id: Scalars['ID'];
  input: QueryMemberScheduleTimeLimitsInput;
}>;

export type GetMemberScheduleTimeLimitsQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    scheduleTimeLimits: Array<{
      __typename?: 'MemberScheduleTimeLimit';
      id: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      description?: string | null;
      limit: number;
      startAt: any;
      endAt: any;
      createdBy: {
        __typename?: 'Member';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    }>;
  };
};

export type MemberUnavailableSummaryModalGetUnavailableQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MemberUnavailableSummaryModalGetUnavailableQuery = {
  __typename?: 'Query';
  memberUnavailableById: {
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    color?: any | null;
    dates: Array<Array<any>>;
    createdAt?: any | null;
    owner: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    createdBy: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  };
};

export type MemberViewUnavailablesGetUnavailablesQueryVariables = Exact<{
  input: QueryMemberUnavailablesInput;
}>;

export type MemberViewUnavailablesGetUnavailablesQuery = {
  __typename?: 'Query';
  memberUnavailables: Array<{
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    dates: Array<Array<any>>;
    createdBy: { __typename?: 'Member'; id: string };
    owner: { __typename?: 'Member'; id: string };
  }>;
};

export type DownloadMemberUploadByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DownloadMemberUploadByIdQuery = {
  __typename?: 'Query';
  downloadMemberUploadById: { __typename?: 'File'; id: string; path: string };
};

export type GetMemberUploadsQueryVariables = Exact<{
  input: QueryMemberUploadsInput;
}>;

export type GetMemberUploadsQuery = {
  __typename?: 'Query';
  memberUploads: Array<{
    __typename?: 'MemberUpload';
    id: string;
    createdAt?: any | null;
    type: MemberFileType;
    processingState: ProcessingState;
    uploadedBy: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    uploadedFor: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    file: {
      __typename?: 'File';
      id: string;
      name: string;
      size: number;
      mime: string;
    };
  }>;
};

export type GetMemberUploadsNotificationBadgeQueryVariables = Exact<{
  input: QueryMemberUploadsInput;
}>;

export type GetMemberUploadsNotificationBadgeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        memberUploads: Array<{
          __typename?: 'MemberUpload';
          id: string;
          processingState: ProcessingState;
        }>;
      };
    } | null;
  };
};

export type GetMissingMemberFilesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMissingMemberFilesQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      employmentStartDate?: any | null;
      missingFiles: Array<MemberFileType>;
      missingBankDetails: boolean;
    } | null;
  };
};

export type GetMemberBankDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberBankDetailsQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    bank?: {
      __typename?: 'MemberBank';
      bsb?: string | null;
      accountNumber?: string | null;
      accountName?: string | null;
    } | null;
  };
};

export type GetMemberBankDetailsSelfQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberBankDetailsSelfQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      bank?: {
        __typename?: 'MemberBank';
        bsb?: string | null;
        accountNumber?: string | null;
        accountName?: string | null;
      } | null;
    } | null;
  };
};

export type GetMemberByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberByIdQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    createdAt?: any | null;
    lastActiveAt?: any | null;
    hasAccess?: boolean | null;
    moduleAccessCS?: boolean | null;
    moduleAccessSC?: boolean | null;
    admin: boolean;
    permissions?: Array<Permission> | null;
    externalId?: string | null;
    schedulable: boolean;
    trackFiles: boolean;
    australianCitizen?: boolean | null;
    workRights?: MemberWorkRights | null;
    workRightsLastCheckedAt?: any | null;
    passportCountry?: AddressCountryCode | null;
    passportNumber?: string | null;
    allowedFortnightlyWorkHours?: number | null;
    driversLicense?: DriversLicense | null;
    driversLicenseCountry?: AddressCountryCode | null;
    driversLicenseExpiryDate?: any | null;
    hasCar?: boolean | null;
    carDescription?: string | null;
    canHoist?: boolean | null;
    color?: any | null;
    dob?: any | null;
    email?: string | null;
    phone?: string | null;
    bio?: string | null;
    notes?: string | null;
    confidentialNotes?: string | null;
    employmentStartDate?: any | null;
    employmentEndDate?: any | null;
    study?: string | null;
    currentHourlyBasePayRate: number;
    currentAnnualPayRate: number;
    bonusEligible: boolean;
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
    remindClock?: boolean | null;
    remindEvents?: boolean | null;
    remindNotes?: boolean | null;
    empathyRating?: number | null;
    reliabilityRating?: number | null;
    canPromptMedication: boolean;
    hasCertSupportingSafeMeals: boolean;
    hasCertEffectiveCommunication: boolean;
    hasCertPreventingDementia: boolean;
    hasCertUnderstandingDementia: boolean;
    hasCertManualHandling: boolean;
    hasCertSeizureRecognition: boolean;
    lateness?: number | null;
    currentPostNominal?: string | null;
    employementContractExternallySaved?: number | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
    visaType?: { __typename?: 'VisaType'; id: string; name: string } | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    invitationMostRecentlyReceived?: {
      __typename?: 'MemberInvitation';
      id: string;
      deletedAt?: any | null;
      usedAt?: any | null;
      sentCount: number;
      lastSender: {
        __typename?: 'MemberInvitationSender';
        id: string;
        sentAt: any;
        sentBy: {
          __typename?: 'Member';
          id: string;
          firstName: string;
          lastName?: string | null;
        };
      };
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  };
};

export type GetMemberEmploymentAgreementDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberEmploymentAgreementDataQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      middleName?: string | null;
      lastName?: string | null;
      email?: string | null;
      employmentStartDate?: any | null;
      unit?: string | null;
      street?: string | null;
      locality?: string | null;
      region?: AddressRegionCode | null;
      postcode?: any | null;
      country?: AddressCountryCode | null;
      currentHourlyBasePayRate: number;
    } | null;
  };
};

export type GetMemberFinancialDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberFinancialDetailsQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    bank?: {
      __typename?: 'MemberBank';
      bsb?: string | null;
      accountNumber?: string | null;
      accountName?: string | null;
    } | null;
    super?: {
      __typename?: 'MemberSuper';
      usi?: string | null;
      fundName?: string | null;
      fundAbn?: any | null;
      accountName?: string | null;
      accountNumber?: string | null;
    } | null;
    taxFileNumberDeclaration?: {
      __typename?: 'MemberTaxFileNumberDeclaration';
      tfn?: string | null;
      appliedForTfn?: boolean | null;
      claimingExemptionMinor?: boolean | null;
      claimingExemptionPension?: boolean | null;
      salutation?: Salutation | null;
      lastName?: string | null;
      firstName?: string | null;
      middleName?: string | null;
      street?: string | null;
      locality?: string | null;
      region?: AddressRegionCode | null;
      postcode?: any | null;
      prevLastName?: string | null;
      email?: string | null;
      dob?: any | null;
      basisOfPay?: MemberTaxFileNumberDeclarationBasisOfPay | null;
      areYou?: MemberTaxFileNumberDeclarationAreYou | null;
      claimTaxFreeThreshold?: boolean | null;
      studentLoanDebt?: boolean | null;
    } | null;
  };
};

export type GetMemberScheduleByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  scheduleInput: QueryMemberScheduleInput;
}>;

export type GetMemberScheduleByIdQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    schedule: {
      __typename?: 'MemberSchedule';
      totalMinutes: number;
      allowedMinutes: number;
    };
  };
};

export type GetMemberScheduleMineQueryVariables = Exact<{
  scheduleInput: QueryMemberScheduleInput;
}>;

export type GetMemberScheduleMineQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      schedule: {
        __typename?: 'MemberSchedule';
        totalMinutes: number;
        allowedMinutes: number;
      };
    } | null;
  };
};

export type GetMemberScheduleSettingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberScheduleSettingsQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    scheduleMaxDayTimeLimitDefault?: number | null;
    scheduleMaxWeekTimeLimitDefault?: number | null;
    scheduleMaxWeekTimeLimitCurrent: number;
    org: {
      __typename?: 'Org';
      id: string;
      scheduleMaxDayTimeLimitDefault?: number | null;
      scheduleMaxWeekTimeLimitDefault?: number | null;
    };
  };
};

export type GetMemberSuperDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberSuperDetailsQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    super?: {
      __typename?: 'MemberSuper';
      usi?: string | null;
      fundName?: string | null;
      fundAbn?: any | null;
      accountName?: string | null;
      accountNumber?: string | null;
    } | null;
  };
};

export type GetMemberSuperDetailsSelfQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberSuperDetailsSelfQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      super?: {
        __typename?: 'MemberSuper';
        usi?: string | null;
        fundName?: string | null;
        fundAbn?: any | null;
        accountName?: string | null;
        accountNumber?: string | null;
      } | null;
    } | null;
  };
};

export type GetMemberTaxFileNumberDeclarationDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberTaxFileNumberDeclarationDetailsQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    taxFileNumberDeclaration?: {
      __typename?: 'MemberTaxFileNumberDeclaration';
      tfn?: string | null;
      appliedForTfn?: boolean | null;
      claimingExemptionMinor?: boolean | null;
      claimingExemptionPension?: boolean | null;
      salutation?: Salutation | null;
      lastName?: string | null;
      firstName?: string | null;
      middleName?: string | null;
      street?: string | null;
      locality?: string | null;
      region?: AddressRegionCode | null;
      postcode?: any | null;
      prevLastName?: string | null;
      email?: string | null;
      dob?: any | null;
      basisOfPay?: MemberTaxFileNumberDeclarationBasisOfPay | null;
      areYou?: MemberTaxFileNumberDeclarationAreYou | null;
      claimTaxFreeThreshold?: boolean | null;
      studentLoanDebt?: boolean | null;
    } | null;
  };
};

export type GetMemberTaxFileNumberDeclarationDetailsSelfQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMemberTaxFileNumberDeclarationDetailsSelfQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      taxFileNumberDeclaration?: {
        __typename?: 'MemberTaxFileNumberDeclaration';
        tfn?: string | null;
        appliedForTfn?: boolean | null;
        claimingExemptionMinor?: boolean | null;
        claimingExemptionPension?: boolean | null;
        salutation?: Salutation | null;
        lastName?: string | null;
        firstName?: string | null;
        middleName?: string | null;
        street?: string | null;
        locality?: string | null;
        region?: AddressRegionCode | null;
        postcode?: any | null;
        prevLastName?: string | null;
        email?: string | null;
        dob?: any | null;
        basisOfPay?: MemberTaxFileNumberDeclarationBasisOfPay | null;
        areYou?: MemberTaxFileNumberDeclarationAreYou | null;
        claimTaxFreeThreshold?: boolean | null;
        studentLoanDebt?: boolean | null;
      } | null;
    } | null;
  };
};

export type GetMemberTimezoneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberTimezoneQuery = {
  __typename?: 'Query';
  memberById: { __typename?: 'Member'; id: string; timezone: Timezone };
};

export type GetMemberTypicalUnavailablilityQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberTypicalUnavailablilityQuery = {
  __typename?: 'Query';
  memberById: {
    __typename?: 'Member';
    id: string;
    unavailableNotes?: string | null;
    unavailableMoMorn: boolean;
    unavailableMoDay: boolean;
    unavailableMoNight: boolean;
    unavailableTuMorn: boolean;
    unavailableTuDay: boolean;
    unavailableTuNight: boolean;
    unavailableWeMorn: boolean;
    unavailableWeDay: boolean;
    unavailableWeNight: boolean;
    unavailableThMorn: boolean;
    unavailableThDay: boolean;
    unavailableThNight: boolean;
    unavailableFrMorn: boolean;
    unavailableFrDay: boolean;
    unavailableFrNight: boolean;
    unavailableSaMorn: boolean;
    unavailableSaDay: boolean;
    unavailableSaNight: boolean;
    unavailableSuMorn: boolean;
    unavailableSuDay: boolean;
    unavailableSuNight: boolean;
  };
};

export type GetMemberUnavailablesQueryVariables = Exact<{
  unavailablesInput: QueryMemberUnavailablesInput;
}>;

export type GetMemberUnavailablesQuery = {
  __typename?: 'Query';
  memberUnavailables: Array<{
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    owner: { __typename?: 'Member'; id: string };
  }>;
};

export type GetMembersQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type GetMembersQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    admin: boolean;
    permissions?: Array<Permission> | null;
    color?: any | null;
    hasAccess?: boolean | null;
    externalId?: string | null;
    lastActiveAt?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    invitationMostRecentlyReceived?: {
      __typename?: 'MemberInvitation';
      id: string;
      deletedAt?: any | null;
      usedAt?: any | null;
    } | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetMemberBasePayRateByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberBasePayRateByIdQuery = {
  __typename?: 'Query';
  memberBasePayRateById: {
    __typename?: 'MemberBasePayRate';
    id: string;
    date: any;
    value: number;
    type: MemberBasePayRateType;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type GetMemberBasePayRatesQueryVariables = Exact<{
  input: QueryMemberBasePayRatesInput;
}>;

export type GetMemberBasePayRatesQuery = {
  __typename?: 'Query';
  memberBasePayRates: Array<{
    __typename?: 'MemberBasePayRate';
    id: string;
    date: any;
    value: number;
    type: MemberBasePayRateType;
    active: boolean;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetMemberBranchAllocationByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberBranchAllocationByIdQuery = {
  __typename?: 'Query';
  memberBranchAllocationById: {
    __typename?: 'MemberBranchAllocation';
    id: string;
    date: any;
    deletedAt?: any | null;
    createdBy: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member: { __typename?: 'Member'; id: string };
    values: Array<{
      __typename?: 'MemberBranchAllocationValue';
      value: number;
      branchId: any;
    }>;
  };
};

export type GetMemberBranchAllocationsQueryVariables = Exact<{
  input: QueryMemberBranchAllocationsInput;
}>;

export type GetMemberBranchAllocationsQuery = {
  __typename?: 'Query';
  memberBranchAllocations: Array<{
    __typename?: 'MemberBranchAllocation';
    id: string;
    date: any;
    deletedAt?: any | null;
    createdBy: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member: { __typename?: 'Member'; id: string };
    values: Array<{
      __typename?: 'MemberBranchAllocationValue';
      value: number;
      branchId: any;
    }>;
  }>;
};

export type GetMembersNamesQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type GetMembersNamesQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetMemberPermissionsQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type GetMemberPermissionsQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    admin: boolean;
    permissions?: Array<Permission> | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetMemberPostNominalByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMemberPostNominalByIdQuery = {
  __typename?: 'Query';
  memberPostNominalById: {
    __typename?: 'MemberPostNominal';
    id: string;
    date: any;
    deletedAt?: any | null;
    value: string;
    createdBy: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member: { __typename?: 'Member'; id: string };
  };
};

export type GetMemberPostNominalsQueryVariables = Exact<{
  input: QueryMemberPostNominalsInput;
}>;

export type GetMemberPostNominalsQuery = {
  __typename?: 'Query';
  memberPostNominals: Array<{
    __typename?: 'MemberPostNominal';
    id: string;
    date: any;
    deletedAt?: any | null;
    value: string;
    createdBy: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member: { __typename?: 'Member'; id: string };
  }>;
};

export type GetMembersSelectQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type GetMembersSelectQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    timezone: Timezone;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetMyUnavailablesQueryVariables = Exact<{
  input: QueryMyMemberUnavailablesInput;
}>;

export type GetMyUnavailablesQuery = {
  __typename?: 'Query';
  myUnavailables: Array<{
    __typename?: 'MemberUnavailable';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    owner: { __typename?: 'Member'; id: string };
  }>;
};

export type GetRedactedMemberByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetRedactedMemberByIdQuery = {
  __typename?: 'Query';
  redactedMemberById: {
    __typename?: 'RedactedMember';
    id: string;
    color?: any | null;
    email?: string | null;
    phone?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  };
};

export type GetRedactedMembersQueryVariables = Exact<{
  input: QueryRedactedMembersInput;
}>;

export type GetRedactedMembersQuery = {
  __typename?: 'Query';
  redactedMembers: Array<{
    __typename?: 'RedactedMember';
    id: string;
    color?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type MemberListContextGetAggregatedMembersQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type MemberListContextGetAggregatedMembersQuery = {
  __typename?: 'Query';
  membersAggregate: {
    __typename?: 'MembersAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{
      __typename?: 'Member';
      id: string;
      createdAt?: any | null;
      admin: boolean;
      permissions?: Array<Permission> | null;
      color?: any | null;
      hasAccess?: boolean | null;
      externalId?: string | null;
      lastActiveAt?: any | null;
      employmentStartDate?: any | null;
      schedulable: boolean;
      trackFiles: boolean;
      currentHourlyBasePayRate: number;
      currentAnnualPayRate: number;
      bonusEligible: boolean;
      employementContractExternallySaved?: number | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
      invitationMostRecentlyReceived?: {
        __typename?: 'MemberInvitation';
        id: string;
        deletedAt?: any | null;
        usedAt?: any | null;
      } | null;
    }>;
  };
};

export type MemberListContextGetAggregatedRedactedMembersQueryVariables =
  Exact<{
    input: QueryRedactedMembersInput;
  }>;

export type MemberListContextGetAggregatedRedactedMembersQuery = {
  __typename?: 'Query';
  redactedMembersAggregate: {
    __typename?: 'RedactedMembersAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{
      __typename?: 'RedactedMember';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    }>;
  };
};

export type MemberListContextGetMembersExtraFieldsQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type MemberListContextGetMembersExtraFieldsQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    totalBillableMinutes?: number | null;
    lateness?: number | null;
    firstBillableEvent?: {
      __typename?: 'Event';
      id: string;
      startAt: any;
    } | null;
    lastBillableEvent?: {
      __typename?: 'Event';
      id: string;
      startAt: any;
    } | null;
  }>;
};

export type ValidateMemberExternalIdsQueryVariables = Exact<{
  input: MembersValidateExternalldsInput;
}>;

export type ValidateMemberExternalIdsQuery = {
  __typename?: 'Query';
  validateMemberExternalIds: {
    __typename?: 'MembersValidatedExternalIdsResponse';
    ids: Array<string>;
  };
};

export type NdisCoordinationClientByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type NdisCoordinationClientByIdQuery = {
  __typename?: 'Query';
  ndisCoordinationClientById: {
    __typename?: 'NdisCoordinationClient';
    id: string;
    createdAt?: any | null;
    color?: any | null;
    dob?: any | null;
    email?: string | null;
    phone?: string | null;
    bio?: string | null;
    ndisId?: any | null;
    externalId?: string | null;
    middleName?: string | null;
    preferredName?: string | null;
    gender?: Gender | null;
    salutation?: Salutation | null;
    pronouns?: Pronouns | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    firstName: string;
    lastName?: string | null;
    branch: { __typename?: 'Branch'; id: string; name: string };
    createdBy?: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    preferredContact?: { __typename?: 'ClientContact'; id: string } | null;
    planManager?: {
      __typename?: 'PlanManager';
      id: string;
      name: string;
    } | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  };
};

export type NdisCoordinationClientListContextGetAggregatedClientsQueryVariables =
  Exact<{
    input: QueryNdisCoordinationClientInput;
  }>;

export type NdisCoordinationClientListContextGetAggregatedClientsQuery = {
  __typename?: 'Query';
  ndisCoordinationClientsAggregate: {
    __typename?: 'NdisCoordinationClientAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{
      __typename?: 'NdisCoordinationClient';
      id: string;
      createdAt?: any | null;
      color?: any | null;
      totalActivityMinutes?: number | null;
      firstActivityDate?: any | null;
      lastActivityDate?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    }>;
  };
};

export type GetCurrentOrgPayrollSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentOrgPayrollSettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        basePayRate?: number | null;
        afternoonPayMultiplier?: number | null;
        nightPayMultiplier?: number | null;
        satPayMultiplier?: number | null;
        sunPayMultiplier?: number | null;
        pubPayMultiplier?: number | null;
        passiveAllowance?: number | null;
        kmAllowance?: number | null;
        bonus2PayRate?: number | null;
        bonus3PayRate?: number | null;
        bonus4PayRate?: number | null;
        bonus5PayRate?: number | null;
        bonus6PayRate?: number | null;
        bonus7PayRate?: number | null;
        bonus8PayRate?: number | null;
        bonus9PayRate?: number | null;
        bonus10PayRate?: number | null;
        bonus11PayRate?: number | null;
        bonus12PayRate?: number | null;
      };
    } | null;
  };
};

export type GetOrgBillingSettingsByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOrgBillingSettingsByIdQuery = {
  __typename?: 'Query';
  orgById: {
    __typename?: 'Org';
    id: string;
    billing: {
      __typename?: 'OrgBilling';
      day?: number | null;
      afternoon?: number | null;
      night?: number | null;
      saturday?: number | null;
      sunday?: number | null;
      publicHoliday?: number | null;
      overnight?: number | null;
      kms?: number | null;
    };
  };
};

export type GetOrgIdeasQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrgIdeasQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: { __typename?: 'Org'; id: string; ideas?: string | null };
    } | null;
  };
};

export type GetOrgNdisRegistrationGroupsQueryVariables = Exact<{
  input?: InputMaybe<QueryNdisRegistrationGroupsInput>;
}>;

export type GetOrgNdisRegistrationGroupsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        ndisRegistrationGroups: Array<{
          __typename?: 'NdisRegistrationGroup';
          id: string;
          name: string;
        }>;
      };
    } | null;
  };
};

export type GetOrgNdisSupportItemsQueryVariables = Exact<{
  input?: InputMaybe<QueryNdisSupportItemsInput>;
}>;

export type GetOrgNdisSupportItemsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        ndisRegistrationGroups: Array<{
          __typename?: 'NdisRegistrationGroup';
          id: string;
          name: string;
          supportItems: Array<{
            __typename?: 'NdisSupportItem';
            id: string;
            name: string;
          }>;
        }>;
      };
    } | null;
  };
};

export type GetOrgPayrollSettingsByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOrgPayrollSettingsByIdQuery = {
  __typename?: 'Query';
  orgById: {
    __typename?: 'Org';
    id: string;
    basePayRate?: number | null;
    afternoonPayMultiplier?: number | null;
    nightPayMultiplier?: number | null;
    satPayMultiplier?: number | null;
    sunPayMultiplier?: number | null;
    pubPayMultiplier?: number | null;
    passiveAllowance?: number | null;
    kmAllowance?: number | null;
    bonus2PayRate?: number | null;
    bonus3PayRate?: number | null;
    bonus4PayRate?: number | null;
    bonus5PayRate?: number | null;
    bonus6PayRate?: number | null;
    bonus7PayRate?: number | null;
    bonus8PayRate?: number | null;
    bonus9PayRate?: number | null;
    bonus10PayRate?: number | null;
    bonus11PayRate?: number | null;
    bonus12PayRate?: number | null;
  };
};

export type GetOrgScheduleLatenessSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOrgScheduleLatenessSettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        maxMemberLatenessForAutomaticSundayEvents?: number | null;
        maxMemberLatenessForAutomaticSaturdayEvents?: number | null;
        maxMemberLatenessForAutomaticPublicHolidayEvents?: number | null;
        maxMemberLatenessForAutomaticHandoverEvents?: number | null;
      };
    } | null;
  };
};

export type GetOrgSettingsByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOrgSettingsByIdQuery = {
  __typename?: 'Query';
  orgById: {
    __typename?: 'Org';
    id: string;
    membersCanSeeClockedTimes?: boolean | null;
    quantityOfFutureShiftsMembersCanSee?: number | null;
    quantityOfPastShiftsMembersCanSee?: number | null;
    taskAssignAttendeeEnable?: boolean | null;
    taskAssignAttendeeFutureDays?: number | null;
    taskAssignAttendeePastWeeks?: number | null;
    unfilledEventsDayRange?: number | null;
    clockOnWarnMinutes?: number | null;
    clockOffWarnMinutes?: number | null;
    allowedDurationBetweenConflicts?: number | null;
    travelTimeAllowSameClient?: boolean | null;
    travelTimeAllowedDurationBetweenEvents?: number | null;
    claimEventsDayRange?: number | null;
    scheduleMaxDayTimeLimitDefault?: number | null;
    scheduleMaxWeekTimeLimitDefault?: number | null;
    maxMemberLatenessForAutomaticSundayEvents?: number | null;
    maxMemberLatenessForAutomaticSaturdayEvents?: number | null;
    maxMemberLatenessForAutomaticPublicHolidayEvents?: number | null;
    maxMemberLatenessForAutomaticHandoverEvents?: number | null;
    autoArchiveClockedOffEventsAfterNumberOfHours?: number | null;
    schedulingContactPerson?: {
      __typename?: 'Member';
      id: string;
      phone?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    schedulingContactPerson2?: {
      __typename?: 'Member';
      id: string;
      phone?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  };
};

export type GetOrgUnfilledEventsDayRangeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOrgUnfilledEventsDayRangeQuery = {
  __typename?: 'Query';
  orgById: {
    __typename?: 'Org';
    id: string;
    unfilledEventsDayRange?: number | null;
  };
};

export type GetPlanManagerByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPlanManagerByIdQuery = {
  __typename?: 'Query';
  planManagerById: {
    __typename?: 'PlanManager';
    id: string;
    abn?: any | null;
    name: string;
    salutation?: Salutation | null;
    phone?: string | null;
    businessName?: string | null;
    contactEmail?: string | null;
    billingEmail?: string | null;
    notes?: string | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  };
};

export type GetPlanManagersQueryVariables = Exact<{
  input: QueryPlanManagersInput;
}>;

export type GetPlanManagersQuery = {
  __typename?: 'Query';
  planManagers: Array<{
    __typename?: 'PlanManager';
    id: string;
    name: string;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetPublicHolidayByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPublicHolidayByIdQuery = {
  __typename?: 'Query';
  publicHolidayById: {
    __typename?: 'PublicHoliday';
    id: string;
    date: any;
    region?: AddressRegionCode | null;
    description: string;
  };
};

export type GetPublicHolidaysQueryVariables = Exact<{
  input: QueryPublicHolidaysInput;
}>;

export type GetPublicHolidaysQuery = {
  __typename?: 'Query';
  publicHolidays: Array<{
    __typename?: 'PublicHoliday';
    id: string;
    date: any;
    region?: AddressRegionCode | null;
    description: string;
  }>;
};

export type GetActivitySlipMembersQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type GetActivitySlipMembersQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type GetActivitySlipsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetActivitySlipsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    publicHoliday?: boolean | null;
    travelDistance?: number | null;
    travelTime?: number | null;
    billable: boolean;
    billingRegistrationGroup: EventBillingRegistrationGroup;
    payable: boolean;
    bonusPay: number;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    hasNotes: boolean;
    region?: AddressRegionCode | null;
    issues: Array<{
      __typename?: 'EventIssue';
      id: string;
      type: EventIssueType;
      closedAt?: any | null;
    }>;
    cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
    client: {
      __typename?: 'Client';
      id: string;
      ndisId?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
      planManager?: {
        __typename?: 'PlanManager';
        id: string;
        name: string;
      } | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      externalId?: string | null;
      bonusEligible: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetMemberWorkedHoursQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetMemberWorkedHoursQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    duration: number;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    travelTime?: number | null;
    passive: boolean;
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetShiftsToApproveQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetShiftsToApproveQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    clockedOnAt?: any | null;
    clockedOnLatitude?: string | null;
    clockedOnLongitude?: string | null;
    clockedOffAt?: any | null;
    clockedOffLatitude?: string | null;
    clockedOffLongitude?: string | null;
    payable: boolean;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type ReportActivitySlipsGetMemberPayrollChecksQueryVariables = Exact<{
  input: QueryMemberPayrollChecksInput;
}>;

export type ReportActivitySlipsGetMemberPayrollChecksQuery = {
  __typename?: 'Query';
  memberPayrollChecks: Array<{
    __typename?: 'MemberPayrollCheck';
    id: string;
    eventsChecked: boolean;
    clocksChecked: boolean;
    paid: boolean;
    member: { __typename?: 'Member'; id: string };
  }>;
};

export type ReportEventCheckerGetEventsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type ReportEventCheckerGetEventsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    notes?: string | null;
    passive: boolean;
    passiveStartAt?: any | null;
    activeAssist: boolean;
    billable: boolean;
    payable: boolean;
    publicHoliday?: boolean | null;
    clockedOnAt?: any | null;
    clockedOffAt?: any | null;
    clockedOnLatitude?: string | null;
    clockedOnLongitude?: string | null;
    clockedOffLatitude?: string | null;
    clockedOffLongitude?: string | null;
    travelDistance?: number | null;
    travelDistanceNotes?: string | null;
    travelTime?: number | null;
    travelTimeNotes?: string | null;
    unit?: string | null;
    buildingName?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    latitude?: string | null;
    longitude?: string | null;
    clockedOnBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    clockedOffBy?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    client: {
      __typename?: 'Client';
      id: string;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    cancel?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  }>;
};

export type ReportEventCheckerGetMembersQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type ReportEventCheckerGetMembersQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type ReportMemberHoursGetMembersQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type ReportMemberHoursGetMembersQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    hasCar?: boolean | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type ReportMemberHoursGetMembers2QueryVariables = Exact<{
  membersInput: QueryMembersInput;
  hoursInput: QueryMemberHoursInput;
}>;

export type ReportMemberHoursGetMembers2Query = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    hours: Array<{
      __typename?: 'MemberHour';
      id: string;
      date: any;
      type: MemberHoursType;
      value?: any | null;
    }>;
  }>;
};

export type ReportMemberHoursGetSchedulesQueryVariables = Exact<{
  scheduleInput: QueryMemberScheduleInput;
  membersInput: QueryMembersInput;
}>;

export type ReportMemberHoursGetSchedulesQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    schedule: {
      __typename?: 'MemberSchedule';
      allowedMinutes: number;
      totalMinutes: number;
    };
  }>;
};

export type ReportMemberPayCalculatorGetInitialDataQueryVariables = Exact<{
  membersInput: QueryMembersInput;
  publicHolidaysInput: QueryPublicHolidaysInput;
  payrollCategoriesInput: QueryPayrollCategoriesInput;
  payrollCategoryValuesInput: QueryPayrollCategoryValuesInput;
}>;

export type ReportMemberPayCalculatorGetInitialDataQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    firstName: string;
    lastName?: string | null;
  }>;
  publicHolidays: Array<{
    __typename?: 'PublicHoliday';
    id: string;
    date: any;
  }>;
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        payrollCategories: Array<{
          __typename?: 'PayrollCategory';
          id: string;
          name: string;
          values: Array<{
            __typename?: 'PayrollCategoryValue';
            id: string;
            createdAt?: any | null;
            type: PayrollCategoryValueType;
            fixedValue?: number | null;
            multiplier?: number | null;
          }>;
        }>;
      };
    } | null;
  };
};

export type ReportMemberPayCalculatorGetSubsequentDataQueryVariables = Exact<{
  eventsInput: QueryEventsInput;
  basePayRatesInput: QueryMemberBasePayRatesInput;
  bonusEligiblesInput: QueryMemberBonusEligiblesInput;
}>;

export type ReportMemberPayCalculatorGetSubsequentDataQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    activeAssist: boolean;
    passive: boolean;
    passiveStartAt?: any | null;
    travelTime?: number | null;
    travelDistance?: number | null;
    payable: boolean;
    member?: { __typename?: 'Member'; id: string } | null;
    cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
  memberBasePayRates: Array<{
    __typename?: 'MemberBasePayRate';
    id: string;
    createdAt?: any | null;
    value: number;
    member: { __typename?: 'Member'; id: string };
  }>;
  memberBonusEligibles: Array<{
    __typename?: 'MemberBonusEligible';
    id: string;
    createdAt?: any | null;
    value: boolean;
    member: { __typename?: 'Member'; id: string };
  }>;
};

export type ReportParticipantQuoteGetInitialDataQueryVariables = Exact<{
  clientsInput: QueryClientsInput;
  ndisActivitiesInput: QueryNdisActivitiesInput;
}>;

export type ReportParticipantQuoteGetInitialDataQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    ndisId?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
  ndisActivities: Array<{
    __typename?: 'NdisActivity';
    id: string;
    code: string;
    description: string;
    rateNational: number;
    rateRemote: number;
    rateVeryRemote: number;
  }>;
};

export type ReportParticipantQuoteGetPublicHolidaysQueryVariables = Exact<{
  input: QueryPublicHolidaysInput;
}>;

export type ReportParticipantQuoteGetPublicHolidaysQuery = {
  __typename?: 'Query';
  publicHolidays: Array<{
    __typename?: 'PublicHoliday';
    id: string;
    date: any;
  }>;
};

export type ReportParticipantSeizuresGetObservationsQueryVariables = Exact<{
  input: QueryClientObservationsInput;
}>;

export type ReportParticipantSeizuresGetObservationsQuery = {
  __typename?: 'Query';
  clientObservations: Array<{
    __typename?: 'ClientObservation';
    id: string;
    date?: any | null;
    notes?: string | null;
    seizureDuration?: number | null;
    seizureType?: SeizureType | null;
    seizureLostConsciousness?: SeizureLostConsciousness | null;
    incontinenceType?: IncontinenceType | null;
    postSeizureWalkDuration?: number | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      currentPostNominal?: string | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    member?: {
      __typename?: 'Member';
      id: string;
      currentPostNominal?: string | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    client: { __typename?: 'Client'; id: string };
  }>;
};

export type ReportShiftProfitLoss2GetFixedExpensesQueryVariables = Exact<{
  membersInput: QueryMembersInput;
}>;

export type ReportShiftProfitLoss2GetFixedExpensesQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    employmentStartDate?: any | null;
    employmentEndDate?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type ReportShiftProfitLoss2GetHolidaysEventsQueryVariables = Exact<{
  eventsInput: QueryEventsInput;
  publicHolidaysInput: QueryPublicHolidaysInput;
}>;

export type ReportShiftProfitLoss2GetHolidaysEventsQuery = {
  __typename?: 'Query';
  eventsNoCache: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    activeAssist: boolean;
    passive: boolean;
    passiveStartAt?: any | null;
    travelTime?: number | null;
    travelDistance?: number | null;
    payable: boolean;
    billable: boolean;
    publicHoliday?: boolean | null;
    region?: AddressRegionCode | null;
    client: { __typename?: 'Client'; id: string };
    member?: {
      __typename?: 'Member';
      id: string;
      bonusEligible: boolean;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
  publicHolidays: Array<{
    __typename?: 'PublicHoliday';
    id: string;
    date: any;
    region?: AddressRegionCode | null;
  }>;
};

export type ReportShiftProfitLoss2GetPayRatesQueryVariables = Exact<{
  membersInput: QueryMembersInput;
  memberBasePayRatesInput: QueryMemberBasePayRatesInput;
}>;

export type ReportShiftProfitLoss2GetPayRatesQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    bonusEligibleStartAt?: any | null;
    basePayRates: Array<{
      __typename?: 'MemberBasePayRate';
      id: string;
      date: any;
      value: number;
    }>;
  }>;
};

export type ReportShiftProfitLoss2GetRatesClientsMembersQueryVariables = Exact<{
  clientsInput: QueryClientsInput;
  membersInput: QueryMembersInput;
  memberPayRatesInput: QueryMemberBasePayRatesInput;
  memberBranchAllocationsInput: QueryMemberBranchAllocationsInput;
}>;

export type ReportShiftProfitLoss2GetRatesClientsMembersQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
  members: Array<{
    __typename?: 'Member';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
  memberBasePayRates: Array<{
    __typename?: 'MemberBasePayRate';
    id: string;
    date: any;
    value: number;
    type: MemberBasePayRateType;
    member: { __typename?: 'Member'; id: string };
  }>;
  memberBranchAllocations: Array<{
    __typename?: 'MemberBranchAllocation';
    id: string;
    date: any;
    values: Array<{
      __typename?: 'MemberBranchAllocationValue';
      branchId: any;
      value: number;
    }>;
    member: { __typename?: 'Member'; id: string };
  }>;
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        fixedExpenses?: string | null;
        basePayRate?: number | null;
        afternoonPayMultiplier?: number | null;
        nightPayMultiplier?: number | null;
        satPayMultiplier?: number | null;
        sunPayMultiplier?: number | null;
        pubPayMultiplier?: number | null;
        passiveAllowance?: number | null;
        kmAllowance?: number | null;
        bonus2PayRate?: number | null;
        bonus3PayRate?: number | null;
        bonus4PayRate?: number | null;
        bonus5PayRate?: number | null;
        bonus6PayRate?: number | null;
        bonus7PayRate?: number | null;
        bonus8PayRate?: number | null;
        bonus9PayRate?: number | null;
        bonus10PayRate?: number | null;
        bonus11PayRate?: number | null;
        bonus12PayRate?: number | null;
        billing: {
          __typename?: 'OrgBilling';
          day?: number | null;
          afternoon?: number | null;
          night?: number | null;
          saturday?: number | null;
          sunday?: number | null;
          publicHoliday?: number | null;
          overnight?: number | null;
          kms?: number | null;
        };
      };
    } | null;
  };
};

export type ReportShiftProfitLossGetHolidaysEventsQueryVariables = Exact<{
  eventsInput: QueryEventsInput;
  publicHolidaysInput: QueryPublicHolidaysInput;
}>;

export type ReportShiftProfitLossGetHolidaysEventsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    activeAssist: boolean;
    passive: boolean;
    passiveStartAt?: any | null;
    travelTime?: number | null;
    travelDistance?: number | null;
    payable: boolean;
    billable: boolean;
    client: { __typename?: 'Client'; id: string };
    member?: {
      __typename?: 'Member';
      id: string;
      bonusEligible: boolean;
      currentHourlyBasePayRate: number;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
    cancel?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
  publicHolidays: Array<{
    __typename?: 'PublicHoliday';
    id: string;
    date: any;
  }>;
};

export type ReportShiftProfitLossGetRatesClientsMembersQueryVariables = Exact<{
  clientsInput: QueryClientsInput;
  membersInput: QueryMembersInput;
}>;

export type ReportShiftProfitLossGetRatesClientsMembersQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
  members: Array<{
    __typename?: 'Member';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        basePayRate?: number | null;
        afternoonPayMultiplier?: number | null;
        nightPayMultiplier?: number | null;
        satPayMultiplier?: number | null;
        sunPayMultiplier?: number | null;
        pubPayMultiplier?: number | null;
        passiveAllowance?: number | null;
        kmAllowance?: number | null;
        bonus2PayRate?: number | null;
        bonus3PayRate?: number | null;
        bonus4PayRate?: number | null;
        bonus5PayRate?: number | null;
        bonus6PayRate?: number | null;
        bonus7PayRate?: number | null;
        bonus8PayRate?: number | null;
        bonus9PayRate?: number | null;
        bonus10PayRate?: number | null;
        bonus11PayRate?: number | null;
        bonus12PayRate?: number | null;
        billing: {
          __typename?: 'OrgBilling';
          day?: number | null;
          afternoon?: number | null;
          night?: number | null;
          saturday?: number | null;
          sunday?: number | null;
          publicHoliday?: number | null;
          overnight?: number | null;
          kms?: number | null;
        };
      };
    } | null;
  };
};

export type ReportWorkedWithGetProfilesQueryVariables = Exact<{
  clientsInput: QueryClientsInput;
  membersInput: QueryMembersInput;
}>;

export type ReportWorkedWithGetProfilesQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    color?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
  members: Array<{
    __typename?: 'Member';
    id: string;
    color?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type ReportWorkedWithGetResultClientsQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type ReportWorkedWithGetResultClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    color?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type ReportWorkedWithGetResultMembersQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type ReportWorkedWithGetResultMembersQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    color?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type TrackFilesGetClientFilesQueryVariables = Exact<{
  input: QueryClientFilesInput;
}>;

export type TrackFilesGetClientFilesQuery = {
  __typename?: 'Query';
  clientFiles: Array<{
    __typename?: 'ClientFile';
    id: string;
    type: ClientFileType;
    dateOfFile?: any | null;
    issuedAt?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Client'; id: string };
    file: { __typename?: 'File'; id: string; name: string };
  }>;
};

export type TrackFilesGetClientsQueryVariables = Exact<{
  input: QueryClientsInput;
}>;

export type TrackFilesGetClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'Client';
    id: string;
    trackFiles: boolean;
    firstName: string;
    lastName?: string | null;
  }>;
};

export type TrackFilesGetMemberFilesQueryVariables = Exact<{
  input: QueryMemberFilesInput;
}>;

export type TrackFilesGetMemberFilesQuery = {
  __typename?: 'Query';
  memberFiles: Array<{
    __typename?: 'MemberFile';
    id: string;
    type: MemberFileType;
    dateOfFile?: any | null;
    issuedAt?: any | null;
    expiresAt?: any | null;
    value?: string | null;
    owner: { __typename?: 'Member'; id: string };
    file: { __typename?: 'File'; id: string; name: string };
  }>;
};

export type TrackFilesGetMembersQueryVariables = Exact<{
  input: QueryMembersInput;
}>;

export type TrackFilesGetMembersQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    trackFiles: boolean;
    schedulable: boolean;
    allowedFortnightlyWorkHours?: number | null;
    australianCitizen?: boolean | null;
    workRights?: MemberWorkRights | null;
    workRightsLastCheckedAt?: any | null;
    externalId?: string | null;
    street?: string | null;
    locality?: string | null;
    region?: AddressRegionCode | null;
    postcode?: any | null;
    country?: AddressCountryCode | null;
    hasCar?: boolean | null;
    firstName: string;
    lastName?: string | null;
    taxFileNumberDeclaration?: {
      __typename?: 'MemberTaxFileNumberDeclaration';
      tfn?: string | null;
    } | null;
    bank?: { __typename?: 'MemberBank'; accountNumber?: string | null } | null;
    super?: { __typename?: 'MemberSuper'; fundName?: string | null } | null;
  }>;
};

export type TrackFilesGetOrgSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TrackFilesGetOrgSettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        memberDocumentTrackerCollapsedFiles?: Array<MemberFileType> | null;
        memberDocumentTrackerVisibleFiles?: Array<MemberFileType> | null;
        clientDocumentTrackerCollapsedFiles?: Array<ClientFileType> | null;
        clientDocumentTrackerVisibleFiles?: Array<ClientFileType> | null;
        fileStorageStatistics: {
          __typename?: 'FileStorageStatistics';
          fileCount: number;
          allocatedSpace: number;
          usedSpace: number;
        };
      };
    } | null;
  };
};

export type ScheduleControlMembersInputQueryVariables = Exact<{
  input: QueryMembersInput;
  scheduleInput: QueryMemberScheduleInput;
  eventsInput: QueryEventsInput;
  teamsInput: QueryClientTeamsInput;
  unavailablesInput: QueryMemberUnavailablesInput;
}>;

export type ScheduleControlMembersInputQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    hasAccess?: boolean | null;
    color?: any | null;
    phone?: string | null;
    gender?: Gender | null;
    dob?: any | null;
    hasCar?: boolean | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    schedule: { __typename?: 'MemberSchedule'; totalMinutes: number };
    events: Array<{
      __typename?: 'Event';
      id: string;
      startAt: any;
      duration: number;
      client: {
        __typename?: 'Client';
        id: string;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
      };
    }>;
    teams?: Array<{
      __typename?: 'ClientTeam';
      id: string;
      preferred: boolean;
      client: { __typename?: 'Client'; id: string };
    }> | null;
    unavailables?: Array<{
      __typename?: 'MemberUnavailable';
      id: string;
      startAt: any;
      endAt: any;
      notes?: string | null;
      owner: { __typename?: 'Member'; id: string };
    }> | null;
  }>;
};

export type ScheduleMemberHoursInputQueryVariables = Exact<{
  membersInput: QueryScheuleMembersInput;
  scheduleInput: QueryMemberScheduleInput;
}>;

export type ScheduleMemberHoursInputQuery = {
  __typename?: 'Query';
  schedule: {
    __typename?: 'Schedule';
    members?: Array<{
      __typename?: 'ScheduleMember';
      weekTime: number;
      fortnightTime: number;
      member: {
        __typename?: 'Member';
        id: string;
        color?: any | null;
        middleName?: string | null;
        preferredName?: string | null;
        firstName: string;
        lastName?: string | null;
        schedule: { __typename?: 'MemberSchedule'; allowedMinutes: number };
      };
      currentEvent?: {
        __typename?: 'Event';
        id: string;
        startAt: any;
        endAt: any;
        clockedOnAt?: any | null;
      } | null;
      nextEvent?: {
        __typename?: 'Event';
        id: string;
        startAt: any;
        endAt: any;
      } | null;
    }> | null;
  };
};

export type ScheduleMemberInfoQueryVariables = Exact<{
  membersInput: QueryMembersInput;
  scheduleInput: QueryMemberSchedulesInput;
}>;

export type ScheduleMemberInfoQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    color?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    currentEvent?: {
      __typename?: 'Event';
      id: string;
      startAt: any;
      endAt: any;
      clockedOnAt?: any | null;
    } | null;
    nextEvent?: {
      __typename?: 'Event';
      id: string;
      startAt: any;
      endAt: any;
    } | null;
    schedules: Array<{
      __typename?: 'MemberSchedule';
      totalMinutes: number;
      allowedMinutes: number;
    }>;
  }>;
};

export type ScheduleMemberInfo2QueryVariables = Exact<{
  membersInput: QueryMembersInput;
  hoursInput: QueryMemberHoursInput;
}>;

export type ScheduleMemberInfo2Query = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    color?: any | null;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    currentEvent?: {
      __typename?: 'Event';
      id: string;
      startAt: any;
      endAt: any;
      clockedOnAt?: any | null;
    } | null;
    nextEvent?: {
      __typename?: 'Event';
      id: string;
      startAt: any;
      endAt: any;
    } | null;
    hours: Array<{
      __typename?: 'MemberHour';
      id: string;
      date: any;
      type: MemberHoursType;
      value?: any | null;
    }>;
  }>;
};

export type GetScheduleOrgSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetScheduleOrgSettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        taskAssignAttendeeFutureDays?: number | null;
        taskAssignAttendeePastWeeks?: number | null;
      };
    } | null;
  };
};

export type ScheduleUnallocatedEventsQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type ScheduleUnallocatedEventsQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type GetApproveShiftsOrgSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetApproveShiftsOrgSettingsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      org: {
        __typename?: 'Org';
        id: string;
        clockOnWarnMinutes?: number | null;
        clockOffWarnMinutes?: number | null;
      };
    } | null;
  };
};

export type GetClaimableShiftsQueryVariables = Exact<{
  input?: InputMaybe<QueryEventsOwnAndRelatedInput>;
}>;

export type GetClaimableShiftsQuery = {
  __typename?: 'Query';
  eventsClaimable: Array<{
    __typename?: 'EventOwnAndRelated';
    id: string;
    startAt: any;
    endAt: any;
    duration: number;
    passive: boolean;
    locality?: string | null;
    region?: AddressRegionCode | null;
    client: {
      __typename?: 'RedactedClient';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'RedactedMember';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type GetMemberSchedulesQueryVariables = Exact<{
  membersInput: QueryMembersInput;
  schedulesInput: QueryMemberSchedulesInput;
}>;

export type GetMemberSchedulesQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    id: string;
    schedules: Array<{
      __typename?: 'MemberSchedule';
      totalMinutes: number;
      allowedMinutes: number;
    }>;
  }>;
};

export type GetMyShiftClaimsQueryVariables = Exact<{
  input: QueryEventClaimsInput;
}>;

export type GetMyShiftClaimsQuery = {
  __typename?: 'Query';
  myEventClaims: Array<{
    __typename?: 'EventClaim';
    id: string;
    event: { __typename?: 'Event'; id: string };
  }>;
};

export type GetMyShiftsQueryVariables = Exact<{
  input: QueryEventsOwnAndRelatedInput;
}>;

export type GetMyShiftsQuery = {
  __typename?: 'Query';
  eventsOwnAndRelated: Array<{
    __typename?: 'EventOwnAndRelated';
    id: string;
    startAt: any;
    endAt: any;
    duration: number;
    passive: boolean;
    locality?: string | null;
    region?: AddressRegionCode | null;
    client: {
      __typename?: 'RedactedClient';
      id: string;
      color?: any | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: { __typename?: 'RedactedMember'; id: string } | null;
  }>;
};

export type GetShiftsMissingAnAttendeeQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type GetShiftsMissingAnAttendeeQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    duration: number;
    locality?: string | null;
    region?: AddressRegionCode | null;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
  }>;
};

export type ShiftsGetEventsShouldNotHaveTravelQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type ShiftsGetEventsShouldNotHaveTravelQuery = {
  __typename?: 'Query';
  events: Array<{ __typename?: 'Event'; id: string }>;
};

export type ShiftsGetEventsWithTravelQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type ShiftsGetEventsWithTravelQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    locality?: string | null;
    region?: AddressRegionCode | null;
    travelTime?: number | null;
    travelTimeNotes?: string | null;
    client: {
      __typename?: 'Client';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    };
    member?: {
      __typename?: 'Member';
      id: string;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
    } | null;
  }>;
};

export type StartGetAdvancedDataQueryVariables = Exact<{
  eventsInput: QueryEventsInput;
  clientsInput: QueryClientsInput;
  membersInput: QueryMembersInput;
}>;

export type StartGetAdvancedDataQuery = {
  __typename?: 'Query';
  events: Array<{
    __typename?: 'Event';
    id: string;
    startAt: any;
    endAt: any;
    duration: number;
    client: {
      __typename?: 'Client';
      id: string;
      firstName: string;
      lastName?: string | null;
    };
    member?: { __typename?: 'Member'; id: string } | null;
  }>;
  clientsAggregate: {
    __typename?: 'ClientsAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{
      __typename?: 'Client';
      id: string;
      firstName: string;
      lastName?: string | null;
    }>;
  };
  membersAggregate: {
    __typename?: 'MembersAggregate';
    aggregate: { __typename?: 'AggregateData'; totalCount: number };
    nodes: Array<{
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    }>;
  };
};

export type StartGetDataQueryVariables = Exact<{
  input: QueryEventsInput;
}>;

export type StartGetDataQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    member?: {
      __typename?: 'Member';
      id: string;
      missingFiles: Array<MemberFileType>;
      missingBankDetails: boolean;
      events: Array<{
        __typename?: 'Event';
        id: string;
        startAt: any;
        endAt: any;
        duration: number;
        clockedOnAt?: any | null;
        clockedOffAt?: any | null;
        passive: boolean;
        passiveStartAt?: any | null;
        activeAssist: boolean;
        client: {
          __typename?: 'Client';
          id: string;
          middleName?: string | null;
          preferredName?: string | null;
          firstName: string;
          lastName?: string | null;
        };
        member?: { __typename?: 'Member'; id: string } | null;
      }>;
    } | null;
  };
};

export type GetSupportCoordinatorByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSupportCoordinatorByIdQuery = {
  __typename?: 'Query';
  supportCoordinatorById: {
    __typename?: 'SupportCoordinator';
    id: string;
    createdAt?: any | null;
    firstName: string;
    middleName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    email?: string | null;
    phone?: string | null;
    businessName?: string | null;
    businessEmail?: string | null;
    businessPhone?: string | null;
    abn?: any | null;
    salutation?: Salutation | null;
    notes?: string | null;
    createdBy?: {
      __typename?: 'Member';
      id: string;
      firstName: string;
      lastName?: string | null;
    } | null;
    clients?: Array<{ __typename?: 'Client'; id: string }> | null;
    archive?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
    delete?: {
      __typename?: 'HistoryRestorable';
      id: string;
      date: any;
      reason?: string | null;
      actor?: {
        __typename?: 'Member';
        id: string;
        firstName: string;
        lastName?: string | null;
      } | null;
    } | null;
  };
};

export type GetSupportCoordinatorsQueryVariables = Exact<{
  input: QuerySupportCoordinatorsInput;
}>;

export type GetSupportCoordinatorsQuery = {
  __typename?: 'Query';
  supportCoordinators: Array<{
    __typename?: 'SupportCoordinator';
    id: string;
    abn?: any | null;
    salutation?: Salutation | null;
    firstName: string;
    middleName?: string | null;
    lastName?: string | null;
    preferredName?: string | null;
    email?: string | null;
    phone?: string | null;
    businessName?: string | null;
    businessEmail?: string | null;
    businessPhone?: string | null;
    archive?: { __typename?: 'HistoryRestorable'; id: string } | null;
    delete?: { __typename?: 'HistoryRestorable'; id: string } | null;
  }>;
};

export type GetMeQueryVariables = Exact<{
  branchesInput?: InputMaybe<QueryBranchesInput>;
  membersInput?: InputMaybe<QueryMembersInput>;
}>;

export type GetMeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    middleName?: string | null;
    preferredName?: string | null;
    firstName: string;
    lastName?: string | null;
    member?: {
      __typename?: 'Member';
      id: string;
      schedulable: boolean;
      permissions?: Array<Permission> | null;
      middleName?: string | null;
      preferredName?: string | null;
      firstName: string;
      lastName?: string | null;
      defaultBranch?: { __typename?: 'Branch'; id: string } | null;
      branches: Array<{ __typename?: 'Branch'; id: string; name: string }>;
      org: {
        __typename?: 'Org';
        id: string;
        membersCanSeeClockedTimes?: boolean | null;
        schedulingContactPerson?: {
          __typename?: 'Member';
          id: string;
          phone?: string | null;
          middleName?: string | null;
          preferredName?: string | null;
          firstName: string;
          lastName?: string | null;
        } | null;
        schedulingContactPerson2?: {
          __typename?: 'Member';
          id: string;
          phone?: string | null;
          middleName?: string | null;
          preferredName?: string | null;
          firstName: string;
          lastName?: string | null;
        } | null;
      };
    } | null;
    members: Array<{
      __typename?: 'Member';
      id: string;
      admin: boolean;
      superAdmin: boolean;
      moduleAccessCS?: boolean | null;
      moduleAccessSC?: boolean | null;
      org: {
        __typename?: 'Org';
        id: string;
        legalName: string;
        preferredName?: string | null;
      };
    }>;
  };
};

export type GetVisaTypesQueryVariables = Exact<{
  input: QueryVisaTypesInput;
}>;

export type GetVisaTypesQuery = {
  __typename?: 'Query';
  visaTypes: Array<{
    __typename?: 'VisaType';
    id: string;
    name: string;
    description?: string | null;
    allowedFortnightlyWorkHours?: number | null;
    allowedWorkHoursWaived: boolean;
  }>;
};

export const AddressFullFragmentDoc = gql`
  fragment AddressFull on Address {
    unit
    buildingName
    street
    locality
    region
    postcode
    country
  }
`;
export const AddressGeolocationFragmentDoc = gql`
  fragment AddressGeolocation on Address {
    latitude
    longitude
  }
`;
export const BusinessNamesFragmentDoc = gql`
  fragment BusinessNames on Business {
    legalName
    preferredName
  }
`;
export const PersonGenderIdentifiersFragmentDoc = gql`
  fragment PersonGenderIdentifiers on Person {
    gender
    salutation
    pronouns
  }
`;
export const PersonNamesPartialFragmentDoc = gql`
  fragment PersonNamesPartial on Person {
    firstName
    lastName
  }
`;
export const PersonNamesFragmentDoc = gql`
  fragment PersonNames on Person {
    middleName
    preferredName
    ...PersonNamesPartial
  }
  ${PersonNamesPartialFragmentDoc}
`;
export const CreateBranchesDocument = gql`
  mutation CreateBranches($input: CreateBranchesInput!) {
    createBranches(input: $input) {
      id
      name
    }
  }
`;
export type CreateBranchesMutationFn = Apollo.MutationFunction<
  CreateBranchesMutation,
  CreateBranchesMutationVariables
>;

/**
 * __useCreateBranchesMutation__
 *
 * To run a mutation, you first call `useCreateBranchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchesMutation, { data, loading, error }] = useCreateBranchesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBranchesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBranchesMutation,
    CreateBranchesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBranchesMutation,
    CreateBranchesMutationVariables
  >(CreateBranchesDocument, options);
}
export type CreateBranchesMutationHookResult = ReturnType<
  typeof useCreateBranchesMutation
>;
export type CreateBranchesMutationResult =
  Apollo.MutationResult<CreateBranchesMutation>;
export type CreateBranchesMutationOptions = Apollo.BaseMutationOptions<
  CreateBranchesMutation,
  CreateBranchesMutationVariables
>;
export const UpdateBranchesDocument = gql`
  mutation UpdateBranches($input: UpdateBranchesInput!) {
    updateBranches(input: $input) {
      id
      name
    }
  }
`;
export type UpdateBranchesMutationFn = Apollo.MutationFunction<
  UpdateBranchesMutation,
  UpdateBranchesMutationVariables
>;

/**
 * __useUpdateBranchesMutation__
 *
 * To run a mutation, you first call `useUpdateBranchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBranchesMutation, { data, loading, error }] = useUpdateBranchesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBranchesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBranchesMutation,
    UpdateBranchesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBranchesMutation,
    UpdateBranchesMutationVariables
  >(UpdateBranchesDocument, options);
}
export type UpdateBranchesMutationHookResult = ReturnType<
  typeof useUpdateBranchesMutation
>;
export type UpdateBranchesMutationResult =
  Apollo.MutationResult<UpdateBranchesMutation>;
export type UpdateBranchesMutationOptions = Apollo.BaseMutationOptions<
  UpdateBranchesMutation,
  UpdateBranchesMutationVariables
>;
export const CreateClientContactDocument = gql`
  mutation CreateClientContact($input: CreateClientContactInput!) {
    createClientContact(input: $input) {
      id
      relations
      customRelation
      name
      email
      phone
      emergencyContact
      client {
        id
        preferredContact {
          id
        }
      }
    }
  }
`;
export type CreateClientContactMutationFn = Apollo.MutationFunction<
  CreateClientContactMutation,
  CreateClientContactMutationVariables
>;

/**
 * __useCreateClientContactMutation__
 *
 * To run a mutation, you first call `useCreateClientContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientContactMutation, { data, loading, error }] = useCreateClientContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientContactMutation,
    CreateClientContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientContactMutation,
    CreateClientContactMutationVariables
  >(CreateClientContactDocument, options);
}
export type CreateClientContactMutationHookResult = ReturnType<
  typeof useCreateClientContactMutation
>;
export type CreateClientContactMutationResult =
  Apollo.MutationResult<CreateClientContactMutation>;
export type CreateClientContactMutationOptions = Apollo.BaseMutationOptions<
  CreateClientContactMutation,
  CreateClientContactMutationVariables
>;
export const DeleteClientContactsDocument = gql`
  mutation DeleteClientContacts($input: DeleteInput!) {
    deleteClientContacts(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientContactsMutationFn = Apollo.MutationFunction<
  DeleteClientContactsMutation,
  DeleteClientContactsMutationVariables
>;

/**
 * __useDeleteClientContactsMutation__
 *
 * To run a mutation, you first call `useDeleteClientContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientContactsMutation, { data, loading, error }] = useDeleteClientContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientContactsMutation,
    DeleteClientContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientContactsMutation,
    DeleteClientContactsMutationVariables
  >(DeleteClientContactsDocument, options);
}
export type DeleteClientContactsMutationHookResult = ReturnType<
  typeof useDeleteClientContactsMutation
>;
export type DeleteClientContactsMutationResult =
  Apollo.MutationResult<DeleteClientContactsMutation>;
export type DeleteClientContactsMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientContactsMutation,
  DeleteClientContactsMutationVariables
>;
export const RestoreClientContactsDocument = gql`
  mutation RestoreClientContacts($input: QueryByIdsInput!) {
    restoreClientContacts(input: $input) {
      id
      delete {
        id
      }
    }
  }
`;
export type RestoreClientContactsMutationFn = Apollo.MutationFunction<
  RestoreClientContactsMutation,
  RestoreClientContactsMutationVariables
>;

/**
 * __useRestoreClientContactsMutation__
 *
 * To run a mutation, you first call `useRestoreClientContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientContactsMutation, { data, loading, error }] = useRestoreClientContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientContactsMutation,
    RestoreClientContactsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientContactsMutation,
    RestoreClientContactsMutationVariables
  >(RestoreClientContactsDocument, options);
}
export type RestoreClientContactsMutationHookResult = ReturnType<
  typeof useRestoreClientContactsMutation
>;
export type RestoreClientContactsMutationResult =
  Apollo.MutationResult<RestoreClientContactsMutation>;
export type RestoreClientContactsMutationOptions = Apollo.BaseMutationOptions<
  RestoreClientContactsMutation,
  RestoreClientContactsMutationVariables
>;
export const UpdateClientContactDocument = gql`
  mutation UpdateClientContact($input: UpdateClientContactInput!) {
    updateClientContact(input: $input) {
      id
      relations
      customRelation
      name
      email
      phone
      emergencyContact
      client {
        id
        preferredContact {
          id
        }
      }
    }
  }
`;
export type UpdateClientContactMutationFn = Apollo.MutationFunction<
  UpdateClientContactMutation,
  UpdateClientContactMutationVariables
>;

/**
 * __useUpdateClientContactMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactMutation, { data, loading, error }] = useUpdateClientContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactMutation,
    UpdateClientContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientContactMutation,
    UpdateClientContactMutationVariables
  >(UpdateClientContactDocument, options);
}
export type UpdateClientContactMutationHookResult = ReturnType<
  typeof useUpdateClientContactMutation
>;
export type UpdateClientContactMutationResult =
  Apollo.MutationResult<UpdateClientContactMutation>;
export type UpdateClientContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactMutation,
  UpdateClientContactMutationVariables
>;
export const CreateClientFileDocument = gql`
  mutation CreateClientFile($input: CreateClientFileInput!) {
    createClientFile(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      value
      owner {
        id
      }
      file {
        id
        name
        size
        path
        createdBy {
          id
        }
      }
    }
  }
`;
export type CreateClientFileMutationFn = Apollo.MutationFunction<
  CreateClientFileMutation,
  CreateClientFileMutationVariables
>;

/**
 * __useCreateClientFileMutation__
 *
 * To run a mutation, you first call `useCreateClientFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientFileMutation, { data, loading, error }] = useCreateClientFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientFileMutation,
    CreateClientFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientFileMutation,
    CreateClientFileMutationVariables
  >(CreateClientFileDocument, options);
}
export type CreateClientFileMutationHookResult = ReturnType<
  typeof useCreateClientFileMutation
>;
export type CreateClientFileMutationResult =
  Apollo.MutationResult<CreateClientFileMutation>;
export type CreateClientFileMutationOptions = Apollo.BaseMutationOptions<
  CreateClientFileMutation,
  CreateClientFileMutationVariables
>;
export const DeleteClientFilesDocument = gql`
  mutation DeleteClientFiles($input: DeleteInput!) {
    deleteClientFiles(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientFilesMutationFn = Apollo.MutationFunction<
  DeleteClientFilesMutation,
  DeleteClientFilesMutationVariables
>;

/**
 * __useDeleteClientFilesMutation__
 *
 * To run a mutation, you first call `useDeleteClientFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientFilesMutation, { data, loading, error }] = useDeleteClientFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientFilesMutation,
    DeleteClientFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientFilesMutation,
    DeleteClientFilesMutationVariables
  >(DeleteClientFilesDocument, options);
}
export type DeleteClientFilesMutationHookResult = ReturnType<
  typeof useDeleteClientFilesMutation
>;
export type DeleteClientFilesMutationResult =
  Apollo.MutationResult<DeleteClientFilesMutation>;
export type DeleteClientFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientFilesMutation,
  DeleteClientFilesMutationVariables
>;
export const RestoreClientFilesDocument = gql`
  mutation RestoreClientFiles($input: QueryByIdsInput!) {
    restoreClientFiles(input: $input) {
      id
      delete {
        id
      }
      archive {
        id
      }
    }
  }
`;
export type RestoreClientFilesMutationFn = Apollo.MutationFunction<
  RestoreClientFilesMutation,
  RestoreClientFilesMutationVariables
>;

/**
 * __useRestoreClientFilesMutation__
 *
 * To run a mutation, you first call `useRestoreClientFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientFilesMutation, { data, loading, error }] = useRestoreClientFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientFilesMutation,
    RestoreClientFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientFilesMutation,
    RestoreClientFilesMutationVariables
  >(RestoreClientFilesDocument, options);
}
export type RestoreClientFilesMutationHookResult = ReturnType<
  typeof useRestoreClientFilesMutation
>;
export type RestoreClientFilesMutationResult =
  Apollo.MutationResult<RestoreClientFilesMutation>;
export type RestoreClientFilesMutationOptions = Apollo.BaseMutationOptions<
  RestoreClientFilesMutation,
  RestoreClientFilesMutationVariables
>;
export const UpdateClientFilesDocument = gql`
  mutation UpdateClientFiles($input: UpdateClientFilesInput!) {
    updateClientFiles(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      value
      owner {
        id
      }
      file {
        id
        name
      }
    }
  }
`;
export type UpdateClientFilesMutationFn = Apollo.MutationFunction<
  UpdateClientFilesMutation,
  UpdateClientFilesMutationVariables
>;

/**
 * __useUpdateClientFilesMutation__
 *
 * To run a mutation, you first call `useUpdateClientFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientFilesMutation, { data, loading, error }] = useUpdateClientFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientFilesMutation,
    UpdateClientFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientFilesMutation,
    UpdateClientFilesMutationVariables
  >(UpdateClientFilesDocument, options);
}
export type UpdateClientFilesMutationHookResult = ReturnType<
  typeof useUpdateClientFilesMutation
>;
export type UpdateClientFilesMutationResult =
  Apollo.MutationResult<UpdateClientFilesMutation>;
export type UpdateClientFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientFilesMutation,
  UpdateClientFilesMutationVariables
>;
export const SetClientMedicationsDocument = gql`
  mutation SetClientMedications($input: SetClientMedicationsInput!) {
    setClientMedications(input: $input) {
      id
      client {
        id
      }
      medication {
        id
      }
    }
  }
`;
export type SetClientMedicationsMutationFn = Apollo.MutationFunction<
  SetClientMedicationsMutation,
  SetClientMedicationsMutationVariables
>;

/**
 * __useSetClientMedicationsMutation__
 *
 * To run a mutation, you first call `useSetClientMedicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientMedicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientMedicationsMutation, { data, loading, error }] = useSetClientMedicationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetClientMedicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetClientMedicationsMutation,
    SetClientMedicationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetClientMedicationsMutation,
    SetClientMedicationsMutationVariables
  >(SetClientMedicationsDocument, options);
}
export type SetClientMedicationsMutationHookResult = ReturnType<
  typeof useSetClientMedicationsMutation
>;
export type SetClientMedicationsMutationResult =
  Apollo.MutationResult<SetClientMedicationsMutation>;
export type SetClientMedicationsMutationOptions = Apollo.BaseMutationOptions<
  SetClientMedicationsMutation,
  SetClientMedicationsMutationVariables
>;
export const ArchiveClientNdisPlanItemTransactionSuppliersDocument = gql`
  mutation ArchiveClientNdisPlanItemTransactionSuppliers(
    $input: ArchiveInput!
  ) {
    archiveClientNdisPlanItemTransactionSuppliers(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveClientNdisPlanItemTransactionSuppliersMutationFn =
  Apollo.MutationFunction<
    ArchiveClientNdisPlanItemTransactionSuppliersMutation,
    ArchiveClientNdisPlanItemTransactionSuppliersMutationVariables
  >;

/**
 * __useArchiveClientNdisPlanItemTransactionSuppliersMutation__
 *
 * To run a mutation, you first call `useArchiveClientNdisPlanItemTransactionSuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveClientNdisPlanItemTransactionSuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveClientNdisPlanItemTransactionSuppliersMutation, { data, loading, error }] = useArchiveClientNdisPlanItemTransactionSuppliersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveClientNdisPlanItemTransactionSuppliersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveClientNdisPlanItemTransactionSuppliersMutation,
    ArchiveClientNdisPlanItemTransactionSuppliersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveClientNdisPlanItemTransactionSuppliersMutation,
    ArchiveClientNdisPlanItemTransactionSuppliersMutationVariables
  >(ArchiveClientNdisPlanItemTransactionSuppliersDocument, options);
}
export type ArchiveClientNdisPlanItemTransactionSuppliersMutationHookResult =
  ReturnType<typeof useArchiveClientNdisPlanItemTransactionSuppliersMutation>;
export type ArchiveClientNdisPlanItemTransactionSuppliersMutationResult =
  Apollo.MutationResult<ArchiveClientNdisPlanItemTransactionSuppliersMutation>;
export type ArchiveClientNdisPlanItemTransactionSuppliersMutationOptions =
  Apollo.BaseMutationOptions<
    ArchiveClientNdisPlanItemTransactionSuppliersMutation,
    ArchiveClientNdisPlanItemTransactionSuppliersMutationVariables
  >;
export const CreateClientNdisPlanItemTransactionSupplierDocument = gql`
  mutation CreateClientNdisPlanItemTransactionSupplier(
    $input: CreateClientNdisPlanItemTransactionSupplierInput!
  ) {
    createClientNdisPlanItemTransactionSupplier(input: $input) {
      id
      name
    }
  }
`;
export type CreateClientNdisPlanItemTransactionSupplierMutationFn =
  Apollo.MutationFunction<
    CreateClientNdisPlanItemTransactionSupplierMutation,
    CreateClientNdisPlanItemTransactionSupplierMutationVariables
  >;

/**
 * __useCreateClientNdisPlanItemTransactionSupplierMutation__
 *
 * To run a mutation, you first call `useCreateClientNdisPlanItemTransactionSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientNdisPlanItemTransactionSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientNdisPlanItemTransactionSupplierMutation, { data, loading, error }] = useCreateClientNdisPlanItemTransactionSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientNdisPlanItemTransactionSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientNdisPlanItemTransactionSupplierMutation,
    CreateClientNdisPlanItemTransactionSupplierMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientNdisPlanItemTransactionSupplierMutation,
    CreateClientNdisPlanItemTransactionSupplierMutationVariables
  >(CreateClientNdisPlanItemTransactionSupplierDocument, options);
}
export type CreateClientNdisPlanItemTransactionSupplierMutationHookResult =
  ReturnType<typeof useCreateClientNdisPlanItemTransactionSupplierMutation>;
export type CreateClientNdisPlanItemTransactionSupplierMutationResult =
  Apollo.MutationResult<CreateClientNdisPlanItemTransactionSupplierMutation>;
export type CreateClientNdisPlanItemTransactionSupplierMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClientNdisPlanItemTransactionSupplierMutation,
    CreateClientNdisPlanItemTransactionSupplierMutationVariables
  >;
export const RestoreClientNdisPlanItemTransactionSuppliersDocument = gql`
  mutation RestoreClientNdisPlanItemTransactionSuppliers(
    $input: QueryByIdsInput!
  ) {
    restoreClientNdisPlanItemTransactionSuppliers(input: $input) {
      id
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreClientNdisPlanItemTransactionSuppliersMutationFn =
  Apollo.MutationFunction<
    RestoreClientNdisPlanItemTransactionSuppliersMutation,
    RestoreClientNdisPlanItemTransactionSuppliersMutationVariables
  >;

/**
 * __useRestoreClientNdisPlanItemTransactionSuppliersMutation__
 *
 * To run a mutation, you first call `useRestoreClientNdisPlanItemTransactionSuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientNdisPlanItemTransactionSuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientNdisPlanItemTransactionSuppliersMutation, { data, loading, error }] = useRestoreClientNdisPlanItemTransactionSuppliersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientNdisPlanItemTransactionSuppliersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientNdisPlanItemTransactionSuppliersMutation,
    RestoreClientNdisPlanItemTransactionSuppliersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientNdisPlanItemTransactionSuppliersMutation,
    RestoreClientNdisPlanItemTransactionSuppliersMutationVariables
  >(RestoreClientNdisPlanItemTransactionSuppliersDocument, options);
}
export type RestoreClientNdisPlanItemTransactionSuppliersMutationHookResult =
  ReturnType<typeof useRestoreClientNdisPlanItemTransactionSuppliersMutation>;
export type RestoreClientNdisPlanItemTransactionSuppliersMutationResult =
  Apollo.MutationResult<RestoreClientNdisPlanItemTransactionSuppliersMutation>;
export type RestoreClientNdisPlanItemTransactionSuppliersMutationOptions =
  Apollo.BaseMutationOptions<
    RestoreClientNdisPlanItemTransactionSuppliersMutation,
    RestoreClientNdisPlanItemTransactionSuppliersMutationVariables
  >;
export const UpdateClientNdisPlanItemTransactionSupplierDocument = gql`
  mutation UpdateClientNdisPlanItemTransactionSupplier(
    $input: UpdateClientNdisPlanItemTransactionSupplierInput!
  ) {
    updateClientNdisPlanItemTransactionSupplier(input: $input) {
      id
      name
    }
  }
`;
export type UpdateClientNdisPlanItemTransactionSupplierMutationFn =
  Apollo.MutationFunction<
    UpdateClientNdisPlanItemTransactionSupplierMutation,
    UpdateClientNdisPlanItemTransactionSupplierMutationVariables
  >;

/**
 * __useUpdateClientNdisPlanItemTransactionSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateClientNdisPlanItemTransactionSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientNdisPlanItemTransactionSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientNdisPlanItemTransactionSupplierMutation, { data, loading, error }] = useUpdateClientNdisPlanItemTransactionSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientNdisPlanItemTransactionSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientNdisPlanItemTransactionSupplierMutation,
    UpdateClientNdisPlanItemTransactionSupplierMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientNdisPlanItemTransactionSupplierMutation,
    UpdateClientNdisPlanItemTransactionSupplierMutationVariables
  >(UpdateClientNdisPlanItemTransactionSupplierDocument, options);
}
export type UpdateClientNdisPlanItemTransactionSupplierMutationHookResult =
  ReturnType<typeof useUpdateClientNdisPlanItemTransactionSupplierMutation>;
export type UpdateClientNdisPlanItemTransactionSupplierMutationResult =
  Apollo.MutationResult<UpdateClientNdisPlanItemTransactionSupplierMutation>;
export type UpdateClientNdisPlanItemTransactionSupplierMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClientNdisPlanItemTransactionSupplierMutation,
    UpdateClientNdisPlanItemTransactionSupplierMutationVariables
  >;
export const ArchiveClientNdisPlanItemTransactionsDocument = gql`
  mutation ArchiveClientNdisPlanItemTransactions($input: ArchiveInput!) {
    archiveClientNdisPlanItemTransactions(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveClientNdisPlanItemTransactionsMutationFn =
  Apollo.MutationFunction<
    ArchiveClientNdisPlanItemTransactionsMutation,
    ArchiveClientNdisPlanItemTransactionsMutationVariables
  >;

/**
 * __useArchiveClientNdisPlanItemTransactionsMutation__
 *
 * To run a mutation, you first call `useArchiveClientNdisPlanItemTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveClientNdisPlanItemTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveClientNdisPlanItemTransactionsMutation, { data, loading, error }] = useArchiveClientNdisPlanItemTransactionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveClientNdisPlanItemTransactionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveClientNdisPlanItemTransactionsMutation,
    ArchiveClientNdisPlanItemTransactionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveClientNdisPlanItemTransactionsMutation,
    ArchiveClientNdisPlanItemTransactionsMutationVariables
  >(ArchiveClientNdisPlanItemTransactionsDocument, options);
}
export type ArchiveClientNdisPlanItemTransactionsMutationHookResult =
  ReturnType<typeof useArchiveClientNdisPlanItemTransactionsMutation>;
export type ArchiveClientNdisPlanItemTransactionsMutationResult =
  Apollo.MutationResult<ArchiveClientNdisPlanItemTransactionsMutation>;
export type ArchiveClientNdisPlanItemTransactionsMutationOptions =
  Apollo.BaseMutationOptions<
    ArchiveClientNdisPlanItemTransactionsMutation,
    ArchiveClientNdisPlanItemTransactionsMutationVariables
  >;
export const CreateClientNdisPlanItemTransactionDocument = gql`
  mutation CreateClientNdisPlanItemTransaction(
    $input: CreateClientNdisPlanItemTransactionInput!
  ) {
    createClientNdisPlanItemTransaction(input: $input) {
      id
      billingDate
      serviceDateStart
      serviceDateEnd
      invoiceNumber
      value
      memo
      clientNdisPlanItem {
        id
      }
    }
  }
`;
export type CreateClientNdisPlanItemTransactionMutationFn =
  Apollo.MutationFunction<
    CreateClientNdisPlanItemTransactionMutation,
    CreateClientNdisPlanItemTransactionMutationVariables
  >;

/**
 * __useCreateClientNdisPlanItemTransactionMutation__
 *
 * To run a mutation, you first call `useCreateClientNdisPlanItemTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientNdisPlanItemTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientNdisPlanItemTransactionMutation, { data, loading, error }] = useCreateClientNdisPlanItemTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientNdisPlanItemTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientNdisPlanItemTransactionMutation,
    CreateClientNdisPlanItemTransactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientNdisPlanItemTransactionMutation,
    CreateClientNdisPlanItemTransactionMutationVariables
  >(CreateClientNdisPlanItemTransactionDocument, options);
}
export type CreateClientNdisPlanItemTransactionMutationHookResult = ReturnType<
  typeof useCreateClientNdisPlanItemTransactionMutation
>;
export type CreateClientNdisPlanItemTransactionMutationResult =
  Apollo.MutationResult<CreateClientNdisPlanItemTransactionMutation>;
export type CreateClientNdisPlanItemTransactionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClientNdisPlanItemTransactionMutation,
    CreateClientNdisPlanItemTransactionMutationVariables
  >;
export const CreateClientNdisPlanItemTransactionsDocument = gql`
  mutation CreateClientNdisPlanItemTransactions(
    $input: CreateClientNdisPlanItemTransactionsInput!
  ) {
    createClientNdisPlanItemTransactions(input: $input) {
      id
      billingDate
      serviceDateStart
      serviceDateEnd
      invoiceNumber
      value
      memo
      clientNdisPlanItem {
        id
      }
    }
  }
`;
export type CreateClientNdisPlanItemTransactionsMutationFn =
  Apollo.MutationFunction<
    CreateClientNdisPlanItemTransactionsMutation,
    CreateClientNdisPlanItemTransactionsMutationVariables
  >;

/**
 * __useCreateClientNdisPlanItemTransactionsMutation__
 *
 * To run a mutation, you first call `useCreateClientNdisPlanItemTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientNdisPlanItemTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientNdisPlanItemTransactionsMutation, { data, loading, error }] = useCreateClientNdisPlanItemTransactionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientNdisPlanItemTransactionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientNdisPlanItemTransactionsMutation,
    CreateClientNdisPlanItemTransactionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientNdisPlanItemTransactionsMutation,
    CreateClientNdisPlanItemTransactionsMutationVariables
  >(CreateClientNdisPlanItemTransactionsDocument, options);
}
export type CreateClientNdisPlanItemTransactionsMutationHookResult = ReturnType<
  typeof useCreateClientNdisPlanItemTransactionsMutation
>;
export type CreateClientNdisPlanItemTransactionsMutationResult =
  Apollo.MutationResult<CreateClientNdisPlanItemTransactionsMutation>;
export type CreateClientNdisPlanItemTransactionsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClientNdisPlanItemTransactionsMutation,
    CreateClientNdisPlanItemTransactionsMutationVariables
  >;
export const DeleteClientNdisPlanItemTransactionsDocument = gql`
  mutation DeleteClientNdisPlanItemTransactions($input: DeleteInput!) {
    deleteClientNdisPlanItemTransactions(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientNdisPlanItemTransactionsMutationFn =
  Apollo.MutationFunction<
    DeleteClientNdisPlanItemTransactionsMutation,
    DeleteClientNdisPlanItemTransactionsMutationVariables
  >;

/**
 * __useDeleteClientNdisPlanItemTransactionsMutation__
 *
 * To run a mutation, you first call `useDeleteClientNdisPlanItemTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientNdisPlanItemTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientNdisPlanItemTransactionsMutation, { data, loading, error }] = useDeleteClientNdisPlanItemTransactionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientNdisPlanItemTransactionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientNdisPlanItemTransactionsMutation,
    DeleteClientNdisPlanItemTransactionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientNdisPlanItemTransactionsMutation,
    DeleteClientNdisPlanItemTransactionsMutationVariables
  >(DeleteClientNdisPlanItemTransactionsDocument, options);
}
export type DeleteClientNdisPlanItemTransactionsMutationHookResult = ReturnType<
  typeof useDeleteClientNdisPlanItemTransactionsMutation
>;
export type DeleteClientNdisPlanItemTransactionsMutationResult =
  Apollo.MutationResult<DeleteClientNdisPlanItemTransactionsMutation>;
export type DeleteClientNdisPlanItemTransactionsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteClientNdisPlanItemTransactionsMutation,
    DeleteClientNdisPlanItemTransactionsMutationVariables
  >;
export const RestoreClientNdisPlanItemTransactionsDocument = gql`
  mutation RestoreClientNdisPlanItemTransactions($input: RestoreInput!) {
    restoreClientNdisPlanItemTransactions(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreClientNdisPlanItemTransactionsMutationFn =
  Apollo.MutationFunction<
    RestoreClientNdisPlanItemTransactionsMutation,
    RestoreClientNdisPlanItemTransactionsMutationVariables
  >;

/**
 * __useRestoreClientNdisPlanItemTransactionsMutation__
 *
 * To run a mutation, you first call `useRestoreClientNdisPlanItemTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientNdisPlanItemTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientNdisPlanItemTransactionsMutation, { data, loading, error }] = useRestoreClientNdisPlanItemTransactionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientNdisPlanItemTransactionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientNdisPlanItemTransactionsMutation,
    RestoreClientNdisPlanItemTransactionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientNdisPlanItemTransactionsMutation,
    RestoreClientNdisPlanItemTransactionsMutationVariables
  >(RestoreClientNdisPlanItemTransactionsDocument, options);
}
export type RestoreClientNdisPlanItemTransactionsMutationHookResult =
  ReturnType<typeof useRestoreClientNdisPlanItemTransactionsMutation>;
export type RestoreClientNdisPlanItemTransactionsMutationResult =
  Apollo.MutationResult<RestoreClientNdisPlanItemTransactionsMutation>;
export type RestoreClientNdisPlanItemTransactionsMutationOptions =
  Apollo.BaseMutationOptions<
    RestoreClientNdisPlanItemTransactionsMutation,
    RestoreClientNdisPlanItemTransactionsMutationVariables
  >;
export const UpdateClientNdisPlanItemTransactionDocument = gql`
  mutation UpdateClientNdisPlanItemTransaction(
    $input: UpdateClientNdisPlanItemTransactionInput!
  ) {
    updateClientNdisPlanItemTransaction(input: $input) {
      id
      billingDate
      serviceDateStart
      serviceDateEnd
      invoiceNumber
      value
      memo
      clientNdisPlanItem {
        id
      }
    }
  }
`;
export type UpdateClientNdisPlanItemTransactionMutationFn =
  Apollo.MutationFunction<
    UpdateClientNdisPlanItemTransactionMutation,
    UpdateClientNdisPlanItemTransactionMutationVariables
  >;

/**
 * __useUpdateClientNdisPlanItemTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateClientNdisPlanItemTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientNdisPlanItemTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientNdisPlanItemTransactionMutation, { data, loading, error }] = useUpdateClientNdisPlanItemTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientNdisPlanItemTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientNdisPlanItemTransactionMutation,
    UpdateClientNdisPlanItemTransactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientNdisPlanItemTransactionMutation,
    UpdateClientNdisPlanItemTransactionMutationVariables
  >(UpdateClientNdisPlanItemTransactionDocument, options);
}
export type UpdateClientNdisPlanItemTransactionMutationHookResult = ReturnType<
  typeof useUpdateClientNdisPlanItemTransactionMutation
>;
export type UpdateClientNdisPlanItemTransactionMutationResult =
  Apollo.MutationResult<UpdateClientNdisPlanItemTransactionMutation>;
export type UpdateClientNdisPlanItemTransactionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClientNdisPlanItemTransactionMutation,
    UpdateClientNdisPlanItemTransactionMutationVariables
  >;
export const CreateClientNdisPlanItemDocument = gql`
  mutation CreateClientNdisPlanItem($input: CreateClientNdisPlanItemInput!) {
    createClientNdisPlanItem(input: $input) {
      id
      category
      name {
        id
        name
      }
      initialFunds
      currentFunds
      clientNdisPlan {
        id
        coreSupportItem {
          id
        }
        supportCoordinationItem {
          id
        }
      }
    }
  }
`;
export type CreateClientNdisPlanItemMutationFn = Apollo.MutationFunction<
  CreateClientNdisPlanItemMutation,
  CreateClientNdisPlanItemMutationVariables
>;

/**
 * __useCreateClientNdisPlanItemMutation__
 *
 * To run a mutation, you first call `useCreateClientNdisPlanItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientNdisPlanItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientNdisPlanItemMutation, { data, loading, error }] = useCreateClientNdisPlanItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientNdisPlanItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientNdisPlanItemMutation,
    CreateClientNdisPlanItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientNdisPlanItemMutation,
    CreateClientNdisPlanItemMutationVariables
  >(CreateClientNdisPlanItemDocument, options);
}
export type CreateClientNdisPlanItemMutationHookResult = ReturnType<
  typeof useCreateClientNdisPlanItemMutation
>;
export type CreateClientNdisPlanItemMutationResult =
  Apollo.MutationResult<CreateClientNdisPlanItemMutation>;
export type CreateClientNdisPlanItemMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClientNdisPlanItemMutation,
    CreateClientNdisPlanItemMutationVariables
  >;
export const DeleteClientNdisPlanItemsDocument = gql`
  mutation DeleteClientNdisPlanItems($input: DeleteInput!) {
    deleteClientNdisPlanItems(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientNdisPlanItemsMutationFn = Apollo.MutationFunction<
  DeleteClientNdisPlanItemsMutation,
  DeleteClientNdisPlanItemsMutationVariables
>;

/**
 * __useDeleteClientNdisPlanItemsMutation__
 *
 * To run a mutation, you first call `useDeleteClientNdisPlanItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientNdisPlanItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientNdisPlanItemsMutation, { data, loading, error }] = useDeleteClientNdisPlanItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientNdisPlanItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientNdisPlanItemsMutation,
    DeleteClientNdisPlanItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientNdisPlanItemsMutation,
    DeleteClientNdisPlanItemsMutationVariables
  >(DeleteClientNdisPlanItemsDocument, options);
}
export type DeleteClientNdisPlanItemsMutationHookResult = ReturnType<
  typeof useDeleteClientNdisPlanItemsMutation
>;
export type DeleteClientNdisPlanItemsMutationResult =
  Apollo.MutationResult<DeleteClientNdisPlanItemsMutation>;
export type DeleteClientNdisPlanItemsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteClientNdisPlanItemsMutation,
    DeleteClientNdisPlanItemsMutationVariables
  >;
export const RestoreClientNdisPlanItemsDocument = gql`
  mutation RestoreClientNdisPlanItems($input: QueryByIdsInput!) {
    restoreClientNdisPlanItems(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreClientNdisPlanItemsMutationFn = Apollo.MutationFunction<
  RestoreClientNdisPlanItemsMutation,
  RestoreClientNdisPlanItemsMutationVariables
>;

/**
 * __useRestoreClientNdisPlanItemsMutation__
 *
 * To run a mutation, you first call `useRestoreClientNdisPlanItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientNdisPlanItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientNdisPlanItemsMutation, { data, loading, error }] = useRestoreClientNdisPlanItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientNdisPlanItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientNdisPlanItemsMutation,
    RestoreClientNdisPlanItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientNdisPlanItemsMutation,
    RestoreClientNdisPlanItemsMutationVariables
  >(RestoreClientNdisPlanItemsDocument, options);
}
export type RestoreClientNdisPlanItemsMutationHookResult = ReturnType<
  typeof useRestoreClientNdisPlanItemsMutation
>;
export type RestoreClientNdisPlanItemsMutationResult =
  Apollo.MutationResult<RestoreClientNdisPlanItemsMutation>;
export type RestoreClientNdisPlanItemsMutationOptions =
  Apollo.BaseMutationOptions<
    RestoreClientNdisPlanItemsMutation,
    RestoreClientNdisPlanItemsMutationVariables
  >;
export const UpdateClientNdisPlanItemDocument = gql`
  mutation UpdateClientNdisPlanItem($input: UpdateClientNdisPlanItemInput!) {
    updateClientNdisPlanItem(input: $input) {
      id
      initialFunds
      currentFunds
      clientNdisPlan {
        id
        initialFunds
        coreSupportItem {
          id
        }
        supportCoordinationItem {
          id
        }
      }
    }
  }
`;
export type UpdateClientNdisPlanItemMutationFn = Apollo.MutationFunction<
  UpdateClientNdisPlanItemMutation,
  UpdateClientNdisPlanItemMutationVariables
>;

/**
 * __useUpdateClientNdisPlanItemMutation__
 *
 * To run a mutation, you first call `useUpdateClientNdisPlanItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientNdisPlanItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientNdisPlanItemMutation, { data, loading, error }] = useUpdateClientNdisPlanItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientNdisPlanItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientNdisPlanItemMutation,
    UpdateClientNdisPlanItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientNdisPlanItemMutation,
    UpdateClientNdisPlanItemMutationVariables
  >(UpdateClientNdisPlanItemDocument, options);
}
export type UpdateClientNdisPlanItemMutationHookResult = ReturnType<
  typeof useUpdateClientNdisPlanItemMutation
>;
export type UpdateClientNdisPlanItemMutationResult =
  Apollo.MutationResult<UpdateClientNdisPlanItemMutation>;
export type UpdateClientNdisPlanItemMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClientNdisPlanItemMutation,
    UpdateClientNdisPlanItemMutationVariables
  >;
export const CreateClientNdisPlanDocument = gql`
  mutation CreateClientNdisPlan($input: CreateClientNdisPlanInput!) {
    createClientNdisPlan(input: $input) {
      id
      startAt
      endAt
      coreSupportItem {
        id
      }
      supportCoordinationItem {
        id
      }
    }
  }
`;
export type CreateClientNdisPlanMutationFn = Apollo.MutationFunction<
  CreateClientNdisPlanMutation,
  CreateClientNdisPlanMutationVariables
>;

/**
 * __useCreateClientNdisPlanMutation__
 *
 * To run a mutation, you first call `useCreateClientNdisPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientNdisPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientNdisPlanMutation, { data, loading, error }] = useCreateClientNdisPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientNdisPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientNdisPlanMutation,
    CreateClientNdisPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientNdisPlanMutation,
    CreateClientNdisPlanMutationVariables
  >(CreateClientNdisPlanDocument, options);
}
export type CreateClientNdisPlanMutationHookResult = ReturnType<
  typeof useCreateClientNdisPlanMutation
>;
export type CreateClientNdisPlanMutationResult =
  Apollo.MutationResult<CreateClientNdisPlanMutation>;
export type CreateClientNdisPlanMutationOptions = Apollo.BaseMutationOptions<
  CreateClientNdisPlanMutation,
  CreateClientNdisPlanMutationVariables
>;
export const DeleteClientNdisPlansDocument = gql`
  mutation DeleteClientNdisPlans($input: DeleteInput!) {
    deleteClientNdisPlans(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientNdisPlansMutationFn = Apollo.MutationFunction<
  DeleteClientNdisPlansMutation,
  DeleteClientNdisPlansMutationVariables
>;

/**
 * __useDeleteClientNdisPlansMutation__
 *
 * To run a mutation, you first call `useDeleteClientNdisPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientNdisPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientNdisPlansMutation, { data, loading, error }] = useDeleteClientNdisPlansMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientNdisPlansMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientNdisPlansMutation,
    DeleteClientNdisPlansMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientNdisPlansMutation,
    DeleteClientNdisPlansMutationVariables
  >(DeleteClientNdisPlansDocument, options);
}
export type DeleteClientNdisPlansMutationHookResult = ReturnType<
  typeof useDeleteClientNdisPlansMutation
>;
export type DeleteClientNdisPlansMutationResult =
  Apollo.MutationResult<DeleteClientNdisPlansMutation>;
export type DeleteClientNdisPlansMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientNdisPlansMutation,
  DeleteClientNdisPlansMutationVariables
>;
export const RestoreClientNdisPlansDocument = gql`
  mutation RestoreClientNdisPlans($input: QueryByIdsInput!) {
    restoreClientNdisPlans(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreClientNdisPlansMutationFn = Apollo.MutationFunction<
  RestoreClientNdisPlansMutation,
  RestoreClientNdisPlansMutationVariables
>;

/**
 * __useRestoreClientNdisPlansMutation__
 *
 * To run a mutation, you first call `useRestoreClientNdisPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientNdisPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientNdisPlansMutation, { data, loading, error }] = useRestoreClientNdisPlansMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientNdisPlansMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientNdisPlansMutation,
    RestoreClientNdisPlansMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientNdisPlansMutation,
    RestoreClientNdisPlansMutationVariables
  >(RestoreClientNdisPlansDocument, options);
}
export type RestoreClientNdisPlansMutationHookResult = ReturnType<
  typeof useRestoreClientNdisPlansMutation
>;
export type RestoreClientNdisPlansMutationResult =
  Apollo.MutationResult<RestoreClientNdisPlansMutation>;
export type RestoreClientNdisPlansMutationOptions = Apollo.BaseMutationOptions<
  RestoreClientNdisPlansMutation,
  RestoreClientNdisPlansMutationVariables
>;
export const UpdateClientNdisPlanDocument = gql`
  mutation UpdateClientNdisPlan($input: UpdateClientNdisPlanInput!) {
    updateClientNdisPlan(input: $input) {
      id
      startAt
      endAt
      coreSupportItem {
        id
      }
      supportCoordinationItem {
        id
      }
    }
  }
`;
export type UpdateClientNdisPlanMutationFn = Apollo.MutationFunction<
  UpdateClientNdisPlanMutation,
  UpdateClientNdisPlanMutationVariables
>;

/**
 * __useUpdateClientNdisPlanMutation__
 *
 * To run a mutation, you first call `useUpdateClientNdisPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientNdisPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientNdisPlanMutation, { data, loading, error }] = useUpdateClientNdisPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientNdisPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientNdisPlanMutation,
    UpdateClientNdisPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientNdisPlanMutation,
    UpdateClientNdisPlanMutationVariables
  >(UpdateClientNdisPlanDocument, options);
}
export type UpdateClientNdisPlanMutationHookResult = ReturnType<
  typeof useUpdateClientNdisPlanMutation
>;
export type UpdateClientNdisPlanMutationResult =
  Apollo.MutationResult<UpdateClientNdisPlanMutation>;
export type UpdateClientNdisPlanMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientNdisPlanMutation,
  UpdateClientNdisPlanMutationVariables
>;
export const CreateClientNoteFileDocument = gql`
  mutation CreateClientNoteFile($input: CreateClientNoteFileInput!) {
    createClientNoteFile(input: $input) {
      id
      type
      dateOfFile
      value
      owner {
        id
      }
      file {
        id
        size
        path
        createdBy {
          id
        }
      }
    }
  }
`;
export type CreateClientNoteFileMutationFn = Apollo.MutationFunction<
  CreateClientNoteFileMutation,
  CreateClientNoteFileMutationVariables
>;

/**
 * __useCreateClientNoteFileMutation__
 *
 * To run a mutation, you first call `useCreateClientNoteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientNoteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientNoteFileMutation, { data, loading, error }] = useCreateClientNoteFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientNoteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientNoteFileMutation,
    CreateClientNoteFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientNoteFileMutation,
    CreateClientNoteFileMutationVariables
  >(CreateClientNoteFileDocument, options);
}
export type CreateClientNoteFileMutationHookResult = ReturnType<
  typeof useCreateClientNoteFileMutation
>;
export type CreateClientNoteFileMutationResult =
  Apollo.MutationResult<CreateClientNoteFileMutation>;
export type CreateClientNoteFileMutationOptions = Apollo.BaseMutationOptions<
  CreateClientNoteFileMutation,
  CreateClientNoteFileMutationVariables
>;
export const DeleteClientNoteFilesDocument = gql`
  mutation DeleteClientNoteFiles($input: DeleteInput!) {
    deleteClientNoteFiles(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientNoteFilesMutationFn = Apollo.MutationFunction<
  DeleteClientNoteFilesMutation,
  DeleteClientNoteFilesMutationVariables
>;

/**
 * __useDeleteClientNoteFilesMutation__
 *
 * To run a mutation, you first call `useDeleteClientNoteFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientNoteFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientNoteFilesMutation, { data, loading, error }] = useDeleteClientNoteFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientNoteFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientNoteFilesMutation,
    DeleteClientNoteFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientNoteFilesMutation,
    DeleteClientNoteFilesMutationVariables
  >(DeleteClientNoteFilesDocument, options);
}
export type DeleteClientNoteFilesMutationHookResult = ReturnType<
  typeof useDeleteClientNoteFilesMutation
>;
export type DeleteClientNoteFilesMutationResult =
  Apollo.MutationResult<DeleteClientNoteFilesMutation>;
export type DeleteClientNoteFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientNoteFilesMutation,
  DeleteClientNoteFilesMutationVariables
>;
export const RestoreClientNoteFilesDocument = gql`
  mutation RestoreClientNoteFiles($input: QueryByIdsInput!) {
    restoreClientNoteFiles(input: $input) {
      id
      delete {
        id
      }
      archive {
        id
      }
    }
  }
`;
export type RestoreClientNoteFilesMutationFn = Apollo.MutationFunction<
  RestoreClientNoteFilesMutation,
  RestoreClientNoteFilesMutationVariables
>;

/**
 * __useRestoreClientNoteFilesMutation__
 *
 * To run a mutation, you first call `useRestoreClientNoteFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientNoteFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientNoteFilesMutation, { data, loading, error }] = useRestoreClientNoteFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientNoteFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientNoteFilesMutation,
    RestoreClientNoteFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientNoteFilesMutation,
    RestoreClientNoteFilesMutationVariables
  >(RestoreClientNoteFilesDocument, options);
}
export type RestoreClientNoteFilesMutationHookResult = ReturnType<
  typeof useRestoreClientNoteFilesMutation
>;
export type RestoreClientNoteFilesMutationResult =
  Apollo.MutationResult<RestoreClientNoteFilesMutation>;
export type RestoreClientNoteFilesMutationOptions = Apollo.BaseMutationOptions<
  RestoreClientNoteFilesMutation,
  RestoreClientNoteFilesMutationVariables
>;
export const UpdateClientNoteFilesDocument = gql`
  mutation UpdateClientNoteFiles($input: UpdateClientNoteFilesInput!) {
    updateClientNoteFiles(input: $input) {
      id
      type
      dateOfFile
      value
      owner {
        id
      }
    }
  }
`;
export type UpdateClientNoteFilesMutationFn = Apollo.MutationFunction<
  UpdateClientNoteFilesMutation,
  UpdateClientNoteFilesMutationVariables
>;

/**
 * __useUpdateClientNoteFilesMutation__
 *
 * To run a mutation, you first call `useUpdateClientNoteFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientNoteFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientNoteFilesMutation, { data, loading, error }] = useUpdateClientNoteFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientNoteFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientNoteFilesMutation,
    UpdateClientNoteFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientNoteFilesMutation,
    UpdateClientNoteFilesMutationVariables
  >(UpdateClientNoteFilesDocument, options);
}
export type UpdateClientNoteFilesMutationHookResult = ReturnType<
  typeof useUpdateClientNoteFilesMutation
>;
export type UpdateClientNoteFilesMutationResult =
  Apollo.MutationResult<UpdateClientNoteFilesMutation>;
export type UpdateClientNoteFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientNoteFilesMutation,
  UpdateClientNoteFilesMutationVariables
>;
export const CreateClientNoteKeywordDocument = gql`
  mutation CreateClientNoteKeyword($input: CreateClientNoteKeywordInput!) {
    createClientNoteKeyword(input: $input) {
      id
      createdAt
      createdBy {
        id
      }
      text
    }
  }
`;
export type CreateClientNoteKeywordMutationFn = Apollo.MutationFunction<
  CreateClientNoteKeywordMutation,
  CreateClientNoteKeywordMutationVariables
>;

/**
 * __useCreateClientNoteKeywordMutation__
 *
 * To run a mutation, you first call `useCreateClientNoteKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientNoteKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientNoteKeywordMutation, { data, loading, error }] = useCreateClientNoteKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientNoteKeywordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientNoteKeywordMutation,
    CreateClientNoteKeywordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientNoteKeywordMutation,
    CreateClientNoteKeywordMutationVariables
  >(CreateClientNoteKeywordDocument, options);
}
export type CreateClientNoteKeywordMutationHookResult = ReturnType<
  typeof useCreateClientNoteKeywordMutation
>;
export type CreateClientNoteKeywordMutationResult =
  Apollo.MutationResult<CreateClientNoteKeywordMutation>;
export type CreateClientNoteKeywordMutationOptions = Apollo.BaseMutationOptions<
  CreateClientNoteKeywordMutation,
  CreateClientNoteKeywordMutationVariables
>;
export const DeleteClientNoteKeywordsDocument = gql`
  mutation DeleteClientNoteKeywords($input: DeleteInput!) {
    deleteClientNoteKeywords(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientNoteKeywordsMutationFn = Apollo.MutationFunction<
  DeleteClientNoteKeywordsMutation,
  DeleteClientNoteKeywordsMutationVariables
>;

/**
 * __useDeleteClientNoteKeywordsMutation__
 *
 * To run a mutation, you first call `useDeleteClientNoteKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientNoteKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientNoteKeywordsMutation, { data, loading, error }] = useDeleteClientNoteKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientNoteKeywordsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientNoteKeywordsMutation,
    DeleteClientNoteKeywordsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientNoteKeywordsMutation,
    DeleteClientNoteKeywordsMutationVariables
  >(DeleteClientNoteKeywordsDocument, options);
}
export type DeleteClientNoteKeywordsMutationHookResult = ReturnType<
  typeof useDeleteClientNoteKeywordsMutation
>;
export type DeleteClientNoteKeywordsMutationResult =
  Apollo.MutationResult<DeleteClientNoteKeywordsMutation>;
export type DeleteClientNoteKeywordsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteClientNoteKeywordsMutation,
    DeleteClientNoteKeywordsMutationVariables
  >;
export const RestoreClientNoteKeywordsDocument = gql`
  mutation RestoreClientNoteKeywords($input: QueryByIdsInput!) {
    restoreClientNoteKeywords(input: $input) {
      id
      delete {
        id
      }
    }
  }
`;
export type RestoreClientNoteKeywordsMutationFn = Apollo.MutationFunction<
  RestoreClientNoteKeywordsMutation,
  RestoreClientNoteKeywordsMutationVariables
>;

/**
 * __useRestoreClientNoteKeywordsMutation__
 *
 * To run a mutation, you first call `useRestoreClientNoteKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientNoteKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientNoteKeywordsMutation, { data, loading, error }] = useRestoreClientNoteKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientNoteKeywordsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientNoteKeywordsMutation,
    RestoreClientNoteKeywordsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientNoteKeywordsMutation,
    RestoreClientNoteKeywordsMutationVariables
  >(RestoreClientNoteKeywordsDocument, options);
}
export type RestoreClientNoteKeywordsMutationHookResult = ReturnType<
  typeof useRestoreClientNoteKeywordsMutation
>;
export type RestoreClientNoteKeywordsMutationResult =
  Apollo.MutationResult<RestoreClientNoteKeywordsMutation>;
export type RestoreClientNoteKeywordsMutationOptions =
  Apollo.BaseMutationOptions<
    RestoreClientNoteKeywordsMutation,
    RestoreClientNoteKeywordsMutationVariables
  >;
export const CreateClientNoteDocument = gql`
  mutation CreateClientNote($input: CreateClientNoteInput!) {
    createClientNote(input: $input) {
      id
      createdAt
      createdBy {
        id
      }
      client {
        id
      }
      event {
        id
        startAt
        endAt
        travelDistance
        hasNotes
      }
      type
      comment
      commentedAt
      commentedBy {
        id
      }
      commentedByText
    }
  }
`;
export type CreateClientNoteMutationFn = Apollo.MutationFunction<
  CreateClientNoteMutation,
  CreateClientNoteMutationVariables
>;

/**
 * __useCreateClientNoteMutation__
 *
 * To run a mutation, you first call `useCreateClientNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientNoteMutation, { data, loading, error }] = useCreateClientNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientNoteMutation,
    CreateClientNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientNoteMutation,
    CreateClientNoteMutationVariables
  >(CreateClientNoteDocument, options);
}
export type CreateClientNoteMutationHookResult = ReturnType<
  typeof useCreateClientNoteMutation
>;
export type CreateClientNoteMutationResult =
  Apollo.MutationResult<CreateClientNoteMutation>;
export type CreateClientNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateClientNoteMutation,
  CreateClientNoteMutationVariables
>;
export const CreateClientNotesDocument = gql`
  mutation CreateClientNotes($input: CreateClientNotesInput!) {
    createClientNotes(input: $input) {
      id
      createdAt
      createdBy {
        id
      }
      client {
        id
      }
      event {
        id
        startAt
        endAt
        travelDistance
      }
      type
      comment
      commentedAt
      commentedBy {
        id
      }
      commentedByText
    }
  }
`;
export type CreateClientNotesMutationFn = Apollo.MutationFunction<
  CreateClientNotesMutation,
  CreateClientNotesMutationVariables
>;

/**
 * __useCreateClientNotesMutation__
 *
 * To run a mutation, you first call `useCreateClientNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientNotesMutation, { data, loading, error }] = useCreateClientNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientNotesMutation,
    CreateClientNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientNotesMutation,
    CreateClientNotesMutationVariables
  >(CreateClientNotesDocument, options);
}
export type CreateClientNotesMutationHookResult = ReturnType<
  typeof useCreateClientNotesMutation
>;
export type CreateClientNotesMutationResult =
  Apollo.MutationResult<CreateClientNotesMutation>;
export type CreateClientNotesMutationOptions = Apollo.BaseMutationOptions<
  CreateClientNotesMutation,
  CreateClientNotesMutationVariables
>;
export const DeleteClientNotesDocument = gql`
  mutation DeleteClientNotes($input: DeleteInput!) {
    deleteClientNotes(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientNotesMutationFn = Apollo.MutationFunction<
  DeleteClientNotesMutation,
  DeleteClientNotesMutationVariables
>;

/**
 * __useDeleteClientNotesMutation__
 *
 * To run a mutation, you first call `useDeleteClientNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientNotesMutation, { data, loading, error }] = useDeleteClientNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientNotesMutation,
    DeleteClientNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientNotesMutation,
    DeleteClientNotesMutationVariables
  >(DeleteClientNotesDocument, options);
}
export type DeleteClientNotesMutationHookResult = ReturnType<
  typeof useDeleteClientNotesMutation
>;
export type DeleteClientNotesMutationResult =
  Apollo.MutationResult<DeleteClientNotesMutation>;
export type DeleteClientNotesMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientNotesMutation,
  DeleteClientNotesMutationVariables
>;
export const RestoreClientNotesDocument = gql`
  mutation RestoreClientNotes($input: QueryByIdsInput!) {
    restoreClientNotes(input: $input) {
      id
      delete {
        id
      }
    }
  }
`;
export type RestoreClientNotesMutationFn = Apollo.MutationFunction<
  RestoreClientNotesMutation,
  RestoreClientNotesMutationVariables
>;

/**
 * __useRestoreClientNotesMutation__
 *
 * To run a mutation, you first call `useRestoreClientNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientNotesMutation, { data, loading, error }] = useRestoreClientNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientNotesMutation,
    RestoreClientNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientNotesMutation,
    RestoreClientNotesMutationVariables
  >(RestoreClientNotesDocument, options);
}
export type RestoreClientNotesMutationHookResult = ReturnType<
  typeof useRestoreClientNotesMutation
>;
export type RestoreClientNotesMutationResult =
  Apollo.MutationResult<RestoreClientNotesMutation>;
export type RestoreClientNotesMutationOptions = Apollo.BaseMutationOptions<
  RestoreClientNotesMutation,
  RestoreClientNotesMutationVariables
>;
export const UpdateClientNoteDocument = gql`
  mutation UpdateClientNote($input: UpdateClientNoteInput!) {
    updateClientNote(input: $input) {
      id
      type
      comment
      commentedAt
      commentedBy {
        id
      }
      commentedByText
      updatedAt
      lastUpdatedBy {
        id
      }
    }
  }
`;
export type UpdateClientNoteMutationFn = Apollo.MutationFunction<
  UpdateClientNoteMutation,
  UpdateClientNoteMutationVariables
>;

/**
 * __useUpdateClientNoteMutation__
 *
 * To run a mutation, you first call `useUpdateClientNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientNoteMutation, { data, loading, error }] = useUpdateClientNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientNoteMutation,
    UpdateClientNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientNoteMutation,
    UpdateClientNoteMutationVariables
  >(UpdateClientNoteDocument, options);
}
export type UpdateClientNoteMutationHookResult = ReturnType<
  typeof useUpdateClientNoteMutation
>;
export type UpdateClientNoteMutationResult =
  Apollo.MutationResult<UpdateClientNoteMutation>;
export type UpdateClientNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientNoteMutation,
  UpdateClientNoteMutationVariables
>;
export const CreateClientObservationDocument = gql`
  mutation CreateClientObservation($input: CreateClientObservationInput!) {
    createClientObservation(input: $input) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      weight
      stoolType
      urineColor
      hadSeizure
      seizureDuration
      seizureType
      seizureLostConsciousness
      incontinenceType
      postSeizureWalkDuration
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      client {
        id
      }
      member {
        id
      }
    }
  }
`;
export type CreateClientObservationMutationFn = Apollo.MutationFunction<
  CreateClientObservationMutation,
  CreateClientObservationMutationVariables
>;

/**
 * __useCreateClientObservationMutation__
 *
 * To run a mutation, you first call `useCreateClientObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientObservationMutation, { data, loading, error }] = useCreateClientObservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientObservationMutation,
    CreateClientObservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientObservationMutation,
    CreateClientObservationMutationVariables
  >(CreateClientObservationDocument, options);
}
export type CreateClientObservationMutationHookResult = ReturnType<
  typeof useCreateClientObservationMutation
>;
export type CreateClientObservationMutationResult =
  Apollo.MutationResult<CreateClientObservationMutation>;
export type CreateClientObservationMutationOptions = Apollo.BaseMutationOptions<
  CreateClientObservationMutation,
  CreateClientObservationMutationVariables
>;
export const CreateClientObservationWithEventDocument = gql`
  mutation CreateClientObservationWithEvent(
    $input: CreateClientObservationWithEventInput!
  ) {
    createClientObservationWithEvent(input: $input) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      weight
      stoolType
      urineColor
      hadSeizure
      seizureDuration
      seizureType
      seizureLostConsciousness
      incontinenceType
      postSeizureWalkDuration
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      event {
        id
      }
      member {
        id
      }
    }
  }
`;
export type CreateClientObservationWithEventMutationFn =
  Apollo.MutationFunction<
    CreateClientObservationWithEventMutation,
    CreateClientObservationWithEventMutationVariables
  >;

/**
 * __useCreateClientObservationWithEventMutation__
 *
 * To run a mutation, you first call `useCreateClientObservationWithEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientObservationWithEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientObservationWithEventMutation, { data, loading, error }] = useCreateClientObservationWithEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientObservationWithEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientObservationWithEventMutation,
    CreateClientObservationWithEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientObservationWithEventMutation,
    CreateClientObservationWithEventMutationVariables
  >(CreateClientObservationWithEventDocument, options);
}
export type CreateClientObservationWithEventMutationHookResult = ReturnType<
  typeof useCreateClientObservationWithEventMutation
>;
export type CreateClientObservationWithEventMutationResult =
  Apollo.MutationResult<CreateClientObservationWithEventMutation>;
export type CreateClientObservationWithEventMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClientObservationWithEventMutation,
    CreateClientObservationWithEventMutationVariables
  >;
export const DeleteClientObservationsDocument = gql`
  mutation DeleteClientObservations($input: DeleteInput!) {
    deleteClientObservations(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientObservationsMutationFn = Apollo.MutationFunction<
  DeleteClientObservationsMutation,
  DeleteClientObservationsMutationVariables
>;

/**
 * __useDeleteClientObservationsMutation__
 *
 * To run a mutation, you first call `useDeleteClientObservationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientObservationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientObservationsMutation, { data, loading, error }] = useDeleteClientObservationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientObservationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientObservationsMutation,
    DeleteClientObservationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientObservationsMutation,
    DeleteClientObservationsMutationVariables
  >(DeleteClientObservationsDocument, options);
}
export type DeleteClientObservationsMutationHookResult = ReturnType<
  typeof useDeleteClientObservationsMutation
>;
export type DeleteClientObservationsMutationResult =
  Apollo.MutationResult<DeleteClientObservationsMutation>;
export type DeleteClientObservationsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteClientObservationsMutation,
    DeleteClientObservationsMutationVariables
  >;
export const UpdateClientObservationDocument = gql`
  mutation UpdateClientObservation($input: UpdateClientObservationInput!) {
    updateClientObservation(input: $input) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      weight
      stoolType
      urineColor
      hadSeizure
      seizureDuration
      seizureType
      seizureLostConsciousness
      incontinenceType
      postSeizureWalkDuration
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      member {
        id
      }
    }
  }
`;
export type UpdateClientObservationMutationFn = Apollo.MutationFunction<
  UpdateClientObservationMutation,
  UpdateClientObservationMutationVariables
>;

/**
 * __useUpdateClientObservationMutation__
 *
 * To run a mutation, you first call `useUpdateClientObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientObservationMutation, { data, loading, error }] = useUpdateClientObservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientObservationMutation,
    UpdateClientObservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientObservationMutation,
    UpdateClientObservationMutationVariables
  >(UpdateClientObservationDocument, options);
}
export type UpdateClientObservationMutationHookResult = ReturnType<
  typeof useUpdateClientObservationMutation
>;
export type UpdateClientObservationMutationResult =
  Apollo.MutationResult<UpdateClientObservationMutation>;
export type UpdateClientObservationMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientObservationMutation,
  UpdateClientObservationMutationVariables
>;
export const SetClientTeamMembersDocument = gql`
  mutation SetClientTeamMembers($input: SetClientTeamMembersInput!) {
    setClientTeamMembers(input: $input) {
      id
      preferred
      client {
        id
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;
export type SetClientTeamMembersMutationFn = Apollo.MutationFunction<
  SetClientTeamMembersMutation,
  SetClientTeamMembersMutationVariables
>;

/**
 * __useSetClientTeamMembersMutation__
 *
 * To run a mutation, you first call `useSetClientTeamMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientTeamMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientTeamMembersMutation, { data, loading, error }] = useSetClientTeamMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetClientTeamMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetClientTeamMembersMutation,
    SetClientTeamMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetClientTeamMembersMutation,
    SetClientTeamMembersMutationVariables
  >(SetClientTeamMembersDocument, options);
}
export type SetClientTeamMembersMutationHookResult = ReturnType<
  typeof useSetClientTeamMembersMutation
>;
export type SetClientTeamMembersMutationResult =
  Apollo.MutationResult<SetClientTeamMembersMutation>;
export type SetClientTeamMembersMutationOptions = Apollo.BaseMutationOptions<
  SetClientTeamMembersMutation,
  SetClientTeamMembersMutationVariables
>;
export const ArchiveClientsDocument = gql`
  mutation ArchiveClients($input: ArchiveInput!) {
    archiveClients(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveClientsMutationFn = Apollo.MutationFunction<
  ArchiveClientsMutation,
  ArchiveClientsMutationVariables
>;

/**
 * __useArchiveClientsMutation__
 *
 * To run a mutation, you first call `useArchiveClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveClientsMutation, { data, loading, error }] = useArchiveClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveClientsMutation,
    ArchiveClientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveClientsMutation,
    ArchiveClientsMutationVariables
  >(ArchiveClientsDocument, options);
}
export type ArchiveClientsMutationHookResult = ReturnType<
  typeof useArchiveClientsMutation
>;
export type ArchiveClientsMutationResult =
  Apollo.MutationResult<ArchiveClientsMutation>;
export type ArchiveClientsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveClientsMutation,
  ArchiveClientsMutationVariables
>;
export const CreateClientsDocument = gql`
  mutation CreateClients($input: CreateClientsInput!) {
    createClients(input: $input) {
      id
      moduleCS
      moduleSC
      email
      phone
      dob
      color
      ndisId
      timezone
      ndisPlanStartDate
      ndisPlanEndDate
      ndisPlanInitialFundsCore
      ndisPlanInitialFundsCs
      ndisPlanInitialFundsCb
      defaultEventDuration
      notes
      active
      allowMedication
      allowObservations
      allowRecordSeizures
      allowAutomaticEventPopulation
      bloodSystolicUpperThreshold1
      bloodSystolicLowerThreshold1
      bloodDiastolicUpperThreshold1
      bloodDiastolicLowerThreshold1
      heartUpperThreshold1
      heartLowerThreshold1
      spo2UpperThreshold1
      spo2LowerThreshold1
      respirationUpperThreshold1
      respirationLowerThreshold1
      bloodSystolicUpperThreshold2
      bloodSystolicLowerThreshold2
      bloodDiastolicUpperThreshold2
      bloodDiastolicLowerThreshold2
      heartUpperThreshold2
      heartLowerThreshold2
      spo2UpperThreshold2
      spo2LowerThreshold2
      respirationUpperThreshold2
      respirationLowerThreshold2
      requireHandover
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      planManager {
        id
        name
      }
      supportCoordinator {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type CreateClientsMutationFn = Apollo.MutationFunction<
  CreateClientsMutation,
  CreateClientsMutationVariables
>;

/**
 * __useCreateClientsMutation__
 *
 * To run a mutation, you first call `useCreateClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientsMutation, { data, loading, error }] = useCreateClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientsMutation,
    CreateClientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientsMutation,
    CreateClientsMutationVariables
  >(CreateClientsDocument, options);
}
export type CreateClientsMutationHookResult = ReturnType<
  typeof useCreateClientsMutation
>;
export type CreateClientsMutationResult =
  Apollo.MutationResult<CreateClientsMutation>;
export type CreateClientsMutationOptions = Apollo.BaseMutationOptions<
  CreateClientsMutation,
  CreateClientsMutationVariables
>;
export const DeleteClientsDocument = gql`
  mutation DeleteClients($input: DeleteInput!) {
    deleteClients(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteClientsMutationFn = Apollo.MutationFunction<
  DeleteClientsMutation,
  DeleteClientsMutationVariables
>;

/**
 * __useDeleteClientsMutation__
 *
 * To run a mutation, you first call `useDeleteClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientsMutation, { data, loading, error }] = useDeleteClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientsMutation,
    DeleteClientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientsMutation,
    DeleteClientsMutationVariables
  >(DeleteClientsDocument, options);
}
export type DeleteClientsMutationHookResult = ReturnType<
  typeof useDeleteClientsMutation
>;
export type DeleteClientsMutationResult =
  Apollo.MutationResult<DeleteClientsMutation>;
export type DeleteClientsMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientsMutation,
  DeleteClientsMutationVariables
>;
export const RestoreClientsDocument = gql`
  mutation RestoreClients($input: QueryByIdsInput!) {
    restoreClients(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreClientsMutationFn = Apollo.MutationFunction<
  RestoreClientsMutation,
  RestoreClientsMutationVariables
>;

/**
 * __useRestoreClientsMutation__
 *
 * To run a mutation, you first call `useRestoreClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreClientsMutation, { data, loading, error }] = useRestoreClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreClientsMutation,
    RestoreClientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreClientsMutation,
    RestoreClientsMutationVariables
  >(RestoreClientsDocument, options);
}
export type RestoreClientsMutationHookResult = ReturnType<
  typeof useRestoreClientsMutation
>;
export type RestoreClientsMutationResult =
  Apollo.MutationResult<RestoreClientsMutation>;
export type RestoreClientsMutationOptions = Apollo.BaseMutationOptions<
  RestoreClientsMutation,
  RestoreClientsMutationVariables
>;
export const UpdateClientsModulesDocument = gql`
  mutation UpdateClientsModules($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      moduleCS
      moduleSC
    }
  }
`;
export type UpdateClientsModulesMutationFn = Apollo.MutationFunction<
  UpdateClientsModulesMutation,
  UpdateClientsModulesMutationVariables
>;

/**
 * __useUpdateClientsModulesMutation__
 *
 * To run a mutation, you first call `useUpdateClientsModulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsModulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsModulesMutation, { data, loading, error }] = useUpdateClientsModulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsModulesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientsModulesMutation,
    UpdateClientsModulesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientsModulesMutation,
    UpdateClientsModulesMutationVariables
  >(UpdateClientsModulesDocument, options);
}
export type UpdateClientsModulesMutationHookResult = ReturnType<
  typeof useUpdateClientsModulesMutation
>;
export type UpdateClientsModulesMutationResult =
  Apollo.MutationResult<UpdateClientsModulesMutation>;
export type UpdateClientsModulesMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientsModulesMutation,
  UpdateClientsModulesMutationVariables
>;
export const UpdateClientsDocument = gql`
  mutation UpdateClients($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      moduleCS
      moduleSC
      email
      phone
      bio
      dob
      color
      timezone
      ndisId
      externalId
      ndisPlanStartDate
      ndisPlanEndDate
      ndisPlanInitialFundsCore
      ndisPlanInitialFundsCs
      ndisPlanInitialFundsCb
      defaultEventDuration
      notes
      confidentialNotes
      active
      allowMedication
      allowObservations
      allowRecordSeizures
      allowAutomaticEventPopulation
      requireHandover
      contactsName
      contactsEmail
      contactsPhone
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      planManager {
        id
        name
      }
      supportCoordinator {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type UpdateClientsMutationFn = Apollo.MutationFunction<
  UpdateClientsMutation,
  UpdateClientsMutationVariables
>;

/**
 * __useUpdateClientsMutation__
 *
 * To run a mutation, you first call `useUpdateClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsMutation, { data, loading, error }] = useUpdateClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientsMutation,
    UpdateClientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientsMutation,
    UpdateClientsMutationVariables
  >(UpdateClientsDocument, options);
}
export type UpdateClientsMutationHookResult = ReturnType<
  typeof useUpdateClientsMutation
>;
export type UpdateClientsMutationResult =
  Apollo.MutationResult<UpdateClientsMutation>;
export type UpdateClientsMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientsMutation,
  UpdateClientsMutationVariables
>;
export const UpdateClientsColorsDocument = gql`
  mutation UpdateClientsColors($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      color
    }
  }
`;
export type UpdateClientsColorsMutationFn = Apollo.MutationFunction<
  UpdateClientsColorsMutation,
  UpdateClientsColorsMutationVariables
>;

/**
 * __useUpdateClientsColorsMutation__
 *
 * To run a mutation, you first call `useUpdateClientsColorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsColorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsColorsMutation, { data, loading, error }] = useUpdateClientsColorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsColorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientsColorsMutation,
    UpdateClientsColorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientsColorsMutation,
    UpdateClientsColorsMutationVariables
  >(UpdateClientsColorsDocument, options);
}
export type UpdateClientsColorsMutationHookResult = ReturnType<
  typeof useUpdateClientsColorsMutation
>;
export type UpdateClientsColorsMutationResult =
  Apollo.MutationResult<UpdateClientsColorsMutation>;
export type UpdateClientsColorsMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientsColorsMutation,
  UpdateClientsColorsMutationVariables
>;
export const UpdateClientsNotesDocument = gql`
  mutation UpdateClientsNotes($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      notes
    }
  }
`;
export type UpdateClientsNotesMutationFn = Apollo.MutationFunction<
  UpdateClientsNotesMutation,
  UpdateClientsNotesMutationVariables
>;

/**
 * __useUpdateClientsNotesMutation__
 *
 * To run a mutation, you first call `useUpdateClientsNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsNotesMutation, { data, loading, error }] = useUpdateClientsNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientsNotesMutation,
    UpdateClientsNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientsNotesMutation,
    UpdateClientsNotesMutationVariables
  >(UpdateClientsNotesDocument, options);
}
export type UpdateClientsNotesMutationHookResult = ReturnType<
  typeof useUpdateClientsNotesMutation
>;
export type UpdateClientsNotesMutationResult =
  Apollo.MutationResult<UpdateClientsNotesMutation>;
export type UpdateClientsNotesMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientsNotesMutation,
  UpdateClientsNotesMutationVariables
>;
export const UpdateClientsObservationThresholdsDocument = gql`
  mutation UpdateClientsObservationThresholds($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      bloodSystolicUpperThreshold1
      bloodSystolicLowerThreshold1
      bloodDiastolicUpperThreshold1
      bloodDiastolicLowerThreshold1
      heartUpperThreshold1
      heartLowerThreshold1
      spo2UpperThreshold1
      spo2LowerThreshold1
      respirationUpperThreshold1
      respirationLowerThreshold1
      bloodSystolicUpperThreshold2
      bloodSystolicLowerThreshold2
      bloodDiastolicUpperThreshold2
      bloodDiastolicLowerThreshold2
      heartUpperThreshold2
      heartLowerThreshold2
      spo2UpperThreshold2
      spo2LowerThreshold2
      respirationUpperThreshold2
      respirationLowerThreshold2
    }
  }
`;
export type UpdateClientsObservationThresholdsMutationFn =
  Apollo.MutationFunction<
    UpdateClientsObservationThresholdsMutation,
    UpdateClientsObservationThresholdsMutationVariables
  >;

/**
 * __useUpdateClientsObservationThresholdsMutation__
 *
 * To run a mutation, you first call `useUpdateClientsObservationThresholdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsObservationThresholdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsObservationThresholdsMutation, { data, loading, error }] = useUpdateClientsObservationThresholdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsObservationThresholdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientsObservationThresholdsMutation,
    UpdateClientsObservationThresholdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientsObservationThresholdsMutation,
    UpdateClientsObservationThresholdsMutationVariables
  >(UpdateClientsObservationThresholdsDocument, options);
}
export type UpdateClientsObservationThresholdsMutationHookResult = ReturnType<
  typeof useUpdateClientsObservationThresholdsMutation
>;
export type UpdateClientsObservationThresholdsMutationResult =
  Apollo.MutationResult<UpdateClientsObservationThresholdsMutation>;
export type UpdateClientsObservationThresholdsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClientsObservationThresholdsMutation,
    UpdateClientsObservationThresholdsMutationVariables
  >;
export const UpdateClientsPersonalDetailsDocument = gql`
  mutation UpdateClientsPersonalDetails($input: UpdateClientsInput!) {
    updateClients(input: $input) {
      id
      email
      phone
      bio
      dob
      color
      externalId
      contactsName
      contactsEmail
      contactsPhone
      timezone
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type UpdateClientsPersonalDetailsMutationFn = Apollo.MutationFunction<
  UpdateClientsPersonalDetailsMutation,
  UpdateClientsPersonalDetailsMutationVariables
>;

/**
 * __useUpdateClientsPersonalDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateClientsPersonalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientsPersonalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientsPersonalDetailsMutation, { data, loading, error }] = useUpdateClientsPersonalDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientsPersonalDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientsPersonalDetailsMutation,
    UpdateClientsPersonalDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientsPersonalDetailsMutation,
    UpdateClientsPersonalDetailsMutationVariables
  >(UpdateClientsPersonalDetailsDocument, options);
}
export type UpdateClientsPersonalDetailsMutationHookResult = ReturnType<
  typeof useUpdateClientsPersonalDetailsMutation
>;
export type UpdateClientsPersonalDetailsMutationResult =
  Apollo.MutationResult<UpdateClientsPersonalDetailsMutation>;
export type UpdateClientsPersonalDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClientsPersonalDetailsMutation,
    UpdateClientsPersonalDetailsMutationVariables
  >;
export const ClaimEventDocument = gql`
  mutation ClaimEvent($id: ID!) {
    claimEvent(id: $id) {
      id
      event {
        id
      }
    }
  }
`;
export type ClaimEventMutationFn = Apollo.MutationFunction<
  ClaimEventMutation,
  ClaimEventMutationVariables
>;

/**
 * __useClaimEventMutation__
 *
 * To run a mutation, you first call `useClaimEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimEventMutation, { data, loading, error }] = useClaimEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClaimEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimEventMutation,
    ClaimEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimEventMutation, ClaimEventMutationVariables>(
    ClaimEventDocument,
    options,
  );
}
export type ClaimEventMutationHookResult = ReturnType<
  typeof useClaimEventMutation
>;
export type ClaimEventMutationResult =
  Apollo.MutationResult<ClaimEventMutation>;
export type ClaimEventMutationOptions = Apollo.BaseMutationOptions<
  ClaimEventMutation,
  ClaimEventMutationVariables
>;
export const DismissEventClaimDocument = gql`
  mutation DismissEventClaim($id: ID!) {
    dismissEventClaim(id: $id) {
      id
      dismissedAt
      event {
        id
      }
    }
  }
`;
export type DismissEventClaimMutationFn = Apollo.MutationFunction<
  DismissEventClaimMutation,
  DismissEventClaimMutationVariables
>;

/**
 * __useDismissEventClaimMutation__
 *
 * To run a mutation, you first call `useDismissEventClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissEventClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissEventClaimMutation, { data, loading, error }] = useDismissEventClaimMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDismissEventClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissEventClaimMutation,
    DismissEventClaimMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissEventClaimMutation,
    DismissEventClaimMutationVariables
  >(DismissEventClaimDocument, options);
}
export type DismissEventClaimMutationHookResult = ReturnType<
  typeof useDismissEventClaimMutation
>;
export type DismissEventClaimMutationResult =
  Apollo.MutationResult<DismissEventClaimMutation>;
export type DismissEventClaimMutationOptions = Apollo.BaseMutationOptions<
  DismissEventClaimMutation,
  DismissEventClaimMutationVariables
>;
export const CreateEventFileDocument = gql`
  mutation CreateEventFile($input: CreateEventFileInput!) {
    createEventFile(input: $input) {
      id
      type
      dateOfFile
      value
      owner {
        id
      }
      file {
        id
        size
        path
        createdBy {
          id
        }
      }
    }
  }
`;
export type CreateEventFileMutationFn = Apollo.MutationFunction<
  CreateEventFileMutation,
  CreateEventFileMutationVariables
>;

/**
 * __useCreateEventFileMutation__
 *
 * To run a mutation, you first call `useCreateEventFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventFileMutation, { data, loading, error }] = useCreateEventFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventFileMutation,
    CreateEventFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEventFileMutation,
    CreateEventFileMutationVariables
  >(CreateEventFileDocument, options);
}
export type CreateEventFileMutationHookResult = ReturnType<
  typeof useCreateEventFileMutation
>;
export type CreateEventFileMutationResult =
  Apollo.MutationResult<CreateEventFileMutation>;
export type CreateEventFileMutationOptions = Apollo.BaseMutationOptions<
  CreateEventFileMutation,
  CreateEventFileMutationVariables
>;
export const DeleteEventFilesDocument = gql`
  mutation DeleteEventFiles($input: DeleteInput!) {
    deleteEventFiles(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteEventFilesMutationFn = Apollo.MutationFunction<
  DeleteEventFilesMutation,
  DeleteEventFilesMutationVariables
>;

/**
 * __useDeleteEventFilesMutation__
 *
 * To run a mutation, you first call `useDeleteEventFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventFilesMutation, { data, loading, error }] = useDeleteEventFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventFilesMutation,
    DeleteEventFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEventFilesMutation,
    DeleteEventFilesMutationVariables
  >(DeleteEventFilesDocument, options);
}
export type DeleteEventFilesMutationHookResult = ReturnType<
  typeof useDeleteEventFilesMutation
>;
export type DeleteEventFilesMutationResult =
  Apollo.MutationResult<DeleteEventFilesMutation>;
export type DeleteEventFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventFilesMutation,
  DeleteEventFilesMutationVariables
>;
export const RestoreEventFilesDocument = gql`
  mutation RestoreEventFiles($input: QueryByIdsInput!) {
    restoreEventFiles(input: $input) {
      id
      delete {
        id
      }
      archive {
        id
      }
    }
  }
`;
export type RestoreEventFilesMutationFn = Apollo.MutationFunction<
  RestoreEventFilesMutation,
  RestoreEventFilesMutationVariables
>;

/**
 * __useRestoreEventFilesMutation__
 *
 * To run a mutation, you first call `useRestoreEventFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreEventFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreEventFilesMutation, { data, loading, error }] = useRestoreEventFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreEventFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreEventFilesMutation,
    RestoreEventFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreEventFilesMutation,
    RestoreEventFilesMutationVariables
  >(RestoreEventFilesDocument, options);
}
export type RestoreEventFilesMutationHookResult = ReturnType<
  typeof useRestoreEventFilesMutation
>;
export type RestoreEventFilesMutationResult =
  Apollo.MutationResult<RestoreEventFilesMutation>;
export type RestoreEventFilesMutationOptions = Apollo.BaseMutationOptions<
  RestoreEventFilesMutation,
  RestoreEventFilesMutationVariables
>;
export const UpdateEventFilesDocument = gql`
  mutation UpdateEventFiles($input: UpdateEventFilesInput!) {
    updateEventFiles(input: $input) {
      id
      type
      dateOfFile
      value
      owner {
        id
      }
    }
  }
`;
export type UpdateEventFilesMutationFn = Apollo.MutationFunction<
  UpdateEventFilesMutation,
  UpdateEventFilesMutationVariables
>;

/**
 * __useUpdateEventFilesMutation__
 *
 * To run a mutation, you first call `useUpdateEventFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventFilesMutation, { data, loading, error }] = useUpdateEventFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventFilesMutation,
    UpdateEventFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEventFilesMutation,
    UpdateEventFilesMutationVariables
  >(UpdateEventFilesDocument, options);
}
export type UpdateEventFilesMutationHookResult = ReturnType<
  typeof useUpdateEventFilesMutation
>;
export type UpdateEventFilesMutationResult =
  Apollo.MutationResult<UpdateEventFilesMutation>;
export type UpdateEventFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventFilesMutation,
  UpdateEventFilesMutationVariables
>;
export const CloseEventIssueDocument = gql`
  mutation CloseEventIssue($id: ID!) {
    closeEventIssue(id: $id) {
      id
      closedAt
      closedBy {
        id
      }
      event {
        id
        hasIssue
      }
    }
  }
`;
export type CloseEventIssueMutationFn = Apollo.MutationFunction<
  CloseEventIssueMutation,
  CloseEventIssueMutationVariables
>;

/**
 * __useCloseEventIssueMutation__
 *
 * To run a mutation, you first call `useCloseEventIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseEventIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeEventIssueMutation, { data, loading, error }] = useCloseEventIssueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseEventIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseEventIssueMutation,
    CloseEventIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloseEventIssueMutation,
    CloseEventIssueMutationVariables
  >(CloseEventIssueDocument, options);
}
export type CloseEventIssueMutationHookResult = ReturnType<
  typeof useCloseEventIssueMutation
>;
export type CloseEventIssueMutationResult =
  Apollo.MutationResult<CloseEventIssueMutation>;
export type CloseEventIssueMutationOptions = Apollo.BaseMutationOptions<
  CloseEventIssueMutation,
  CloseEventIssueMutationVariables
>;
export const CreateEventIssueDocument = gql`
  mutation CreateEventIssue($input: CreateEventIssueInput!) {
    createEventIssue(input: $input) {
      id
      type
      createdAt
      createdBy {
        id
      }
      event {
        id
        hasIssue
      }
    }
  }
`;
export type CreateEventIssueMutationFn = Apollo.MutationFunction<
  CreateEventIssueMutation,
  CreateEventIssueMutationVariables
>;

/**
 * __useCreateEventIssueMutation__
 *
 * To run a mutation, you first call `useCreateEventIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventIssueMutation, { data, loading, error }] = useCreateEventIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventIssueMutation,
    CreateEventIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEventIssueMutation,
    CreateEventIssueMutationVariables
  >(CreateEventIssueDocument, options);
}
export type CreateEventIssueMutationHookResult = ReturnType<
  typeof useCreateEventIssueMutation
>;
export type CreateEventIssueMutationResult =
  Apollo.MutationResult<CreateEventIssueMutation>;
export type CreateEventIssueMutationOptions = Apollo.BaseMutationOptions<
  CreateEventIssueMutation,
  CreateEventIssueMutationVariables
>;
export const ApproveEventsTravelDocument = gql`
  mutation ApproveEventsTravel($input: QueryByIdsInput!) {
    approveEventsTravel(input: $input) {
      id
      travelApprovedAt
      travelApprovedBy {
        id
      }
    }
  }
`;
export type ApproveEventsTravelMutationFn = Apollo.MutationFunction<
  ApproveEventsTravelMutation,
  ApproveEventsTravelMutationVariables
>;

/**
 * __useApproveEventsTravelMutation__
 *
 * To run a mutation, you first call `useApproveEventsTravelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveEventsTravelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveEventsTravelMutation, { data, loading, error }] = useApproveEventsTravelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveEventsTravelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveEventsTravelMutation,
    ApproveEventsTravelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveEventsTravelMutation,
    ApproveEventsTravelMutationVariables
  >(ApproveEventsTravelDocument, options);
}
export type ApproveEventsTravelMutationHookResult = ReturnType<
  typeof useApproveEventsTravelMutation
>;
export type ApproveEventsTravelMutationResult =
  Apollo.MutationResult<ApproveEventsTravelMutation>;
export type ApproveEventsTravelMutationOptions = Apollo.BaseMutationOptions<
  ApproveEventsTravelMutation,
  ApproveEventsTravelMutationVariables
>;
export const ArchiveEventsDocument = gql`
  mutation ArchiveEvents($input: ArchiveInput!) {
    archiveEvents(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveEventsMutationFn = Apollo.MutationFunction<
  ArchiveEventsMutation,
  ArchiveEventsMutationVariables
>;

/**
 * __useArchiveEventsMutation__
 *
 * To run a mutation, you first call `useArchiveEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveEventsMutation, { data, loading, error }] = useArchiveEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveEventsMutation,
    ArchiveEventsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveEventsMutation,
    ArchiveEventsMutationVariables
  >(ArchiveEventsDocument, options);
}
export type ArchiveEventsMutationHookResult = ReturnType<
  typeof useArchiveEventsMutation
>;
export type ArchiveEventsMutationResult =
  Apollo.MutationResult<ArchiveEventsMutation>;
export type ArchiveEventsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveEventsMutation,
  ArchiveEventsMutationVariables
>;
export const AutomaticallyAssignAttendeeDocument = gql`
  mutation AutomaticallyAssignAttendee($input: QueryByIdInput!) {
    updateEventAutomaticAttendee(input: $input) {
      id
      memberAssignedAutomatically
      scheduleNotes
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;
export type AutomaticallyAssignAttendeeMutationFn = Apollo.MutationFunction<
  AutomaticallyAssignAttendeeMutation,
  AutomaticallyAssignAttendeeMutationVariables
>;

/**
 * __useAutomaticallyAssignAttendeeMutation__
 *
 * To run a mutation, you first call `useAutomaticallyAssignAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutomaticallyAssignAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [automaticallyAssignAttendeeMutation, { data, loading, error }] = useAutomaticallyAssignAttendeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAutomaticallyAssignAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AutomaticallyAssignAttendeeMutation,
    AutomaticallyAssignAttendeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AutomaticallyAssignAttendeeMutation,
    AutomaticallyAssignAttendeeMutationVariables
  >(AutomaticallyAssignAttendeeDocument, options);
}
export type AutomaticallyAssignAttendeeMutationHookResult = ReturnType<
  typeof useAutomaticallyAssignAttendeeMutation
>;
export type AutomaticallyAssignAttendeeMutationResult =
  Apollo.MutationResult<AutomaticallyAssignAttendeeMutation>;
export type AutomaticallyAssignAttendeeMutationOptions =
  Apollo.BaseMutationOptions<
    AutomaticallyAssignAttendeeMutation,
    AutomaticallyAssignAttendeeMutationVariables
  >;
export const CancelEventsDocument = gql`
  mutation CancelEvents($input: EventsCancelInput!) {
    cancelEvents(input: $input) {
      id
      billable
      payable
      cancel {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type CancelEventsMutationFn = Apollo.MutationFunction<
  CancelEventsMutation,
  CancelEventsMutationVariables
>;

/**
 * __useCancelEventsMutation__
 *
 * To run a mutation, you first call `useCancelEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEventsMutation, { data, loading, error }] = useCancelEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelEventsMutation,
    CancelEventsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelEventsMutation,
    CancelEventsMutationVariables
  >(CancelEventsDocument, options);
}
export type CancelEventsMutationHookResult = ReturnType<
  typeof useCancelEventsMutation
>;
export type CancelEventsMutationResult =
  Apollo.MutationResult<CancelEventsMutation>;
export type CancelEventsMutationOptions = Apollo.BaseMutationOptions<
  CancelEventsMutation,
  CancelEventsMutationVariables
>;
export const ClockOffEventDocument = gql`
  mutation ClockOffEvent($input: ClockEventInput!) {
    clockEvent(input: $input) {
      id
      clockedOffAt
      clockedOffLatitude
      clockedOffLongitude
      clockedOffBy {
        id
      }
    }
  }
`;
export type ClockOffEventMutationFn = Apollo.MutationFunction<
  ClockOffEventMutation,
  ClockOffEventMutationVariables
>;

/**
 * __useClockOffEventMutation__
 *
 * To run a mutation, you first call `useClockOffEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockOffEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockOffEventMutation, { data, loading, error }] = useClockOffEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClockOffEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClockOffEventMutation,
    ClockOffEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClockOffEventMutation,
    ClockOffEventMutationVariables
  >(ClockOffEventDocument, options);
}
export type ClockOffEventMutationHookResult = ReturnType<
  typeof useClockOffEventMutation
>;
export type ClockOffEventMutationResult =
  Apollo.MutationResult<ClockOffEventMutation>;
export type ClockOffEventMutationOptions = Apollo.BaseMutationOptions<
  ClockOffEventMutation,
  ClockOffEventMutationVariables
>;
export const ClockOffEventAttendedDocument = gql`
  mutation ClockOffEventAttended($input: ClockEventInput!) {
    clockEventAttended(input: $input) {
      id
      clockedOffAt
    }
  }
`;
export type ClockOffEventAttendedMutationFn = Apollo.MutationFunction<
  ClockOffEventAttendedMutation,
  ClockOffEventAttendedMutationVariables
>;

/**
 * __useClockOffEventAttendedMutation__
 *
 * To run a mutation, you first call `useClockOffEventAttendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockOffEventAttendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockOffEventAttendedMutation, { data, loading, error }] = useClockOffEventAttendedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClockOffEventAttendedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClockOffEventAttendedMutation,
    ClockOffEventAttendedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClockOffEventAttendedMutation,
    ClockOffEventAttendedMutationVariables
  >(ClockOffEventAttendedDocument, options);
}
export type ClockOffEventAttendedMutationHookResult = ReturnType<
  typeof useClockOffEventAttendedMutation
>;
export type ClockOffEventAttendedMutationResult =
  Apollo.MutationResult<ClockOffEventAttendedMutation>;
export type ClockOffEventAttendedMutationOptions = Apollo.BaseMutationOptions<
  ClockOffEventAttendedMutation,
  ClockOffEventAttendedMutationVariables
>;
export const ClockOnEventDocument = gql`
  mutation ClockOnEvent($input: ClockEventInput!) {
    clockEvent(input: $input) {
      id
      clockedOnAt
      clockedOnLatitude
      clockedOnLongitude
      clockedOnBy {
        id
      }
    }
  }
`;
export type ClockOnEventMutationFn = Apollo.MutationFunction<
  ClockOnEventMutation,
  ClockOnEventMutationVariables
>;

/**
 * __useClockOnEventMutation__
 *
 * To run a mutation, you first call `useClockOnEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockOnEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockOnEventMutation, { data, loading, error }] = useClockOnEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClockOnEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClockOnEventMutation,
    ClockOnEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClockOnEventMutation,
    ClockOnEventMutationVariables
  >(ClockOnEventDocument, options);
}
export type ClockOnEventMutationHookResult = ReturnType<
  typeof useClockOnEventMutation
>;
export type ClockOnEventMutationResult =
  Apollo.MutationResult<ClockOnEventMutation>;
export type ClockOnEventMutationOptions = Apollo.BaseMutationOptions<
  ClockOnEventMutation,
  ClockOnEventMutationVariables
>;
export const ClockOnEventAttendedDocument = gql`
  mutation ClockOnEventAttended($input: ClockEventInput!) {
    clockEventAttended(input: $input) {
      id
      clockedOnAt
    }
  }
`;
export type ClockOnEventAttendedMutationFn = Apollo.MutationFunction<
  ClockOnEventAttendedMutation,
  ClockOnEventAttendedMutationVariables
>;

/**
 * __useClockOnEventAttendedMutation__
 *
 * To run a mutation, you first call `useClockOnEventAttendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClockOnEventAttendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clockOnEventAttendedMutation, { data, loading, error }] = useClockOnEventAttendedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClockOnEventAttendedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClockOnEventAttendedMutation,
    ClockOnEventAttendedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClockOnEventAttendedMutation,
    ClockOnEventAttendedMutationVariables
  >(ClockOnEventAttendedDocument, options);
}
export type ClockOnEventAttendedMutationHookResult = ReturnType<
  typeof useClockOnEventAttendedMutation
>;
export type ClockOnEventAttendedMutationResult =
  Apollo.MutationResult<ClockOnEventAttendedMutation>;
export type ClockOnEventAttendedMutationOptions = Apollo.BaseMutationOptions<
  ClockOnEventAttendedMutation,
  ClockOnEventAttendedMutationVariables
>;
export const CreateEventDocument = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      locked
      published
      billable
      billingRegistrationGroup
      payable
      bonusPay
      startAt
      duration
      passive
      passiveStartAt
      activeAssist
      summary
      publicHoliday
      requireCar
      ...AddressFull
      ...AddressGeolocation
      client {
        id
        timezone
      }
      member {
        id
      }
    }
  }
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type CreateEventMutationFn = Apollo.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventMutation,
    CreateEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
    options,
  );
}
export type CreateEventMutationHookResult = ReturnType<
  typeof useCreateEventMutation
>;
export type CreateEventMutationResult =
  Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const DeleteEventsDocument = gql`
  mutation DeleteEvents($input: DeleteInput!) {
    deleteEvents(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteEventsMutationFn = Apollo.MutationFunction<
  DeleteEventsMutation,
  DeleteEventsMutationVariables
>;

/**
 * __useDeleteEventsMutation__
 *
 * To run a mutation, you first call `useDeleteEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventsMutation, { data, loading, error }] = useDeleteEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventsMutation,
    DeleteEventsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEventsMutation,
    DeleteEventsMutationVariables
  >(DeleteEventsDocument, options);
}
export type DeleteEventsMutationHookResult = ReturnType<
  typeof useDeleteEventsMutation
>;
export type DeleteEventsMutationResult =
  Apollo.MutationResult<DeleteEventsMutation>;
export type DeleteEventsMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventsMutation,
  DeleteEventsMutationVariables
>;
export const RestoreEventsDocument = gql`
  mutation RestoreEvents($input: RestoreInput!) {
    restoreEvents(input: $input) {
      id
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      cancel {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreEventsMutationFn = Apollo.MutationFunction<
  RestoreEventsMutation,
  RestoreEventsMutationVariables
>;

/**
 * __useRestoreEventsMutation__
 *
 * To run a mutation, you first call `useRestoreEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreEventsMutation, { data, loading, error }] = useRestoreEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreEventsMutation,
    RestoreEventsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreEventsMutation,
    RestoreEventsMutationVariables
  >(RestoreEventsDocument, options);
}
export type RestoreEventsMutationHookResult = ReturnType<
  typeof useRestoreEventsMutation
>;
export type RestoreEventsMutationResult =
  Apollo.MutationResult<RestoreEventsMutation>;
export type RestoreEventsMutationOptions = Apollo.BaseMutationOptions<
  RestoreEventsMutation,
  RestoreEventsMutationVariables
>;
export const UpdateEventAttendeeDocument = gql`
  mutation UpdateEventAttendee($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      member {
        id
      }
    }
  }
`;
export type UpdateEventAttendeeMutationFn = Apollo.MutationFunction<
  UpdateEventAttendeeMutation,
  UpdateEventAttendeeMutationVariables
>;

/**
 * __useUpdateEventAttendeeMutation__
 *
 * To run a mutation, you first call `useUpdateEventAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventAttendeeMutation, { data, loading, error }] = useUpdateEventAttendeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventAttendeeMutation,
    UpdateEventAttendeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEventAttendeeMutation,
    UpdateEventAttendeeMutationVariables
  >(UpdateEventAttendeeDocument, options);
}
export type UpdateEventAttendeeMutationHookResult = ReturnType<
  typeof useUpdateEventAttendeeMutation
>;
export type UpdateEventAttendeeMutationResult =
  Apollo.MutationResult<UpdateEventAttendeeMutation>;
export type UpdateEventAttendeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventAttendeeMutation,
  UpdateEventAttendeeMutationVariables
>;
export const UpdateEventDocument = gql`
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      hasNotes
      locked
      published
      billable
      billingRegistrationGroup
      payable
      bonusPay
      startAt
      endAt
      clockedOnAt
      clockedOnBy {
        id
      }
      clockedOffAt
      clockedOffBy {
        id
      }
      duration
      passive
      passiveStartAt
      activeAssist
      summary
      scheduleNotes
      travelDistance
      travelDistanceNotes
      travelTime
      travelTimeNotes
      publicHoliday
      memberAssignedAutomatically
      requireCar
      ...AddressFull
      ...AddressGeolocation
      client {
        id
      }
      member {
        id
      }
    }
  }
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventMutation,
    UpdateEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(
    UpdateEventDocument,
    options,
  );
}
export type UpdateEventMutationHookResult = ReturnType<
  typeof useUpdateEventMutation
>;
export type UpdateEventMutationResult =
  Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export const UpdateEventAttendedDocument = gql`
  mutation UpdateEventAttended($input: UpdateEventBasicFieldsInput!) {
    updateEventAttended(input: $input) {
      id
      hasNotes
      travelDistance
      travelDistanceNotes
      travelTime
      travelTimeNotes
    }
  }
`;
export type UpdateEventAttendedMutationFn = Apollo.MutationFunction<
  UpdateEventAttendedMutation,
  UpdateEventAttendedMutationVariables
>;

/**
 * __useUpdateEventAttendedMutation__
 *
 * To run a mutation, you first call `useUpdateEventAttendedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventAttendedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventAttendedMutation, { data, loading, error }] = useUpdateEventAttendedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventAttendedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventAttendedMutation,
    UpdateEventAttendedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEventAttendedMutation,
    UpdateEventAttendedMutationVariables
  >(UpdateEventAttendedDocument, options);
}
export type UpdateEventAttendedMutationHookResult = ReturnType<
  typeof useUpdateEventAttendedMutation
>;
export type UpdateEventAttendedMutationResult =
  Apollo.MutationResult<UpdateEventAttendedMutation>;
export type UpdateEventAttendedMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventAttendedMutation,
  UpdateEventAttendedMutationVariables
>;
export const VerifyEventsDocument = gql`
  mutation VerifyEvents($input: VerifyEventsInput!) {
    verifyEvents(input: $input) {
      id
      verified
      verifiedAt
    }
  }
`;
export type VerifyEventsMutationFn = Apollo.MutationFunction<
  VerifyEventsMutation,
  VerifyEventsMutationVariables
>;

/**
 * __useVerifyEventsMutation__
 *
 * To run a mutation, you first call `useVerifyEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEventsMutation, { data, loading, error }] = useVerifyEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEventsMutation,
    VerifyEventsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyEventsMutation,
    VerifyEventsMutationVariables
  >(VerifyEventsDocument, options);
}
export type VerifyEventsMutationHookResult = ReturnType<
  typeof useVerifyEventsMutation
>;
export type VerifyEventsMutationResult =
  Apollo.MutationResult<VerifyEventsMutation>;
export type VerifyEventsMutationOptions = Apollo.BaseMutationOptions<
  VerifyEventsMutation,
  VerifyEventsMutationVariables
>;
export const CreateMedicationDocument = gql`
  mutation CreateMedication($input: CreateMedicationInput!) {
    createMedication(input: $input) {
      id
      name
    }
  }
`;
export type CreateMedicationMutationFn = Apollo.MutationFunction<
  CreateMedicationMutation,
  CreateMedicationMutationVariables
>;

/**
 * __useCreateMedicationMutation__
 *
 * To run a mutation, you first call `useCreateMedicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedicationMutation, { data, loading, error }] = useCreateMedicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMedicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMedicationMutation,
    CreateMedicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMedicationMutation,
    CreateMedicationMutationVariables
  >(CreateMedicationDocument, options);
}
export type CreateMedicationMutationHookResult = ReturnType<
  typeof useCreateMedicationMutation
>;
export type CreateMedicationMutationResult =
  Apollo.MutationResult<CreateMedicationMutation>;
export type CreateMedicationMutationOptions = Apollo.BaseMutationOptions<
  CreateMedicationMutation,
  CreateMedicationMutationVariables
>;
export const DeleteMedicationsDocument = gql`
  mutation DeleteMedications($input: DeleteInput!) {
    deleteMedications(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteMedicationsMutationFn = Apollo.MutationFunction<
  DeleteMedicationsMutation,
  DeleteMedicationsMutationVariables
>;

/**
 * __useDeleteMedicationsMutation__
 *
 * To run a mutation, you first call `useDeleteMedicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMedicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMedicationsMutation, { data, loading, error }] = useDeleteMedicationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMedicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMedicationsMutation,
    DeleteMedicationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMedicationsMutation,
    DeleteMedicationsMutationVariables
  >(DeleteMedicationsDocument, options);
}
export type DeleteMedicationsMutationHookResult = ReturnType<
  typeof useDeleteMedicationsMutation
>;
export type DeleteMedicationsMutationResult =
  Apollo.MutationResult<DeleteMedicationsMutation>;
export type DeleteMedicationsMutationOptions = Apollo.BaseMutationOptions<
  DeleteMedicationsMutation,
  DeleteMedicationsMutationVariables
>;
export const UpdateMedicationDocument = gql`
  mutation UpdateMedication($input: UpdateMedicationInput!) {
    updateMedication(input: $input) {
      id
      name
    }
  }
`;
export type UpdateMedicationMutationFn = Apollo.MutationFunction<
  UpdateMedicationMutation,
  UpdateMedicationMutationVariables
>;

/**
 * __useUpdateMedicationMutation__
 *
 * To run a mutation, you first call `useUpdateMedicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMedicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMedicationMutation, { data, loading, error }] = useUpdateMedicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMedicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMedicationMutation,
    UpdateMedicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMedicationMutation,
    UpdateMedicationMutationVariables
  >(UpdateMedicationDocument, options);
}
export type UpdateMedicationMutationHookResult = ReturnType<
  typeof useUpdateMedicationMutation
>;
export type UpdateMedicationMutationResult =
  Apollo.MutationResult<UpdateMedicationMutation>;
export type UpdateMedicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateMedicationMutation,
  UpdateMedicationMutationVariables
>;
export const CreateMemberBasePayRateDocument = gql`
  mutation CreateMemberBasePayRate($input: CreateMemberBasePayRateInput!) {
    createMemberBasePayRate(input: $input) {
      id
      date
      value
      type
      member {
        id
      }
    }
  }
`;
export type CreateMemberBasePayRateMutationFn = Apollo.MutationFunction<
  CreateMemberBasePayRateMutation,
  CreateMemberBasePayRateMutationVariables
>;

/**
 * __useCreateMemberBasePayRateMutation__
 *
 * To run a mutation, you first call `useCreateMemberBasePayRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberBasePayRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberBasePayRateMutation, { data, loading, error }] = useCreateMemberBasePayRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberBasePayRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberBasePayRateMutation,
    CreateMemberBasePayRateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberBasePayRateMutation,
    CreateMemberBasePayRateMutationVariables
  >(CreateMemberBasePayRateDocument, options);
}
export type CreateMemberBasePayRateMutationHookResult = ReturnType<
  typeof useCreateMemberBasePayRateMutation
>;
export type CreateMemberBasePayRateMutationResult =
  Apollo.MutationResult<CreateMemberBasePayRateMutation>;
export type CreateMemberBasePayRateMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberBasePayRateMutation,
  CreateMemberBasePayRateMutationVariables
>;
export const DeleteMemberBasePayRatesDocument = gql`
  mutation DeleteMemberBasePayRates($input: DeleteInput!) {
    deleteMemberBasePayRates(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteMemberBasePayRatesMutationFn = Apollo.MutationFunction<
  DeleteMemberBasePayRatesMutation,
  DeleteMemberBasePayRatesMutationVariables
>;

/**
 * __useDeleteMemberBasePayRatesMutation__
 *
 * To run a mutation, you first call `useDeleteMemberBasePayRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberBasePayRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberBasePayRatesMutation, { data, loading, error }] = useDeleteMemberBasePayRatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberBasePayRatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemberBasePayRatesMutation,
    DeleteMemberBasePayRatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMemberBasePayRatesMutation,
    DeleteMemberBasePayRatesMutationVariables
  >(DeleteMemberBasePayRatesDocument, options);
}
export type DeleteMemberBasePayRatesMutationHookResult = ReturnType<
  typeof useDeleteMemberBasePayRatesMutation
>;
export type DeleteMemberBasePayRatesMutationResult =
  Apollo.MutationResult<DeleteMemberBasePayRatesMutation>;
export type DeleteMemberBasePayRatesMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMemberBasePayRatesMutation,
    DeleteMemberBasePayRatesMutationVariables
  >;
export const RestoreMemberBasePayRatesDocument = gql`
  mutation RestoreMemberBasePayRates($input: QueryByIdsInput!) {
    restoreMemberBasePayRates(input: $input) {
      id
      active
      delete {
        id
      }
    }
  }
`;
export type RestoreMemberBasePayRatesMutationFn = Apollo.MutationFunction<
  RestoreMemberBasePayRatesMutation,
  RestoreMemberBasePayRatesMutationVariables
>;

/**
 * __useRestoreMemberBasePayRatesMutation__
 *
 * To run a mutation, you first call `useRestoreMemberBasePayRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMemberBasePayRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMemberBasePayRatesMutation, { data, loading, error }] = useRestoreMemberBasePayRatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreMemberBasePayRatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreMemberBasePayRatesMutation,
    RestoreMemberBasePayRatesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreMemberBasePayRatesMutation,
    RestoreMemberBasePayRatesMutationVariables
  >(RestoreMemberBasePayRatesDocument, options);
}
export type RestoreMemberBasePayRatesMutationHookResult = ReturnType<
  typeof useRestoreMemberBasePayRatesMutation
>;
export type RestoreMemberBasePayRatesMutationResult =
  Apollo.MutationResult<RestoreMemberBasePayRatesMutation>;
export type RestoreMemberBasePayRatesMutationOptions =
  Apollo.BaseMutationOptions<
    RestoreMemberBasePayRatesMutation,
    RestoreMemberBasePayRatesMutationVariables
  >;
export const UpdateMemberBasePayRateDocument = gql`
  mutation UpdateMemberBasePayRate($input: UpdateMemberBasePayRateInput!) {
    updateMemberBasePayRate(input: $input) {
      id
      date
      value
      type
      active
    }
  }
`;
export type UpdateMemberBasePayRateMutationFn = Apollo.MutationFunction<
  UpdateMemberBasePayRateMutation,
  UpdateMemberBasePayRateMutationVariables
>;

/**
 * __useUpdateMemberBasePayRateMutation__
 *
 * To run a mutation, you first call `useUpdateMemberBasePayRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberBasePayRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberBasePayRateMutation, { data, loading, error }] = useUpdateMemberBasePayRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberBasePayRateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberBasePayRateMutation,
    UpdateMemberBasePayRateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberBasePayRateMutation,
    UpdateMemberBasePayRateMutationVariables
  >(UpdateMemberBasePayRateDocument, options);
}
export type UpdateMemberBasePayRateMutationHookResult = ReturnType<
  typeof useUpdateMemberBasePayRateMutation
>;
export type UpdateMemberBasePayRateMutationResult =
  Apollo.MutationResult<UpdateMemberBasePayRateMutation>;
export type UpdateMemberBasePayRateMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberBasePayRateMutation,
  UpdateMemberBasePayRateMutationVariables
>;
export const CreateMemberBranchAllocationDocument = gql`
  mutation CreateMemberBranchAllocation(
    $input: CreateMemberBranchAllocationInput!
  ) {
    createMemberBranchAllocation(input: $input) {
      id
      date
      deletedAt
      member {
        id
      }
      values {
        value
        branchId
      }
    }
  }
`;
export type CreateMemberBranchAllocationMutationFn = Apollo.MutationFunction<
  CreateMemberBranchAllocationMutation,
  CreateMemberBranchAllocationMutationVariables
>;

/**
 * __useCreateMemberBranchAllocationMutation__
 *
 * To run a mutation, you first call `useCreateMemberBranchAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberBranchAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberBranchAllocationMutation, { data, loading, error }] = useCreateMemberBranchAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberBranchAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberBranchAllocationMutation,
    CreateMemberBranchAllocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberBranchAllocationMutation,
    CreateMemberBranchAllocationMutationVariables
  >(CreateMemberBranchAllocationDocument, options);
}
export type CreateMemberBranchAllocationMutationHookResult = ReturnType<
  typeof useCreateMemberBranchAllocationMutation
>;
export type CreateMemberBranchAllocationMutationResult =
  Apollo.MutationResult<CreateMemberBranchAllocationMutation>;
export type CreateMemberBranchAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMemberBranchAllocationMutation,
    CreateMemberBranchAllocationMutationVariables
  >;
export const DeleteMemberBranchAllocationDocument = gql`
  mutation DeleteMemberBranchAllocation($input: QueryByIdInput!) {
    deleteMemberBranchAllocation(input: $input) {
      id
      deletedAt
    }
  }
`;
export type DeleteMemberBranchAllocationMutationFn = Apollo.MutationFunction<
  DeleteMemberBranchAllocationMutation,
  DeleteMemberBranchAllocationMutationVariables
>;

/**
 * __useDeleteMemberBranchAllocationMutation__
 *
 * To run a mutation, you first call `useDeleteMemberBranchAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberBranchAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberBranchAllocationMutation, { data, loading, error }] = useDeleteMemberBranchAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberBranchAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemberBranchAllocationMutation,
    DeleteMemberBranchAllocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMemberBranchAllocationMutation,
    DeleteMemberBranchAllocationMutationVariables
  >(DeleteMemberBranchAllocationDocument, options);
}
export type DeleteMemberBranchAllocationMutationHookResult = ReturnType<
  typeof useDeleteMemberBranchAllocationMutation
>;
export type DeleteMemberBranchAllocationMutationResult =
  Apollo.MutationResult<DeleteMemberBranchAllocationMutation>;
export type DeleteMemberBranchAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMemberBranchAllocationMutation,
    DeleteMemberBranchAllocationMutationVariables
  >;
export const UpdateMemberBranchAllocationDocument = gql`
  mutation UpdateMemberBranchAllocation(
    $input: UpdateMemberBranchAllocationInput!
  ) {
    updateMemberBranchAllocation(input: $input) {
      id
      date
      values {
        value
        branchId
      }
    }
  }
`;
export type UpdateMemberBranchAllocationMutationFn = Apollo.MutationFunction<
  UpdateMemberBranchAllocationMutation,
  UpdateMemberBranchAllocationMutationVariables
>;

/**
 * __useUpdateMemberBranchAllocationMutation__
 *
 * To run a mutation, you first call `useUpdateMemberBranchAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberBranchAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberBranchAllocationMutation, { data, loading, error }] = useUpdateMemberBranchAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberBranchAllocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberBranchAllocationMutation,
    UpdateMemberBranchAllocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberBranchAllocationMutation,
    UpdateMemberBranchAllocationMutationVariables
  >(UpdateMemberBranchAllocationDocument, options);
}
export type UpdateMemberBranchAllocationMutationHookResult = ReturnType<
  typeof useUpdateMemberBranchAllocationMutation
>;
export type UpdateMemberBranchAllocationMutationResult =
  Apollo.MutationResult<UpdateMemberBranchAllocationMutation>;
export type UpdateMemberBranchAllocationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMemberBranchAllocationMutation,
    UpdateMemberBranchAllocationMutationVariables
  >;
export const CreateMemberFileDocument = gql`
  mutation CreateMemberFile($input: CreateMemberFileInput!) {
    createMemberFile(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      issuedAt
      value
      owner {
        id
      }
      file {
        id
        name
        size
        path
        createdBy {
          id
        }
      }
    }
  }
`;
export type CreateMemberFileMutationFn = Apollo.MutationFunction<
  CreateMemberFileMutation,
  CreateMemberFileMutationVariables
>;

/**
 * __useCreateMemberFileMutation__
 *
 * To run a mutation, you first call `useCreateMemberFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberFileMutation, { data, loading, error }] = useCreateMemberFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberFileMutation,
    CreateMemberFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberFileMutation,
    CreateMemberFileMutationVariables
  >(CreateMemberFileDocument, options);
}
export type CreateMemberFileMutationHookResult = ReturnType<
  typeof useCreateMemberFileMutation
>;
export type CreateMemberFileMutationResult =
  Apollo.MutationResult<CreateMemberFileMutation>;
export type CreateMemberFileMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberFileMutation,
  CreateMemberFileMutationVariables
>;
export const DeleteMemberFilesDocument = gql`
  mutation DeleteMemberFiles($input: DeleteInput!) {
    deleteMemberFiles(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteMemberFilesMutationFn = Apollo.MutationFunction<
  DeleteMemberFilesMutation,
  DeleteMemberFilesMutationVariables
>;

/**
 * __useDeleteMemberFilesMutation__
 *
 * To run a mutation, you first call `useDeleteMemberFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberFilesMutation, { data, loading, error }] = useDeleteMemberFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemberFilesMutation,
    DeleteMemberFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMemberFilesMutation,
    DeleteMemberFilesMutationVariables
  >(DeleteMemberFilesDocument, options);
}
export type DeleteMemberFilesMutationHookResult = ReturnType<
  typeof useDeleteMemberFilesMutation
>;
export type DeleteMemberFilesMutationResult =
  Apollo.MutationResult<DeleteMemberFilesMutation>;
export type DeleteMemberFilesMutationOptions = Apollo.BaseMutationOptions<
  DeleteMemberFilesMutation,
  DeleteMemberFilesMutationVariables
>;
export const RestoreMemberFilesDocument = gql`
  mutation RestoreMemberFiles($input: QueryByIdsInput!) {
    restoreMemberFiles(input: $input) {
      id
      delete {
        id
      }
      archive {
        id
      }
    }
  }
`;
export type RestoreMemberFilesMutationFn = Apollo.MutationFunction<
  RestoreMemberFilesMutation,
  RestoreMemberFilesMutationVariables
>;

/**
 * __useRestoreMemberFilesMutation__
 *
 * To run a mutation, you first call `useRestoreMemberFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMemberFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMemberFilesMutation, { data, loading, error }] = useRestoreMemberFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreMemberFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreMemberFilesMutation,
    RestoreMemberFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreMemberFilesMutation,
    RestoreMemberFilesMutationVariables
  >(RestoreMemberFilesDocument, options);
}
export type RestoreMemberFilesMutationHookResult = ReturnType<
  typeof useRestoreMemberFilesMutation
>;
export type RestoreMemberFilesMutationResult =
  Apollo.MutationResult<RestoreMemberFilesMutation>;
export type RestoreMemberFilesMutationOptions = Apollo.BaseMutationOptions<
  RestoreMemberFilesMutation,
  RestoreMemberFilesMutationVariables
>;
export const UpdateMemberFilesDocument = gql`
  mutation UpdateMemberFiles($input: UpdateMemberFilesInput!) {
    updateMemberFiles(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      issuedAt
      value
      owner {
        id
      }
      file {
        id
        name
      }
    }
  }
`;
export type UpdateMemberFilesMutationFn = Apollo.MutationFunction<
  UpdateMemberFilesMutation,
  UpdateMemberFilesMutationVariables
>;

/**
 * __useUpdateMemberFilesMutation__
 *
 * To run a mutation, you first call `useUpdateMemberFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberFilesMutation, { data, loading, error }] = useUpdateMemberFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberFilesMutation,
    UpdateMemberFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberFilesMutation,
    UpdateMemberFilesMutationVariables
  >(UpdateMemberFilesDocument, options);
}
export type UpdateMemberFilesMutationHookResult = ReturnType<
  typeof useUpdateMemberFilesMutation
>;
export type UpdateMemberFilesMutationResult =
  Apollo.MutationResult<UpdateMemberFilesMutation>;
export type UpdateMemberFilesMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberFilesMutation,
  UpdateMemberFilesMutationVariables
>;
export const GenerateMemberHoursDocument = gql`
  mutation GenerateMemberHours($input: GenerateMemberHoursInput!) {
    generateMemberHours(input: $input) {
      id
      date
      type
      value
    }
  }
`;
export type GenerateMemberHoursMutationFn = Apollo.MutationFunction<
  GenerateMemberHoursMutation,
  GenerateMemberHoursMutationVariables
>;

/**
 * __useGenerateMemberHoursMutation__
 *
 * To run a mutation, you first call `useGenerateMemberHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMemberHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMemberHoursMutation, { data, loading, error }] = useGenerateMemberHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateMemberHoursMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateMemberHoursMutation,
    GenerateMemberHoursMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateMemberHoursMutation,
    GenerateMemberHoursMutationVariables
  >(GenerateMemberHoursDocument, options);
}
export type GenerateMemberHoursMutationHookResult = ReturnType<
  typeof useGenerateMemberHoursMutation
>;
export type GenerateMemberHoursMutationResult =
  Apollo.MutationResult<GenerateMemberHoursMutation>;
export type GenerateMemberHoursMutationOptions = Apollo.BaseMutationOptions<
  GenerateMemberHoursMutation,
  GenerateMemberHoursMutationVariables
>;
export const InviteMembersDocument = gql`
  mutation InviteMembers($input: QueryByIdsInput!) {
    inviteMembers(input: $input) {
      id
      invitationMostRecentlyReceived {
        id
        lastSender {
          id
          sentAt
          sentBy {
            id
            ...PersonNamesPartial
          }
        }
      }
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;
export type InviteMembersMutationFn = Apollo.MutationFunction<
  InviteMembersMutation,
  InviteMembersMutationVariables
>;

/**
 * __useInviteMembersMutation__
 *
 * To run a mutation, you first call `useInviteMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMembersMutation, { data, loading, error }] = useInviteMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteMembersMutation,
    InviteMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteMembersMutation,
    InviteMembersMutationVariables
  >(InviteMembersDocument, options);
}
export type InviteMembersMutationHookResult = ReturnType<
  typeof useInviteMembersMutation
>;
export type InviteMembersMutationResult =
  Apollo.MutationResult<InviteMembersMutation>;
export type InviteMembersMutationOptions = Apollo.BaseMutationOptions<
  InviteMembersMutation,
  InviteMembersMutationVariables
>;
export const ProcessMemberInvitationDocument = gql`
  mutation ProcessMemberInvitation($input: LinkMemberInput!) {
    linkMember(input: $input) {
      id
      org {
        id
      }
    }
  }
`;
export type ProcessMemberInvitationMutationFn = Apollo.MutationFunction<
  ProcessMemberInvitationMutation,
  ProcessMemberInvitationMutationVariables
>;

/**
 * __useProcessMemberInvitationMutation__
 *
 * To run a mutation, you first call `useProcessMemberInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessMemberInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processMemberInvitationMutation, { data, loading, error }] = useProcessMemberInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessMemberInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessMemberInvitationMutation,
    ProcessMemberInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProcessMemberInvitationMutation,
    ProcessMemberInvitationMutationVariables
  >(ProcessMemberInvitationDocument, options);
}
export type ProcessMemberInvitationMutationHookResult = ReturnType<
  typeof useProcessMemberInvitationMutation
>;
export type ProcessMemberInvitationMutationResult =
  Apollo.MutationResult<ProcessMemberInvitationMutation>;
export type ProcessMemberInvitationMutationOptions = Apollo.BaseMutationOptions<
  ProcessMemberInvitationMutation,
  ProcessMemberInvitationMutationVariables
>;
export const UninviteMemberDocument = gql`
  mutation UninviteMember($input: QueryByIdInput!) {
    uninviteMember(input: $input) {
      id
      deletedAt
    }
  }
`;
export type UninviteMemberMutationFn = Apollo.MutationFunction<
  UninviteMemberMutation,
  UninviteMemberMutationVariables
>;

/**
 * __useUninviteMemberMutation__
 *
 * To run a mutation, you first call `useUninviteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninviteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninviteMemberMutation, { data, loading, error }] = useUninviteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUninviteMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninviteMemberMutation,
    UninviteMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UninviteMemberMutation,
    UninviteMemberMutationVariables
  >(UninviteMemberDocument, options);
}
export type UninviteMemberMutationHookResult = ReturnType<
  typeof useUninviteMemberMutation
>;
export type UninviteMemberMutationResult =
  Apollo.MutationResult<UninviteMemberMutation>;
export type UninviteMemberMutationOptions = Apollo.BaseMutationOptions<
  UninviteMemberMutation,
  UninviteMemberMutationVariables
>;
export const SetMemberPayrollCheckDocument = gql`
  mutation SetMemberPayrollCheck($input: SetMemberPayrollCheckInput!) {
    setMemberPayrollCheck(input: $input) {
      id
      eventsChecked
      clocksChecked
      paid
      member {
        id
      }
    }
  }
`;
export type SetMemberPayrollCheckMutationFn = Apollo.MutationFunction<
  SetMemberPayrollCheckMutation,
  SetMemberPayrollCheckMutationVariables
>;

/**
 * __useSetMemberPayrollCheckMutation__
 *
 * To run a mutation, you first call `useSetMemberPayrollCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMemberPayrollCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMemberPayrollCheckMutation, { data, loading, error }] = useSetMemberPayrollCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMemberPayrollCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetMemberPayrollCheckMutation,
    SetMemberPayrollCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetMemberPayrollCheckMutation,
    SetMemberPayrollCheckMutationVariables
  >(SetMemberPayrollCheckDocument, options);
}
export type SetMemberPayrollCheckMutationHookResult = ReturnType<
  typeof useSetMemberPayrollCheckMutation
>;
export type SetMemberPayrollCheckMutationResult =
  Apollo.MutationResult<SetMemberPayrollCheckMutation>;
export type SetMemberPayrollCheckMutationOptions = Apollo.BaseMutationOptions<
  SetMemberPayrollCheckMutation,
  SetMemberPayrollCheckMutationVariables
>;
export const CreateMemberPostNominalDocument = gql`
  mutation CreateMemberPostNominal($input: CreateMemberPostNominalInput!) {
    createMemberPostNominal(input: $input) {
      id
      date
      value
      member {
        id
        currentPostNominal
      }
    }
  }
`;
export type CreateMemberPostNominalMutationFn = Apollo.MutationFunction<
  CreateMemberPostNominalMutation,
  CreateMemberPostNominalMutationVariables
>;

/**
 * __useCreateMemberPostNominalMutation__
 *
 * To run a mutation, you first call `useCreateMemberPostNominalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberPostNominalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberPostNominalMutation, { data, loading, error }] = useCreateMemberPostNominalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberPostNominalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberPostNominalMutation,
    CreateMemberPostNominalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberPostNominalMutation,
    CreateMemberPostNominalMutationVariables
  >(CreateMemberPostNominalDocument, options);
}
export type CreateMemberPostNominalMutationHookResult = ReturnType<
  typeof useCreateMemberPostNominalMutation
>;
export type CreateMemberPostNominalMutationResult =
  Apollo.MutationResult<CreateMemberPostNominalMutation>;
export type CreateMemberPostNominalMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberPostNominalMutation,
  CreateMemberPostNominalMutationVariables
>;
export const DeleteMemberPostNominalDocument = gql`
  mutation DeleteMemberPostNominal($input: QueryByIdInput!) {
    deleteMemberPostNominal(input: $input) {
      id
      deletedAt
      member {
        id
        currentPostNominal
      }
    }
  }
`;
export type DeleteMemberPostNominalMutationFn = Apollo.MutationFunction<
  DeleteMemberPostNominalMutation,
  DeleteMemberPostNominalMutationVariables
>;

/**
 * __useDeleteMemberPostNominalMutation__
 *
 * To run a mutation, you first call `useDeleteMemberPostNominalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberPostNominalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberPostNominalMutation, { data, loading, error }] = useDeleteMemberPostNominalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberPostNominalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemberPostNominalMutation,
    DeleteMemberPostNominalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMemberPostNominalMutation,
    DeleteMemberPostNominalMutationVariables
  >(DeleteMemberPostNominalDocument, options);
}
export type DeleteMemberPostNominalMutationHookResult = ReturnType<
  typeof useDeleteMemberPostNominalMutation
>;
export type DeleteMemberPostNominalMutationResult =
  Apollo.MutationResult<DeleteMemberPostNominalMutation>;
export type DeleteMemberPostNominalMutationOptions = Apollo.BaseMutationOptions<
  DeleteMemberPostNominalMutation,
  DeleteMemberPostNominalMutationVariables
>;
export const UpdateMemberPostNominalDocument = gql`
  mutation UpdateMemberPostNominal($input: UpdateMemberPostNominalInput!) {
    updateMemberPostNominal(input: $input) {
      id
      date
      value
      member {
        id
        currentPostNominal
      }
    }
  }
`;
export type UpdateMemberPostNominalMutationFn = Apollo.MutationFunction<
  UpdateMemberPostNominalMutation,
  UpdateMemberPostNominalMutationVariables
>;

/**
 * __useUpdateMemberPostNominalMutation__
 *
 * To run a mutation, you first call `useUpdateMemberPostNominalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberPostNominalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberPostNominalMutation, { data, loading, error }] = useUpdateMemberPostNominalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberPostNominalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberPostNominalMutation,
    UpdateMemberPostNominalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberPostNominalMutation,
    UpdateMemberPostNominalMutationVariables
  >(UpdateMemberPostNominalDocument, options);
}
export type UpdateMemberPostNominalMutationHookResult = ReturnType<
  typeof useUpdateMemberPostNominalMutation
>;
export type UpdateMemberPostNominalMutationResult =
  Apollo.MutationResult<UpdateMemberPostNominalMutation>;
export type UpdateMemberPostNominalMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberPostNominalMutation,
  UpdateMemberPostNominalMutationVariables
>;
export const CreateMemberScheduleTimeLimitDocument = gql`
  mutation CreateMemberScheduleTimeLimit(
    $input: CreateMemberScheduleTimeLimitsInput!
  ) {
    createMemberScheduleTimeLimits(input: $input) {
      id
      createdAt
      description
      limit
      startAt
      endAt
      member {
        id
        scheduleMaxWeekTimeLimitCurrent
      }
      createdBy {
        id
      }
    }
  }
`;
export type CreateMemberScheduleTimeLimitMutationFn = Apollo.MutationFunction<
  CreateMemberScheduleTimeLimitMutation,
  CreateMemberScheduleTimeLimitMutationVariables
>;

/**
 * __useCreateMemberScheduleTimeLimitMutation__
 *
 * To run a mutation, you first call `useCreateMemberScheduleTimeLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberScheduleTimeLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberScheduleTimeLimitMutation, { data, loading, error }] = useCreateMemberScheduleTimeLimitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberScheduleTimeLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberScheduleTimeLimitMutation,
    CreateMemberScheduleTimeLimitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberScheduleTimeLimitMutation,
    CreateMemberScheduleTimeLimitMutationVariables
  >(CreateMemberScheduleTimeLimitDocument, options);
}
export type CreateMemberScheduleTimeLimitMutationHookResult = ReturnType<
  typeof useCreateMemberScheduleTimeLimitMutation
>;
export type CreateMemberScheduleTimeLimitMutationResult =
  Apollo.MutationResult<CreateMemberScheduleTimeLimitMutation>;
export type CreateMemberScheduleTimeLimitMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMemberScheduleTimeLimitMutation,
    CreateMemberScheduleTimeLimitMutationVariables
  >;
export const CreateMemberUnavailablesDocument = gql`
  mutation CreateMemberUnavailables($input: CreateMemberUnavailablesInput!) {
    createMemberUnavailables(input: $input) {
      id
      startAt
      endAt
      notes
      dates
      color
      rrule
      owner {
        id
      }
    }
  }
`;
export type CreateMemberUnavailablesMutationFn = Apollo.MutationFunction<
  CreateMemberUnavailablesMutation,
  CreateMemberUnavailablesMutationVariables
>;

/**
 * __useCreateMemberUnavailablesMutation__
 *
 * To run a mutation, you first call `useCreateMemberUnavailablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberUnavailablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberUnavailablesMutation, { data, loading, error }] = useCreateMemberUnavailablesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberUnavailablesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberUnavailablesMutation,
    CreateMemberUnavailablesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberUnavailablesMutation,
    CreateMemberUnavailablesMutationVariables
  >(CreateMemberUnavailablesDocument, options);
}
export type CreateMemberUnavailablesMutationHookResult = ReturnType<
  typeof useCreateMemberUnavailablesMutation
>;
export type CreateMemberUnavailablesMutationResult =
  Apollo.MutationResult<CreateMemberUnavailablesMutation>;
export type CreateMemberUnavailablesMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMemberUnavailablesMutation,
    CreateMemberUnavailablesMutationVariables
  >;
export const DeleteMemberUnavailablesDocument = gql`
  mutation DeleteMemberUnavailables($input: QueryByIdsInput!) {
    deleteMemberUnavailables(input: $input) {
      id
      deletedAt
    }
  }
`;
export type DeleteMemberUnavailablesMutationFn = Apollo.MutationFunction<
  DeleteMemberUnavailablesMutation,
  DeleteMemberUnavailablesMutationVariables
>;

/**
 * __useDeleteMemberUnavailablesMutation__
 *
 * To run a mutation, you first call `useDeleteMemberUnavailablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberUnavailablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberUnavailablesMutation, { data, loading, error }] = useDeleteMemberUnavailablesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMemberUnavailablesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMemberUnavailablesMutation,
    DeleteMemberUnavailablesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMemberUnavailablesMutation,
    DeleteMemberUnavailablesMutationVariables
  >(DeleteMemberUnavailablesDocument, options);
}
export type DeleteMemberUnavailablesMutationHookResult = ReturnType<
  typeof useDeleteMemberUnavailablesMutation
>;
export type DeleteMemberUnavailablesMutationResult =
  Apollo.MutationResult<DeleteMemberUnavailablesMutation>;
export type DeleteMemberUnavailablesMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteMemberUnavailablesMutation,
    DeleteMemberUnavailablesMutationVariables
  >;
export const UpdateMemberUnavailableDocument = gql`
  mutation UpdateMemberUnavailable($input: UpdateMemberUnavailableInput!) {
    updateMemberUnavailable(input: $input) {
      id
      startAt
      endAt
      notes
      dates
      color
      rrule
    }
  }
`;
export type UpdateMemberUnavailableMutationFn = Apollo.MutationFunction<
  UpdateMemberUnavailableMutation,
  UpdateMemberUnavailableMutationVariables
>;

/**
 * __useUpdateMemberUnavailableMutation__
 *
 * To run a mutation, you first call `useUpdateMemberUnavailableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberUnavailableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberUnavailableMutation, { data, loading, error }] = useUpdateMemberUnavailableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberUnavailableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberUnavailableMutation,
    UpdateMemberUnavailableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberUnavailableMutation,
    UpdateMemberUnavailableMutationVariables
  >(UpdateMemberUnavailableDocument, options);
}
export type UpdateMemberUnavailableMutationHookResult = ReturnType<
  typeof useUpdateMemberUnavailableMutation
>;
export type UpdateMemberUnavailableMutationResult =
  Apollo.MutationResult<UpdateMemberUnavailableMutation>;
export type UpdateMemberUnavailableMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberUnavailableMutation,
  UpdateMemberUnavailableMutationVariables
>;
export const CreateMemberUploadDocument = gql`
  mutation CreateMemberUpload($input: CreateMemberUploadInput!) {
    createMemberUpload(input: $input) {
      id
      type
      processingState
      uploadedFor {
        id
        missingFiles
        ...PersonNames
      }
      file {
        id
        size
        path
        createdBy {
          id
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;
export type CreateMemberUploadMutationFn = Apollo.MutationFunction<
  CreateMemberUploadMutation,
  CreateMemberUploadMutationVariables
>;

/**
 * __useCreateMemberUploadMutation__
 *
 * To run a mutation, you first call `useCreateMemberUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberUploadMutation, { data, loading, error }] = useCreateMemberUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberUploadMutation,
    CreateMemberUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMemberUploadMutation,
    CreateMemberUploadMutationVariables
  >(CreateMemberUploadDocument, options);
}
export type CreateMemberUploadMutationHookResult = ReturnType<
  typeof useCreateMemberUploadMutation
>;
export type CreateMemberUploadMutationResult =
  Apollo.MutationResult<CreateMemberUploadMutation>;
export type CreateMemberUploadMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberUploadMutation,
  CreateMemberUploadMutationVariables
>;
export const ProcessMemberUploadDocument = gql`
  mutation ProcessMemberUpload($input: ProcessMemberUploadInput!) {
    processMemberUpload(input: $input) {
      id
      processingState
      processingNote
      processedBy {
        id
      }
      memberFile {
        id
      }
    }
  }
`;
export type ProcessMemberUploadMutationFn = Apollo.MutationFunction<
  ProcessMemberUploadMutation,
  ProcessMemberUploadMutationVariables
>;

/**
 * __useProcessMemberUploadMutation__
 *
 * To run a mutation, you first call `useProcessMemberUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessMemberUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processMemberUploadMutation, { data, loading, error }] = useProcessMemberUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessMemberUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessMemberUploadMutation,
    ProcessMemberUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProcessMemberUploadMutation,
    ProcessMemberUploadMutationVariables
  >(ProcessMemberUploadDocument, options);
}
export type ProcessMemberUploadMutationHookResult = ReturnType<
  typeof useProcessMemberUploadMutation
>;
export type ProcessMemberUploadMutationResult =
  Apollo.MutationResult<ProcessMemberUploadMutation>;
export type ProcessMemberUploadMutationOptions = Apollo.BaseMutationOptions<
  ProcessMemberUploadMutation,
  ProcessMemberUploadMutationVariables
>;
export const UpdateMemberBankDetailsSelfDocument = gql`
  mutation UpdateMemberBankDetailsSelf($input: UpdateMemberCurrentInput!) {
    updateMemberCurrent(input: $input) {
      id
      bank {
        bsb
        accountNumber
        accountName
      }
    }
  }
`;
export type UpdateMemberBankDetailsSelfMutationFn = Apollo.MutationFunction<
  UpdateMemberBankDetailsSelfMutation,
  UpdateMemberBankDetailsSelfMutationVariables
>;

/**
 * __useUpdateMemberBankDetailsSelfMutation__
 *
 * To run a mutation, you first call `useUpdateMemberBankDetailsSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberBankDetailsSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberBankDetailsSelfMutation, { data, loading, error }] = useUpdateMemberBankDetailsSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberBankDetailsSelfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberBankDetailsSelfMutation,
    UpdateMemberBankDetailsSelfMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberBankDetailsSelfMutation,
    UpdateMemberBankDetailsSelfMutationVariables
  >(UpdateMemberBankDetailsSelfDocument, options);
}
export type UpdateMemberBankDetailsSelfMutationHookResult = ReturnType<
  typeof useUpdateMemberBankDetailsSelfMutation
>;
export type UpdateMemberBankDetailsSelfMutationResult =
  Apollo.MutationResult<UpdateMemberBankDetailsSelfMutation>;
export type UpdateMemberBankDetailsSelfMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMemberBankDetailsSelfMutation,
    UpdateMemberBankDetailsSelfMutationVariables
  >;
export const UpdateMemberSuperDetailsSelfDocument = gql`
  mutation UpdateMemberSuperDetailsSelf($input: UpdateMemberCurrentInput!) {
    updateMemberCurrent(input: $input) {
      id
      super {
        usi
        fundName
        fundAbn
        accountName
        accountNumber
      }
    }
  }
`;
export type UpdateMemberSuperDetailsSelfMutationFn = Apollo.MutationFunction<
  UpdateMemberSuperDetailsSelfMutation,
  UpdateMemberSuperDetailsSelfMutationVariables
>;

/**
 * __useUpdateMemberSuperDetailsSelfMutation__
 *
 * To run a mutation, you first call `useUpdateMemberSuperDetailsSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberSuperDetailsSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberSuperDetailsSelfMutation, { data, loading, error }] = useUpdateMemberSuperDetailsSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberSuperDetailsSelfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberSuperDetailsSelfMutation,
    UpdateMemberSuperDetailsSelfMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberSuperDetailsSelfMutation,
    UpdateMemberSuperDetailsSelfMutationVariables
  >(UpdateMemberSuperDetailsSelfDocument, options);
}
export type UpdateMemberSuperDetailsSelfMutationHookResult = ReturnType<
  typeof useUpdateMemberSuperDetailsSelfMutation
>;
export type UpdateMemberSuperDetailsSelfMutationResult =
  Apollo.MutationResult<UpdateMemberSuperDetailsSelfMutation>;
export type UpdateMemberSuperDetailsSelfMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMemberSuperDetailsSelfMutation,
    UpdateMemberSuperDetailsSelfMutationVariables
  >;
export const UpdateMemberTaxFileNumberDeclarationDetailsSelfDocument = gql`
  mutation UpdateMemberTaxFileNumberDeclarationDetailsSelf(
    $input: UpdateMemberCurrentInput!
  ) {
    updateMemberCurrent(input: $input) {
      id
      taxFileNumberDeclaration {
        tfn
        appliedForTfn
        claimingExemptionMinor
        claimingExemptionPension
        salutation
        lastName
        firstName
        middleName
        street
        locality
        region
        postcode
        prevLastName
        email
        dob
        basisOfPay
        areYou
        claimTaxFreeThreshold
        studentLoanDebt
      }
    }
  }
`;
export type UpdateMemberTaxFileNumberDeclarationDetailsSelfMutationFn =
  Apollo.MutationFunction<
    UpdateMemberTaxFileNumberDeclarationDetailsSelfMutation,
    UpdateMemberTaxFileNumberDeclarationDetailsSelfMutationVariables
  >;

/**
 * __useUpdateMemberTaxFileNumberDeclarationDetailsSelfMutation__
 *
 * To run a mutation, you first call `useUpdateMemberTaxFileNumberDeclarationDetailsSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberTaxFileNumberDeclarationDetailsSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberTaxFileNumberDeclarationDetailsSelfMutation, { data, loading, error }] = useUpdateMemberTaxFileNumberDeclarationDetailsSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberTaxFileNumberDeclarationDetailsSelfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberTaxFileNumberDeclarationDetailsSelfMutation,
    UpdateMemberTaxFileNumberDeclarationDetailsSelfMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberTaxFileNumberDeclarationDetailsSelfMutation,
    UpdateMemberTaxFileNumberDeclarationDetailsSelfMutationVariables
  >(UpdateMemberTaxFileNumberDeclarationDetailsSelfDocument, options);
}
export type UpdateMemberTaxFileNumberDeclarationDetailsSelfMutationHookResult =
  ReturnType<typeof useUpdateMemberTaxFileNumberDeclarationDetailsSelfMutation>;
export type UpdateMemberTaxFileNumberDeclarationDetailsSelfMutationResult =
  Apollo.MutationResult<UpdateMemberTaxFileNumberDeclarationDetailsSelfMutation>;
export type UpdateMemberTaxFileNumberDeclarationDetailsSelfMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMemberTaxFileNumberDeclarationDetailsSelfMutation,
    UpdateMemberTaxFileNumberDeclarationDetailsSelfMutationVariables
  >;
export const ArchiveMembersDocument = gql`
  mutation ArchiveMembers($input: ArchiveInput!) {
    archiveMembers(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveMembersMutationFn = Apollo.MutationFunction<
  ArchiveMembersMutation,
  ArchiveMembersMutationVariables
>;

/**
 * __useArchiveMembersMutation__
 *
 * To run a mutation, you first call `useArchiveMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveMembersMutation, { data, loading, error }] = useArchiveMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveMembersMutation,
    ArchiveMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveMembersMutation,
    ArchiveMembersMutationVariables
  >(ArchiveMembersDocument, options);
}
export type ArchiveMembersMutationHookResult = ReturnType<
  typeof useArchiveMembersMutation
>;
export type ArchiveMembersMutationResult =
  Apollo.MutationResult<ArchiveMembersMutation>;
export type ArchiveMembersMutationOptions = Apollo.BaseMutationOptions<
  ArchiveMembersMutation,
  ArchiveMembersMutationVariables
>;
export const CreateMembersDocument = gql`
  mutation CreateMembers($input: CreateMembersInput!) {
    createMembers(input: $input) {
      id
      moduleAccessCS
      moduleAccessSC
      externalId
      color
      email
      phone
      salutation
      gender
      pronouns
      dob
      timezone
      schedulable
      trackFiles
      bonusEligible
      workRights
      workRightsLastCheckedAt
      passportCountry
      passportNumber
      allowedFortnightlyWorkHours
      visaType {
        id
      }
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type CreateMembersMutationFn = Apollo.MutationFunction<
  CreateMembersMutation,
  CreateMembersMutationVariables
>;

/**
 * __useCreateMembersMutation__
 *
 * To run a mutation, you first call `useCreateMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMembersMutation, { data, loading, error }] = useCreateMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMembersMutation,
    CreateMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMembersMutation,
    CreateMembersMutationVariables
  >(CreateMembersDocument, options);
}
export type CreateMembersMutationHookResult = ReturnType<
  typeof useCreateMembersMutation
>;
export type CreateMembersMutationResult =
  Apollo.MutationResult<CreateMembersMutation>;
export type CreateMembersMutationOptions = Apollo.BaseMutationOptions<
  CreateMembersMutation,
  CreateMembersMutationVariables
>;
export const DeleteMembersDocument = gql`
  mutation DeleteMembers($input: DeleteInput!) {
    deleteMembers(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteMembersMutationFn = Apollo.MutationFunction<
  DeleteMembersMutation,
  DeleteMembersMutationVariables
>;

/**
 * __useDeleteMembersMutation__
 *
 * To run a mutation, you first call `useDeleteMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembersMutation, { data, loading, error }] = useDeleteMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMembersMutation,
    DeleteMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMembersMutation,
    DeleteMembersMutationVariables
  >(DeleteMembersDocument, options);
}
export type DeleteMembersMutationHookResult = ReturnType<
  typeof useDeleteMembersMutation
>;
export type DeleteMembersMutationResult =
  Apollo.MutationResult<DeleteMembersMutation>;
export type DeleteMembersMutationOptions = Apollo.BaseMutationOptions<
  DeleteMembersMutation,
  DeleteMembersMutationVariables
>;
export const EmailMemberClockedPeriodsDocument = gql`
  mutation EmailMemberClockedPeriods($input: QueryMemberClockedPeriodsInput!) {
    emailMemberClockedPeriods(input: $input) {
      value
    }
  }
`;
export type EmailMemberClockedPeriodsMutationFn = Apollo.MutationFunction<
  EmailMemberClockedPeriodsMutation,
  EmailMemberClockedPeriodsMutationVariables
>;

/**
 * __useEmailMemberClockedPeriodsMutation__
 *
 * To run a mutation, you first call `useEmailMemberClockedPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailMemberClockedPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailMemberClockedPeriodsMutation, { data, loading, error }] = useEmailMemberClockedPeriodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailMemberClockedPeriodsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EmailMemberClockedPeriodsMutation,
    EmailMemberClockedPeriodsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EmailMemberClockedPeriodsMutation,
    EmailMemberClockedPeriodsMutationVariables
  >(EmailMemberClockedPeriodsDocument, options);
}
export type EmailMemberClockedPeriodsMutationHookResult = ReturnType<
  typeof useEmailMemberClockedPeriodsMutation
>;
export type EmailMemberClockedPeriodsMutationResult =
  Apollo.MutationResult<EmailMemberClockedPeriodsMutation>;
export type EmailMemberClockedPeriodsMutationOptions =
  Apollo.BaseMutationOptions<
    EmailMemberClockedPeriodsMutation,
    EmailMemberClockedPeriodsMutationVariables
  >;
export const RestoreMembersDocument = gql`
  mutation RestoreMembers($input: QueryByIdsInput!) {
    restoreMembers(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreMembersMutationFn = Apollo.MutationFunction<
  RestoreMembersMutation,
  RestoreMembersMutationVariables
>;

/**
 * __useRestoreMembersMutation__
 *
 * To run a mutation, you first call `useRestoreMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreMembersMutation, { data, loading, error }] = useRestoreMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreMembersMutation,
    RestoreMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreMembersMutation,
    RestoreMembersMutationVariables
  >(RestoreMembersDocument, options);
}
export type RestoreMembersMutationHookResult = ReturnType<
  typeof useRestoreMembersMutation
>;
export type RestoreMembersMutationResult =
  Apollo.MutationResult<RestoreMembersMutation>;
export type RestoreMembersMutationOptions = Apollo.BaseMutationOptions<
  RestoreMembersMutation,
  RestoreMembersMutationVariables
>;
export const UnlinkMembersFromUsersDocument = gql`
  mutation UnlinkMembersFromUsers($input: QueryByIdsInput!) {
    unlinkMembers(input: $input) {
      id
      hasAccess
    }
  }
`;
export type UnlinkMembersFromUsersMutationFn = Apollo.MutationFunction<
  UnlinkMembersFromUsersMutation,
  UnlinkMembersFromUsersMutationVariables
>;

/**
 * __useUnlinkMembersFromUsersMutation__
 *
 * To run a mutation, you first call `useUnlinkMembersFromUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkMembersFromUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkMembersFromUsersMutation, { data, loading, error }] = useUnlinkMembersFromUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnlinkMembersFromUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkMembersFromUsersMutation,
    UnlinkMembersFromUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkMembersFromUsersMutation,
    UnlinkMembersFromUsersMutationVariables
  >(UnlinkMembersFromUsersDocument, options);
}
export type UnlinkMembersFromUsersMutationHookResult = ReturnType<
  typeof useUnlinkMembersFromUsersMutation
>;
export type UnlinkMembersFromUsersMutationResult =
  Apollo.MutationResult<UnlinkMembersFromUsersMutation>;
export type UnlinkMembersFromUsersMutationOptions = Apollo.BaseMutationOptions<
  UnlinkMembersFromUsersMutation,
  UnlinkMembersFromUsersMutationVariables
>;
export const UpdateCurrentMemberTypicalUnavailabilityDocument = gql`
  mutation UpdateCurrentMemberTypicalUnavailability(
    $input: UpdateMemberCurrentInput!
  ) {
    updateMemberCurrent(input: $input) {
      id
      unavailableNotes
      unavailableMoMorn
      unavailableMoDay
      unavailableMoNight
      unavailableTuMorn
      unavailableTuDay
      unavailableTuNight
      unavailableWeMorn
      unavailableWeDay
      unavailableWeNight
      unavailableThMorn
      unavailableThDay
      unavailableThNight
      unavailableFrMorn
      unavailableFrDay
      unavailableFrNight
      unavailableSaMorn
      unavailableSaDay
      unavailableSaNight
      unavailableSuMorn
      unavailableSuDay
      unavailableSuNight
    }
  }
`;
export type UpdateCurrentMemberTypicalUnavailabilityMutationFn =
  Apollo.MutationFunction<
    UpdateCurrentMemberTypicalUnavailabilityMutation,
    UpdateCurrentMemberTypicalUnavailabilityMutationVariables
  >;

/**
 * __useUpdateCurrentMemberTypicalUnavailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentMemberTypicalUnavailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentMemberTypicalUnavailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentMemberTypicalUnavailabilityMutation, { data, loading, error }] = useUpdateCurrentMemberTypicalUnavailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentMemberTypicalUnavailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentMemberTypicalUnavailabilityMutation,
    UpdateCurrentMemberTypicalUnavailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentMemberTypicalUnavailabilityMutation,
    UpdateCurrentMemberTypicalUnavailabilityMutationVariables
  >(UpdateCurrentMemberTypicalUnavailabilityDocument, options);
}
export type UpdateCurrentMemberTypicalUnavailabilityMutationHookResult =
  ReturnType<typeof useUpdateCurrentMemberTypicalUnavailabilityMutation>;
export type UpdateCurrentMemberTypicalUnavailabilityMutationResult =
  Apollo.MutationResult<UpdateCurrentMemberTypicalUnavailabilityMutation>;
export type UpdateCurrentMemberTypicalUnavailabilityMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentMemberTypicalUnavailabilityMutation,
    UpdateCurrentMemberTypicalUnavailabilityMutationVariables
  >;
export const UpdateMemberDefaultBranchDocument = gql`
  mutation UpdateMemberDefaultBranch($input: UpdateMemberCurrentInput!) {
    updateMemberCurrent(input: $input) {
      id
      defaultBranch {
        id
      }
    }
  }
`;
export type UpdateMemberDefaultBranchMutationFn = Apollo.MutationFunction<
  UpdateMemberDefaultBranchMutation,
  UpdateMemberDefaultBranchMutationVariables
>;

/**
 * __useUpdateMemberDefaultBranchMutation__
 *
 * To run a mutation, you first call `useUpdateMemberDefaultBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberDefaultBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberDefaultBranchMutation, { data, loading, error }] = useUpdateMemberDefaultBranchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberDefaultBranchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberDefaultBranchMutation,
    UpdateMemberDefaultBranchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMemberDefaultBranchMutation,
    UpdateMemberDefaultBranchMutationVariables
  >(UpdateMemberDefaultBranchDocument, options);
}
export type UpdateMemberDefaultBranchMutationHookResult = ReturnType<
  typeof useUpdateMemberDefaultBranchMutation
>;
export type UpdateMemberDefaultBranchMutationResult =
  Apollo.MutationResult<UpdateMemberDefaultBranchMutation>;
export type UpdateMemberDefaultBranchMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMemberDefaultBranchMutation,
    UpdateMemberDefaultBranchMutationVariables
  >;
export const UpdateMembersDocument = gql`
  mutation UpdateMembers($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      moduleAccessCS
      moduleAccessSC
      currentPostNominal
      dob
      bio
      email
      phone
      study
      externalId
      timezone
      schedulable
      trackFiles
      australianCitizen
      workRights
      workRightsLastCheckedAt
      passportCountry
      passportNumber
      allowedFortnightlyWorkHours
      driversLicense
      driversLicenseCountry
      driversLicenseExpiryDate
      hasCar
      carDescription
      canHoist
      employmentStartDate
      employmentEndDate
      bonusEligible
      remindClock
      remindEvents
      remindNotes
      employementContractExternallySaved
      visaType {
        id
      }
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branches {
        id
        name
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type UpdateMembersMutationFn = Apollo.MutationFunction<
  UpdateMembersMutation,
  UpdateMembersMutationVariables
>;

/**
 * __useUpdateMembersMutation__
 *
 * To run a mutation, you first call `useUpdateMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersMutation, { data, loading, error }] = useUpdateMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersMutation,
    UpdateMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersMutation,
    UpdateMembersMutationVariables
  >(UpdateMembersDocument, options);
}
export type UpdateMembersMutationHookResult = ReturnType<
  typeof useUpdateMembersMutation
>;
export type UpdateMembersMutationResult =
  Apollo.MutationResult<UpdateMembersMutation>;
export type UpdateMembersMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersMutation,
  UpdateMembersMutationVariables
>;
export const UpdateMembersAttributesDocument = gql`
  mutation UpdateMembersAttributes($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      empathyRating
      reliabilityRating
    }
  }
`;
export type UpdateMembersAttributesMutationFn = Apollo.MutationFunction<
  UpdateMembersAttributesMutation,
  UpdateMembersAttributesMutationVariables
>;

/**
 * __useUpdateMembersAttributesMutation__
 *
 * To run a mutation, you first call `useUpdateMembersAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersAttributesMutation, { data, loading, error }] = useUpdateMembersAttributesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersAttributesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersAttributesMutation,
    UpdateMembersAttributesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersAttributesMutation,
    UpdateMembersAttributesMutationVariables
  >(UpdateMembersAttributesDocument, options);
}
export type UpdateMembersAttributesMutationHookResult = ReturnType<
  typeof useUpdateMembersAttributesMutation
>;
export type UpdateMembersAttributesMutationResult =
  Apollo.MutationResult<UpdateMembersAttributesMutation>;
export type UpdateMembersAttributesMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersAttributesMutation,
  UpdateMembersAttributesMutationVariables
>;
export const UpdateMembersAvatarDocument = gql`
  mutation UpdateMembersAvatar($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      avatar {
        id
        path
        size
      }
    }
  }
`;
export type UpdateMembersAvatarMutationFn = Apollo.MutationFunction<
  UpdateMembersAvatarMutation,
  UpdateMembersAvatarMutationVariables
>;

/**
 * __useUpdateMembersAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateMembersAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersAvatarMutation, { data, loading, error }] = useUpdateMembersAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersAvatarMutation,
    UpdateMembersAvatarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersAvatarMutation,
    UpdateMembersAvatarMutationVariables
  >(UpdateMembersAvatarDocument, options);
}
export type UpdateMembersAvatarMutationHookResult = ReturnType<
  typeof useUpdateMembersAvatarMutation
>;
export type UpdateMembersAvatarMutationResult =
  Apollo.MutationResult<UpdateMembersAvatarMutation>;
export type UpdateMembersAvatarMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersAvatarMutation,
  UpdateMembersAvatarMutationVariables
>;
export const UpdateMembersBankDetailsDocument = gql`
  mutation UpdateMembersBankDetails($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      bank {
        bsb
        accountNumber
        accountName
      }
    }
  }
`;
export type UpdateMembersBankDetailsMutationFn = Apollo.MutationFunction<
  UpdateMembersBankDetailsMutation,
  UpdateMembersBankDetailsMutationVariables
>;

/**
 * __useUpdateMembersBankDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersBankDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersBankDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersBankDetailsMutation, { data, loading, error }] = useUpdateMembersBankDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersBankDetailsMutation,
    UpdateMembersBankDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersBankDetailsMutation,
    UpdateMembersBankDetailsMutationVariables
  >(UpdateMembersBankDetailsDocument, options);
}
export type UpdateMembersBankDetailsMutationHookResult = ReturnType<
  typeof useUpdateMembersBankDetailsMutation
>;
export type UpdateMembersBankDetailsMutationResult =
  Apollo.MutationResult<UpdateMembersBankDetailsMutation>;
export type UpdateMembersBankDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMembersBankDetailsMutation,
    UpdateMembersBankDetailsMutationVariables
  >;
export const UpdateMembersBranchesDocument = gql`
  mutation UpdateMembersBranches($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      branches {
        id
        name
      }
    }
  }
`;
export type UpdateMembersBranchesMutationFn = Apollo.MutationFunction<
  UpdateMembersBranchesMutation,
  UpdateMembersBranchesMutationVariables
>;

/**
 * __useUpdateMembersBranchesMutation__
 *
 * To run a mutation, you first call `useUpdateMembersBranchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersBranchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersBranchesMutation, { data, loading, error }] = useUpdateMembersBranchesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersBranchesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersBranchesMutation,
    UpdateMembersBranchesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersBranchesMutation,
    UpdateMembersBranchesMutationVariables
  >(UpdateMembersBranchesDocument, options);
}
export type UpdateMembersBranchesMutationHookResult = ReturnType<
  typeof useUpdateMembersBranchesMutation
>;
export type UpdateMembersBranchesMutationResult =
  Apollo.MutationResult<UpdateMembersBranchesMutation>;
export type UpdateMembersBranchesMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersBranchesMutation,
  UpdateMembersBranchesMutationVariables
>;
export const UpdateMembersColorsDocument = gql`
  mutation UpdateMembersColors($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      color
    }
  }
`;
export type UpdateMembersColorsMutationFn = Apollo.MutationFunction<
  UpdateMembersColorsMutation,
  UpdateMembersColorsMutationVariables
>;

/**
 * __useUpdateMembersColorsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersColorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersColorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersColorsMutation, { data, loading, error }] = useUpdateMembersColorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersColorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersColorsMutation,
    UpdateMembersColorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersColorsMutation,
    UpdateMembersColorsMutationVariables
  >(UpdateMembersColorsDocument, options);
}
export type UpdateMembersColorsMutationHookResult = ReturnType<
  typeof useUpdateMembersColorsMutation
>;
export type UpdateMembersColorsMutationResult =
  Apollo.MutationResult<UpdateMembersColorsMutation>;
export type UpdateMembersColorsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersColorsMutation,
  UpdateMembersColorsMutationVariables
>;
export const UpdateMembersExternalIdsDocument = gql`
  mutation UpdateMembersExternalIds($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      externalId
    }
  }
`;
export type UpdateMembersExternalIdsMutationFn = Apollo.MutationFunction<
  UpdateMembersExternalIdsMutation,
  UpdateMembersExternalIdsMutationVariables
>;

/**
 * __useUpdateMembersExternalIdsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersExternalIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersExternalIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersExternalIdsMutation, { data, loading, error }] = useUpdateMembersExternalIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersExternalIdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersExternalIdsMutation,
    UpdateMembersExternalIdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersExternalIdsMutation,
    UpdateMembersExternalIdsMutationVariables
  >(UpdateMembersExternalIdsDocument, options);
}
export type UpdateMembersExternalIdsMutationHookResult = ReturnType<
  typeof useUpdateMembersExternalIdsMutation
>;
export type UpdateMembersExternalIdsMutationResult =
  Apollo.MutationResult<UpdateMembersExternalIdsMutation>;
export type UpdateMembersExternalIdsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMembersExternalIdsMutation,
    UpdateMembersExternalIdsMutationVariables
  >;
export const UpdateMembersFinancialDetailsDocument = gql`
  mutation UpdateMembersFinancialDetails($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      bank {
        bsb
        accountNumber
        accountName
      }
      super {
        usi
        fundName
        fundAbn
        accountName
        accountNumber
      }
      taxFileNumberDeclaration {
        tfn
        appliedForTfn
        claimingExemptionMinor
        claimingExemptionPension
        salutation
        lastName
        firstName
        middleName
        street
        locality
        region
        postcode
        prevLastName
        email
        dob
        basisOfPay
        areYou
        claimTaxFreeThreshold
        studentLoanDebt
      }
    }
  }
`;
export type UpdateMembersFinancialDetailsMutationFn = Apollo.MutationFunction<
  UpdateMembersFinancialDetailsMutation,
  UpdateMembersFinancialDetailsMutationVariables
>;

/**
 * __useUpdateMembersFinancialDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersFinancialDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersFinancialDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersFinancialDetailsMutation, { data, loading, error }] = useUpdateMembersFinancialDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersFinancialDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersFinancialDetailsMutation,
    UpdateMembersFinancialDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersFinancialDetailsMutation,
    UpdateMembersFinancialDetailsMutationVariables
  >(UpdateMembersFinancialDetailsDocument, options);
}
export type UpdateMembersFinancialDetailsMutationHookResult = ReturnType<
  typeof useUpdateMembersFinancialDetailsMutation
>;
export type UpdateMembersFinancialDetailsMutationResult =
  Apollo.MutationResult<UpdateMembersFinancialDetailsMutation>;
export type UpdateMembersFinancialDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMembersFinancialDetailsMutation,
    UpdateMembersFinancialDetailsMutationVariables
  >;
export const UpdateMembersNotesDocument = gql`
  mutation UpdateMembersNotes($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      notes
      confidentialNotes
    }
  }
`;
export type UpdateMembersNotesMutationFn = Apollo.MutationFunction<
  UpdateMembersNotesMutation,
  UpdateMembersNotesMutationVariables
>;

/**
 * __useUpdateMembersNotesMutation__
 *
 * To run a mutation, you first call `useUpdateMembersNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersNotesMutation, { data, loading, error }] = useUpdateMembersNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersNotesMutation,
    UpdateMembersNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersNotesMutation,
    UpdateMembersNotesMutationVariables
  >(UpdateMembersNotesDocument, options);
}
export type UpdateMembersNotesMutationHookResult = ReturnType<
  typeof useUpdateMembersNotesMutation
>;
export type UpdateMembersNotesMutationResult =
  Apollo.MutationResult<UpdateMembersNotesMutation>;
export type UpdateMembersNotesMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembersNotesMutation,
  UpdateMembersNotesMutationVariables
>;
export const UpdateMembersPermissionsDocument = gql`
  mutation UpdateMembersPermissions($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      admin
      superAdmin
      permissions
    }
  }
`;
export type UpdateMembersPermissionsMutationFn = Apollo.MutationFunction<
  UpdateMembersPermissionsMutation,
  UpdateMembersPermissionsMutationVariables
>;

/**
 * __useUpdateMembersPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersPermissionsMutation, { data, loading, error }] = useUpdateMembersPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersPermissionsMutation,
    UpdateMembersPermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersPermissionsMutation,
    UpdateMembersPermissionsMutationVariables
  >(UpdateMembersPermissionsDocument, options);
}
export type UpdateMembersPermissionsMutationHookResult = ReturnType<
  typeof useUpdateMembersPermissionsMutation
>;
export type UpdateMembersPermissionsMutationResult =
  Apollo.MutationResult<UpdateMembersPermissionsMutation>;
export type UpdateMembersPermissionsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMembersPermissionsMutation,
    UpdateMembersPermissionsMutationVariables
  >;
export const UpdateMembersScheduleMaxTimeLimitDefaultsDocument = gql`
  mutation UpdateMembersScheduleMaxTimeLimitDefaults(
    $input: UpdateMembersInput!
  ) {
    updateMembers(input: $input) {
      id
      scheduleMaxDayTimeLimitDefault
      scheduleMaxWeekTimeLimitDefault
      scheduleMaxWeekTimeLimitCurrent
    }
  }
`;
export type UpdateMembersScheduleMaxTimeLimitDefaultsMutationFn =
  Apollo.MutationFunction<
    UpdateMembersScheduleMaxTimeLimitDefaultsMutation,
    UpdateMembersScheduleMaxTimeLimitDefaultsMutationVariables
  >;

/**
 * __useUpdateMembersScheduleMaxTimeLimitDefaultsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersScheduleMaxTimeLimitDefaultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersScheduleMaxTimeLimitDefaultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersScheduleMaxTimeLimitDefaultsMutation, { data, loading, error }] = useUpdateMembersScheduleMaxTimeLimitDefaultsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersScheduleMaxTimeLimitDefaultsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersScheduleMaxTimeLimitDefaultsMutation,
    UpdateMembersScheduleMaxTimeLimitDefaultsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersScheduleMaxTimeLimitDefaultsMutation,
    UpdateMembersScheduleMaxTimeLimitDefaultsMutationVariables
  >(UpdateMembersScheduleMaxTimeLimitDefaultsDocument, options);
}
export type UpdateMembersScheduleMaxTimeLimitDefaultsMutationHookResult =
  ReturnType<typeof useUpdateMembersScheduleMaxTimeLimitDefaultsMutation>;
export type UpdateMembersScheduleMaxTimeLimitDefaultsMutationResult =
  Apollo.MutationResult<UpdateMembersScheduleMaxTimeLimitDefaultsMutation>;
export type UpdateMembersScheduleMaxTimeLimitDefaultsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMembersScheduleMaxTimeLimitDefaultsMutation,
    UpdateMembersScheduleMaxTimeLimitDefaultsMutationVariables
  >;
export const UpdateMembersSuperDetailsDocument = gql`
  mutation UpdateMembersSuperDetails($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      super {
        usi
        fundName
        fundAbn
        accountName
        accountNumber
      }
    }
  }
`;
export type UpdateMembersSuperDetailsMutationFn = Apollo.MutationFunction<
  UpdateMembersSuperDetailsMutation,
  UpdateMembersSuperDetailsMutationVariables
>;

/**
 * __useUpdateMembersSuperDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersSuperDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersSuperDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersSuperDetailsMutation, { data, loading, error }] = useUpdateMembersSuperDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersSuperDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersSuperDetailsMutation,
    UpdateMembersSuperDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersSuperDetailsMutation,
    UpdateMembersSuperDetailsMutationVariables
  >(UpdateMembersSuperDetailsDocument, options);
}
export type UpdateMembersSuperDetailsMutationHookResult = ReturnType<
  typeof useUpdateMembersSuperDetailsMutation
>;
export type UpdateMembersSuperDetailsMutationResult =
  Apollo.MutationResult<UpdateMembersSuperDetailsMutation>;
export type UpdateMembersSuperDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMembersSuperDetailsMutation,
    UpdateMembersSuperDetailsMutationVariables
  >;
export const UpdateMembersTaxFileNumberDeclarationDetailsDocument = gql`
  mutation UpdateMembersTaxFileNumberDeclarationDetails(
    $input: UpdateMembersInput!
  ) {
    updateMembers(input: $input) {
      id
      taxFileNumberDeclaration {
        tfn
        appliedForTfn
        claimingExemptionMinor
        claimingExemptionPension
        salutation
        lastName
        firstName
        middleName
        street
        locality
        region
        postcode
        prevLastName
        email
        dob
        basisOfPay
        areYou
        claimTaxFreeThreshold
        studentLoanDebt
      }
    }
  }
`;
export type UpdateMembersTaxFileNumberDeclarationDetailsMutationFn =
  Apollo.MutationFunction<
    UpdateMembersTaxFileNumberDeclarationDetailsMutation,
    UpdateMembersTaxFileNumberDeclarationDetailsMutationVariables
  >;

/**
 * __useUpdateMembersTaxFileNumberDeclarationDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMembersTaxFileNumberDeclarationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersTaxFileNumberDeclarationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersTaxFileNumberDeclarationDetailsMutation, { data, loading, error }] = useUpdateMembersTaxFileNumberDeclarationDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersTaxFileNumberDeclarationDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersTaxFileNumberDeclarationDetailsMutation,
    UpdateMembersTaxFileNumberDeclarationDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersTaxFileNumberDeclarationDetailsMutation,
    UpdateMembersTaxFileNumberDeclarationDetailsMutationVariables
  >(UpdateMembersTaxFileNumberDeclarationDetailsDocument, options);
}
export type UpdateMembersTaxFileNumberDeclarationDetailsMutationHookResult =
  ReturnType<typeof useUpdateMembersTaxFileNumberDeclarationDetailsMutation>;
export type UpdateMembersTaxFileNumberDeclarationDetailsMutationResult =
  Apollo.MutationResult<UpdateMembersTaxFileNumberDeclarationDetailsMutation>;
export type UpdateMembersTaxFileNumberDeclarationDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMembersTaxFileNumberDeclarationDetailsMutation,
    UpdateMembersTaxFileNumberDeclarationDetailsMutationVariables
  >;
export const UpdateMembersTypicalUnavailabilityDocument = gql`
  mutation UpdateMembersTypicalUnavailability($input: UpdateMembersInput!) {
    updateMembers(input: $input) {
      id
      unavailableNotes
      unavailableMoMorn
      unavailableMoDay
      unavailableMoNight
      unavailableTuMorn
      unavailableTuDay
      unavailableTuNight
      unavailableWeMorn
      unavailableWeDay
      unavailableWeNight
      unavailableThMorn
      unavailableThDay
      unavailableThNight
      unavailableFrMorn
      unavailableFrDay
      unavailableFrNight
      unavailableSaMorn
      unavailableSaDay
      unavailableSaNight
      unavailableSuMorn
      unavailableSuDay
      unavailableSuNight
    }
  }
`;
export type UpdateMembersTypicalUnavailabilityMutationFn =
  Apollo.MutationFunction<
    UpdateMembersTypicalUnavailabilityMutation,
    UpdateMembersTypicalUnavailabilityMutationVariables
  >;

/**
 * __useUpdateMembersTypicalUnavailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateMembersTypicalUnavailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersTypicalUnavailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersTypicalUnavailabilityMutation, { data, loading, error }] = useUpdateMembersTypicalUnavailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMembersTypicalUnavailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembersTypicalUnavailabilityMutation,
    UpdateMembersTypicalUnavailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembersTypicalUnavailabilityMutation,
    UpdateMembersTypicalUnavailabilityMutationVariables
  >(UpdateMembersTypicalUnavailabilityDocument, options);
}
export type UpdateMembersTypicalUnavailabilityMutationHookResult = ReturnType<
  typeof useUpdateMembersTypicalUnavailabilityMutation
>;
export type UpdateMembersTypicalUnavailabilityMutationResult =
  Apollo.MutationResult<UpdateMembersTypicalUnavailabilityMutation>;
export type UpdateMembersTypicalUnavailabilityMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMembersTypicalUnavailabilityMutation,
    UpdateMembersTypicalUnavailabilityMutationVariables
  >;
export const ArchiveNdisCoordinationClientsDocument = gql`
  mutation ArchiveNdisCoordinationClients($input: ArchiveInput!) {
    archiveNdisCoordinationClients(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveNdisCoordinationClientsMutationFn = Apollo.MutationFunction<
  ArchiveNdisCoordinationClientsMutation,
  ArchiveNdisCoordinationClientsMutationVariables
>;

/**
 * __useArchiveNdisCoordinationClientsMutation__
 *
 * To run a mutation, you first call `useArchiveNdisCoordinationClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveNdisCoordinationClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveNdisCoordinationClientsMutation, { data, loading, error }] = useArchiveNdisCoordinationClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveNdisCoordinationClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveNdisCoordinationClientsMutation,
    ArchiveNdisCoordinationClientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveNdisCoordinationClientsMutation,
    ArchiveNdisCoordinationClientsMutationVariables
  >(ArchiveNdisCoordinationClientsDocument, options);
}
export type ArchiveNdisCoordinationClientsMutationHookResult = ReturnType<
  typeof useArchiveNdisCoordinationClientsMutation
>;
export type ArchiveNdisCoordinationClientsMutationResult =
  Apollo.MutationResult<ArchiveNdisCoordinationClientsMutation>;
export type ArchiveNdisCoordinationClientsMutationOptions =
  Apollo.BaseMutationOptions<
    ArchiveNdisCoordinationClientsMutation,
    ArchiveNdisCoordinationClientsMutationVariables
  >;
export const CreateNdisCoordinationClientDocument = gql`
  mutation CreateNdisCoordinationClient(
    $input: CreateNdisCoordinationClientInput!
  ) {
    createNdisCoordinationClient(input: $input) {
      id
      email
      phone
      dob
      color
      ndisId
      timezone
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      planManager {
        id
        name
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type CreateNdisCoordinationClientMutationFn = Apollo.MutationFunction<
  CreateNdisCoordinationClientMutation,
  CreateNdisCoordinationClientMutationVariables
>;

/**
 * __useCreateNdisCoordinationClientMutation__
 *
 * To run a mutation, you first call `useCreateNdisCoordinationClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNdisCoordinationClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNdisCoordinationClientMutation, { data, loading, error }] = useCreateNdisCoordinationClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNdisCoordinationClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNdisCoordinationClientMutation,
    CreateNdisCoordinationClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNdisCoordinationClientMutation,
    CreateNdisCoordinationClientMutationVariables
  >(CreateNdisCoordinationClientDocument, options);
}
export type CreateNdisCoordinationClientMutationHookResult = ReturnType<
  typeof useCreateNdisCoordinationClientMutation
>;
export type CreateNdisCoordinationClientMutationResult =
  Apollo.MutationResult<CreateNdisCoordinationClientMutation>;
export type CreateNdisCoordinationClientMutationOptions =
  Apollo.BaseMutationOptions<
    CreateNdisCoordinationClientMutation,
    CreateNdisCoordinationClientMutationVariables
  >;
export const RestoreNdisCoordinationClientsDocument = gql`
  mutation RestoreNdisCoordinationClients($input: QueryByIdsInput!) {
    restoreNdisCoordinationClients(input: $input) {
      id
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreNdisCoordinationClientsMutationFn = Apollo.MutationFunction<
  RestoreNdisCoordinationClientsMutation,
  RestoreNdisCoordinationClientsMutationVariables
>;

/**
 * __useRestoreNdisCoordinationClientsMutation__
 *
 * To run a mutation, you first call `useRestoreNdisCoordinationClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreNdisCoordinationClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreNdisCoordinationClientsMutation, { data, loading, error }] = useRestoreNdisCoordinationClientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreNdisCoordinationClientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreNdisCoordinationClientsMutation,
    RestoreNdisCoordinationClientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreNdisCoordinationClientsMutation,
    RestoreNdisCoordinationClientsMutationVariables
  >(RestoreNdisCoordinationClientsDocument, options);
}
export type RestoreNdisCoordinationClientsMutationHookResult = ReturnType<
  typeof useRestoreNdisCoordinationClientsMutation
>;
export type RestoreNdisCoordinationClientsMutationResult =
  Apollo.MutationResult<RestoreNdisCoordinationClientsMutation>;
export type RestoreNdisCoordinationClientsMutationOptions =
  Apollo.BaseMutationOptions<
    RestoreNdisCoordinationClientsMutation,
    RestoreNdisCoordinationClientsMutationVariables
  >;
export const UpdateNdisCoordinationClientDocument = gql`
  mutation UpdateNdisCoordinationClient(
    $input: UpdateNdisCoordinationClientInput!
  ) {
    updateNdisCoordinationClient(input: $input) {
      id
      email
      phone
      bio
      dob
      color
      timezone
      ndisId
      externalId
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      planManager {
        id
        name
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type UpdateNdisCoordinationClientMutationFn = Apollo.MutationFunction<
  UpdateNdisCoordinationClientMutation,
  UpdateNdisCoordinationClientMutationVariables
>;

/**
 * __useUpdateNdisCoordinationClientMutation__
 *
 * To run a mutation, you first call `useUpdateNdisCoordinationClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNdisCoordinationClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNdisCoordinationClientMutation, { data, loading, error }] = useUpdateNdisCoordinationClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNdisCoordinationClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNdisCoordinationClientMutation,
    UpdateNdisCoordinationClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNdisCoordinationClientMutation,
    UpdateNdisCoordinationClientMutationVariables
  >(UpdateNdisCoordinationClientDocument, options);
}
export type UpdateNdisCoordinationClientMutationHookResult = ReturnType<
  typeof useUpdateNdisCoordinationClientMutation
>;
export type UpdateNdisCoordinationClientMutationResult =
  Apollo.MutationResult<UpdateNdisCoordinationClientMutation>;
export type UpdateNdisCoordinationClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNdisCoordinationClientMutation,
    UpdateNdisCoordinationClientMutationVariables
  >;
export const UpdateNdisCoordinationClientColorDocument = gql`
  mutation UpdateNdisCoordinationClientColor(
    $input: UpdateNdisCoordinationClientInput!
  ) {
    updateNdisCoordinationClient(input: $input) {
      id
      color
    }
  }
`;
export type UpdateNdisCoordinationClientColorMutationFn =
  Apollo.MutationFunction<
    UpdateNdisCoordinationClientColorMutation,
    UpdateNdisCoordinationClientColorMutationVariables
  >;

/**
 * __useUpdateNdisCoordinationClientColorMutation__
 *
 * To run a mutation, you first call `useUpdateNdisCoordinationClientColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNdisCoordinationClientColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNdisCoordinationClientColorMutation, { data, loading, error }] = useUpdateNdisCoordinationClientColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNdisCoordinationClientColorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNdisCoordinationClientColorMutation,
    UpdateNdisCoordinationClientColorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNdisCoordinationClientColorMutation,
    UpdateNdisCoordinationClientColorMutationVariables
  >(UpdateNdisCoordinationClientColorDocument, options);
}
export type UpdateNdisCoordinationClientColorMutationHookResult = ReturnType<
  typeof useUpdateNdisCoordinationClientColorMutation
>;
export type UpdateNdisCoordinationClientColorMutationResult =
  Apollo.MutationResult<UpdateNdisCoordinationClientColorMutation>;
export type UpdateNdisCoordinationClientColorMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNdisCoordinationClientColorMutation,
    UpdateNdisCoordinationClientColorMutationVariables
  >;
export const UpdateNdisCoordinationClientPersonalDetailsDocument = gql`
  mutation UpdateNdisCoordinationClientPersonalDetails(
    $input: UpdateNdisCoordinationClientInput!
  ) {
    updateNdisCoordinationClient(input: $input) {
      id
      email
      phone
      bio
      dob
      color
      externalId
      timezone
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;
export type UpdateNdisCoordinationClientPersonalDetailsMutationFn =
  Apollo.MutationFunction<
    UpdateNdisCoordinationClientPersonalDetailsMutation,
    UpdateNdisCoordinationClientPersonalDetailsMutationVariables
  >;

/**
 * __useUpdateNdisCoordinationClientPersonalDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateNdisCoordinationClientPersonalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNdisCoordinationClientPersonalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNdisCoordinationClientPersonalDetailsMutation, { data, loading, error }] = useUpdateNdisCoordinationClientPersonalDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNdisCoordinationClientPersonalDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNdisCoordinationClientPersonalDetailsMutation,
    UpdateNdisCoordinationClientPersonalDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNdisCoordinationClientPersonalDetailsMutation,
    UpdateNdisCoordinationClientPersonalDetailsMutationVariables
  >(UpdateNdisCoordinationClientPersonalDetailsDocument, options);
}
export type UpdateNdisCoordinationClientPersonalDetailsMutationHookResult =
  ReturnType<typeof useUpdateNdisCoordinationClientPersonalDetailsMutation>;
export type UpdateNdisCoordinationClientPersonalDetailsMutationResult =
  Apollo.MutationResult<UpdateNdisCoordinationClientPersonalDetailsMutation>;
export type UpdateNdisCoordinationClientPersonalDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateNdisCoordinationClientPersonalDetailsMutation,
    UpdateNdisCoordinationClientPersonalDetailsMutationVariables
  >;
export const CreateOrgDocument = gql`
  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      id
      abn
      email
      phone
      website
      ...BusinessNames
      branches {
        id
        name
      }
    }
  }
  ${BusinessNamesFragmentDoc}
`;
export type CreateOrgMutationFn = Apollo.MutationFunction<
  CreateOrgMutation,
  CreateOrgMutationVariables
>;

/**
 * __useCreateOrgMutation__
 *
 * To run a mutation, you first call `useCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMutation, { data, loading, error }] = useCreateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrgMutation,
    CreateOrgMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrgMutation, CreateOrgMutationVariables>(
    CreateOrgDocument,
    options,
  );
}
export type CreateOrgMutationHookResult = ReturnType<
  typeof useCreateOrgMutation
>;
export type CreateOrgMutationResult = Apollo.MutationResult<CreateOrgMutation>;
export type CreateOrgMutationOptions = Apollo.BaseMutationOptions<
  CreateOrgMutation,
  CreateOrgMutationVariables
>;
export const UpdateOrgBillingDocument = gql`
  mutation UpdateOrgBilling($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      billing {
        day
        afternoon
        night
        saturday
        sunday
        publicHoliday
        overnight
        kms
      }
    }
  }
`;
export type UpdateOrgBillingMutationFn = Apollo.MutationFunction<
  UpdateOrgBillingMutation,
  UpdateOrgBillingMutationVariables
>;

/**
 * __useUpdateOrgBillingMutation__
 *
 * To run a mutation, you first call `useUpdateOrgBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgBillingMutation, { data, loading, error }] = useUpdateOrgBillingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgBillingMutation,
    UpdateOrgBillingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgBillingMutation,
    UpdateOrgBillingMutationVariables
  >(UpdateOrgBillingDocument, options);
}
export type UpdateOrgBillingMutationHookResult = ReturnType<
  typeof useUpdateOrgBillingMutation
>;
export type UpdateOrgBillingMutationResult =
  Apollo.MutationResult<UpdateOrgBillingMutation>;
export type UpdateOrgBillingMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgBillingMutation,
  UpdateOrgBillingMutationVariables
>;
export const UpdateOrgClientDocumentTrackerCollapsedFilesDocument = gql`
  mutation UpdateOrgClientDocumentTrackerCollapsedFiles(
    $input: UpdateOrgInput!
  ) {
    updateOrg(input: $input) {
      id
      clientDocumentTrackerCollapsedFiles
    }
  }
`;
export type UpdateOrgClientDocumentTrackerCollapsedFilesMutationFn =
  Apollo.MutationFunction<
    UpdateOrgClientDocumentTrackerCollapsedFilesMutation,
    UpdateOrgClientDocumentTrackerCollapsedFilesMutationVariables
  >;

/**
 * __useUpdateOrgClientDocumentTrackerCollapsedFilesMutation__
 *
 * To run a mutation, you first call `useUpdateOrgClientDocumentTrackerCollapsedFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgClientDocumentTrackerCollapsedFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgClientDocumentTrackerCollapsedFilesMutation, { data, loading, error }] = useUpdateOrgClientDocumentTrackerCollapsedFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgClientDocumentTrackerCollapsedFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgClientDocumentTrackerCollapsedFilesMutation,
    UpdateOrgClientDocumentTrackerCollapsedFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgClientDocumentTrackerCollapsedFilesMutation,
    UpdateOrgClientDocumentTrackerCollapsedFilesMutationVariables
  >(UpdateOrgClientDocumentTrackerCollapsedFilesDocument, options);
}
export type UpdateOrgClientDocumentTrackerCollapsedFilesMutationHookResult =
  ReturnType<typeof useUpdateOrgClientDocumentTrackerCollapsedFilesMutation>;
export type UpdateOrgClientDocumentTrackerCollapsedFilesMutationResult =
  Apollo.MutationResult<UpdateOrgClientDocumentTrackerCollapsedFilesMutation>;
export type UpdateOrgClientDocumentTrackerCollapsedFilesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrgClientDocumentTrackerCollapsedFilesMutation,
    UpdateOrgClientDocumentTrackerCollapsedFilesMutationVariables
  >;
export const UpdateOrgClientDocumentTrackerVisibleFilesDocument = gql`
  mutation UpdateOrgClientDocumentTrackerVisibleFiles($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      clientDocumentTrackerVisibleFiles
    }
  }
`;
export type UpdateOrgClientDocumentTrackerVisibleFilesMutationFn =
  Apollo.MutationFunction<
    UpdateOrgClientDocumentTrackerVisibleFilesMutation,
    UpdateOrgClientDocumentTrackerVisibleFilesMutationVariables
  >;

/**
 * __useUpdateOrgClientDocumentTrackerVisibleFilesMutation__
 *
 * To run a mutation, you first call `useUpdateOrgClientDocumentTrackerVisibleFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgClientDocumentTrackerVisibleFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgClientDocumentTrackerVisibleFilesMutation, { data, loading, error }] = useUpdateOrgClientDocumentTrackerVisibleFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgClientDocumentTrackerVisibleFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgClientDocumentTrackerVisibleFilesMutation,
    UpdateOrgClientDocumentTrackerVisibleFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgClientDocumentTrackerVisibleFilesMutation,
    UpdateOrgClientDocumentTrackerVisibleFilesMutationVariables
  >(UpdateOrgClientDocumentTrackerVisibleFilesDocument, options);
}
export type UpdateOrgClientDocumentTrackerVisibleFilesMutationHookResult =
  ReturnType<typeof useUpdateOrgClientDocumentTrackerVisibleFilesMutation>;
export type UpdateOrgClientDocumentTrackerVisibleFilesMutationResult =
  Apollo.MutationResult<UpdateOrgClientDocumentTrackerVisibleFilesMutation>;
export type UpdateOrgClientDocumentTrackerVisibleFilesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrgClientDocumentTrackerVisibleFilesMutation,
    UpdateOrgClientDocumentTrackerVisibleFilesMutationVariables
  >;
export const UpdateOrgFixedExpensesDocument = gql`
  mutation UpdateOrgFixedExpenses($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      fixedExpenses
    }
  }
`;
export type UpdateOrgFixedExpensesMutationFn = Apollo.MutationFunction<
  UpdateOrgFixedExpensesMutation,
  UpdateOrgFixedExpensesMutationVariables
>;

/**
 * __useUpdateOrgFixedExpensesMutation__
 *
 * To run a mutation, you first call `useUpdateOrgFixedExpensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgFixedExpensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgFixedExpensesMutation, { data, loading, error }] = useUpdateOrgFixedExpensesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgFixedExpensesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgFixedExpensesMutation,
    UpdateOrgFixedExpensesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgFixedExpensesMutation,
    UpdateOrgFixedExpensesMutationVariables
  >(UpdateOrgFixedExpensesDocument, options);
}
export type UpdateOrgFixedExpensesMutationHookResult = ReturnType<
  typeof useUpdateOrgFixedExpensesMutation
>;
export type UpdateOrgFixedExpensesMutationResult =
  Apollo.MutationResult<UpdateOrgFixedExpensesMutation>;
export type UpdateOrgFixedExpensesMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgFixedExpensesMutation,
  UpdateOrgFixedExpensesMutationVariables
>;
export const UpdateOrgIdeasDocument = gql`
  mutation UpdateOrgIdeas($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      ideas
    }
  }
`;
export type UpdateOrgIdeasMutationFn = Apollo.MutationFunction<
  UpdateOrgIdeasMutation,
  UpdateOrgIdeasMutationVariables
>;

/**
 * __useUpdateOrgIdeasMutation__
 *
 * To run a mutation, you first call `useUpdateOrgIdeasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgIdeasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgIdeasMutation, { data, loading, error }] = useUpdateOrgIdeasMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgIdeasMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgIdeasMutation,
    UpdateOrgIdeasMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgIdeasMutation,
    UpdateOrgIdeasMutationVariables
  >(UpdateOrgIdeasDocument, options);
}
export type UpdateOrgIdeasMutationHookResult = ReturnType<
  typeof useUpdateOrgIdeasMutation
>;
export type UpdateOrgIdeasMutationResult =
  Apollo.MutationResult<UpdateOrgIdeasMutation>;
export type UpdateOrgIdeasMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgIdeasMutation,
  UpdateOrgIdeasMutationVariables
>;
export const UpdateOrgMemberDocumentTrackerCollapsedFilesDocument = gql`
  mutation UpdateOrgMemberDocumentTrackerCollapsedFiles(
    $input: UpdateOrgInput!
  ) {
    updateOrg(input: $input) {
      id
      memberDocumentTrackerCollapsedFiles
    }
  }
`;
export type UpdateOrgMemberDocumentTrackerCollapsedFilesMutationFn =
  Apollo.MutationFunction<
    UpdateOrgMemberDocumentTrackerCollapsedFilesMutation,
    UpdateOrgMemberDocumentTrackerCollapsedFilesMutationVariables
  >;

/**
 * __useUpdateOrgMemberDocumentTrackerCollapsedFilesMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMemberDocumentTrackerCollapsedFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMemberDocumentTrackerCollapsedFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMemberDocumentTrackerCollapsedFilesMutation, { data, loading, error }] = useUpdateOrgMemberDocumentTrackerCollapsedFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgMemberDocumentTrackerCollapsedFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgMemberDocumentTrackerCollapsedFilesMutation,
    UpdateOrgMemberDocumentTrackerCollapsedFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgMemberDocumentTrackerCollapsedFilesMutation,
    UpdateOrgMemberDocumentTrackerCollapsedFilesMutationVariables
  >(UpdateOrgMemberDocumentTrackerCollapsedFilesDocument, options);
}
export type UpdateOrgMemberDocumentTrackerCollapsedFilesMutationHookResult =
  ReturnType<typeof useUpdateOrgMemberDocumentTrackerCollapsedFilesMutation>;
export type UpdateOrgMemberDocumentTrackerCollapsedFilesMutationResult =
  Apollo.MutationResult<UpdateOrgMemberDocumentTrackerCollapsedFilesMutation>;
export type UpdateOrgMemberDocumentTrackerCollapsedFilesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrgMemberDocumentTrackerCollapsedFilesMutation,
    UpdateOrgMemberDocumentTrackerCollapsedFilesMutationVariables
  >;
export const UpdateOrgMemberDocumentTrackerVisibleFilesDocument = gql`
  mutation UpdateOrgMemberDocumentTrackerVisibleFiles($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      memberDocumentTrackerVisibleFiles
    }
  }
`;
export type UpdateOrgMemberDocumentTrackerVisibleFilesMutationFn =
  Apollo.MutationFunction<
    UpdateOrgMemberDocumentTrackerVisibleFilesMutation,
    UpdateOrgMemberDocumentTrackerVisibleFilesMutationVariables
  >;

/**
 * __useUpdateOrgMemberDocumentTrackerVisibleFilesMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMemberDocumentTrackerVisibleFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMemberDocumentTrackerVisibleFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMemberDocumentTrackerVisibleFilesMutation, { data, loading, error }] = useUpdateOrgMemberDocumentTrackerVisibleFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgMemberDocumentTrackerVisibleFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgMemberDocumentTrackerVisibleFilesMutation,
    UpdateOrgMemberDocumentTrackerVisibleFilesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgMemberDocumentTrackerVisibleFilesMutation,
    UpdateOrgMemberDocumentTrackerVisibleFilesMutationVariables
  >(UpdateOrgMemberDocumentTrackerVisibleFilesDocument, options);
}
export type UpdateOrgMemberDocumentTrackerVisibleFilesMutationHookResult =
  ReturnType<typeof useUpdateOrgMemberDocumentTrackerVisibleFilesMutation>;
export type UpdateOrgMemberDocumentTrackerVisibleFilesMutationResult =
  Apollo.MutationResult<UpdateOrgMemberDocumentTrackerVisibleFilesMutation>;
export type UpdateOrgMemberDocumentTrackerVisibleFilesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOrgMemberDocumentTrackerVisibleFilesMutation,
    UpdateOrgMemberDocumentTrackerVisibleFilesMutationVariables
  >;
export const UpdateOrgPayrollDocument = gql`
  mutation UpdateOrgPayroll($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      basePayRate
      afternoonPayMultiplier
      nightPayMultiplier
      satPayMultiplier
      sunPayMultiplier
      pubPayMultiplier
      passiveAllowance
      kmAllowance
      bonus2PayRate
      bonus3PayRate
      bonus4PayRate
      bonus5PayRate
      bonus6PayRate
      bonus7PayRate
      bonus8PayRate
      bonus9PayRate
      bonus10PayRate
      bonus11PayRate
      bonus12PayRate
    }
  }
`;
export type UpdateOrgPayrollMutationFn = Apollo.MutationFunction<
  UpdateOrgPayrollMutation,
  UpdateOrgPayrollMutationVariables
>;

/**
 * __useUpdateOrgPayrollMutation__
 *
 * To run a mutation, you first call `useUpdateOrgPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgPayrollMutation, { data, loading, error }] = useUpdateOrgPayrollMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgPayrollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgPayrollMutation,
    UpdateOrgPayrollMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgPayrollMutation,
    UpdateOrgPayrollMutationVariables
  >(UpdateOrgPayrollDocument, options);
}
export type UpdateOrgPayrollMutationHookResult = ReturnType<
  typeof useUpdateOrgPayrollMutation
>;
export type UpdateOrgPayrollMutationResult =
  Apollo.MutationResult<UpdateOrgPayrollMutation>;
export type UpdateOrgPayrollMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgPayrollMutation,
  UpdateOrgPayrollMutationVariables
>;
export const UpdateOrgSchedulingDocument = gql`
  mutation UpdateOrgScheduling($input: UpdateOrgInput!) {
    updateOrg(input: $input) {
      id
      taskAssignAttendeeEnable
      taskAssignAttendeeFutureDays
      taskAssignAttendeePastWeeks
      membersCanSeeClockedTimes
      quantityOfFutureShiftsMembersCanSee
      quantityOfPastShiftsMembersCanSee
      unfilledEventsDayRange
      clockOnWarnMinutes
      clockOffWarnMinutes
      allowedDurationBetweenConflicts
      travelTimeAllowSameClient
      travelTimeAllowedDurationBetweenEvents
      claimEventsDayRange
      scheduleMaxDayTimeLimitDefault
      scheduleMaxWeekTimeLimitDefault
      maxMemberLatenessForAutomaticSundayEvents
      maxMemberLatenessForAutomaticSaturdayEvents
      maxMemberLatenessForAutomaticPublicHolidayEvents
      maxMemberLatenessForAutomaticHandoverEvents
      autoArchiveClockedOffEventsAfterNumberOfHours
      schedulingContactPerson {
        id
      }
      schedulingContactPerson2 {
        id
      }
    }
  }
`;
export type UpdateOrgSchedulingMutationFn = Apollo.MutationFunction<
  UpdateOrgSchedulingMutation,
  UpdateOrgSchedulingMutationVariables
>;

/**
 * __useUpdateOrgSchedulingMutation__
 *
 * To run a mutation, you first call `useUpdateOrgSchedulingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgSchedulingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgSchedulingMutation, { data, loading, error }] = useUpdateOrgSchedulingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgSchedulingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgSchedulingMutation,
    UpdateOrgSchedulingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrgSchedulingMutation,
    UpdateOrgSchedulingMutationVariables
  >(UpdateOrgSchedulingDocument, options);
}
export type UpdateOrgSchedulingMutationHookResult = ReturnType<
  typeof useUpdateOrgSchedulingMutation
>;
export type UpdateOrgSchedulingMutationResult =
  Apollo.MutationResult<UpdateOrgSchedulingMutation>;
export type UpdateOrgSchedulingMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgSchedulingMutation,
  UpdateOrgSchedulingMutationVariables
>;
export const ArchivePlanManagersDocument = gql`
  mutation ArchivePlanManagers($input: ArchiveInput!) {
    archivePlanManagers(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchivePlanManagersMutationFn = Apollo.MutationFunction<
  ArchivePlanManagersMutation,
  ArchivePlanManagersMutationVariables
>;

/**
 * __useArchivePlanManagersMutation__
 *
 * To run a mutation, you first call `useArchivePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePlanManagersMutation, { data, loading, error }] = useArchivePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchivePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchivePlanManagersMutation,
    ArchivePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchivePlanManagersMutation,
    ArchivePlanManagersMutationVariables
  >(ArchivePlanManagersDocument, options);
}
export type ArchivePlanManagersMutationHookResult = ReturnType<
  typeof useArchivePlanManagersMutation
>;
export type ArchivePlanManagersMutationResult =
  Apollo.MutationResult<ArchivePlanManagersMutation>;
export type ArchivePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  ArchivePlanManagersMutation,
  ArchivePlanManagersMutationVariables
>;
export const CreatePlanManagersDocument = gql`
  mutation CreatePlanManagers($input: CreatePlanManagersInput!) {
    createPlanManagers(input: $input) {
      id
      abn
      name
      salutation
      phone
      businessName
      contactEmail
      billingEmail
      notes
    }
  }
`;
export type CreatePlanManagersMutationFn = Apollo.MutationFunction<
  CreatePlanManagersMutation,
  CreatePlanManagersMutationVariables
>;

/**
 * __useCreatePlanManagersMutation__
 *
 * To run a mutation, you first call `useCreatePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanManagersMutation, { data, loading, error }] = useCreatePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlanManagersMutation,
    CreatePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePlanManagersMutation,
    CreatePlanManagersMutationVariables
  >(CreatePlanManagersDocument, options);
}
export type CreatePlanManagersMutationHookResult = ReturnType<
  typeof useCreatePlanManagersMutation
>;
export type CreatePlanManagersMutationResult =
  Apollo.MutationResult<CreatePlanManagersMutation>;
export type CreatePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  CreatePlanManagersMutation,
  CreatePlanManagersMutationVariables
>;
export const DeletePlanManagersDocument = gql`
  mutation DeletePlanManagers($input: DeleteInput!) {
    deletePlanManagers(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeletePlanManagersMutationFn = Apollo.MutationFunction<
  DeletePlanManagersMutation,
  DeletePlanManagersMutationVariables
>;

/**
 * __useDeletePlanManagersMutation__
 *
 * To run a mutation, you first call `useDeletePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanManagersMutation, { data, loading, error }] = useDeletePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlanManagersMutation,
    DeletePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePlanManagersMutation,
    DeletePlanManagersMutationVariables
  >(DeletePlanManagersDocument, options);
}
export type DeletePlanManagersMutationHookResult = ReturnType<
  typeof useDeletePlanManagersMutation
>;
export type DeletePlanManagersMutationResult =
  Apollo.MutationResult<DeletePlanManagersMutation>;
export type DeletePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  DeletePlanManagersMutation,
  DeletePlanManagersMutationVariables
>;
export const RestorePlanManagersDocument = gql`
  mutation RestorePlanManagers($input: QueryByIdsInput!) {
    restorePlanManagers(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestorePlanManagersMutationFn = Apollo.MutationFunction<
  RestorePlanManagersMutation,
  RestorePlanManagersMutationVariables
>;

/**
 * __useRestorePlanManagersMutation__
 *
 * To run a mutation, you first call `useRestorePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePlanManagersMutation, { data, loading, error }] = useRestorePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestorePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestorePlanManagersMutation,
    RestorePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestorePlanManagersMutation,
    RestorePlanManagersMutationVariables
  >(RestorePlanManagersDocument, options);
}
export type RestorePlanManagersMutationHookResult = ReturnType<
  typeof useRestorePlanManagersMutation
>;
export type RestorePlanManagersMutationResult =
  Apollo.MutationResult<RestorePlanManagersMutation>;
export type RestorePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  RestorePlanManagersMutation,
  RestorePlanManagersMutationVariables
>;
export const UpdatePlanManagersDocument = gql`
  mutation UpdatePlanManagers($input: UpdatePlanManagersInput!) {
    updatePlanManagers(input: $input) {
      id
      abn
      name
      salutation
      phone
      businessName
      contactEmail
      billingEmail
      notes
    }
  }
`;
export type UpdatePlanManagersMutationFn = Apollo.MutationFunction<
  UpdatePlanManagersMutation,
  UpdatePlanManagersMutationVariables
>;

/**
 * __useUpdatePlanManagersMutation__
 *
 * To run a mutation, you first call `useUpdatePlanManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanManagersMutation, { data, loading, error }] = useUpdatePlanManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanManagersMutation,
    UpdatePlanManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlanManagersMutation,
    UpdatePlanManagersMutationVariables
  >(UpdatePlanManagersDocument, options);
}
export type UpdatePlanManagersMutationHookResult = ReturnType<
  typeof useUpdatePlanManagersMutation
>;
export type UpdatePlanManagersMutationResult =
  Apollo.MutationResult<UpdatePlanManagersMutation>;
export type UpdatePlanManagersMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanManagersMutation,
  UpdatePlanManagersMutationVariables
>;
export const ArchiveSupportCoordinatorsDocument = gql`
  mutation ArchiveSupportCoordinators($input: ArchiveInput!) {
    archiveSupportCoordinators(input: $input) {
      id
      archive {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type ArchiveSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  ArchiveSupportCoordinatorsMutation,
  ArchiveSupportCoordinatorsMutationVariables
>;

/**
 * __useArchiveSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useArchiveSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSupportCoordinatorsMutation, { data, loading, error }] = useArchiveSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveSupportCoordinatorsMutation,
    ArchiveSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveSupportCoordinatorsMutation,
    ArchiveSupportCoordinatorsMutationVariables
  >(ArchiveSupportCoordinatorsDocument, options);
}
export type ArchiveSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useArchiveSupportCoordinatorsMutation
>;
export type ArchiveSupportCoordinatorsMutationResult =
  Apollo.MutationResult<ArchiveSupportCoordinatorsMutation>;
export type ArchiveSupportCoordinatorsMutationOptions =
  Apollo.BaseMutationOptions<
    ArchiveSupportCoordinatorsMutation,
    ArchiveSupportCoordinatorsMutationVariables
  >;
export const CreateSupportCoordinatorsDocument = gql`
  mutation CreateSupportCoordinators($input: CreateSupportCoordinatorsInput!) {
    createSupportCoordinators(input: $input) {
      id
      firstName
      middleName
      lastName
      preferredName
      email
      phone
      businessName
      businessEmail
      businessPhone
      abn
      salutation
      notes
      clients {
        id
      }
    }
  }
`;
export type CreateSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  CreateSupportCoordinatorsMutation,
  CreateSupportCoordinatorsMutationVariables
>;

/**
 * __useCreateSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useCreateSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportCoordinatorsMutation, { data, loading, error }] = useCreateSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSupportCoordinatorsMutation,
    CreateSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSupportCoordinatorsMutation,
    CreateSupportCoordinatorsMutationVariables
  >(CreateSupportCoordinatorsDocument, options);
}
export type CreateSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useCreateSupportCoordinatorsMutation
>;
export type CreateSupportCoordinatorsMutationResult =
  Apollo.MutationResult<CreateSupportCoordinatorsMutation>;
export type CreateSupportCoordinatorsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSupportCoordinatorsMutation,
    CreateSupportCoordinatorsMutationVariables
  >;
export const DeleteSupportCoordinatorsDocument = gql`
  mutation DeleteSupportCoordinators($input: DeleteInput!) {
    deleteSupportCoordinators(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  DeleteSupportCoordinatorsMutation,
  DeleteSupportCoordinatorsMutationVariables
>;

/**
 * __useDeleteSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useDeleteSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupportCoordinatorsMutation, { data, loading, error }] = useDeleteSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSupportCoordinatorsMutation,
    DeleteSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSupportCoordinatorsMutation,
    DeleteSupportCoordinatorsMutationVariables
  >(DeleteSupportCoordinatorsDocument, options);
}
export type DeleteSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useDeleteSupportCoordinatorsMutation
>;
export type DeleteSupportCoordinatorsMutationResult =
  Apollo.MutationResult<DeleteSupportCoordinatorsMutation>;
export type DeleteSupportCoordinatorsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSupportCoordinatorsMutation,
    DeleteSupportCoordinatorsMutationVariables
  >;
export const RestoreSupportCoordinatorsDocument = gql`
  mutation RestoreSupportCoordinators($input: QueryByIdsInput!) {
    restoreSupportCoordinators(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
      archive {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  RestoreSupportCoordinatorsMutation,
  RestoreSupportCoordinatorsMutationVariables
>;

/**
 * __useRestoreSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useRestoreSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSupportCoordinatorsMutation, { data, loading, error }] = useRestoreSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreSupportCoordinatorsMutation,
    RestoreSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreSupportCoordinatorsMutation,
    RestoreSupportCoordinatorsMutationVariables
  >(RestoreSupportCoordinatorsDocument, options);
}
export type RestoreSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useRestoreSupportCoordinatorsMutation
>;
export type RestoreSupportCoordinatorsMutationResult =
  Apollo.MutationResult<RestoreSupportCoordinatorsMutation>;
export type RestoreSupportCoordinatorsMutationOptions =
  Apollo.BaseMutationOptions<
    RestoreSupportCoordinatorsMutation,
    RestoreSupportCoordinatorsMutationVariables
  >;
export const UpdateSupportCoordinatorsDocument = gql`
  mutation UpdateSupportCoordinators($input: UpdateSupportCoordinatorsInput!) {
    updateSupportCoordinators(input: $input) {
      id
      firstName
      middleName
      lastName
      preferredName
      email
      phone
      businessName
      businessEmail
      businessPhone
      abn
      salutation
      notes
      clients {
        id
      }
    }
  }
`;
export type UpdateSupportCoordinatorsMutationFn = Apollo.MutationFunction<
  UpdateSupportCoordinatorsMutation,
  UpdateSupportCoordinatorsMutationVariables
>;

/**
 * __useUpdateSupportCoordinatorsMutation__
 *
 * To run a mutation, you first call `useUpdateSupportCoordinatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupportCoordinatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupportCoordinatorsMutation, { data, loading, error }] = useUpdateSupportCoordinatorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupportCoordinatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSupportCoordinatorsMutation,
    UpdateSupportCoordinatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSupportCoordinatorsMutation,
    UpdateSupportCoordinatorsMutationVariables
  >(UpdateSupportCoordinatorsDocument, options);
}
export type UpdateSupportCoordinatorsMutationHookResult = ReturnType<
  typeof useUpdateSupportCoordinatorsMutation
>;
export type UpdateSupportCoordinatorsMutationResult =
  Apollo.MutationResult<UpdateSupportCoordinatorsMutation>;
export type UpdateSupportCoordinatorsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSupportCoordinatorsMutation,
    UpdateSupportCoordinatorsMutationVariables
  >;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      token
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const LoginUserDocument = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      token
    }
  }
`;
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginUserMutation,
    LoginUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options,
  );
}
export type LoginUserMutationHookResult = ReturnType<
  typeof useLoginUserMutation
>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const SetUserActiveMemberDocument = gql`
  mutation SetUserActiveMember($id: ID!) {
    setUserActiveMember(id: $id) {
      id
      member {
        id
        org {
          id
          ...BusinessNames
        }
      }
    }
  }
  ${BusinessNamesFragmentDoc}
`;
export type SetUserActiveMemberMutationFn = Apollo.MutationFunction<
  SetUserActiveMemberMutation,
  SetUserActiveMemberMutationVariables
>;

/**
 * __useSetUserActiveMemberMutation__
 *
 * To run a mutation, you first call `useSetUserActiveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserActiveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserActiveMemberMutation, { data, loading, error }] = useSetUserActiveMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetUserActiveMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserActiveMemberMutation,
    SetUserActiveMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetUserActiveMemberMutation,
    SetUserActiveMemberMutationVariables
  >(SetUserActiveMemberDocument, options);
}
export type SetUserActiveMemberMutationHookResult = ReturnType<
  typeof useSetUserActiveMemberMutation
>;
export type SetUserActiveMemberMutationResult =
  Apollo.MutationResult<SetUserActiveMemberMutation>;
export type SetUserActiveMemberMutationOptions = Apollo.BaseMutationOptions<
  SetUserActiveMemberMutation,
  SetUserActiveMemberMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      email
      phone
      ...PersonNames
      ...PersonGenderIdentifiers
      member {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CreateVisaTypeDocument = gql`
  mutation CreateVisaType($input: CreateVisaTypeInput!) {
    createVisaType(input: $input) {
      id
      name
      description
      allowedFortnightlyWorkHours
      allowedWorkHoursWaived
    }
  }
`;
export type CreateVisaTypeMutationFn = Apollo.MutationFunction<
  CreateVisaTypeMutation,
  CreateVisaTypeMutationVariables
>;

/**
 * __useCreateVisaTypeMutation__
 *
 * To run a mutation, you first call `useCreateVisaTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisaTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisaTypeMutation, { data, loading, error }] = useCreateVisaTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVisaTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVisaTypeMutation,
    CreateVisaTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVisaTypeMutation,
    CreateVisaTypeMutationVariables
  >(CreateVisaTypeDocument, options);
}
export type CreateVisaTypeMutationHookResult = ReturnType<
  typeof useCreateVisaTypeMutation
>;
export type CreateVisaTypeMutationResult =
  Apollo.MutationResult<CreateVisaTypeMutation>;
export type CreateVisaTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateVisaTypeMutation,
  CreateVisaTypeMutationVariables
>;
export const DeleteVisaTypesDocument = gql`
  mutation DeleteVisaTypes($input: DeleteInput!) {
    deleteVisaTypes(input: $input) {
      id
      delete {
        id
        date
        reason
        actor {
          id
        }
      }
    }
  }
`;
export type DeleteVisaTypesMutationFn = Apollo.MutationFunction<
  DeleteVisaTypesMutation,
  DeleteVisaTypesMutationVariables
>;

/**
 * __useDeleteVisaTypesMutation__
 *
 * To run a mutation, you first call `useDeleteVisaTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisaTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisaTypesMutation, { data, loading, error }] = useDeleteVisaTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVisaTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVisaTypesMutation,
    DeleteVisaTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteVisaTypesMutation,
    DeleteVisaTypesMutationVariables
  >(DeleteVisaTypesDocument, options);
}
export type DeleteVisaTypesMutationHookResult = ReturnType<
  typeof useDeleteVisaTypesMutation
>;
export type DeleteVisaTypesMutationResult =
  Apollo.MutationResult<DeleteVisaTypesMutation>;
export type DeleteVisaTypesMutationOptions = Apollo.BaseMutationOptions<
  DeleteVisaTypesMutation,
  DeleteVisaTypesMutationVariables
>;
export const RestoreVisaTypesDocument = gql`
  mutation RestoreVisaTypes($input: QueryByIdsInput!) {
    restoreVisaTypes(input: $input) {
      id
      delete {
        id
        restoredAt
        restoredBy {
          id
        }
      }
    }
  }
`;
export type RestoreVisaTypesMutationFn = Apollo.MutationFunction<
  RestoreVisaTypesMutation,
  RestoreVisaTypesMutationVariables
>;

/**
 * __useRestoreVisaTypesMutation__
 *
 * To run a mutation, you first call `useRestoreVisaTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreVisaTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreVisaTypesMutation, { data, loading, error }] = useRestoreVisaTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreVisaTypesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreVisaTypesMutation,
    RestoreVisaTypesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestoreVisaTypesMutation,
    RestoreVisaTypesMutationVariables
  >(RestoreVisaTypesDocument, options);
}
export type RestoreVisaTypesMutationHookResult = ReturnType<
  typeof useRestoreVisaTypesMutation
>;
export type RestoreVisaTypesMutationResult =
  Apollo.MutationResult<RestoreVisaTypesMutation>;
export type RestoreVisaTypesMutationOptions = Apollo.BaseMutationOptions<
  RestoreVisaTypesMutation,
  RestoreVisaTypesMutationVariables
>;
export const UpdateVisaTypeDocument = gql`
  mutation UpdateVisaType($input: UpdateVisaTypeInput!) {
    updateVisaType(input: $input) {
      id
      name
      description
      allowedFortnightlyWorkHours
      allowedWorkHoursWaived
    }
  }
`;
export type UpdateVisaTypeMutationFn = Apollo.MutationFunction<
  UpdateVisaTypeMutation,
  UpdateVisaTypeMutationVariables
>;

/**
 * __useUpdateVisaTypeMutation__
 *
 * To run a mutation, you first call `useUpdateVisaTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisaTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisaTypeMutation, { data, loading, error }] = useUpdateVisaTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVisaTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVisaTypeMutation,
    UpdateVisaTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVisaTypeMutation,
    UpdateVisaTypeMutationVariables
  >(UpdateVisaTypeDocument, options);
}
export type UpdateVisaTypeMutationHookResult = ReturnType<
  typeof useUpdateVisaTypeMutation
>;
export type UpdateVisaTypeMutationResult =
  Apollo.MutationResult<UpdateVisaTypeMutation>;
export type UpdateVisaTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateVisaTypeMutation,
  UpdateVisaTypeMutationVariables
>;
export const GetBranchByIdDocument = gql`
  query GetBranchById($id: ID!) {
    branchById(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetBranchByIdQuery__
 *
 * To run a query within a React component, call `useGetBranchByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBranchByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBranchByIdQuery,
    GetBranchByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBranchByIdQuery, GetBranchByIdQueryVariables>(
    GetBranchByIdDocument,
    options,
  );
}
export function useGetBranchByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBranchByIdQuery,
    GetBranchByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBranchByIdQuery, GetBranchByIdQueryVariables>(
    GetBranchByIdDocument,
    options,
  );
}
export type GetBranchByIdQueryHookResult = ReturnType<
  typeof useGetBranchByIdQuery
>;
export type GetBranchByIdLazyQueryHookResult = ReturnType<
  typeof useGetBranchByIdLazyQuery
>;
export type GetBranchByIdQueryResult = Apollo.QueryResult<
  GetBranchByIdQuery,
  GetBranchByIdQueryVariables
>;
export const GetBranchesDocument = gql`
  query GetBranches($input: QueryBranchesInput!) {
    branches(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useGetBranchesQuery__
 *
 * To run a query within a React component, call `useGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBranchesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBranchesQuery,
    GetBranchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBranchesQuery, GetBranchesQueryVariables>(
    GetBranchesDocument,
    options,
  );
}
export function useGetBranchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBranchesQuery,
    GetBranchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBranchesQuery, GetBranchesQueryVariables>(
    GetBranchesDocument,
    options,
  );
}
export type GetBranchesQueryHookResult = ReturnType<typeof useGetBranchesQuery>;
export type GetBranchesLazyQueryHookResult = ReturnType<
  typeof useGetBranchesLazyQuery
>;
export type GetBranchesQueryResult = Apollo.QueryResult<
  GetBranchesQuery,
  GetBranchesQueryVariables
>;
export const GetClientContactByIdDocument = gql`
  query GetClientContactById($id: ID!) {
    clientContactById(id: $id) {
      id
      name
      email
      phone
      relations
      customRelation
      emergencyContact
      client {
        id
        preferredContact {
          id
        }
      }
      delete {
        id
      }
    }
  }
`;

/**
 * __useGetClientContactByIdQuery__
 *
 * To run a query within a React component, call `useGetClientContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientContactByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientContactByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientContactByIdQuery,
    GetClientContactByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientContactByIdQuery,
    GetClientContactByIdQueryVariables
  >(GetClientContactByIdDocument, options);
}
export function useGetClientContactByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientContactByIdQuery,
    GetClientContactByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientContactByIdQuery,
    GetClientContactByIdQueryVariables
  >(GetClientContactByIdDocument, options);
}
export type GetClientContactByIdQueryHookResult = ReturnType<
  typeof useGetClientContactByIdQuery
>;
export type GetClientContactByIdLazyQueryHookResult = ReturnType<
  typeof useGetClientContactByIdLazyQuery
>;
export type GetClientContactByIdQueryResult = Apollo.QueryResult<
  GetClientContactByIdQuery,
  GetClientContactByIdQueryVariables
>;
export const GetClientContactsDocument = gql`
  query GetClientContacts($input: QueryClientContactsInput!) {
    clientContacts(input: $input) {
      id
      name
      email
      phone
      relations
      customRelation
      emergencyContact
      client {
        id
      }
      delete {
        id
      }
    }
  }
`;

/**
 * __useGetClientContactsQuery__
 *
 * To run a query within a React component, call `useGetClientContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientContactsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientContactsQuery,
    GetClientContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientContactsQuery,
    GetClientContactsQueryVariables
  >(GetClientContactsDocument, options);
}
export function useGetClientContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientContactsQuery,
    GetClientContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientContactsQuery,
    GetClientContactsQueryVariables
  >(GetClientContactsDocument, options);
}
export type GetClientContactsQueryHookResult = ReturnType<
  typeof useGetClientContactsQuery
>;
export type GetClientContactsLazyQueryHookResult = ReturnType<
  typeof useGetClientContactsLazyQuery
>;
export type GetClientContactsQueryResult = Apollo.QueryResult<
  GetClientContactsQuery,
  GetClientContactsQueryVariables
>;
export const DownloadClientCarePlanDocument = gql`
  query DownloadClientCarePlan($id: ID!) {
    downloadClientCarePlan(id: $id) {
      id
      path
    }
  }
`;

/**
 * __useDownloadClientCarePlanQuery__
 *
 * To run a query within a React component, call `useDownloadClientCarePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadClientCarePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadClientCarePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadClientCarePlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadClientCarePlanQuery,
    DownloadClientCarePlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadClientCarePlanQuery,
    DownloadClientCarePlanQueryVariables
  >(DownloadClientCarePlanDocument, options);
}
export function useDownloadClientCarePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadClientCarePlanQuery,
    DownloadClientCarePlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadClientCarePlanQuery,
    DownloadClientCarePlanQueryVariables
  >(DownloadClientCarePlanDocument, options);
}
export type DownloadClientCarePlanQueryHookResult = ReturnType<
  typeof useDownloadClientCarePlanQuery
>;
export type DownloadClientCarePlanLazyQueryHookResult = ReturnType<
  typeof useDownloadClientCarePlanLazyQuery
>;
export type DownloadClientCarePlanQueryResult = Apollo.QueryResult<
  DownloadClientCarePlanQuery,
  DownloadClientCarePlanQueryVariables
>;
export const DownloadClientFileByIdDocument = gql`
  query DownloadClientFileById($id: ID!) {
    downloadClientFileById(id: $id) {
      id
      path
    }
  }
`;

/**
 * __useDownloadClientFileByIdQuery__
 *
 * To run a query within a React component, call `useDownloadClientFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadClientFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadClientFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadClientFileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadClientFileByIdQuery,
    DownloadClientFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadClientFileByIdQuery,
    DownloadClientFileByIdQueryVariables
  >(DownloadClientFileByIdDocument, options);
}
export function useDownloadClientFileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadClientFileByIdQuery,
    DownloadClientFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadClientFileByIdQuery,
    DownloadClientFileByIdQueryVariables
  >(DownloadClientFileByIdDocument, options);
}
export type DownloadClientFileByIdQueryHookResult = ReturnType<
  typeof useDownloadClientFileByIdQuery
>;
export type DownloadClientFileByIdLazyQueryHookResult = ReturnType<
  typeof useDownloadClientFileByIdLazyQuery
>;
export type DownloadClientFileByIdQueryResult = Apollo.QueryResult<
  DownloadClientFileByIdQuery,
  DownloadClientFileByIdQueryVariables
>;
export const GetClientFilesDocument = gql`
  query GetClientFiles($input: QueryClientFilesInput!) {
    clientFiles(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      value
      file {
        id
        name
        size
        createdAt
        createdBy {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientFilesQuery__
 *
 * To run a query within a React component, call `useGetClientFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientFilesQuery,
    GetClientFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientFilesQuery, GetClientFilesQueryVariables>(
    GetClientFilesDocument,
    options,
  );
}
export function useGetClientFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientFilesQuery,
    GetClientFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientFilesQuery, GetClientFilesQueryVariables>(
    GetClientFilesDocument,
    options,
  );
}
export type GetClientFilesQueryHookResult = ReturnType<
  typeof useGetClientFilesQuery
>;
export type GetClientFilesLazyQueryHookResult = ReturnType<
  typeof useGetClientFilesLazyQuery
>;
export type GetClientFilesQueryResult = Apollo.QueryResult<
  GetClientFilesQuery,
  GetClientFilesQueryVariables
>;
export const GetClientNdisPlanItemNamesDocument = gql`
  query GetClientNdisPlanItemNames($input: QueryClientNdisPlanItemNameInput!) {
    clientNdisPlanItemNames(input: $input) {
      id
      category
      name
    }
  }
`;

/**
 * __useGetClientNdisPlanItemNamesQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlanItemNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlanItemNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlanItemNamesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNdisPlanItemNamesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlanItemNamesQuery,
    GetClientNdisPlanItemNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlanItemNamesQuery,
    GetClientNdisPlanItemNamesQueryVariables
  >(GetClientNdisPlanItemNamesDocument, options);
}
export function useGetClientNdisPlanItemNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlanItemNamesQuery,
    GetClientNdisPlanItemNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlanItemNamesQuery,
    GetClientNdisPlanItemNamesQueryVariables
  >(GetClientNdisPlanItemNamesDocument, options);
}
export type GetClientNdisPlanItemNamesQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanItemNamesQuery
>;
export type GetClientNdisPlanItemNamesLazyQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanItemNamesLazyQuery
>;
export type GetClientNdisPlanItemNamesQueryResult = Apollo.QueryResult<
  GetClientNdisPlanItemNamesQuery,
  GetClientNdisPlanItemNamesQueryVariables
>;
export const ClientNdisPlanItemTransactionContextGetTransactionsDocument = gql`
  query ClientNdisPlanItemTransactionContextGetTransactions(
    $input: QueryClientNdisPlanItemTransactionInput!
  ) {
    clientNdisPlanItemTransactionAggregate(input: $input) {
      aggregate {
        totalCount
      }
      nodes {
        id
        createdAt
        billingDate
        serviceDateStart
        serviceDateEnd
        invoiceNumber
        value
        memo
        clientNdisPlanItem {
          id
          name {
            id
            name
          }
        }
        actor {
          id
          ...PersonNames
        }
        clientNdisPlanItemTransactionSupplier {
          id
          name
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useClientNdisPlanItemTransactionContextGetTransactionsQuery__
 *
 * To run a query within a React component, call `useClientNdisPlanItemTransactionContextGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientNdisPlanItemTransactionContextGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientNdisPlanItemTransactionContextGetTransactionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientNdisPlanItemTransactionContextGetTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientNdisPlanItemTransactionContextGetTransactionsQuery,
    ClientNdisPlanItemTransactionContextGetTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientNdisPlanItemTransactionContextGetTransactionsQuery,
    ClientNdisPlanItemTransactionContextGetTransactionsQueryVariables
  >(ClientNdisPlanItemTransactionContextGetTransactionsDocument, options);
}
export function useClientNdisPlanItemTransactionContextGetTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientNdisPlanItemTransactionContextGetTransactionsQuery,
    ClientNdisPlanItemTransactionContextGetTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientNdisPlanItemTransactionContextGetTransactionsQuery,
    ClientNdisPlanItemTransactionContextGetTransactionsQueryVariables
  >(ClientNdisPlanItemTransactionContextGetTransactionsDocument, options);
}
export type ClientNdisPlanItemTransactionContextGetTransactionsQueryHookResult =
  ReturnType<
    typeof useClientNdisPlanItemTransactionContextGetTransactionsQuery
  >;
export type ClientNdisPlanItemTransactionContextGetTransactionsLazyQueryHookResult =
  ReturnType<
    typeof useClientNdisPlanItemTransactionContextGetTransactionsLazyQuery
  >;
export type ClientNdisPlanItemTransactionContextGetTransactionsQueryResult =
  Apollo.QueryResult<
    ClientNdisPlanItemTransactionContextGetTransactionsQuery,
    ClientNdisPlanItemTransactionContextGetTransactionsQueryVariables
  >;
export const GetClientNdisPlanItemTransactionHistoriesDocument = gql`
  query GetClientNdisPlanItemTransactionHistories(
    $input: QueryHistoriesInput!
  ) {
    histories(input: $input) {
      ... on BaseHistory {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
      }
      ... on HistoryRestorable {
        id
        date
        type
        reason
        actor {
          id
          firstName
          lastName
        }
        restoredAt
        restoredBy {
          id
          firstName
          middleName
          lastName
          preferredName
        }
      }
      ... on HistoryClientNdisPlanItemTransactionSupplier {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItemTransaction {
          id
        }
        clientNdisPlanItemTransactionSupplierNew {
          id
        }
        clientNdisPlanItemTransactionSupplierOld {
          id
        }
      }
      ... on HistoryClientNdisPlanItemTransactionBillingDate {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItemTransaction {
          id
        }
        clientNdisPlanItemTransactionBillingDateNew
        clientNdisPlanItemTransactionBillingDateOld
      }
      ... on HistoryClientNdisPlanItemTransactionServiceDateStart {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItemTransaction {
          id
        }
        clientNdisPlanItemTransactionServiceDateStartNew
        clientNdisPlanItemTransactionServiceDateStartOld
      }
      ... on HistoryClientNdisPlanItemTransactionServiceDateEnd {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItemTransaction {
          id
        }
        clientNdisPlanItemTransactionServiceDateEndNew
        clientNdisPlanItemTransactionServiceDateEndOld
      }
      ... on HistoryClientNdisPlanItemTransactionMemo {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItemTransaction {
          id
        }
        clientNdisPlanItemTransactionMemoNew
        clientNdisPlanItemTransactionMemoOld
      }
      ... on HistoryClientNdisPlanItemTransactionValue {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItemTransaction {
          id
        }
        clientNdisPlanItemTransactionValueNew
        clientNdisPlanItemTransactionValueOld
      }
      ... on HistoryClientNdisPlanItemTransactionInvoiceNumber {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItemTransaction {
          id
        }
        clientNdisPlanItemTransactionInvoiceNumberNew
        clientNdisPlanItemTransactionInvoiceNumberOld
      }
    }
  }
`;

/**
 * __useGetClientNdisPlanItemTransactionHistoriesQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlanItemTransactionHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlanItemTransactionHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlanItemTransactionHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNdisPlanItemTransactionHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlanItemTransactionHistoriesQuery,
    GetClientNdisPlanItemTransactionHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlanItemTransactionHistoriesQuery,
    GetClientNdisPlanItemTransactionHistoriesQueryVariables
  >(GetClientNdisPlanItemTransactionHistoriesDocument, options);
}
export function useGetClientNdisPlanItemTransactionHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlanItemTransactionHistoriesQuery,
    GetClientNdisPlanItemTransactionHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlanItemTransactionHistoriesQuery,
    GetClientNdisPlanItemTransactionHistoriesQueryVariables
  >(GetClientNdisPlanItemTransactionHistoriesDocument, options);
}
export type GetClientNdisPlanItemTransactionHistoriesQueryHookResult =
  ReturnType<typeof useGetClientNdisPlanItemTransactionHistoriesQuery>;
export type GetClientNdisPlanItemTransactionHistoriesLazyQueryHookResult =
  ReturnType<typeof useGetClientNdisPlanItemTransactionHistoriesLazyQuery>;
export type GetClientNdisPlanItemTransactionHistoriesQueryResult =
  Apollo.QueryResult<
    GetClientNdisPlanItemTransactionHistoriesQuery,
    GetClientNdisPlanItemTransactionHistoriesQueryVariables
  >;
export const GetClientNdisPlanItemTransactionsDocument = gql`
  query GetClientNdisPlanItemTransactions(
    $input: QueryClientNdisPlanItemTransactionInput!
  ) {
    clientNdisPlanItemTransactions(input: $input) {
      id
      createdAt
      billingDate
      serviceDateStart
      serviceDateEnd
      invoiceNumber
      value
      memo
      clientNdisPlanItem {
        id
      }
      actor {
        id
        ...PersonNames
      }
      clientNdisPlanItemTransactionSupplier {
        id
        name
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientNdisPlanItemTransactionsQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlanItemTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlanItemTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlanItemTransactionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNdisPlanItemTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlanItemTransactionsQuery,
    GetClientNdisPlanItemTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlanItemTransactionsQuery,
    GetClientNdisPlanItemTransactionsQueryVariables
  >(GetClientNdisPlanItemTransactionsDocument, options);
}
export function useGetClientNdisPlanItemTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlanItemTransactionsQuery,
    GetClientNdisPlanItemTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlanItemTransactionsQuery,
    GetClientNdisPlanItemTransactionsQueryVariables
  >(GetClientNdisPlanItemTransactionsDocument, options);
}
export type GetClientNdisPlanItemTransactionsQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanItemTransactionsQuery
>;
export type GetClientNdisPlanItemTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanItemTransactionsLazyQuery
>;
export type GetClientNdisPlanItemTransactionsQueryResult = Apollo.QueryResult<
  GetClientNdisPlanItemTransactionsQuery,
  GetClientNdisPlanItemTransactionsQueryVariables
>;
export const GetClientNdisPlanItemTransationSupplierHistoriesDocument = gql`
  query GetClientNdisPlanItemTransationSupplierHistories(
    $input: QueryHistoriesInput!
  ) {
    histories(input: $input) {
      ... on BaseHistory {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
      }
      ... on HistoryRestorable {
        id
        date
        type
        reason
        actor {
          id
          firstName
          lastName
        }
        restoredAt
        restoredBy {
          id
          firstName
          middleName
          lastName
          preferredName
        }
      }
      ... on HistoryClientNdisPlanItemTransactionSupplierName {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItemTransactionSupplier {
          id
        }
        clientNdisPlanItemTransactionSupplierNameNew
        clientNdisPlanItemTransactionSupplierNameOld
      }
    }
  }
`;

/**
 * __useGetClientNdisPlanItemTransationSupplierHistoriesQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlanItemTransationSupplierHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlanItemTransationSupplierHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlanItemTransationSupplierHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNdisPlanItemTransationSupplierHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlanItemTransationSupplierHistoriesQuery,
    GetClientNdisPlanItemTransationSupplierHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlanItemTransationSupplierHistoriesQuery,
    GetClientNdisPlanItemTransationSupplierHistoriesQueryVariables
  >(GetClientNdisPlanItemTransationSupplierHistoriesDocument, options);
}
export function useGetClientNdisPlanItemTransationSupplierHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlanItemTransationSupplierHistoriesQuery,
    GetClientNdisPlanItemTransationSupplierHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlanItemTransationSupplierHistoriesQuery,
    GetClientNdisPlanItemTransationSupplierHistoriesQueryVariables
  >(GetClientNdisPlanItemTransationSupplierHistoriesDocument, options);
}
export type GetClientNdisPlanItemTransationSupplierHistoriesQueryHookResult =
  ReturnType<typeof useGetClientNdisPlanItemTransationSupplierHistoriesQuery>;
export type GetClientNdisPlanItemTransationSupplierHistoriesLazyQueryHookResult =
  ReturnType<
    typeof useGetClientNdisPlanItemTransationSupplierHistoriesLazyQuery
  >;
export type GetClientNdisPlanItemTransationSupplierHistoriesQueryResult =
  Apollo.QueryResult<
    GetClientNdisPlanItemTransationSupplierHistoriesQuery,
    GetClientNdisPlanItemTransationSupplierHistoriesQueryVariables
  >;
export const GetClientNdisPlanItemTransationSuppliersDocument = gql`
  query GetClientNdisPlanItemTransationSuppliers(
    $input: QueryClientNdisPlanItemTransactionSuppliersInput!
  ) {
    clientNdisPlanItemTransactionSuppliers(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useGetClientNdisPlanItemTransationSuppliersQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlanItemTransationSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlanItemTransationSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlanItemTransationSuppliersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNdisPlanItemTransationSuppliersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlanItemTransationSuppliersQuery,
    GetClientNdisPlanItemTransationSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlanItemTransationSuppliersQuery,
    GetClientNdisPlanItemTransationSuppliersQueryVariables
  >(GetClientNdisPlanItemTransationSuppliersDocument, options);
}
export function useGetClientNdisPlanItemTransationSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlanItemTransationSuppliersQuery,
    GetClientNdisPlanItemTransationSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlanItemTransationSuppliersQuery,
    GetClientNdisPlanItemTransationSuppliersQueryVariables
  >(GetClientNdisPlanItemTransationSuppliersDocument, options);
}
export type GetClientNdisPlanItemTransationSuppliersQueryHookResult =
  ReturnType<typeof useGetClientNdisPlanItemTransationSuppliersQuery>;
export type GetClientNdisPlanItemTransationSuppliersLazyQueryHookResult =
  ReturnType<typeof useGetClientNdisPlanItemTransationSuppliersLazyQuery>;
export type GetClientNdisPlanItemTransationSuppliersQueryResult =
  Apollo.QueryResult<
    GetClientNdisPlanItemTransationSuppliersQuery,
    GetClientNdisPlanItemTransationSuppliersQueryVariables
  >;
export const GetClientNdisPlanItemHistoriesDocument = gql`
  query GetClientNdisPlanItemHistories($input: QueryHistoriesInput!) {
    histories(input: $input) {
      ... on BaseHistory {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
      }
      ... on HistoryRestorable {
        id
        date
        type
        reason
        actor {
          id
          firstName
          lastName
        }
        restoredAt
        restoredBy {
          id
          firstName
          middleName
          lastName
          preferredName
        }
      }
      ... on HistoryClientNdisPlanItemCategory {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItem {
          id
        }
        clientNdisPlanItemCategoryNew
        clientNdisPlanItemCategoryOld
      }
      ... on HistoryClientNdisPlanItemInitialFunds {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItem {
          id
        }
        clientNdisPlanItemInitialFundsNew
        clientNdisPlanItemInitialFundsOld
      }
      ... on HistoryClientNdisPlanItemManagedBy {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItem {
          id
        }
        clientNdisPlanItemManagedByNew
        clientNdisPlanItemManagedByOld
      }
      ... on HistoryClientNdisPlanItemName {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlanItem {
          id
        }
        clientNdisPlanItemNameNew {
          id
          name
        }
        clientNdisPlanItemNameOld {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetClientNdisPlanItemHistoriesQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlanItemHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlanItemHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlanItemHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNdisPlanItemHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlanItemHistoriesQuery,
    GetClientNdisPlanItemHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlanItemHistoriesQuery,
    GetClientNdisPlanItemHistoriesQueryVariables
  >(GetClientNdisPlanItemHistoriesDocument, options);
}
export function useGetClientNdisPlanItemHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlanItemHistoriesQuery,
    GetClientNdisPlanItemHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlanItemHistoriesQuery,
    GetClientNdisPlanItemHistoriesQueryVariables
  >(GetClientNdisPlanItemHistoriesDocument, options);
}
export type GetClientNdisPlanItemHistoriesQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanItemHistoriesQuery
>;
export type GetClientNdisPlanItemHistoriesLazyQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanItemHistoriesLazyQuery
>;
export type GetClientNdisPlanItemHistoriesQueryResult = Apollo.QueryResult<
  GetClientNdisPlanItemHistoriesQuery,
  GetClientNdisPlanItemHistoriesQueryVariables
>;
export const GetClientNdisPlanItemsDocument = gql`
  query GetClientNdisPlanItems($input: QueryClientNdisPlanItemInput!) {
    clientNdisPlanItems(input: $input) {
      id
      category
      name {
        id
        name
      }
      initialFunds
      currentFunds
    }
  }
`;

/**
 * __useGetClientNdisPlanItemsQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlanItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlanItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlanItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNdisPlanItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlanItemsQuery,
    GetClientNdisPlanItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlanItemsQuery,
    GetClientNdisPlanItemsQueryVariables
  >(GetClientNdisPlanItemsDocument, options);
}
export function useGetClientNdisPlanItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlanItemsQuery,
    GetClientNdisPlanItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlanItemsQuery,
    GetClientNdisPlanItemsQueryVariables
  >(GetClientNdisPlanItemsDocument, options);
}
export type GetClientNdisPlanItemsQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanItemsQuery
>;
export type GetClientNdisPlanItemsLazyQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanItemsLazyQuery
>;
export type GetClientNdisPlanItemsQueryResult = Apollo.QueryResult<
  GetClientNdisPlanItemsQuery,
  GetClientNdisPlanItemsQueryVariables
>;
export const GetClientNdisPlanByIdDocument = gql`
  query GetClientNdisPlanById($id: ID!) {
    clientNdisPlanById(id: $id) {
      id
      startAt
      endAt
      initialFunds
    }
  }
`;

/**
 * __useGetClientNdisPlanByIdQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlanByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientNdisPlanByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlanByIdQuery,
    GetClientNdisPlanByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlanByIdQuery,
    GetClientNdisPlanByIdQueryVariables
  >(GetClientNdisPlanByIdDocument, options);
}
export function useGetClientNdisPlanByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlanByIdQuery,
    GetClientNdisPlanByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlanByIdQuery,
    GetClientNdisPlanByIdQueryVariables
  >(GetClientNdisPlanByIdDocument, options);
}
export type GetClientNdisPlanByIdQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanByIdQuery
>;
export type GetClientNdisPlanByIdLazyQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanByIdLazyQuery
>;
export type GetClientNdisPlanByIdQueryResult = Apollo.QueryResult<
  GetClientNdisPlanByIdQuery,
  GetClientNdisPlanByIdQueryVariables
>;
export const GetClientNdisPlanHistoriesDocument = gql`
  query GetClientNdisPlanHistories($input: QueryHistoriesInput!) {
    histories(input: $input) {
      ... on BaseHistory {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
      }
      ... on HistoryRestorable {
        id
        date
        type
        reason
        actor {
          id
          firstName
          lastName
        }
        restoredAt
        restoredBy {
          id
          firstName
          middleName
          lastName
          preferredName
        }
      }
      ... on HistoryClientNdisPlanStartAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlan {
          id
        }
        clientNdisPlanStartAtNew
        clientNdisPlanStartAtOld
      }
      ... on HistoryClientNdisPlanEndAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlan {
          id
        }
        clientNdisPlanEndAtNew
        clientNdisPlanEndAtOld
      }
      ... on HistoryClientNdisPlanCoreSupportItem {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlan {
          id
        }
        clientNdisPlanCoreSupportItemNew {
          id
          name {
            id
            name
          }
        }
        clientNdisPlanCoreSupportItemOld {
          id
          name {
            id
            name
          }
        }
      }
      ... on HistoryClientNdisPlanSupportCoordinationItem {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        clientNdisPlan {
          id
        }
        clientNdisPlanSupportCoordinationItemNew {
          id
          name {
            id
            name
          }
        }
        clientNdisPlanSupportCoordinationItemOld {
          id
          name {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetClientNdisPlanHistoriesQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlanHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlanHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlanHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNdisPlanHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlanHistoriesQuery,
    GetClientNdisPlanHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlanHistoriesQuery,
    GetClientNdisPlanHistoriesQueryVariables
  >(GetClientNdisPlanHistoriesDocument, options);
}
export function useGetClientNdisPlanHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlanHistoriesQuery,
    GetClientNdisPlanHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlanHistoriesQuery,
    GetClientNdisPlanHistoriesQueryVariables
  >(GetClientNdisPlanHistoriesDocument, options);
}
export type GetClientNdisPlanHistoriesQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanHistoriesQuery
>;
export type GetClientNdisPlanHistoriesLazyQueryHookResult = ReturnType<
  typeof useGetClientNdisPlanHistoriesLazyQuery
>;
export type GetClientNdisPlanHistoriesQueryResult = Apollo.QueryResult<
  GetClientNdisPlanHistoriesQuery,
  GetClientNdisPlanHistoriesQueryVariables
>;
export const GetClientNdisPlansDocument = gql`
  query GetClientNdisPlans(
    $input: QueryClientNdisPlanInput!
    $itemsInput: QueryClientNdisPlanItemInput!
  ) {
    clientNdisPlans(input: $input) {
      id
      startAt
      endAt
      initialFunds
      items(input: $itemsInput) {
        id
        category
        managedBy
        name {
          id
          category
          name
        }
        initialFunds
        currentFunds
      }
      coreSupportItem {
        id
      }
      supportCoordinationItem {
        id
      }
    }
  }
`;

/**
 * __useGetClientNdisPlansQuery__
 *
 * To run a query within a React component, call `useGetClientNdisPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNdisPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNdisPlansQuery({
 *   variables: {
 *      input: // value for 'input'
 *      itemsInput: // value for 'itemsInput'
 *   },
 * });
 */
export function useGetClientNdisPlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNdisPlansQuery,
    GetClientNdisPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNdisPlansQuery,
    GetClientNdisPlansQueryVariables
  >(GetClientNdisPlansDocument, options);
}
export function useGetClientNdisPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNdisPlansQuery,
    GetClientNdisPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNdisPlansQuery,
    GetClientNdisPlansQueryVariables
  >(GetClientNdisPlansDocument, options);
}
export type GetClientNdisPlansQueryHookResult = ReturnType<
  typeof useGetClientNdisPlansQuery
>;
export type GetClientNdisPlansLazyQueryHookResult = ReturnType<
  typeof useGetClientNdisPlansLazyQuery
>;
export type GetClientNdisPlansQueryResult = Apollo.QueryResult<
  GetClientNdisPlansQuery,
  GetClientNdisPlansQueryVariables
>;
export const DownloadClientNoteFileByIdDocument = gql`
  query DownloadClientNoteFileById($id: ID!) {
    downloadClientNoteFileById(id: $id) {
      id
      path
    }
  }
`;

/**
 * __useDownloadClientNoteFileByIdQuery__
 *
 * To run a query within a React component, call `useDownloadClientNoteFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadClientNoteFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadClientNoteFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadClientNoteFileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadClientNoteFileByIdQuery,
    DownloadClientNoteFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadClientNoteFileByIdQuery,
    DownloadClientNoteFileByIdQueryVariables
  >(DownloadClientNoteFileByIdDocument, options);
}
export function useDownloadClientNoteFileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadClientNoteFileByIdQuery,
    DownloadClientNoteFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadClientNoteFileByIdQuery,
    DownloadClientNoteFileByIdQueryVariables
  >(DownloadClientNoteFileByIdDocument, options);
}
export type DownloadClientNoteFileByIdQueryHookResult = ReturnType<
  typeof useDownloadClientNoteFileByIdQuery
>;
export type DownloadClientNoteFileByIdLazyQueryHookResult = ReturnType<
  typeof useDownloadClientNoteFileByIdLazyQuery
>;
export type DownloadClientNoteFileByIdQueryResult = Apollo.QueryResult<
  DownloadClientNoteFileByIdQuery,
  DownloadClientNoteFileByIdQueryVariables
>;
export const GetClientNoteFilesDocument = gql`
  query GetClientNoteFiles($input: QueryClientNoteFilesInput!) {
    clientNoteFiles(input: $input) {
      id
      type
      dateOfFile
      value
      file {
        id
        size
        createdAt
        createdBy {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientNoteFilesQuery__
 *
 * To run a query within a React component, call `useGetClientNoteFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNoteFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNoteFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNoteFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNoteFilesQuery,
    GetClientNoteFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNoteFilesQuery,
    GetClientNoteFilesQueryVariables
  >(GetClientNoteFilesDocument, options);
}
export function useGetClientNoteFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNoteFilesQuery,
    GetClientNoteFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNoteFilesQuery,
    GetClientNoteFilesQueryVariables
  >(GetClientNoteFilesDocument, options);
}
export type GetClientNoteFilesQueryHookResult = ReturnType<
  typeof useGetClientNoteFilesQuery
>;
export type GetClientNoteFilesLazyQueryHookResult = ReturnType<
  typeof useGetClientNoteFilesLazyQuery
>;
export type GetClientNoteFilesQueryResult = Apollo.QueryResult<
  GetClientNoteFilesQuery,
  GetClientNoteFilesQueryVariables
>;
export const GetClientNoteKeywordsDocument = gql`
  query GetClientNoteKeywords($input: QueryClientNoteKeywordsInput!) {
    clientNoteKeywords(input: $input) {
      id
      createdAt
      createdBy {
        id
        ...PersonNamesPartial
      }
      text
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetClientNoteKeywordsQuery__
 *
 * To run a query within a React component, call `useGetClientNoteKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNoteKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNoteKeywordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNoteKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNoteKeywordsQuery,
    GetClientNoteKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientNoteKeywordsQuery,
    GetClientNoteKeywordsQueryVariables
  >(GetClientNoteKeywordsDocument, options);
}
export function useGetClientNoteKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNoteKeywordsQuery,
    GetClientNoteKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientNoteKeywordsQuery,
    GetClientNoteKeywordsQueryVariables
  >(GetClientNoteKeywordsDocument, options);
}
export type GetClientNoteKeywordsQueryHookResult = ReturnType<
  typeof useGetClientNoteKeywordsQuery
>;
export type GetClientNoteKeywordsLazyQueryHookResult = ReturnType<
  typeof useGetClientNoteKeywordsLazyQuery
>;
export type GetClientNoteKeywordsQueryResult = Apollo.QueryResult<
  GetClientNoteKeywordsQuery,
  GetClientNoteKeywordsQueryVariables
>;
export const NotesContextGetClientNoteKeywordsDocument = gql`
  query NotesContextGetClientNoteKeywords(
    $input: QueryClientNoteKeywordsInput!
  ) {
    clientNoteKeywords(input: $input) {
      id
      text
    }
  }
`;

/**
 * __useNotesContextGetClientNoteKeywordsQuery__
 *
 * To run a query within a React component, call `useNotesContextGetClientNoteKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesContextGetClientNoteKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesContextGetClientNoteKeywordsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotesContextGetClientNoteKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotesContextGetClientNoteKeywordsQuery,
    NotesContextGetClientNoteKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotesContextGetClientNoteKeywordsQuery,
    NotesContextGetClientNoteKeywordsQueryVariables
  >(NotesContextGetClientNoteKeywordsDocument, options);
}
export function useNotesContextGetClientNoteKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotesContextGetClientNoteKeywordsQuery,
    NotesContextGetClientNoteKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotesContextGetClientNoteKeywordsQuery,
    NotesContextGetClientNoteKeywordsQueryVariables
  >(NotesContextGetClientNoteKeywordsDocument, options);
}
export type NotesContextGetClientNoteKeywordsQueryHookResult = ReturnType<
  typeof useNotesContextGetClientNoteKeywordsQuery
>;
export type NotesContextGetClientNoteKeywordsLazyQueryHookResult = ReturnType<
  typeof useNotesContextGetClientNoteKeywordsLazyQuery
>;
export type NotesContextGetClientNoteKeywordsQueryResult = Apollo.QueryResult<
  NotesContextGetClientNoteKeywordsQuery,
  NotesContextGetClientNoteKeywordsQueryVariables
>;
export const ExportClientNotesDocument = gql`
  query ExportClientNotes($input: QueryClientNotesInput!) {
    clientNotes(input: $input) {
      id
      client {
        id
        ...PersonNames
      }
      type
      comment
      commentedAt
      commentedBy {
        id
        ...PersonNames
      }
      commentedByText
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useExportClientNotesQuery__
 *
 * To run a query within a React component, call `useExportClientNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportClientNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportClientNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportClientNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportClientNotesQuery,
    ExportClientNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExportClientNotesQuery,
    ExportClientNotesQueryVariables
  >(ExportClientNotesDocument, options);
}
export function useExportClientNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportClientNotesQuery,
    ExportClientNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExportClientNotesQuery,
    ExportClientNotesQueryVariables
  >(ExportClientNotesDocument, options);
}
export type ExportClientNotesQueryHookResult = ReturnType<
  typeof useExportClientNotesQuery
>;
export type ExportClientNotesLazyQueryHookResult = ReturnType<
  typeof useExportClientNotesLazyQuery
>;
export type ExportClientNotesQueryResult = Apollo.QueryResult<
  ExportClientNotesQuery,
  ExportClientNotesQueryVariables
>;
export const GetClientNotesDocument = gql`
  query GetClientNotes($input: QueryClientNotesInput!) {
    clientNotes(input: $input) {
      id
      createdBy {
        id
        ...PersonNames
      }
      client {
        id
        ...PersonNames
      }
      event {
        id
        startAt
        endAt
        travelDistance
      }
      type
      comment
      commentedAt
      commentedBy {
        id
        ...PersonNames
      }
      commentedByText
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientNotesQuery__
 *
 * To run a query within a React component, call `useGetClientNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientNotesQuery,
    GetClientNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientNotesQuery, GetClientNotesQueryVariables>(
    GetClientNotesDocument,
    options,
  );
}
export function useGetClientNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientNotesQuery,
    GetClientNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientNotesQuery, GetClientNotesQueryVariables>(
    GetClientNotesDocument,
    options,
  );
}
export type GetClientNotesQueryHookResult = ReturnType<
  typeof useGetClientNotesQuery
>;
export type GetClientNotesLazyQueryHookResult = ReturnType<
  typeof useGetClientNotesLazyQuery
>;
export type GetClientNotesQueryResult = Apollo.QueryResult<
  GetClientNotesQuery,
  GetClientNotesQueryVariables
>;
export const NotesContextGetAggregatedClientNotesDocument = gql`
  query NotesContextGetAggregatedClientNotes($input: QueryClientNotesInput!) {
    clientNotesAggregate(input: $input) {
      aggregate {
        totalCount
      }
      nodes {
        id
        createdBy {
          id
          ...PersonNames
        }
        client {
          id
          ...PersonNames
        }
        event {
          id
          startAt
          endAt
          travelDistance
        }
        type
        comment
        commentedAt
        commentedBy {
          id
          ...PersonNames
        }
        commentedByText
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useNotesContextGetAggregatedClientNotesQuery__
 *
 * To run a query within a React component, call `useNotesContextGetAggregatedClientNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesContextGetAggregatedClientNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesContextGetAggregatedClientNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotesContextGetAggregatedClientNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotesContextGetAggregatedClientNotesQuery,
    NotesContextGetAggregatedClientNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotesContextGetAggregatedClientNotesQuery,
    NotesContextGetAggregatedClientNotesQueryVariables
  >(NotesContextGetAggregatedClientNotesDocument, options);
}
export function useNotesContextGetAggregatedClientNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotesContextGetAggregatedClientNotesQuery,
    NotesContextGetAggregatedClientNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotesContextGetAggregatedClientNotesQuery,
    NotesContextGetAggregatedClientNotesQueryVariables
  >(NotesContextGetAggregatedClientNotesDocument, options);
}
export type NotesContextGetAggregatedClientNotesQueryHookResult = ReturnType<
  typeof useNotesContextGetAggregatedClientNotesQuery
>;
export type NotesContextGetAggregatedClientNotesLazyQueryHookResult =
  ReturnType<typeof useNotesContextGetAggregatedClientNotesLazyQuery>;
export type NotesContextGetAggregatedClientNotesQueryResult =
  Apollo.QueryResult<
    NotesContextGetAggregatedClientNotesQuery,
    NotesContextGetAggregatedClientNotesQueryVariables
  >;
export const NotesContextGetFilesDocument = gql`
  query NotesContextGetFiles(
    $clientNoteFilesInput: QueryClientNoteFilesInput!
    $eventFilesInput: QueryEventFilesInput!
  ) {
    clientNoteFiles(input: $clientNoteFilesInput) {
      id
      type
      dateOfFile
      value
      owner {
        id
      }
      file {
        id
        size
        createdAt
        createdBy {
          id
          ...PersonNames
        }
      }
    }
    eventFiles(input: $eventFilesInput) {
      id
      type
      dateOfFile
      value
      owner {
        id
      }
      file {
        id
        size
        createdAt
        createdBy {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useNotesContextGetFilesQuery__
 *
 * To run a query within a React component, call `useNotesContextGetFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesContextGetFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesContextGetFilesQuery({
 *   variables: {
 *      clientNoteFilesInput: // value for 'clientNoteFilesInput'
 *      eventFilesInput: // value for 'eventFilesInput'
 *   },
 * });
 */
export function useNotesContextGetFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotesContextGetFilesQuery,
    NotesContextGetFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotesContextGetFilesQuery,
    NotesContextGetFilesQueryVariables
  >(NotesContextGetFilesDocument, options);
}
export function useNotesContextGetFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotesContextGetFilesQuery,
    NotesContextGetFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotesContextGetFilesQuery,
    NotesContextGetFilesQueryVariables
  >(NotesContextGetFilesDocument, options);
}
export type NotesContextGetFilesQueryHookResult = ReturnType<
  typeof useNotesContextGetFilesQuery
>;
export type NotesContextGetFilesLazyQueryHookResult = ReturnType<
  typeof useNotesContextGetFilesLazyQuery
>;
export type NotesContextGetFilesQueryResult = Apollo.QueryResult<
  NotesContextGetFilesQuery,
  NotesContextGetFilesQueryVariables
>;
export const NotesImportFormGetClientIdsDocument = gql`
  query NotesImportFormGetClientIds($input: QueryClientsInput!) {
    clients(input: $input) {
      id
      externalId
    }
  }
`;

/**
 * __useNotesImportFormGetClientIdsQuery__
 *
 * To run a query within a React component, call `useNotesImportFormGetClientIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesImportFormGetClientIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesImportFormGetClientIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotesImportFormGetClientIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotesImportFormGetClientIdsQuery,
    NotesImportFormGetClientIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotesImportFormGetClientIdsQuery,
    NotesImportFormGetClientIdsQueryVariables
  >(NotesImportFormGetClientIdsDocument, options);
}
export function useNotesImportFormGetClientIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotesImportFormGetClientIdsQuery,
    NotesImportFormGetClientIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotesImportFormGetClientIdsQuery,
    NotesImportFormGetClientIdsQueryVariables
  >(NotesImportFormGetClientIdsDocument, options);
}
export type NotesImportFormGetClientIdsQueryHookResult = ReturnType<
  typeof useNotesImportFormGetClientIdsQuery
>;
export type NotesImportFormGetClientIdsLazyQueryHookResult = ReturnType<
  typeof useNotesImportFormGetClientIdsLazyQuery
>;
export type NotesImportFormGetClientIdsQueryResult = Apollo.QueryResult<
  NotesImportFormGetClientIdsQuery,
  NotesImportFormGetClientIdsQueryVariables
>;
export const NotesImportFormGetMemberIdsDocument = gql`
  query NotesImportFormGetMemberIds($input: QueryMembersInput!) {
    members(input: $input) {
      id
      externalId
    }
  }
`;

/**
 * __useNotesImportFormGetMemberIdsQuery__
 *
 * To run a query within a React component, call `useNotesImportFormGetMemberIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesImportFormGetMemberIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesImportFormGetMemberIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotesImportFormGetMemberIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotesImportFormGetMemberIdsQuery,
    NotesImportFormGetMemberIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotesImportFormGetMemberIdsQuery,
    NotesImportFormGetMemberIdsQueryVariables
  >(NotesImportFormGetMemberIdsDocument, options);
}
export function useNotesImportFormGetMemberIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotesImportFormGetMemberIdsQuery,
    NotesImportFormGetMemberIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotesImportFormGetMemberIdsQuery,
    NotesImportFormGetMemberIdsQueryVariables
  >(NotesImportFormGetMemberIdsDocument, options);
}
export type NotesImportFormGetMemberIdsQueryHookResult = ReturnType<
  typeof useNotesImportFormGetMemberIdsQuery
>;
export type NotesImportFormGetMemberIdsLazyQueryHookResult = ReturnType<
  typeof useNotesImportFormGetMemberIdsLazyQuery
>;
export type NotesImportFormGetMemberIdsQueryResult = Apollo.QueryResult<
  NotesImportFormGetMemberIdsQuery,
  NotesImportFormGetMemberIdsQueryVariables
>;
export const GetClientEnergyIntakeDocument = gql`
  query GetClientEnergyIntake($input: QueryClientObservationsInput!) {
    clientObservations(input: $input) {
      id
      date
      client {
        id
      }
      givenFood {
        id
        kilojoules
      }
    }
  }
`;

/**
 * __useGetClientEnergyIntakeQuery__
 *
 * To run a query within a React component, call `useGetClientEnergyIntakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientEnergyIntakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientEnergyIntakeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientEnergyIntakeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientEnergyIntakeQuery,
    GetClientEnergyIntakeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientEnergyIntakeQuery,
    GetClientEnergyIntakeQueryVariables
  >(GetClientEnergyIntakeDocument, options);
}
export function useGetClientEnergyIntakeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientEnergyIntakeQuery,
    GetClientEnergyIntakeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientEnergyIntakeQuery,
    GetClientEnergyIntakeQueryVariables
  >(GetClientEnergyIntakeDocument, options);
}
export type GetClientEnergyIntakeQueryHookResult = ReturnType<
  typeof useGetClientEnergyIntakeQuery
>;
export type GetClientEnergyIntakeLazyQueryHookResult = ReturnType<
  typeof useGetClientEnergyIntakeLazyQuery
>;
export type GetClientEnergyIntakeQueryResult = Apollo.QueryResult<
  GetClientEnergyIntakeQuery,
  GetClientEnergyIntakeQueryVariables
>;
export const GetClientObservationByIdDocument = gql`
  query GetClientObservationById($id: ID!) {
    clientObservationById(id: $id) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      weight
      stoolType
      urineColor
      hadSeizure
      seizureDuration
      seizureType
      seizureLostConsciousness
      incontinenceType
      postSeizureWalkDuration
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      client {
        id
      }
      event {
        id
      }
      member {
        id
        ...PersonNames
      }
      createdBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientObservationByIdQuery__
 *
 * To run a query within a React component, call `useGetClientObservationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientObservationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientObservationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientObservationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientObservationByIdQuery,
    GetClientObservationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientObservationByIdQuery,
    GetClientObservationByIdQueryVariables
  >(GetClientObservationByIdDocument, options);
}
export function useGetClientObservationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientObservationByIdQuery,
    GetClientObservationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientObservationByIdQuery,
    GetClientObservationByIdQueryVariables
  >(GetClientObservationByIdDocument, options);
}
export type GetClientObservationByIdQueryHookResult = ReturnType<
  typeof useGetClientObservationByIdQuery
>;
export type GetClientObservationByIdLazyQueryHookResult = ReturnType<
  typeof useGetClientObservationByIdLazyQuery
>;
export type GetClientObservationByIdQueryResult = Apollo.QueryResult<
  GetClientObservationByIdQuery,
  GetClientObservationByIdQueryVariables
>;
export const GetClientObservationsDocument = gql`
  query GetClientObservations($input: QueryClientObservationsInput!) {
    clientObservations(input: $input) {
      id
      date
      notes
      nursesName
      bloodSystolic
      bloodDiastolic
      heart
      temp
      spo2
      respiration
      weight
      stoolType
      urineColor
      hadSeizure
      seizureDuration
      seizureType
      seizureLostConsciousness
      incontinenceType
      postSeizureWalkDuration
      givenFood {
        id
        description
        kilojoules
      }
      givenMedications {
        id
        quantity
        medication {
          id
          name
        }
      }
      client {
        id
      }
      event {
        id
        member {
          id
          ...PersonNames
        }
      }
      member {
        id
        ...PersonNames
      }
      createdBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientObservationsQuery__
 *
 * To run a query within a React component, call `useGetClientObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientObservationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientObservationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientObservationsQuery,
    GetClientObservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientObservationsQuery,
    GetClientObservationsQueryVariables
  >(GetClientObservationsDocument, options);
}
export function useGetClientObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientObservationsQuery,
    GetClientObservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientObservationsQuery,
    GetClientObservationsQueryVariables
  >(GetClientObservationsDocument, options);
}
export type GetClientObservationsQueryHookResult = ReturnType<
  typeof useGetClientObservationsQuery
>;
export type GetClientObservationsLazyQueryHookResult = ReturnType<
  typeof useGetClientObservationsLazyQuery
>;
export type GetClientObservationsQueryResult = Apollo.QueryResult<
  GetClientObservationsQuery,
  GetClientObservationsQueryVariables
>;
export const GetClientTeamsDocument = gql`
  query GetClientTeams($input: QueryClientTeamsInput!) {
    clientTeams(input: $input) {
      id
      preferred
      client {
        id
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientTeamsQuery__
 *
 * To run a query within a React component, call `useGetClientTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientTeamsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientTeamsQuery,
    GetClientTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientTeamsQuery, GetClientTeamsQueryVariables>(
    GetClientTeamsDocument,
    options,
  );
}
export function useGetClientTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientTeamsQuery,
    GetClientTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientTeamsQuery, GetClientTeamsQueryVariables>(
    GetClientTeamsDocument,
    options,
  );
}
export type GetClientTeamsQueryHookResult = ReturnType<
  typeof useGetClientTeamsQuery
>;
export type GetClientTeamsLazyQueryHookResult = ReturnType<
  typeof useGetClientTeamsLazyQuery
>;
export type GetClientTeamsQueryResult = Apollo.QueryResult<
  GetClientTeamsQuery,
  GetClientTeamsQueryVariables
>;
export const ClientListContextGetAggregatedClientsDocument = gql`
  query ClientListContextGetAggregatedClients($input: QueryClientsInput!) {
    clientsAggregate(input: $input) {
      aggregate {
        totalCount
      }
      nodes {
        id
        createdAt
        color
        totalBillableMinutes
        trackFiles
        ...PersonNames
        firstBillableEvent {
          id
          startAt
        }
        lastBillableEvent {
          id
          startAt
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useClientListContextGetAggregatedClientsQuery__
 *
 * To run a query within a React component, call `useClientListContextGetAggregatedClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientListContextGetAggregatedClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientListContextGetAggregatedClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientListContextGetAggregatedClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientListContextGetAggregatedClientsQuery,
    ClientListContextGetAggregatedClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientListContextGetAggregatedClientsQuery,
    ClientListContextGetAggregatedClientsQueryVariables
  >(ClientListContextGetAggregatedClientsDocument, options);
}
export function useClientListContextGetAggregatedClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientListContextGetAggregatedClientsQuery,
    ClientListContextGetAggregatedClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientListContextGetAggregatedClientsQuery,
    ClientListContextGetAggregatedClientsQueryVariables
  >(ClientListContextGetAggregatedClientsDocument, options);
}
export type ClientListContextGetAggregatedClientsQueryHookResult = ReturnType<
  typeof useClientListContextGetAggregatedClientsQuery
>;
export type ClientListContextGetAggregatedClientsLazyQueryHookResult =
  ReturnType<typeof useClientListContextGetAggregatedClientsLazyQuery>;
export type ClientListContextGetAggregatedClientsQueryResult =
  Apollo.QueryResult<
    ClientListContextGetAggregatedClientsQuery,
    ClientListContextGetAggregatedClientsQueryVariables
  >;
export const ClientListContextGetAggregatedRedactedClientsDocument = gql`
  query ClientListContextGetAggregatedRedactedClients(
    $input: QueryRedactedClientsInput!
  ) {
    redactedClientsAggregate(input: $input) {
      aggregate {
        totalCount
      }
      nodes {
        id
        color
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useClientListContextGetAggregatedRedactedClientsQuery__
 *
 * To run a query within a React component, call `useClientListContextGetAggregatedRedactedClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientListContextGetAggregatedRedactedClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientListContextGetAggregatedRedactedClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientListContextGetAggregatedRedactedClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientListContextGetAggregatedRedactedClientsQuery,
    ClientListContextGetAggregatedRedactedClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientListContextGetAggregatedRedactedClientsQuery,
    ClientListContextGetAggregatedRedactedClientsQueryVariables
  >(ClientListContextGetAggregatedRedactedClientsDocument, options);
}
export function useClientListContextGetAggregatedRedactedClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientListContextGetAggregatedRedactedClientsQuery,
    ClientListContextGetAggregatedRedactedClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientListContextGetAggregatedRedactedClientsQuery,
    ClientListContextGetAggregatedRedactedClientsQueryVariables
  >(ClientListContextGetAggregatedRedactedClientsDocument, options);
}
export type ClientListContextGetAggregatedRedactedClientsQueryHookResult =
  ReturnType<typeof useClientListContextGetAggregatedRedactedClientsQuery>;
export type ClientListContextGetAggregatedRedactedClientsLazyQueryHookResult =
  ReturnType<typeof useClientListContextGetAggregatedRedactedClientsLazyQuery>;
export type ClientListContextGetAggregatedRedactedClientsQueryResult =
  Apollo.QueryResult<
    ClientListContextGetAggregatedRedactedClientsQuery,
    ClientListContextGetAggregatedRedactedClientsQueryVariables
  >;
export const GetClientByIdDocument = gql`
  query GetClientById($id: ID!) {
    clientById(id: $id) {
      id
      createdAt
      moduleCS
      moduleSC
      color
      dob
      email
      phone
      bio
      ndisId
      externalId
      ndisPlanStartDate
      ndisPlanEndDate
      ndisPlanInitialFundsCore
      ndisPlanInitialFundsCs
      ndisPlanInitialFundsCb
      defaultEventDuration
      active
      allowMedication
      allowObservations
      allowRecordSeizures
      allowAutomaticEventPopulation
      bloodSystolicUpperThreshold1
      bloodSystolicLowerThreshold1
      bloodDiastolicUpperThreshold1
      bloodDiastolicLowerThreshold1
      heartUpperThreshold1
      heartLowerThreshold1
      spo2UpperThreshold1
      spo2LowerThreshold1
      respirationUpperThreshold1
      respirationLowerThreshold1
      bloodSystolicUpperThreshold2
      bloodSystolicLowerThreshold2
      bloodDiastolicUpperThreshold2
      bloodDiastolicLowerThreshold2
      heartUpperThreshold2
      heartLowerThreshold2
      spo2UpperThreshold2
      spo2LowerThreshold2
      respirationUpperThreshold2
      respirationLowerThreshold2
      notes
      confidentialNotes
      requireHandover
      contactsName
      contactsEmail
      contactsPhone
      currentNdisPlan {
        id
        coreSupportItem {
          id
          currentFunds
        }
        supportCoordinationItem {
          id
          currentFunds
        }
      }
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      createdBy {
        id
        ...PersonNamesPartial
      }
      preferredContact {
        id
      }
      planManager {
        id
        name
      }
      supportCoordinator {
        id
        firstName
        lastName
      }
      archive {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
      delete {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetClientByIdQuery__
 *
 * To run a query within a React component, call `useGetClientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientByIdQuery,
    GetClientByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientByIdQuery, GetClientByIdQueryVariables>(
    GetClientByIdDocument,
    options,
  );
}
export function useGetClientByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientByIdQuery,
    GetClientByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientByIdQuery, GetClientByIdQueryVariables>(
    GetClientByIdDocument,
    options,
  );
}
export type GetClientByIdQueryHookResult = ReturnType<
  typeof useGetClientByIdQuery
>;
export type GetClientByIdLazyQueryHookResult = ReturnType<
  typeof useGetClientByIdLazyQuery
>;
export type GetClientByIdQueryResult = Apollo.QueryResult<
  GetClientByIdQuery,
  GetClientByIdQueryVariables
>;
export const GetClientIdsDocument = gql`
  query GetClientIds($input: QueryClientsInput!) {
    clients(input: $input) {
      id
    }
  }
`;

/**
 * __useGetClientIdsQuery__
 *
 * To run a query within a React component, call `useGetClientIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientIdsQuery,
    GetClientIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientIdsQuery, GetClientIdsQueryVariables>(
    GetClientIdsDocument,
    options,
  );
}
export function useGetClientIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientIdsQuery,
    GetClientIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientIdsQuery, GetClientIdsQueryVariables>(
    GetClientIdsDocument,
    options,
  );
}
export type GetClientIdsQueryHookResult = ReturnType<
  typeof useGetClientIdsQuery
>;
export type GetClientIdsLazyQueryHookResult = ReturnType<
  typeof useGetClientIdsLazyQuery
>;
export type GetClientIdsQueryResult = Apollo.QueryResult<
  GetClientIdsQuery,
  GetClientIdsQueryVariables
>;
export const GetClientMedicationsDocument = gql`
  query GetClientMedications($input: QueryClientMedicationsInput!) {
    clientMedications(input: $input) {
      id
      medication {
        id
        name
      }
      client {
        id
      }
    }
  }
`;

/**
 * __useGetClientMedicationsQuery__
 *
 * To run a query within a React component, call `useGetClientMedicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientMedicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientMedicationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientMedicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientMedicationsQuery,
    GetClientMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientMedicationsQuery,
    GetClientMedicationsQueryVariables
  >(GetClientMedicationsDocument, options);
}
export function useGetClientMedicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientMedicationsQuery,
    GetClientMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientMedicationsQuery,
    GetClientMedicationsQueryVariables
  >(GetClientMedicationsDocument, options);
}
export type GetClientMedicationsQueryHookResult = ReturnType<
  typeof useGetClientMedicationsQuery
>;
export type GetClientMedicationsLazyQueryHookResult = ReturnType<
  typeof useGetClientMedicationsLazyQuery
>;
export type GetClientMedicationsQueryResult = Apollo.QueryResult<
  GetClientMedicationsQuery,
  GetClientMedicationsQueryVariables
>;
export const GetClientsDocument = gql`
  query GetClients($input: QueryClientsInput!) {
    clients(input: $input) {
      id
      createdAt
      color
      dob
      email
      phone
      ndisId
      timezone
      defaultEventDuration
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      archive {
        id
      }
      delete {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export function useGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<
  typeof useGetClientsLazyQuery
>;
export type GetClientsQueryResult = Apollo.QueryResult<
  GetClientsQuery,
  GetClientsQueryVariables
>;
export const GetClientsAutocompleteDocument = gql`
  query GetClientsAutocomplete($input: QueryClientsInput!) {
    clients(input: $input) {
      id
      color
      ndisId
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientsAutocompleteQuery__
 *
 * To run a query within a React component, call `useGetClientsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsAutocompleteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientsAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientsAutocompleteQuery,
    GetClientsAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientsAutocompleteQuery,
    GetClientsAutocompleteQueryVariables
  >(GetClientsAutocompleteDocument, options);
}
export function useGetClientsAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsAutocompleteQuery,
    GetClientsAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientsAutocompleteQuery,
    GetClientsAutocompleteQueryVariables
  >(GetClientsAutocompleteDocument, options);
}
export type GetClientsAutocompleteQueryHookResult = ReturnType<
  typeof useGetClientsAutocompleteQuery
>;
export type GetClientsAutocompleteLazyQueryHookResult = ReturnType<
  typeof useGetClientsAutocompleteLazyQuery
>;
export type GetClientsAutocompleteQueryResult = Apollo.QueryResult<
  GetClientsAutocompleteQuery,
  GetClientsAutocompleteQueryVariables
>;
export const GetClientsNdisIdDocument = gql`
  query GetClientsNdisId($input: QueryClientsInput!) {
    clients(input: $input) {
      id
      ndisId
    }
  }
`;

/**
 * __useGetClientsNdisIdQuery__
 *
 * To run a query within a React component, call `useGetClientsNdisIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsNdisIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsNdisIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientsNdisIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientsNdisIdQuery,
    GetClientsNdisIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsNdisIdQuery, GetClientsNdisIdQueryVariables>(
    GetClientsNdisIdDocument,
    options,
  );
}
export function useGetClientsNdisIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsNdisIdQuery,
    GetClientsNdisIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientsNdisIdQuery,
    GetClientsNdisIdQueryVariables
  >(GetClientsNdisIdDocument, options);
}
export type GetClientsNdisIdQueryHookResult = ReturnType<
  typeof useGetClientsNdisIdQuery
>;
export type GetClientsNdisIdLazyQueryHookResult = ReturnType<
  typeof useGetClientsNdisIdLazyQuery
>;
export type GetClientsNdisIdQueryResult = Apollo.QueryResult<
  GetClientsNdisIdQuery,
  GetClientsNdisIdQueryVariables
>;
export const GetClientsSelectDocument = gql`
  query GetClientsSelect($input: QueryClientsInput!) {
    clients(input: $input) {
      id
      timezone
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientsSelectQuery__
 *
 * To run a query within a React component, call `useGetClientsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientsSelectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientsSelectQuery,
    GetClientsSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsSelectQuery, GetClientsSelectQueryVariables>(
    GetClientsSelectDocument,
    options,
  );
}
export function useGetClientsSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsSelectQuery,
    GetClientsSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientsSelectQuery,
    GetClientsSelectQueryVariables
  >(GetClientsSelectDocument, options);
}
export type GetClientsSelectQueryHookResult = ReturnType<
  typeof useGetClientsSelectQuery
>;
export type GetClientsSelectLazyQueryHookResult = ReturnType<
  typeof useGetClientsSelectLazyQuery
>;
export type GetClientsSelectQueryResult = Apollo.QueryResult<
  GetClientsSelectQuery,
  GetClientsSelectQueryVariables
>;
export const GetClientsSelectRedactedDocument = gql`
  query GetClientsSelectRedacted($input: QueryRedactedClientsInput!) {
    redactedClients(input: $input) {
      id
      timezone
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClientsSelectRedactedQuery__
 *
 * To run a query within a React component, call `useGetClientsSelectRedactedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsSelectRedactedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsSelectRedactedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientsSelectRedactedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientsSelectRedactedQuery,
    GetClientsSelectRedactedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientsSelectRedactedQuery,
    GetClientsSelectRedactedQueryVariables
  >(GetClientsSelectRedactedDocument, options);
}
export function useGetClientsSelectRedactedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsSelectRedactedQuery,
    GetClientsSelectRedactedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientsSelectRedactedQuery,
    GetClientsSelectRedactedQueryVariables
  >(GetClientsSelectRedactedDocument, options);
}
export type GetClientsSelectRedactedQueryHookResult = ReturnType<
  typeof useGetClientsSelectRedactedQuery
>;
export type GetClientsSelectRedactedLazyQueryHookResult = ReturnType<
  typeof useGetClientsSelectRedactedLazyQuery
>;
export type GetClientsSelectRedactedQueryResult = Apollo.QueryResult<
  GetClientsSelectRedactedQuery,
  GetClientsSelectRedactedQueryVariables
>;
export const GetRedactedClientByIdDocument = gql`
  query GetRedactedClientById($id: ID!) {
    redactedClientById(id: $id) {
      id
      color
      age
      allowMedication
      allowObservations
      allowRecordSeizures
      allowAutomaticEventPopulation
      bloodSystolicUpperThreshold1
      bloodSystolicLowerThreshold1
      bloodDiastolicUpperThreshold1
      bloodDiastolicLowerThreshold1
      heartUpperThreshold1
      heartLowerThreshold1
      spo2UpperThreshold1
      spo2LowerThreshold1
      respirationUpperThreshold1
      respirationLowerThreshold1
      bloodSystolicUpperThreshold2
      bloodSystolicLowerThreshold2
      bloodDiastolicUpperThreshold2
      bloodDiastolicLowerThreshold2
      heartUpperThreshold2
      heartLowerThreshold2
      spo2UpperThreshold2
      spo2LowerThreshold2
      respirationUpperThreshold2
      respirationLowerThreshold2
      requireHandover
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;

/**
 * __useGetRedactedClientByIdQuery__
 *
 * To run a query within a React component, call `useGetRedactedClientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedClientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedClientByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRedactedClientByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedactedClientByIdQuery,
    GetRedactedClientByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRedactedClientByIdQuery,
    GetRedactedClientByIdQueryVariables
  >(GetRedactedClientByIdDocument, options);
}
export function useGetRedactedClientByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedClientByIdQuery,
    GetRedactedClientByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRedactedClientByIdQuery,
    GetRedactedClientByIdQueryVariables
  >(GetRedactedClientByIdDocument, options);
}
export type GetRedactedClientByIdQueryHookResult = ReturnType<
  typeof useGetRedactedClientByIdQuery
>;
export type GetRedactedClientByIdLazyQueryHookResult = ReturnType<
  typeof useGetRedactedClientByIdLazyQuery
>;
export type GetRedactedClientByIdQueryResult = Apollo.QueryResult<
  GetRedactedClientByIdQuery,
  GetRedactedClientByIdQueryVariables
>;
export const GetRedactedClientMedicationsDocument = gql`
  query GetRedactedClientMedications($input: QueryClientMedicationsInput!) {
    clientMedications(input: $input) {
      id
      medication {
        id
        name
      }
      client {
        id
      }
    }
  }
`;

/**
 * __useGetRedactedClientMedicationsQuery__
 *
 * To run a query within a React component, call `useGetRedactedClientMedicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedClientMedicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedClientMedicationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRedactedClientMedicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedactedClientMedicationsQuery,
    GetRedactedClientMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRedactedClientMedicationsQuery,
    GetRedactedClientMedicationsQueryVariables
  >(GetRedactedClientMedicationsDocument, options);
}
export function useGetRedactedClientMedicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedClientMedicationsQuery,
    GetRedactedClientMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRedactedClientMedicationsQuery,
    GetRedactedClientMedicationsQueryVariables
  >(GetRedactedClientMedicationsDocument, options);
}
export type GetRedactedClientMedicationsQueryHookResult = ReturnType<
  typeof useGetRedactedClientMedicationsQuery
>;
export type GetRedactedClientMedicationsLazyQueryHookResult = ReturnType<
  typeof useGetRedactedClientMedicationsLazyQuery
>;
export type GetRedactedClientMedicationsQueryResult = Apollo.QueryResult<
  GetRedactedClientMedicationsQuery,
  GetRedactedClientMedicationsQueryVariables
>;
export const GetRedactedClientTimezoneDocument = gql`
  query GetRedactedClientTimezone($id: ID!) {
    redactedClientById(id: $id) {
      id
      timezone
    }
  }
`;

/**
 * __useGetRedactedClientTimezoneQuery__
 *
 * To run a query within a React component, call `useGetRedactedClientTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedClientTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedClientTimezoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRedactedClientTimezoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedactedClientTimezoneQuery,
    GetRedactedClientTimezoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRedactedClientTimezoneQuery,
    GetRedactedClientTimezoneQueryVariables
  >(GetRedactedClientTimezoneDocument, options);
}
export function useGetRedactedClientTimezoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedClientTimezoneQuery,
    GetRedactedClientTimezoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRedactedClientTimezoneQuery,
    GetRedactedClientTimezoneQueryVariables
  >(GetRedactedClientTimezoneDocument, options);
}
export type GetRedactedClientTimezoneQueryHookResult = ReturnType<
  typeof useGetRedactedClientTimezoneQuery
>;
export type GetRedactedClientTimezoneLazyQueryHookResult = ReturnType<
  typeof useGetRedactedClientTimezoneLazyQuery
>;
export type GetRedactedClientTimezoneQueryResult = Apollo.QueryResult<
  GetRedactedClientTimezoneQuery,
  GetRedactedClientTimezoneQueryVariables
>;
export const GetRedactedClientsDocument = gql`
  query GetRedactedClients($input: QueryRedactedClientsInput!) {
    redactedClients(input: $input) {
      id
      color
      age
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;

/**
 * __useGetRedactedClientsQuery__
 *
 * To run a query within a React component, call `useGetRedactedClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRedactedClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedactedClientsQuery,
    GetRedactedClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRedactedClientsQuery,
    GetRedactedClientsQueryVariables
  >(GetRedactedClientsDocument, options);
}
export function useGetRedactedClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedClientsQuery,
    GetRedactedClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRedactedClientsQuery,
    GetRedactedClientsQueryVariables
  >(GetRedactedClientsDocument, options);
}
export type GetRedactedClientsQueryHookResult = ReturnType<
  typeof useGetRedactedClientsQuery
>;
export type GetRedactedClientsLazyQueryHookResult = ReturnType<
  typeof useGetRedactedClientsLazyQuery
>;
export type GetRedactedClientsQueryResult = Apollo.QueryResult<
  GetRedactedClientsQuery,
  GetRedactedClientsQueryVariables
>;
export const ValidateClientExternalIdsDocument = gql`
  query ValidateClientExternalIds($input: ClientsValidateExternalldsInput!) {
    validateClientExternalIds(input: $input) {
      ids
    }
  }
`;

/**
 * __useValidateClientExternalIdsQuery__
 *
 * To run a query within a React component, call `useValidateClientExternalIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateClientExternalIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateClientExternalIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateClientExternalIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateClientExternalIdsQuery,
    ValidateClientExternalIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateClientExternalIdsQuery,
    ValidateClientExternalIdsQueryVariables
  >(ValidateClientExternalIdsDocument, options);
}
export function useValidateClientExternalIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateClientExternalIdsQuery,
    ValidateClientExternalIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateClientExternalIdsQuery,
    ValidateClientExternalIdsQueryVariables
  >(ValidateClientExternalIdsDocument, options);
}
export type ValidateClientExternalIdsQueryHookResult = ReturnType<
  typeof useValidateClientExternalIdsQuery
>;
export type ValidateClientExternalIdsLazyQueryHookResult = ReturnType<
  typeof useValidateClientExternalIdsLazyQuery
>;
export type ValidateClientExternalIdsQueryResult = Apollo.QueryResult<
  ValidateClientExternalIdsQuery,
  ValidateClientExternalIdsQueryVariables
>;
export const GetConflictyIdDocument = gql`
  query GetConflictyId($id: ID!) {
    conflictById(id: $id) {
      id
      startAt
      endAt
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetConflictyIdQuery__
 *
 * To run a query within a React component, call `useGetConflictyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConflictyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConflictyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConflictyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConflictyIdQuery,
    GetConflictyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConflictyIdQuery, GetConflictyIdQueryVariables>(
    GetConflictyIdDocument,
    options,
  );
}
export function useGetConflictyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConflictyIdQuery,
    GetConflictyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConflictyIdQuery, GetConflictyIdQueryVariables>(
    GetConflictyIdDocument,
    options,
  );
}
export type GetConflictyIdQueryHookResult = ReturnType<
  typeof useGetConflictyIdQuery
>;
export type GetConflictyIdLazyQueryHookResult = ReturnType<
  typeof useGetConflictyIdLazyQuery
>;
export type GetConflictyIdQueryResult = Apollo.QueryResult<
  GetConflictyIdQuery,
  GetConflictyIdQueryVariables
>;
export const GetConflictsDocument = gql`
  query GetConflicts($input: QueryConflictsInput!) {
    conflicts(input: $input) {
      id
      startAt
      endAt
      member {
        id
        ...PersonNames
      }
      eventConflicts {
        event {
          id
        }
        conflict {
          id
        }
      }
      unavailableConflicts {
        memberUnavailable {
          id
        }
        conflict {
          id
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetConflictsQuery__
 *
 * To run a query within a React component, call `useGetConflictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConflictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConflictsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetConflictsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConflictsQuery,
    GetConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConflictsQuery, GetConflictsQueryVariables>(
    GetConflictsDocument,
    options,
  );
}
export function useGetConflictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConflictsQuery,
    GetConflictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConflictsQuery, GetConflictsQueryVariables>(
    GetConflictsDocument,
    options,
  );
}
export type GetConflictsQueryHookResult = ReturnType<
  typeof useGetConflictsQuery
>;
export type GetConflictsLazyQueryHookResult = ReturnType<
  typeof useGetConflictsLazyQuery
>;
export type GetConflictsQueryResult = Apollo.QueryResult<
  GetConflictsQuery,
  GetConflictsQueryVariables
>;
export const GetClaimedEventsDocument = gql`
  query GetClaimedEvents($input: QueryEventClaimsInput!) {
    eventClaims(input: $input) {
      id
      createdAt
      dismissedAt
      member {
        id
        color
        ...PersonNames
      }
      event {
        id
        startAt
        endAt
        locality
        region
        requireCar
        client {
          id
          color
          ...PersonNames
        }
        archive {
          id
        }
        delete {
          id
        }
        cancel {
          id
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClaimedEventsQuery__
 *
 * To run a query within a React component, call `useGetClaimedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimedEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClaimedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClaimedEventsQuery,
    GetClaimedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClaimedEventsQuery, GetClaimedEventsQueryVariables>(
    GetClaimedEventsDocument,
    options,
  );
}
export function useGetClaimedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClaimedEventsQuery,
    GetClaimedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClaimedEventsQuery,
    GetClaimedEventsQueryVariables
  >(GetClaimedEventsDocument, options);
}
export type GetClaimedEventsQueryHookResult = ReturnType<
  typeof useGetClaimedEventsQuery
>;
export type GetClaimedEventsLazyQueryHookResult = ReturnType<
  typeof useGetClaimedEventsLazyQuery
>;
export type GetClaimedEventsQueryResult = Apollo.QueryResult<
  GetClaimedEventsQuery,
  GetClaimedEventsQueryVariables
>;
export const GetEventClaimsDocument = gql`
  query GetEventClaims(
    $eventClaimsInput: QueryEventClaimsInput!
    $hoursInput: QueryMemberHoursInput!
  ) {
    eventClaims(input: $eventClaimsInput) {
      id
      createdAt
      member {
        id
        schedulable
        ...PersonNames
        hours(input: $hoursInput) {
          id
          date
          type
          value
        }
      }
      event {
        id
      }
      issues {
        reasons
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventClaimsQuery__
 *
 * To run a query within a React component, call `useGetEventClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventClaimsQuery({
 *   variables: {
 *      eventClaimsInput: // value for 'eventClaimsInput'
 *      hoursInput: // value for 'hoursInput'
 *   },
 * });
 */
export function useGetEventClaimsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventClaimsQuery,
    GetEventClaimsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventClaimsQuery, GetEventClaimsQueryVariables>(
    GetEventClaimsDocument,
    options,
  );
}
export function useGetEventClaimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventClaimsQuery,
    GetEventClaimsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventClaimsQuery, GetEventClaimsQueryVariables>(
    GetEventClaimsDocument,
    options,
  );
}
export type GetEventClaimsQueryHookResult = ReturnType<
  typeof useGetEventClaimsQuery
>;
export type GetEventClaimsLazyQueryHookResult = ReturnType<
  typeof useGetEventClaimsLazyQuery
>;
export type GetEventClaimsQueryResult = Apollo.QueryResult<
  GetEventClaimsQuery,
  GetEventClaimsQueryVariables
>;
export const GetEventClaimsNotificationBadgeDocument = gql`
  query GetEventClaimsNotificationBadge($input: QueryEventClaimsInput!) {
    eventClaims(input: $input) {
      id
      dismissedAt
    }
  }
`;

/**
 * __useGetEventClaimsNotificationBadgeQuery__
 *
 * To run a query within a React component, call `useGetEventClaimsNotificationBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventClaimsNotificationBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventClaimsNotificationBadgeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventClaimsNotificationBadgeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventClaimsNotificationBadgeQuery,
    GetEventClaimsNotificationBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventClaimsNotificationBadgeQuery,
    GetEventClaimsNotificationBadgeQueryVariables
  >(GetEventClaimsNotificationBadgeDocument, options);
}
export function useGetEventClaimsNotificationBadgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventClaimsNotificationBadgeQuery,
    GetEventClaimsNotificationBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventClaimsNotificationBadgeQuery,
    GetEventClaimsNotificationBadgeQueryVariables
  >(GetEventClaimsNotificationBadgeDocument, options);
}
export type GetEventClaimsNotificationBadgeQueryHookResult = ReturnType<
  typeof useGetEventClaimsNotificationBadgeQuery
>;
export type GetEventClaimsNotificationBadgeLazyQueryHookResult = ReturnType<
  typeof useGetEventClaimsNotificationBadgeLazyQuery
>;
export type GetEventClaimsNotificationBadgeQueryResult = Apollo.QueryResult<
  GetEventClaimsNotificationBadgeQuery,
  GetEventClaimsNotificationBadgeQueryVariables
>;
export const GetEventsWithClaimsDocument = gql`
  query GetEventsWithClaims(
    $eventsInput: QueryEventsInput!
    $claimsInput: QueryEventClaimsInput!
  ) {
    events(input: $eventsInput) {
      id
      startAt
      endAt
      locality
      region
      requireCar
      client {
        id
        color
        ...PersonNames
      }
      claims(input: $claimsInput) {
        id
        createdAt
        member {
          id
          color
          hasCar
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsWithClaimsQuery__
 *
 * To run a query within a React component, call `useGetEventsWithClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsWithClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsWithClaimsQuery({
 *   variables: {
 *      eventsInput: // value for 'eventsInput'
 *      claimsInput: // value for 'claimsInput'
 *   },
 * });
 */
export function useGetEventsWithClaimsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsWithClaimsQuery,
    GetEventsWithClaimsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsWithClaimsQuery,
    GetEventsWithClaimsQueryVariables
  >(GetEventsWithClaimsDocument, options);
}
export function useGetEventsWithClaimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsWithClaimsQuery,
    GetEventsWithClaimsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsWithClaimsQuery,
    GetEventsWithClaimsQueryVariables
  >(GetEventsWithClaimsDocument, options);
}
export type GetEventsWithClaimsQueryHookResult = ReturnType<
  typeof useGetEventsWithClaimsQuery
>;
export type GetEventsWithClaimsLazyQueryHookResult = ReturnType<
  typeof useGetEventsWithClaimsLazyQuery
>;
export type GetEventsWithClaimsQueryResult = Apollo.QueryResult<
  GetEventsWithClaimsQuery,
  GetEventsWithClaimsQueryVariables
>;
export const GetOldClaimedEventsDocument = gql`
  query GetOldClaimedEvents($input: QueryEventClaimsInput!) {
    eventClaims(input: $input) {
      id
      createdAt
      dismissedAt
      member {
        id
        schedulable
        ...PersonNames
      }
      dismissedBy {
        id
        ...PersonNames
      }
      event {
        id
        startAt
        endAt
        locality
        region
        requireCar
        client {
          id
          color
          ...PersonNames
        }
        member {
          id
        }
        archive {
          id
        }
        delete {
          id
        }
        cancel {
          id
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetOldClaimedEventsQuery__
 *
 * To run a query within a React component, call `useGetOldClaimedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOldClaimedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOldClaimedEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOldClaimedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOldClaimedEventsQuery,
    GetOldClaimedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOldClaimedEventsQuery,
    GetOldClaimedEventsQueryVariables
  >(GetOldClaimedEventsDocument, options);
}
export function useGetOldClaimedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOldClaimedEventsQuery,
    GetOldClaimedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOldClaimedEventsQuery,
    GetOldClaimedEventsQueryVariables
  >(GetOldClaimedEventsDocument, options);
}
export type GetOldClaimedEventsQueryHookResult = ReturnType<
  typeof useGetOldClaimedEventsQuery
>;
export type GetOldClaimedEventsLazyQueryHookResult = ReturnType<
  typeof useGetOldClaimedEventsLazyQuery
>;
export type GetOldClaimedEventsQueryResult = Apollo.QueryResult<
  GetOldClaimedEventsQuery,
  GetOldClaimedEventsQueryVariables
>;
export const GetOrgClaimRangeSettingDocument = gql`
  query GetOrgClaimRangeSetting {
    me {
      id
      member {
        id
        org {
          id
          claimEventsDayRange
        }
      }
    }
  }
`;

/**
 * __useGetOrgClaimRangeSettingQuery__
 *
 * To run a query within a React component, call `useGetOrgClaimRangeSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgClaimRangeSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgClaimRangeSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgClaimRangeSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrgClaimRangeSettingQuery,
    GetOrgClaimRangeSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgClaimRangeSettingQuery,
    GetOrgClaimRangeSettingQueryVariables
  >(GetOrgClaimRangeSettingDocument, options);
}
export function useGetOrgClaimRangeSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgClaimRangeSettingQuery,
    GetOrgClaimRangeSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgClaimRangeSettingQuery,
    GetOrgClaimRangeSettingQueryVariables
  >(GetOrgClaimRangeSettingDocument, options);
}
export type GetOrgClaimRangeSettingQueryHookResult = ReturnType<
  typeof useGetOrgClaimRangeSettingQuery
>;
export type GetOrgClaimRangeSettingLazyQueryHookResult = ReturnType<
  typeof useGetOrgClaimRangeSettingLazyQuery
>;
export type GetOrgClaimRangeSettingQueryResult = Apollo.QueryResult<
  GetOrgClaimRangeSettingQuery,
  GetOrgClaimRangeSettingQueryVariables
>;
export const DownloadEventFileByIdDocument = gql`
  query DownloadEventFileById($id: ID!) {
    downloadEventFileById(id: $id) {
      id
      path
    }
  }
`;

/**
 * __useDownloadEventFileByIdQuery__
 *
 * To run a query within a React component, call `useDownloadEventFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadEventFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadEventFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadEventFileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadEventFileByIdQuery,
    DownloadEventFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadEventFileByIdQuery,
    DownloadEventFileByIdQueryVariables
  >(DownloadEventFileByIdDocument, options);
}
export function useDownloadEventFileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadEventFileByIdQuery,
    DownloadEventFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadEventFileByIdQuery,
    DownloadEventFileByIdQueryVariables
  >(DownloadEventFileByIdDocument, options);
}
export type DownloadEventFileByIdQueryHookResult = ReturnType<
  typeof useDownloadEventFileByIdQuery
>;
export type DownloadEventFileByIdLazyQueryHookResult = ReturnType<
  typeof useDownloadEventFileByIdLazyQuery
>;
export type DownloadEventFileByIdQueryResult = Apollo.QueryResult<
  DownloadEventFileByIdQuery,
  DownloadEventFileByIdQueryVariables
>;
export const GetEventFilesDocument = gql`
  query GetEventFiles($input: QueryEventFilesInput!) {
    eventFiles(input: $input) {
      id
      type
      dateOfFile
      value
      file {
        id
        size
        createdAt
        createdBy {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventFilesQuery__
 *
 * To run a query within a React component, call `useGetEventFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventFilesQuery,
    GetEventFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventFilesQuery, GetEventFilesQueryVariables>(
    GetEventFilesDocument,
    options,
  );
}
export function useGetEventFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventFilesQuery,
    GetEventFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventFilesQuery, GetEventFilesQueryVariables>(
    GetEventFilesDocument,
    options,
  );
}
export type GetEventFilesQueryHookResult = ReturnType<
  typeof useGetEventFilesQuery
>;
export type GetEventFilesLazyQueryHookResult = ReturnType<
  typeof useGetEventFilesLazyQuery
>;
export type GetEventFilesQueryResult = Apollo.QueryResult<
  GetEventFilesQuery,
  GetEventFilesQueryVariables
>;
export const GetEventAccessLogsDocument = gql`
  query GetEventAccessLogs($input: QueryAccessLogsInput!) {
    accessLogs(input: $input) {
      id
      date
      ip
      actor {
        id
        ...PersonNames
      }
      event {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventAccessLogsQuery__
 *
 * To run a query within a React component, call `useGetEventAccessLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventAccessLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventAccessLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventAccessLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventAccessLogsQuery,
    GetEventAccessLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventAccessLogsQuery,
    GetEventAccessLogsQueryVariables
  >(GetEventAccessLogsDocument, options);
}
export function useGetEventAccessLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventAccessLogsQuery,
    GetEventAccessLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventAccessLogsQuery,
    GetEventAccessLogsQueryVariables
  >(GetEventAccessLogsDocument, options);
}
export type GetEventAccessLogsQueryHookResult = ReturnType<
  typeof useGetEventAccessLogsQuery
>;
export type GetEventAccessLogsLazyQueryHookResult = ReturnType<
  typeof useGetEventAccessLogsLazyQuery
>;
export type GetEventAccessLogsQueryResult = Apollo.QueryResult<
  GetEventAccessLogsQuery,
  GetEventAccessLogsQueryVariables
>;
export const GetEventByIdDocument = gql`
  query GetEventById(
    $id: ID!
    $eventIssuesInput: QueryEventIssuesInput!
    $fileTypeInput: QueryClientHasFileInput!
  ) {
    eventById(id: $id) {
      id
      createdAt
      createdBy {
        id
        ...PersonNames
      }
      verified
      verifiedAt
      locked
      startAt
      endAt
      duration
      summary
      scheduleNotes
      passive
      passiveStartAt
      activeAssist
      billable
      billingRegistrationGroup
      payable
      bonusPay
      publicHoliday
      memberAssignedAutomatically
      ...AddressFull
      ...AddressGeolocation
      clockedOnAt
      clockedOffAt
      clockedOnLatitude
      clockedOnLongitude
      clockedOffLatitude
      clockedOffLongitude
      revenue
      clockedOnBy {
        id
        color
        ...PersonNames
      }
      clockedOffBy {
        id
        color
        ...PersonNames
      }
      travelDistance
      travelDistanceNotes
      travelTime
      travelTimeNotes
      travelApprovedBy {
        id
        ...PersonNames
      }
      requireCar
      client {
        id
        color
        timezone
        defaultEventDuration
        allowMedication
        allowObservations
        allowRecordSeizures
        requireHandover
        bloodSystolicUpperThreshold1
        bloodSystolicLowerThreshold1
        bloodDiastolicUpperThreshold1
        bloodDiastolicLowerThreshold1
        heartUpperThreshold1
        heartLowerThreshold1
        spo2UpperThreshold1
        spo2LowerThreshold1
        respirationUpperThreshold1
        respirationLowerThreshold1
        bloodSystolicUpperThreshold2
        bloodSystolicLowerThreshold2
        bloodDiastolicUpperThreshold2
        bloodDiastolicLowerThreshold2
        heartUpperThreshold2
        heartLowerThreshold2
        spo2UpperThreshold2
        spo2LowerThreshold2
        respirationUpperThreshold2
        respirationLowerThreshold2
        ...PersonNames
        hasFile(input: $fileTypeInput)
      }
      member {
        id
        color
        timezone
        lateCount
        canPromptMedication
        hasCertSupportingSafeMeals
        hasCertEffectiveCommunication
        hasCertPreventingDementia
        hasCertUnderstandingDementia
        hasCertManualHandling
        hasCertSeizureRecognition
        hasCar
        canHoist
        bonusEligible
        ...PersonNames
      }
      issues(input: $eventIssuesInput) {
        id
        type
        closedAt
      }
      archive {
        id
        date
        reason
        actor {
          id
          ...PersonNames
        }
      }
      delete {
        id
        date
        reason
        actor {
          id
          ...PersonNames
        }
      }
      cancel {
        id
        date
        reason
        actor {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
`;

/**
 * __useGetEventByIdQuery__
 *
 * To run a query within a React component, call `useGetEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      eventIssuesInput: // value for 'eventIssuesInput'
 *      fileTypeInput: // value for 'fileTypeInput'
 *   },
 * });
 */
export function useGetEventByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventByIdQuery,
    GetEventByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(
    GetEventByIdDocument,
    options,
  );
}
export function useGetEventByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventByIdQuery,
    GetEventByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventByIdQuery, GetEventByIdQueryVariables>(
    GetEventByIdDocument,
    options,
  );
}
export type GetEventByIdQueryHookResult = ReturnType<
  typeof useGetEventByIdQuery
>;
export type GetEventByIdLazyQueryHookResult = ReturnType<
  typeof useGetEventByIdLazyQuery
>;
export type GetEventByIdQueryResult = Apollo.QueryResult<
  GetEventByIdQuery,
  GetEventByIdQueryVariables
>;
export const GetEventCreatorDocument = gql`
  query GetEventCreator($id: ID!) {
    eventById(id: $id) {
      id
      createdAt
      createdBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventCreatorQuery__
 *
 * To run a query within a React component, call `useGetEventCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventCreatorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventCreatorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventCreatorQuery,
    GetEventCreatorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventCreatorQuery, GetEventCreatorQueryVariables>(
    GetEventCreatorDocument,
    options,
  );
}
export function useGetEventCreatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventCreatorQuery,
    GetEventCreatorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventCreatorQuery,
    GetEventCreatorQueryVariables
  >(GetEventCreatorDocument, options);
}
export type GetEventCreatorQueryHookResult = ReturnType<
  typeof useGetEventCreatorQuery
>;
export type GetEventCreatorLazyQueryHookResult = ReturnType<
  typeof useGetEventCreatorLazyQuery
>;
export type GetEventCreatorQueryResult = Apollo.QueryResult<
  GetEventCreatorQuery,
  GetEventCreatorQueryVariables
>;
export const GetEventHistoriesDocument = gql`
  query GetEventHistories($input: QueryHistoriesInput!) {
    histories(input: $input) {
      ... on BaseHistory {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
      }
      ... on HistoryRestorable {
        id
        date
        type
        reason
        actor {
          id
          firstName
          lastName
        }
        restoredAt
        restoredBy {
          id
          firstName
          middleName
          lastName
          preferredName
        }
      }
      ... on HistoryEventActiveAssist {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventActiveAssistNew
        eventActiveAssistOld
      }
      ... on HistoryEventBillable {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventBillablenew
        eventBillableOld
      }
      ... on HistoryEventBillingRegistrationGroup {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventBillingRegistrationGroupNew
        eventBillingRegistrationGroupOld
      }
      ... on HistoryEventClient {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventClientNew {
          id
          firstName
          lastName
        }
        eventClientOld {
          id
          firstName
          lastName
        }
      }
      ... on HistoryEventCountry {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventCountryNew
        eventCountryOld
      }
      ... on HistoryEventDuration {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventDurationNew
        eventDurationOld
      }
      ... on HistoryEventLocality {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventLocalityNew
        eventLocalityOld
      }
      ... on HistoryEventMember {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventMemberNew {
          id
          firstName
          lastName
        }
        eventMemberOld {
          id
          firstName
          lastName
        }
      }
      ... on HistoryEventNotes {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventPassiveStartAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPassiveStartAtNew
        eventPassiveStartAtOld
      }
      ... on HistoryEventPassive {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPassiveNew
        eventPassiveOld
      }
      ... on HistoryEventPayable {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPayableNew
        eventPayableOld
      }
      ... on HistoryEventRequireCar {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventRequireCarNew
        eventRequireCarOld
      }
      ... on HistoryEventPostcode {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPostcodeNew
        eventPostcodeOld
      }
      ... on HistoryEventPublicHoliday {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventPublicHolidayNew
        eventPublicHolidayOld
      }
      ... on HistoryEventRegion {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventRegionNew
        eventRegionOld
      }
      ... on HistoryEventScheduleNotes {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventStartAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventStartAtNew
        eventStartAtOld
      }
      ... on HistoryEventClockedOnAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventClockedOnAtNew
        eventClockedOnAtOld
      }
      ... on HistoryEventClockedOffAt {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventClockedOffAtNew
        eventClockedOffAtOld
      }
      ... on HistoryEventStreet {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventStreetNew
        eventStreetOld
      }
      ... on HistoryEventSummary {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventTravelDistanceNotes {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventTravelDistance {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventTravelDistanceNew
        eventTravelDistanceOld
      }
      ... on HistoryEventTravelTimeNotes {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
      }
      ... on HistoryEventTravelTime {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventTravelTimeNew
        eventTravelTimeOld
      }
      ... on HistoryEventUnit {
        id
        date
        type
        actor {
          id
          firstName
          lastName
        }
        event {
          id
        }
        eventUnitNew
        eventUnitOld
      }
    }
  }
`;

/**
 * __useGetEventHistoriesQuery__
 *
 * To run a query within a React component, call `useGetEventHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventHistoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventHistoriesQuery,
    GetEventHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventHistoriesQuery,
    GetEventHistoriesQueryVariables
  >(GetEventHistoriesDocument, options);
}
export function useGetEventHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventHistoriesQuery,
    GetEventHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventHistoriesQuery,
    GetEventHistoriesQueryVariables
  >(GetEventHistoriesDocument, options);
}
export type GetEventHistoriesQueryHookResult = ReturnType<
  typeof useGetEventHistoriesQuery
>;
export type GetEventHistoriesLazyQueryHookResult = ReturnType<
  typeof useGetEventHistoriesLazyQuery
>;
export type GetEventHistoriesQueryResult = Apollo.QueryResult<
  GetEventHistoriesQuery,
  GetEventHistoriesQueryVariables
>;
export const GetEventOwnAndRelatedByIdDocument = gql`
  query GetEventOwnAndRelatedById(
    $id: ID!
    $fileTypeInput: QueryClientHasFileInput!
    $eventIssuesInput: QueryEventIssuesInput!
  ) {
    eventOwnAndRelatedById(id: $id) {
      id
      startAt
      endAt
      duration
      notes
      summary
      passive
      passiveStartAt
      activeAssist
      publicHoliday
      memberAssignedAutomatically
      unit
      street
      locality
      region
      postcode
      country
      latitude
      longitude
      clockedOnAt
      clockedOffAt
      travelDistance
      travelDistanceNotes
      travelTime
      travelTimeNotes
      requireCar
      client {
        id
        color
        timezone
        allowMedication
        allowObservations
        allowRecordSeizures
        bloodSystolicUpperThreshold1
        bloodSystolicLowerThreshold1
        bloodDiastolicUpperThreshold1
        bloodDiastolicLowerThreshold1
        heartUpperThreshold1
        heartLowerThreshold1
        spo2UpperThreshold1
        spo2LowerThreshold1
        respirationUpperThreshold1
        respirationLowerThreshold1
        bloodSystolicUpperThreshold2
        bloodSystolicLowerThreshold2
        bloodDiastolicUpperThreshold2
        bloodDiastolicLowerThreshold2
        heartUpperThreshold2
        heartLowerThreshold2
        spo2UpperThreshold2
        spo2LowerThreshold2
        respirationUpperThreshold2
        respirationLowerThreshold2
        requireHandover
        ...PersonNames
        hasFile(input: $fileTypeInput)
      }
      member {
        id
        color
        timezone
        lateCount
        ...PersonNames
      }
      issues(input: $eventIssuesInput) {
        id
        type
        closedAt
      }
      cancel {
        id
        date
        reason
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventOwnAndRelatedByIdQuery__
 *
 * To run a query within a React component, call `useGetEventOwnAndRelatedByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventOwnAndRelatedByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventOwnAndRelatedByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fileTypeInput: // value for 'fileTypeInput'
 *      eventIssuesInput: // value for 'eventIssuesInput'
 *   },
 * });
 */
export function useGetEventOwnAndRelatedByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventOwnAndRelatedByIdQuery,
    GetEventOwnAndRelatedByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventOwnAndRelatedByIdQuery,
    GetEventOwnAndRelatedByIdQueryVariables
  >(GetEventOwnAndRelatedByIdDocument, options);
}
export function useGetEventOwnAndRelatedByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventOwnAndRelatedByIdQuery,
    GetEventOwnAndRelatedByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventOwnAndRelatedByIdQuery,
    GetEventOwnAndRelatedByIdQueryVariables
  >(GetEventOwnAndRelatedByIdDocument, options);
}
export type GetEventOwnAndRelatedByIdQueryHookResult = ReturnType<
  typeof useGetEventOwnAndRelatedByIdQuery
>;
export type GetEventOwnAndRelatedByIdLazyQueryHookResult = ReturnType<
  typeof useGetEventOwnAndRelatedByIdLazyQuery
>;
export type GetEventOwnAndRelatedByIdQueryResult = Apollo.QueryResult<
  GetEventOwnAndRelatedByIdQuery,
  GetEventOwnAndRelatedByIdQueryVariables
>;
export const GetEventsPotentialAttendeesDocument = gql`
  query GetEventsPotentialAttendees(
    $input: QueryEventsPotentialAttendeesInput!
    $scheduleInput: QueryMemberScheduleInput!
  ) {
    eventPotentialAttendees(input: $input) {
      members {
        id
        color
        hasCar
        lateCount
        workRights
        bonusEligible
        locality
        region
        schedule(input: $scheduleInput) {
          totalMinutes
          allowedMinutes
        }
        ...PersonNames
      }
      hours {
        id
        thisWeek
        prevWeek
        nextWeek
      }
      conflicts {
        id
        client {
          id
          ...PersonNames
        }
      }
      unavailables {
        id
      }
      teams {
        id
        preferred
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsPotentialAttendeesQuery__
 *
 * To run a query within a React component, call `useGetEventsPotentialAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsPotentialAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsPotentialAttendeesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      scheduleInput: // value for 'scheduleInput'
 *   },
 * });
 */
export function useGetEventsPotentialAttendeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsPotentialAttendeesQuery,
    GetEventsPotentialAttendeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsPotentialAttendeesQuery,
    GetEventsPotentialAttendeesQueryVariables
  >(GetEventsPotentialAttendeesDocument, options);
}
export function useGetEventsPotentialAttendeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsPotentialAttendeesQuery,
    GetEventsPotentialAttendeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsPotentialAttendeesQuery,
    GetEventsPotentialAttendeesQueryVariables
  >(GetEventsPotentialAttendeesDocument, options);
}
export type GetEventsPotentialAttendeesQueryHookResult = ReturnType<
  typeof useGetEventsPotentialAttendeesQuery
>;
export type GetEventsPotentialAttendeesLazyQueryHookResult = ReturnType<
  typeof useGetEventsPotentialAttendeesLazyQuery
>;
export type GetEventsPotentialAttendeesQueryResult = Apollo.QueryResult<
  GetEventsPotentialAttendeesQuery,
  GetEventsPotentialAttendeesQueryVariables
>;
export const GetEventsDocument = gql`
  query GetEvents($input: QueryEventsInput!) {
    events(input: $input) {
      id
      locked
      createdAt
      startAt
      endAt
      duration
      clockedOnAt
      clockedOffAt
      passive
      passiveStartAt
      activeAssist
      hasNotes
      publicHoliday
      travelDistance
      travelTime
      memberAssignedAutomatically
      payable
      bonusPay
      locked
      verified
      hasIssue
      hasSeizureObservation
      region
      client {
        id
        ndisId
        color
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
      archive {
        id
      }
      delete {
        id
      }
      cancel {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options,
  );
}
export function useGetEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsQuery,
    GetEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options,
  );
}
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<
  typeof useGetEventsLazyQuery
>;
export type GetEventsQueryResult = Apollo.QueryResult<
  GetEventsQuery,
  GetEventsQueryVariables
>;
export const GetEventsMembersReadyToExportDocument = gql`
  query GetEventsMembersReadyToExport($input: QueryEventsInput!) {
    events(input: $input) {
      id
      verified
      clockedOnAt
      clockedOffAt
      hasNotes
      issues {
        id
        closedAt
      }
      member {
        id
        bonusEligible
        externalId
        employmentStartDate
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsMembersReadyToExportQuery__
 *
 * To run a query within a React component, call `useGetEventsMembersReadyToExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsMembersReadyToExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsMembersReadyToExportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsMembersReadyToExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsMembersReadyToExportQuery,
    GetEventsMembersReadyToExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsMembersReadyToExportQuery,
    GetEventsMembersReadyToExportQueryVariables
  >(GetEventsMembersReadyToExportDocument, options);
}
export function useGetEventsMembersReadyToExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsMembersReadyToExportQuery,
    GetEventsMembersReadyToExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsMembersReadyToExportQuery,
    GetEventsMembersReadyToExportQueryVariables
  >(GetEventsMembersReadyToExportDocument, options);
}
export type GetEventsMembersReadyToExportQueryHookResult = ReturnType<
  typeof useGetEventsMembersReadyToExportQuery
>;
export type GetEventsMembersReadyToExportLazyQueryHookResult = ReturnType<
  typeof useGetEventsMembersReadyToExportLazyQuery
>;
export type GetEventsMembersReadyToExportQueryResult = Apollo.QueryResult<
  GetEventsMembersReadyToExportQuery,
  GetEventsMembersReadyToExportQueryVariables
>;
export const GetEventsMembersPaySlipsDataDocument = gql`
  query GetEventsMembersPaySlipsData($input: QueryEventsInput!) {
    events(input: $input) {
      id
      clockedOnAt
      clockedOffAt
      hasNotes
      member {
        id
        currentHourlyBasePayRate
        bonusEligible
        externalId
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsMembersPaySlipsDataQuery__
 *
 * To run a query within a React component, call `useGetEventsMembersPaySlipsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsMembersPaySlipsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsMembersPaySlipsDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsMembersPaySlipsDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsMembersPaySlipsDataQuery,
    GetEventsMembersPaySlipsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsMembersPaySlipsDataQuery,
    GetEventsMembersPaySlipsDataQueryVariables
  >(GetEventsMembersPaySlipsDataDocument, options);
}
export function useGetEventsMembersPaySlipsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsMembersPaySlipsDataQuery,
    GetEventsMembersPaySlipsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsMembersPaySlipsDataQuery,
    GetEventsMembersPaySlipsDataQueryVariables
  >(GetEventsMembersPaySlipsDataDocument, options);
}
export type GetEventsMembersPaySlipsDataQueryHookResult = ReturnType<
  typeof useGetEventsMembersPaySlipsDataQuery
>;
export type GetEventsMembersPaySlipsDataLazyQueryHookResult = ReturnType<
  typeof useGetEventsMembersPaySlipsDataLazyQuery
>;
export type GetEventsMembersPaySlipsDataQueryResult = Apollo.QueryResult<
  GetEventsMembersPaySlipsDataQuery,
  GetEventsMembersPaySlipsDataQueryVariables
>;
export const GetEventsMembersWorkedHoursDocument = gql`
  query GetEventsMembersWorkedHours($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      clockedOnAt
      clockedOffAt
      member {
        id
        ...PersonNames
      }
      client {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsMembersWorkedHoursQuery__
 *
 * To run a query within a React component, call `useGetEventsMembersWorkedHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsMembersWorkedHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsMembersWorkedHoursQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsMembersWorkedHoursQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsMembersWorkedHoursQuery,
    GetEventsMembersWorkedHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsMembersWorkedHoursQuery,
    GetEventsMembersWorkedHoursQueryVariables
  >(GetEventsMembersWorkedHoursDocument, options);
}
export function useGetEventsMembersWorkedHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsMembersWorkedHoursQuery,
    GetEventsMembersWorkedHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsMembersWorkedHoursQuery,
    GetEventsMembersWorkedHoursQueryVariables
  >(GetEventsMembersWorkedHoursDocument, options);
}
export type GetEventsMembersWorkedHoursQueryHookResult = ReturnType<
  typeof useGetEventsMembersWorkedHoursQuery
>;
export type GetEventsMembersWorkedHoursLazyQueryHookResult = ReturnType<
  typeof useGetEventsMembersWorkedHoursLazyQuery
>;
export type GetEventsMembersWorkedHoursQueryResult = Apollo.QueryResult<
  GetEventsMembersWorkedHoursQuery,
  GetEventsMembersWorkedHoursQueryVariables
>;
export const GetEventsOwnAndRelatedDocument = gql`
  query GetEventsOwnAndRelated($input: QueryEventsOwnAndRelatedInput!) {
    eventsOwnAndRelated(input: $input) {
      id
      createdAt
      startAt
      endAt
      duration
      clockedOnAt
      clockedOffAt
      passive
      passiveStartAt
      activeAssist
      hasNotes
      publicHoliday
      travelDistance
      travelTime
      memberAssignedAutomatically
      verified
      hasIssue
      hasSeizureObservation
      region
      client {
        id
        color
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
      cancel {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetEventsOwnAndRelatedQuery__
 *
 * To run a query within a React component, call `useGetEventsOwnAndRelatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsOwnAndRelatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsOwnAndRelatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsOwnAndRelatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventsOwnAndRelatedQuery,
    GetEventsOwnAndRelatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsOwnAndRelatedQuery,
    GetEventsOwnAndRelatedQueryVariables
  >(GetEventsOwnAndRelatedDocument, options);
}
export function useGetEventsOwnAndRelatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsOwnAndRelatedQuery,
    GetEventsOwnAndRelatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsOwnAndRelatedQuery,
    GetEventsOwnAndRelatedQueryVariables
  >(GetEventsOwnAndRelatedDocument, options);
}
export type GetEventsOwnAndRelatedQueryHookResult = ReturnType<
  typeof useGetEventsOwnAndRelatedQuery
>;
export type GetEventsOwnAndRelatedLazyQueryHookResult = ReturnType<
  typeof useGetEventsOwnAndRelatedLazyQuery
>;
export type GetEventsOwnAndRelatedQueryResult = Apollo.QueryResult<
  GetEventsOwnAndRelatedQuery,
  GetEventsOwnAndRelatedQueryVariables
>;
export const GetNonClockedEventsDocument = gql`
  query GetNonClockedEvents($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      clockedOnAt
      clockedOffAt
      client {
        id
        ...PersonNames
      }
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetNonClockedEventsQuery__
 *
 * To run a query within a React component, call `useGetNonClockedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonClockedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonClockedEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNonClockedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNonClockedEventsQuery,
    GetNonClockedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNonClockedEventsQuery,
    GetNonClockedEventsQueryVariables
  >(GetNonClockedEventsDocument, options);
}
export function useGetNonClockedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNonClockedEventsQuery,
    GetNonClockedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNonClockedEventsQuery,
    GetNonClockedEventsQueryVariables
  >(GetNonClockedEventsDocument, options);
}
export type GetNonClockedEventsQueryHookResult = ReturnType<
  typeof useGetNonClockedEventsQuery
>;
export type GetNonClockedEventsLazyQueryHookResult = ReturnType<
  typeof useGetNonClockedEventsLazyQuery
>;
export type GetNonClockedEventsQueryResult = Apollo.QueryResult<
  GetNonClockedEventsQuery,
  GetNonClockedEventsQueryVariables
>;
export const GetUnfilledEventsDocument = gql`
  query GetUnfilledEvents($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      client {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetUnfilledEventsQuery__
 *
 * To run a query within a React component, call `useGetUnfilledEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnfilledEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnfilledEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUnfilledEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnfilledEventsQuery,
    GetUnfilledEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUnfilledEventsQuery,
    GetUnfilledEventsQueryVariables
  >(GetUnfilledEventsDocument, options);
}
export function useGetUnfilledEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnfilledEventsQuery,
    GetUnfilledEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnfilledEventsQuery,
    GetUnfilledEventsQueryVariables
  >(GetUnfilledEventsDocument, options);
}
export type GetUnfilledEventsQueryHookResult = ReturnType<
  typeof useGetUnfilledEventsQuery
>;
export type GetUnfilledEventsLazyQueryHookResult = ReturnType<
  typeof useGetUnfilledEventsLazyQuery
>;
export type GetUnfilledEventsQueryResult = Apollo.QueryResult<
  GetUnfilledEventsQuery,
  GetUnfilledEventsQueryVariables
>;
export const GetMedicationByIdDocument = gql`
  query GetMedicationById($id: ID!) {
    medicationById(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetMedicationByIdQuery__
 *
 * To run a query within a React component, call `useGetMedicationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMedicationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMedicationByIdQuery,
    GetMedicationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMedicationByIdQuery,
    GetMedicationByIdQueryVariables
  >(GetMedicationByIdDocument, options);
}
export function useGetMedicationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMedicationByIdQuery,
    GetMedicationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMedicationByIdQuery,
    GetMedicationByIdQueryVariables
  >(GetMedicationByIdDocument, options);
}
export type GetMedicationByIdQueryHookResult = ReturnType<
  typeof useGetMedicationByIdQuery
>;
export type GetMedicationByIdLazyQueryHookResult = ReturnType<
  typeof useGetMedicationByIdLazyQuery
>;
export type GetMedicationByIdQueryResult = Apollo.QueryResult<
  GetMedicationByIdQuery,
  GetMedicationByIdQueryVariables
>;
export const GetMedicationsDocument = gql`
  query GetMedications($input: QueryMedicationsInput!) {
    medications(input: $input) {
      id
      name
      pronunciation
      url
    }
  }
`;

/**
 * __useGetMedicationsQuery__
 *
 * To run a query within a React component, call `useGetMedicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMedicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMedicationsQuery,
    GetMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMedicationsQuery, GetMedicationsQueryVariables>(
    GetMedicationsDocument,
    options,
  );
}
export function useGetMedicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMedicationsQuery,
    GetMedicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMedicationsQuery, GetMedicationsQueryVariables>(
    GetMedicationsDocument,
    options,
  );
}
export type GetMedicationsQueryHookResult = ReturnType<
  typeof useGetMedicationsQuery
>;
export type GetMedicationsLazyQueryHookResult = ReturnType<
  typeof useGetMedicationsLazyQuery
>;
export type GetMedicationsQueryResult = Apollo.QueryResult<
  GetMedicationsQuery,
  GetMedicationsQueryVariables
>;
export const DownloadMemberFileByIdDocument = gql`
  query DownloadMemberFileById($id: ID!) {
    downloadMemberFileById(id: $id) {
      id
      path
    }
  }
`;

/**
 * __useDownloadMemberFileByIdQuery__
 *
 * To run a query within a React component, call `useDownloadMemberFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadMemberFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadMemberFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadMemberFileByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadMemberFileByIdQuery,
    DownloadMemberFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadMemberFileByIdQuery,
    DownloadMemberFileByIdQueryVariables
  >(DownloadMemberFileByIdDocument, options);
}
export function useDownloadMemberFileByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadMemberFileByIdQuery,
    DownloadMemberFileByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadMemberFileByIdQuery,
    DownloadMemberFileByIdQueryVariables
  >(DownloadMemberFileByIdDocument, options);
}
export type DownloadMemberFileByIdQueryHookResult = ReturnType<
  typeof useDownloadMemberFileByIdQuery
>;
export type DownloadMemberFileByIdLazyQueryHookResult = ReturnType<
  typeof useDownloadMemberFileByIdLazyQuery
>;
export type DownloadMemberFileByIdQueryResult = Apollo.QueryResult<
  DownloadMemberFileByIdQuery,
  DownloadMemberFileByIdQueryVariables
>;
export const GetMemberFilesDocument = gql`
  query GetMemberFiles($input: QueryMemberFilesInput!) {
    memberFiles(input: $input) {
      id
      type
      dateOfFile
      expiresAt
      issuedAt
      value
      file {
        id
        name
        size
        createdAt
        createdBy {
          id
          ...PersonNames
        }
      }
      memberUpload {
        id
        processedAt
        processingNote
        processedBy {
          id
          ...PersonNamesPartial
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetMemberFilesQuery__
 *
 * To run a query within a React component, call `useGetMemberFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberFilesQuery,
    GetMemberFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberFilesQuery, GetMemberFilesQueryVariables>(
    GetMemberFilesDocument,
    options,
  );
}
export function useGetMemberFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberFilesQuery,
    GetMemberFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberFilesQuery, GetMemberFilesQueryVariables>(
    GetMemberFilesDocument,
    options,
  );
}
export type GetMemberFilesQueryHookResult = ReturnType<
  typeof useGetMemberFilesQuery
>;
export type GetMemberFilesLazyQueryHookResult = ReturnType<
  typeof useGetMemberFilesLazyQuery
>;
export type GetMemberFilesQueryResult = Apollo.QueryResult<
  GetMemberFilesQuery,
  GetMemberFilesQueryVariables
>;
export const GetMemberScheduleTimeLimitsDocument = gql`
  query GetMemberScheduleTimeLimits(
    $id: ID!
    $input: QueryMemberScheduleTimeLimitsInput!
  ) {
    memberById(id: $id) {
      id
      scheduleTimeLimits(input: $input) {
        id
        createdAt
        deletedAt
        description
        limit
        startAt
        endAt
        createdBy {
          id
          ...PersonNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberScheduleTimeLimitsQuery__
 *
 * To run a query within a React component, call `useGetMemberScheduleTimeLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberScheduleTimeLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberScheduleTimeLimitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberScheduleTimeLimitsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberScheduleTimeLimitsQuery,
    GetMemberScheduleTimeLimitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberScheduleTimeLimitsQuery,
    GetMemberScheduleTimeLimitsQueryVariables
  >(GetMemberScheduleTimeLimitsDocument, options);
}
export function useGetMemberScheduleTimeLimitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberScheduleTimeLimitsQuery,
    GetMemberScheduleTimeLimitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberScheduleTimeLimitsQuery,
    GetMemberScheduleTimeLimitsQueryVariables
  >(GetMemberScheduleTimeLimitsDocument, options);
}
export type GetMemberScheduleTimeLimitsQueryHookResult = ReturnType<
  typeof useGetMemberScheduleTimeLimitsQuery
>;
export type GetMemberScheduleTimeLimitsLazyQueryHookResult = ReturnType<
  typeof useGetMemberScheduleTimeLimitsLazyQuery
>;
export type GetMemberScheduleTimeLimitsQueryResult = Apollo.QueryResult<
  GetMemberScheduleTimeLimitsQuery,
  GetMemberScheduleTimeLimitsQueryVariables
>;
export const MemberUnavailableSummaryModalGetUnavailableDocument = gql`
  query MemberUnavailableSummaryModalGetUnavailable($id: ID!) {
    memberUnavailableById(id: $id) {
      id
      startAt
      endAt
      notes
      color
      dates
      owner {
        id
        ...PersonNames
      }
      createdAt
      createdBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useMemberUnavailableSummaryModalGetUnavailableQuery__
 *
 * To run a query within a React component, call `useMemberUnavailableSummaryModalGetUnavailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberUnavailableSummaryModalGetUnavailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberUnavailableSummaryModalGetUnavailableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberUnavailableSummaryModalGetUnavailableQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberUnavailableSummaryModalGetUnavailableQuery,
    MemberUnavailableSummaryModalGetUnavailableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberUnavailableSummaryModalGetUnavailableQuery,
    MemberUnavailableSummaryModalGetUnavailableQueryVariables
  >(MemberUnavailableSummaryModalGetUnavailableDocument, options);
}
export function useMemberUnavailableSummaryModalGetUnavailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberUnavailableSummaryModalGetUnavailableQuery,
    MemberUnavailableSummaryModalGetUnavailableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberUnavailableSummaryModalGetUnavailableQuery,
    MemberUnavailableSummaryModalGetUnavailableQueryVariables
  >(MemberUnavailableSummaryModalGetUnavailableDocument, options);
}
export type MemberUnavailableSummaryModalGetUnavailableQueryHookResult =
  ReturnType<typeof useMemberUnavailableSummaryModalGetUnavailableQuery>;
export type MemberUnavailableSummaryModalGetUnavailableLazyQueryHookResult =
  ReturnType<typeof useMemberUnavailableSummaryModalGetUnavailableLazyQuery>;
export type MemberUnavailableSummaryModalGetUnavailableQueryResult =
  Apollo.QueryResult<
    MemberUnavailableSummaryModalGetUnavailableQuery,
    MemberUnavailableSummaryModalGetUnavailableQueryVariables
  >;
export const MemberViewUnavailablesGetUnavailablesDocument = gql`
  query MemberViewUnavailablesGetUnavailables(
    $input: QueryMemberUnavailablesInput!
  ) {
    memberUnavailables(input: $input) {
      id
      startAt
      endAt
      notes
      dates
      createdBy {
        id
      }
      owner {
        id
      }
    }
  }
`;

/**
 * __useMemberViewUnavailablesGetUnavailablesQuery__
 *
 * To run a query within a React component, call `useMemberViewUnavailablesGetUnavailablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberViewUnavailablesGetUnavailablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberViewUnavailablesGetUnavailablesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberViewUnavailablesGetUnavailablesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberViewUnavailablesGetUnavailablesQuery,
    MemberViewUnavailablesGetUnavailablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberViewUnavailablesGetUnavailablesQuery,
    MemberViewUnavailablesGetUnavailablesQueryVariables
  >(MemberViewUnavailablesGetUnavailablesDocument, options);
}
export function useMemberViewUnavailablesGetUnavailablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberViewUnavailablesGetUnavailablesQuery,
    MemberViewUnavailablesGetUnavailablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberViewUnavailablesGetUnavailablesQuery,
    MemberViewUnavailablesGetUnavailablesQueryVariables
  >(MemberViewUnavailablesGetUnavailablesDocument, options);
}
export type MemberViewUnavailablesGetUnavailablesQueryHookResult = ReturnType<
  typeof useMemberViewUnavailablesGetUnavailablesQuery
>;
export type MemberViewUnavailablesGetUnavailablesLazyQueryHookResult =
  ReturnType<typeof useMemberViewUnavailablesGetUnavailablesLazyQuery>;
export type MemberViewUnavailablesGetUnavailablesQueryResult =
  Apollo.QueryResult<
    MemberViewUnavailablesGetUnavailablesQuery,
    MemberViewUnavailablesGetUnavailablesQueryVariables
  >;
export const DownloadMemberUploadByIdDocument = gql`
  query DownloadMemberUploadById($id: ID!) {
    downloadMemberUploadById(id: $id) {
      id
      path
    }
  }
`;

/**
 * __useDownloadMemberUploadByIdQuery__
 *
 * To run a query within a React component, call `useDownloadMemberUploadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadMemberUploadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadMemberUploadByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadMemberUploadByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadMemberUploadByIdQuery,
    DownloadMemberUploadByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadMemberUploadByIdQuery,
    DownloadMemberUploadByIdQueryVariables
  >(DownloadMemberUploadByIdDocument, options);
}
export function useDownloadMemberUploadByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadMemberUploadByIdQuery,
    DownloadMemberUploadByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadMemberUploadByIdQuery,
    DownloadMemberUploadByIdQueryVariables
  >(DownloadMemberUploadByIdDocument, options);
}
export type DownloadMemberUploadByIdQueryHookResult = ReturnType<
  typeof useDownloadMemberUploadByIdQuery
>;
export type DownloadMemberUploadByIdLazyQueryHookResult = ReturnType<
  typeof useDownloadMemberUploadByIdLazyQuery
>;
export type DownloadMemberUploadByIdQueryResult = Apollo.QueryResult<
  DownloadMemberUploadByIdQuery,
  DownloadMemberUploadByIdQueryVariables
>;
export const GetMemberUploadsDocument = gql`
  query GetMemberUploads($input: QueryMemberUploadsInput!) {
    memberUploads(input: $input) {
      id
      createdAt
      type
      processingState
      uploadedBy {
        id
        ...PersonNames
      }
      uploadedFor {
        id
        ...PersonNames
      }
      file {
        id
        name
        size
        mime
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberUploadsQuery__
 *
 * To run a query within a React component, call `useGetMemberUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberUploadsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberUploadsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberUploadsQuery,
    GetMemberUploadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberUploadsQuery, GetMemberUploadsQueryVariables>(
    GetMemberUploadsDocument,
    options,
  );
}
export function useGetMemberUploadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberUploadsQuery,
    GetMemberUploadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberUploadsQuery,
    GetMemberUploadsQueryVariables
  >(GetMemberUploadsDocument, options);
}
export type GetMemberUploadsQueryHookResult = ReturnType<
  typeof useGetMemberUploadsQuery
>;
export type GetMemberUploadsLazyQueryHookResult = ReturnType<
  typeof useGetMemberUploadsLazyQuery
>;
export type GetMemberUploadsQueryResult = Apollo.QueryResult<
  GetMemberUploadsQuery,
  GetMemberUploadsQueryVariables
>;
export const GetMemberUploadsNotificationBadgeDocument = gql`
  query GetMemberUploadsNotificationBadge($input: QueryMemberUploadsInput!) {
    me {
      id
      member {
        id
        org {
          id
          memberUploads(input: $input) {
            id
            processingState
          }
        }
      }
    }
  }
`;

/**
 * __useGetMemberUploadsNotificationBadgeQuery__
 *
 * To run a query within a React component, call `useGetMemberUploadsNotificationBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberUploadsNotificationBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberUploadsNotificationBadgeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberUploadsNotificationBadgeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberUploadsNotificationBadgeQuery,
    GetMemberUploadsNotificationBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberUploadsNotificationBadgeQuery,
    GetMemberUploadsNotificationBadgeQueryVariables
  >(GetMemberUploadsNotificationBadgeDocument, options);
}
export function useGetMemberUploadsNotificationBadgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberUploadsNotificationBadgeQuery,
    GetMemberUploadsNotificationBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberUploadsNotificationBadgeQuery,
    GetMemberUploadsNotificationBadgeQueryVariables
  >(GetMemberUploadsNotificationBadgeDocument, options);
}
export type GetMemberUploadsNotificationBadgeQueryHookResult = ReturnType<
  typeof useGetMemberUploadsNotificationBadgeQuery
>;
export type GetMemberUploadsNotificationBadgeLazyQueryHookResult = ReturnType<
  typeof useGetMemberUploadsNotificationBadgeLazyQuery
>;
export type GetMemberUploadsNotificationBadgeQueryResult = Apollo.QueryResult<
  GetMemberUploadsNotificationBadgeQuery,
  GetMemberUploadsNotificationBadgeQueryVariables
>;
export const GetMissingMemberFilesDocument = gql`
  query GetMissingMemberFiles {
    me {
      id
      member {
        id
        employmentStartDate
        missingFiles
        missingBankDetails
      }
    }
  }
`;

/**
 * __useGetMissingMemberFilesQuery__
 *
 * To run a query within a React component, call `useGetMissingMemberFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingMemberFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingMemberFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMissingMemberFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMissingMemberFilesQuery,
    GetMissingMemberFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMissingMemberFilesQuery,
    GetMissingMemberFilesQueryVariables
  >(GetMissingMemberFilesDocument, options);
}
export function useGetMissingMemberFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMissingMemberFilesQuery,
    GetMissingMemberFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMissingMemberFilesQuery,
    GetMissingMemberFilesQueryVariables
  >(GetMissingMemberFilesDocument, options);
}
export type GetMissingMemberFilesQueryHookResult = ReturnType<
  typeof useGetMissingMemberFilesQuery
>;
export type GetMissingMemberFilesLazyQueryHookResult = ReturnType<
  typeof useGetMissingMemberFilesLazyQuery
>;
export type GetMissingMemberFilesQueryResult = Apollo.QueryResult<
  GetMissingMemberFilesQuery,
  GetMissingMemberFilesQueryVariables
>;
export const GetMemberBankDetailsDocument = gql`
  query GetMemberBankDetails($id: ID!) {
    memberById(id: $id) {
      id
      bank {
        bsb
        accountNumber
        accountName
      }
    }
  }
`;

/**
 * __useGetMemberBankDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberBankDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberBankDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberBankDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberBankDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberBankDetailsQuery,
    GetMemberBankDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberBankDetailsQuery,
    GetMemberBankDetailsQueryVariables
  >(GetMemberBankDetailsDocument, options);
}
export function useGetMemberBankDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberBankDetailsQuery,
    GetMemberBankDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberBankDetailsQuery,
    GetMemberBankDetailsQueryVariables
  >(GetMemberBankDetailsDocument, options);
}
export type GetMemberBankDetailsQueryHookResult = ReturnType<
  typeof useGetMemberBankDetailsQuery
>;
export type GetMemberBankDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMemberBankDetailsLazyQuery
>;
export type GetMemberBankDetailsQueryResult = Apollo.QueryResult<
  GetMemberBankDetailsQuery,
  GetMemberBankDetailsQueryVariables
>;
export const GetMemberBankDetailsSelfDocument = gql`
  query GetMemberBankDetailsSelf {
    me {
      id
      member {
        id
        bank {
          bsb
          accountNumber
          accountName
        }
      }
    }
  }
`;

/**
 * __useGetMemberBankDetailsSelfQuery__
 *
 * To run a query within a React component, call `useGetMemberBankDetailsSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberBankDetailsSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberBankDetailsSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberBankDetailsSelfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberBankDetailsSelfQuery,
    GetMemberBankDetailsSelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberBankDetailsSelfQuery,
    GetMemberBankDetailsSelfQueryVariables
  >(GetMemberBankDetailsSelfDocument, options);
}
export function useGetMemberBankDetailsSelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberBankDetailsSelfQuery,
    GetMemberBankDetailsSelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberBankDetailsSelfQuery,
    GetMemberBankDetailsSelfQueryVariables
  >(GetMemberBankDetailsSelfDocument, options);
}
export type GetMemberBankDetailsSelfQueryHookResult = ReturnType<
  typeof useGetMemberBankDetailsSelfQuery
>;
export type GetMemberBankDetailsSelfLazyQueryHookResult = ReturnType<
  typeof useGetMemberBankDetailsSelfLazyQuery
>;
export type GetMemberBankDetailsSelfQueryResult = Apollo.QueryResult<
  GetMemberBankDetailsSelfQuery,
  GetMemberBankDetailsSelfQueryVariables
>;
export const GetMemberByIdDocument = gql`
  query GetMemberById($id: ID!) {
    memberById(id: $id) {
      id
      createdAt
      lastActiveAt
      hasAccess
      moduleAccessCS
      moduleAccessSC
      admin
      permissions
      externalId
      schedulable
      trackFiles
      australianCitizen
      workRights
      workRightsLastCheckedAt
      passportCountry
      passportNumber
      allowedFortnightlyWorkHours
      driversLicense
      driversLicenseCountry
      driversLicenseExpiryDate
      hasCar
      carDescription
      canHoist
      color
      dob
      email
      phone
      bio
      notes
      confidentialNotes
      employmentStartDate
      employmentEndDate
      study
      currentHourlyBasePayRate
      currentAnnualPayRate
      bonusEligible
      unavailableNotes
      unavailableMoMorn
      unavailableMoDay
      unavailableMoNight
      unavailableTuMorn
      unavailableTuDay
      unavailableTuNight
      unavailableWeMorn
      unavailableWeDay
      unavailableWeNight
      unavailableThMorn
      unavailableThDay
      unavailableThNight
      unavailableFrMorn
      unavailableFrDay
      unavailableFrNight
      unavailableSaMorn
      unavailableSaDay
      unavailableSaNight
      unavailableSuMorn
      unavailableSuDay
      unavailableSuNight
      remindClock
      remindEvents
      remindNotes
      empathyRating
      reliabilityRating
      canPromptMedication
      hasCertSupportingSafeMeals
      hasCertEffectiveCommunication
      hasCertPreventingDementia
      hasCertUnderstandingDementia
      hasCertManualHandling
      hasCertSeizureRecognition
      lateness
      currentPostNominal
      employementContractExternallySaved
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branches {
        id
        name
      }
      visaType {
        id
        name
      }
      createdBy {
        id
        ...PersonNamesPartial
      }
      invitationMostRecentlyReceived {
        id
        deletedAt
        usedAt
        sentCount
        lastSender {
          id
          sentAt
          sentBy {
            id
            ...PersonNamesPartial
          }
        }
      }
      archive {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
      delete {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberByIdQuery,
    GetMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMemberByIdQuery, GetMemberByIdQueryVariables>(
    GetMemberByIdDocument,
    options,
  );
}
export function useGetMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberByIdQuery,
    GetMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMemberByIdQuery, GetMemberByIdQueryVariables>(
    GetMemberByIdDocument,
    options,
  );
}
export type GetMemberByIdQueryHookResult = ReturnType<
  typeof useGetMemberByIdQuery
>;
export type GetMemberByIdLazyQueryHookResult = ReturnType<
  typeof useGetMemberByIdLazyQuery
>;
export type GetMemberByIdQueryResult = Apollo.QueryResult<
  GetMemberByIdQuery,
  GetMemberByIdQueryVariables
>;
export const GetMemberEmploymentAgreementDataDocument = gql`
  query GetMemberEmploymentAgreementData {
    me {
      id
      member {
        id
        firstName
        middleName
        lastName
        email
        employmentStartDate
        unit
        street
        locality
        region
        postcode
        country
        currentHourlyBasePayRate
      }
    }
  }
`;

/**
 * __useGetMemberEmploymentAgreementDataQuery__
 *
 * To run a query within a React component, call `useGetMemberEmploymentAgreementDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberEmploymentAgreementDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberEmploymentAgreementDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberEmploymentAgreementDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberEmploymentAgreementDataQuery,
    GetMemberEmploymentAgreementDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberEmploymentAgreementDataQuery,
    GetMemberEmploymentAgreementDataQueryVariables
  >(GetMemberEmploymentAgreementDataDocument, options);
}
export function useGetMemberEmploymentAgreementDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberEmploymentAgreementDataQuery,
    GetMemberEmploymentAgreementDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberEmploymentAgreementDataQuery,
    GetMemberEmploymentAgreementDataQueryVariables
  >(GetMemberEmploymentAgreementDataDocument, options);
}
export type GetMemberEmploymentAgreementDataQueryHookResult = ReturnType<
  typeof useGetMemberEmploymentAgreementDataQuery
>;
export type GetMemberEmploymentAgreementDataLazyQueryHookResult = ReturnType<
  typeof useGetMemberEmploymentAgreementDataLazyQuery
>;
export type GetMemberEmploymentAgreementDataQueryResult = Apollo.QueryResult<
  GetMemberEmploymentAgreementDataQuery,
  GetMemberEmploymentAgreementDataQueryVariables
>;
export const GetMemberFinancialDetailsDocument = gql`
  query GetMemberFinancialDetails($id: ID!) {
    memberById(id: $id) {
      id
      bank {
        bsb
        accountNumber
        accountName
      }
      super {
        usi
        fundName
        fundAbn
        accountName
        accountNumber
      }
      taxFileNumberDeclaration {
        tfn
        appliedForTfn
        claimingExemptionMinor
        claimingExemptionPension
        salutation
        lastName
        firstName
        middleName
        street
        locality
        region
        postcode
        prevLastName
        email
        dob
        basisOfPay
        areYou
        claimTaxFreeThreshold
        studentLoanDebt
      }
    }
  }
`;

/**
 * __useGetMemberFinancialDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberFinancialDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberFinancialDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberFinancialDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberFinancialDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberFinancialDetailsQuery,
    GetMemberFinancialDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberFinancialDetailsQuery,
    GetMemberFinancialDetailsQueryVariables
  >(GetMemberFinancialDetailsDocument, options);
}
export function useGetMemberFinancialDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberFinancialDetailsQuery,
    GetMemberFinancialDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberFinancialDetailsQuery,
    GetMemberFinancialDetailsQueryVariables
  >(GetMemberFinancialDetailsDocument, options);
}
export type GetMemberFinancialDetailsQueryHookResult = ReturnType<
  typeof useGetMemberFinancialDetailsQuery
>;
export type GetMemberFinancialDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMemberFinancialDetailsLazyQuery
>;
export type GetMemberFinancialDetailsQueryResult = Apollo.QueryResult<
  GetMemberFinancialDetailsQuery,
  GetMemberFinancialDetailsQueryVariables
>;
export const GetMemberScheduleByIdDocument = gql`
  query GetMemberScheduleById(
    $id: ID!
    $scheduleInput: QueryMemberScheduleInput!
  ) {
    memberById(id: $id) {
      id
      schedule(input: $scheduleInput) {
        totalMinutes
        allowedMinutes
      }
    }
  }
`;

/**
 * __useGetMemberScheduleByIdQuery__
 *
 * To run a query within a React component, call `useGetMemberScheduleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberScheduleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberScheduleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      scheduleInput: // value for 'scheduleInput'
 *   },
 * });
 */
export function useGetMemberScheduleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberScheduleByIdQuery,
    GetMemberScheduleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberScheduleByIdQuery,
    GetMemberScheduleByIdQueryVariables
  >(GetMemberScheduleByIdDocument, options);
}
export function useGetMemberScheduleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberScheduleByIdQuery,
    GetMemberScheduleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberScheduleByIdQuery,
    GetMemberScheduleByIdQueryVariables
  >(GetMemberScheduleByIdDocument, options);
}
export type GetMemberScheduleByIdQueryHookResult = ReturnType<
  typeof useGetMemberScheduleByIdQuery
>;
export type GetMemberScheduleByIdLazyQueryHookResult = ReturnType<
  typeof useGetMemberScheduleByIdLazyQuery
>;
export type GetMemberScheduleByIdQueryResult = Apollo.QueryResult<
  GetMemberScheduleByIdQuery,
  GetMemberScheduleByIdQueryVariables
>;
export const GetMemberScheduleMineDocument = gql`
  query GetMemberScheduleMine($scheduleInput: QueryMemberScheduleInput!) {
    me {
      id
      member {
        id
        schedule(input: $scheduleInput) {
          totalMinutes
          allowedMinutes
        }
      }
    }
  }
`;

/**
 * __useGetMemberScheduleMineQuery__
 *
 * To run a query within a React component, call `useGetMemberScheduleMineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberScheduleMineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberScheduleMineQuery({
 *   variables: {
 *      scheduleInput: // value for 'scheduleInput'
 *   },
 * });
 */
export function useGetMemberScheduleMineQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberScheduleMineQuery,
    GetMemberScheduleMineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberScheduleMineQuery,
    GetMemberScheduleMineQueryVariables
  >(GetMemberScheduleMineDocument, options);
}
export function useGetMemberScheduleMineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberScheduleMineQuery,
    GetMemberScheduleMineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberScheduleMineQuery,
    GetMemberScheduleMineQueryVariables
  >(GetMemberScheduleMineDocument, options);
}
export type GetMemberScheduleMineQueryHookResult = ReturnType<
  typeof useGetMemberScheduleMineQuery
>;
export type GetMemberScheduleMineLazyQueryHookResult = ReturnType<
  typeof useGetMemberScheduleMineLazyQuery
>;
export type GetMemberScheduleMineQueryResult = Apollo.QueryResult<
  GetMemberScheduleMineQuery,
  GetMemberScheduleMineQueryVariables
>;
export const GetMemberScheduleSettingsDocument = gql`
  query GetMemberScheduleSettings($id: ID!) {
    memberById(id: $id) {
      id
      scheduleMaxDayTimeLimitDefault
      scheduleMaxWeekTimeLimitDefault
      scheduleMaxWeekTimeLimitCurrent
      org {
        id
        scheduleMaxDayTimeLimitDefault
        scheduleMaxWeekTimeLimitDefault
      }
    }
  }
`;

/**
 * __useGetMemberScheduleSettingsQuery__
 *
 * To run a query within a React component, call `useGetMemberScheduleSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberScheduleSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberScheduleSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberScheduleSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberScheduleSettingsQuery,
    GetMemberScheduleSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberScheduleSettingsQuery,
    GetMemberScheduleSettingsQueryVariables
  >(GetMemberScheduleSettingsDocument, options);
}
export function useGetMemberScheduleSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberScheduleSettingsQuery,
    GetMemberScheduleSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberScheduleSettingsQuery,
    GetMemberScheduleSettingsQueryVariables
  >(GetMemberScheduleSettingsDocument, options);
}
export type GetMemberScheduleSettingsQueryHookResult = ReturnType<
  typeof useGetMemberScheduleSettingsQuery
>;
export type GetMemberScheduleSettingsLazyQueryHookResult = ReturnType<
  typeof useGetMemberScheduleSettingsLazyQuery
>;
export type GetMemberScheduleSettingsQueryResult = Apollo.QueryResult<
  GetMemberScheduleSettingsQuery,
  GetMemberScheduleSettingsQueryVariables
>;
export const GetMemberSuperDetailsDocument = gql`
  query GetMemberSuperDetails($id: ID!) {
    memberById(id: $id) {
      id
      super {
        usi
        fundName
        fundAbn
        accountName
        accountNumber
      }
    }
  }
`;

/**
 * __useGetMemberSuperDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberSuperDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberSuperDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberSuperDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberSuperDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberSuperDetailsQuery,
    GetMemberSuperDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberSuperDetailsQuery,
    GetMemberSuperDetailsQueryVariables
  >(GetMemberSuperDetailsDocument, options);
}
export function useGetMemberSuperDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberSuperDetailsQuery,
    GetMemberSuperDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberSuperDetailsQuery,
    GetMemberSuperDetailsQueryVariables
  >(GetMemberSuperDetailsDocument, options);
}
export type GetMemberSuperDetailsQueryHookResult = ReturnType<
  typeof useGetMemberSuperDetailsQuery
>;
export type GetMemberSuperDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMemberSuperDetailsLazyQuery
>;
export type GetMemberSuperDetailsQueryResult = Apollo.QueryResult<
  GetMemberSuperDetailsQuery,
  GetMemberSuperDetailsQueryVariables
>;
export const GetMemberSuperDetailsSelfDocument = gql`
  query GetMemberSuperDetailsSelf {
    me {
      id
      member {
        id
        super {
          usi
          fundName
          fundAbn
          accountName
          accountNumber
        }
      }
    }
  }
`;

/**
 * __useGetMemberSuperDetailsSelfQuery__
 *
 * To run a query within a React component, call `useGetMemberSuperDetailsSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberSuperDetailsSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberSuperDetailsSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberSuperDetailsSelfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberSuperDetailsSelfQuery,
    GetMemberSuperDetailsSelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberSuperDetailsSelfQuery,
    GetMemberSuperDetailsSelfQueryVariables
  >(GetMemberSuperDetailsSelfDocument, options);
}
export function useGetMemberSuperDetailsSelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberSuperDetailsSelfQuery,
    GetMemberSuperDetailsSelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberSuperDetailsSelfQuery,
    GetMemberSuperDetailsSelfQueryVariables
  >(GetMemberSuperDetailsSelfDocument, options);
}
export type GetMemberSuperDetailsSelfQueryHookResult = ReturnType<
  typeof useGetMemberSuperDetailsSelfQuery
>;
export type GetMemberSuperDetailsSelfLazyQueryHookResult = ReturnType<
  typeof useGetMemberSuperDetailsSelfLazyQuery
>;
export type GetMemberSuperDetailsSelfQueryResult = Apollo.QueryResult<
  GetMemberSuperDetailsSelfQuery,
  GetMemberSuperDetailsSelfQueryVariables
>;
export const GetMemberTaxFileNumberDeclarationDetailsDocument = gql`
  query GetMemberTaxFileNumberDeclarationDetails($id: ID!) {
    memberById(id: $id) {
      id
      taxFileNumberDeclaration {
        tfn
        appliedForTfn
        claimingExemptionMinor
        claimingExemptionPension
        salutation
        lastName
        firstName
        middleName
        street
        locality
        region
        postcode
        prevLastName
        email
        dob
        basisOfPay
        areYou
        claimTaxFreeThreshold
        studentLoanDebt
      }
    }
  }
`;

/**
 * __useGetMemberTaxFileNumberDeclarationDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberTaxFileNumberDeclarationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberTaxFileNumberDeclarationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberTaxFileNumberDeclarationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberTaxFileNumberDeclarationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberTaxFileNumberDeclarationDetailsQuery,
    GetMemberTaxFileNumberDeclarationDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberTaxFileNumberDeclarationDetailsQuery,
    GetMemberTaxFileNumberDeclarationDetailsQueryVariables
  >(GetMemberTaxFileNumberDeclarationDetailsDocument, options);
}
export function useGetMemberTaxFileNumberDeclarationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberTaxFileNumberDeclarationDetailsQuery,
    GetMemberTaxFileNumberDeclarationDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberTaxFileNumberDeclarationDetailsQuery,
    GetMemberTaxFileNumberDeclarationDetailsQueryVariables
  >(GetMemberTaxFileNumberDeclarationDetailsDocument, options);
}
export type GetMemberTaxFileNumberDeclarationDetailsQueryHookResult =
  ReturnType<typeof useGetMemberTaxFileNumberDeclarationDetailsQuery>;
export type GetMemberTaxFileNumberDeclarationDetailsLazyQueryHookResult =
  ReturnType<typeof useGetMemberTaxFileNumberDeclarationDetailsLazyQuery>;
export type GetMemberTaxFileNumberDeclarationDetailsQueryResult =
  Apollo.QueryResult<
    GetMemberTaxFileNumberDeclarationDetailsQuery,
    GetMemberTaxFileNumberDeclarationDetailsQueryVariables
  >;
export const GetMemberTaxFileNumberDeclarationDetailsSelfDocument = gql`
  query GetMemberTaxFileNumberDeclarationDetailsSelf {
    me {
      id
      member {
        id
        taxFileNumberDeclaration {
          tfn
          appliedForTfn
          claimingExemptionMinor
          claimingExemptionPension
          salutation
          lastName
          firstName
          middleName
          street
          locality
          region
          postcode
          prevLastName
          email
          dob
          basisOfPay
          areYou
          claimTaxFreeThreshold
          studentLoanDebt
        }
      }
    }
  }
`;

/**
 * __useGetMemberTaxFileNumberDeclarationDetailsSelfQuery__
 *
 * To run a query within a React component, call `useGetMemberTaxFileNumberDeclarationDetailsSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberTaxFileNumberDeclarationDetailsSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberTaxFileNumberDeclarationDetailsSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberTaxFileNumberDeclarationDetailsSelfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMemberTaxFileNumberDeclarationDetailsSelfQuery,
    GetMemberTaxFileNumberDeclarationDetailsSelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberTaxFileNumberDeclarationDetailsSelfQuery,
    GetMemberTaxFileNumberDeclarationDetailsSelfQueryVariables
  >(GetMemberTaxFileNumberDeclarationDetailsSelfDocument, options);
}
export function useGetMemberTaxFileNumberDeclarationDetailsSelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberTaxFileNumberDeclarationDetailsSelfQuery,
    GetMemberTaxFileNumberDeclarationDetailsSelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberTaxFileNumberDeclarationDetailsSelfQuery,
    GetMemberTaxFileNumberDeclarationDetailsSelfQueryVariables
  >(GetMemberTaxFileNumberDeclarationDetailsSelfDocument, options);
}
export type GetMemberTaxFileNumberDeclarationDetailsSelfQueryHookResult =
  ReturnType<typeof useGetMemberTaxFileNumberDeclarationDetailsSelfQuery>;
export type GetMemberTaxFileNumberDeclarationDetailsSelfLazyQueryHookResult =
  ReturnType<typeof useGetMemberTaxFileNumberDeclarationDetailsSelfLazyQuery>;
export type GetMemberTaxFileNumberDeclarationDetailsSelfQueryResult =
  Apollo.QueryResult<
    GetMemberTaxFileNumberDeclarationDetailsSelfQuery,
    GetMemberTaxFileNumberDeclarationDetailsSelfQueryVariables
  >;
export const GetMemberTimezoneDocument = gql`
  query GetMemberTimezone($id: ID!) {
    memberById(id: $id) {
      id
      timezone
    }
  }
`;

/**
 * __useGetMemberTimezoneQuery__
 *
 * To run a query within a React component, call `useGetMemberTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberTimezoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberTimezoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberTimezoneQuery,
    GetMemberTimezoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberTimezoneQuery,
    GetMemberTimezoneQueryVariables
  >(GetMemberTimezoneDocument, options);
}
export function useGetMemberTimezoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberTimezoneQuery,
    GetMemberTimezoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberTimezoneQuery,
    GetMemberTimezoneQueryVariables
  >(GetMemberTimezoneDocument, options);
}
export type GetMemberTimezoneQueryHookResult = ReturnType<
  typeof useGetMemberTimezoneQuery
>;
export type GetMemberTimezoneLazyQueryHookResult = ReturnType<
  typeof useGetMemberTimezoneLazyQuery
>;
export type GetMemberTimezoneQueryResult = Apollo.QueryResult<
  GetMemberTimezoneQuery,
  GetMemberTimezoneQueryVariables
>;
export const GetMemberTypicalUnavailablilityDocument = gql`
  query GetMemberTypicalUnavailablility($id: ID!) {
    memberById(id: $id) {
      id
      unavailableNotes
      unavailableMoMorn
      unavailableMoDay
      unavailableMoNight
      unavailableTuMorn
      unavailableTuDay
      unavailableTuNight
      unavailableWeMorn
      unavailableWeDay
      unavailableWeNight
      unavailableThMorn
      unavailableThDay
      unavailableThNight
      unavailableFrMorn
      unavailableFrDay
      unavailableFrNight
      unavailableSaMorn
      unavailableSaDay
      unavailableSaNight
      unavailableSuMorn
      unavailableSuDay
      unavailableSuNight
    }
  }
`;

/**
 * __useGetMemberTypicalUnavailablilityQuery__
 *
 * To run a query within a React component, call `useGetMemberTypicalUnavailablilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberTypicalUnavailablilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberTypicalUnavailablilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberTypicalUnavailablilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberTypicalUnavailablilityQuery,
    GetMemberTypicalUnavailablilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberTypicalUnavailablilityQuery,
    GetMemberTypicalUnavailablilityQueryVariables
  >(GetMemberTypicalUnavailablilityDocument, options);
}
export function useGetMemberTypicalUnavailablilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberTypicalUnavailablilityQuery,
    GetMemberTypicalUnavailablilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberTypicalUnavailablilityQuery,
    GetMemberTypicalUnavailablilityQueryVariables
  >(GetMemberTypicalUnavailablilityDocument, options);
}
export type GetMemberTypicalUnavailablilityQueryHookResult = ReturnType<
  typeof useGetMemberTypicalUnavailablilityQuery
>;
export type GetMemberTypicalUnavailablilityLazyQueryHookResult = ReturnType<
  typeof useGetMemberTypicalUnavailablilityLazyQuery
>;
export type GetMemberTypicalUnavailablilityQueryResult = Apollo.QueryResult<
  GetMemberTypicalUnavailablilityQuery,
  GetMemberTypicalUnavailablilityQueryVariables
>;
export const GetMemberUnavailablesDocument = gql`
  query GetMemberUnavailables(
    $unavailablesInput: QueryMemberUnavailablesInput!
  ) {
    memberUnavailables(input: $unavailablesInput) {
      id
      startAt
      endAt
      notes
      owner {
        id
      }
    }
  }
`;

/**
 * __useGetMemberUnavailablesQuery__
 *
 * To run a query within a React component, call `useGetMemberUnavailablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberUnavailablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberUnavailablesQuery({
 *   variables: {
 *      unavailablesInput: // value for 'unavailablesInput'
 *   },
 * });
 */
export function useGetMemberUnavailablesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberUnavailablesQuery,
    GetMemberUnavailablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberUnavailablesQuery,
    GetMemberUnavailablesQueryVariables
  >(GetMemberUnavailablesDocument, options);
}
export function useGetMemberUnavailablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberUnavailablesQuery,
    GetMemberUnavailablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberUnavailablesQuery,
    GetMemberUnavailablesQueryVariables
  >(GetMemberUnavailablesDocument, options);
}
export type GetMemberUnavailablesQueryHookResult = ReturnType<
  typeof useGetMemberUnavailablesQuery
>;
export type GetMemberUnavailablesLazyQueryHookResult = ReturnType<
  typeof useGetMemberUnavailablesLazyQuery
>;
export type GetMemberUnavailablesQueryResult = Apollo.QueryResult<
  GetMemberUnavailablesQuery,
  GetMemberUnavailablesQueryVariables
>;
export const GetMembersDocument = gql`
  query GetMembers($input: QueryMembersInput!) {
    members(input: $input) {
      id
      admin
      permissions
      color
      hasAccess
      externalId
      lastActiveAt
      ...PersonNames
      invitationMostRecentlyReceived {
        id
        deletedAt
        usedAt
      }
      archive {
        id
      }
      delete {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMembersQuery,
    GetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(
    GetMembersDocument,
    options,
  );
}
export function useGetMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMembersQuery,
    GetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(
    GetMembersDocument,
    options,
  );
}
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<
  typeof useGetMembersLazyQuery
>;
export type GetMembersQueryResult = Apollo.QueryResult<
  GetMembersQuery,
  GetMembersQueryVariables
>;
export const GetMemberBasePayRateByIdDocument = gql`
  query GetMemberBasePayRateById($id: ID!) {
    memberBasePayRateById(id: $id) {
      id
      date
      value
      type
      createdBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberBasePayRateByIdQuery__
 *
 * To run a query within a React component, call `useGetMemberBasePayRateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberBasePayRateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberBasePayRateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberBasePayRateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberBasePayRateByIdQuery,
    GetMemberBasePayRateByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberBasePayRateByIdQuery,
    GetMemberBasePayRateByIdQueryVariables
  >(GetMemberBasePayRateByIdDocument, options);
}
export function useGetMemberBasePayRateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberBasePayRateByIdQuery,
    GetMemberBasePayRateByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberBasePayRateByIdQuery,
    GetMemberBasePayRateByIdQueryVariables
  >(GetMemberBasePayRateByIdDocument, options);
}
export type GetMemberBasePayRateByIdQueryHookResult = ReturnType<
  typeof useGetMemberBasePayRateByIdQuery
>;
export type GetMemberBasePayRateByIdLazyQueryHookResult = ReturnType<
  typeof useGetMemberBasePayRateByIdLazyQuery
>;
export type GetMemberBasePayRateByIdQueryResult = Apollo.QueryResult<
  GetMemberBasePayRateByIdQuery,
  GetMemberBasePayRateByIdQueryVariables
>;
export const GetMemberBasePayRatesDocument = gql`
  query GetMemberBasePayRates($input: QueryMemberBasePayRatesInput!) {
    memberBasePayRates(input: $input) {
      id
      date
      value
      type
      active
      createdBy {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberBasePayRatesQuery__
 *
 * To run a query within a React component, call `useGetMemberBasePayRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberBasePayRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberBasePayRatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberBasePayRatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberBasePayRatesQuery,
    GetMemberBasePayRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberBasePayRatesQuery,
    GetMemberBasePayRatesQueryVariables
  >(GetMemberBasePayRatesDocument, options);
}
export function useGetMemberBasePayRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberBasePayRatesQuery,
    GetMemberBasePayRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberBasePayRatesQuery,
    GetMemberBasePayRatesQueryVariables
  >(GetMemberBasePayRatesDocument, options);
}
export type GetMemberBasePayRatesQueryHookResult = ReturnType<
  typeof useGetMemberBasePayRatesQuery
>;
export type GetMemberBasePayRatesLazyQueryHookResult = ReturnType<
  typeof useGetMemberBasePayRatesLazyQuery
>;
export type GetMemberBasePayRatesQueryResult = Apollo.QueryResult<
  GetMemberBasePayRatesQuery,
  GetMemberBasePayRatesQueryVariables
>;
export const GetMemberBranchAllocationByIdDocument = gql`
  query GetMemberBranchAllocationById($id: ID!) {
    memberBranchAllocationById(id: $id) {
      id
      date
      deletedAt
      createdBy {
        id
        ...PersonNames
      }
      member {
        id
      }
      values {
        value
        branchId
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberBranchAllocationByIdQuery__
 *
 * To run a query within a React component, call `useGetMemberBranchAllocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberBranchAllocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberBranchAllocationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberBranchAllocationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberBranchAllocationByIdQuery,
    GetMemberBranchAllocationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberBranchAllocationByIdQuery,
    GetMemberBranchAllocationByIdQueryVariables
  >(GetMemberBranchAllocationByIdDocument, options);
}
export function useGetMemberBranchAllocationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberBranchAllocationByIdQuery,
    GetMemberBranchAllocationByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberBranchAllocationByIdQuery,
    GetMemberBranchAllocationByIdQueryVariables
  >(GetMemberBranchAllocationByIdDocument, options);
}
export type GetMemberBranchAllocationByIdQueryHookResult = ReturnType<
  typeof useGetMemberBranchAllocationByIdQuery
>;
export type GetMemberBranchAllocationByIdLazyQueryHookResult = ReturnType<
  typeof useGetMemberBranchAllocationByIdLazyQuery
>;
export type GetMemberBranchAllocationByIdQueryResult = Apollo.QueryResult<
  GetMemberBranchAllocationByIdQuery,
  GetMemberBranchAllocationByIdQueryVariables
>;
export const GetMemberBranchAllocationsDocument = gql`
  query GetMemberBranchAllocations($input: QueryMemberBranchAllocationsInput!) {
    memberBranchAllocations(input: $input) {
      id
      date
      deletedAt
      createdBy {
        id
        ...PersonNames
      }
      member {
        id
      }
      values {
        value
        branchId
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberBranchAllocationsQuery__
 *
 * To run a query within a React component, call `useGetMemberBranchAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberBranchAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberBranchAllocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberBranchAllocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberBranchAllocationsQuery,
    GetMemberBranchAllocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberBranchAllocationsQuery,
    GetMemberBranchAllocationsQueryVariables
  >(GetMemberBranchAllocationsDocument, options);
}
export function useGetMemberBranchAllocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberBranchAllocationsQuery,
    GetMemberBranchAllocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberBranchAllocationsQuery,
    GetMemberBranchAllocationsQueryVariables
  >(GetMemberBranchAllocationsDocument, options);
}
export type GetMemberBranchAllocationsQueryHookResult = ReturnType<
  typeof useGetMemberBranchAllocationsQuery
>;
export type GetMemberBranchAllocationsLazyQueryHookResult = ReturnType<
  typeof useGetMemberBranchAllocationsLazyQuery
>;
export type GetMemberBranchAllocationsQueryResult = Apollo.QueryResult<
  GetMemberBranchAllocationsQuery,
  GetMemberBranchAllocationsQueryVariables
>;
export const GetMembersNamesDocument = gql`
  query GetMembersNames($input: QueryMembersInput!) {
    members(input: $input) {
      id
      ...PersonNamesPartial
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetMembersNamesQuery__
 *
 * To run a query within a React component, call `useGetMembersNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersNamesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMembersNamesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMembersNamesQuery,
    GetMembersNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembersNamesQuery, GetMembersNamesQueryVariables>(
    GetMembersNamesDocument,
    options,
  );
}
export function useGetMembersNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMembersNamesQuery,
    GetMembersNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMembersNamesQuery,
    GetMembersNamesQueryVariables
  >(GetMembersNamesDocument, options);
}
export type GetMembersNamesQueryHookResult = ReturnType<
  typeof useGetMembersNamesQuery
>;
export type GetMembersNamesLazyQueryHookResult = ReturnType<
  typeof useGetMembersNamesLazyQuery
>;
export type GetMembersNamesQueryResult = Apollo.QueryResult<
  GetMembersNamesQuery,
  GetMembersNamesQueryVariables
>;
export const GetMemberPermissionsDocument = gql`
  query GetMemberPermissions($input: QueryMembersInput!) {
    members(input: $input) {
      id
      admin
      permissions
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberPermissionsQuery__
 *
 * To run a query within a React component, call `useGetMemberPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPermissionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberPermissionsQuery,
    GetMemberPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPermissionsQuery,
    GetMemberPermissionsQueryVariables
  >(GetMemberPermissionsDocument, options);
}
export function useGetMemberPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPermissionsQuery,
    GetMemberPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPermissionsQuery,
    GetMemberPermissionsQueryVariables
  >(GetMemberPermissionsDocument, options);
}
export type GetMemberPermissionsQueryHookResult = ReturnType<
  typeof useGetMemberPermissionsQuery
>;
export type GetMemberPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetMemberPermissionsLazyQuery
>;
export type GetMemberPermissionsQueryResult = Apollo.QueryResult<
  GetMemberPermissionsQuery,
  GetMemberPermissionsQueryVariables
>;
export const GetMemberPostNominalByIdDocument = gql`
  query GetMemberPostNominalById($id: ID!) {
    memberPostNominalById(id: $id) {
      id
      date
      deletedAt
      value
      createdBy {
        id
        ...PersonNames
      }
      member {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberPostNominalByIdQuery__
 *
 * To run a query within a React component, call `useGetMemberPostNominalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPostNominalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPostNominalByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberPostNominalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberPostNominalByIdQuery,
    GetMemberPostNominalByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPostNominalByIdQuery,
    GetMemberPostNominalByIdQueryVariables
  >(GetMemberPostNominalByIdDocument, options);
}
export function useGetMemberPostNominalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPostNominalByIdQuery,
    GetMemberPostNominalByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPostNominalByIdQuery,
    GetMemberPostNominalByIdQueryVariables
  >(GetMemberPostNominalByIdDocument, options);
}
export type GetMemberPostNominalByIdQueryHookResult = ReturnType<
  typeof useGetMemberPostNominalByIdQuery
>;
export type GetMemberPostNominalByIdLazyQueryHookResult = ReturnType<
  typeof useGetMemberPostNominalByIdLazyQuery
>;
export type GetMemberPostNominalByIdQueryResult = Apollo.QueryResult<
  GetMemberPostNominalByIdQuery,
  GetMemberPostNominalByIdQueryVariables
>;
export const GetMemberPostNominalsDocument = gql`
  query GetMemberPostNominals($input: QueryMemberPostNominalsInput!) {
    memberPostNominals(input: $input) {
      id
      date
      deletedAt
      value
      createdBy {
        id
        ...PersonNames
      }
      member {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberPostNominalsQuery__
 *
 * To run a query within a React component, call `useGetMemberPostNominalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPostNominalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPostNominalsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberPostNominalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberPostNominalsQuery,
    GetMemberPostNominalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberPostNominalsQuery,
    GetMemberPostNominalsQueryVariables
  >(GetMemberPostNominalsDocument, options);
}
export function useGetMemberPostNominalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberPostNominalsQuery,
    GetMemberPostNominalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberPostNominalsQuery,
    GetMemberPostNominalsQueryVariables
  >(GetMemberPostNominalsDocument, options);
}
export type GetMemberPostNominalsQueryHookResult = ReturnType<
  typeof useGetMemberPostNominalsQuery
>;
export type GetMemberPostNominalsLazyQueryHookResult = ReturnType<
  typeof useGetMemberPostNominalsLazyQuery
>;
export type GetMemberPostNominalsQueryResult = Apollo.QueryResult<
  GetMemberPostNominalsQuery,
  GetMemberPostNominalsQueryVariables
>;
export const GetMembersSelectDocument = gql`
  query GetMembersSelect($input: QueryMembersInput!) {
    members(input: $input) {
      id
      timezone
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMembersSelectQuery__
 *
 * To run a query within a React component, call `useGetMembersSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMembersSelectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMembersSelectQuery,
    GetMembersSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembersSelectQuery, GetMembersSelectQueryVariables>(
    GetMembersSelectDocument,
    options,
  );
}
export function useGetMembersSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMembersSelectQuery,
    GetMembersSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMembersSelectQuery,
    GetMembersSelectQueryVariables
  >(GetMembersSelectDocument, options);
}
export type GetMembersSelectQueryHookResult = ReturnType<
  typeof useGetMembersSelectQuery
>;
export type GetMembersSelectLazyQueryHookResult = ReturnType<
  typeof useGetMembersSelectLazyQuery
>;
export type GetMembersSelectQueryResult = Apollo.QueryResult<
  GetMembersSelectQuery,
  GetMembersSelectQueryVariables
>;
export const GetMyUnavailablesDocument = gql`
  query GetMyUnavailables($input: QueryMyMemberUnavailablesInput!) {
    myUnavailables(input: $input) {
      id
      startAt
      endAt
      notes
      owner {
        id
      }
    }
  }
`;

/**
 * __useGetMyUnavailablesQuery__
 *
 * To run a query within a React component, call `useGetMyUnavailablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUnavailablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUnavailablesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyUnavailablesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyUnavailablesQuery,
    GetMyUnavailablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyUnavailablesQuery,
    GetMyUnavailablesQueryVariables
  >(GetMyUnavailablesDocument, options);
}
export function useGetMyUnavailablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUnavailablesQuery,
    GetMyUnavailablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyUnavailablesQuery,
    GetMyUnavailablesQueryVariables
  >(GetMyUnavailablesDocument, options);
}
export type GetMyUnavailablesQueryHookResult = ReturnType<
  typeof useGetMyUnavailablesQuery
>;
export type GetMyUnavailablesLazyQueryHookResult = ReturnType<
  typeof useGetMyUnavailablesLazyQuery
>;
export type GetMyUnavailablesQueryResult = Apollo.QueryResult<
  GetMyUnavailablesQuery,
  GetMyUnavailablesQueryVariables
>;
export const GetRedactedMemberByIdDocument = gql`
  query GetRedactedMemberById($id: ID!) {
    redactedMemberById(id: $id) {
      id
      color
      email
      phone
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetRedactedMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetRedactedMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedMemberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRedactedMemberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedactedMemberByIdQuery,
    GetRedactedMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRedactedMemberByIdQuery,
    GetRedactedMemberByIdQueryVariables
  >(GetRedactedMemberByIdDocument, options);
}
export function useGetRedactedMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedMemberByIdQuery,
    GetRedactedMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRedactedMemberByIdQuery,
    GetRedactedMemberByIdQueryVariables
  >(GetRedactedMemberByIdDocument, options);
}
export type GetRedactedMemberByIdQueryHookResult = ReturnType<
  typeof useGetRedactedMemberByIdQuery
>;
export type GetRedactedMemberByIdLazyQueryHookResult = ReturnType<
  typeof useGetRedactedMemberByIdLazyQuery
>;
export type GetRedactedMemberByIdQueryResult = Apollo.QueryResult<
  GetRedactedMemberByIdQuery,
  GetRedactedMemberByIdQueryVariables
>;
export const GetRedactedMembersDocument = gql`
  query GetRedactedMembers($input: QueryRedactedMembersInput!) {
    redactedMembers(input: $input) {
      id
      color
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetRedactedMembersQuery__
 *
 * To run a query within a React component, call `useGetRedactedMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedactedMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedactedMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRedactedMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedactedMembersQuery,
    GetRedactedMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRedactedMembersQuery,
    GetRedactedMembersQueryVariables
  >(GetRedactedMembersDocument, options);
}
export function useGetRedactedMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedactedMembersQuery,
    GetRedactedMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRedactedMembersQuery,
    GetRedactedMembersQueryVariables
  >(GetRedactedMembersDocument, options);
}
export type GetRedactedMembersQueryHookResult = ReturnType<
  typeof useGetRedactedMembersQuery
>;
export type GetRedactedMembersLazyQueryHookResult = ReturnType<
  typeof useGetRedactedMembersLazyQuery
>;
export type GetRedactedMembersQueryResult = Apollo.QueryResult<
  GetRedactedMembersQuery,
  GetRedactedMembersQueryVariables
>;
export const MemberListContextGetAggregatedMembersDocument = gql`
  query MemberListContextGetAggregatedMembers($input: QueryMembersInput!) {
    membersAggregate(input: $input) {
      aggregate {
        totalCount
      }
      nodes {
        id
        createdAt
        admin
        permissions
        color
        hasAccess
        externalId
        lastActiveAt
        employmentStartDate
        schedulable
        trackFiles
        currentHourlyBasePayRate
        currentAnnualPayRate
        bonusEligible
        employementContractExternallySaved
        ...PersonNames
        invitationMostRecentlyReceived {
          id
          deletedAt
          usedAt
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useMemberListContextGetAggregatedMembersQuery__
 *
 * To run a query within a React component, call `useMemberListContextGetAggregatedMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberListContextGetAggregatedMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberListContextGetAggregatedMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberListContextGetAggregatedMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberListContextGetAggregatedMembersQuery,
    MemberListContextGetAggregatedMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberListContextGetAggregatedMembersQuery,
    MemberListContextGetAggregatedMembersQueryVariables
  >(MemberListContextGetAggregatedMembersDocument, options);
}
export function useMemberListContextGetAggregatedMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberListContextGetAggregatedMembersQuery,
    MemberListContextGetAggregatedMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberListContextGetAggregatedMembersQuery,
    MemberListContextGetAggregatedMembersQueryVariables
  >(MemberListContextGetAggregatedMembersDocument, options);
}
export type MemberListContextGetAggregatedMembersQueryHookResult = ReturnType<
  typeof useMemberListContextGetAggregatedMembersQuery
>;
export type MemberListContextGetAggregatedMembersLazyQueryHookResult =
  ReturnType<typeof useMemberListContextGetAggregatedMembersLazyQuery>;
export type MemberListContextGetAggregatedMembersQueryResult =
  Apollo.QueryResult<
    MemberListContextGetAggregatedMembersQuery,
    MemberListContextGetAggregatedMembersQueryVariables
  >;
export const MemberListContextGetAggregatedRedactedMembersDocument = gql`
  query MemberListContextGetAggregatedRedactedMembers(
    $input: QueryRedactedMembersInput!
  ) {
    redactedMembersAggregate(input: $input) {
      aggregate {
        totalCount
      }
      nodes {
        id
        color
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useMemberListContextGetAggregatedRedactedMembersQuery__
 *
 * To run a query within a React component, call `useMemberListContextGetAggregatedRedactedMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberListContextGetAggregatedRedactedMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberListContextGetAggregatedRedactedMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberListContextGetAggregatedRedactedMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberListContextGetAggregatedRedactedMembersQuery,
    MemberListContextGetAggregatedRedactedMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberListContextGetAggregatedRedactedMembersQuery,
    MemberListContextGetAggregatedRedactedMembersQueryVariables
  >(MemberListContextGetAggregatedRedactedMembersDocument, options);
}
export function useMemberListContextGetAggregatedRedactedMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberListContextGetAggregatedRedactedMembersQuery,
    MemberListContextGetAggregatedRedactedMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberListContextGetAggregatedRedactedMembersQuery,
    MemberListContextGetAggregatedRedactedMembersQueryVariables
  >(MemberListContextGetAggregatedRedactedMembersDocument, options);
}
export type MemberListContextGetAggregatedRedactedMembersQueryHookResult =
  ReturnType<typeof useMemberListContextGetAggregatedRedactedMembersQuery>;
export type MemberListContextGetAggregatedRedactedMembersLazyQueryHookResult =
  ReturnType<typeof useMemberListContextGetAggregatedRedactedMembersLazyQuery>;
export type MemberListContextGetAggregatedRedactedMembersQueryResult =
  Apollo.QueryResult<
    MemberListContextGetAggregatedRedactedMembersQuery,
    MemberListContextGetAggregatedRedactedMembersQueryVariables
  >;
export const MemberListContextGetMembersExtraFieldsDocument = gql`
  query MemberListContextGetMembersExtraFields($input: QueryMembersInput!) {
    members(input: $input) {
      id
      totalBillableMinutes
      lateness
      firstBillableEvent {
        id
        startAt
      }
      lastBillableEvent {
        id
        startAt
      }
    }
  }
`;

/**
 * __useMemberListContextGetMembersExtraFieldsQuery__
 *
 * To run a query within a React component, call `useMemberListContextGetMembersExtraFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberListContextGetMembersExtraFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberListContextGetMembersExtraFieldsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMemberListContextGetMembersExtraFieldsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberListContextGetMembersExtraFieldsQuery,
    MemberListContextGetMembersExtraFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberListContextGetMembersExtraFieldsQuery,
    MemberListContextGetMembersExtraFieldsQueryVariables
  >(MemberListContextGetMembersExtraFieldsDocument, options);
}
export function useMemberListContextGetMembersExtraFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberListContextGetMembersExtraFieldsQuery,
    MemberListContextGetMembersExtraFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberListContextGetMembersExtraFieldsQuery,
    MemberListContextGetMembersExtraFieldsQueryVariables
  >(MemberListContextGetMembersExtraFieldsDocument, options);
}
export type MemberListContextGetMembersExtraFieldsQueryHookResult = ReturnType<
  typeof useMemberListContextGetMembersExtraFieldsQuery
>;
export type MemberListContextGetMembersExtraFieldsLazyQueryHookResult =
  ReturnType<typeof useMemberListContextGetMembersExtraFieldsLazyQuery>;
export type MemberListContextGetMembersExtraFieldsQueryResult =
  Apollo.QueryResult<
    MemberListContextGetMembersExtraFieldsQuery,
    MemberListContextGetMembersExtraFieldsQueryVariables
  >;
export const ValidateMemberExternalIdsDocument = gql`
  query ValidateMemberExternalIds($input: MembersValidateExternalldsInput!) {
    validateMemberExternalIds(input: $input) {
      ids
    }
  }
`;

/**
 * __useValidateMemberExternalIdsQuery__
 *
 * To run a query within a React component, call `useValidateMemberExternalIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateMemberExternalIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateMemberExternalIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateMemberExternalIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateMemberExternalIdsQuery,
    ValidateMemberExternalIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateMemberExternalIdsQuery,
    ValidateMemberExternalIdsQueryVariables
  >(ValidateMemberExternalIdsDocument, options);
}
export function useValidateMemberExternalIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateMemberExternalIdsQuery,
    ValidateMemberExternalIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateMemberExternalIdsQuery,
    ValidateMemberExternalIdsQueryVariables
  >(ValidateMemberExternalIdsDocument, options);
}
export type ValidateMemberExternalIdsQueryHookResult = ReturnType<
  typeof useValidateMemberExternalIdsQuery
>;
export type ValidateMemberExternalIdsLazyQueryHookResult = ReturnType<
  typeof useValidateMemberExternalIdsLazyQuery
>;
export type ValidateMemberExternalIdsQueryResult = Apollo.QueryResult<
  ValidateMemberExternalIdsQuery,
  ValidateMemberExternalIdsQueryVariables
>;
export const NdisCoordinationClientByIdDocument = gql`
  query NdisCoordinationClientById($id: ID!) {
    ndisCoordinationClientById(id: $id) {
      id
      createdAt
      color
      dob
      email
      phone
      bio
      ndisId
      externalId
      ...PersonNames
      ...PersonGenderIdentifiers
      ...AddressFull
      ...AddressGeolocation
      branch {
        id
        name
      }
      createdBy {
        id
        ...PersonNamesPartial
      }
      preferredContact {
        id
      }
      planManager {
        id
        name
      }
      archive {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${PersonGenderIdentifiersFragmentDoc}
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useNdisCoordinationClientByIdQuery__
 *
 * To run a query within a React component, call `useNdisCoordinationClientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useNdisCoordinationClientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNdisCoordinationClientByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNdisCoordinationClientByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    NdisCoordinationClientByIdQuery,
    NdisCoordinationClientByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NdisCoordinationClientByIdQuery,
    NdisCoordinationClientByIdQueryVariables
  >(NdisCoordinationClientByIdDocument, options);
}
export function useNdisCoordinationClientByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NdisCoordinationClientByIdQuery,
    NdisCoordinationClientByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NdisCoordinationClientByIdQuery,
    NdisCoordinationClientByIdQueryVariables
  >(NdisCoordinationClientByIdDocument, options);
}
export type NdisCoordinationClientByIdQueryHookResult = ReturnType<
  typeof useNdisCoordinationClientByIdQuery
>;
export type NdisCoordinationClientByIdLazyQueryHookResult = ReturnType<
  typeof useNdisCoordinationClientByIdLazyQuery
>;
export type NdisCoordinationClientByIdQueryResult = Apollo.QueryResult<
  NdisCoordinationClientByIdQuery,
  NdisCoordinationClientByIdQueryVariables
>;
export const NdisCoordinationClientListContextGetAggregatedClientsDocument = gql`
  query NdisCoordinationClientListContextGetAggregatedClients(
    $input: QueryNdisCoordinationClientInput!
  ) {
    ndisCoordinationClientsAggregate(input: $input) {
      aggregate {
        totalCount
      }
      nodes {
        id
        createdAt
        color
        totalActivityMinutes
        firstActivityDate
        lastActivityDate
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useNdisCoordinationClientListContextGetAggregatedClientsQuery__
 *
 * To run a query within a React component, call `useNdisCoordinationClientListContextGetAggregatedClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNdisCoordinationClientListContextGetAggregatedClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNdisCoordinationClientListContextGetAggregatedClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNdisCoordinationClientListContextGetAggregatedClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NdisCoordinationClientListContextGetAggregatedClientsQuery,
    NdisCoordinationClientListContextGetAggregatedClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NdisCoordinationClientListContextGetAggregatedClientsQuery,
    NdisCoordinationClientListContextGetAggregatedClientsQueryVariables
  >(NdisCoordinationClientListContextGetAggregatedClientsDocument, options);
}
export function useNdisCoordinationClientListContextGetAggregatedClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NdisCoordinationClientListContextGetAggregatedClientsQuery,
    NdisCoordinationClientListContextGetAggregatedClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NdisCoordinationClientListContextGetAggregatedClientsQuery,
    NdisCoordinationClientListContextGetAggregatedClientsQueryVariables
  >(NdisCoordinationClientListContextGetAggregatedClientsDocument, options);
}
export type NdisCoordinationClientListContextGetAggregatedClientsQueryHookResult =
  ReturnType<
    typeof useNdisCoordinationClientListContextGetAggregatedClientsQuery
  >;
export type NdisCoordinationClientListContextGetAggregatedClientsLazyQueryHookResult =
  ReturnType<
    typeof useNdisCoordinationClientListContextGetAggregatedClientsLazyQuery
  >;
export type NdisCoordinationClientListContextGetAggregatedClientsQueryResult =
  Apollo.QueryResult<
    NdisCoordinationClientListContextGetAggregatedClientsQuery,
    NdisCoordinationClientListContextGetAggregatedClientsQueryVariables
  >;
export const GetCurrentOrgPayrollSettingsDocument = gql`
  query GetCurrentOrgPayrollSettings {
    me {
      id
      member {
        id
        org {
          id
          basePayRate
          afternoonPayMultiplier
          nightPayMultiplier
          satPayMultiplier
          sunPayMultiplier
          pubPayMultiplier
          passiveAllowance
          kmAllowance
          bonus2PayRate
          bonus3PayRate
          bonus4PayRate
          bonus5PayRate
          bonus6PayRate
          bonus7PayRate
          bonus8PayRate
          bonus9PayRate
          bonus10PayRate
          bonus11PayRate
          bonus12PayRate
        }
      }
    }
  }
`;

/**
 * __useGetCurrentOrgPayrollSettingsQuery__
 *
 * To run a query within a React component, call `useGetCurrentOrgPayrollSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOrgPayrollSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOrgPayrollSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentOrgPayrollSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentOrgPayrollSettingsQuery,
    GetCurrentOrgPayrollSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentOrgPayrollSettingsQuery,
    GetCurrentOrgPayrollSettingsQueryVariables
  >(GetCurrentOrgPayrollSettingsDocument, options);
}
export function useGetCurrentOrgPayrollSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentOrgPayrollSettingsQuery,
    GetCurrentOrgPayrollSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentOrgPayrollSettingsQuery,
    GetCurrentOrgPayrollSettingsQueryVariables
  >(GetCurrentOrgPayrollSettingsDocument, options);
}
export type GetCurrentOrgPayrollSettingsQueryHookResult = ReturnType<
  typeof useGetCurrentOrgPayrollSettingsQuery
>;
export type GetCurrentOrgPayrollSettingsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentOrgPayrollSettingsLazyQuery
>;
export type GetCurrentOrgPayrollSettingsQueryResult = Apollo.QueryResult<
  GetCurrentOrgPayrollSettingsQuery,
  GetCurrentOrgPayrollSettingsQueryVariables
>;
export const GetOrgBillingSettingsByIdDocument = gql`
  query GetOrgBillingSettingsById($id: ID!) {
    orgById(id: $id) {
      id
      billing {
        day
        afternoon
        night
        saturday
        sunday
        publicHoliday
        overnight
        kms
      }
    }
  }
`;

/**
 * __useGetOrgBillingSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetOrgBillingSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgBillingSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgBillingSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgBillingSettingsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrgBillingSettingsByIdQuery,
    GetOrgBillingSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgBillingSettingsByIdQuery,
    GetOrgBillingSettingsByIdQueryVariables
  >(GetOrgBillingSettingsByIdDocument, options);
}
export function useGetOrgBillingSettingsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgBillingSettingsByIdQuery,
    GetOrgBillingSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgBillingSettingsByIdQuery,
    GetOrgBillingSettingsByIdQueryVariables
  >(GetOrgBillingSettingsByIdDocument, options);
}
export type GetOrgBillingSettingsByIdQueryHookResult = ReturnType<
  typeof useGetOrgBillingSettingsByIdQuery
>;
export type GetOrgBillingSettingsByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrgBillingSettingsByIdLazyQuery
>;
export type GetOrgBillingSettingsByIdQueryResult = Apollo.QueryResult<
  GetOrgBillingSettingsByIdQuery,
  GetOrgBillingSettingsByIdQueryVariables
>;
export const GetOrgIdeasDocument = gql`
  query GetOrgIdeas {
    me {
      id
      member {
        id
        org {
          id
          ideas
        }
      }
    }
  }
`;

/**
 * __useGetOrgIdeasQuery__
 *
 * To run a query within a React component, call `useGetOrgIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgIdeasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgIdeasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrgIdeasQuery,
    GetOrgIdeasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgIdeasQuery, GetOrgIdeasQueryVariables>(
    GetOrgIdeasDocument,
    options,
  );
}
export function useGetOrgIdeasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgIdeasQuery,
    GetOrgIdeasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrgIdeasQuery, GetOrgIdeasQueryVariables>(
    GetOrgIdeasDocument,
    options,
  );
}
export type GetOrgIdeasQueryHookResult = ReturnType<typeof useGetOrgIdeasQuery>;
export type GetOrgIdeasLazyQueryHookResult = ReturnType<
  typeof useGetOrgIdeasLazyQuery
>;
export type GetOrgIdeasQueryResult = Apollo.QueryResult<
  GetOrgIdeasQuery,
  GetOrgIdeasQueryVariables
>;
export const GetOrgNdisRegistrationGroupsDocument = gql`
  query GetOrgNdisRegistrationGroups($input: QueryNdisRegistrationGroupsInput) {
    me {
      id
      member {
        id
        org {
          id
          ndisRegistrationGroups(input: $input) {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetOrgNdisRegistrationGroupsQuery__
 *
 * To run a query within a React component, call `useGetOrgNdisRegistrationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgNdisRegistrationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgNdisRegistrationGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrgNdisRegistrationGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrgNdisRegistrationGroupsQuery,
    GetOrgNdisRegistrationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgNdisRegistrationGroupsQuery,
    GetOrgNdisRegistrationGroupsQueryVariables
  >(GetOrgNdisRegistrationGroupsDocument, options);
}
export function useGetOrgNdisRegistrationGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgNdisRegistrationGroupsQuery,
    GetOrgNdisRegistrationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgNdisRegistrationGroupsQuery,
    GetOrgNdisRegistrationGroupsQueryVariables
  >(GetOrgNdisRegistrationGroupsDocument, options);
}
export type GetOrgNdisRegistrationGroupsQueryHookResult = ReturnType<
  typeof useGetOrgNdisRegistrationGroupsQuery
>;
export type GetOrgNdisRegistrationGroupsLazyQueryHookResult = ReturnType<
  typeof useGetOrgNdisRegistrationGroupsLazyQuery
>;
export type GetOrgNdisRegistrationGroupsQueryResult = Apollo.QueryResult<
  GetOrgNdisRegistrationGroupsQuery,
  GetOrgNdisRegistrationGroupsQueryVariables
>;
export const GetOrgNdisSupportItemsDocument = gql`
  query GetOrgNdisSupportItems($input: QueryNdisSupportItemsInput) {
    me {
      id
      member {
        id
        org {
          id
          ndisRegistrationGroups {
            id
            name
            supportItems(input: $input) {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOrgNdisSupportItemsQuery__
 *
 * To run a query within a React component, call `useGetOrgNdisSupportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgNdisSupportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgNdisSupportItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrgNdisSupportItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrgNdisSupportItemsQuery,
    GetOrgNdisSupportItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgNdisSupportItemsQuery,
    GetOrgNdisSupportItemsQueryVariables
  >(GetOrgNdisSupportItemsDocument, options);
}
export function useGetOrgNdisSupportItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgNdisSupportItemsQuery,
    GetOrgNdisSupportItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgNdisSupportItemsQuery,
    GetOrgNdisSupportItemsQueryVariables
  >(GetOrgNdisSupportItemsDocument, options);
}
export type GetOrgNdisSupportItemsQueryHookResult = ReturnType<
  typeof useGetOrgNdisSupportItemsQuery
>;
export type GetOrgNdisSupportItemsLazyQueryHookResult = ReturnType<
  typeof useGetOrgNdisSupportItemsLazyQuery
>;
export type GetOrgNdisSupportItemsQueryResult = Apollo.QueryResult<
  GetOrgNdisSupportItemsQuery,
  GetOrgNdisSupportItemsQueryVariables
>;
export const GetOrgPayrollSettingsByIdDocument = gql`
  query GetOrgPayrollSettingsById($id: ID!) {
    orgById(id: $id) {
      id
      basePayRate
      afternoonPayMultiplier
      nightPayMultiplier
      satPayMultiplier
      sunPayMultiplier
      pubPayMultiplier
      passiveAllowance
      kmAllowance
      bonus2PayRate
      bonus3PayRate
      bonus4PayRate
      bonus5PayRate
      bonus6PayRate
      bonus7PayRate
      bonus8PayRate
      bonus9PayRate
      bonus10PayRate
      bonus11PayRate
      bonus12PayRate
    }
  }
`;

/**
 * __useGetOrgPayrollSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetOrgPayrollSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgPayrollSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgPayrollSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgPayrollSettingsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrgPayrollSettingsByIdQuery,
    GetOrgPayrollSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgPayrollSettingsByIdQuery,
    GetOrgPayrollSettingsByIdQueryVariables
  >(GetOrgPayrollSettingsByIdDocument, options);
}
export function useGetOrgPayrollSettingsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgPayrollSettingsByIdQuery,
    GetOrgPayrollSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgPayrollSettingsByIdQuery,
    GetOrgPayrollSettingsByIdQueryVariables
  >(GetOrgPayrollSettingsByIdDocument, options);
}
export type GetOrgPayrollSettingsByIdQueryHookResult = ReturnType<
  typeof useGetOrgPayrollSettingsByIdQuery
>;
export type GetOrgPayrollSettingsByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrgPayrollSettingsByIdLazyQuery
>;
export type GetOrgPayrollSettingsByIdQueryResult = Apollo.QueryResult<
  GetOrgPayrollSettingsByIdQuery,
  GetOrgPayrollSettingsByIdQueryVariables
>;
export const GetOrgScheduleLatenessSettingsDocument = gql`
  query GetOrgScheduleLatenessSettings {
    me {
      id
      member {
        id
        org {
          id
          maxMemberLatenessForAutomaticSundayEvents
          maxMemberLatenessForAutomaticSaturdayEvents
          maxMemberLatenessForAutomaticPublicHolidayEvents
          maxMemberLatenessForAutomaticHandoverEvents
        }
      }
    }
  }
`;

/**
 * __useGetOrgScheduleLatenessSettingsQuery__
 *
 * To run a query within a React component, call `useGetOrgScheduleLatenessSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgScheduleLatenessSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgScheduleLatenessSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgScheduleLatenessSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrgScheduleLatenessSettingsQuery,
    GetOrgScheduleLatenessSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgScheduleLatenessSettingsQuery,
    GetOrgScheduleLatenessSettingsQueryVariables
  >(GetOrgScheduleLatenessSettingsDocument, options);
}
export function useGetOrgScheduleLatenessSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgScheduleLatenessSettingsQuery,
    GetOrgScheduleLatenessSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgScheduleLatenessSettingsQuery,
    GetOrgScheduleLatenessSettingsQueryVariables
  >(GetOrgScheduleLatenessSettingsDocument, options);
}
export type GetOrgScheduleLatenessSettingsQueryHookResult = ReturnType<
  typeof useGetOrgScheduleLatenessSettingsQuery
>;
export type GetOrgScheduleLatenessSettingsLazyQueryHookResult = ReturnType<
  typeof useGetOrgScheduleLatenessSettingsLazyQuery
>;
export type GetOrgScheduleLatenessSettingsQueryResult = Apollo.QueryResult<
  GetOrgScheduleLatenessSettingsQuery,
  GetOrgScheduleLatenessSettingsQueryVariables
>;
export const GetOrgSettingsByIdDocument = gql`
  query GetOrgSettingsById($id: ID!) {
    orgById(id: $id) {
      id
      membersCanSeeClockedTimes
      quantityOfFutureShiftsMembersCanSee
      quantityOfPastShiftsMembersCanSee
      taskAssignAttendeeEnable
      taskAssignAttendeeFutureDays
      taskAssignAttendeePastWeeks
      unfilledEventsDayRange
      clockOnWarnMinutes
      clockOffWarnMinutes
      allowedDurationBetweenConflicts
      travelTimeAllowSameClient
      travelTimeAllowedDurationBetweenEvents
      claimEventsDayRange
      scheduleMaxDayTimeLimitDefault
      scheduleMaxWeekTimeLimitDefault
      maxMemberLatenessForAutomaticSundayEvents
      maxMemberLatenessForAutomaticSaturdayEvents
      maxMemberLatenessForAutomaticPublicHolidayEvents
      maxMemberLatenessForAutomaticHandoverEvents
      autoArchiveClockedOffEventsAfterNumberOfHours
      schedulingContactPerson {
        id
        phone
        ...PersonNamesPartial
      }
      schedulingContactPerson2 {
        id
        phone
        ...PersonNamesPartial
      }
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetOrgSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetOrgSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgSettingsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrgSettingsByIdQuery,
    GetOrgSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgSettingsByIdQuery,
    GetOrgSettingsByIdQueryVariables
  >(GetOrgSettingsByIdDocument, options);
}
export function useGetOrgSettingsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgSettingsByIdQuery,
    GetOrgSettingsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgSettingsByIdQuery,
    GetOrgSettingsByIdQueryVariables
  >(GetOrgSettingsByIdDocument, options);
}
export type GetOrgSettingsByIdQueryHookResult = ReturnType<
  typeof useGetOrgSettingsByIdQuery
>;
export type GetOrgSettingsByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrgSettingsByIdLazyQuery
>;
export type GetOrgSettingsByIdQueryResult = Apollo.QueryResult<
  GetOrgSettingsByIdQuery,
  GetOrgSettingsByIdQueryVariables
>;
export const GetOrgUnfilledEventsDayRangeDocument = gql`
  query GetOrgUnfilledEventsDayRange($id: ID!) {
    orgById(id: $id) {
      id
      unfilledEventsDayRange
    }
  }
`;

/**
 * __useGetOrgUnfilledEventsDayRangeQuery__
 *
 * To run a query within a React component, call `useGetOrgUnfilledEventsDayRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUnfilledEventsDayRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUnfilledEventsDayRangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgUnfilledEventsDayRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrgUnfilledEventsDayRangeQuery,
    GetOrgUnfilledEventsDayRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrgUnfilledEventsDayRangeQuery,
    GetOrgUnfilledEventsDayRangeQueryVariables
  >(GetOrgUnfilledEventsDayRangeDocument, options);
}
export function useGetOrgUnfilledEventsDayRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgUnfilledEventsDayRangeQuery,
    GetOrgUnfilledEventsDayRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrgUnfilledEventsDayRangeQuery,
    GetOrgUnfilledEventsDayRangeQueryVariables
  >(GetOrgUnfilledEventsDayRangeDocument, options);
}
export type GetOrgUnfilledEventsDayRangeQueryHookResult = ReturnType<
  typeof useGetOrgUnfilledEventsDayRangeQuery
>;
export type GetOrgUnfilledEventsDayRangeLazyQueryHookResult = ReturnType<
  typeof useGetOrgUnfilledEventsDayRangeLazyQuery
>;
export type GetOrgUnfilledEventsDayRangeQueryResult = Apollo.QueryResult<
  GetOrgUnfilledEventsDayRangeQuery,
  GetOrgUnfilledEventsDayRangeQueryVariables
>;
export const GetPlanManagerByIdDocument = gql`
  query GetPlanManagerById($id: ID!) {
    planManagerById(id: $id) {
      id
      abn
      name
      salutation
      phone
      businessName
      contactEmail
      billingEmail
      notes
      archive {
        id
      }
      delete {
        id
      }
    }
  }
`;

/**
 * __useGetPlanManagerByIdQuery__
 *
 * To run a query within a React component, call `useGetPlanManagerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanManagerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanManagerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlanManagerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlanManagerByIdQuery,
    GetPlanManagerByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlanManagerByIdQuery,
    GetPlanManagerByIdQueryVariables
  >(GetPlanManagerByIdDocument, options);
}
export function useGetPlanManagerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlanManagerByIdQuery,
    GetPlanManagerByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlanManagerByIdQuery,
    GetPlanManagerByIdQueryVariables
  >(GetPlanManagerByIdDocument, options);
}
export type GetPlanManagerByIdQueryHookResult = ReturnType<
  typeof useGetPlanManagerByIdQuery
>;
export type GetPlanManagerByIdLazyQueryHookResult = ReturnType<
  typeof useGetPlanManagerByIdLazyQuery
>;
export type GetPlanManagerByIdQueryResult = Apollo.QueryResult<
  GetPlanManagerByIdQuery,
  GetPlanManagerByIdQueryVariables
>;
export const GetPlanManagersDocument = gql`
  query GetPlanManagers($input: QueryPlanManagersInput!) {
    planManagers(input: $input) {
      id
      name
      archive {
        id
      }
      delete {
        id
      }
    }
  }
`;

/**
 * __useGetPlanManagersQuery__
 *
 * To run a query within a React component, call `useGetPlanManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanManagersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlanManagersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlanManagersQuery,
    GetPlanManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlanManagersQuery, GetPlanManagersQueryVariables>(
    GetPlanManagersDocument,
    options,
  );
}
export function useGetPlanManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlanManagersQuery,
    GetPlanManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlanManagersQuery,
    GetPlanManagersQueryVariables
  >(GetPlanManagersDocument, options);
}
export type GetPlanManagersQueryHookResult = ReturnType<
  typeof useGetPlanManagersQuery
>;
export type GetPlanManagersLazyQueryHookResult = ReturnType<
  typeof useGetPlanManagersLazyQuery
>;
export type GetPlanManagersQueryResult = Apollo.QueryResult<
  GetPlanManagersQuery,
  GetPlanManagersQueryVariables
>;
export const GetPublicHolidayByIdDocument = gql`
  query GetPublicHolidayById($id: ID!) {
    publicHolidayById(id: $id) {
      id
      date
      region
      description
    }
  }
`;

/**
 * __useGetPublicHolidayByIdQuery__
 *
 * To run a query within a React component, call `useGetPublicHolidayByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicHolidayByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicHolidayByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublicHolidayByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicHolidayByIdQuery,
    GetPublicHolidayByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPublicHolidayByIdQuery,
    GetPublicHolidayByIdQueryVariables
  >(GetPublicHolidayByIdDocument, options);
}
export function useGetPublicHolidayByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicHolidayByIdQuery,
    GetPublicHolidayByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPublicHolidayByIdQuery,
    GetPublicHolidayByIdQueryVariables
  >(GetPublicHolidayByIdDocument, options);
}
export type GetPublicHolidayByIdQueryHookResult = ReturnType<
  typeof useGetPublicHolidayByIdQuery
>;
export type GetPublicHolidayByIdLazyQueryHookResult = ReturnType<
  typeof useGetPublicHolidayByIdLazyQuery
>;
export type GetPublicHolidayByIdQueryResult = Apollo.QueryResult<
  GetPublicHolidayByIdQuery,
  GetPublicHolidayByIdQueryVariables
>;
export const GetPublicHolidaysDocument = gql`
  query GetPublicHolidays($input: QueryPublicHolidaysInput!) {
    publicHolidays(input: $input) {
      id
      date
      region
      description
    }
  }
`;

/**
 * __useGetPublicHolidaysQuery__
 *
 * To run a query within a React component, call `useGetPublicHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicHolidaysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPublicHolidaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicHolidaysQuery,
    GetPublicHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPublicHolidaysQuery,
    GetPublicHolidaysQueryVariables
  >(GetPublicHolidaysDocument, options);
}
export function useGetPublicHolidaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicHolidaysQuery,
    GetPublicHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPublicHolidaysQuery,
    GetPublicHolidaysQueryVariables
  >(GetPublicHolidaysDocument, options);
}
export type GetPublicHolidaysQueryHookResult = ReturnType<
  typeof useGetPublicHolidaysQuery
>;
export type GetPublicHolidaysLazyQueryHookResult = ReturnType<
  typeof useGetPublicHolidaysLazyQuery
>;
export type GetPublicHolidaysQueryResult = Apollo.QueryResult<
  GetPublicHolidaysQuery,
  GetPublicHolidaysQueryVariables
>;
export const GetActivitySlipMembersDocument = gql`
  query GetActivitySlipMembers($input: QueryMembersInput!) {
    members(input: $input) {
      id
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetActivitySlipMembersQuery__
 *
 * To run a query within a React component, call `useGetActivitySlipMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitySlipMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitySlipMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActivitySlipMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivitySlipMembersQuery,
    GetActivitySlipMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivitySlipMembersQuery,
    GetActivitySlipMembersQueryVariables
  >(GetActivitySlipMembersDocument, options);
}
export function useGetActivitySlipMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitySlipMembersQuery,
    GetActivitySlipMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivitySlipMembersQuery,
    GetActivitySlipMembersQueryVariables
  >(GetActivitySlipMembersDocument, options);
}
export type GetActivitySlipMembersQueryHookResult = ReturnType<
  typeof useGetActivitySlipMembersQuery
>;
export type GetActivitySlipMembersLazyQueryHookResult = ReturnType<
  typeof useGetActivitySlipMembersLazyQuery
>;
export type GetActivitySlipMembersQueryResult = Apollo.QueryResult<
  GetActivitySlipMembersQuery,
  GetActivitySlipMembersQueryVariables
>;
export const GetActivitySlipsDocument = gql`
  query GetActivitySlips($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      passive
      passiveStartAt
      activeAssist
      publicHoliday
      travelDistance
      travelTime
      billable
      billingRegistrationGroup
      payable
      bonusPay
      clockedOnAt
      clockedOffAt
      hasNotes
      region
      issues {
        id
        type
        closedAt
      }
      cancel {
        id
      }
      client {
        id
        ndisId
        ...PersonNames
        planManager {
          id
          name
        }
      }
      member {
        id
        externalId
        bonusEligible
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetActivitySlipsQuery__
 *
 * To run a query within a React component, call `useGetActivitySlipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitySlipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitySlipsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActivitySlipsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivitySlipsQuery,
    GetActivitySlipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivitySlipsQuery, GetActivitySlipsQueryVariables>(
    GetActivitySlipsDocument,
    options,
  );
}
export function useGetActivitySlipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitySlipsQuery,
    GetActivitySlipsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivitySlipsQuery,
    GetActivitySlipsQueryVariables
  >(GetActivitySlipsDocument, options);
}
export type GetActivitySlipsQueryHookResult = ReturnType<
  typeof useGetActivitySlipsQuery
>;
export type GetActivitySlipsLazyQueryHookResult = ReturnType<
  typeof useGetActivitySlipsLazyQuery
>;
export type GetActivitySlipsQueryResult = Apollo.QueryResult<
  GetActivitySlipsQuery,
  GetActivitySlipsQueryVariables
>;
export const GetMemberWorkedHoursDocument = gql`
  query GetMemberWorkedHours($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      duration
      clockedOnAt
      clockedOffAt
      travelTime
      passive
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMemberWorkedHoursQuery__
 *
 * To run a query within a React component, call `useGetMemberWorkedHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberWorkedHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberWorkedHoursQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMemberWorkedHoursQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberWorkedHoursQuery,
    GetMemberWorkedHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberWorkedHoursQuery,
    GetMemberWorkedHoursQueryVariables
  >(GetMemberWorkedHoursDocument, options);
}
export function useGetMemberWorkedHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberWorkedHoursQuery,
    GetMemberWorkedHoursQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberWorkedHoursQuery,
    GetMemberWorkedHoursQueryVariables
  >(GetMemberWorkedHoursDocument, options);
}
export type GetMemberWorkedHoursQueryHookResult = ReturnType<
  typeof useGetMemberWorkedHoursQuery
>;
export type GetMemberWorkedHoursLazyQueryHookResult = ReturnType<
  typeof useGetMemberWorkedHoursLazyQuery
>;
export type GetMemberWorkedHoursQueryResult = Apollo.QueryResult<
  GetMemberWorkedHoursQuery,
  GetMemberWorkedHoursQueryVariables
>;
export const GetShiftsToApproveDocument = gql`
  query GetShiftsToApprove($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      clockedOnAt
      clockedOnLatitude
      clockedOnLongitude
      clockedOffAt
      clockedOffLatitude
      clockedOffLongitude
      payable
      client {
        id
        ...PersonNames
      }
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetShiftsToApproveQuery__
 *
 * To run a query within a React component, call `useGetShiftsToApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftsToApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftsToApproveQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShiftsToApproveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShiftsToApproveQuery,
    GetShiftsToApproveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetShiftsToApproveQuery,
    GetShiftsToApproveQueryVariables
  >(GetShiftsToApproveDocument, options);
}
export function useGetShiftsToApproveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShiftsToApproveQuery,
    GetShiftsToApproveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetShiftsToApproveQuery,
    GetShiftsToApproveQueryVariables
  >(GetShiftsToApproveDocument, options);
}
export type GetShiftsToApproveQueryHookResult = ReturnType<
  typeof useGetShiftsToApproveQuery
>;
export type GetShiftsToApproveLazyQueryHookResult = ReturnType<
  typeof useGetShiftsToApproveLazyQuery
>;
export type GetShiftsToApproveQueryResult = Apollo.QueryResult<
  GetShiftsToApproveQuery,
  GetShiftsToApproveQueryVariables
>;
export const ReportActivitySlipsGetMemberPayrollChecksDocument = gql`
  query ReportActivitySlipsGetMemberPayrollChecks(
    $input: QueryMemberPayrollChecksInput!
  ) {
    memberPayrollChecks(input: $input) {
      id
      eventsChecked
      clocksChecked
      paid
      member {
        id
      }
    }
  }
`;

/**
 * __useReportActivitySlipsGetMemberPayrollChecksQuery__
 *
 * To run a query within a React component, call `useReportActivitySlipsGetMemberPayrollChecksQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportActivitySlipsGetMemberPayrollChecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportActivitySlipsGetMemberPayrollChecksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportActivitySlipsGetMemberPayrollChecksQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportActivitySlipsGetMemberPayrollChecksQuery,
    ReportActivitySlipsGetMemberPayrollChecksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportActivitySlipsGetMemberPayrollChecksQuery,
    ReportActivitySlipsGetMemberPayrollChecksQueryVariables
  >(ReportActivitySlipsGetMemberPayrollChecksDocument, options);
}
export function useReportActivitySlipsGetMemberPayrollChecksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportActivitySlipsGetMemberPayrollChecksQuery,
    ReportActivitySlipsGetMemberPayrollChecksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportActivitySlipsGetMemberPayrollChecksQuery,
    ReportActivitySlipsGetMemberPayrollChecksQueryVariables
  >(ReportActivitySlipsGetMemberPayrollChecksDocument, options);
}
export type ReportActivitySlipsGetMemberPayrollChecksQueryHookResult =
  ReturnType<typeof useReportActivitySlipsGetMemberPayrollChecksQuery>;
export type ReportActivitySlipsGetMemberPayrollChecksLazyQueryHookResult =
  ReturnType<typeof useReportActivitySlipsGetMemberPayrollChecksLazyQuery>;
export type ReportActivitySlipsGetMemberPayrollChecksQueryResult =
  Apollo.QueryResult<
    ReportActivitySlipsGetMemberPayrollChecksQuery,
    ReportActivitySlipsGetMemberPayrollChecksQueryVariables
  >;
export const ReportEventCheckerGetEventsDocument = gql`
  query ReportEventCheckerGetEvents($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      notes
      passive
      passiveStartAt
      activeAssist
      billable
      payable
      publicHoliday
      ...AddressFull
      ...AddressGeolocation
      clockedOnAt
      clockedOffAt
      clockedOnLatitude
      clockedOnLongitude
      clockedOffLatitude
      clockedOffLongitude
      clockedOnBy {
        id
        ...PersonNames
      }
      clockedOffBy {
        id
        ...PersonNames
      }
      travelDistance
      travelDistanceNotes
      travelTime
      travelTimeNotes
      client {
        id
        ...PersonNamesPartial
      }
      member {
        id
        ...PersonNamesPartial
      }
      cancel {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
    }
  }
  ${AddressFullFragmentDoc}
  ${AddressGeolocationFragmentDoc}
  ${PersonNamesFragmentDoc}
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useReportEventCheckerGetEventsQuery__
 *
 * To run a query within a React component, call `useReportEventCheckerGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportEventCheckerGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportEventCheckerGetEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportEventCheckerGetEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportEventCheckerGetEventsQuery,
    ReportEventCheckerGetEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportEventCheckerGetEventsQuery,
    ReportEventCheckerGetEventsQueryVariables
  >(ReportEventCheckerGetEventsDocument, options);
}
export function useReportEventCheckerGetEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportEventCheckerGetEventsQuery,
    ReportEventCheckerGetEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportEventCheckerGetEventsQuery,
    ReportEventCheckerGetEventsQueryVariables
  >(ReportEventCheckerGetEventsDocument, options);
}
export type ReportEventCheckerGetEventsQueryHookResult = ReturnType<
  typeof useReportEventCheckerGetEventsQuery
>;
export type ReportEventCheckerGetEventsLazyQueryHookResult = ReturnType<
  typeof useReportEventCheckerGetEventsLazyQuery
>;
export type ReportEventCheckerGetEventsQueryResult = Apollo.QueryResult<
  ReportEventCheckerGetEventsQuery,
  ReportEventCheckerGetEventsQueryVariables
>;
export const ReportEventCheckerGetMembersDocument = gql`
  query ReportEventCheckerGetMembers($input: QueryMembersInput!) {
    members(input: $input) {
      id
      ...PersonNamesPartial
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useReportEventCheckerGetMembersQuery__
 *
 * To run a query within a React component, call `useReportEventCheckerGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportEventCheckerGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportEventCheckerGetMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportEventCheckerGetMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportEventCheckerGetMembersQuery,
    ReportEventCheckerGetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportEventCheckerGetMembersQuery,
    ReportEventCheckerGetMembersQueryVariables
  >(ReportEventCheckerGetMembersDocument, options);
}
export function useReportEventCheckerGetMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportEventCheckerGetMembersQuery,
    ReportEventCheckerGetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportEventCheckerGetMembersQuery,
    ReportEventCheckerGetMembersQueryVariables
  >(ReportEventCheckerGetMembersDocument, options);
}
export type ReportEventCheckerGetMembersQueryHookResult = ReturnType<
  typeof useReportEventCheckerGetMembersQuery
>;
export type ReportEventCheckerGetMembersLazyQueryHookResult = ReturnType<
  typeof useReportEventCheckerGetMembersLazyQuery
>;
export type ReportEventCheckerGetMembersQueryResult = Apollo.QueryResult<
  ReportEventCheckerGetMembersQuery,
  ReportEventCheckerGetMembersQueryVariables
>;
export const ReportMemberHoursGetMembersDocument = gql`
  query ReportMemberHoursGetMembers($input: QueryMembersInput!) {
    members(input: $input) {
      id
      hasCar
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportMemberHoursGetMembersQuery__
 *
 * To run a query within a React component, call `useReportMemberHoursGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportMemberHoursGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportMemberHoursGetMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportMemberHoursGetMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportMemberHoursGetMembersQuery,
    ReportMemberHoursGetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportMemberHoursGetMembersQuery,
    ReportMemberHoursGetMembersQueryVariables
  >(ReportMemberHoursGetMembersDocument, options);
}
export function useReportMemberHoursGetMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportMemberHoursGetMembersQuery,
    ReportMemberHoursGetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportMemberHoursGetMembersQuery,
    ReportMemberHoursGetMembersQueryVariables
  >(ReportMemberHoursGetMembersDocument, options);
}
export type ReportMemberHoursGetMembersQueryHookResult = ReturnType<
  typeof useReportMemberHoursGetMembersQuery
>;
export type ReportMemberHoursGetMembersLazyQueryHookResult = ReturnType<
  typeof useReportMemberHoursGetMembersLazyQuery
>;
export type ReportMemberHoursGetMembersQueryResult = Apollo.QueryResult<
  ReportMemberHoursGetMembersQuery,
  ReportMemberHoursGetMembersQueryVariables
>;
export const ReportMemberHoursGetMembers2Document = gql`
  query ReportMemberHoursGetMembers2(
    $membersInput: QueryMembersInput!
    $hoursInput: QueryMemberHoursInput!
  ) {
    members(input: $membersInput) {
      id
      ...PersonNames
      hours(input: $hoursInput) {
        id
        date
        type
        value
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportMemberHoursGetMembers2Query__
 *
 * To run a query within a React component, call `useReportMemberHoursGetMembers2Query` and pass it any options that fit your needs.
 * When your component renders, `useReportMemberHoursGetMembers2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportMemberHoursGetMembers2Query({
 *   variables: {
 *      membersInput: // value for 'membersInput'
 *      hoursInput: // value for 'hoursInput'
 *   },
 * });
 */
export function useReportMemberHoursGetMembers2Query(
  baseOptions: Apollo.QueryHookOptions<
    ReportMemberHoursGetMembers2Query,
    ReportMemberHoursGetMembers2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportMemberHoursGetMembers2Query,
    ReportMemberHoursGetMembers2QueryVariables
  >(ReportMemberHoursGetMembers2Document, options);
}
export function useReportMemberHoursGetMembers2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportMemberHoursGetMembers2Query,
    ReportMemberHoursGetMembers2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportMemberHoursGetMembers2Query,
    ReportMemberHoursGetMembers2QueryVariables
  >(ReportMemberHoursGetMembers2Document, options);
}
export type ReportMemberHoursGetMembers2QueryHookResult = ReturnType<
  typeof useReportMemberHoursGetMembers2Query
>;
export type ReportMemberHoursGetMembers2LazyQueryHookResult = ReturnType<
  typeof useReportMemberHoursGetMembers2LazyQuery
>;
export type ReportMemberHoursGetMembers2QueryResult = Apollo.QueryResult<
  ReportMemberHoursGetMembers2Query,
  ReportMemberHoursGetMembers2QueryVariables
>;
export const ReportMemberHoursGetSchedulesDocument = gql`
  query ReportMemberHoursGetSchedules(
    $scheduleInput: QueryMemberScheduleInput!
    $membersInput: QueryMembersInput!
  ) {
    members(input: $membersInput) {
      id
      ...PersonNames
      schedule(input: $scheduleInput) {
        allowedMinutes
        totalMinutes
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportMemberHoursGetSchedulesQuery__
 *
 * To run a query within a React component, call `useReportMemberHoursGetSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportMemberHoursGetSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportMemberHoursGetSchedulesQuery({
 *   variables: {
 *      scheduleInput: // value for 'scheduleInput'
 *      membersInput: // value for 'membersInput'
 *   },
 * });
 */
export function useReportMemberHoursGetSchedulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportMemberHoursGetSchedulesQuery,
    ReportMemberHoursGetSchedulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportMemberHoursGetSchedulesQuery,
    ReportMemberHoursGetSchedulesQueryVariables
  >(ReportMemberHoursGetSchedulesDocument, options);
}
export function useReportMemberHoursGetSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportMemberHoursGetSchedulesQuery,
    ReportMemberHoursGetSchedulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportMemberHoursGetSchedulesQuery,
    ReportMemberHoursGetSchedulesQueryVariables
  >(ReportMemberHoursGetSchedulesDocument, options);
}
export type ReportMemberHoursGetSchedulesQueryHookResult = ReturnType<
  typeof useReportMemberHoursGetSchedulesQuery
>;
export type ReportMemberHoursGetSchedulesLazyQueryHookResult = ReturnType<
  typeof useReportMemberHoursGetSchedulesLazyQuery
>;
export type ReportMemberHoursGetSchedulesQueryResult = Apollo.QueryResult<
  ReportMemberHoursGetSchedulesQuery,
  ReportMemberHoursGetSchedulesQueryVariables
>;
export const ReportMemberPayCalculatorGetInitialDataDocument = gql`
  query ReportMemberPayCalculatorGetInitialData(
    $membersInput: QueryMembersInput!
    $publicHolidaysInput: QueryPublicHolidaysInput!
    $payrollCategoriesInput: QueryPayrollCategoriesInput!
    $payrollCategoryValuesInput: QueryPayrollCategoryValuesInput!
  ) {
    members(input: $membersInput) {
      id
      ...PersonNamesPartial
    }
    publicHolidays(input: $publicHolidaysInput) {
      id
      date
    }
    me {
      id
      member {
        id
        org {
          id
          payrollCategories(input: $payrollCategoriesInput) {
            id
            name
            values(input: $payrollCategoryValuesInput) {
              id
              createdAt
              type
              fixedValue
              multiplier
            }
          }
        }
      }
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useReportMemberPayCalculatorGetInitialDataQuery__
 *
 * To run a query within a React component, call `useReportMemberPayCalculatorGetInitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportMemberPayCalculatorGetInitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportMemberPayCalculatorGetInitialDataQuery({
 *   variables: {
 *      membersInput: // value for 'membersInput'
 *      publicHolidaysInput: // value for 'publicHolidaysInput'
 *      payrollCategoriesInput: // value for 'payrollCategoriesInput'
 *      payrollCategoryValuesInput: // value for 'payrollCategoryValuesInput'
 *   },
 * });
 */
export function useReportMemberPayCalculatorGetInitialDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportMemberPayCalculatorGetInitialDataQuery,
    ReportMemberPayCalculatorGetInitialDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportMemberPayCalculatorGetInitialDataQuery,
    ReportMemberPayCalculatorGetInitialDataQueryVariables
  >(ReportMemberPayCalculatorGetInitialDataDocument, options);
}
export function useReportMemberPayCalculatorGetInitialDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportMemberPayCalculatorGetInitialDataQuery,
    ReportMemberPayCalculatorGetInitialDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportMemberPayCalculatorGetInitialDataQuery,
    ReportMemberPayCalculatorGetInitialDataQueryVariables
  >(ReportMemberPayCalculatorGetInitialDataDocument, options);
}
export type ReportMemberPayCalculatorGetInitialDataQueryHookResult = ReturnType<
  typeof useReportMemberPayCalculatorGetInitialDataQuery
>;
export type ReportMemberPayCalculatorGetInitialDataLazyQueryHookResult =
  ReturnType<typeof useReportMemberPayCalculatorGetInitialDataLazyQuery>;
export type ReportMemberPayCalculatorGetInitialDataQueryResult =
  Apollo.QueryResult<
    ReportMemberPayCalculatorGetInitialDataQuery,
    ReportMemberPayCalculatorGetInitialDataQueryVariables
  >;
export const ReportMemberPayCalculatorGetSubsequentDataDocument = gql`
  query ReportMemberPayCalculatorGetSubsequentData(
    $eventsInput: QueryEventsInput!
    $basePayRatesInput: QueryMemberBasePayRatesInput!
    $bonusEligiblesInput: QueryMemberBonusEligiblesInput!
  ) {
    events(input: $eventsInput) {
      id
      startAt
      endAt
      activeAssist
      passive
      passiveStartAt
      travelTime
      travelDistance
      payable
      member {
        id
      }
      cancel {
        id
      }
    }
    memberBasePayRates(input: $basePayRatesInput) {
      id
      createdAt
      value
      member {
        id
      }
    }
    memberBonusEligibles(input: $bonusEligiblesInput) {
      id
      createdAt
      value
      member {
        id
      }
    }
  }
`;

/**
 * __useReportMemberPayCalculatorGetSubsequentDataQuery__
 *
 * To run a query within a React component, call `useReportMemberPayCalculatorGetSubsequentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportMemberPayCalculatorGetSubsequentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportMemberPayCalculatorGetSubsequentDataQuery({
 *   variables: {
 *      eventsInput: // value for 'eventsInput'
 *      basePayRatesInput: // value for 'basePayRatesInput'
 *      bonusEligiblesInput: // value for 'bonusEligiblesInput'
 *   },
 * });
 */
export function useReportMemberPayCalculatorGetSubsequentDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportMemberPayCalculatorGetSubsequentDataQuery,
    ReportMemberPayCalculatorGetSubsequentDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportMemberPayCalculatorGetSubsequentDataQuery,
    ReportMemberPayCalculatorGetSubsequentDataQueryVariables
  >(ReportMemberPayCalculatorGetSubsequentDataDocument, options);
}
export function useReportMemberPayCalculatorGetSubsequentDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportMemberPayCalculatorGetSubsequentDataQuery,
    ReportMemberPayCalculatorGetSubsequentDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportMemberPayCalculatorGetSubsequentDataQuery,
    ReportMemberPayCalculatorGetSubsequentDataQueryVariables
  >(ReportMemberPayCalculatorGetSubsequentDataDocument, options);
}
export type ReportMemberPayCalculatorGetSubsequentDataQueryHookResult =
  ReturnType<typeof useReportMemberPayCalculatorGetSubsequentDataQuery>;
export type ReportMemberPayCalculatorGetSubsequentDataLazyQueryHookResult =
  ReturnType<typeof useReportMemberPayCalculatorGetSubsequentDataLazyQuery>;
export type ReportMemberPayCalculatorGetSubsequentDataQueryResult =
  Apollo.QueryResult<
    ReportMemberPayCalculatorGetSubsequentDataQuery,
    ReportMemberPayCalculatorGetSubsequentDataQueryVariables
  >;
export const ReportParticipantQuoteGetInitialDataDocument = gql`
  query ReportParticipantQuoteGetInitialData(
    $clientsInput: QueryClientsInput!
    $ndisActivitiesInput: QueryNdisActivitiesInput!
  ) {
    clients(input: $clientsInput) {
      id
      ndisId
      ...PersonNames
    }
    ndisActivities(input: $ndisActivitiesInput) {
      id
      code
      description
      rateNational
      rateRemote
      rateVeryRemote
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportParticipantQuoteGetInitialDataQuery__
 *
 * To run a query within a React component, call `useReportParticipantQuoteGetInitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportParticipantQuoteGetInitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportParticipantQuoteGetInitialDataQuery({
 *   variables: {
 *      clientsInput: // value for 'clientsInput'
 *      ndisActivitiesInput: // value for 'ndisActivitiesInput'
 *   },
 * });
 */
export function useReportParticipantQuoteGetInitialDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportParticipantQuoteGetInitialDataQuery,
    ReportParticipantQuoteGetInitialDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportParticipantQuoteGetInitialDataQuery,
    ReportParticipantQuoteGetInitialDataQueryVariables
  >(ReportParticipantQuoteGetInitialDataDocument, options);
}
export function useReportParticipantQuoteGetInitialDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportParticipantQuoteGetInitialDataQuery,
    ReportParticipantQuoteGetInitialDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportParticipantQuoteGetInitialDataQuery,
    ReportParticipantQuoteGetInitialDataQueryVariables
  >(ReportParticipantQuoteGetInitialDataDocument, options);
}
export type ReportParticipantQuoteGetInitialDataQueryHookResult = ReturnType<
  typeof useReportParticipantQuoteGetInitialDataQuery
>;
export type ReportParticipantQuoteGetInitialDataLazyQueryHookResult =
  ReturnType<typeof useReportParticipantQuoteGetInitialDataLazyQuery>;
export type ReportParticipantQuoteGetInitialDataQueryResult =
  Apollo.QueryResult<
    ReportParticipantQuoteGetInitialDataQuery,
    ReportParticipantQuoteGetInitialDataQueryVariables
  >;
export const ReportParticipantQuoteGetPublicHolidaysDocument = gql`
  query ReportParticipantQuoteGetPublicHolidays(
    $input: QueryPublicHolidaysInput!
  ) {
    publicHolidays(input: $input) {
      id
      date
    }
  }
`;

/**
 * __useReportParticipantQuoteGetPublicHolidaysQuery__
 *
 * To run a query within a React component, call `useReportParticipantQuoteGetPublicHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportParticipantQuoteGetPublicHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportParticipantQuoteGetPublicHolidaysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportParticipantQuoteGetPublicHolidaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportParticipantQuoteGetPublicHolidaysQuery,
    ReportParticipantQuoteGetPublicHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportParticipantQuoteGetPublicHolidaysQuery,
    ReportParticipantQuoteGetPublicHolidaysQueryVariables
  >(ReportParticipantQuoteGetPublicHolidaysDocument, options);
}
export function useReportParticipantQuoteGetPublicHolidaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportParticipantQuoteGetPublicHolidaysQuery,
    ReportParticipantQuoteGetPublicHolidaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportParticipantQuoteGetPublicHolidaysQuery,
    ReportParticipantQuoteGetPublicHolidaysQueryVariables
  >(ReportParticipantQuoteGetPublicHolidaysDocument, options);
}
export type ReportParticipantQuoteGetPublicHolidaysQueryHookResult = ReturnType<
  typeof useReportParticipantQuoteGetPublicHolidaysQuery
>;
export type ReportParticipantQuoteGetPublicHolidaysLazyQueryHookResult =
  ReturnType<typeof useReportParticipantQuoteGetPublicHolidaysLazyQuery>;
export type ReportParticipantQuoteGetPublicHolidaysQueryResult =
  Apollo.QueryResult<
    ReportParticipantQuoteGetPublicHolidaysQuery,
    ReportParticipantQuoteGetPublicHolidaysQueryVariables
  >;
export const ReportParticipantSeizuresGetObservationsDocument = gql`
  query ReportParticipantSeizuresGetObservations(
    $input: QueryClientObservationsInput!
  ) {
    clientObservations(input: $input) {
      id
      date
      notes
      seizureDuration
      seizureType
      seizureLostConsciousness
      incontinenceType
      postSeizureWalkDuration
      createdBy {
        id
        currentPostNominal
        ...PersonNames
      }
      member {
        id
        currentPostNominal
        ...PersonNames
      }
      client {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportParticipantSeizuresGetObservationsQuery__
 *
 * To run a query within a React component, call `useReportParticipantSeizuresGetObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportParticipantSeizuresGetObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportParticipantSeizuresGetObservationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportParticipantSeizuresGetObservationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportParticipantSeizuresGetObservationsQuery,
    ReportParticipantSeizuresGetObservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportParticipantSeizuresGetObservationsQuery,
    ReportParticipantSeizuresGetObservationsQueryVariables
  >(ReportParticipantSeizuresGetObservationsDocument, options);
}
export function useReportParticipantSeizuresGetObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportParticipantSeizuresGetObservationsQuery,
    ReportParticipantSeizuresGetObservationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportParticipantSeizuresGetObservationsQuery,
    ReportParticipantSeizuresGetObservationsQueryVariables
  >(ReportParticipantSeizuresGetObservationsDocument, options);
}
export type ReportParticipantSeizuresGetObservationsQueryHookResult =
  ReturnType<typeof useReportParticipantSeizuresGetObservationsQuery>;
export type ReportParticipantSeizuresGetObservationsLazyQueryHookResult =
  ReturnType<typeof useReportParticipantSeizuresGetObservationsLazyQuery>;
export type ReportParticipantSeizuresGetObservationsQueryResult =
  Apollo.QueryResult<
    ReportParticipantSeizuresGetObservationsQuery,
    ReportParticipantSeizuresGetObservationsQueryVariables
  >;
export const ReportShiftProfitLoss2GetFixedExpensesDocument = gql`
  query ReportShiftProfitLoss2GetFixedExpenses(
    $membersInput: QueryMembersInput!
  ) {
    members(input: $membersInput) {
      id
      employmentStartDate
      employmentEndDate
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportShiftProfitLoss2GetFixedExpensesQuery__
 *
 * To run a query within a React component, call `useReportShiftProfitLoss2GetFixedExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportShiftProfitLoss2GetFixedExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportShiftProfitLoss2GetFixedExpensesQuery({
 *   variables: {
 *      membersInput: // value for 'membersInput'
 *   },
 * });
 */
export function useReportShiftProfitLoss2GetFixedExpensesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportShiftProfitLoss2GetFixedExpensesQuery,
    ReportShiftProfitLoss2GetFixedExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportShiftProfitLoss2GetFixedExpensesQuery,
    ReportShiftProfitLoss2GetFixedExpensesQueryVariables
  >(ReportShiftProfitLoss2GetFixedExpensesDocument, options);
}
export function useReportShiftProfitLoss2GetFixedExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportShiftProfitLoss2GetFixedExpensesQuery,
    ReportShiftProfitLoss2GetFixedExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportShiftProfitLoss2GetFixedExpensesQuery,
    ReportShiftProfitLoss2GetFixedExpensesQueryVariables
  >(ReportShiftProfitLoss2GetFixedExpensesDocument, options);
}
export type ReportShiftProfitLoss2GetFixedExpensesQueryHookResult = ReturnType<
  typeof useReportShiftProfitLoss2GetFixedExpensesQuery
>;
export type ReportShiftProfitLoss2GetFixedExpensesLazyQueryHookResult =
  ReturnType<typeof useReportShiftProfitLoss2GetFixedExpensesLazyQuery>;
export type ReportShiftProfitLoss2GetFixedExpensesQueryResult =
  Apollo.QueryResult<
    ReportShiftProfitLoss2GetFixedExpensesQuery,
    ReportShiftProfitLoss2GetFixedExpensesQueryVariables
  >;
export const ReportShiftProfitLoss2GetHolidaysEventsDocument = gql`
  query ReportShiftProfitLoss2GetHolidaysEvents(
    $eventsInput: QueryEventsInput!
    $publicHolidaysInput: QueryPublicHolidaysInput!
  ) {
    eventsNoCache(input: $eventsInput) {
      id
      startAt
      endAt
      activeAssist
      passive
      passiveStartAt
      travelTime
      travelDistance
      payable
      billable
      publicHoliday
      region
      client {
        id
      }
      member {
        id
        bonusEligible
        ...PersonNames
      }
      cancel {
        id
      }
    }
    publicHolidays(input: $publicHolidaysInput) {
      id
      date
      region
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportShiftProfitLoss2GetHolidaysEventsQuery__
 *
 * To run a query within a React component, call `useReportShiftProfitLoss2GetHolidaysEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportShiftProfitLoss2GetHolidaysEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportShiftProfitLoss2GetHolidaysEventsQuery({
 *   variables: {
 *      eventsInput: // value for 'eventsInput'
 *      publicHolidaysInput: // value for 'publicHolidaysInput'
 *   },
 * });
 */
export function useReportShiftProfitLoss2GetHolidaysEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportShiftProfitLoss2GetHolidaysEventsQuery,
    ReportShiftProfitLoss2GetHolidaysEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportShiftProfitLoss2GetHolidaysEventsQuery,
    ReportShiftProfitLoss2GetHolidaysEventsQueryVariables
  >(ReportShiftProfitLoss2GetHolidaysEventsDocument, options);
}
export function useReportShiftProfitLoss2GetHolidaysEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportShiftProfitLoss2GetHolidaysEventsQuery,
    ReportShiftProfitLoss2GetHolidaysEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportShiftProfitLoss2GetHolidaysEventsQuery,
    ReportShiftProfitLoss2GetHolidaysEventsQueryVariables
  >(ReportShiftProfitLoss2GetHolidaysEventsDocument, options);
}
export type ReportShiftProfitLoss2GetHolidaysEventsQueryHookResult = ReturnType<
  typeof useReportShiftProfitLoss2GetHolidaysEventsQuery
>;
export type ReportShiftProfitLoss2GetHolidaysEventsLazyQueryHookResult =
  ReturnType<typeof useReportShiftProfitLoss2GetHolidaysEventsLazyQuery>;
export type ReportShiftProfitLoss2GetHolidaysEventsQueryResult =
  Apollo.QueryResult<
    ReportShiftProfitLoss2GetHolidaysEventsQuery,
    ReportShiftProfitLoss2GetHolidaysEventsQueryVariables
  >;
export const ReportShiftProfitLoss2GetPayRatesDocument = gql`
  query ReportShiftProfitLoss2GetPayRates(
    $membersInput: QueryMembersInput!
    $memberBasePayRatesInput: QueryMemberBasePayRatesInput!
  ) {
    members(input: $membersInput) {
      id
      bonusEligibleStartAt
      basePayRates(input: $memberBasePayRatesInput) {
        id
        date
        value
      }
    }
  }
`;

/**
 * __useReportShiftProfitLoss2GetPayRatesQuery__
 *
 * To run a query within a React component, call `useReportShiftProfitLoss2GetPayRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportShiftProfitLoss2GetPayRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportShiftProfitLoss2GetPayRatesQuery({
 *   variables: {
 *      membersInput: // value for 'membersInput'
 *      memberBasePayRatesInput: // value for 'memberBasePayRatesInput'
 *   },
 * });
 */
export function useReportShiftProfitLoss2GetPayRatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportShiftProfitLoss2GetPayRatesQuery,
    ReportShiftProfitLoss2GetPayRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportShiftProfitLoss2GetPayRatesQuery,
    ReportShiftProfitLoss2GetPayRatesQueryVariables
  >(ReportShiftProfitLoss2GetPayRatesDocument, options);
}
export function useReportShiftProfitLoss2GetPayRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportShiftProfitLoss2GetPayRatesQuery,
    ReportShiftProfitLoss2GetPayRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportShiftProfitLoss2GetPayRatesQuery,
    ReportShiftProfitLoss2GetPayRatesQueryVariables
  >(ReportShiftProfitLoss2GetPayRatesDocument, options);
}
export type ReportShiftProfitLoss2GetPayRatesQueryHookResult = ReturnType<
  typeof useReportShiftProfitLoss2GetPayRatesQuery
>;
export type ReportShiftProfitLoss2GetPayRatesLazyQueryHookResult = ReturnType<
  typeof useReportShiftProfitLoss2GetPayRatesLazyQuery
>;
export type ReportShiftProfitLoss2GetPayRatesQueryResult = Apollo.QueryResult<
  ReportShiftProfitLoss2GetPayRatesQuery,
  ReportShiftProfitLoss2GetPayRatesQueryVariables
>;
export const ReportShiftProfitLoss2GetRatesClientsMembersDocument = gql`
  query ReportShiftProfitLoss2GetRatesClientsMembers(
    $clientsInput: QueryClientsInput!
    $membersInput: QueryMembersInput!
    $memberPayRatesInput: QueryMemberBasePayRatesInput!
    $memberBranchAllocationsInput: QueryMemberBranchAllocationsInput!
  ) {
    clients(input: $clientsInput) {
      id
      ...PersonNames
    }
    members(input: $membersInput) {
      id
      ...PersonNames
    }
    memberBasePayRates(input: $memberPayRatesInput) {
      id
      date
      value
      type
      member {
        id
      }
    }
    memberBranchAllocations(input: $memberBranchAllocationsInput) {
      id
      date
      values {
        branchId
        value
      }
      member {
        id
      }
    }
    me {
      id
      member {
        id
        org {
          id
          fixedExpenses
          basePayRate
          afternoonPayMultiplier
          nightPayMultiplier
          satPayMultiplier
          sunPayMultiplier
          pubPayMultiplier
          passiveAllowance
          kmAllowance
          bonus2PayRate
          bonus3PayRate
          bonus4PayRate
          bonus5PayRate
          bonus6PayRate
          bonus7PayRate
          bonus8PayRate
          bonus9PayRate
          bonus10PayRate
          bonus11PayRate
          bonus12PayRate
          billing {
            day
            afternoon
            night
            saturday
            sunday
            publicHoliday
            overnight
            kms
          }
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportShiftProfitLoss2GetRatesClientsMembersQuery__
 *
 * To run a query within a React component, call `useReportShiftProfitLoss2GetRatesClientsMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportShiftProfitLoss2GetRatesClientsMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportShiftProfitLoss2GetRatesClientsMembersQuery({
 *   variables: {
 *      clientsInput: // value for 'clientsInput'
 *      membersInput: // value for 'membersInput'
 *      memberPayRatesInput: // value for 'memberPayRatesInput'
 *      memberBranchAllocationsInput: // value for 'memberBranchAllocationsInput'
 *   },
 * });
 */
export function useReportShiftProfitLoss2GetRatesClientsMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportShiftProfitLoss2GetRatesClientsMembersQuery,
    ReportShiftProfitLoss2GetRatesClientsMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportShiftProfitLoss2GetRatesClientsMembersQuery,
    ReportShiftProfitLoss2GetRatesClientsMembersQueryVariables
  >(ReportShiftProfitLoss2GetRatesClientsMembersDocument, options);
}
export function useReportShiftProfitLoss2GetRatesClientsMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportShiftProfitLoss2GetRatesClientsMembersQuery,
    ReportShiftProfitLoss2GetRatesClientsMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportShiftProfitLoss2GetRatesClientsMembersQuery,
    ReportShiftProfitLoss2GetRatesClientsMembersQueryVariables
  >(ReportShiftProfitLoss2GetRatesClientsMembersDocument, options);
}
export type ReportShiftProfitLoss2GetRatesClientsMembersQueryHookResult =
  ReturnType<typeof useReportShiftProfitLoss2GetRatesClientsMembersQuery>;
export type ReportShiftProfitLoss2GetRatesClientsMembersLazyQueryHookResult =
  ReturnType<typeof useReportShiftProfitLoss2GetRatesClientsMembersLazyQuery>;
export type ReportShiftProfitLoss2GetRatesClientsMembersQueryResult =
  Apollo.QueryResult<
    ReportShiftProfitLoss2GetRatesClientsMembersQuery,
    ReportShiftProfitLoss2GetRatesClientsMembersQueryVariables
  >;
export const ReportShiftProfitLossGetHolidaysEventsDocument = gql`
  query ReportShiftProfitLossGetHolidaysEvents(
    $eventsInput: QueryEventsInput!
    $publicHolidaysInput: QueryPublicHolidaysInput!
  ) {
    events(input: $eventsInput) {
      id
      startAt
      endAt
      activeAssist
      passive
      passiveStartAt
      travelTime
      travelDistance
      payable
      billable
      client {
        id
      }
      member {
        id
        bonusEligible
        currentHourlyBasePayRate
        ...PersonNames
      }
      cancel {
        id
      }
    }
    publicHolidays(input: $publicHolidaysInput) {
      id
      date
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportShiftProfitLossGetHolidaysEventsQuery__
 *
 * To run a query within a React component, call `useReportShiftProfitLossGetHolidaysEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportShiftProfitLossGetHolidaysEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportShiftProfitLossGetHolidaysEventsQuery({
 *   variables: {
 *      eventsInput: // value for 'eventsInput'
 *      publicHolidaysInput: // value for 'publicHolidaysInput'
 *   },
 * });
 */
export function useReportShiftProfitLossGetHolidaysEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportShiftProfitLossGetHolidaysEventsQuery,
    ReportShiftProfitLossGetHolidaysEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportShiftProfitLossGetHolidaysEventsQuery,
    ReportShiftProfitLossGetHolidaysEventsQueryVariables
  >(ReportShiftProfitLossGetHolidaysEventsDocument, options);
}
export function useReportShiftProfitLossGetHolidaysEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportShiftProfitLossGetHolidaysEventsQuery,
    ReportShiftProfitLossGetHolidaysEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportShiftProfitLossGetHolidaysEventsQuery,
    ReportShiftProfitLossGetHolidaysEventsQueryVariables
  >(ReportShiftProfitLossGetHolidaysEventsDocument, options);
}
export type ReportShiftProfitLossGetHolidaysEventsQueryHookResult = ReturnType<
  typeof useReportShiftProfitLossGetHolidaysEventsQuery
>;
export type ReportShiftProfitLossGetHolidaysEventsLazyQueryHookResult =
  ReturnType<typeof useReportShiftProfitLossGetHolidaysEventsLazyQuery>;
export type ReportShiftProfitLossGetHolidaysEventsQueryResult =
  Apollo.QueryResult<
    ReportShiftProfitLossGetHolidaysEventsQuery,
    ReportShiftProfitLossGetHolidaysEventsQueryVariables
  >;
export const ReportShiftProfitLossGetRatesClientsMembersDocument = gql`
  query ReportShiftProfitLossGetRatesClientsMembers(
    $clientsInput: QueryClientsInput!
    $membersInput: QueryMembersInput!
  ) {
    clients(input: $clientsInput) {
      id
      ...PersonNames
    }
    members(input: $membersInput) {
      id
      ...PersonNames
    }
    me {
      id
      member {
        id
        org {
          id
          basePayRate
          afternoonPayMultiplier
          nightPayMultiplier
          satPayMultiplier
          sunPayMultiplier
          pubPayMultiplier
          passiveAllowance
          kmAllowance
          bonus2PayRate
          bonus3PayRate
          bonus4PayRate
          bonus5PayRate
          bonus6PayRate
          bonus7PayRate
          bonus8PayRate
          bonus9PayRate
          bonus10PayRate
          bonus11PayRate
          bonus12PayRate
          billing {
            day
            afternoon
            night
            saturday
            sunday
            publicHoliday
            overnight
            kms
          }
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportShiftProfitLossGetRatesClientsMembersQuery__
 *
 * To run a query within a React component, call `useReportShiftProfitLossGetRatesClientsMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportShiftProfitLossGetRatesClientsMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportShiftProfitLossGetRatesClientsMembersQuery({
 *   variables: {
 *      clientsInput: // value for 'clientsInput'
 *      membersInput: // value for 'membersInput'
 *   },
 * });
 */
export function useReportShiftProfitLossGetRatesClientsMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportShiftProfitLossGetRatesClientsMembersQuery,
    ReportShiftProfitLossGetRatesClientsMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportShiftProfitLossGetRatesClientsMembersQuery,
    ReportShiftProfitLossGetRatesClientsMembersQueryVariables
  >(ReportShiftProfitLossGetRatesClientsMembersDocument, options);
}
export function useReportShiftProfitLossGetRatesClientsMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportShiftProfitLossGetRatesClientsMembersQuery,
    ReportShiftProfitLossGetRatesClientsMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportShiftProfitLossGetRatesClientsMembersQuery,
    ReportShiftProfitLossGetRatesClientsMembersQueryVariables
  >(ReportShiftProfitLossGetRatesClientsMembersDocument, options);
}
export type ReportShiftProfitLossGetRatesClientsMembersQueryHookResult =
  ReturnType<typeof useReportShiftProfitLossGetRatesClientsMembersQuery>;
export type ReportShiftProfitLossGetRatesClientsMembersLazyQueryHookResult =
  ReturnType<typeof useReportShiftProfitLossGetRatesClientsMembersLazyQuery>;
export type ReportShiftProfitLossGetRatesClientsMembersQueryResult =
  Apollo.QueryResult<
    ReportShiftProfitLossGetRatesClientsMembersQuery,
    ReportShiftProfitLossGetRatesClientsMembersQueryVariables
  >;
export const ReportWorkedWithGetProfilesDocument = gql`
  query ReportWorkedWithGetProfiles(
    $clientsInput: QueryClientsInput!
    $membersInput: QueryMembersInput!
  ) {
    clients(input: $clientsInput) {
      id
      color
      ...PersonNames
    }
    members(input: $membersInput) {
      id
      color
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportWorkedWithGetProfilesQuery__
 *
 * To run a query within a React component, call `useReportWorkedWithGetProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportWorkedWithGetProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportWorkedWithGetProfilesQuery({
 *   variables: {
 *      clientsInput: // value for 'clientsInput'
 *      membersInput: // value for 'membersInput'
 *   },
 * });
 */
export function useReportWorkedWithGetProfilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportWorkedWithGetProfilesQuery,
    ReportWorkedWithGetProfilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportWorkedWithGetProfilesQuery,
    ReportWorkedWithGetProfilesQueryVariables
  >(ReportWorkedWithGetProfilesDocument, options);
}
export function useReportWorkedWithGetProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportWorkedWithGetProfilesQuery,
    ReportWorkedWithGetProfilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportWorkedWithGetProfilesQuery,
    ReportWorkedWithGetProfilesQueryVariables
  >(ReportWorkedWithGetProfilesDocument, options);
}
export type ReportWorkedWithGetProfilesQueryHookResult = ReturnType<
  typeof useReportWorkedWithGetProfilesQuery
>;
export type ReportWorkedWithGetProfilesLazyQueryHookResult = ReturnType<
  typeof useReportWorkedWithGetProfilesLazyQuery
>;
export type ReportWorkedWithGetProfilesQueryResult = Apollo.QueryResult<
  ReportWorkedWithGetProfilesQuery,
  ReportWorkedWithGetProfilesQueryVariables
>;
export const ReportWorkedWithGetResultClientsDocument = gql`
  query ReportWorkedWithGetResultClients($input: QueryClientsInput!) {
    clients(input: $input) {
      id
      color
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportWorkedWithGetResultClientsQuery__
 *
 * To run a query within a React component, call `useReportWorkedWithGetResultClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportWorkedWithGetResultClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportWorkedWithGetResultClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportWorkedWithGetResultClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportWorkedWithGetResultClientsQuery,
    ReportWorkedWithGetResultClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportWorkedWithGetResultClientsQuery,
    ReportWorkedWithGetResultClientsQueryVariables
  >(ReportWorkedWithGetResultClientsDocument, options);
}
export function useReportWorkedWithGetResultClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportWorkedWithGetResultClientsQuery,
    ReportWorkedWithGetResultClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportWorkedWithGetResultClientsQuery,
    ReportWorkedWithGetResultClientsQueryVariables
  >(ReportWorkedWithGetResultClientsDocument, options);
}
export type ReportWorkedWithGetResultClientsQueryHookResult = ReturnType<
  typeof useReportWorkedWithGetResultClientsQuery
>;
export type ReportWorkedWithGetResultClientsLazyQueryHookResult = ReturnType<
  typeof useReportWorkedWithGetResultClientsLazyQuery
>;
export type ReportWorkedWithGetResultClientsQueryResult = Apollo.QueryResult<
  ReportWorkedWithGetResultClientsQuery,
  ReportWorkedWithGetResultClientsQueryVariables
>;
export const ReportWorkedWithGetResultMembersDocument = gql`
  query ReportWorkedWithGetResultMembers($input: QueryMembersInput!) {
    members(input: $input) {
      id
      color
      ...PersonNames
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useReportWorkedWithGetResultMembersQuery__
 *
 * To run a query within a React component, call `useReportWorkedWithGetResultMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportWorkedWithGetResultMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportWorkedWithGetResultMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportWorkedWithGetResultMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportWorkedWithGetResultMembersQuery,
    ReportWorkedWithGetResultMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReportWorkedWithGetResultMembersQuery,
    ReportWorkedWithGetResultMembersQueryVariables
  >(ReportWorkedWithGetResultMembersDocument, options);
}
export function useReportWorkedWithGetResultMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportWorkedWithGetResultMembersQuery,
    ReportWorkedWithGetResultMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportWorkedWithGetResultMembersQuery,
    ReportWorkedWithGetResultMembersQueryVariables
  >(ReportWorkedWithGetResultMembersDocument, options);
}
export type ReportWorkedWithGetResultMembersQueryHookResult = ReturnType<
  typeof useReportWorkedWithGetResultMembersQuery
>;
export type ReportWorkedWithGetResultMembersLazyQueryHookResult = ReturnType<
  typeof useReportWorkedWithGetResultMembersLazyQuery
>;
export type ReportWorkedWithGetResultMembersQueryResult = Apollo.QueryResult<
  ReportWorkedWithGetResultMembersQuery,
  ReportWorkedWithGetResultMembersQueryVariables
>;
export const TrackFilesGetClientFilesDocument = gql`
  query TrackFilesGetClientFiles($input: QueryClientFilesInput!) {
    clientFiles(input: $input) {
      id
      type
      dateOfFile
      issuedAt
      expiresAt
      value
      owner {
        id
      }
      file {
        id
        name
      }
    }
  }
`;

/**
 * __useTrackFilesGetClientFilesQuery__
 *
 * To run a query within a React component, call `useTrackFilesGetClientFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackFilesGetClientFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackFilesGetClientFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackFilesGetClientFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrackFilesGetClientFilesQuery,
    TrackFilesGetClientFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrackFilesGetClientFilesQuery,
    TrackFilesGetClientFilesQueryVariables
  >(TrackFilesGetClientFilesDocument, options);
}
export function useTrackFilesGetClientFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrackFilesGetClientFilesQuery,
    TrackFilesGetClientFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrackFilesGetClientFilesQuery,
    TrackFilesGetClientFilesQueryVariables
  >(TrackFilesGetClientFilesDocument, options);
}
export type TrackFilesGetClientFilesQueryHookResult = ReturnType<
  typeof useTrackFilesGetClientFilesQuery
>;
export type TrackFilesGetClientFilesLazyQueryHookResult = ReturnType<
  typeof useTrackFilesGetClientFilesLazyQuery
>;
export type TrackFilesGetClientFilesQueryResult = Apollo.QueryResult<
  TrackFilesGetClientFilesQuery,
  TrackFilesGetClientFilesQueryVariables
>;
export const TrackFilesGetClientsDocument = gql`
  query TrackFilesGetClients($input: QueryClientsInput!) {
    clients(input: $input) {
      id
      trackFiles
      ...PersonNamesPartial
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useTrackFilesGetClientsQuery__
 *
 * To run a query within a React component, call `useTrackFilesGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackFilesGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackFilesGetClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackFilesGetClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrackFilesGetClientsQuery,
    TrackFilesGetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrackFilesGetClientsQuery,
    TrackFilesGetClientsQueryVariables
  >(TrackFilesGetClientsDocument, options);
}
export function useTrackFilesGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrackFilesGetClientsQuery,
    TrackFilesGetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrackFilesGetClientsQuery,
    TrackFilesGetClientsQueryVariables
  >(TrackFilesGetClientsDocument, options);
}
export type TrackFilesGetClientsQueryHookResult = ReturnType<
  typeof useTrackFilesGetClientsQuery
>;
export type TrackFilesGetClientsLazyQueryHookResult = ReturnType<
  typeof useTrackFilesGetClientsLazyQuery
>;
export type TrackFilesGetClientsQueryResult = Apollo.QueryResult<
  TrackFilesGetClientsQuery,
  TrackFilesGetClientsQueryVariables
>;
export const TrackFilesGetMemberFilesDocument = gql`
  query TrackFilesGetMemberFiles($input: QueryMemberFilesInput!) {
    memberFiles(input: $input) {
      id
      type
      dateOfFile
      issuedAt
      expiresAt
      value
      owner {
        id
      }
      file {
        id
        name
      }
    }
  }
`;

/**
 * __useTrackFilesGetMemberFilesQuery__
 *
 * To run a query within a React component, call `useTrackFilesGetMemberFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackFilesGetMemberFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackFilesGetMemberFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackFilesGetMemberFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrackFilesGetMemberFilesQuery,
    TrackFilesGetMemberFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrackFilesGetMemberFilesQuery,
    TrackFilesGetMemberFilesQueryVariables
  >(TrackFilesGetMemberFilesDocument, options);
}
export function useTrackFilesGetMemberFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrackFilesGetMemberFilesQuery,
    TrackFilesGetMemberFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrackFilesGetMemberFilesQuery,
    TrackFilesGetMemberFilesQueryVariables
  >(TrackFilesGetMemberFilesDocument, options);
}
export type TrackFilesGetMemberFilesQueryHookResult = ReturnType<
  typeof useTrackFilesGetMemberFilesQuery
>;
export type TrackFilesGetMemberFilesLazyQueryHookResult = ReturnType<
  typeof useTrackFilesGetMemberFilesLazyQuery
>;
export type TrackFilesGetMemberFilesQueryResult = Apollo.QueryResult<
  TrackFilesGetMemberFilesQuery,
  TrackFilesGetMemberFilesQueryVariables
>;
export const TrackFilesGetMembersDocument = gql`
  query TrackFilesGetMembers($input: QueryMembersInput!) {
    members(input: $input) {
      id
      trackFiles
      schedulable
      allowedFortnightlyWorkHours
      australianCitizen
      workRights
      workRightsLastCheckedAt
      externalId
      taxFileNumberDeclaration {
        tfn
      }
      ...PersonNamesPartial
      street
      locality
      region
      postcode
      country
      bank {
        accountNumber
      }
      super {
        fundName
      }
      hasCar
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useTrackFilesGetMembersQuery__
 *
 * To run a query within a React component, call `useTrackFilesGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackFilesGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackFilesGetMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackFilesGetMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    TrackFilesGetMembersQuery,
    TrackFilesGetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrackFilesGetMembersQuery,
    TrackFilesGetMembersQueryVariables
  >(TrackFilesGetMembersDocument, options);
}
export function useTrackFilesGetMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrackFilesGetMembersQuery,
    TrackFilesGetMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrackFilesGetMembersQuery,
    TrackFilesGetMembersQueryVariables
  >(TrackFilesGetMembersDocument, options);
}
export type TrackFilesGetMembersQueryHookResult = ReturnType<
  typeof useTrackFilesGetMembersQuery
>;
export type TrackFilesGetMembersLazyQueryHookResult = ReturnType<
  typeof useTrackFilesGetMembersLazyQuery
>;
export type TrackFilesGetMembersQueryResult = Apollo.QueryResult<
  TrackFilesGetMembersQuery,
  TrackFilesGetMembersQueryVariables
>;
export const TrackFilesGetOrgSettingsDocument = gql`
  query TrackFilesGetOrgSettings {
    me {
      id
      member {
        id
        org {
          id
          memberDocumentTrackerCollapsedFiles
          memberDocumentTrackerVisibleFiles
          clientDocumentTrackerCollapsedFiles
          clientDocumentTrackerVisibleFiles
          fileStorageStatistics {
            fileCount
            allocatedSpace
            usedSpace
          }
        }
      }
    }
  }
`;

/**
 * __useTrackFilesGetOrgSettingsQuery__
 *
 * To run a query within a React component, call `useTrackFilesGetOrgSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackFilesGetOrgSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackFilesGetOrgSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrackFilesGetOrgSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrackFilesGetOrgSettingsQuery,
    TrackFilesGetOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TrackFilesGetOrgSettingsQuery,
    TrackFilesGetOrgSettingsQueryVariables
  >(TrackFilesGetOrgSettingsDocument, options);
}
export function useTrackFilesGetOrgSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrackFilesGetOrgSettingsQuery,
    TrackFilesGetOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrackFilesGetOrgSettingsQuery,
    TrackFilesGetOrgSettingsQueryVariables
  >(TrackFilesGetOrgSettingsDocument, options);
}
export type TrackFilesGetOrgSettingsQueryHookResult = ReturnType<
  typeof useTrackFilesGetOrgSettingsQuery
>;
export type TrackFilesGetOrgSettingsLazyQueryHookResult = ReturnType<
  typeof useTrackFilesGetOrgSettingsLazyQuery
>;
export type TrackFilesGetOrgSettingsQueryResult = Apollo.QueryResult<
  TrackFilesGetOrgSettingsQuery,
  TrackFilesGetOrgSettingsQueryVariables
>;
export const ScheduleControlMembersInputDocument = gql`
  query ScheduleControlMembersInput(
    $input: QueryMembersInput!
    $scheduleInput: QueryMemberScheduleInput!
    $eventsInput: QueryEventsInput!
    $teamsInput: QueryClientTeamsInput!
    $unavailablesInput: QueryMemberUnavailablesInput!
  ) {
    members(input: $input) {
      id
      hasAccess
      color
      phone
      gender
      dob
      hasCar
      ...PersonNames
      schedule(input: $scheduleInput) {
        totalMinutes
      }
      events(input: $eventsInput) {
        id
        startAt
        duration
        client {
          id
          ...PersonNames
        }
      }
      teams(input: $teamsInput) {
        id
        preferred
        client {
          id
        }
      }
      unavailables(input: $unavailablesInput) {
        id
        startAt
        endAt
        notes
        owner {
          id
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useScheduleControlMembersInputQuery__
 *
 * To run a query within a React component, call `useScheduleControlMembersInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleControlMembersInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleControlMembersInputQuery({
 *   variables: {
 *      input: // value for 'input'
 *      scheduleInput: // value for 'scheduleInput'
 *      eventsInput: // value for 'eventsInput'
 *      teamsInput: // value for 'teamsInput'
 *      unavailablesInput: // value for 'unavailablesInput'
 *   },
 * });
 */
export function useScheduleControlMembersInputQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleControlMembersInputQuery,
    ScheduleControlMembersInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleControlMembersInputQuery,
    ScheduleControlMembersInputQueryVariables
  >(ScheduleControlMembersInputDocument, options);
}
export function useScheduleControlMembersInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleControlMembersInputQuery,
    ScheduleControlMembersInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleControlMembersInputQuery,
    ScheduleControlMembersInputQueryVariables
  >(ScheduleControlMembersInputDocument, options);
}
export type ScheduleControlMembersInputQueryHookResult = ReturnType<
  typeof useScheduleControlMembersInputQuery
>;
export type ScheduleControlMembersInputLazyQueryHookResult = ReturnType<
  typeof useScheduleControlMembersInputLazyQuery
>;
export type ScheduleControlMembersInputQueryResult = Apollo.QueryResult<
  ScheduleControlMembersInputQuery,
  ScheduleControlMembersInputQueryVariables
>;
export const ScheduleMemberHoursInputDocument = gql`
  query ScheduleMemberHoursInput(
    $membersInput: QueryScheuleMembersInput!
    $scheduleInput: QueryMemberScheduleInput!
  ) {
    schedule {
      members(input: $membersInput) {
        weekTime
        fortnightTime
        member {
          id
          color
          schedule(input: $scheduleInput) {
            allowedMinutes
          }
          ...PersonNames
        }
        currentEvent {
          id
          startAt
          endAt
          clockedOnAt
        }
        nextEvent {
          id
          startAt
          endAt
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useScheduleMemberHoursInputQuery__
 *
 * To run a query within a React component, call `useScheduleMemberHoursInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleMemberHoursInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleMemberHoursInputQuery({
 *   variables: {
 *      membersInput: // value for 'membersInput'
 *      scheduleInput: // value for 'scheduleInput'
 *   },
 * });
 */
export function useScheduleMemberHoursInputQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleMemberHoursInputQuery,
    ScheduleMemberHoursInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleMemberHoursInputQuery,
    ScheduleMemberHoursInputQueryVariables
  >(ScheduleMemberHoursInputDocument, options);
}
export function useScheduleMemberHoursInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleMemberHoursInputQuery,
    ScheduleMemberHoursInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleMemberHoursInputQuery,
    ScheduleMemberHoursInputQueryVariables
  >(ScheduleMemberHoursInputDocument, options);
}
export type ScheduleMemberHoursInputQueryHookResult = ReturnType<
  typeof useScheduleMemberHoursInputQuery
>;
export type ScheduleMemberHoursInputLazyQueryHookResult = ReturnType<
  typeof useScheduleMemberHoursInputLazyQuery
>;
export type ScheduleMemberHoursInputQueryResult = Apollo.QueryResult<
  ScheduleMemberHoursInputQuery,
  ScheduleMemberHoursInputQueryVariables
>;
export const ScheduleMemberInfoDocument = gql`
  query ScheduleMemberInfo(
    $membersInput: QueryMembersInput!
    $scheduleInput: QueryMemberSchedulesInput!
  ) {
    members(input: $membersInput) {
      id
      color
      ...PersonNames
      currentEvent {
        id
        startAt
        endAt
        clockedOnAt
      }
      nextEvent {
        id
        startAt
        endAt
      }
      schedules(input: $scheduleInput) {
        totalMinutes
        allowedMinutes
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useScheduleMemberInfoQuery__
 *
 * To run a query within a React component, call `useScheduleMemberInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleMemberInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleMemberInfoQuery({
 *   variables: {
 *      membersInput: // value for 'membersInput'
 *      scheduleInput: // value for 'scheduleInput'
 *   },
 * });
 */
export function useScheduleMemberInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleMemberInfoQuery,
    ScheduleMemberInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleMemberInfoQuery,
    ScheduleMemberInfoQueryVariables
  >(ScheduleMemberInfoDocument, options);
}
export function useScheduleMemberInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleMemberInfoQuery,
    ScheduleMemberInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleMemberInfoQuery,
    ScheduleMemberInfoQueryVariables
  >(ScheduleMemberInfoDocument, options);
}
export type ScheduleMemberInfoQueryHookResult = ReturnType<
  typeof useScheduleMemberInfoQuery
>;
export type ScheduleMemberInfoLazyQueryHookResult = ReturnType<
  typeof useScheduleMemberInfoLazyQuery
>;
export type ScheduleMemberInfoQueryResult = Apollo.QueryResult<
  ScheduleMemberInfoQuery,
  ScheduleMemberInfoQueryVariables
>;
export const ScheduleMemberInfo2Document = gql`
  query ScheduleMemberInfo2(
    $membersInput: QueryMembersInput!
    $hoursInput: QueryMemberHoursInput!
  ) {
    members(input: $membersInput) {
      id
      color
      ...PersonNames
      currentEvent {
        id
        startAt
        endAt
        clockedOnAt
      }
      nextEvent {
        id
        startAt
        endAt
      }
      hours(input: $hoursInput) {
        id
        date
        type
        value
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useScheduleMemberInfo2Query__
 *
 * To run a query within a React component, call `useScheduleMemberInfo2Query` and pass it any options that fit your needs.
 * When your component renders, `useScheduleMemberInfo2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleMemberInfo2Query({
 *   variables: {
 *      membersInput: // value for 'membersInput'
 *      hoursInput: // value for 'hoursInput'
 *   },
 * });
 */
export function useScheduleMemberInfo2Query(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleMemberInfo2Query,
    ScheduleMemberInfo2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleMemberInfo2Query,
    ScheduleMemberInfo2QueryVariables
  >(ScheduleMemberInfo2Document, options);
}
export function useScheduleMemberInfo2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleMemberInfo2Query,
    ScheduleMemberInfo2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleMemberInfo2Query,
    ScheduleMemberInfo2QueryVariables
  >(ScheduleMemberInfo2Document, options);
}
export type ScheduleMemberInfo2QueryHookResult = ReturnType<
  typeof useScheduleMemberInfo2Query
>;
export type ScheduleMemberInfo2LazyQueryHookResult = ReturnType<
  typeof useScheduleMemberInfo2LazyQuery
>;
export type ScheduleMemberInfo2QueryResult = Apollo.QueryResult<
  ScheduleMemberInfo2Query,
  ScheduleMemberInfo2QueryVariables
>;
export const GetScheduleOrgSettingsDocument = gql`
  query GetScheduleOrgSettings {
    me {
      id
      member {
        id
        org {
          id
          taskAssignAttendeeFutureDays
          taskAssignAttendeePastWeeks
        }
      }
    }
  }
`;

/**
 * __useGetScheduleOrgSettingsQuery__
 *
 * To run a query within a React component, call `useGetScheduleOrgSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleOrgSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleOrgSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScheduleOrgSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetScheduleOrgSettingsQuery,
    GetScheduleOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetScheduleOrgSettingsQuery,
    GetScheduleOrgSettingsQueryVariables
  >(GetScheduleOrgSettingsDocument, options);
}
export function useGetScheduleOrgSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScheduleOrgSettingsQuery,
    GetScheduleOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScheduleOrgSettingsQuery,
    GetScheduleOrgSettingsQueryVariables
  >(GetScheduleOrgSettingsDocument, options);
}
export type GetScheduleOrgSettingsQueryHookResult = ReturnType<
  typeof useGetScheduleOrgSettingsQuery
>;
export type GetScheduleOrgSettingsLazyQueryHookResult = ReturnType<
  typeof useGetScheduleOrgSettingsLazyQuery
>;
export type GetScheduleOrgSettingsQueryResult = Apollo.QueryResult<
  GetScheduleOrgSettingsQuery,
  GetScheduleOrgSettingsQueryVariables
>;
export const ScheduleUnallocatedEventsDocument = gql`
  query ScheduleUnallocatedEvents($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      client {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useScheduleUnallocatedEventsQuery__
 *
 * To run a query within a React component, call `useScheduleUnallocatedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleUnallocatedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleUnallocatedEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleUnallocatedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleUnallocatedEventsQuery,
    ScheduleUnallocatedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleUnallocatedEventsQuery,
    ScheduleUnallocatedEventsQueryVariables
  >(ScheduleUnallocatedEventsDocument, options);
}
export function useScheduleUnallocatedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleUnallocatedEventsQuery,
    ScheduleUnallocatedEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleUnallocatedEventsQuery,
    ScheduleUnallocatedEventsQueryVariables
  >(ScheduleUnallocatedEventsDocument, options);
}
export type ScheduleUnallocatedEventsQueryHookResult = ReturnType<
  typeof useScheduleUnallocatedEventsQuery
>;
export type ScheduleUnallocatedEventsLazyQueryHookResult = ReturnType<
  typeof useScheduleUnallocatedEventsLazyQuery
>;
export type ScheduleUnallocatedEventsQueryResult = Apollo.QueryResult<
  ScheduleUnallocatedEventsQuery,
  ScheduleUnallocatedEventsQueryVariables
>;
export const GetApproveShiftsOrgSettingsDocument = gql`
  query GetApproveShiftsOrgSettings {
    me {
      id
      member {
        id
        org {
          id
          clockOnWarnMinutes
          clockOffWarnMinutes
        }
      }
    }
  }
`;

/**
 * __useGetApproveShiftsOrgSettingsQuery__
 *
 * To run a query within a React component, call `useGetApproveShiftsOrgSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApproveShiftsOrgSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApproveShiftsOrgSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApproveShiftsOrgSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApproveShiftsOrgSettingsQuery,
    GetApproveShiftsOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApproveShiftsOrgSettingsQuery,
    GetApproveShiftsOrgSettingsQueryVariables
  >(GetApproveShiftsOrgSettingsDocument, options);
}
export function useGetApproveShiftsOrgSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApproveShiftsOrgSettingsQuery,
    GetApproveShiftsOrgSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApproveShiftsOrgSettingsQuery,
    GetApproveShiftsOrgSettingsQueryVariables
  >(GetApproveShiftsOrgSettingsDocument, options);
}
export type GetApproveShiftsOrgSettingsQueryHookResult = ReturnType<
  typeof useGetApproveShiftsOrgSettingsQuery
>;
export type GetApproveShiftsOrgSettingsLazyQueryHookResult = ReturnType<
  typeof useGetApproveShiftsOrgSettingsLazyQuery
>;
export type GetApproveShiftsOrgSettingsQueryResult = Apollo.QueryResult<
  GetApproveShiftsOrgSettingsQuery,
  GetApproveShiftsOrgSettingsQueryVariables
>;
export const GetClaimableShiftsDocument = gql`
  query GetClaimableShifts($input: QueryEventsOwnAndRelatedInput) {
    eventsClaimable(input: $input) {
      id
      startAt
      endAt
      duration
      passive
      locality
      region
      client {
        id
        color
        ...PersonNames
      }
      member {
        id
        color
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetClaimableShiftsQuery__
 *
 * To run a query within a React component, call `useGetClaimableShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimableShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimableShiftsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClaimableShiftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClaimableShiftsQuery,
    GetClaimableShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClaimableShiftsQuery,
    GetClaimableShiftsQueryVariables
  >(GetClaimableShiftsDocument, options);
}
export function useGetClaimableShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClaimableShiftsQuery,
    GetClaimableShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClaimableShiftsQuery,
    GetClaimableShiftsQueryVariables
  >(GetClaimableShiftsDocument, options);
}
export type GetClaimableShiftsQueryHookResult = ReturnType<
  typeof useGetClaimableShiftsQuery
>;
export type GetClaimableShiftsLazyQueryHookResult = ReturnType<
  typeof useGetClaimableShiftsLazyQuery
>;
export type GetClaimableShiftsQueryResult = Apollo.QueryResult<
  GetClaimableShiftsQuery,
  GetClaimableShiftsQueryVariables
>;
export const GetMemberSchedulesDocument = gql`
  query GetMemberSchedules(
    $membersInput: QueryMembersInput!
    $schedulesInput: QueryMemberSchedulesInput!
  ) {
    members(input: $membersInput) {
      id
      schedules(input: $schedulesInput) {
        totalMinutes
        allowedMinutes
      }
    }
  }
`;

/**
 * __useGetMemberSchedulesQuery__
 *
 * To run a query within a React component, call `useGetMemberSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberSchedulesQuery({
 *   variables: {
 *      membersInput: // value for 'membersInput'
 *      schedulesInput: // value for 'schedulesInput'
 *   },
 * });
 */
export function useGetMemberSchedulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMemberSchedulesQuery,
    GetMemberSchedulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMemberSchedulesQuery,
    GetMemberSchedulesQueryVariables
  >(GetMemberSchedulesDocument, options);
}
export function useGetMemberSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMemberSchedulesQuery,
    GetMemberSchedulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMemberSchedulesQuery,
    GetMemberSchedulesQueryVariables
  >(GetMemberSchedulesDocument, options);
}
export type GetMemberSchedulesQueryHookResult = ReturnType<
  typeof useGetMemberSchedulesQuery
>;
export type GetMemberSchedulesLazyQueryHookResult = ReturnType<
  typeof useGetMemberSchedulesLazyQuery
>;
export type GetMemberSchedulesQueryResult = Apollo.QueryResult<
  GetMemberSchedulesQuery,
  GetMemberSchedulesQueryVariables
>;
export const GetMyShiftClaimsDocument = gql`
  query GetMyShiftClaims($input: QueryEventClaimsInput!) {
    myEventClaims(input: $input) {
      id
      event {
        id
      }
    }
  }
`;

/**
 * __useGetMyShiftClaimsQuery__
 *
 * To run a query within a React component, call `useGetMyShiftClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyShiftClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyShiftClaimsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyShiftClaimsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyShiftClaimsQuery,
    GetMyShiftClaimsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyShiftClaimsQuery, GetMyShiftClaimsQueryVariables>(
    GetMyShiftClaimsDocument,
    options,
  );
}
export function useGetMyShiftClaimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyShiftClaimsQuery,
    GetMyShiftClaimsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyShiftClaimsQuery,
    GetMyShiftClaimsQueryVariables
  >(GetMyShiftClaimsDocument, options);
}
export type GetMyShiftClaimsQueryHookResult = ReturnType<
  typeof useGetMyShiftClaimsQuery
>;
export type GetMyShiftClaimsLazyQueryHookResult = ReturnType<
  typeof useGetMyShiftClaimsLazyQuery
>;
export type GetMyShiftClaimsQueryResult = Apollo.QueryResult<
  GetMyShiftClaimsQuery,
  GetMyShiftClaimsQueryVariables
>;
export const GetMyShiftsDocument = gql`
  query GetMyShifts($input: QueryEventsOwnAndRelatedInput!) {
    eventsOwnAndRelated(input: $input) {
      id
      startAt
      endAt
      duration
      passive
      locality
      region
      client {
        id
        color
        ...PersonNames
      }
      member {
        id
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetMyShiftsQuery__
 *
 * To run a query within a React component, call `useGetMyShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyShiftsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyShiftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyShiftsQuery,
    GetMyShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyShiftsQuery, GetMyShiftsQueryVariables>(
    GetMyShiftsDocument,
    options,
  );
}
export function useGetMyShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyShiftsQuery,
    GetMyShiftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyShiftsQuery, GetMyShiftsQueryVariables>(
    GetMyShiftsDocument,
    options,
  );
}
export type GetMyShiftsQueryHookResult = ReturnType<typeof useGetMyShiftsQuery>;
export type GetMyShiftsLazyQueryHookResult = ReturnType<
  typeof useGetMyShiftsLazyQuery
>;
export type GetMyShiftsQueryResult = Apollo.QueryResult<
  GetMyShiftsQuery,
  GetMyShiftsQueryVariables
>;
export const GetShiftsMissingAnAttendeeDocument = gql`
  query GetShiftsMissingAnAttendee($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      duration
      locality
      region
      client {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useGetShiftsMissingAnAttendeeQuery__
 *
 * To run a query within a React component, call `useGetShiftsMissingAnAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftsMissingAnAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftsMissingAnAttendeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShiftsMissingAnAttendeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShiftsMissingAnAttendeeQuery,
    GetShiftsMissingAnAttendeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetShiftsMissingAnAttendeeQuery,
    GetShiftsMissingAnAttendeeQueryVariables
  >(GetShiftsMissingAnAttendeeDocument, options);
}
export function useGetShiftsMissingAnAttendeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShiftsMissingAnAttendeeQuery,
    GetShiftsMissingAnAttendeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetShiftsMissingAnAttendeeQuery,
    GetShiftsMissingAnAttendeeQueryVariables
  >(GetShiftsMissingAnAttendeeDocument, options);
}
export type GetShiftsMissingAnAttendeeQueryHookResult = ReturnType<
  typeof useGetShiftsMissingAnAttendeeQuery
>;
export type GetShiftsMissingAnAttendeeLazyQueryHookResult = ReturnType<
  typeof useGetShiftsMissingAnAttendeeLazyQuery
>;
export type GetShiftsMissingAnAttendeeQueryResult = Apollo.QueryResult<
  GetShiftsMissingAnAttendeeQuery,
  GetShiftsMissingAnAttendeeQueryVariables
>;
export const ShiftsGetEventsShouldNotHaveTravelDocument = gql`
  query ShiftsGetEventsShouldNotHaveTravel($input: QueryEventsInput!) {
    events(input: $input) {
      id
    }
  }
`;

/**
 * __useShiftsGetEventsShouldNotHaveTravelQuery__
 *
 * To run a query within a React component, call `useShiftsGetEventsShouldNotHaveTravelQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftsGetEventsShouldNotHaveTravelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftsGetEventsShouldNotHaveTravelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShiftsGetEventsShouldNotHaveTravelQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShiftsGetEventsShouldNotHaveTravelQuery,
    ShiftsGetEventsShouldNotHaveTravelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ShiftsGetEventsShouldNotHaveTravelQuery,
    ShiftsGetEventsShouldNotHaveTravelQueryVariables
  >(ShiftsGetEventsShouldNotHaveTravelDocument, options);
}
export function useShiftsGetEventsShouldNotHaveTravelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShiftsGetEventsShouldNotHaveTravelQuery,
    ShiftsGetEventsShouldNotHaveTravelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShiftsGetEventsShouldNotHaveTravelQuery,
    ShiftsGetEventsShouldNotHaveTravelQueryVariables
  >(ShiftsGetEventsShouldNotHaveTravelDocument, options);
}
export type ShiftsGetEventsShouldNotHaveTravelQueryHookResult = ReturnType<
  typeof useShiftsGetEventsShouldNotHaveTravelQuery
>;
export type ShiftsGetEventsShouldNotHaveTravelLazyQueryHookResult = ReturnType<
  typeof useShiftsGetEventsShouldNotHaveTravelLazyQuery
>;
export type ShiftsGetEventsShouldNotHaveTravelQueryResult = Apollo.QueryResult<
  ShiftsGetEventsShouldNotHaveTravelQuery,
  ShiftsGetEventsShouldNotHaveTravelQueryVariables
>;
export const ShiftsGetEventsWithTravelDocument = gql`
  query ShiftsGetEventsWithTravel($input: QueryEventsInput!) {
    events(input: $input) {
      id
      startAt
      endAt
      locality
      region
      travelTime
      travelTimeNotes
      client {
        id
        ...PersonNames
      }
      member {
        id
        ...PersonNames
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useShiftsGetEventsWithTravelQuery__
 *
 * To run a query within a React component, call `useShiftsGetEventsWithTravelQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftsGetEventsWithTravelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftsGetEventsWithTravelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShiftsGetEventsWithTravelQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShiftsGetEventsWithTravelQuery,
    ShiftsGetEventsWithTravelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ShiftsGetEventsWithTravelQuery,
    ShiftsGetEventsWithTravelQueryVariables
  >(ShiftsGetEventsWithTravelDocument, options);
}
export function useShiftsGetEventsWithTravelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShiftsGetEventsWithTravelQuery,
    ShiftsGetEventsWithTravelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShiftsGetEventsWithTravelQuery,
    ShiftsGetEventsWithTravelQueryVariables
  >(ShiftsGetEventsWithTravelDocument, options);
}
export type ShiftsGetEventsWithTravelQueryHookResult = ReturnType<
  typeof useShiftsGetEventsWithTravelQuery
>;
export type ShiftsGetEventsWithTravelLazyQueryHookResult = ReturnType<
  typeof useShiftsGetEventsWithTravelLazyQuery
>;
export type ShiftsGetEventsWithTravelQueryResult = Apollo.QueryResult<
  ShiftsGetEventsWithTravelQuery,
  ShiftsGetEventsWithTravelQueryVariables
>;
export const StartGetAdvancedDataDocument = gql`
  query StartGetAdvancedData(
    $eventsInput: QueryEventsInput!
    $clientsInput: QueryClientsInput!
    $membersInput: QueryMembersInput!
  ) {
    events(input: $eventsInput) {
      id
      startAt
      endAt
      duration
      client {
        id
        firstName
        lastName
      }
      member {
        id
      }
    }
    clientsAggregate(input: $clientsInput) {
      aggregate {
        totalCount
      }
      nodes {
        id
        firstName
        lastName
      }
    }
    membersAggregate(input: $membersInput) {
      aggregate {
        totalCount
      }
      nodes {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useStartGetAdvancedDataQuery__
 *
 * To run a query within a React component, call `useStartGetAdvancedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartGetAdvancedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartGetAdvancedDataQuery({
 *   variables: {
 *      eventsInput: // value for 'eventsInput'
 *      clientsInput: // value for 'clientsInput'
 *      membersInput: // value for 'membersInput'
 *   },
 * });
 */
export function useStartGetAdvancedDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    StartGetAdvancedDataQuery,
    StartGetAdvancedDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StartGetAdvancedDataQuery,
    StartGetAdvancedDataQueryVariables
  >(StartGetAdvancedDataDocument, options);
}
export function useStartGetAdvancedDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StartGetAdvancedDataQuery,
    StartGetAdvancedDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StartGetAdvancedDataQuery,
    StartGetAdvancedDataQueryVariables
  >(StartGetAdvancedDataDocument, options);
}
export type StartGetAdvancedDataQueryHookResult = ReturnType<
  typeof useStartGetAdvancedDataQuery
>;
export type StartGetAdvancedDataLazyQueryHookResult = ReturnType<
  typeof useStartGetAdvancedDataLazyQuery
>;
export type StartGetAdvancedDataQueryResult = Apollo.QueryResult<
  StartGetAdvancedDataQuery,
  StartGetAdvancedDataQueryVariables
>;
export const StartGetDataDocument = gql`
  query StartGetData($input: QueryEventsInput!) {
    me {
      id
      member {
        id
        missingFiles
        missingBankDetails
        events(input: $input) {
          id
          startAt
          endAt
          duration
          clockedOnAt
          clockedOffAt
          passive
          passiveStartAt
          activeAssist
          client {
            id
            ...PersonNames
          }
          member {
            id
          }
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
`;

/**
 * __useStartGetDataQuery__
 *
 * To run a query within a React component, call `useStartGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartGetDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartGetDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    StartGetDataQuery,
    StartGetDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StartGetDataQuery, StartGetDataQueryVariables>(
    StartGetDataDocument,
    options,
  );
}
export function useStartGetDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StartGetDataQuery,
    StartGetDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StartGetDataQuery, StartGetDataQueryVariables>(
    StartGetDataDocument,
    options,
  );
}
export type StartGetDataQueryHookResult = ReturnType<
  typeof useStartGetDataQuery
>;
export type StartGetDataLazyQueryHookResult = ReturnType<
  typeof useStartGetDataLazyQuery
>;
export type StartGetDataQueryResult = Apollo.QueryResult<
  StartGetDataQuery,
  StartGetDataQueryVariables
>;
export const GetSupportCoordinatorByIdDocument = gql`
  query GetSupportCoordinatorById($id: ID!) {
    supportCoordinatorById(id: $id) {
      id
      createdAt
      firstName
      middleName
      lastName
      preferredName
      email
      phone
      businessName
      businessEmail
      businessPhone
      abn
      salutation
      notes
      createdBy {
        id
        ...PersonNamesPartial
      }
      clients {
        id
      }
      archive {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
      delete {
        id
        date
        reason
        actor {
          id
          ...PersonNamesPartial
        }
      }
    }
  }
  ${PersonNamesPartialFragmentDoc}
`;

/**
 * __useGetSupportCoordinatorByIdQuery__
 *
 * To run a query within a React component, call `useGetSupportCoordinatorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportCoordinatorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportCoordinatorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSupportCoordinatorByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupportCoordinatorByIdQuery,
    GetSupportCoordinatorByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSupportCoordinatorByIdQuery,
    GetSupportCoordinatorByIdQueryVariables
  >(GetSupportCoordinatorByIdDocument, options);
}
export function useGetSupportCoordinatorByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportCoordinatorByIdQuery,
    GetSupportCoordinatorByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupportCoordinatorByIdQuery,
    GetSupportCoordinatorByIdQueryVariables
  >(GetSupportCoordinatorByIdDocument, options);
}
export type GetSupportCoordinatorByIdQueryHookResult = ReturnType<
  typeof useGetSupportCoordinatorByIdQuery
>;
export type GetSupportCoordinatorByIdLazyQueryHookResult = ReturnType<
  typeof useGetSupportCoordinatorByIdLazyQuery
>;
export type GetSupportCoordinatorByIdQueryResult = Apollo.QueryResult<
  GetSupportCoordinatorByIdQuery,
  GetSupportCoordinatorByIdQueryVariables
>;
export const GetSupportCoordinatorsDocument = gql`
  query GetSupportCoordinators($input: QuerySupportCoordinatorsInput!) {
    supportCoordinators(input: $input) {
      id
      abn
      salutation
      firstName
      middleName
      lastName
      preferredName
      email
      phone
      businessName
      businessEmail
      businessPhone
      archive {
        id
      }
      delete {
        id
      }
    }
  }
`;

/**
 * __useGetSupportCoordinatorsQuery__
 *
 * To run a query within a React component, call `useGetSupportCoordinatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportCoordinatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportCoordinatorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSupportCoordinatorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSupportCoordinatorsQuery,
    GetSupportCoordinatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSupportCoordinatorsQuery,
    GetSupportCoordinatorsQueryVariables
  >(GetSupportCoordinatorsDocument, options);
}
export function useGetSupportCoordinatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportCoordinatorsQuery,
    GetSupportCoordinatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupportCoordinatorsQuery,
    GetSupportCoordinatorsQueryVariables
  >(GetSupportCoordinatorsDocument, options);
}
export type GetSupportCoordinatorsQueryHookResult = ReturnType<
  typeof useGetSupportCoordinatorsQuery
>;
export type GetSupportCoordinatorsLazyQueryHookResult = ReturnType<
  typeof useGetSupportCoordinatorsLazyQuery
>;
export type GetSupportCoordinatorsQueryResult = Apollo.QueryResult<
  GetSupportCoordinatorsQuery,
  GetSupportCoordinatorsQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe(
    $branchesInput: QueryBranchesInput
    $membersInput: QueryMembersInput
  ) {
    me {
      id
      ...PersonNames
      member {
        id
        schedulable
        permissions
        ...PersonNames
        defaultBranch {
          id
        }
        branches(input: $branchesInput) {
          id
          name
        }
        org {
          id
          membersCanSeeClockedTimes
          schedulingContactPerson {
            id
            phone
            ...PersonNames
          }
          schedulingContactPerson2 {
            id
            phone
            ...PersonNames
          }
        }
      }
      members(input: $membersInput) {
        id
        admin
        superAdmin
        moduleAccessCS
        moduleAccessSC
        org {
          id
          ...BusinessNames
        }
      }
    }
  }
  ${PersonNamesFragmentDoc}
  ${BusinessNamesFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *      branchesInput: // value for 'branchesInput'
 *      membersInput: // value for 'membersInput'
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetVisaTypesDocument = gql`
  query GetVisaTypes($input: QueryVisaTypesInput!) {
    visaTypes(input: $input) {
      id
      name
      description
      allowedFortnightlyWorkHours
      allowedWorkHoursWaived
    }
  }
`;

/**
 * __useGetVisaTypesQuery__
 *
 * To run a query within a React component, call `useGetVisaTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisaTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisaTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVisaTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVisaTypesQuery,
    GetVisaTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVisaTypesQuery, GetVisaTypesQueryVariables>(
    GetVisaTypesDocument,
    options,
  );
}
export function useGetVisaTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVisaTypesQuery,
    GetVisaTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVisaTypesQuery, GetVisaTypesQueryVariables>(
    GetVisaTypesDocument,
    options,
  );
}
export type GetVisaTypesQueryHookResult = ReturnType<
  typeof useGetVisaTypesQuery
>;
export type GetVisaTypesLazyQueryHookResult = ReturnType<
  typeof useGetVisaTypesLazyQuery
>;
export type GetVisaTypesQueryResult = Apollo.QueryResult<
  GetVisaTypesQuery,
  GetVisaTypesQueryVariables
>;
