import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useRouter } from '@timed/common';
import {
  ReportActivitySlips,
  ReportDocumentTracker,
  ReportEventsChecker,
  ReportEventsTravel,
  ReportMemberClockedPeriods,
  ReportMemberHours,
  ReportMemberHours2,
  ReportMemberPaySlipForm,
  ReportMemberWorkedHours,
  ReportModule,
  ReportModuleInput,
  ReportParticipantObservationSeizures,
  ReportParticipantQuote,
  ReportProfitAndLossStatement,
  ReportWorkedWith,
} from '@timed/report';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  module: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
  }),
);

const ReportWizard = () => {
  const classes = useStyles();

  const {
    search: [searchParams, setSearchParams],
  } = useRouter();

  const { watch, control } = useForm<FormData>({
    defaultValues:
      !!searchParams.get('m') &&
      Object.values(ReportModule).includes(
        searchParams.get('m')!.toUpperCase() as ReportModule,
      )
        ? { module: searchParams.get('m')!.toUpperCase() }
        : undefined,
  });

  const watched = watch('module');

  useEffect(() => {
    if (!!watched && searchParams.get('m') !== watched) {
      searchParams.set('m', watched.toLowerCase());
      setSearchParams(searchParams);
    }
  }, [watched, searchParams, setSearchParams]);

  let module!: JSX.Element;

  switch (watched) {
    case ReportModule.EVENT_TRAVEL:
      module = <ReportEventsTravel />;
      break;

    case ReportModule.ACTIVITY_SLIPS:
      module = <ReportActivitySlips />;
      break;

    case ReportModule.EVENT_CHECKER:
      module = <ReportEventsChecker />;
      break;

    case ReportModule.EMPLOYEE_CLOCKED_SHIFTS:
      module = <ReportMemberClockedPeriods />;
      break;

    case ReportModule.EMPLOYEE_HOURS:
      module = <ReportMemberHours />;
      break;

    case ReportModule.EMPLOYEE_HOURS_2:
      module = <ReportMemberHours2 />;
      break;

    case ReportModule.EMPLOYEE_WORKED_HOURS:
      module = <ReportMemberWorkedHours />;
      break;

    case ReportModule.EMPLOYEE_PAY_SLIP:
      module = <ReportMemberPaySlipForm />;
      break;

    case ReportModule.PROFIT_AND_LOSS_STATEMENT:
      module = <ReportProfitAndLossStatement />;
      break;

    case ReportModule.PARTICIPANT_QUOTE_GENERATOR:
      module = <ReportParticipantQuote />;
      break;

    case ReportModule.WORKED_WITH:
      module = <ReportWorkedWith />;
      break;

    case ReportModule.PARTICIPANT_SEIZURES:
      module = <ReportParticipantObservationSeizures />;
      break;

    case ReportModule.DOCUMENT_TRACKER:
      module = <ReportDocumentTracker />;
      break;

    default:
      module = <></>;
      break;
  }

  return (
    <Paper className={classes.paper}>
      <Typography align="center" variant="h6">
        Export Data
      </Typography>
      <ReportModuleInput
        name="module"
        control={control}
        formControlProps={{ size: 'small', variant: 'outlined' }}
      />
      {module}
    </Paper>
  );
};

export default ReportWizard;
